import { CheckCircleOutlineOutlined, Delete, Edit } from "@mui/icons-material";
import ErrorIcon from "@mui/icons-material/Error";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { createRef, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import FormatNumber, {
  FormatTotalNumbers,
} from "../../components/FormatNumber/FormatNumber";
import { shopDetails } from "../../redux/Account/ShopDetails/action";
import { appointmentDetails } from "../../redux/appointment/action";
import {
  createWorkOrderAuthorization,
  getWorkOrderAuthorization,
  updatetWorkOrderAuthorizationStatus,
} from "../../redux/WorkOrderAuthorization/action";
import { theme } from "../../utility/helpers/CustomBreakpoints";
import { customisePartName } from "../../utility/helpers/displayHelper";
import { getItem } from "../../utility/localStorage";
import AppointmentModel from "../Appointment/AppointmentModel";
import ViewUpcomingModal from "../Appointment/UpcomingAppointment/ViewUpcomingModal";
import Loader from "../Common/Loader";
import SuccessMessageModal from "../Common/SuccessMessage";
import AddOdoModal from "../modal/AddOdoModal";
import ServiceModal from "../modal/ServiceModal";
import EditAuthorizationModel from "./EditAuthorizationModel";
import "./WorkOrderAuthorization.css";
import HistoryModel from "./HistoryModel";
const FIXED_PRICE = "fixed";
const LABOR_PARTS = "labor_&_parts";
const PRICE_RANGE = "range";

function WorkOrderAuthorization({ openSidebar }) {
  const { id, authorizationId } = useParams();
  const serviceRef = useRef([]);
  const discountRef = useRef();

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { shopName } = getItem("apex-saas_details");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const opeMoreAction = Boolean(anchorEl);
  const [openEditAuthorizeModel, setOpenAuthorizeModel] = useState(false);
  const [authorizeMessage, setAuthorizeMessage] = useState(
    `I hereby authorize the above repair work to be done along with any necessary materials. ${shopName} and its employees may operate the vehicle for purposes of testing, inspection or delivery. An express mechanic’s lien is acknowledged on vehicle to secure the amount of repairs thereto. ${shopName} will not be held responsible for loss or damage to the vehicle or articles left in the vehicle in case of fire, theft, accident or any other cause beyond  ${shopName}'s control.`
  );
  const [openServiceModel, setOpenServiceModal] = useState(false);
  const [appointmentData, setAppointmentData] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [scheduledServices, setScheduledServices] = useState([]);
  const [otherServices, setOtherServices] = useState([]);
  const [editServiceData, setEditServiceData] = useState({
    edit: false,
    index: "",
  });
  const errorMsgPriceRangeService =
    "Pricing type should be in fixed price or labor part.";
  const zeroPriceError = "Please add a price for all services added.";
  const zeroErroForTable = "Pricing should be greater than 0.";
  const [respondedBtn, setRespondedBtn] = useState("");
  const [totals, setTotals] = useState({
    subTotal: 0,
    discount: 0,
    taxPercentage: 13,
    tax: 0,
    estimatedTotal: 0,
  });
  const [discountError, setDiscountError] = useState(false);
  const [openAppointmentTodayModel, setOpenAppointmentTodayModel] =
    useState(false);
  const [upcomingAppointmentModel, setUpcomingAppointmentModel] =
    useState(false);
  const [updateAppointmentStatus, setUpdateAppointmentStatus] =
    React.useState("");
  const [appointmentDropOffODO, setAppointmentDropOffODo] = useState(null);
  const [appointmentCompletionOffODO, setAppointmentCompletionOffODo] =
    useState(null);
  const [editedServiceData, setEditedServiceData] = useState({});
  const [openODOModel, setOpenODOModel] = useState(false);
  const [workOrderStatus, setWorkOrderStatus] = useState("pending");
  const [isbothStateMatches, setIsBothStateMatches] = useState(true);
  const [isWorkOrderAuthorizationId, setIsWorkorderAuthorizationId] =
    useState("");
  const [workOrderData, setWorkOrderData] = useState({});
  const [authorizeDataPdf, setAuthrorizeDataPdf] = useState({
    droppedOffDate: "",
    completionDate: "",
    vehicleInfo: "",
    vehicleVin: "",
    licensePlate: "",
    odometerDroppedOff: "",
    odometerReturned: "",
    totalServices: 3,

    authorizeMessage: authorizeMessage,
    services: [],
    subTotal: 2000,
    discount: 0,
    tax: 0,
    total: 2000,
    address: "",
    shopName: "",
    shopPhone: "",
    businessNumber: "",
    shopLogo: "",
    customerName: "",
    customerNumber: "",
    customerEmail: "",
    authorizationNumber: "W" + dayjs().format("MMYY") + "-",
    authorizeDate: "2024-06-19T06:15:54.331Z",
    signature:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/d/df/Pavel_Demidov_Signature.svg/1280px-Pavel_Demidov_Signature.svg.png",
  });
  const [openHistory, setOpenHistory] = useState(false);
  const [animatedCSSError, setAnimatedCSSError] = useState("");

  useEffect(() => {
    fetchAppointmentDetails();
    if (!isWorkOrderAuthorizationId) getShopDetails();
  }, [id]);

  const handleAppointmentChangeStatus = () => {
    setOpenODOModel(true);
  };
  const setDefaultData = (detailView, type, appointmentData) => {
    console.log("detailView", detailView, type);
    console.log("appointmentDataappointmentData", appointmentData);
    let addedServices = appointmentData?.services?.filter(
      (d) => d.approvalStatus == "waiting" || d.approvalStatus == "approved"
    );

    addedServices = addedServices?.map((service) => {
      if (service?.pricingType == "no_price")
        return {
          ...service,
          pricingType: FIXED_PRICE,
          fixedPrice: 0,
          service: { _id: service?.serviceId, name: service?.serviceName },
        };
      if (service?.pricingType == FIXED_PRICE) {
        // if (type == "workOrder") {
        //   return {
        //     ...service,
        //     service: {
        //       _id: service?.service?._id,
        //       name: service?.service?.name,
        //     },
        //     serviceCategory: { _id: service?.service?.serviceCategory },
        //   };
        // } else
        return {
          ...service,
          service: { _id: service?.serviceId, name: service?.serviceName },
          serviceCategory: { _id: service?.serviceCategory?._id },
        };
      }

      if (service?.pricingType == LABOR_PARTS) {
        // if (type == "workOrder") {
        //   return {
        //     ...service,
        //     service: {
        //       _id: service?.service?._id,
        //       name: service?.service?.name,
        //     },
        //     serviceCategory: { _id: service?.service?.serviceCategory },
        //     laborQuantity: service?.labor.qty,
        //     laborRate: service?.labor.cost,
        //   };
        // }
        // else {
        return {
          ...service,
          service: {
            _id: service?.serviceId,
            name: service?.serviceName,
          },
          serviceCategory: { _id: service?.serviceCategory?._id },
          laborQuantity: service?.labor.qty,
          laborRate: service?.labor.ratePerHour,
        };
        // }
      }
    });
    console.log("addedServices", addedServices);
    setOtherServices(addedServices);

    let services = detailView?.services?.filter((d) => d.newAdded == false);
    console.log("services", services);
    services = services?.map((service) => {
      if (service?.pricingType == "no_price") {
        return {
          ...service,
          pricingType: FIXED_PRICE,
          fixedPrice: 0,
          service: { _id: service?.serviceId, name: service?.serviceName },
        };
      }

      if (service?.pricingType == FIXED_PRICE) {
        if (type == "workOrder") {
          return {
            ...service,
            service: {
              _id: service?.service?._id,
              name: service?.service?.name,
            },
            serviceCategory: { _id: service?.service?.serviceCategory },
          };
        } else
          return {
            ...service,
            service: { _id: service?.serviceId, name: service?.serviceName },
          };
      }

      if (service?.pricingType == LABOR_PARTS) {
        if (type == "workOrder") {
          return {
            ...service,
            service: {
              _id: service?.service?._id,
              name: service?.service?.name,
            },
            serviceCategory: { _id: service?.service?.serviceCategory },
            laborQuantity: service?.labor.qty,
            laborRate: service?.labor.cost,
          };
        } else {
          return {
            ...service,
            service: {
              _id: service?.serviceId,
              name: service?.serviceName,
            },
            laborQuantity: service?.labor.qty,
            laborRate: service?.labor.ratePerHour,
          };
        }
      }
      if (service?.pricingType == "range") {
        if (type == "workOrder") {
          return {
            ...service,
            service: {
              _id: service?.service?._id,
              name: service?.service?.name,
            },
            serviceCategory: { _id: service?.service?.serviceCategory?._id },
            priceMin: service?.priceMin,
            priceMax: service?.priceMax,
          };
        } else {
          return {
            ...service,
            service: {
              _id: service?.serviceId,
              name: service?.serviceName,
            },
            priceMin: service?.priceMin,
            priceMax: service?.priceMax,
            // serviceCategory: { _id: service?.serviceCategory?._id },
          };
        }
      }
    });

    setScheduledServices(services);
    setTotals({
      ...totals,
      discount: detailView?.discount,
    });
  };
  console.log("scheduled service", scheduledServices);
  async function fetchAppointmentDetails() {
    setShowLoader(true);
    const detailView = await dispatch(appointmentDetails(id));

    console.log("detailView", detailView);

    // const shopDetails = await getShopDetails();
    if (detailView) {
      setAppointmentData(detailView);
      if (detailView?.workOrderAuthorization?._id)
        workOrderAuthrizationDetail(
          detailView?.workOrderAuthorization?._id,
          detailView
        );
      else {
        setDefaultData(detailView, "appointment", detailView);
        setShowLoader(false);
      }
    } else {
      setShowLoader(false);
    }
  }

  async function fetchAppointmentDetailsForModel() {
    setShowLoader(true);
    const detailView = await dispatch(appointmentDetails(id));
    if (detailView) {
      setAppointmentData(detailView);
    }
    setShowLoader(false);
  }

  const getShopDetails = async () => {
    setShowLoader(true);
    const data = await dispatch(shopDetails());
    if (data && !isWorkOrderAuthorizationId) {
      setAuthorizeMessage(
        `I hereby authorize the above repair work to be done along with any necessary materials. ${data?.shopName} and its employees may operate the vehicle for purposes of testing, inspection or delivery. An express mechanic’s lien is acknowledged on vehicle to secure the amount of repairs thereto. ${data?.shopName} will not be held responsible for loss or damage to the vehicle or articles left in the vehicle in case of fire, theft, accident or any other cause beyond  ${data?.shopName}'s control.`
      );

      return data;
    }
    setShowLoader(false);
  };
  const EditServiceHandler = (idx) => {
    // handleOpen();
    setOpenServiceModal(true);

    setEditServiceData({ edit: true, index: idx });
  };
  const removeServicesClicked = (idx) => {
    const newdata = [...scheduledServices];
    let newArray = [...newdata.slice(0, idx), ...newdata.slice(idx + 1)];
    setScheduledServices(newArray);
  };

  useEffect(() => {
    let subTotal = 0;
    serviceRef.current = scheduledServices?.map(
      (_, i) => serviceRef.current[i] ?? createRef()
    );

    scheduledServices?.map((service) => {
      if (service?.pricingType == "fixed") {
        subTotal += +service?.fixedPrice;
      } else if (service?.pricingType == PRICE_RANGE) {
        subTotal += +service?.priceMin;
      } else {
        subTotal += +(service?.laborQuantity * service?.laborRate);
        service?.parts?.map((part) => {
          subTotal += +part?.totalAmount;
        });
      }
    });
    console.log("otherServices", otherServices);
    otherServices?.map((service) => {
      if (service?.pricingType == "fixed") {
        subTotal += +service?.fixedPrice;
      } else {
        subTotal += +(service?.labor.qty * service?.labor.ratePerHour);
        service?.parts?.map((part) => {
          subTotal += +part?.totalAmount;
        });
      }
    });

    if (subTotal == 0) {
      totals.discount = 0;
    }
    setTotals({
      subTotal: subTotal,
      taxPercentage: +totals?.taxPercentage,
      discount: +totals?.discount,
      tax:
        +totals?.taxPercentage > 0
          ? ((+subTotal - +totals?.discount) * +totals?.taxPercentage) / 100
          : 0,
      estimatedTotal:
        subTotal -
        +totals?.discount +
        (+totals?.taxPercentage > 0
          ? ((+subTotal - +totals?.discount) * +totals?.taxPercentage) / 100
          : 0),
    });
    if (subTotal > 0 && totals?.discount <= subTotal) {
      setDiscountError(false);
    } else if (subTotal == 0) {
      setDiscountError(false);
    } else {
      setDiscountError(true);
    }
    let localTotals = {
      subTotal: subTotal,
      taxPercentage: +totals?.taxPercentage,
      discount: +totals?.discount,
      tax:
        +totals?.taxPercentage > 0
          ? ((+subTotal - +totals?.discount) * +totals?.taxPercentage) / 100
          : 0,
      estimatedTotal:
        subTotal -
        +totals?.discount +
        (+totals?.taxPercentage > 0
          ? ((+subTotal - +totals?.discount) * +totals?.taxPercentage) / 100
          : 0),
    };

    setAuthrorizeDataPdf({
      ...authorizeDataPdf,
      totalServices: scheduledServices.length + otherServices.length,

      services: [...scheduledServices, ...otherServices],

      subTotal: subTotal,
      discount: totals.discount,
      tax:
        +totals?.taxPercentage > 0
          ? ((+subTotal - +totals?.discount) * +totals?.taxPercentage) / 100
          : 0,
      total:
        subTotal -
        +totals?.discount +
        (+totals?.taxPercentage > 0
          ? ((+subTotal - +totals?.discount) * +totals?.taxPercentage) / 100
          : 0),
    });
    console.log("insideeeeee", scheduledServices, otherServices);
    if (isWorkOrderAuthorizationId) matchBothStates(localTotals);
  }, [scheduledServices, otherServices]);
  console.log("authorizeDataPdf", authorizeDataPdf);
  useEffect(() => {
    if (isWorkOrderAuthorizationId) matchBothStates(totals);
  }, [totals]);
  const handleSubmit = async ({ print, reauthorise }) => {
    const findPriceRangeServiceIndex = scheduledServices.findIndex(
      (d) => d.pricingType == PRICE_RANGE
    );
    const zeroPriceServiceIndex = scheduledServices.findIndex(
      (d) => d.pricingType == "fixed" && d.fixedPrice == 0
    );
    console.log("scheduledServices", scheduledServices);
    if (findPriceRangeServiceIndex !== -1) {
      toast.error(errorMsgPriceRangeService);
      // setTimeout(() => {
      const offset = -80; // Adjust this value to the desired margin
      const elementTop =
        serviceRef.current[
          findPriceRangeServiceIndex
        ].current.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: elementTop + offset, behavior: "smooth" });
      setAnimatedCSSError("animatedBorderError");
      setTimeout(() => {
        setAnimatedCSSError("");
      }, [1500]);
    }
    if (zeroPriceServiceIndex !== -1) {
      toast.error(zeroPriceError);
      // setTimeout(() => {
      const offset = -80; // Adjust this value to the desired margin
      const elementTop =
        serviceRef.current[
          zeroPriceServiceIndex
        ].current.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: elementTop + offset, behavior: "smooth" });
      setAnimatedCSSError("animatedBorderError");
      setTimeout(() => {
        setAnimatedCSSError("");
      }, [1500]);
    }
    if (zeroPriceServiceIndex != -1 || findPriceRangeServiceIndex != -1) {
      return;
    }
    if (discountError) {
      const offset = -80; // Adjust this value to the desired margin

      const elementTop =
        discountRef.current.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: elementTop + offset, behavior: "smooth" });

      return;
    }
    const payload = {
      appointment: id,
      authorizationMessage: authorizeMessage,
      services: [],
      action: "",
      subTotal: totals.subTotal,
      discount: totals?.discount,
      taxAmount: totals?.tax,
      taxPercentage: totals?.taxPercentage,
      total: totals?.estimatedTotal,
      isEdited: !isbothStateMatches,
    };

    if (reauthorise) {
      payload.action = "reauthorization";
    } else if (print) {
      payload.action = "print";
    } else if (isWorkOrderAuthorizationId && !print && workOrderData?.isSent) {
      payload.action = "resend";
    }
    //  (
    //   !isWorkOrderAuthorizationId &&
    //   !print &&
    //   !workOrderData?.isSent
    // )
    else {
      payload.action = "send";
    }

    if (isWorkOrderAuthorizationId) {
      payload._id = isWorkOrderAuthorizationId;
    }
    console.log(
      "🚀 ~ authorizeDataPdf?.services?.map ~ authorizeDataPdf:",
      authorizeDataPdf
    );
    authorizeDataPdf?.services?.map((item) => {
      const data = {
        service: item?.service?._id,
        pricingType: item?.pricingType,
        // "labor": {
        //   "qty": 2,
        //   "cost": 50,
        //   "totalAmount": 100
        // },
        // "parts": [
        //   {
        //     "partName": "Oil Filter",
        //     "partNumber": "OF-123",
        //     "description": "High-quality oil filter",
        //     "partsProvider": "oem",
        //     "qty": 1,
        //     "cost": 15,
        //     "totalAmount": 15
        //   }
        // ],
        // fixedPrice: item?.fixedPrice,
      };
      if (Object.keys(item).includes("approvalStatus")) {
        data.approvalStatus = item?.approvalStatus;
      }
      if (Object.keys(item).includes("newAdded")) {
        data.newAdded = item?.newAdded;
      }

      if (item?.pricingType == "fixed") {
        data.fixedPrice = item?.fixedPrice;
      }
      if (item?.pricingType == "labor_&_parts") {
        data.labor = {
          qty: item?.laborQuantity,
          cost: item?.laborRate,
          totalAmount: +item?.laborQuantity * +item?.laborRate,
        };
        data.parts = [];
        item.parts?.map((part) => {
          data.parts.push({
            partName: part?.partName,
            partNumber: part?.partNumber,
            description: part?.description,
            partsProvider: part?.partsProvider,
            qty: part?.qty,
            cost: part?.cost,
            totalAmount: part?.totalAmount,
          });
        });
      }
      payload.services.push(data);
    });

    console.log("payload", payload);
    console.log("authorizeDataPdf", authorizeDataPdf);
    setShowLoader(true);

    const resp = await dispatch(createWorkOrderAuthorization(payload));

    console.log("resp", resp);
    if (resp) {
      setRespondedBtn("pending");
      console.log("resp", resp);

      // navigate(`/workOrderAuthorization/${resp?.data?._id}`, { replace: true });
      fetchAppointmentDetails();
      if (print) {
        toast.success("Changes saved");
        window.open(resp?.data?.pdfUrl, "");
      } else {
        toast.success(`Work order authorization sent to customer.`);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }

      // workOrderAuthrizationDetail(resp?.data?._id);
    } else {
      setShowLoader(false);
    }
    // setShowLoader(false);

    // Open pdf in new page
    // window.open(url, "");
  };

  const handleCloseAppointmentTodayModel = () => {
    setOpenAppointmentTodayModel(false);
  };
  const handleCloseUpcomingAppointmentModal = () => {
    setUpcomingAppointmentModel(false);
  };
  const updateStatus = async () => {
    setShowLoader(true);
    const resp = await dispatch(
      updatetWorkOrderAuthorizationStatus(isWorkOrderAuthorizationId, {
        status: "approved",
      })
    );
    if (resp) {
      toast.success(`Work order authorized successfully`);
      setWorkOrderStatus("approved");
      setWorkOrderData(resp?.data);
      workOrderAuthrizationDetail(isWorkOrderAuthorizationId);
    }
    setShowLoader(false);
  };
  const workOrderAuthrizationDetail = async (id, detailView = {}) => {
    setShowLoader(true);
    const resp = await dispatch(getWorkOrderAuthorization(id));
    if (resp) {
      console.log("respp", resp);
      setWorkOrderStatus(resp?.data?.status);
      setIsWorkorderAuthorizationId(resp?.data?._id);
      setIsBothStateMatches(true);
      if (Object.keys(detailView).length > 0) {
        setDefaultData(resp?.data, "workOrder", detailView);
      }
      setWorkOrderData(resp?.data);
      setAuthorizeMessage(resp?.data?.authorizationMessage);
    }
    setShowLoader(false);
  };
  const handleOnlyPrintPdf = () => {
    window.open(workOrderData?.pdfUrl, "");
  };

  const matchBothStates = (localTotals) => {
    // console.log("match localTotals", localTotals);
    // console.log("match scheduledServices", scheduledServices);
    // console.log("match otherServices", otherServices);
    // console.log("match editedServiceData", editedServiceData);
    // console.log("match workOrderData", workOrderData);
    // console.log("match total", totals);

    let finalServices = workOrderData?.services?.filter(
      (d) => d.approvalStatus != "waiting" && d.approvalStatus != "approved"
    );
    let allServices = [...scheduledServices];

    if (
      localTotals.estimatedTotal != workOrderData?.total ||
      localTotals?.subTotal != workOrderData?.subTotal ||
      localTotals?.discount != workOrderData?.discount ||
      localTotals?.taxPercentage != workOrderData?.taxPercentage ||
      finalServices?.length != allServices.length ||
      authorizeMessage !== workOrderData?.authorizationMessage
    ) {
      setIsBothStateMatches(false);
    } else {
      let allServicesMatch = true;

      for (let service of finalServices) {
        console.log("service", service);
        const found = allServices.find(
          (s) => s.service?._id === service?.service?._id
        );
        console.log("service found", found);

        if (!found) {
          console.log("Service not found in allServices");
          allServicesMatch = false;
          break;
        } else {
          if (service?.pricingType == FIXED_PRICE) {
            if (service.fixedPrice != found.fixedPrice) {
              allServicesMatch = false;
              break;
            }
          } else {
            if (
              service.labor.qty != found.laborQuantity ||
              service.labor.cost != found.laborRate
            ) {
              allServicesMatch = false;
              break;
            }

            if (service.parts.length !== found.parts.length) {
              allServicesMatch = false;
              break;
            }

            for (let part of service.parts) {
              console.log("part", part, found.parts);
              const partFound = found.parts.find((p) => p._id == part?._id);
              console.log("partFound", partFound);

              if (!partFound) {
                allServicesMatch = false;
                break;
              } else {
                console.log("partttt", part, partFound);
                console.log("partttt 11", part.cost);
                console.log("partttt 22", partFound.cost);

                if (
                  part.cost != partFound.cost ||
                  part.description != partFound.description ||
                  part.partName != partFound.partName ||
                  part.partNumber != partFound.partNumber ||
                  part.partsProvider != partFound.partsProvider ||
                  part.qty != partFound.qty
                ) {
                  allServicesMatch = false;
                  break;
                }
              }
            }

            // Break the outer loop if parts mismatch
            if (!allServicesMatch) break;
          }
        }
      }

      if (allServicesMatch) {
        console.log("All services match");
        setIsBothStateMatches(true);
      } else {
        console.log("Service mismatch detected");
        setIsBothStateMatches(false);
      }
    }
    // schduled service and api response services
    // totals match
  };

  console.log("isbothmatches", isbothStateMatches, workOrderStatus);
  useEffect(() => {
    if (
      isWorkOrderAuthorizationId &&
      authorizeMessage !== workOrderData.authorizeMessage
    )
      setIsBothStateMatches(false);
    else {
      setIsBothStateMatches(true);
    }
  }, [authorizeMessage]);

  // console.log("appointmentData", appointmentData);
  // console.log("scheduledServices", scheduledServices);
  // console.log("otherservices", otherServices);
  // console.log("authorizeDataPdf", authorizeDataPdf);

  return (
    <ThemeProvider theme={theme}>
      <Box
        padding={"32px 24px"}
        sx={{ width: "fit-content", minWidth: "100%" }}
      >
        <SuccessMessageModal
          message={successMessage}
          open={openSuccessModal}
          setOpenSuccessModal={setOpenSuccessModal}
        />
        <Grid
          container
          sx={{ padding: "16px 0px", justifyContent: "space-between" }}
          gap={"0px"}
        >
          <Grid
            item
            xl={6}
            lg={openSidebar ? 6 : 6}
            md={openSidebar ? 12 : 6}
            sm={12}
            xs={12}
          >
            <div
              role="presentation"
              //    onClick={handleClick}
            >
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  to="/appointment/today"
                  style={{
                    color: "black",
                    opacity: "0.6",
                    textDecoration: "none",
                  }}
                >
                  Appointments
                </Link>
                <Link
                  style={{
                    color: "black",
                    opacity: "0.6",
                    textDecoration: "none",
                  }}
                >
                  Work Order Authorization
                </Link>
              </Breadcrumbs>
            </div>

            <Typography
              variant="h4"
              sx={{ fontWeight: "500", marginTop: "5px" }}
            >
              {isWorkOrderAuthorizationId == ""
                ? "Work Order Authorization"
                : workOrderData?.workOrderNumber +
                  " - " +
                  workOrderData?.customer?.fullName}
            </Typography>
          </Grid>

          <Grid
            item
            xl={6}
            lg={openSidebar ? 12 : 6}
            md={openSidebar ? 12 : 6}
            justifyContent={"end"}
            display={"flex"}
            alignItems={"center"}
            gap={2}
          >
            {isWorkOrderAuthorizationId !== "" &&
              workOrderStatus == "pending" &&
              isbothStateMatches == true && (
                <Grid item>
                  <Button
                    variant="outlined"
                    // color="primary"
                    onClick={() => {
                      updateStatus();
                      //   details();
                    }}
                    size="large"
                    sx={{
                      background: "#30D158",
                      color: "white",
                      borderColor: "#30D158",
                      ":hover": {
                        borderColor: "green", // theme.palette.primary.main
                        color: "white",
                        background: "green  ",
                      },
                    }}
                    //   className="btn-text-size-manage"
                  >
                    MARK AS AUTHORIZED
                  </Button>
                </Grid>
              )}
            {workOrderStatus == "approved" && isbothStateMatches && (
              <Grid item>
                <Button
                  variant="outlined"
                  // color="primary"
                  // disabled
                  onClick={() => {
                    //   details();
                  }}
                  size="large"
                  sx={{
                    background: "rgba(48, 209, 88, 0.2)",
                    color: "rgba(0, 0, 0, 0.3)",
                    fontWeight: 500,
                    borderWidth: 0,
                    ":hover": {
                      background: "rgba(48, 209, 88, 0.2)",
                      cursor: "not-allowed",
                      borderWidth: 0,
                    },
                  }}
                  //   className="btn-text-size-manage"
                >
                  AUTHORIZED
                </Button>
              </Grid>
            )}
            {workOrderStatus == "declined" && isbothStateMatches && (
              <Grid item>
                <Button
                  variant="outlined"
                  // color="primary"
                  // disabled
                  onClick={() => {
                    //   details();
                  }}
                  size="large"
                  sx={{
                    background: "rgba(255, 39, 39, 0.2)",
                    color: "rgba(0, 0, 0, 0.3)",
                    fontWeight: 500,
                    borderWidth: 0,
                    ":hover": {
                      background: "rgba(255, 39, 39, 0.2)",
                      cursor: "not-allowed",
                      borderWidth: 0,
                    },
                  }}
                  //   className="btn-text-size-manage"
                >
                  DECLINED
                </Button>
              </Grid>
            )}
            <Grid item>
              {/* <PDFDownloadLink
                document={<AuthorizationPDF data={authorizeDataPdf} />}
                fileName={
                  authorizeDataPdf?.customerName +
                  " work-order-authorization.pdf"
                }
              >
                {
                  ({ blob, url, loading, error }) => (
                    // loading ? (
                    //   "Loading document..."
                    // ) : (
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      onClick={() => {
                        // handlePrint(url);
                        handleSubmit({ print: true });

                        //   details();
                        // window.open
                      }}
                    >
                   
                      PRINT
        
                    </Button>
                  )
                  // )
                }
              </PDFDownloadLink> */}
              <Button
                variant="outlined"
                color="primary"
                size="large"
                onClick={() => {
                  // if (
                  //   (["approved", "declined"].includes(workOrderStatus) &&
                  //     isbothStateMatches) ||
                  //   (workOrderStatus == "pending" && isbothStateMatches)
                  // )
                  //   handleOnlyPrintPdf();
                  // else
                  handleSubmit({ print: true });
                }}
              >
                PRINT
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                aria-controls={
                  opeMoreAction ? "demo-positioned-menu" : undefined
                }
                aria-haspopup="true"
                aria-expanded={opeMoreAction ? "true" : undefined}
                onClick={(e) => setAnchorEl(e.currentTarget)}
                //   className="btn-text-size-manage"
              >
                MORE ACTIONS
              </Button>
              <Menu
                style={{ width: "fitContent", top: 48 }}
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={opeMoreAction}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    // setOpenAppointmentTodayModel(true);
                    if (
                      dayjs(appointmentData?.dropOffDateTime).format(
                        "YYYY-MM-DD"
                      ) > dayjs().format("YYYY-MM-DD")
                    )
                      setUpcomingAppointmentModel(true);
                    else {
                      setOpenAppointmentTodayModel(true);
                    }
                  }}
                >
                  View Appointment
                </MenuItem>
                {workOrderStatus == "pending" &&
                  isbothStateMatches &&
                  isWorkOrderAuthorizationId &&
                  workOrderData?.isSent && (
                    <MenuItem
                      onClick={() => {
                        setAnchorEl(null);
                        handleSubmit({
                          print: false,
                          reauthorise: false,
                        });
                      }}
                    >
                      Send Reminder
                    </MenuItem>
                  )}

                {isWorkOrderAuthorizationId && (
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      setOpenHistory(true);
                    }}
                  >
                    View History
                  </MenuItem>
                )}
              </Menu>
            </Grid>
          </Grid>
        </Grid>

        <Box
          sx={{
            mt: "17px",
            padding: "32px",
          }}
          bgcolor={"white"}
          borderRadius={2}
          boxShadow={
            "0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)"
          }
        >
          {workOrderStatus == "approved" && isbothStateMatches && (
            <Grid container item mb={2}>
              <Alert
                sx={{
                  width: "100%",
                  fontSize: "16px",
                  fontWeight: 500,
                  // color: "#FF2727",
                  // background: "#FFB3B3",
                }}
                // severity="error"
                // icon={<InfoOutlined sx={{ color: "#FF2727" }} />}
              >
                Authorized
              </Alert>
            </Grid>
          )}
          {workOrderStatus == "declined" && isbothStateMatches && (
            <Grid container item mb={2}>
              <Alert
                sx={{
                  width: "100%",
                  fontSize: "16px",
                  fontWeight: 500,
                  // color: "#FF2727",
                  // background: "#FFB3B3",
                }}
                severity="error"
                // icon={<InfoOutlined sx={{ color: "#FF2727" }} />}
              >
                Declined
              </Alert>
            </Grid>
          )}
          {workOrderStatus == "pending" &&
            workOrderData?.isSent &&
            isbothStateMatches && (
              <Grid container item mb={2}>
                <Alert
                  sx={{
                    width: "100%",
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "black",
                    background: "rgb(176, 189, 252)",
                  }}
                  // severity="error"
                  icon={<CheckCircleOutlineOutlined sx={{ color: "black" }} />}
                >
                  Sent
                </Alert>
              </Grid>
            )}
          {/* {respondedBtn == "" && (
            <Alert
              variant="filled"
              severity="info"
              sx={{
                background: "#FDD79C",
                color: "black",
                fontSize: "16px",
                mb: "24px",
              }}
            >
              Pending
            </Alert>
          )} */}
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography variant="h5">Scheduled Services</Typography>

            <Button
              variant="contained"
              size="large"
              color="secondary"
              onClick={() => setOpenServiceModal(true)}
            >
              Add Service
            </Button>
          </Box>
          <Box mb={3}>
            {scheduledServices?.map((service, index) => (
              <Grid
                container
                item
                style={{
                  color: "black",
                  marginTop: "24px",
                }}
                ref={serviceRef.current[index]}
                key={index}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  bgcolor={"#002394"}
                  borderRadius={"4px 4px 0 0 "}
                >
                  <Typography
                    variant="h6"
                    padding={"16px"}
                    color={"white"}
                    bgcolor={"secondary"}
                  >
                    {service?.service?.name}
                  </Typography>
                </Grid>
                <Grid container item>
                  <Grid item xl={12} md={12} sm={12} xs={12} boxShadow={0}>
                    <Table aria-label="caption table" style={{ boxShadow: 0 }}>
                      <TableHead
                        style={{
                          backgroundColor: "#f4f6f8",
                          fontWeight: 500,
                        }}
                      >
                        <TableRow>
                          <TableCell width={"9.6%"}>
                            <Typography variant="table/header" fontWeight={500}>
                              {" "}
                              No.
                            </Typography>
                          </TableCell>
                          <TableCell> Description</TableCell>
                          <TableCell width={"9.6%"}> Quantity</TableCell>
                          <TableCell width={"9.6%"}> Cost</TableCell>
                          <TableCell width={"9.6%"}> Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {service?.pricingType == FIXED_PRICE ||
                        service?.pricingType == PRICE_RANGE ? (
                          <TableRow
                            key={1}
                            className={
                              service?.pricingType == PRICE_RANGE
                                ? animatedCSSError
                                : service?.fixedPrice == "0"
                                ? animatedCSSError
                                : ""
                            }
                          >
                            <TableCell component="th" scope="row">
                              1
                            </TableCell>
                            <TableCell align="left">
                              {service?.service?.name}
                            </TableCell>
                            <TableCell align="left">1</TableCell>{" "}
                            <TableCell
                              align="left"
                              className={
                                service.pricingType == PRICE_RANGE ||
                                (service.pricingType == FIXED_PRICE &&
                                  service.fixedPrice == "")
                                  ? "priceRangeError"
                                  : ""
                              }
                            >
                              {service?.pricingType == FIXED_PRICE &&
                                service.fixedPrice != "0" && (
                                  <FormatNumber
                                    inputValue={service?.fixedPrice}
                                  />
                                )}
                              {service?.pricingType == PRICE_RANGE && (
                                <Tooltip
                                  title={
                                    <div className="tooltipStyle">
                                      {errorMsgPriceRangeService}
                                    </div>
                                  }
                                  placement="top"
                                >
                                  <div className="pricerangeCell">
                                    <div className="minMaxPrice">
                                      <FormatNumber
                                        inputValue={service?.priceMin}
                                      />
                                      {" - "}
                                      <FormatNumber
                                        inputValue={service?.priceMax}
                                      />
                                    </div>
                                    <ErrorIcon />
                                  </div>
                                </Tooltip>
                              )}
                              {service?.pricingType == FIXED_PRICE &&
                                service.fixedPrice == "0" && (
                                  <Tooltip
                                    title={
                                      <div className="tooltipStyle">
                                        {zeroErroForTable}
                                      </div>
                                    }
                                    placement="top"
                                  >
                                    <div className="pricerangeCell">
                                      <div className="minMaxPrice">
                                        <FormatNumber
                                          inputValue={service?.fixedPrice}
                                        />
                                      </div>
                                      <ErrorIcon />
                                    </div>
                                  </Tooltip>
                                )}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={
                                service.pricingType == PRICE_RANGE ||
                                (service.pricingType == FIXED_PRICE &&
                                  service.fixedPrice == "")
                                  ? "priceRangeError"
                                  : ""
                              }
                            >
                              {service?.pricingType == FIXED_PRICE &&
                                service.fixedPrice != "0" && (
                                  <FormatNumber
                                    inputValue={service?.fixedPrice}
                                  />
                                )}
                              {service?.pricingType == PRICE_RANGE && (
                                <Tooltip
                                  title={
                                    <div className="tooltipStyle">
                                      {errorMsgPriceRangeService}
                                    </div>
                                  }
                                  placement="top"
                                >
                                  <div className="pricerangeCell">
                                    <div className="minMaxPrice">
                                      <FormatNumber
                                        inputValue={service?.priceMin}
                                      />
                                      {" - "}
                                      <FormatNumber
                                        inputValue={service?.priceMax}
                                      />
                                    </div>
                                    <ErrorIcon />
                                  </div>
                                </Tooltip>
                              )}
                              {service?.pricingType == FIXED_PRICE &&
                                service.fixedPrice == "0" && (
                                  <Tooltip
                                    title={
                                      <div className="tooltipStyle">
                                        {zeroErroForTable}
                                      </div>
                                    }
                                    placement="top"
                                  >
                                    <div className="pricerangeCell">
                                      <div className="minMaxPrice">
                                        <FormatNumber
                                          inputValue={service?.fixedPrice}
                                        />
                                      </div>
                                      <ErrorIcon />
                                    </div>
                                  </Tooltip>
                                )}
                            </TableCell>
                          </TableRow>
                        ) : (
                          <>
                            <TableRow key={1}>
                              <TableCell component="th" scope="row">
                                1
                              </TableCell>
                              <TableCell align="left">Labor</TableCell>
                              <TableCell align="left">
                                {service?.laborQuantity}
                              </TableCell>{" "}
                              <TableCell align="left">
                                <FormatNumber inputValue={service?.laborRate} />
                              </TableCell>
                              <TableCell align="left">
                                <FormatNumber
                                  inputValue={
                                    +service?.laborQuantity *
                                    +service?.laborRate
                                  }
                                />
                              </TableCell>
                            </TableRow>
                            {service?.parts?.map((part, index) => (
                              <TableRow key={index + 1}>
                                <TableCell component="th" scope="row">
                                  {index + 2}
                                </TableCell>
                                <TableCell align="left">
                                  {part?.partName}

                                  <br />
                                  <Typography fontSize={"12px"}>
                                    Part Provider:{" "}
                                    {customisePartName(part?.partsProvider)}
                                    {part?.partNumber && (
                                      <>
                                        <br />
                                        Part Number: {part?.partNumber}
                                      </>
                                    )}
                                  </Typography>
                                  {part?.description && (
                                    <Typography
                                      fontSize={"12px"}
                                      // color={"#848484"}
                                      width={"60%"}
                                    >
                                      Description: {part?.description}
                                    </Typography>
                                  )}
                                </TableCell>
                                <TableCell align="left">{part?.qty}</TableCell>{" "}
                                <TableCell align="left">
                                  <FormatNumber inputValue={part?.cost} />
                                </TableCell>
                                <TableCell align="left">
                                  <FormatNumber
                                    inputValue={part?.totalAmount}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </>
                        )}
                      </TableBody>
                    </Table>
                    <Grid
                      container
                      item
                      justifyContent={"end"}
                      padding={"24px"}
                    >
                      <Grid item>
                        <Stack direction="row" spacing={2}>
                          <Button
                            variant="contained"
                            startIcon={<Edit />}
                            onClick={() => {
                              EditServiceHandler(index);
                              setEditedServiceData(service);
                            }}
                            className="btn-text-size-manage"
                          >
                            EDIT SERVICE
                          </Button>

                          <Button
                            variant="contained"
                            color="error"
                            startIcon={<Delete />}
                            className="btn-text-size-manage"
                            onClick={() => {
                              removeServicesClicked(index);
                            }}
                          >
                            REMOVE SERVICE
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Box>
          {appointmentData?.services?.filter(
            (d) =>
              d.approvalStatus == "waiting" || d.approvalStatus == "approved"
          )?.length > 0 && (
            <Box display={"flex"} justifyContent={"space-between"} mt={7}>
              <Typography variant="h5"> Services Added</Typography>
            </Box>
          )}
          {appointmentData?.services
            ?.filter(
              (d) =>
                d.approvalStatus == "waiting" || d.approvalStatus == "approved"
            )
            ?.map((service, index) => {
              return (
                <Grid
                  container
                  item
                  style={{
                    color: "black",
                    marginTop: "24px",
                  }}
                  key={index}
                >
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    bgcolor={"#002394"}
                    borderRadius={"4px 4px 0 0 "}
                  >
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      padding={"16px"}
                    >
                      <Typography
                        variant="h6"
                        color={"white"}
                        bgcolor={"secondary"}
                      >
                        {service?.serviceName}
                      </Typography>
                      <Chip
                        label={
                          service?.approvalStatus == "waiting"
                            ? "Waiting"
                            : "Approved"
                        }
                        style={{
                          background:
                            service?.approvalStatus == "waiting"
                              ? "#FFD60A"
                              : "#30D158",
                          color:
                            service?.approvalStatus == "waiting"
                              ? "black"
                              : "white",
                          padding: "3px 0px",
                        }}
                      ></Chip>
                    </Box>
                  </Grid>
                  <Grid container item>
                    <Grid item xl={12} md={12} sm={12} xs={12} boxShadow={0}>
                      <Table
                        aria-label="caption table"
                        style={{ boxShadow: 0 }}
                      >
                        <TableHead
                          style={{
                            backgroundColor: "#f4f6f8",
                            fontWeight: 500,
                          }}
                        >
                          <TableRow>
                            <TableCell width={"9.6%"}>
                              <Typography
                                variant="table/header"
                                fontWeight={500}
                              >
                                {" "}
                                No.
                              </Typography>
                            </TableCell>
                            <TableCell> Description</TableCell>
                            <TableCell width={"9.6%"}> Quantity</TableCell>
                            <TableCell width={"9.6%"}> Cost</TableCell>
                            <TableCell width={"9.6%"}> Amount</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {service?.pricingType == FIXED_PRICE ? (
                            <TableRow key={1}>
                              <TableCell component="th" scope="row">
                                1
                              </TableCell>
                              <TableCell align="left">
                                {service?.serviceName}
                              </TableCell>
                              <TableCell align="left">1</TableCell>{" "}
                              <TableCell align="left">
                                <FormatNumber
                                  inputValue={service?.fixedPrice}
                                />
                              </TableCell>
                              <TableCell align="left">
                                <FormatNumber
                                  inputValue={service?.fixedPrice}
                                />
                              </TableCell>
                            </TableRow>
                          ) : (
                            <>
                              <TableRow key={1}>
                                <TableCell component="th" scope="row">
                                  1
                                </TableCell>
                                <TableCell align="left">Labor</TableCell>
                                <TableCell align="left">
                                  {service?.labor?.qty}
                                </TableCell>{" "}
                                <TableCell align="left">
                                  <FormatNumber
                                    inputValue={service?.labor?.ratePerHour}
                                  />
                                </TableCell>
                                <TableCell align="left">
                                  <FormatNumber
                                    inputValue={service?.labor?.totalAmount}
                                  />
                                </TableCell>
                              </TableRow>
                              {service?.parts?.map((part, index) => (
                                <TableRow key={index + 1}>
                                  <TableCell component="th" scope="row">
                                    {index + 2}
                                  </TableCell>
                                  <TableCell align="left">
                                    {part?.partName}

                                    <br />
                                    <Typography fontSize={"12px"}>
                                      Part Provider:{" "}
                                      {customisePartName(part?.partsProvider)}
                                      <br />
                                      Part Number: {part?.partNumber}
                                    </Typography>
                                    <Typography
                                      fontSize={"12px"}
                                      // color={"#848484"}
                                      width={"60%"}
                                    >
                                      Description: {part?.description}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="left">
                                    {part?.qty}
                                  </TableCell>{" "}
                                  <TableCell align="left">
                                    <FormatNumber inputValue={part?.cost} />
                                  </TableCell>
                                  <TableCell align="left">
                                    <FormatNumber
                                      inputValue={part?.totalAmount}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </>
                          )}
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          <Grid
            container
            item
            justifyContent={"end"}
            padding={"56px 23px 24px 24px"}
          >
            <Grid item>
              <Stack direction="row" spacing={2}>
                <Stack direction="column">
                  <TextField
                    variant="outlined"
                    size="medium"
                    label="Discount($)"
                    color={discountError ? "error" : "primary"}
                    value={totals?.discount}
                    disabled={totals?.subTotal == 0 ? true : false}
                    onChange={(e) => {
                      if (!isNaN(e.target.value)) {
                        setTotals({
                          discount: +e.target.value,
                          tax:
                            totals?.taxPercentage > 0
                              ? ((totals?.subTotal - +e.target.value) *
                                  totals?.taxPercentage) /
                                100
                              : 0,

                          estimatedTotal:
                            totals?.subTotal -
                            e.target.value +
                            (totals?.taxPercentage > 0
                              ? ((totals?.subTotal - +e.target.value) *
                                  totals?.taxPercentage) /
                                100
                              : 0),
                          taxPercentage:
                            totals?.taxPercentage > 0
                              ? totals?.taxPercentage
                              : 0,
                          subTotal: totals?.subTotal,
                        });
                        setAuthrorizeDataPdf({
                          ...authorizeDataPdf,

                          subTotal: totals?.subTotal,
                          discount: +e.target.value,
                          tax:
                            totals?.taxPercentage > 0
                              ? ((totals?.subTotal - +e.target.value) *
                                  totals?.taxPercentage) /
                                100
                              : 0,
                          total:
                            totals?.subTotal -
                            e.target.value +
                            (totals?.taxPercentage > 0
                              ? ((totals?.subTotal - +e.target.value) *
                                  totals?.taxPercentage) /
                                100
                              : 0),
                        });
                        if (e.target.value > totals?.subTotal) {
                          setDiscountError(true);
                        } else {
                          setDiscountError(false);
                        }
                      } else {
                        toast.error("Please Enter Number value");
                      }
                    }}
                    error={discountError}
                    ref={discountRef}
                  />
                  {discountError && (
                    <p
                      style={{
                        color: "#d32f2f",
                        fontSize: "0.75rem",
                        letterSpacing: "0.03333em",
                        lineHeight: "1.66",
                        marginTop: "3px",
                      }}
                    >
                      Discount can not be more than subtotal amount
                    </p>
                  )}
                </Stack>
                <TextField
                  variant="outlined"
                  size="medium"
                  value={totals?.taxPercentage}
                  label="Taxes (%)"
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      setTotals({
                        taxPercentage: +e.target.value,
                        tax:
                          ((totals?.subTotal - totals?.discount) *
                            e.target.value) /
                          100,
                        estimatedTotal:
                          totals?.subTotal -
                          totals?.discount +
                          ((totals?.subTotal - totals?.discount) *
                            +e.target.value) /
                            100,
                        discount: totals?.discount,
                        subTotal: totals?.subTotal,
                      });
                      setAuthrorizeDataPdf({
                        ...authorizeDataPdf,

                        tax:
                          ((totals?.subTotal - totals?.discount) *
                            e.target.value) /
                          100,
                        total:
                          totals?.subTotal -
                          totals?.discount +
                          ((totals?.subTotal - totals?.discount) *
                            +e.target.value) /
                            100,
                      });
                    } else {
                      toast.error("Please Enter Number value");
                    }
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid
            container
            item
            justifyContent={"end"}
            padding={"0px 23px 24px 24px"}
          >
            <Grid item>
              <Stack direction="row" spacing={2}>
                <Box textAlign={"end"}>
                  <Typography mb={1}>Sub Total</Typography>
                  <Typography mb={1}>Discount</Typography>
                  <Typography mb={1}>Tax</Typography>
                  <Typography>Total</Typography>
                </Box>
                <Box textAlign={"end"}>
                  <Typography mb={1}>
                    <FormatTotalNumbers inputValue={totals?.subTotal} />
                  </Typography>
                  <Typography mb={1}>
                    <FormatTotalNumbers inputValue={totals?.discount} />
                  </Typography>
                  <Typography mb={1}>
                    <FormatTotalNumbers inputValue={totals?.tax} />
                  </Typography>
                  <Typography>
                    <FormatTotalNumbers inputValue={totals?.estimatedTotal} />
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            mt: "32px",
            //   height: "500px",

            padding: "24px",
          }}
          bgcolor={"white"}
          borderRadius={2}
          boxShadow={
            "0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)"
          }
        >
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography variant="h5">Authorization</Typography>

            {(workOrderStatus == "pending" ||
              (["approved", "declined"].includes(workOrderStatus) &&
                !isbothStateMatches)) && (
              <Button
                variant="contained"
                size="large"
                color="primary"
                startIcon={<ModeEditIcon />}
                onClick={() => setOpenAuthorizeModel(true)}
              >
                EDIT AUTHORIZATION
              </Button>
            )}
          </Box>
          <Grid
            container
            item
            style={{
              color: "black",
              marginTop: "20px",
            }}
          >
            <Grid
              item
              lg={
                workOrderStatus == "pending" ||
                (["approved", "declined"].includes(workOrderStatus) &&
                  !isbothStateMatches)
                  ? 6.53
                  : 12
              }
              md={
                workOrderStatus == "pending" ||
                (["approved", "declined"].includes(workOrderStatus) &&
                  !isbothStateMatches)
                  ? 6.53
                  : 12
              }
              sm={12}
              xs={12}
            >
              {/* <Typography variant="body1">{authorizeMessage}</Typography> */}
              <Typography variant="body1">
                {" "}
                <div
                  contentEditable="false"
                  dangerouslySetInnerHTML={{ __html: authorizeMessage }}
                ></div>
              </Typography>

              {/* Once authorization authrized then have to display */}
              {workOrderData?.approvalRole != "shop_owner" &&
                ["approved", "declined"].includes(workOrderStatus) &&
                isbothStateMatches && (
                  <div style={{ fontWeight: 500 }}>
                    {workOrderData?.signature && (
                      <div className="signatureBox">
                        {/* {workOrderStatus != "pending" && ( */}
                        <img src={workOrderData?.signature} alt="signature" />
                        {/* )} */}
                      </div>
                    )}
                    <div style={{ marginTop: "14px" }}>
                      {workOrderData?.approvedBy && (
                        <>
                          Authorized By:
                          <br />
                          {workOrderData?.approvedBy?.fullName}
                        </>
                      )}
                      {workOrderData?.declinedBy && (
                        <>
                          Declined By:
                          <br />
                          {workOrderData?.declinedBy?.fullName}
                        </>
                      )}

                      <Typography variant="body1" fontWeight={500}>
                        {
                          workOrderStatus == "pending"
                            ? "Date"
                            : workOrderStatus == "approved"
                            ? dayjs(workOrderData?.approvedAt).format(
                                "MMMM DD, YYYY hh:mm A"
                              )
                            : // new Date(workOrderData?.approvedAt).toLocaleString()
                              dayjs(workOrderData?.declinedAt).format(
                                "MMMM DD, YYYY hh:mm A"
                              )
                          // new Date(
                          //   workOrderData?.declinedAt
                          // ).toLocaleString()
                        }
                      </Typography>
                    </div>
                  </div>
                )}
            </Grid>
          </Grid>
        </Box>
        {!workOrderData?.isSent && workOrderStatus == "pending" && (
          <Box
            sx={{
              mt: "32px",
              //   height: "500px",
              // padding: "32px",
              display: "flex",
              justifyContent: "end",
            }}
            // bgcolor={"white"}
            // borderRadius={2}
            // boxShadow={
            //   "0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)"
            // }
            // elevation={2}
          >
            <Button
              size="large"
              variant="contained"
              color="secondary"
              onClick={() => {
                handleSubmit({
                  print: false,
                  reauthorise: false,
                });
              }}
            >
              {"Send"}
              {/* {isWorkOrderAuthorizationId && workOrderData?.isSent
                ? "Resend"
                : "Send"} */}
            </Button>
          </Box>
        )}
        {
          // ["approved", "declined"].includes(workOrderStatus) &&
          isWorkOrderAuthorizationId &&
            !isbothStateMatches &&
            (["approved", "declined"].includes(workOrderStatus) ||
              (workOrderData?.isSent && workOrderStatus == "pending")) && (
              // workOrderData.isSent &&
              <Box
                sx={{
                  mt: "32px",
                  //   height: "500px",
                  // padding: "32px",
                  display: "flex",
                  justifyContent: "end",
                }}
                // bgcolor={"white"}
                // borderRadius={2}
                // boxShadow={
                //   "0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)"
                // }
                // elevation={2}
              >
                <Button
                  size="large"
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    handleSubmit({ print: false, reauthorise: true })
                  }
                >
                  Request Reauthorization
                </Button>
              </Box>
            )
        }

        <EditAuthorizationModel
          open={openEditAuthorizeModel}
          setOpen={setOpenAuthorizeModel}
          message={authorizeMessage}
          setMessage={setAuthorizeMessage}
        />
        <ServiceModal
          open={openServiceModel}
          setOpenServiceModal={setOpenServiceModal}
          setAllServices={setScheduledServices}
          allServices={scheduledServices}
          editServiceData={editServiceData}
          setEditServiceData={setEditServiceData}
          addedServices={otherServices}
        />
        <AppointmentModel
          open={openAppointmentTodayModel}
          handleClose={handleCloseAppointmentTodayModel}
          data={appointmentData}
          status={appointmentData?.status}
          recallDetailsAPI={fetchAppointmentDetailsForModel}
          show={openODOModel}
          handleStatus={handleAppointmentChangeStatus}
          setAppointmentCompletionOffODo={setAppointmentCompletionOffODo}
          setAppointmentDropOffODo={setAppointmentDropOffODo}
          setUpdateStatus={setUpdateAppointmentStatus}
        />

        {(appointmentData?.status == "waiting_for_drop_off" ||
          appointmentData?.status == "in_progress" ||
          appointmentData?.status == "vehicle_dropped_off") && (
          <AddOdoModal
            open={openODOModel}
            handleClose={() => setOpenODOModel(false)}
            updateStatus={updateAppointmentStatus}
            id={appointmentData?._id}
            handleClose1={() => setOpenODOModel(false)}
            currentStatus={appointmentData?.status}
            handleClose2={() => setOpenODOModel(false)}
            appointmentDropOffODO={appointmentDropOffODO}
            appointmentCompletionOffODO={appointmentCompletionOffODO}
            setAppointmentCompletionOffODo={setAppointmentCompletionOffODo}
            setAppointmentDropOffODo={setAppointmentDropOffODo}
            appointmentView={() => {}}
            recallDetailsAPI={fetchAppointmentDetailsForModel}
          />
        )}
        <ViewUpcomingModal
          handleClose={handleCloseUpcomingAppointmentModal}
          open={upcomingAppointmentModel}
          handleCancelModel={handleCloseUpcomingAppointmentModal}
          upcomingData={appointmentData}
          appointmentId={appointmentData?._id}
          recallDetailsAPI={fetchAppointmentDetailsForModel}
        />
        <HistoryModel id={id} open={openHistory} setOpen={setOpenHistory} />
      </Box>
      {/* <Grid container item xs={12}>
        <PDFDownloadLink
          document={<AuthorizationPDF data={invoiceData} />}
          fileName="invoices.pdf"
        >
          {({ loading }) =>
            loading ? "Loading document..." : "Download Invoice PDF"
          }
        </PDFDownloadLink>
        <PDFViewer width={"100%"} height={"900px"}>
          <AuthorizationPDF data={invoiceData} />
        </PDFViewer>
      </Grid> */}

      <Loader showLoader={showLoader} />
    </ThemeProvider>
  );
}

export default WorkOrderAuthorization;
