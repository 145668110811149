import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import InfiniteScroll from "react-infinite-scroll-component";
import React, { useEffect, useState } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import {
  getNotification,
  markAsReadById,
} from "../../redux/Notification/action";
import { useDispatch, useSelector } from "react-redux";
import CurrentDateToGivenTImeCalculation from "../../components/CurrentDateToGivenTImeCalculation/CurrentDateToGivenTImeCalculation";
import { useNavigate } from "react-router-dom";
import Loader from "../Common/Loader";
import AppointmentRescheduleRequestModal from "../Appointment/AppointmentRescheduleRequestModal";
import AppointmentCancelModel from "../modal/AppointmentCancelModel";
import { requestAppointmentDetails } from "../../redux/Dashboard/action";
import { appointmentDetails } from "../../redux/appointment/action";

function Notifications(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [cardData, setCardData] = useState([]);
  const [currentPages, setCurrentPages] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [appointmentData, setAppointmentData] = useState({});
  const [appointmentid, setAppointmentid] = useState();

  const navigate = useNavigate();
  const limit = 20;
  let notificationList = useSelector(
    (state) => state?.Notification?.notificationData
  );

  useEffect(() => {
    getNotificationList(null, false);
  }, []);

  const getNotificationList = async (currentPage, InfiniteScrollCall) => {
    // if (InfiniteScrollCall == false) setLoading(true);
    setPage(currentPage ? currentPage : 1);
    setLoading(true);
    let res = await dispatch(
      getNotification(limit, currentPage ? currentPage : page, false)
    );
    if (res) {
      setCurrentPages(res.currentPage);
      setTotalPages(res.totalPages);
      setCardData([...cardData, ...res?.data]);

      // res = res?.data;
      // if (currentPage) {
      //   if (currentPage == 1) {
      //     setCardData(res);
      //   } else {
      //   }
      // } else {
      //   if (page == 1) {
      //     setCardData(res);
      //   } else {
      //     setCardData([...cardData, ...res]);
      //   }
      // }
      // if (InfiniteScrollCall == false) setLoading(false);
      setLoading(false);
    }
  };
  const handleCardClick = (item) => {
    readNotification(item?._id);
    if (item?.type == "newEstimateRequest") {
      navigate("/request/estimates");
    }
    if (item?.type == "newAppointmentRequest") {
      navigate("/request/appointments");
    }

    if (item?.type == "approvalRequestResponded") {
      navigate(`/approval/${item?.referenceId}`);
    }

    if (item?.type == "appointmentRescheduleRequest") {
      setAppointmentid(item?.referenceId);
      AppointmentDetails(item?.referenceId, "appointmentRescheduleRequest");
      setOpenRescheduleModal(true);
    }
    if (
      item?.type == "acceptProposeNewTimeAppointment" ||
      item?.type == "acceptRescheduleAppointment" ||
      item?.type == "declineRescheduleAppointment"
    ) {
      navigate(`/appointment/upcoming-appointments`);
    }

    if (item?.type == "cancelAppointment") {
      setAppointmentid(item?.referenceId);
      AppointmentDetails(item?.referenceId, "cancelAppointment");
      setOpenCancelModal(true);
    }
    if (item?.type == "workOrderAuthorizationResponded") {
      navigate(`/appointment/workOrderAuthorization/${item?.appointment?._id}`);
    }
    if (item?.type == "subscriptionPaymentReceived") {
      navigate(`/account-settings/billing`);
    }

    console.log("item", item);
  };
  const handleScroll = () => {
    const isAtBottom =
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight;

    if (isAtBottom && !loading && page < totalPages) {
      // params.page = page + 1;
      setPage(page + 1);
      getNotificationList(page + 1, true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading, page]);

  const readNotification = async (id) => {
    await dispatch(markAsReadById(id));
  };
  const AppointmentDetails = async (id, selectedType) => {
    if (id) {
      setLoading(true);
      let data;
      if (selectedType == "cancelAppointment") {
        data = await dispatch(appointmentDetails(id));
      } else {
        data = await dispatch(requestAppointmentDetails(id));
      }
      if (data) {
        setAppointmentData(data);
      }
      setLoading(false);
    }
  };
  return (
    <>
      <Box padding={"24px 24px 24px 20px"} sx={{ bgcolor: "background.main" }}>
        <Grid container rowGap={3}>
          <Grid container item justifyContent={"space-between"}>
            <Grid item lg={9}>
              <Typography variant="h4">Notifications</Typography>
            </Grid>
          </Grid>

          <Grid container justifyContent={"center"}>
            <Grid item className="notification-wrapper-div">
              <Box>
                {/* {!loading ? (
                cardData && cardData?.length > 0 ? (
                  <InfiniteScroll
                    dataLength={cardData.length}
                    next={() => {
                      setPage(page + 1);
                      getNotificationList(page + 1, true);
                    }}
                    hasMore={currentPages < totalPages}
                    loader={
                      currentPages < totalPages && (
                        <CircularProgress
                          style={{ display: "block", margin: "auto" }}
                        />
                      )
                    }
                  > */}
                {cardData?.map((item, index) => {
                  return (
                    <Card
                      style={{
                        border: "0px, 0px, 1px, 0px",
                        minHeight: "112px",
                        maxHeight: "fit-content",
                        cursor: "pointer",
                      }}
                      sx={{ mb: 1 }}
                      key={index}
                      onClick={() => handleCardClick(item)}
                    >
                      <CardHeader
                        title={
                          <Stack direction="row" justifyContent="space-between">
                            <div
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              {item?.title} <br />
                              <Typography style={{ fontSize: "16px" }}>
                                {item?.message}
                              </Typography>
                            </div>
                            <div>
                              <CircleIcon
                                className={`${
                                  item?.isRead ? "gray-dot" : "green-dot"
                                } common-dot`}
                              />
                            </div>
                          </Stack>
                        }
                      ></CardHeader>
                      <CardContent
                        style={{
                          marginBottom: "10px",
                          paddingTop: "inherit",
                          fontSize: "14px",
                        }}
                      >
                        {CurrentDateToGivenTImeCalculation(item?.createdAt)}
                      </CardContent>
                    </Card>
                  );
                })}
                {/* </InfiniteScroll>
                ) : (
                  // <CircularProgress
                  //   style={{ display: "block", margin: "auto" }}
                  // />
                  !loading && (
                    <Typography
                      variant="h5"
                      style={{ fontWeight: 400, textAlign: "center" }}
                    >
                      Data is not available
                    </Typography>
                  )
                )
              ) : (
                <CircularProgress
                  style={{ display: "block", margin: "auto" }}
                />
              )} */}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Loader showLoader={loading} />
      </Box>
      <AppointmentRescheduleRequestModal
        open={openRescheduleModal}
        setOpen={setOpenRescheduleModal}
        id={appointmentid}
        callNotificationAPI={getNotificationList}
        appointmentData={appointmentData}
      />
      <AppointmentCancelModel
        open={openCancelModal}
        setOpen={setOpenCancelModal}
        appointmentData={appointmentData}
        showLoader={loading}
        setShowLoader={setLoading}
      />
    </>
  );
}

export default Notifications;
