import { Button, Grid, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { REQUIRED_RED } from "../../../App";
import { FormatNumberForKmFloat } from "../../../components/FormatNumber/FormatNumber";
import {
  getShopPriceDetails,
  updateShopPriceDetails,
} from "../../../redux/Account/ShopDetails/action";

function ShopPricing({
  setShowLoader,
  setOpenSuccessModal,
  setSuccessMessage,
}) {
  const dispatch = useDispatch();
  // const [openSuccessModal, setOpenSuccessModal] = useState(false);
  // const [successMessage, setSuccessMessage] = useState("");
  const [defaultPriceDetails, setDefaultPriceDetails] = useState("");
  const {
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getShopPrice();
  }, []);
  const getShopPrice = async () => {
    await dispatch(getShopPriceDetails());
  };
  const shopPriceDetails = useSelector((state) => state?.account.shopPrice);
  useEffect(() => {
    setValue(
      "defaultHourlyRate",
      FormatNumberForKmFloat(shopPriceDetails + "")
    );
    setDefaultPriceDetails(shopPriceDetails);
  }, [shopPriceDetails]);

  const onSubmitShopServicePricing = async (values) => {
    let payload = {
      defaultHourlyRate: parseInt(
        ("" + values?.defaultHourlyRate)?.replace(/,/g, "")
      ),
    };
    setShowLoader(true);
    let result = await dispatch(updateShopPriceDetails(payload));
    if (result) {
      setOpenSuccessModal(true);
      setSuccessMessage("Changes Saved");
      setDefaultPriceDetails(result.defaultHourlyRate);
    }
    setShowLoader(false);
  };
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmitShopServicePricing)}
        style={{ marginTop: "20px" }}
      >
        <Grid
          container
          sx={{ boxShadow: 3, bgcolor: "white" }}
          justifyContent={"start"}
          marginTop={"32px"}
          padding={"24px"}
          borderRadius={"10px"}
        >
          <Grid item xs={12} sm={12} md={4} xl={3}>
            <Typography variant="h5" sx={{ fontWeight: 400, fontSize: "24px" }}>
              Shop Pricing
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={12} md={8} xl={9} rowGap={3}>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              xl={12}
              justifyContent={"end"}
            >
              <Grid item></Grid>

              <Grid
                container
                item
                xs={12}
                sm={12}
                md={12}
                xl={10}
                justifyContent={"space-between"}
                alignItems={"center"}
                padding={"16px 0"}
                style={{ marginTop: "24px" }}
              >
                <Grid
                  container
                  item
                  xs={12}
                  sm={9}
                  md={8}
                  xl={7}
                  spacing={2}
                  alignItems="center"
                >
                  <Grid item xs={12} sm={4} md={7} xl={4}>
                    <Typography variant="body1" className="label-500">
                      Labor Rate
                      <span style={{ color: REQUIRED_RED, marginLeft: "4px" }}>
                        *
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={7} md={5} xl={8}>
                    <Controller
                      name="defaultHourlyRate"
                      control={control}
                      rules={{
                        required: "Please enter service rate",
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          fullWidth
                          error={!!errors.defaultHourlyRate}
                          helperText={
                            errors.defaultHourlyRate
                              ? errors.defaultHourlyRate.message
                              : ""
                          }
                          onChange={(event) => {
                            const inputValue = event.target.value;
                            const numericValue = inputValue.replace(
                              /[^\d.]/g,
                              ""
                            );
                            setValue(
                              "defaultHourlyRate",
                              FormatNumberForKmFloat(numericValue)
                            );
                          }}
                          InputLabelProps={{ shrink: field.value?.length > 0 }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Grid xs={12} sm={3} item direction={"row"}></Grid>
              </Grid>
            </Grid>

            <Grid container justifyContent={"end"}>
              <Grid item spacing={2}>
                <Stack spacing={2} direction="row">
                  <Button
                    className="btn-text-size-manage"
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setValue(
                        "defaultHourlyRate",
                        FormatNumberForKmFloat(defaultPriceDetails + "")
                      );
                    }}
                  >
                    Cancel Changes
                  </Button>
                  <Button
                    className="btn-text-size-manage"
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Save Changes
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default ShopPricing;
