import React from "react";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  useMediaQuery,
  useTheme,
} from "@mui/material";
// import { useTheme } from "@emotion/react";

const Loader = ({ showLoader }) => {
  const theme = useTheme();

  return (
    <Dialog open={showLoader}>
      <DialogContent>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  );
};

export default Loader;
