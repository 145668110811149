import { API } from "../../config/api";
import { DataService } from "../../config/dataService/dataService";

export const createWorkOrderAuthorization = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        `${API.workOrderAuthorization.create}`,
        payload
      );
      if (response.data.status) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const getWorkOrderAuthorization = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.workOrderAuthorization.detail + id}`
      );
      if (response.data.status) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const updatetWorkOrderAuthorizationStatus = (
  authorizationId,
  payload
) => {
  console.log(authorizationId, payload);
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        `${API.workOrderAuthorization.updateStatus + authorizationId}`,
        payload
      );
      if (response.data.status) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
export const getWorkOrderAuthorizationHistory = (id) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.workOrderAuthorization.history + id}`
      );
      if (response.data.status) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
