import { API } from "../../config/api";
import { DataService } from "../../config/dataService/dataService";

export const customerList = (params, canceltoken) => async (dispatch) => {
  try {
    let queryParameter = `?page=${params?.page}&limit=${params?.limit}&sortBy=${
      params?.sortBy
    }&sortType=${params?.sortType}&timezone=${
      Intl.DateTimeFormat().resolvedOptions().timeZone
    }`;

    if (params?.search) {
      queryParameter += `&searchText=${encodeURIComponent(params?.search)}`;
    }

    if (params?.date) {
      queryParameter += `&date=${params?.date}`;
    }

    if (params?.searchBy) {
      queryParameter += `&searchBy=${params?.searchBy}`;
    }

    if (params?.createdFrom) {
      params?.createdFrom.forEach(
        (item) => (queryParameter += `&createdFrom=${item}`)
      );
    }

    const response = await DataService.get(
      API.customer.list + queryParameter,
      {},
      canceltoken
    );
    if (!response.data.error) {
      dispatch({
        type: API.customer.list,
        customerList: response.data,
        loading: false,
      });
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error in fetching user", error);
    return error;
  }
};
export const customerDetail = (id) => async (dispatch) => {
  try {
    const response = await DataService.get(
      API.customer.detail +
        id +
        `?timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`
    );
    if (response.data.status) {
      dispatch({
        type: API.customer.detail,
        customerDetail: response.data.data,
        loading: false,
      });
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const customerVehicles = (id) => async (dispatch) => {
  try {
    const response = await DataService.get(API.customer.vehicles + id);
    if (response.data.status) {
      dispatch({
        type: API.customer.vehicles,
        customerVehicle: response.data.data,
        loading: false,
      });
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
export const customerAppointments = (params) => async (dispatch) => {
  try {
    let queryParameter = `?page=${params?.page}&limit=${params?.limit}&customerId=${params?.customerId}`;
    const response = await DataService.get(
      API.customer.appointments + queryParameter
    );
    if (response.data.status) {
      dispatch({
        type: API.customer.appointments,
        customerAppointment: response.data,
        loading: false,
      });
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const customerNotes = (id) => async (dispatch) => {
  try {
    const response = await DataService.get(API.customer.notes + id);
    if (response.data.status) {
      dispatch({
        type: API.customer.notes,
        customerNotes: response.data.data,
        loading: false,
      });
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const vehicleYearMaster =
  ({ page, limit }) =>
  async (dispatch) => {
    try {
      const response = await DataService.get(
        API.customer.vehicleYear + `?page=${page}&limit=${limit}`
      );
      if (response.data.status) {
        dispatch({
          type: API.customer.vehicleYear,
          vehicleYear: response.data?.data,
          loading: false,
        });
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

export const vehicleMakeMaster = (params) => async (dispatch) => {
  let queryParam = `?page=${params?.page}&limit=${params?.limit}`;
  if (params.year !== undefined && params.year !== null && params.year !== "") {
    queryParam += `&year=${params.year}`;
  }
  try {
    const response = await DataService.get(
      API.customer.vehicleMake + queryParam
    );
    if (response.data.status) {
      dispatch({
        type: API.customer.vehicleMake,
        vehicleMake: response.data.data,
        loading: false,
      });
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
export const vehicleModelMaster = (params) => async (dispatch) => {
  let queryParam = `?page=${params?.page}&limit=${params?.limit}&vehicleMakeId=${params?.vehicleMakeId}`;
  if (params.year !== undefined && params.year !== null && params.year !== "") {
    queryParam += `&year=${params.year}`;
  }
  try {
    const response = await DataService.get(
      API.customer.vehicleModel + queryParam
    );
    if (response.data.status) {
      dispatch({
        type: API.customer.vehicleModel,
        vehicleModel: response.data.data,
        loading: false,
      });
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const vehicleTrimMaster = (params) => async (dispatch) => {
  let queryParam = `?page=${params?.page}&limit=${params?.limit}&vehicleMake=${params?.vehicleMakeId}&vehicleModel=${params?.vehicleModelId}`;
  if (params.year !== undefined && params.year !== null && params.year !== "") {
    queryParam += `&year=${params.year}`;
  }
  try {
    const response = await DataService.get(
      API.customer.vehicleTrim + queryParam
    );
    if (response.data.status) {
      dispatch({
        type: API.customer.vehicleTrim,
        vehicleTrim: response.data.data,
        loading: false,
      });
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
// get ShopCustomer List

export const customerDetails = () => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(API.customer.getCustomerList);
      if (!response.data.error) {
        dispatch({
          type: API.customer.getCustomerList,
          customerData: response.data.data,
          loading: false,
        });
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const getNotesListing = (params) => async (dispatch) => {
  try {
    const response = await DataService.get(
      API.customer.notesList +
        `?page=${params?.page}&limit=${params?.limit}&customerId=${params?.customerId}`
    );
    if (response.data.status) {
      dispatch({
        type: API.customer.notesList,
        notesList: response.data.data,
        loading: false,
      });
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const addNote = (payload) => async (dispatch) => {
  try {
    const response = await DataService.post(API.customer.addNote, payload);
    if (response.data.status) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const addVehicle = (payload) => async (dispatch) => {
  try {
    const response = await DataService.post(API.customer.addVehicle, payload);
    if (response.data.status) {
      return response.data;
    } else {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};
export const importedVehicleCreated = (payload, id) => async (dispatch) => {
  try {
    const response = await DataService.post(
      `${API.customer.importedVehicleAdded}/${id}`,
      payload
    );
    if (response.data) {
      return response.data;
    } else {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};

export const customerCreate = (payload) => async (dispatch) => {
  try {
    const response = await DataService.post(API.customer.create, payload);
    if (response.data.status) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
export const shopImportedCustomerCreate = (payload) => async (dispatch) => {
  try {
    const response = await DataService.post(
      API.customer.shopImportedCreate,
      payload
    );
    if (response.data.status) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
export const editCustomer = (id, payload) => async (dispatch) => {
  try {
    const response = await DataService.put(API.customer.edit + id, payload);
    if (response.data.status) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
export const deleteCustomer = (id) => async (dispatch) => {
  try {
    const response = await DataService.delete(API.customer.delete + id);
    if (response.data.status) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
export const editCustomerVehicle = (id, payload) => async (dispatch) => {
  try {
    const response = await DataService.put(
      API.customer.editVehicle + id,
      payload
    );
    if (response.data.status) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
export const deleteCustomerVehicle = (id) => async (dispatch) => {
  try {
    const response = await DataService.delete(API.customer.deleteVehicle + id);
    if (response.data.status) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const editCustomerNote = (id, payload) => async (dispatch) => {
  try {
    const response = await DataService.put(API.customer.editNote + id, payload);
    if (response.data.status) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
export const deleteCustomerNote = (id) => async (dispatch) => {
  try {
    const response = await DataService.delete(API.customer.deleteNote + id);
    if (response.data.status) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const decodeVIN = (params) => async (dispatch) => {
  try {
    const response = await DataService.get(API.customer.decodeVIN + params);
    if (!response.data.error) {
      return response.data;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};
export const decodeLicence =
  ({ licenceNumber, provinceCode }) =>
  async (dispatch) => {
    let params = `?licenseNumber=${licenceNumber}&provinceCode=${provinceCode}`;
    console.log("🚀 ~ decodeLicence ~ params:", params);
    try {
      const response = await DataService.get(
        API.customer.decodeLicense + params
      );
      if (!response.data.error) {
        return response.data;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };

export const getMergeCustomerList = (params) => async (dispatch) => {
  try {
    const response = await DataService.get(
      API.customer.mergeCustomerList +
        `?page=${params.page}&limit=${params?.limit}&option=${params?.option}`
    );
    if (response.data.status) {
      dispatch({
        type: API.customer.mergeCustomerList,
        mergeList: response.data,
        loading: false,
      });
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
export const getMergeCustomerByIds = (payload) => async (dispatch) => {
  try {
    const response = await DataService.post(
      API.customer.mergeCustomerById,
      payload
    );
    if (response.data.status) {
      dispatch({
        type: API.customer.mergeCustomerById,
        mergeCustomerById: response.data,
        loading: false,
      });
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
export const customerImportList = (params) => async (dispatch) => {
  try {
    let queryParameter = `?page=${params?.page}&limit=${params?.limit}&sortBy=${params?.sortBy}&sortType=${params?.sortType}`;

    if (params?.search !== "") {
      queryParameter += `&searchText=${encodeURIComponent(params?.search)}`;
      if (params?.searchBy !== "") {
        queryParameter += `&searchBy=${params?.searchBy}`;
      }
    }
    const response = await DataService.get(
      API.customer.importCustomerList + queryParameter
    );
    if (!response.data.error) {
      dispatch({
        type: API.customer.importCustomerList,
        importCustomerList: response.data,
        loading: false,
      });
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const mergeCustomer = (payload) => async (dispatch) => {
  try {
    const response = await DataService.post(
      API.customer.mergeCustomers,
      payload
    );
    if (response.data.status) {
      return response.data;
    } else {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};
export const dismissMergeCustomers = (payload) => async (dispatch) => {
  try {
    const response = await DataService.put(
      API.customer.dismissCustomer,
      payload
    );
    if (response.data.status) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
export const removeMergeCustomer = (id) => async (dispatch) => {
  try {
    const response = await DataService.delete(
      API.customer.removeMergeCustomer + id
    );
    if (response.data.status) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
export const customerCheckPhoneNumberExist = (payload) => async (dispatch) => {
  try {
    const response = await DataService.post(
      API.customer.checkPhoneNumber,
      payload
    );
    if (response.data.status) {
      return response.data;
    } else {
      return response.data;
    }
  } catch (error) {
    return false;
  }
};

export const updatePhoneNumberExistCustomer =
  (id, payload) => async (dispatch) => {
    try {
      const response = await DataService.put(
        API.customer.updatePhoneCustomer + id,
        payload
      );
      if (response.data.status) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

export const importedCustomerDetail = (id) => async (dispatch) => {
  try {
    const response = await DataService.get(
      API.customer.importCustomerDetail + id
    );
    console.log("response", response);
    if (response.data.status) {
      dispatch({
        type: API.customer.importCustomerDetail,
        importCustomerDetail: response.data.data,
        loading: false,
      });
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const getCityList = (params) => async (dispatch) => {
  let queryParameter = `?countryId=${params?.country}`;
  if (params?.province) {
    queryParameter += `&provinceId=${params?.province}`;
  }

  try {
    const response = await DataService.get(
      API.customer.cityList + queryParameter
    );

    if (response.data.status) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const getProvinceList = (params) => async (dispatch) => {
  let queryParameter = `?countryId=${params?.country}`;

  try {
    const response = await DataService.get(
      API.customer.provinceList + queryParameter
    );

    if (response.data.status) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const getCountryList = () => async (dispatch) => {
  try {
    const response = await DataService.get(API.customer.countryList);

    if (response.data.status) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
export const editImportedVehicle = (id, payload) => async (dispatch) => {
  try {
    const response = await DataService.put(
      API.customer.editImportedVehicle + id,
      payload
    );
    if (response.data.status) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
export const deleteImportedVehicle = (id) => async (dispatch) => {
  try {
    const response = await DataService.delete(
      API.customer.deleteImportedVehicle + id
    );
    if (response.data.status) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const carfaxVehicleHistory =
  ({ vin, licenseNumber, province }) =>
  async (dispatch) => {
    let params = "";
    if (vin) params = `?vin=${vin}`;
    if (licenseNumber && province)
      params = `?licenseNumber=${licenseNumber}&provinceCode=${province}`;

    try {
      const response = await DataService.get(
        API.customer.getCarfaxHistory + params
      );
      if (response.data.status) {
        dispatch({
          type: API.customer.getCarfaxHistory,
          carfaxHistory: response.data,
          loading: false,
        });
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
