import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Context } from "../../../App";
import {
  discountProgramApi,
  saveDiscountProgramApi,
} from "../../../redux/Account/Pricing/action";
import SuccessMessageModal from "../../Common/SuccessMessage";
import ProgramModal from "./ProgramModal";

const DiscountProgram = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { isOpenSideBar } = useContext(Context);

  const [isProgramModal, setIsProgramModal] = useState(false);
  const [updateIndex, setUpdateIndex] = useState(-1);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const [discountProgram, setDiscountProgram] = useState([]);
  const [storeData, setStoreData] = useState([]);
  const [deletedMemberships, setDeletedMemberships] = useState([]);
  console.log("🚀 ~ DiscountProgram ~ deletedMemberships:", deletedMemberships);
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const discountProgramData = useSelector(
    (state) => state?.account.showDiscountProgramData
  );

  const fetchDiscountProgram = async () => {
    setShowLoader(true);
    await dispatch(discountProgramApi({ pagination: false }));
    setShowLoader(false);
  };

  const handleSubmit = async (deleteArray, discountProgramArray) => {
    setShowLoader(true);
    const payload = {
      deletedMemberships:
        deleteArray && deleteArray.length > 0
          ? deleteArray
          : deletedMemberships,
      memberships:
        discountProgramArray && discountProgramArray.length > 0
          ? discountProgramArray
          : discountProgram,
    };
    const response = await dispatch(saveDiscountProgramApi(payload));
    if (response) {
      setSuccessMessage("Changes Saved");
      setOpenSuccessModal(true);
      setDeletedMemberships([]);
      fetchDiscountProgram();
    }
    setIsDeleteModal(false);
    setShowLoader(false);
  };

  useEffect(() => {
    fetchDiscountProgram();
  }, []);

  useEffect(() => {
    if (discountProgramData?.data?.length) {
      const formattedPrograms = discountProgramData.data.map(
        ({ _id, programName, discount }) => ({ _id, programName, discount })
      );
      setDiscountProgram(formattedPrograms);
      setStoreData(formattedPrograms);
    }
  }, [discountProgramData]);

  const handleDeleteProgram = (program, index) => {
    // setDiscountProgram((prev) => prev.filter((_, i) => i !== index));
    if (program._id) {
      setDeletedMemberships((prev) => [...prev, program._id]);
      setIsDeleteModal(true);
    }
  };

  return (
    <>
      <Box>
        <Grid
          container
          sx={{ boxShadow: 3, bgcolor: "white" }}
          justifyContent={"start"}
          borderTop={"none"}
          padding={"24px"}
          borderRadius={"10px"}
          marginTop={"32px"}
        >
          <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
            <Typography variant="h5" sx={{ fontWeight: 400, fontSize: "24px" }}>
              Discount Program
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            lg={9}
            xl={9}
            rowGap={3}
            // paddingLeft={"24px"}
          >
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              xl={12}
              justifyContent={"end"}
              style={{ marginTop: "24px" }}
            >
              <Grid container item lg={12} justifyContent={"end"}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  style={{ marginBottom: "24px" }}
                  onClick={() => setIsProgramModal(true)}
                >
                  ADD NEW PROGRAM
                </Button>
                {/* Add new policy modal begins */}
                <ProgramModal
                  open={isProgramModal}
                  onClose={setIsProgramModal}
                  onSave={setDiscountProgram}
                  updateIndex={updateIndex}
                  setUpdateIndex={setUpdateIndex}
                  discountProgram={discountProgram}
                  handleSubmitProgram={handleSubmit}
                />
              </Grid>

              {Array.isArray(discountProgram) &&
                discountProgram?.map((program, index) => {
                  return (
                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      xl={10}
                      justifyContent={"space-between"}
                      rowSpacing={2}
                      alignItems={"center"}
                      padding={"16px 0"}
                      key={index}
                      // style={index === 0 ? {} : { marginTop: "24px" }}
                    >
                      <Grid
                        container
                        item
                        xs={12}
                        sm={isOpenSideBar ? 12 : 9}
                        md={8}
                        xl={7}
                        spacing={2}
                        alignItems="center"
                      >
                        <Grid
                          item
                          xs={12}
                          sm={isOpenSideBar ? 12 : 4}
                          md={7}
                          xl={4}
                        >
                          <Typography variant="body1" className="label-500">
                            {program.programName}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={isOpenSideBar ? 12 : 7}
                          md={5}
                          xl={8}
                        >
                          <OutlinedInput
                            value={(+program.discount)?.toFixed(2)}
                            type="number"
                            disabled
                            endAdornment={
                              <InputAdornment position="end">%</InputAdornment>
                            }
                            id="outlined-basic"
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        xs={12}
                        sm={isOpenSideBar ? 12 : 3}
                        md={3}
                        item
                        direction={"row"}
                      >
                        <Stack
                          spacing={2}
                          direction="row"
                          justifyContent={"flex-end"}
                        >
                          <Button
                            variant="contained"
                            size="large"
                            sx={{
                              fontSize: "14px !important",
                              letterSpacing: "0.4px",
                            }}
                            onClick={() => {
                              setUpdateIndex(index);
                              setIsProgramModal(true);
                            }}
                          >
                            EDIT
                          </Button>
                          <Button
                            color="error"
                            variant="contained"
                            size="large"
                            sx={{
                              fontSize: "14px !important",
                              letterSpacing: "0.4px",
                            }}
                            onClick={() => handleDeleteProgram(program, index)}
                          >
                            DELETE
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>

          {/* <Grid
            container
            justifyContent={"end"}
            spacing={2}
            style={{ marginTop: "20px" }}
          >
            <Grid item>
              <Stack spacing={2} direction="row">
                <Button
                  className="btn-text-size-manage"
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setDeletedMemberships([]);
                    setDiscountProgram(storeData);
                  }}
                >
                  CANCEL CHANGES
                </Button>
                <Button
                  className="btn-text-size-manage"
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit()}
                >
                  SAVE CHANGES
                </Button>
              </Stack>
            </Grid>
          </Grid> */}
        </Grid>
      </Box>

      {/* delete conformation modal  */}

      <Dialog
        open={isDeleteModal}
        onClose={() => {
          setIsDeleteModal(false);
          setDeletedMemberships([]);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this discount program?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="btn-text-size-manage"
            onClick={() => {
              setIsDeleteModal(false);
              setDeletedMemberships([]);
            }}
          >
            No
          </Button>
          <Button
            className="btn-text-size-manage"
            onClick={() => {
              handleSubmit(deletedMemberships, undefined);
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
    </>
  );
};

export default DiscountProgram;
