import { toast } from "sonner";

export const displayError = (message) => {
  let errMessage = message || "An error occurred. Please try again later.";

  return toast.error(errMessage);
};
const vinRegex = new RegExp("^(?=.*[0-9])(?=.*[A-z])[0-9A-z-]{17}$");

export const checkValidVIN = (data) => {
  return vinRegex.test(data);
};
export const provinceLicensePlatesExample = {
  AB: "ABC 123", // Alberta
  BC: "DEF 456", // British Columbia
  MB: "GHI 789", // Manitoba
  NB: "JKL 123", // New Brunswick
  NL: "123 456", // Newfoundland and Labrador
  NT: "789 012", // Northwest Territories
  NS: "MNO 345", // Nova Scotia
  NU: "456 789", // Nunavut
  ON: "PQRS 123", // Ontario
  PE: "TUV 456", // Prince Edward Island
  QC: "WXY 789", // Quebec
  SK: "AB 1234", // Saskatchewan
  YT: "XYZ 123", // Yukon
};

// Example usage:
// console.log(provinceLicensePlates);

export const licensePlateRegex = {
  AB: /^[A-Z]{3}-\d{4}$/, // Alberta
  BC: /^[A-Z]{3} \d{3}$/, // British Columbia
  MB: /^[A-Z]{3} \d{3}$/, // Manitoba
  NB: /^[A-Z]{3} \d{3}$/, // New Brunswick
  NL: /^\d{3} \d{3}$/, // Newfoundland and Labrador
  NT: /^\d{3} \d{3}$/, // Northwest Territories
  NS: /^[A-Z]{3} \d{3}$/, // Nova Scotia
  NU: /^\d{3} \d{3}$/, // Nunavut
  ON: /^[A-Z]{4} \d{3}$/, // Ontario
  PE: /^[A-Z]{3} \d{3}$/, // Prince Edward Island
  QC: /^[A-Z]{3} \d{3}$/, // Quebec
  SK: /^[A-Z]{2} \d{4}$/, // Saskatchewan
  YT: /^[A-Z]{3} \d{3}$/, // Yukon
};

export const getLicenceLength = (province) => {
  console.log("🚀 ~ getLicenceLength ~ province:", province);

  if (province == "AB") return 8;
  if (province == "BC") return 7;
  if (province == "MB") return 7;
  if (province == "NB") return 7;
  if (province == "NL") return 7;
  if (province == "NT") return 7;
  if (province == "NS") return 7;
  if (province == "NU") return 7;
  if (province == "ON") return 8;
  if (province == "PE") return 7;
  if (province == "QC") return 7;
  if (province == "SK") return 7;
  if (province == "YT") return 7;
  if (province == "NT") return 7;
  if (province == "NU") return 7;
  return "";
};

export const licenceSliceLength = (province) => {
  if (province == "AB") return [3, 7];
  if (province == "BC") return [3, 6];
  if (province == "MB") return [3, 6];
  if (province == "NB") return [3, 6];
  if (province == "NL") return [3, 6];
  if (province == "NT") return [3, 6];
  if (province == "NS") return [3, 6];
  if (province == "NU") return [3, 6];
  if (province == "ON") return [4, 7];
  if (province == "PE") return [3, 6];
  if (province == "QC") return [3, 6];
  if (province == "SK") return [2, 6];
  if (province == "YT") return [3, 6];
  if (province == "NT") return [3, 6];
  if (province == "NU") return [3, 6];
};

export const getLicencePatterns = (province) => {};

export const formatLicenceNumber = (inputValue, province) => {
  console.log("inputValue", inputValue, province);
  const [one, two] = licenceSliceLength(province);
  const trimmedValue = inputValue?.replace(/\s/g, "").toUpperCase(); // Remove spaces and convert to uppercase
  const value1 = trimmedValue?.slice(0, one);
  const value2 = trimmedValue?.slice(one, two); // Only take up to 3 more characters
  let formattedValue = value1;
  if (value2?.length > 0) {
    formattedValue += province == "AB" ? "-" : " " + value2;
  }
  return formattedValue; // Return the formatted value
};

export const checkValidLicence = (data, province) => {
  return licensePlateRegex[province].test(data);
};

// export function inputFormatLicenseNumber(licenseNumber) {

//   let inputValue;
//   if (licenseNumber) {
//     inputValue = licenseNumber.replace(/-/g, "");
//     if (inputValue.length > 3 && inputValue.length <= 6) {
//       inputValue = `${inputValue.slice(0, 3)}-${inputValue.slice(3)}`;
//     } else if (inputValue.length > 6) {
//       inputValue = `${inputValue.slice(0, 3)}-${inputValue.slice(
//         3,
//         6
//       )}-${inputValue.slice(6)}`;
//     } else {
//       inputValue = inputValue;
//     }
//     return inputValue;
//   } else return "";
// }
