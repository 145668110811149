import { useTheme } from "@emotion/react";
import { CarRepair, Construction } from "@mui/icons-material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  appointmentDetails,
  kanbanList,
  updateAppointmentStatus,
} from "../../../redux/appointment/action";
import AddOdoModal from "../../modal/AddOdoModal";
import AppointmentModel from "../AppointmentModel";

const styleTag = {
  borderRadius: "48px",
  color: "black",
  padding: "10px 20px",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  margin: "10px",
  width: "200px",
};
const styleButton = {
  borderRadius: "48px",
  color: "black",
  padding: "6px 16px",
  justifyContent: "center",
  alignItems: "center",
};
const styleDiv = {
  width: "400px",
  height: "56px",
  alignItems: "center",
  display: "flex",
  padding: "24px 16px",
  alignSelf: "stretch",
  borderRadius: "8px",
  position: "relative",
};
const styleTypography = {
  padding: "8px",
  borderStyle: "solid",
  borderColor: "white",
  borderWidth: "1px",
  borderRadius: "40px",
  marginLeft: "20px",
  backgroundColor: "white",
  color: "black",
  display: "flex",
  width: "40px",
  height: "40px",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  right: "8px",
  fontSize: "20px",
  fontWeight: "500",
  lineHeight: "24px",
};
const TodayAppointment = () => {
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [status, setStatus] = useState("");
  const [showLoader, setShowLoader] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [data, setData] = React.useState("");
  const [selectId, setSelectId] = React.useState("");
  const [updateStatus, setUpdateStatus] = React.useState("");
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dragId, setDragId] = React.useState("");
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const [appointmentDropOffODO, setAppointmentDropOffODo] = useState(null);
  const [appointmentCompletionOffODO, setAppointmentCompletionOffODo] =
    useState(null);
  let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    if (open) details();
  }, [id, open]);

  const details = async () => {
    if (id) {
      setShowLoader(true);
      const detailView = await dispatch(appointmentDetails(id));
      if (detailView) {
        setData(detailView);
      }
      setShowLoader(false);
    }
  };

  const open1 = Boolean(anchorEl);
  const handleClick1 = (event, statusData) => {
    setAnchorEl(event.currentTarget);
    setStatus(statusData);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const Data = useSelector((state) => state.appointment.appointmentData.data);

  const handleStatusClose = () => {
    kanbanBoardData();
    setShow(false);
  };
  const handleStatus = () => setShow(true);
  const onDragEnd = async (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    setAppointmentDropOffODo(null);
    setAppointmentCompletionOffODo(null);

    let found = columns?.[source?.droppableId]?.items?.filter(
      (d) => d?._id == result?.draggableId
    );

    if (Array.isArray(found) && found?.[0]?.dropOffODO) {
      setAppointmentDropOffODo(found?.[0]?.dropOffODO);
    }
    if (Array.isArray(found) && found?.[0]?.completionODO) {
      setAppointmentCompletionOffODo(found?.[0]?.completionODO);
    }
    let key = source.droppableId;
    let dragIndex = source.index;

    if (source.droppableId !== destination.droppableId) {
      setShowLoader(true);
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
      let payload = {
        status: destination.droppableId,
      };
      if (
        source.droppableId == "waiting_for_drop_off" &&
        (destination.droppableId == "vehicle_dropped_off" ||
          destination.droppableId == "in_progress" ||
          destination.droppableId == "ready_for_pick_up" ||
          destination.droppableId == "customer_paid" ||
          destination.droppableId == "mark_as_no_show")
      ) {
        // if (source.droppableId == "waiting_for_drop_off" || source.droppableId == "in_progress")
        if (
          (source.droppableId == "waiting_for_drop_off" &&
            (destination.droppableId == "vehicle_dropped_off" ||
              destination.droppableId == "in_progress" ||
              destination.droppableId == "ready_for_pick_up" ||
              destination.droppableId == "customer_paid")) ||
          (source.droppableId == "in_progress" &&
            destination.droppableId == "ready_for_pick_up")
        ) {
          setStatus(source.droppableId);
          setUpdateStatus(destination.droppableId);
          handleStatus();
          setDragId(Data[key][dragIndex]._id);
          // dragableId = Data[key][dragIndex]._id;
          setShowLoader(false);
        } else {
          if (Data[key][dragIndex]._id) {
            const statusUpdate = await dispatch(
              updateAppointmentStatus(Data[key][dragIndex]._id, payload)
            );
            if (statusUpdate) {
              kanbanBoardData();
            }
            setShowLoader(false);
          }
        }
      } else if (
        source.droppableId == "vehicle_dropped_off" &&
        (destination.droppableId == "in_progress" ||
          destination.droppableId == "ready_for_pick_up" ||
          destination.droppableId == "customer_paid")
      ) {
        if (
          source.droppableId == "vehicle_dropped_off" &&
          (destination.droppableId == "ready_for_pick_up" ||
            destination.droppableId == "customer_paid")
          //          ||
          // (source.droppableId == "in_progress" && destination.droppableId == "ready_for_pick_up")
        ) {
          setStatus(source.droppableId);
          setUpdateStatus(destination.droppableId);
          handleStatus();

          setDragId(Data[key][dragIndex]._id);
          // dragableId = Data[key][dragIndex]._id;
          setShowLoader(false);
        } else {
          if (Data[key][dragIndex]._id) {
            const statusUpdate = await dispatch(
              updateAppointmentStatus(Data[key][dragIndex]._id, payload)
            );
            if (statusUpdate) {
              kanbanBoardData();
            }
            setShowLoader(false);
          }
        }
      } else if (
        source.droppableId == "in_progress" &&
        (destination.droppableId == "vehicle_dropped_off" ||
          destination.droppableId == "ready_for_pick_up" ||
          destination.droppableId == "customer_paid")
      ) {
        if (
          source.droppableId == "in_progress" &&
          (destination.droppableId == "ready_for_pick_up" ||
            destination.droppableId == "customer_paid")
          //          ||
          // (source.droppableId == "in_progress" && destination.droppableId == "ready_for_pick_up")
        ) {
          setStatus(source.droppableId);
          setUpdateStatus(destination.droppableId);
          handleStatus();
          setDragId(Data[key][dragIndex]._id);
          // dragableId = Data[key][dragIndex]._id;
          setShowLoader(false);
        } else {
          if (Data[key][dragIndex]._id) {
            const statusUpdate = await dispatch(
              updateAppointmentStatus(Data[key][dragIndex]._id, payload)
            );
            if (statusUpdate) {
              kanbanBoardData();
            }
            setShowLoader(false);
          }
        }
      } else if (
        source.droppableId == "ready_for_pick_up" &&
        (destination.droppableId == "in_progress" ||
          destination.droppableId == "customer_paid")
      ) {
        if (
          (source.droppableId == "waiting_for_drop_off" &&
            (destination.droppableId == "vehicle_dropped_off" ||
              destination.droppableId == "ready_for_pick_up" ||
              destination.droppableId == "customer_paid")) ||
          (source.droppableId == "in_progress" &&
            destination.droppableId == "ready_for_pick_up")
        ) {
          setStatus(source.droppableId);
          setUpdateStatus(destination.droppableId);
          handleStatus();
          setDragId(Data[key][dragIndex]._id);
          // dragableId = Data[key][dragIndex]._id;
          setShowLoader(false);
        } else {
          if (Data[key][dragIndex]._id) {
            const statusUpdate = await dispatch(
              updateAppointmentStatus(Data[key][dragIndex]._id, payload)
            );
            if (statusUpdate) {
              kanbanBoardData();
            }
            setShowLoader(false);
          }
        }
      } else {
        handleStatusClose();
      }
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  const handleOpen = (detailsData) => {
    setStatus(detailsData?.status);
    setId(detailsData?._id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    kanbanBoardData();
  };

  const [columns, setColumns] = useState({
    waiting_for_drop_off: {
      name: "Waiting for Drop Off",
      items: Data?.waiting_for_drop_off,
    },
    vehicle_dropped_off: {
      name: "Vehicles Dropped Off",
      items: Data?.vehicle_dropped_off,
    },
    in_progress: {
      name: "In Progress",
      items: Data?.in_progress,
    },
    ready_for_pick_up: {
      name: "Ready for Pick Up",
      items: Data?.ready_for_pick_up,
    },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const newData = { ...columns };
    newData.waiting_for_drop_off.items = Data?.waiting_for_drop_off;
    newData.vehicle_dropped_off.items = Data?.vehicle_dropped_off;
    newData.in_progress.items = Data?.in_progress;
    newData.ready_for_pick_up.items = Data?.ready_for_pick_up;
    setColumns(newData);
  }, [Data]);

  useEffect(() => {
    kanbanBoardData();
  }, []);

  const kanbanBoardData = async () => {
    setShowLoader(true);
    const data = await dispatch(kanbanList(timeZone));
    if (data) {
      setShowLoader(false);
    }
  };

  const UpdateStatus = async (id, status) => {
    setShowLoader(true);
    let payload = {
      status: status,
    };
    const statusUpdate = await dispatch(updateAppointmentStatus(id, payload));
    setShowLoader(false);
    if (statusUpdate) {
      handleClose();
      navigate("/appointment/today");
    }
  };

  return (
    <div className="today_appointment_container">
      <div className="appointmentCardGridContainer">
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
        >
          {Object.entries(columns)?.map(([columnId, column], index) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  rowGap: "16px",
                  width: "430px",
                  // flex:'0 0 auto'
                  // height: "100%",
                }}
                key={columnId}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    // paddingInline: "16px",
                  }}
                >
                  <Typography
                    variant="title"
                    fontWeight={500}
                    fontSize={"16px"}
                    style={{
                      ...styleDiv,
                      backgroundColor:
                        column.name == "Waiting for Drop Off"
                          ? "#75DBFF"
                          : column.name == "Vehicles Dropped Off"
                          ? "#FFC30F"
                          : column.name == "In Progress"
                          ? "#FF9E31"
                          : "#5CE469",
                    }}
                  >
                    {column.name}
                    <Typography style={styleTypography}>
                      {" "}
                      {column?.items?.length ? column?.items?.length : 0}
                    </Typography>
                  </Typography>
                </div>
                <div style={{ height: "100%" }}>
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            background: snapshot.isDraggingOver
                              ? "lightgrey"
                              : "#0000001F",
                            padding: 0,
                            width: 400,
                            minHeight: 500,
                            borderRadius: "8px",
                            height: "100%",
                          }}
                        >
                          {column?.items?.map((item, index) => {
                            return (
                              <Draggable
                                key={item._id}
                                draggableId={item._id}
                                index={index}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={{
                                        userSelect: "none",
                                        minHeight: "100px",
                                        color: "white",
                                        ...provided.draggableProps.style,
                                      }}
                                    >
                                      <Grid
                                        container
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        xl={12}
                                        padding={"8px 16px"}
                                        paddingTop={"16px"}
                                      >
                                        <Card
                                          border={"1px solid #ffffff"}
                                          style={{ borderRadius: "16px" }}
                                          key={item._id}
                                        >
                                          <Grid
                                            container
                                            padding={"16px 24px"}
                                            rowGap={"16px"}
                                            borderRadius={"16px"}
                                            onClick={() => {
                                              handleOpen(item);
                                            }}
                                          >
                                            <Grid
                                              item
                                              xs={12}
                                              sm={12}
                                              md={12}
                                              xl={12}
                                            >
                                              <Stack
                                                direction={"row"}
                                                spacing={2}
                                              >
                                                <Stack>
                                                  <Typography
                                                    variant="body1"
                                                    fontWeight={500}
                                                    fontSize={"20px"}
                                                  >
                                                    {item?.appointmentNumber}
                                                  </Typography>
                                                </Stack>
                                                <Stack>
                                                  <Typography
                                                    variant="body1"
                                                    fontWeight={500}
                                                    fontSize={"20px"}
                                                  >
                                                    {item?.customer?.firstName
                                                      ? item?.customer
                                                          ?.firstName + " "
                                                      : ""}
                                                    {item?.customer?.lastName
                                                      ? item?.customer?.lastName
                                                      : ""}
                                                  </Typography>
                                                </Stack>
                                              </Stack>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              sm={12}
                                              md={12}
                                              xl={12}
                                            >
                                              <Stack
                                                direction={"row"}
                                                spacing={2}
                                              >
                                                <Stack>
                                                  <Typography
                                                    variant="body1"
                                                    fontWeight={500}
                                                    fontSize={"16px"}
                                                  >
                                                    <CarRepair
                                                      style={{ color: "gray" }}
                                                    />
                                                  </Typography>
                                                </Stack>
                                                <Stack>
                                                  <Typography
                                                    variant="body1"
                                                    fontWeight={400}
                                                    fontSize={"16px"}
                                                    color={"#000000"}
                                                  >
                                                    {item?.vehicle?.year
                                                      ? item?.vehicle?.year
                                                      : ""}
                                                    {" " +
                                                      item?.vehicle?.vehicleMake
                                                        ?.name +
                                                      " " +
                                                      item?.vehicle
                                                        ?.vehicleModel?.name}
                                                    {item?.vehicle
                                                      ?.vehicleModelTrim?.name
                                                      ? " " +
                                                        item?.vehicle
                                                          ?.vehicleModelTrim
                                                          ?.name
                                                      : ""}
                                                  </Typography>
                                                </Stack>
                                              </Stack>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              sm={12}
                                              md={12}
                                              xl={12}
                                            >
                                              <Stack
                                                direction={"row"}
                                                spacing={2}
                                              >
                                                <Stack>
                                                  <Typography
                                                    variant="body1"
                                                    fontWeight={500}
                                                    fontSize={"16px"}
                                                  >
                                                    <CalendarTodayIcon
                                                      style={{ color: "gray" }}
                                                    />
                                                  </Typography>
                                                </Stack>
                                                <Stack>
                                                  <Typography
                                                    variant="body1"
                                                    fontWeight={400}
                                                    fontSize={"16px"}
                                                    color={"#000000"}
                                                  >
                                                    {moment(
                                                      item?.dropOffDateTime
                                                    ).format(
                                                      "MMM D, YYYY hh:mm A"
                                                    )}
                                                  </Typography>
                                                </Stack>
                                              </Stack>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              sm={12}
                                              md={12}
                                              xl={12}
                                            >
                                              <Stack
                                                direction={"row"}
                                                spacing={2}
                                              >
                                                <Stack>
                                                  <Typography
                                                    variant="body1"
                                                    fontWeight={500}
                                                    fontSize={"16px"}
                                                  >
                                                    <Construction
                                                      style={{ color: "gray" }}
                                                    />
                                                  </Typography>
                                                </Stack>
                                                <Stack>
                                                  <Typography
                                                    variant="body1"
                                                    fontWeight={400}
                                                    fontSize={"16px"}
                                                    color={"#000000"}
                                                  >
                                                    {item?.serviceCount > 1
                                                      ? item?.serviceCount +
                                                        " " +
                                                        "Total Services"
                                                      : item?.services[0].name}
                                                  </Typography>
                                                </Stack>
                                              </Stack>
                                            </Grid>
                                          </Grid>
                                          <Grid
                                            item
                                            container
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            xl={12}
                                            justifyContent={"end"}
                                            padding={"6px 20px"}
                                            marginBottom={"20px"}
                                          >
                                            <Grid item>
                                              <Button
                                                variant="contained"
                                                endIcon={<ExpandMoreIcon />}
                                                style={{
                                                  ...styleButton,
                                                  backgroundColor:
                                                    column.name ==
                                                    "Waiting for Drop Off"
                                                      ? "#75DBFF"
                                                      : column.name ==
                                                        "Vehicles Dropped Off"
                                                      ? "#FFC30F"
                                                      : column.name ==
                                                        "In Progress"
                                                      ? "#FF9E31"
                                                      : "#5CE469",
                                                }}
                                                aria-controls={
                                                  open1
                                                    ? "demo-positioned-menu"
                                                    : undefined
                                                }
                                                aria-haspopup="true"
                                                aria-expanded={
                                                  open1 ? "true" : undefined
                                                }
                                                onClick={(e) => {
                                                  setSelectId(item?._id);
                                                  handleClick1(e, item?.status);
                                                }}
                                              >
                                                {column.name ==
                                                "Waiting for Drop Off"
                                                  ? "WAITING FOR DROP OFF"
                                                  : column.name ==
                                                    "Vehicles Dropped Off"
                                                  ? "VEHICLE DROPPED OFF"
                                                  : column.name == "In Progress"
                                                  ? "IN PROGRESS"
                                                  : "READY FOR PICK UP"}
                                              </Button>

                                              <Menu
                                                style={{
                                                  width: "400px",
                                                  border: "none",
                                                  boxShadow: "none !important",
                                                }}
                                                id="demo-positioned-menu"
                                                anchorEl={anchorEl}
                                                open={open1}
                                                onClose={handleClose1}
                                                anchorOrigin={{
                                                  vertical: "top",
                                                  horizontal: "left",
                                                }}
                                                transformOrigin={{
                                                  vertical: "top",
                                                  horizontal: "left",
                                                }}
                                              >
                                                <MenuItem>
                                                  Update Status
                                                </MenuItem>
                                                <MenuItem
                                                  onClick={() => {
                                                    setUpdateStatus(
                                                      "waiting_for_drop_off"
                                                    );
                                                    handleStatus();
                                                  }}
                                                  disabled={true}
                                                  style={{
                                                    ...styleTag,
                                                    backgroundColor: "#75DBFF",
                                                  }}
                                                >
                                                  Waiting for Drop Off
                                                </MenuItem>

                                                <MenuItem
                                                  onClick={() => {
                                                    setUpdateStatus(
                                                      "vehicle_dropped_off"
                                                    );
                                                    if (
                                                      status ==
                                                      "waiting_for_drop_off"
                                                    ) {
                                                      handleStatus();
                                                    } else {
                                                      UpdateStatus(
                                                        selectId,
                                                        "vehicle_dropped_off"
                                                      );
                                                    }
                                                  }}
                                                  disabled={
                                                    status ==
                                                      "waiting_for_drop_off" ||
                                                    status == "in_progress"
                                                      ? false
                                                      : true
                                                  }
                                                  style={{
                                                    ...styleTag,
                                                    background: "#FFC30F",
                                                  }}
                                                >
                                                  Vehicle Dropped Off
                                                </MenuItem>

                                                <MenuItem
                                                  onClick={() => {
                                                    setUpdateStatus(
                                                      "in_progress"
                                                    );
                                                    if (
                                                      status ==
                                                      "waiting_for_drop_off"
                                                    ) {
                                                      handleStatus();
                                                    } else {
                                                      UpdateStatus(
                                                        selectId,
                                                        "in_progress"
                                                      );
                                                    }
                                                  }}
                                                  disabled={
                                                    status ==
                                                      "waiting_for_drop_off" ||
                                                    status ==
                                                      "vehicle_dropped_off" ||
                                                    status ==
                                                      "ready_for_pick_up"
                                                      ? false
                                                      : true
                                                  }
                                                  style={{
                                                    ...styleTag,
                                                    background: "#FF9E31",
                                                  }}
                                                >
                                                  In Progress
                                                </MenuItem>

                                                <MenuItem
                                                  onClick={() => {
                                                    setUpdateStatus(
                                                      "ready_for_pick_up"
                                                    );

                                                    setAppointmentDropOffODo(
                                                      null
                                                    );
                                                    setAppointmentCompletionOffODo(
                                                      null
                                                    );

                                                    let found = columns?.[
                                                      status
                                                    ]?.items?.filter(
                                                      (d) => d._id == selectId
                                                    );

                                                    if (
                                                      Array.isArray(found) &&
                                                      found?.[0]?.dropOffODO
                                                    ) {
                                                      setAppointmentDropOffODo(
                                                        found?.[0]?.dropOffODO
                                                      );
                                                    }
                                                    if (
                                                      Array.isArray(found) &&
                                                      found?.[0]?.completionODO
                                                    ) {
                                                      setAppointmentCompletionOffODo(
                                                        found?.[0]
                                                          ?.completionODO
                                                      );
                                                    }

                                                    if (
                                                      status ==
                                                        "waiting_for_drop_off" ||
                                                      status ==
                                                        "vehicle_dropped_off" ||
                                                      status == "in_progress"
                                                    ) {
                                                      handleStatus();
                                                    } else {
                                                      UpdateStatus(
                                                        selectId,
                                                        "ready_for_pick_up"
                                                      );
                                                    }
                                                  }}
                                                  disabled={
                                                    status ==
                                                      "waiting_for_drop_off" ||
                                                    status ==
                                                      "vehicle_dropped_off" ||
                                                    status == "in_progress"
                                                      ? false
                                                      : true
                                                  }
                                                  style={{
                                                    ...styleTag,
                                                    background: "#5CE469",
                                                  }}
                                                >
                                                  Ready for Pick Up
                                                </MenuItem>
                                                <MenuItem
                                                  onClick={() => {
                                                    setUpdateStatus(
                                                      "customer_paid"
                                                    );
                                                    setAppointmentDropOffODo(
                                                      null
                                                    );
                                                    setAppointmentCompletionOffODo(
                                                      null
                                                    );

                                                    let found = columns?.[
                                                      status
                                                    ]?.items?.filter(
                                                      (d) => d._id == selectId
                                                    );

                                                    if (
                                                      Array.isArray(found) &&
                                                      found?.[0]?.dropOffODO
                                                    ) {
                                                      setAppointmentDropOffODo(
                                                        found?.[0]?.dropOffODO
                                                      );
                                                    }
                                                    if (
                                                      Array.isArray(found) &&
                                                      found?.[0]?.completionODO
                                                    ) {
                                                      setAppointmentCompletionOffODo(
                                                        found?.[0]
                                                          ?.completionODO
                                                      );
                                                    }
                                                    if (
                                                      status ==
                                                        "waiting_for_drop_off" ||
                                                      status ==
                                                        "vehicle_dropped_off" ||
                                                      status == "in_progress"
                                                    ) {
                                                      handleStatus();
                                                    } else {
                                                      UpdateStatus(
                                                        selectId,
                                                        "customer_paid"
                                                      );
                                                    }
                                                  }}
                                                  disabled={
                                                    status ==
                                                      "waiting_for_drop_off" ||
                                                    status ==
                                                      "vehicle_dropped_off" ||
                                                    status == "in_progress" ||
                                                    status ==
                                                      "ready_for_pick_up"
                                                      ? false
                                                      : true
                                                  }
                                                  style={{
                                                    ...styleTag,
                                                    background: "#00CD44",
                                                  }}
                                                >
                                                  Customer Paid
                                                </MenuItem>
                                                <MenuItem
                                                  onClick={() => {
                                                    setUpdateStatus(
                                                      "mark_as_no_show"
                                                    );
                                                    UpdateStatus(
                                                      selectId,
                                                      "mark_as_no_show"
                                                    );
                                                  }}
                                                  disabled={
                                                    status ==
                                                    "waiting_for_drop_off"
                                                      ? false
                                                      : true
                                                  }
                                                  style={{
                                                    ...styleTag,
                                                    background: "#F44336",
                                                  }}
                                                >
                                                  Mark as No Show
                                                </MenuItem>
                                              </Menu>
                                            </Grid>
                                          </Grid>
                                        </Card>
                                      </Grid>
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </div>
              </div>
            );
          })}
        </DragDropContext>
      </div>
      {(status == "waiting_for_drop_off" ||
        status == "in_progress" ||
        status == "vehicle_dropped_off") && (
        <AddOdoModal
          open={show}
          handleClose={handleStatusClose}
          updateStatus={updateStatus}
          id={dragId ? dragId : selectId}
          handleClose1={handleClose1}
          currentStatus={status}
          handleClose2={handleClose}
          appointmentDropOffODO={appointmentDropOffODO}
          appointmentCompletionOffODO={appointmentCompletionOffODO}
          setAppointmentCompletionOffODo={setAppointmentCompletionOffODo}
          setAppointmentDropOffODo={setAppointmentDropOffODo}
          appointmentView={() => {}}
        />
      )}

      <AppointmentModel
        open={open}
        handleClose={handleClose}
        data={data}
        status={status}
        show={show}
        handleStatusClose={handleStatusClose}
        handleStatus={handleStatus}
        recallDetailsAPI={details}
        setAppointmentCompletionOffODo={setAppointmentCompletionOffODo}
        setAppointmentDropOffODo={setAppointmentDropOffODo}
        columns={columns}
        setUpdateStatus={setUpdateStatus}
        setSelectId={setSelectId}
      />
      <Dialog open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TodayAppointment;
