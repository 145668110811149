import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  InputAdornment,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import React, { useEffect } from "react";

import { useTheme } from "@emotion/react";
import { Controller, useForm } from "react-hook-form"; // Step 1
import { useDispatch } from "react-redux";
import { toast } from "sonner";
import { REQUIRED_RED } from "../../App";
import { FormatNumberForKm } from "../../components/FormatNumber/FormatNumber";
import { updateAppointmentStatus } from "../../redux/appointment/action";
import SuccessMessageModal from "../Common/SuccessMessage";

const select_category = [
  { label: "Inglourious Basterds", year: 2009 },
  { label: "Snatch", year: 2000 },
  { label: "3 Idiots", year: 2009 },
  { label: "Monty Python and the Holy Grail", year: 1975 },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const textAreaStyle = {
  width: "670px",
  height: "100px",
  fontSize: "16px",
  border: "1px solid #ccc",
  borderRadius: "4px",
  padding: "10px",
};

const AddOdoModal = ({
  open,
  handleClose,
  updateStatus,
  id,
  handleClose1,
  currentStatus,
  handleClose2,
  appointmentDropOffODO,
  appointmentCompletionOffODO,
  setAppointmentCompletionOffODo,
  setAppointmentDropOffODo,
  appointmentView,
  fetchCalendarDataonAppointmentStatusChange,
  recallDetailsAPI,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm();
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = React.useState(false);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [odoValidation, setOdoValidation] = React.useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const path = window.location.pathname.split("/");
  const onSubmit = async (values) => {
    if (values?.odo && !/^[0-9,]+$/.test(values?.odo)) {
      setOdoValidation("Please enter proper number value");
      return;
    }
    if (values?.completionODO && !/^[0-9,]+$/.test(values?.completionODO)) {
      setOdoValidation("Please enter proper number value");
      return;
    }

    let payload;
    if (values?.completionODO && values?.odo) {
      let dropOdo = values?.odo.toString()?.replace(/,/g, "");
      let pickUpOdo = values?.completionODO.toString()?.replace(/,/g, "");

      if (+pickUpOdo < +dropOdo) {
        return toast.info("Pick Up ODO should be greater than Drop Off ODO");
      }
      payload = {
        status: updateStatus,
        odo: values?.odo?.toString()?.replace(/,/g, ""),
        completionODO: values?.completionODO?.toString()?.replace(/,/g, ""),
      };
    } else if (values?.odo) {
      payload = {
        status: updateStatus,
        odo: values?.odo?.toString()?.replace(/,/g, ""),
      };
    } else {
      payload = {
        status: updateStatus,
      };
    }
    setShowLoader(true);
    const statusUpdate = await dispatch(updateAppointmentStatus(id, payload));

    if (statusUpdate) {
      setOpenSuccessModal(true);
      setSuccessMessage("Appointment status updated successfully");
      handleClose();
      handleClose1();
      handleClose2();
      appointmentView();
      setValue("odo", "");
      setValue("completionODO", "");
    }
    setShowLoader(false);
    if (path.includes("calendar")) {
      fetchCalendarDataonAppointmentStatusChange();
    }
    if (path.includes("workOrderAuthorization")) {
      recallDetailsAPI();
    }
  };
  const handleSkipOdo = async () => {
    let payload = {
      status: updateStatus,
    };
    setShowLoader(true);
    const statusUpdate = await dispatch(updateAppointmentStatus(id, payload));

    if (statusUpdate) {
      setOpenSuccessModal(true);
      setSuccessMessage("Appointment status updated successfully");
      handleClose();
      handleClose1();
      handleClose2();
      appointmentView();
    }
    setShowLoader(false);
    if (path.includes("calendar")) {
      fetchCalendarDataonAppointmentStatusChange();
    }
  };
  const handleCancel = () => {
    handleClose();
    handleClose1();
    setAppointmentCompletionOffODo(null);
    setAppointmentDropOffODo(null);
    // reset();
    setValue("odo", "");
    setValue("completionODO", "");
  };

  useEffect(() => {
    setValue("odo", FormatNumberForKm(appointmentDropOffODO));
    setValue("completionODO", FormatNumberForKm(appointmentCompletionOffODO));
  }, [appointmentDropOffODO, appointmentCompletionOffODO]);

  return (
    <Box>
      <Grid container>
        <Grid item xs={9} md={9} xl={9}>
          <Modal
            open={open}
            onClose={handleCancel}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              {" "}
              {/* Step 3 */}
              <Box
                sx={{
                  ...style,
                  height: "fit-content",
                  width: "720px",
                  border: "none",
                  padding: "18px",
                  flexDirection: "row",
                }}
              >
                <AppBar
                  variant="elevation"
                  style={{
                    height: "64px",
                    boxShadow: "none",
                    backgroundColor: "#002394",
                    padding: "18px",
                    flexDirection: "row",
                  }}
                >
                  <Typography variant="h6" fontWeight={500} width={"98%"}>
                    Enter Odometer
                  </Typography>
                  <Stack direction={"row"} spacing={0}>
                    <div
                      onClick={handleClose}
                      style={{ cursor: "pointer", height: "32px" }}
                    >
                      <CloseIcon style={{ fontSize: 32 }} />
                    </div>
                  </Stack>
                </AppBar>
                <Container gap={2} disableGutters style={{ marginTop: "66px" }}>
                  <Grid container padding={"4px 6px"}>
                    <Grid container item justifyContent={"start"} columnGap={4}>
                      <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                        <Grid
                          item
                          lg={12}
                          xl={12}
                          md={12}
                          sm={8.9}
                          xs={8.9}
                          style={{
                            backgroundColor: "#f0f0f0",
                            padding: "10px",
                            width: "150%",
                            height: "100px",
                            borderRadius: "8px",
                          }}
                        >
                          <Stack spacing={2}>
                            <Typography
                              variant="body1"
                              fontWeight={500}
                              maxWidth={"100%"}
                              fontSize={"16px"}
                              letterSpacing={"0.15px"}
                              padding={"9px 12px"}
                            >
                              To comply with Ontario regulations, it is
                              essential to record the vehicle’s odometer
                              readings both when it is dropped off and picked up
                              in order to generate the final invoice.
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                    {(currentStatus == "waiting_for_drop_off" &&
                      updateStatus == "ready_for_pick_up") ||
                    (currentStatus == "waiting_for_drop_off" &&
                      updateStatus == "customer_paid") ||
                    (currentStatus == "vehicle_dropped_off" &&
                      updateStatus == "ready_for_pick_up") ||
                    (currentStatus == "vehicle_dropped_off" &&
                      updateStatus == "customer_paid") ||
                    (currentStatus == "in_progress" &&
                      updateStatus == "ready_for_pick_up") ||
                    (currentStatus == "in_progress" &&
                      updateStatus == "customer_paid") ? (
                      <>
                        <Grid
                          container
                          item
                          justifyContent={"start"}
                          columnGap={4}
                          marginTop={"24px"}
                        >
                          <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                            <Stack spacing={1}>
                              <Typography
                                variant="body1"
                                style={{
                                  fontWeight: 500,
                                  fontSize: "16px",
                                  lineHeight: "22px",
                                }}
                              >
                                Drop Off ODO
                              </Typography>

                              <Controller
                                name="odo"
                                control={control}
                                render={({ field }) => (
                                  <>
                                    <TextField
                                      fullWidth
                                      required
                                      // type="number"
                                      id="outlined-end-adornment"
                                      variant="outlined"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            km
                                          </InputAdornment>
                                        ),
                                      }}
                                      {...field}
                                      onChange={(event) => {
                                        setValue(
                                          "odo",
                                          FormatNumberForKm(event.target.value)
                                        );
                                      }}
                                    />
                                  </>
                                )}
                              />
                            </Stack>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          item
                          justifyContent={"start"}
                          columnGap={4}
                          marginTop={"20px"}
                        >
                          <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                            <Stack spacing={1}>
                              <Typography
                                variant="body1"
                                style={{
                                  fontWeight: 500,
                                  fontSize: "16px",
                                  lineHeight: "22px",
                                }}
                              >
                                Pick Up ODO
                              </Typography>

                              <Controller
                                name="completionODO"
                                control={control}
                                render={({ field }) => (
                                  <>
                                    <TextField
                                      fullWidth
                                      required
                                      // type="number"
                                      id="outlined-end-adornment"
                                      variant="outlined"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            km
                                          </InputAdornment>
                                        ),
                                      }}
                                      {...field}
                                      onChange={(event) => {
                                        setValue(
                                          "completionODO",
                                          FormatNumberForKm(event.target.value)
                                        );
                                      }}
                                    />
                                  </>
                                )}
                              />
                              <span style={{ color: REQUIRED_RED }}>
                                {odoValidation}
                              </span>
                            </Stack>
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid
                          container
                          item
                          justifyContent={"start"}
                          columnGap={4}
                          marginTop={"24px"}
                        >
                          <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                            <Stack spacing={1}>
                              <Typography
                                variant="body1"
                                style={{
                                  fontWeight: 500,
                                  fontSize: "16px",
                                  lineHeight: "22px",
                                }}
                              >
                                Enter ODO
                              </Typography>

                              <Controller
                                name="odo"
                                control={control}
                                render={({ field }) => (
                                  <>
                                    <TextField
                                      fullWidth
                                      required
                                      // type="number"
                                      id="outlined-end-adornment"
                                      variant="outlined"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            km
                                          </InputAdornment>
                                        ),
                                      }}
                                      {...field}
                                      onChange={(event) => {
                                        setValue(
                                          "odo",
                                          FormatNumberForKm(event.target.value)
                                        );
                                      }}
                                    />
                                  </>
                                )}
                              />
                              <span style={{ color: REQUIRED_RED }}>
                                {odoValidation}
                              </span>
                            </Stack>
                          </Grid>
                        </Grid>
                      </>
                    )}

                    <Grid
                      item
                      container
                      // justifyContent={"space-between"}
                      spacing={2}
                      style={{ marginTop: "0px", marginBottom: "2px" }}
                    >
                      <Grid item xs={12}>
                        <Stack
                          spacing={2}
                          direction="row"
                          // justifyContent={"space-between"}
                          justifyContent={"end"}
                        >
                          {/* <Button
                            variant="text"
                            color="primary"
                            // type="submit"
                            sx={{
                              fontSize: "15px",
                              paddingInline: "11px !important",
                            }}
                            onClick={handleSkipOdo}
                          >
                            SKIP ODOMETER
                          </Button> */}
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            SAVE
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            </form>
          </Modal>
        </Grid>
      </Grid>
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AddOdoModal;
