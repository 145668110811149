import { API } from "../../config/api/index";

//ALL TYPE WISE DATA RETURN
const initState = {
  loading: false,
  customerList: {},
  customerDetail: {},
  customerVehicle: {},
  customerAppointment: {},
  customerNotes: {},
  vehicleYear: {},
  vehicleMake: {},
  vehicleModel: {},
  vehicleTrim: {},
  customerData: {},
  notesList: {},
  mergeCustomerList: [],
  mergeCustomerData: [],
  customerImportList: [],
  carfaxHistory: {},
};

//DEFINE ACCOUNT REDUCER FUNCTION
const CustomerReducer = (state = initState, action) => {
  switch (action.type) {
    case API.customer.list:
      return {
        ...state,
        customerList: action.customerList,
        loading: action.loading,
      };
    case API.customer.getCustomerList:
      return {
        ...state,
        customerData: action.customerData,
        loading: action.loading,
      };
    case API.customer.detail:
      return {
        ...state,
        customerDetail: action.customerDetail,
        loading: action.loading,
      };
    case API.customer.vehicles:
      return {
        ...state,
        customerVehicle: action.customerVehicle,
        loading: action.loading,
      };
    case API.customer.appointments:
      return {
        ...state,
        customerAppointment: action.customerAppointment,
        loading: action.loading,
      };
    case API.customer.notes:
      return {
        ...state,
        customerNotes: action.customerNotes,
        loading: action.loading,
      };
    case API.customer.vehicleYear:
      return {
        ...state,
        vehicleYear: action.vehicleYear,
        loading: action.loading,
      };
    case API.customer.vehicleMake:
      return {
        ...state,
        vehicleMake: action.vehicleMake,
        loading: action.loading,
      };

    case API.customer.vehicleModel:
      return {
        ...state,
        vehicleModel: action.vehicleModel,
        loading: action.loading,
      };
    case API.customer.vehicleTrim:
      return {
        ...state,
        vehicleTrim: action.vehicleTrim,
        loading: action.loading,
      };
    case API.customer.notesList:
      return {
        ...state,
        notesList: action.notesList,
        loading: action.loading,
      };
    case API.customer.mergeCustomerList:
      return {
        ...state,
        mergeCustomerList: action.mergeList,
        loading: action.loading,
      };
    case API.customer.mergeCustomerById:
      return {
        ...state,
        mergeCustomerData: action.mergeCustomerById,
        loading: action.loading,
      };
    case API.customer.importCustomerList:
      return {
        ...state,
        customerImportList: action.importCustomerList,
        loading: action.loading,
      };
    case API.customer.getCarfaxHistory:
      return {
        ...state,
        carfaxHistory: action.carfaxHistory,
        loading: action.loading,
      };

    default:
      return state;
  }
};

//EXPORT ALL FUNCTION
export default CustomerReducer;
