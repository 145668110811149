import { Add, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useTheme } from "@emotion/react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import FileUpload from "../../../components/FileUpload/FileUpload";
import FormatNumber from "../../../components/FormatNumber/FormatNumber";
import {
  createApproval,
  getNewApprovals,
} from "../../../redux/Approvals/action";
import { appointmentDetails } from "../../../redux/appointment/action";
import AppointmentModel from "../../Appointment/AppointmentModel";
import Loader from "../../Common/Loader";
import SuccessMessageModal from "../../Common/SuccessMessage";
import ServiceModal from "../../modal/ServiceModal";
import "../CurrentApproval/CurrentApproval.css";

function CustomFileUpload() {
  const fileInputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState([]);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      setFiles([...files, selectedFile]);
      setUploadProgress([...uploadProgress, 0]);

      // You can start the upload process here
      uploadFile(selectedFile, files.length);
    }
  };
  const uploadFile = (file, index) => {
    const formData = new FormData();
    formData.append("file", file);

    const xhr = new XMLHttpRequest();
    xhr.open("POST", "/your-upload-endpoint", true);

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const progress = (event.loaded / event.total) * 100;
        const updatedProgress = [...uploadProgress];
        updatedProgress[index] = progress;
        setUploadProgress(updatedProgress);
      }
    };

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        // File upload is complete
        console.log("File upload complete");
      }
    };

    xhr.send(formData);
  };

  return (
    <div>
      <input
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      <Grid container>
        <Grid item xs={6}></Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        onClick={handleUploadClick}
        startIcon={<CloudUploadIcon />}
      >
        Upload File
      </Button>
      <ul>
        {files.map((file, index) => (
          <li key={index}>
            {file.name} -{" "}
            {uploadProgress[index]
              ? `${uploadProgress[index].toFixed(2)}%`
              : "Uploading..."}
          </li>
        ))}
      </ul>
    </div>
  );
}

const NewApprovals = () => {
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [commingFromModalData, setCommingFromModalData] = React.useState([]);
  const [commingPartsFromModalData, setCommingPartsFromModalData] =
    React.useState([]);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [editServiceData, setEditServiceData] = useState({
    edit: false,
    index: "",
  });
  const [totals, setTotals] = useState({
    tax: 0,
    estimatedTotal: 0,
  });
  const [openServiceModal, setOpenServiceModal] = useState(false);

  const [showLoader, setShowLoader] = useState(false);
  const [openDetail, setOpenDetail] = React.useState(false);
  const [status, setStatus] = useState("");
  const [show, setShow] = React.useState(false);
  const [data, setData] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleOpenServiceModal = () => {
    setOpenServiceModal(true);
    calculation1();
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    calculation1();
  };
  const [open1, setOpen1] = React.useState(false);

  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => {
    setOpen1(false);
    setOpen(false);
  };
  const [open2, setOpen2] = React.useState(false);

  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => {
    setOpen2(false);
    setOpen(false);
  };
  const [open3, setOpen3] = React.useState(false);

  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => {
    setOpen3(false);
  };

  const [discTaxes, setDiscTaxes] = useState({
    disc: 0,
    taxes: 0,
    total: 0,
    taxesinDollar: 0,
  });

  const [imagesFile, setImagesFile] = useState([]);

  useEffect(() => {
    // calculationTotal();
    // getApprovalData("653cd337390c50b814e50e5c");
  }, []);

  useEffect(() => {
    calculation1();
  }, [commingFromModalData]);

  const calculation1 = () => {
    let subTotal = 0;

    commingFromModalData?.map((service) => {
      if (service?.pricingType == "fixed") {
        subTotal += +service?.fixedPrice;
      } else {
        subTotal += service?.laborQuantity * service?.laborRate;
        service?.parts?.map((part) => {
          subTotal += +part?.totalAmount;
        });
      }
    });

    setTotals({
      estimatedTotal: subTotal,
    });
  };

  // useEffect(() => {
  //   calculationTotal();
  // }, [commingFromModalData.length, commingFromModalData?.parts?.length]);

  const getApprovalData = async (id) => {
    const res = await dispatch(getNewApprovals(id));
  };
  const { id } = useParams();
  const onSubmitNewApprovals = async (data) => {
    const payload = {
      appointmentId: id,
      noteForCustomer: data.noteForCustomer,
    };
    // discount: totals?.discount,
    // tax: totals?.tax,
    // subTotal: totals?.subTotal,
    // Total: totals?.estimatedTotal,

    for (let rowIdx = 0; rowIdx < commingFromModalData.length; rowIdx++) {
      payload[`services[${rowIdx}][serviceId]`] =
        commingFromModalData[rowIdx]?.service?._id;
      // "64f05046cd89fb67db694928";

      payload[`services[${rowIdx}][serviceName]`] =
        commingFromModalData[rowIdx]?.service?.name;
      payload[`services[${rowIdx}][pricingType]`] =
        commingFromModalData[rowIdx]?.pricingType == "fixed"
          ? "fixed"
          : "labor_&_parts";

      if (commingFromModalData[rowIdx]?.pricingType == "fixed") {
        payload[`services[${rowIdx}][fixedPrice]`] =
          commingFromModalData[rowIdx]?.fixedPrice;
      } else {
        payload[`services[${rowIdx}][labor][qty]`] =
          commingFromModalData[rowIdx]?.laborQuantity;
        payload[`services[${rowIdx}][labor][ratePerHour]`] =
          commingFromModalData[rowIdx]?.laborRate;
        payload[`services[${rowIdx}][labor][totalAmount]`] = parseInt(
          parseInt(commingFromModalData[rowIdx]?.laborQuantity) *
            parseInt(commingFromModalData[rowIdx]?.laborRate)
        );
        for (let j = 0; j < commingFromModalData[rowIdx]?.parts?.length; j++) {
          payload[`services[${rowIdx}][parts][${j}][partName]`] =
            commingFromModalData[rowIdx]?.parts[j].partName;

          payload[`services[${rowIdx}][parts][${j}][partNumber]`] =
            commingFromModalData[rowIdx]?.parts[j].partNumber;
          payload[`services[${rowIdx}][parts][${j}][description]`] =
            commingFromModalData[rowIdx]?.parts[j].description;
          payload[`services[${rowIdx}][parts][${j}][qty]`] =
            commingFromModalData[rowIdx]?.parts[j].qty;
          payload[`services[${rowIdx}][parts][${j}][totalAmount]`] =
            commingFromModalData[rowIdx]?.parts[j].totalAmount;
          payload[`services[${rowIdx}][parts][${j}][partsProvider]`] =
            commingFromModalData[rowIdx]?.parts[j].partsProvider;
          payload[`services[${rowIdx}][parts][${j}][cost]`] =
            commingFromModalData[rowIdx]?.parts[j].cost;
        }
      }
    }
    for (let i = 0; i < imagesFile.length; i++) {
      payload[`images[${i}]`] = imagesFile[i];
    }
    setShowLoader(true);
    let res = await dispatch(createApproval(payload));
    if (res) {
      setOpenSuccessModal(true);
      setSuccessMessage("Changes Saved");
      navigate("/approvals/waiting");
    }
    setShowLoader(false);
  };

  const removeServicesClicked = (idx) => {
    const newdata = [...commingFromModalData];
    let newArray = [...newdata.slice(0, idx), ...newdata.slice(idx + 1)];
    setCommingFromModalData(newArray);
  };

  const EditServiceHandler = (idx) => {
    // handleOpen();
    handleOpenServiceModal();

    setEditServiceData({ edit: true, index: idx });
  };

  if (showLoader) {
    return <Loader showLoader={showLoader} />;
  }

  const details = async () => {
    setLoading(true);
    if (id) {
      const detailView = await dispatch(appointmentDetails(id));
      if (detailView) {
        setData(detailView);
        setStatus(detailView?.status);
        setOpenDetail(true);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
  };
  const handleStatus = () => setShow(true);
  const handleStatusClose = () => {
    setShow(false);
  };

  return (
    <Box padding={"32px 24px"}>
      {" "}
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
      {/* <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog> */}
      <form
        onSubmit={handleSubmit(onSubmitNewApprovals)}
        style={{ marginTop: "20px" }}
      >
        <Grid container sx={{ padding: "16px 0px" }} gap={"8px"}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div role="presentation" onClick={handleClick}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  to="/appointment/today"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  Appointments
                </Link>
                <Link style={{ color: "black", textDecoration: "none" }}>
                  New Approval
                </Link>
              </Breadcrumbs>
            </div>
          </Grid>

          <Grid item lg={12}>
            <Grid container item justifyContent={"space-between"}>
              <Grid item lg={9}>
                <Typography variant="h4" sx={{ fontWeight: "500" }}>
                  New Approval
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  disabled={loading}
                  style={{ color: "#002394", border: "1px solid #00239480" }}
                  onClick={() => {
                    details();
                  }}
                  className="btn-text-size-manage"
                >
                  {loading && (
                    <div className="btn-loader">
                      <CircularProgress />
                    </div>
                  )}{" "}
                  VIEW APPOINTMENT DETAILS
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            container
            item
            justifyItems={"space-between"}
            boxShadow={2}
            marginTop={"32px"}
            padding={"24px"}
          >
            <Grid item container justifyContent={"space-between"}>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Typography variant="h5" fontWeight={400}>
                  Services
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  color="secondary"
                  variant="contained"
                  startIcon={<Add />}
                  onClick={() => {
                    handleOpenServiceModal();

                    setEditServiceData({ edit: false, index: "" });
                  }}
                  className="btn-text-size-manage"
                >
                  ADD SERVICE
                </Button>
              </Grid>
            </Grid>
            {commingFromModalData?.map((data, index) => {
              return (
                <>
                  {data?.pricingType == "fixed" ? (
                    <>
                      <Grid
                        container
                        item
                        style={{
                          backgroundColor: "#c5d0da",
                          color: "black",
                          marginTop: "20px",
                        }}
                      >
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography
                            variant="h6"
                            fontWeight={"500"}
                            padding={"16px"}
                          >
                            {data?.service?.name}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container item>
                        <Grid
                          item
                          xl={12}
                          md={12}
                          sm={12}
                          xs={12}
                          boxShadow={0}
                        >
                          <Table
                            aria-label="caption table"
                            padding="16px"
                            style={{ boxShadow: 0 }}
                          >
                            <TableHead
                              style={{
                                backgroundColor: "#f4f6f8",
                                fontWeight: 500,
                              }}
                            >
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    variant="table/header"
                                    fontWeight={500}
                                  >
                                    {" "}
                                    No.
                                  </Typography>
                                </TableCell>
                                <TableCell width={"600px"}>
                                  {" "}
                                  Description
                                </TableCell>
                                <TableCell> Quantity</TableCell>
                                <TableCell> Cost</TableCell>
                                <TableCell> Amount</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow key={1}>
                                <TableCell component="th" scope="row">
                                  1
                                </TableCell>
                                <TableCell align="left">
                                  {data?.service?.name}
                                </TableCell>
                                <TableCell align="left">1</TableCell>{" "}
                                <TableCell align="left">
                                  {/* ${formatNumber(data?.fixedPrice)} */}

                                  <FormatNumber inputValue={data?.fixedPrice} />
                                </TableCell>
                                <TableCell align="left">
                                  <FormatNumber inputValue={data?.fixedPrice} />
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                          {/* <Grid
                            container
                            item
                            justifyContent={"end"}
                            padding={"24px"}
                          >
                            <Grid item>
                              <Stack direction="row" spacing={2}>
                                <Button
                                  variant="contained"
                                  startIcon={<Edit />}
                                  onClick={() => {
                                    EditServiceHandler(data);
                                  }}
                                  className="btn-text-size-manage"
                                >
                                  EDIT SERVICEgf
                                </Button>
                               
                                <Button
                                  variant="contained"
                                  color="error"
                                  startIcon={<Delete />}
                                  className="btn-text-size-manage"
                                  onClick={() => {
                                    removeServicesClicked(index);
                                  }}
                                >
                                  REMOVE SERVICE
                                </Button>
                              </Stack>
                            </Grid>{" "}
                          </Grid> */}
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid
                        container
                        item
                        style={{
                          backgroundColor: "#c5d0da",
                          color: "black",
                          marginTop: "20px",
                        }}
                      >
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography
                            variant="h6"
                            fontWeight={"500"}
                            padding={"16px"}
                          >
                            {data?.service?.name}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container item>
                        <Grid
                          item
                          xl={12}
                          md={12}
                          sm={12}
                          xs={12}
                          boxShadow={0}
                        >
                          <Table
                            aria-label="caption table"
                            padding="16px"
                            style={{ boxShadow: 0 }}
                          >
                            <TableHead
                              style={{
                                backgroundColor: "#f4f6f8",
                                fontWeight: 500,
                              }}
                            >
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    variant="table/header"
                                    fontWeight={500}
                                  >
                                    {" "}
                                    No.
                                  </Typography>
                                </TableCell>
                                <TableCell width={"600px"}>
                                  {" "}
                                  Description
                                </TableCell>
                                <TableCell> Quantity</TableCell>
                                <TableCell> Cost</TableCell>
                                <TableCell> Amount</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow key={0}>
                                <TableCell component="th" scope="row">
                                  {1}
                                </TableCell>
                                <TableCell align="left">Labor</TableCell>

                                <TableCell align="left">
                                  {data?.laborQuantity}
                                </TableCell>
                                <TableCell align="left">
                                  <FormatNumber inputValue={data?.laborRate} />
                                </TableCell>
                                <TableCell align="left">
                                  <FormatNumber
                                    inputValue={`${
                                      data?.laborQuantity * data?.laborRate
                                    }`}
                                  />
                                </TableCell>
                              </TableRow>
                              {data?.parts?.map((row, idx) => (
                                <TableRow key={idx}>
                                  <TableCell component="th" scope="row">
                                    {idx + 2}
                                  </TableCell>
                                  <TableCell align="left">
                                    <Typography
                                      variant="body2"
                                      fontWeight={400}
                                    >
                                      {row?.partName}
                                    </Typography>

                                    <Typography fontWeight={400} fontSize={12}>
                                      Part Provider:{" "}
                                      {row?.partsProvider == "used"
                                        ? "Used"
                                        : row.partsProvider == "oem"
                                        ? "OEM"
                                        : "After Market"}
                                    </Typography>
                                    {row?.partNumber && (
                                      <Typography
                                        variant="body2"
                                        fontWeight={400}
                                        fontSize={12}
                                      >
                                        Part Number: {row?.partNumber}
                                      </Typography>
                                    )}
                                    {row?.description && (
                                      <Typography variant="body2">
                                        Description: {row?.description}
                                      </Typography>
                                    )}
                                  </TableCell>
                                  <TableCell align="left">{row.qty}</TableCell>
                                  <TableCell align="left">
                                    <FormatNumber inputValue={row.cost} />
                                  </TableCell>
                                  <TableCell align="left">
                                    <FormatNumber
                                      inputValue={row.totalAmount}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                          {/* <Grid
                            container
                            item
                            justifyContent={"end"}
                            padding={"24px"}
                          >
                            <Grid item>
                              <Stack direction="row" spacing={2}>
                                <Button
                                  variant="contained"
                                  startIcon={<Edit />}
                                  onClick={handleOpen2}
                                  className="btn-text-size-manage"
                                >
                                  EDIT SERVICE
                                </Button>
                                <EditServiceModal
                                  open2={open2}
                                  handleClose2={handleClose2}
                                  handleOpen1={handleOpen1}
                                  handleOpen2={handleOpen2}
                                />
                                <Button
                                  variant="contained"
                                  color="error"
                                  startIcon={<Delete />}
                                  className="btn-text-size-manage"
                                >
                                  REMOVE SERVICE
                                </Button>
                              </Stack>
                            </Grid>{" "}
                          </Grid> */}
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <Grid container item justifyContent={"end"} padding={"24px"}>
                    <Grid item>
                      <Stack direction="row" spacing={2}>
                        <Button
                          variant="contained"
                          startIcon={<Edit />}
                          onClick={() => {
                            EditServiceHandler(index);
                          }}
                          className="btn-text-size-manage"
                        >
                          EDIT SERVICE
                        </Button>

                        <Button
                          variant="contained"
                          color="error"
                          startIcon={<Delete />}
                          className="btn-text-size-manage"
                          onClick={() => {
                            removeServicesClicked(index);
                          }}
                        >
                          REMOVE SERVICE
                        </Button>
                      </Stack>
                    </Grid>{" "}
                  </Grid>
                </>
              );
            })}

            <Divider
              style={{
                width: "100%",
                borderBottomStyle: "solid",
                borderBottomWidth: "1px",
                height: "1px",
                paddingTop: "16px",
              }}
            />

            {/* <Grid container item justifyContent={"end"} marginTop={"32px"}>
              <Grid item>
                <Stack direction="row" spacing={2}></Stack>
              </Grid>
            </Grid> */}

            <Grid
              container
              item
              justifyContent={"end"}
              marginTop={"32px"}
              padding={" 0 23px"}
              spacing={1}
            >
              {/* <Grid
                item
                container
                justifyContent={"end"}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <Grid item></Grid>
              </Grid>
              <Grid
                item
                container
                justifyContent={"end"}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              ></Grid>
              <Grid
                item
                container
                justifyContent={"end"}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <Grid item></Grid>
              </Grid> */}
              <Grid
                item
                container
                justifyContent={"end"}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <Grid item>
                  <Typography variant="body1" fontWeight={400}>
                    {/* Estimated Total&#160; &#160; $
                    {parseInt(
                      parseInt(discTaxes.total * 1).toFixed(2) -
                        parseInt(discTaxes.disc * 1).toFixed(2) +
                        parseInt(discTaxes.taxesinDollar * 1).toFixed(2)
                    ).toFixed(2)} */}
                    Total&#160; &#160;
                    <FormatNumber inputValue={totals?.estimatedTotal} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            padding={"24px"}
            marginTop={"32px"}
            boxShadow={2}
          >
            <Grid item>
              <FileUpload
                sendBackUploadedFile={(data) => {
                  setImagesFile(data);
                }}
              />
            </Grid>

            {/* {CustomFileUpload()} */}
          </Grid>
          <Grid
            container
            item
            padding={"24px"}
            boxShadow={2}
            marginTop={"32px"}
          >
            <Grid item xl={12} md={12} sm={12} xs={12} boxShadow={0}>
              <Typography variant="h5" fontWeight={400} marginBottom={"16px"}>
                Note for the Customer
              </Typography>
            </Grid>
            <Grid item xl={12} md={12} sm={12} xs={12} boxShadow={0}>
              <Controller
                name="noteForCustomer"
                control={control}
                // rules={{
                //   required: "Shop Name is required",
                // }}
                render={({ field }) => (
                  <TextField
                    id="outlined-basic"
                    // label="Shop Name"
                    variant="outlined"
                    fullWidth
                    // required
                    error={!!errors.noteForCustomer}
                    helperText={
                      errors.noteForCustomer
                        ? errors.noteForCustomer.message
                        : ""
                    }
                    {...field}
                    InputLabelProps={{ shrink: field?.value?.length > 0 }}
                  />
                )}
              />
            </Grid>
            {/* <Grid container item justifyContent={"end"} marginTop={"16px"}>
              <Grid item>
                <Button
                  color="secondary"
                  variant="contained"
                  className="btn-text-size-manage"
                  onClick={() => {}}
                >
                  Add Note
                </Button>
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid container justifyContent={"end"} padding={"32px 0px 0px 0px "}>
          <Grid item>
            <Stack spacing={2} direction={"row"}>
              <Button
                color="secondary"
                variant="contained"
                className="btn-text-size-manage"
                type="submit"
              >
                Send
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
      <ServiceModal
        open={openServiceModal}
        setOpenServiceModal={setOpenServiceModal}
        setAllServices={setCommingFromModalData}
        allServices={commingFromModalData}
        editServiceData={editServiceData}
        setEditServiceData={setEditServiceData}
        // open1={open1}
        // handleClose1={handleClose1}
        // handleClose={handleClose}
        // handleOpen1={handleOpen1}

        // serviceModalDataProps={(data) => {
        //   setCommingFromModalData(data);
        // }}
        // getData={commingFromModalData}
        // editServiceData={editServiceData}
        // servicePartsModalDataProps={(data) => {
        //   setCommingPartsFromModalData(data);
        // }}
      />
      {openDetail && (
        <AppointmentModel
          open={openDetail}
          handleClose={handleCloseDetail}
          data={data}
          status={status}
          show={show}
          handleStatusClose={handleStatusClose}
          handleStatus={handleStatus}
          recallDetailsAPI={details}
        />
      )}
    </Box>
  );
};

export default NewApprovals;
