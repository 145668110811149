import { API } from "../../../config/api";
import { DataService } from "../../../config/dataService/dataService";

// shop details fetch
export const getTimeZones = () => async (dispatch) => {
  try {
    const response = await DataService.get(API.account.shopHours.getTimeZone);
    if (!response.data.error) {
      dispatch({
        type: API.account.shopHours.getTimeZone,
        getTimeZone: response.data.data,
      });
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
export const getOntarioHolidays = (limits, pages, year) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.account.shopHours.ontarioHolidays.getOntarioHolidays}?limit=${limits}&page=${pages}&year=${year}`
      );
      if (response.data.status) {
        dispatch({
          type: API.account.shopHours.ontarioHolidays.getOntarioHolidays,
          getOntarioHolidays: response.data.data,
        });
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const getShopHours = () => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        API.account.shopHours.getShopHours
      );
      if (response.data.status) {
        dispatch({
          type: API.account.shopHours.getShopHours,
          getShopHours: response.data.data,
        });
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const updateOntarioHolidays = (payload, policyId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        `${API.account.shopHours.ontarioHolidays.updateOntarioHolidays}/${policyId}`,
        payload,
        {
          "Content-Type": "multipart/form-data",
        }
      );

      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const getCustomHolidays = (limits, pages) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.account.shopHours.customHolidays.getCustomHolidays}?limit=${limits}&page=${pages}`
      );
      if (response.data.status) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const updateCustomHolidays = (payload, policyId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        `${API.account.shopHours.customHolidays.updateCustomHolidays}/${policyId}`,
        payload,
        {
          "Content-Type": "multipart/form-data",
        }
      );

      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const addCustomHolidays = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(
        API.account.shopHours.customHolidays.addCustomHolidays,
        payload,
        {
          "Content-Type": "multipart/form-data",
        }
      );

      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const updateBusinessHours = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        API.account.shopHours.businessHours.updateBusinessHours,
        payload,
        {
          "Content-Type": "multipart/form-data",
        }
      );

      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const deleteCustomHolidays = (policyId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(
        `${API.account.shopHours.customHolidays.deleteCustomHolidays}/${policyId}`
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const updateOperationalHours = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.put(
        API.account.shopHours.operationalHours.updateOperationHours,
        payload,
        {
          "Content-Type": "multipart/form-data",
        }
      );

      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
