import {
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { toast } from "sonner";
import {
  handlePreventKeyDownDecimal,
  parseNumberFromCommas,
} from "../../utility/helpers/commonFunction";
import { FormatTotalNumbers } from "../FormatNumber/FormatNumber";

const DiscountComponent = ({
  discountId,
  setDiscountId,
  setSelectedDiscount,
  totals,
  setTotals,
  discountList = [],
}) => {
  const [discountError, setDiscountError] = useState(false);

  const handleDiscountChange = (value) => {
    const selectedDiscount = discountList.find(
      (item) => item._id === value.target.value
    );
    setSelectedDiscount(selectedDiscount);
    const discountPercentage = selectedDiscount?.discount?.toFixed(2) || 0;

    setDiscountId(value.target.value);

    const discountValue = parseFloat(
      (totals?.subTotal * discountPercentage) / 100
    ).toFixed(2);

    const tax =
      totals?.taxPercentage > 0
        ? ((totals?.subTotal - discountValue) * totals?.taxPercentage) / 100
        : 0;
    const estimatedTotal = totals?.subTotal - discountValue + tax;

    setTotals({
      ...totals,
      discountPercentage,
      discount: discountValue,
      tax,
      estimatedTotal,
    });

    // Validate discount
    const isValidDiscount =
      totals?.subTotal === 0 || discountValue <= totals?.subTotal;
    setDiscountError(!isValidDiscount);
  };

  return (
    <div style={{ width: "100%" }}>
      <Grid
        container
        item
        justifyContent={"space-between"}
        paddingTop={"24px"}
        paddingBottom={"24px"}
        rowSpacing={2}
        spacing={2}
      >
        <Grid item xs={12} md={4.2} lg={3.1}>
          <FormControl fullWidth lineHeight={"24px"}>
            <InputLabel htmlFor="outlined-adornment-search" lineHeight={"24px"}>
              Discount Program
            </InputLabel>
            <MuiSelect
              style={{ width: "100%" }}
              value={discountId || ""}
              onChange={(value) => {
                handleDiscountChange(value);
              }}
              id="outlined-password-input"
              label="Discount Program"
            >
              <MenuItem value="none">
                <div>None</div>
              </MenuItem>
              {discountList.map((item, index) => (
                <MenuItem key={index} value={item?._id}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    sx={{ width: "100%" }}
                  >
                    <div> {item.programName}</div>
                    <Chip
                      sx={{
                        background: "#EBEBEB",
                        color: "#000",
                      }}
                      size="small"
                      label={`${item.discount}%`}
                    />
                  </Stack>
                </MenuItem>
              ))}
            </MuiSelect>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={7.8} lg={5.6}>
          <Stack direction="row" spacing={4} justifyContent={"flex-end"}>
            <Stack direction="column">
              <TextField
                variant="outlined"
                size="medium"
                label="Discount(%)"
                color={discountError ? "error" : "primary"}
                value={totals?.discountPercentage}
                disabled={
                  discountId && discountId !== "" && discountId !== "none"
                    ? true
                    : totals?.subTotal == 0
                    ? true
                    : false
                }
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const numericValue = parseNumberFromCommas(inputValue);
                  let discountValue = parseFloat(
                    (totals?.subTotal * numericValue) / 100
                  ).toFixed(2);

                  setTotals({
                    discountPercentage: numericValue,
                    discount: discountValue,
                    tax:
                      totals?.taxPercentage > 0
                        ? ((totals?.subTotal - discountValue) *
                            totals?.taxPercentage) /
                          100
                        : 0,

                    estimatedTotal:
                      totals?.subTotal -
                      numericValue +
                      (totals?.taxPercentage > 0
                        ? ((totals?.subTotal - discountValue) *
                            totals?.taxPercentage) /
                          100
                        : 0),
                    taxPercentage:
                      totals?.taxPercentage > 0 ? totals?.taxPercentage : 0,
                    subTotal: totals?.subTotal,
                  });
                  if (numericValue > 100) {
                    setDiscountError(true);
                  } else if (numericValue > totals?.subTotal) {
                    setDiscountError(true);
                  } else {
                    setDiscountError(false);
                  }
                }}
                onKeyDown={handlePreventKeyDownDecimal}
              />
              {discountError && (
                <p
                  style={{
                    color: "#d32f2f",
                    fontSize: "0.75rem",
                    letterSpacing: "0.03333em",
                    lineHeight: "1.66",
                    marginTop: "3px",
                  }}
                >
                  Discount cannot exceed 100% or be less than 0.
                </p>
              )}
            </Stack>
            <Stack direction="column">
              <TextField
                variant="outlined"
                size="medium"
                label="Discount($)"
                color={discountError ? "error" : "primary"}
                value={totals?.discount}
                disabled={
                  discountId && discountId !== "" && discountId !== "none"
                    ? true
                    : totals?.subTotal == 0
                    ? true
                    : false
                }
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const numericValue = parseNumberFromCommas(inputValue);

                  let discountPercentage =
                    (numericValue / totals?.subTotal) * 100;
                  setTotals({
                    discount: numericValue,
                    tax:
                      totals?.taxPercentage > 0
                        ? ((totals?.subTotal - numericValue) *
                            totals?.taxPercentage) /
                          100
                        : 0,

                    estimatedTotal:
                      totals?.subTotal -
                      numericValue +
                      (totals?.taxPercentage > 0
                        ? ((totals?.subTotal - numericValue) *
                            totals?.taxPercentage) /
                          100
                        : 0),
                    taxPercentage:
                      totals?.taxPercentage > 0 ? totals?.taxPercentage : 0,
                    subTotal: totals?.subTotal,
                    discountPercentage: discountPercentage.toFixed(2),
                  });
                  if (numericValue > totals?.subTotal) {
                    setDiscountError(true);
                  } else {
                    setDiscountError(false);
                  }
                }}
                onKeyDown={handlePreventKeyDownDecimal}
              />
              {discountError && (
                <p
                  style={{
                    color: "#d32f2f",
                    fontSize: "0.75rem",
                    letterSpacing: "0.03333em",
                    lineHeight: "1.66",
                    marginTop: "3px",
                  }}
                >
                  Discount can not be more than subtotal amount
                </p>
              )}
            </Stack>
            <TextField
              variant="outlined"
              size="medium"
              value={totals?.taxPercentage}
              label="Taxes (%)"
              onChange={(e) => {
                const taxPercentage = +e.target.value;
                if (!isNaN(taxPercentage)) {
                  const { subTotal, discount, discountPercentage } = totals;
                  const hasDiscount = discountPercentage > 0 || discount > 0;

                  // Calculate discount and tax
                  const discountValue = hasDiscount
                    ? (subTotal * discountPercentage) / 100
                    : 0;

                  const tax =
                    ((subTotal - discountValue) * taxPercentage) / 100;

                  setTotals({
                    ...totals,
                    taxPercentage: taxPercentage,
                    discountPercentage: hasDiscount ? discountPercentage : 0,
                    tax: tax,
                    estimatedTotal: subTotal - discountValue + tax,
                  });
                } else {
                  toast.error("Please enter a valid number");
                }
              }}
            />
          </Stack>
        </Grid>
      </Grid>

      <Grid
        container
        item
        justifyContent={"end"}
        padding={"0px 23px 24px 24px"}
      >
        <Grid item>
          <Stack direction="row" spacing={2}>
            <Box textAlign={"end"}>
              <Typography mb={1}>Sub Total</Typography>

              <Typography mb={1}>Discount</Typography>

              <Typography mb={1}>Tax</Typography>
              <Typography mb={1}> Total</Typography>
            </Box>
            <Box textAlign={"end"}>
              <Typography mb={1}>
                <FormatTotalNumbers inputValue={totals?.subTotal} />
              </Typography>

              <Typography mb={1}>
                <FormatTotalNumbers inputValue={totals?.discount} />
              </Typography>

              <Typography mb={1}>
                <FormatTotalNumbers inputValue={totals?.tax} />
              </Typography>
              <Typography>
                <FormatTotalNumbers inputValue={totals?.estimatedTotal} />
              </Typography>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};

export default DiscountComponent;
