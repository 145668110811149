import React, { useState } from "react";
import Cards from "./Cards";
import { Grid } from "@mui/material";

function Invoices(props) {
  const [invoices, setInvoices] = useState([]);
  return (
    <Grid container spacing={3}>
      {/* {
        invoices?.map(invoice=>{

        })
    } */}
      <Grid item container md={6}>
        <Cards
          activeTab="invoice"
          date="January 23,2023"
          customerName="John Michale"
          vehicle="2019 Volkswagen Jetta 1.4T SEL"
          chipNumber="EST824-01"
          amount="$80.9"
        />
      </Grid>
      <Grid item container md={6}>
        <Cards
          activeTab="invoice"
          date="January 26,2023"
          customerName="John Michale"
          vehicle="2019 Volkswagen Jetta 1.4T SEL"
          chipNumber="EST924-01"
          amount="$81.9"
        />
      </Grid>
      <Grid item container md={6}>
        <Cards
          activeTab="invoice"
          date="January 24,2023"
          customerName="John Michale"
          vehicle="2019 Volkswagen Jetta 1.4T SEL"
          chipNumber="EST824-04"
          amount="$82.9"
        />
      </Grid>{" "}
      <Grid item container md={6}>
        <Cards
          activeTab="invoice"
          date="January 23,2023"
          customerName="John Michale"
          vehicle="2019 Volkswagen Jetta 1.4T SEL"
          chipNumber="EST824-10"
          amount="$40.9"
        />
      </Grid>
    </Grid>
  );
}

export default Invoices;
