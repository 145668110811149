import { API } from "../../config/api/index";

//ALL TYPE WISE DATA RETURN
const initState = {
  loading: false,
  invoiceData: {},
  vehicleList: {},
  invoiceDetails: {},
  newInvoice: {},
  customerList: {},
};

//DEFINE ACCOUNT REDUCER FUNCTION
const InvoiceReducer = (state = initState, action) => {
  switch (action.type) {
    case API.invoice.invoiceList:
      return {
        ...state,
        invoiceData: action.InvoiceData,
        loading: action.loading,
      };
    case API.invoice.vehicleList:
      return {
        ...state,
        vehicleList: action.vehicleList,
        loading: action.loading,
      };
    case API.invoice.invoiceDetails:
      return {
        ...state,
        invoiceDetails: action.invoiceDetails,
        loading: action.loading,
      };
    case API.invoice.create:
      return {
        ...state,
        newInvoice: action.newInvoice,
        loading: action.loading,
      };
    case API.invoice.customerList:
      return {
        ...state,
        customerList: action.customerList,
        loading: action.loading,
      };
    default:
      return state;
  }
};

//EXPORT ALL FUNCTION
export default InvoiceReducer;
