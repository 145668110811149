import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import HomeIcon from "@mui/icons-material/Home";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WorkIcon from "@mui/icons-material/Work";
import {
  Button,
  Chip,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  APEX_AUTO_APP,
  SHOP_CREATED,
  SHOP_IMPORTED,
} from "../../../config/dataService/constants";
import { CONTACT_PREFRENCES } from "../../../utility/Constant";
import { formatPhoneNumber } from "../../Common/FormatPhoneNumber";

function PhoneTypeIcon(type) {
  if (type == "home")
    return <HomeIcon style={{ color: "#006CDD", fontSize: "20px" }} />;
  if (type == "work")
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M17.5 18.75V5H13.75V1.25H2.5V18.75H0V20H8.75V16.25H11.25V20H20V18.75H17.5ZM7.5 13.75H5V11.25H7.5V13.75ZM7.5 10H5V7.5H7.5V10ZM7.5 6.25H5V3.75H7.5V6.25ZM11.25 13.75H8.75V11.25H11.25V13.75ZM11.25 10H8.75V7.5H11.25V10ZM11.25 6.25H8.75V3.75H11.25V6.25ZM16.25 13.75H13.75V11.25H16.25V13.75ZM16.25 10H13.75V7.5H16.25V10Z"
          fill="#006CDD"
        />
      </svg>
    );
  if (type == "landline")
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M17.5 18.75V5H13.75V1.25H2.5V18.75H0V20H8.75V16.25H11.25V20H20V18.75H17.5ZM7.5 13.75H5V11.25H7.5V13.75ZM7.5 10H5V7.5H7.5V10ZM7.5 6.25H5V3.75H7.5V6.25ZM11.25 13.75H8.75V11.25H11.25V13.75ZM11.25 10H8.75V7.5H11.25V10ZM11.25 6.25H8.75V3.75H11.25V6.25ZM16.25 13.75H13.75V11.25H16.25V13.75ZM16.25 10H13.75V7.5H16.25V10Z"
          fill="#006CDD"
        />
      </svg>
    );
  if (type == "mobile")
    return (
      <img src="https://icongr.am/entypo/mobile.svg?size=20&color=006cdd" />
    );
}

function CustomerProfileLeftDataSection({
  data,
  importedCustomerData,
  setQuickEdit = () => {},
  setOpenEditCustomer,
  setOpenDelete,
}) {
  const { id, type } = useParams();

  const navigate = useNavigate();

  const customerAddress = () => {
    let add = " ";
    if (data?.streetAddress) {
      add += data?.streetAddress;
    }
    if (data?.city) {
      if (add.trim() != "") add += ", " + data?.city?.city;
      else add += data?.city?.city;
    }
    if (data?.province) {
      if (add.trim() != "") add += ", " + data?.province?.province;
      else add += data?.province?.province;
    }

    if (data?.postalCode) {
      if (add.trim() != "") add += ", " + data?.postalCode;
      else add += data?.postalCode;
    }
    return add;
  };
  const importCustomerAddress = () => {
    let add = " ";
    if (importedCustomerData?.streetAddress) {
      if (add.trim() != "") add += ", " + importedCustomerData?.streetAddress;
      else {
        add += importedCustomerData?.streetAddress;
      }
    }
    if (importedCustomerData?.city) {
      if (add.trim() != "") add += ", " + importedCustomerData?.city?.city;
      else add += importedCustomerData?.city?.city;
    }
    if (importedCustomerData?.province) {
      if (add.trim() != "")
        add += ", " + importedCustomerData?.province?.province;
      else add += importedCustomerData?.province?.province;
    }

    if (importedCustomerData?.postalCode) {
      if (add.trim() != "") add += ", " + importedCustomerData?.postalCode;
      else add += importedCustomerData?.postalCode;
    }
    return add;
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open1 = Boolean(anchorEl);
  return (
    <>
      <Grid item width={"100%"}>
        <Typography
          variant="h5"
          fontWeight={600}
          justifyContent={"space-between"}
          display={"flex"}
          paddingBottom={"8px"}
        >
          <Chip
            label={
              type
                ? type == SHOP_CREATED
                  ? "Shop Created"
                  : type == SHOP_IMPORTED
                  ? "Shop Imported"
                  : "From Apex Auto App"
                : data.createdFrom == SHOP_CREATED
                ? "Shop Created"
                : "From Apex Auto App"
            }
            style={{
              background: type
                ? type == SHOP_CREATED
                  ? " rgba(117, 219, 255, 0.5)"
                  : type == SHOP_IMPORTED
                  ? "rgba(225, 208, 253, 1)"
                  : "rgba(239, 239, 239, 1)"
                : data.createdFrom == SHOP_CREATED
                ? "rgba(117, 219, 255, 0.5)"
                : "rgba(239, 239, 239, 1)",

              color: type
                ? type == SHOP_CREATED
                  ? "rgba(0, 0, 0, 1)"
                  : type == SHOP_IMPORTED
                  ? "rgba(62, 28, 118, 1)"
                  : "rgba(0, 35, 148, 1)"
                : data.createdFrom == SHOP_CREATED
                ? "rgba(0, 0, 0, 1)"
                : "rgba(0, 35, 148, 1)",

              fontWeight: "500",
            }}
          />

          {type !== SHOP_IMPORTED && (
            <span onClick={() => setQuickEdit(true)}>
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                cursor={"pointer"}
              >
                <path
                  d="M0 18V13.75L13.2 0.575C13.4 0.391667 13.621 0.25 13.863 0.15C14.105 0.0500001 14.359 0 14.625 0C14.891 0 15.1493 0.0500001 15.4 0.15C15.6507 0.25 15.8673 0.4 16.05 0.6L17.425 2C17.625 2.18333 17.771 2.4 17.863 2.65C17.955 2.9 18.0007 3.15 18 3.4C18 3.66667 17.9543 3.921 17.863 4.163C17.7717 4.405 17.6257 4.62567 17.425 4.825L4.25 18H0ZM14.6 4.8L16 3.4L14.6 2L13.2 3.4L14.6 4.8Z"
                  fill="black"
                  fill-opacity="0.6"
                />
              </svg>
            </span>
          )}
        </Typography>

        <Typography variant="body1" fontWeight={400} pt={1} fontSize={"24px"}>
          {type == SHOP_IMPORTED
            ? importedCustomerData?.firstName +
              " " +
              importedCustomerData?.lastName
            : data?.firstName + " " + data?.lastName}
        </Typography>

        <Typography
          variant="body1"
          fontWeight={400}
          paddingTop={"16px"}
          display={"flex"}
          gap={"8px"}
        >
          {PhoneTypeIcon(
            type == SHOP_IMPORTED ? "mobile" : data?.primaryPhoneNumberType
          )}
          <span
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",

              lineHeight: "normal",
            }}
          >
            {type == SHOP_IMPORTED
              ? formatPhoneNumber(importedCustomerData?.phoneNumber)
              : formatPhoneNumber(data?.phoneNumber)}
          </span>
        </Typography>
        {type == SHOP_IMPORTED
          ? formatPhoneNumber(importedCustomerData?.secondaryPhoneNumber) !==
              "" && (
              <Typography
                variant="body1"
                fontWeight={400}
                paddingTop={"16px"}
                display={"flex"}
                gap={"8px"}
              >
                {PhoneTypeIcon(
                  type == SHOP_IMPORTED
                    ? "mobile"
                    : data?.secondaryPhoneNumberType
                )}
                {formatPhoneNumber(importedCustomerData?.secondaryPhoneNumber)}
              </Typography>
            )
          : formatPhoneNumber(data?.secondaryPhoneNumber) !== "" && (
              <Typography
                variant="body1"
                fontWeight={400}
                paddingTop={"16px"}
                display={"flex"}
                gap={"8px"}
              >
                {PhoneTypeIcon(
                  type == SHOP_IMPORTED
                    ? "mobile"
                    : data?.secondaryPhoneNumberType
                )}
                {formatPhoneNumber(data?.secondaryPhoneNumber)}
              </Typography>
            )}

        <Typography
          variant="body1"
          fontWeight={400}
          paddingTop={"16px"}
          display={importedCustomerData?.email || data?.email ? "flex" : "none"}
          gap={"8px"}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M16.667 3.33337H3.33366C2.41699 3.33337 1.67533 4.08337 1.67533 5.00004L1.66699 15C1.66699 15.9167 2.41699 16.6667 3.33366 16.6667H16.667C17.5837 16.6667 18.3337 15.9167 18.3337 15V5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337ZM16.667 6.66671L10.0003 10.8334L3.33366 6.66671V5.00004L10.0003 9.16671L16.667 5.00004V6.66671Z"
              fill="#006CDD"
            />
          </svg>
          <span
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",

              lineHeight: "normal",
            }}
          >
            {type == SHOP_IMPORTED ? importedCustomerData?.email : data?.email}
          </span>
        </Typography>

        <Typography
          variant="body1"
          fontWeight={400}
          paddingTop={"16px"}
          display={
            importedCustomerData?.secondaryEmail || data?.secondaryEmail
              ? "flex "
              : "none"
          }
          gap={"8px"}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M16.667 3.33337H3.33366C2.41699 3.33337 1.67533 4.08337 1.67533 5.00004L1.66699 15C1.66699 15.9167 2.41699 16.6667 3.33366 16.6667H16.667C17.5837 16.6667 18.3337 15.9167 18.3337 15V5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337ZM16.667 6.66671L10.0003 10.8334L3.33366 6.66671V5.00004L10.0003 9.16671L16.667 5.00004V6.66671Z"
              fill="#006CDD"
            />
          </svg>
          <span
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",

              lineHeight: "normal",
            }}
          >
            {type == SHOP_IMPORTED
              ? importedCustomerData?.secondaryEmail
              : data?.secondaryEmail}
          </span>
        </Typography>

        <Typography
          variant="body1"
          fontWeight={400}
          paddingTop={"16px"}
          display={
            importedCustomerData?.company || data?.company ? "flex" : "none"
          }
          gap={"8px"}
        >
          <WorkIcon style={{ color: "#006CDD", fontSize: "20px" }} />

          {type == SHOP_IMPORTED
            ? importedCustomerData?.company
            : data?.company}
        </Typography>
        <Typography
          variant="body1"
          fontWeight={400}
          paddingTop={"16px"}
          display={
            customerAddress()?.trim() || importCustomerAddress()?.trim()
              ? "flex"
              : "none"
          }
          gap={"8px"}
          alignItems={"center"}
        >
          <LocationOnIcon style={{ color: "#006CDD", fontSize: "20px" }} />
          <span
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",

              lineHeight: "normal",
            }}
          >
            {type != SHOP_IMPORTED && customerAddress()}
            {type == SHOP_IMPORTED && importCustomerAddress()}
          </span>
        </Typography>

        <Typography
          variant="body1"
          fontWeight={400}
          paddingTop={"16px"}
          display={data?.contactPrefrence ? "flex" : "none"}
          gap={"8px"}
        >
          <FilterAltIcon style={{ color: "#006CDD", fontSize: "20px" }} />
          <span
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              fontWeight: 500,

              lineHeight: "normal",
            }}
          >
            Contact Preferences:
          </span>
          <span
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              // fontWeight: 500,

              lineHeight: "normal",
            }}
          >
            {
              CONTACT_PREFRENCES?.filter(
                (d) => d.key == data?.contactPrefrence
              )[0]?.value
            }
          </span>
        </Typography>

        {type != SHOP_IMPORTED && (
          <Divider style={{ width: "100%", marginTop: "23px" }} />
        )}

        {data?.membershipProgram?.discount && (
          <>
            <Typography
              variant="body1"
              fontWeight={400}
              paddingTop={"16px"}
              display={"flex"}
              gap={"8px"}
            >
              <Chip
                sx={{
                  background: "#EBEBEB",
                  color: "#000",
                }}
                label={`${data?.membershipProgram.discount}%`}
              />
              <span
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",

                  lineHeight: "normal",
                }}
              >
                {data?.membershipProgram?.programName}
              </span>
            </Typography>
            <Divider style={{ width: "100%", marginTop: "23px" }} />
          </>
        )}
      </Grid>
      <Grid
        item
        container
        justifyContent={"end"}
        paddingRight={"16px"}
        paddingTop={"16px"}
      >
        <Stack spacing={2} direction={"row"}>
          {type != SHOP_IMPORTED && (
            <Button
              variant="contained"
              onClick={() => {
                navigate(`/messenger/${id}`);
              }}
            >
              SEND MESSAGE
            </Button>
          )}
          {(type == SHOP_CREATED || type == APEX_AUTO_APP) && (
            <Button
              variant="contained"
              endIcon={<ExpandMoreIcon />}
              id="demo-positioned-button"
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
              }}
            >
              ACTIONS{" "}
            </Button>
          )}
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open1}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClick={() => setAnchorEl(null)}
          >
            <MenuItem
              onClick={() => {
                setOpenEditCustomer(true);
              }}
            >
              <EditIcon
                style={{
                  marginRight: "10px",
                  color: "rgba(0, 0, 0, 0.56)",
                }}
              />{" "}
              Edit Customer
            </MenuItem>
            {type == SHOP_CREATED && (
              <MenuItem onClick={() => setOpenDelete(true)}>
                <DeleteIcon
                  style={{
                    marginRight: "10px",
                    color: "rgba(0, 0, 0, 0.56)",
                  }}
                />{" "}
                Delete Customer
              </MenuItem>
            )}
          </Menu>
        </Stack>
      </Grid>
    </>
  );
}

export default CustomerProfileLeftDataSection;
