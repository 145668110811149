import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal } from "@mui/material";
import React from "react";

const ImageShowModal = ({
  setImageOpenModalShows,
  imageOpenModalShows,
  setImageView,
  imageView,
}) => {
  return (
    <div>
      {" "}
      <Modal
        open={imageOpenModalShows}
        onClose={() => {
          setImageOpenModalShows(false);
        }}
        className="image-view-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
          className="shop-images-modal"
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton
              onClick={() => {
                setImageOpenModalShows(false);
                setImageView("");
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <img
            src={imageView}
            alt="Full Size Image"
            className="view-shop-image"
          />
          {/* <Button
        onClick={() => {
          setImageOpenModalShows(false);
          setImageView("");
        }}
      >
        Close
      </Button> */}
        </Box>
      </Modal>
    </div>
  );
};

export default ImageShowModal;
