import { Box, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Appointments from "./Appointments";
import Estimates from "./Estimates";
import Invoices from "./Invoices";
import SupplierInvoice from "./SupplierInvoice";
import SupplierNotes from "./SupplierNotes";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function SupplierProfileRightSection(props) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        className="supplierProfileTabs"
        sx={{ borderBottom: 0, borderColor: "divider", overflow: "auto" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{ marginBottom: "24px", overflow: "auto" }}
          style={{ overflow: "auto" }}
        >
          <Tab label="OVERVIEW" {...a11yProps(0)} />

          <Tab label="INVOICES" {...a11yProps(1)} />

          <Tab label="APPOINTMENTS" {...a11yProps(2)} />
          <Tab label="ESTIMATES" {...a11yProps(3)} />
          <Tab label="SUPPLIER INVOICE" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <CustomTabPanel
        value={value}
        index={0}
        // style={{ display: type == SHOP_IMPORTED && "none" }}
      >
        <SupplierNotes />
      </CustomTabPanel>
      <CustomTabPanel
        value={value}
        index={1}
        // style={{ display: type == SHOP_IMPORTED && "none" }}
      >
        <Invoices />
      </CustomTabPanel>
      <CustomTabPanel
        value={value}
        index={2}
        // style={{ display: type == SHOP_IMPORTED && "none" }}
      >
        <Appointments />
      </CustomTabPanel>
      <CustomTabPanel
        value={value}
        index={3}
        // style={{ display: type == SHOP_IMPORTED && "none" }}
      >
        <Estimates />
      </CustomTabPanel>
      <CustomTabPanel
        value={value}
        index={4}
        // style={{ display: type == SHOP_IMPORTED && "none" }}
      >
        <SupplierInvoice />
      </CustomTabPanel>
    </Box>
  );
}

export default SupplierProfileRightSection;
