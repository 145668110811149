import { Add } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import "../../../App.css";

import { useTheme } from "@emotion/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import { REQUIRED_RED } from "../../../App";
import FormatNumber, {
  FormatNumberForKmFloat,
  FormatTotalNumbers,
} from "../../../components/FormatNumber/FormatNumber";
import { PAYMENT_STATUS, PAYMENT_TERMS } from "../../../utility/Constant";
import {
  customStyles,
  errorCustomStyles,
} from "../../../utility/SelectDropdown";
import SuccessMessageModal from "../../Common/SuccessMessage";
import SupplierPartModel from "../SupplierPartModel";
// import "../Invoice.css";

const SupplierNewInvoice = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    trigger,
    setValue,
    reset,
    setFocus,
    register,
  } = useForm();

  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [paymentTermsTypes, setPaymentTermsType] = useState(PAYMENT_TERMS);
  const [paymentStatus, setPaymentStatus] = useState(PAYMENT_STATUS);

  const [messageType, setMessagetype] = useState("");
  const [allService, setAllServices] = useState([]);

  const [showLoader, setShowLoader] = useState(false);

  const [IsSaveDraft, setIsSaveDraft] = useState(false);

  const handleOpenPartModal = () => setOpenpartModel(true);
  const [suppliersList, setSuppliersList] = React.useState([]);

  const [supplierData, setSupplierData] = React.useState("");
  const [supplierListLoader, setSupplierListLoader] = useState(false);
  const [selectDate, setSelectDate] = React.useState(null);
  const [completionDate, setCompletionDate] = React.useState(null);

  const [totals, setTotals] = useState({
    subTotal: 0,
    discount: 0,
    taxPercentage: 13,
    tax: 0,
    estimatedTotal: 0,
    discountPercentage: 0,
  });

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [discountError, setDiscountError] = useState(false);
  const [openPartModel, setOpenpartModel] = useState(false);

  const handleClosePartModel = () => {
    setOpenpartModel(false);
  };
  const onSubmitPartModel = () => {};

  const onSubmitInvoice = async (values) => {
    // await trigger();
  };

  const onInvalid = (errors) => {
    const firstErrorField = Object.keys(errors)[0];
    if (firstErrorField) {
      const errorElement = document.querySelector(`[name=${firstErrorField}]`);
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth" });
        setTimeout(() => {
          const offset = 100; // Adjust the offset value as needed
          const elementPosition = errorElement.getBoundingClientRect().top;
          const offsetPosition = elementPosition - offset;
          window.scrollBy({ top: offsetPosition, behavior: "smooth" });
          setFocus(firstErrorField);
        }, 0);
      }
    }
  };

  const cancelInvoice = () => {};

  return (
    <>
      <Box
        padding={"32px 24px"}
        sx={{
          width: "fit-content",
          minWidth: "100%",
        }}
      >
        {" "}
        <SuccessMessageModal
          message={successMessage}
          open={openSuccessModal}
          setOpenSuccessModal={setOpenSuccessModal}
          alertType={messageType}
        />
        <form onSubmit={handleSubmit(onSubmitInvoice, onInvalid)}>
          <Grid container sx={{ padding: "16px 0px" }} gap={"8px"}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div role="presentation">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    to="/suppliers-invoice"
                    style={{ color: "#00000099", textDecoration: "none" }}
                  >
                    Supplier
                  </Link>
                  {/* <Link
                    // to="/invoice/new"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    {id ? "Drafts " : "New Invoice "}
   
                  </Link> */}
                </Breadcrumbs>
              </div>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="h4" sx={{ fontWeight: "500" }}>
                {id ? "Edit " : "New "}
                Supplier Invoice
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            padding={"24px"}
            margin={" 17px 0px 32px 0"}
            boxShadow={2}
            bgcolor={"white"}
            borderRadius={2}
          >
            <Grid container item justifyItems={"space-between"}>
              <Grid
                container
                item
                justifyItems={"space-between"}
                rowGap={3}
                padding={"10px 0px"}
                columnSpacing={2}
              >
                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                  <Stack spacing={0}>
                    <Typography variant="body1" className="label-500">
                      Supplier <span style={{ color: REQUIRED_RED }}>*</span>
                    </Typography>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={suppliersList ? suppliersList : []}
                      getOptionLabel={(option) =>
                        // option.firstName + " " + option.lastName
                        option.fullName
                      }
                      value={supplierData ? supplierData : null}
                      onChange={(event, newValue) => {}}
                      loading={supplierListLoader}
                      loadingText={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <CircularProgress />
                        </div>
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          // onChange={(e) =>
                          //   handleInputDebounced(e, e.target.value)
                          // }
                          // onBlur={handleBlur}
                          // style={{cursor: "not-allowed"}}
                        />
                      )}
                      // ListboxProps={{
                      //   style: { maxHeight: "300px" },
                      //   onScroll: (event) => {
                      //     handleScroll(event);
                      //   },
                      // }}

                      renderOption={(props, option) => (
                        <>
                          <Box component="li" {...props}>
                            <li
                              {...props}
                              style={{
                                width: "100%",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                {/* {option.firstName + " " + option.lastName} */}
                                {option.fullName}
                              </div>
                            </li>

                            {/* <li>
                                <CircularProgress />
                              </li> */}
                          </Box>
                          <Box component="li" {...props}>
                            {props["data-option-index"] + 1 ==
                              suppliersList?.length &&
                              supplierListLoader && (
                                <li
                                  style={{
                                    width: "100%",
                                    justifyContent: "center",

                                    display: "flex",
                                  }}
                                >
                                  <CircularProgress />
                                </li>
                              )}
                          </Box>
                        </>
                      )}
                    />
                  </Stack>
                </Grid>

                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                  <Typography className="edit-invoice-form-title style-15-500-24-px">
                    Invoice Number{" "}
                    <span style={{ color: REQUIRED_RED }}>*</span>
                  </Typography>

                  {/* <FormatNumberForKm inputValue={"10000"} /> */}
                  <Controller
                    name="invoiceNumber"
                    control={control}
                    rules={{
                      required: "Invoice number is required",
                    }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        fullWidth
                        error={!!errors.invoiceNumber}
                        helperText={
                          errors.invoiceNumber
                            ? errors.invoiceNumber.message
                            : ""
                        }
                        {...field}
                        onChange={(event) => {
                          setValue(
                            "invoiceNumber"
                            // FormatNumberForKm(event.target.value)
                          );
                        }}
                        // onKeyPress={handleKeyPress}
                        InputLabelProps={{ shrink: field.value?.length > 0 }}
                        // required={!IsSaveDraft}
                      />
                    )}
                  />
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                  <Typography className="edit-invoice-form-title style-15-500-24-px">
                    Payment Terms
                  </Typography>
                  <Controller
                    name="paymentTerm"
                    control={control}
                    render={({ field }) => (
                      <Select
                        fullWidth
                        placeholder="" // defaultValue={}
                        isDisabled={false}
                        isLoading={false}
                        isClearable={false}
                        isSearchable={false}
                        options={paymentTermsTypes ? paymentTermsTypes : []}
                        getOptionLabel={(option) => option.value} // Specifies which field to use as the label
                        getOptionValue={(option) => option}
                        styles={
                          errors.paymentTerm
                            ? {
                                ...customStyles,
                                ...errorCustomStyles,
                              }
                            : {
                                ...customStyles,
                              }
                        }
                        error={!!errors.paymentTerm}
                        helperText={
                          errors.paymentTerm ? errors.paymentTerm.message : ""
                        }
                        {...field}
                        // components={{ Input }}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  className="width-100-pers"
                >
                  <Typography className="edit-invoice-form-title style-15-500-24-px">
                    Supplier Invoice Date
                  </Typography>
                  {/* <Controller
                      name="appointmentDroppedOffDate"
                      control={control}
                      rules={{
                        required: "appointmentDroppedOffDate is required",
                      }}
                      render={({ field }) => ( */}

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      // label="Select date"
                      // disabled={appoinmentId && appoinmentData?.dropOffDateTime}
                      value={selectDate || null}
                      // disablePast={true}
                      onChange={(date) => {
                        setSelectDate(dayjs(dayjs(date).format("YYYY-MM-DD")));
                      }}
                      style={{ width: "100%" }}
                      //   components={{
                      //     OpenPickerIcon: KeyboardArrowDown,
                      //   }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!errors.supplierInvoiceDate}
                          helperText={
                            errors.supplierInvoiceDate
                              ? errors.supplierInvoiceDate.message
                              : ""
                          }
                          required={true}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  className="width-100-pers"
                >
                  <Typography className="edit-invoice-form-title style-15-500-24-px">
                    Past Ordered Date
                  </Typography>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      // label="Select date"
                      value={completionDate || null}
                      // disabled={appoinmentId && appoinmentData?.shopClosingTime}
                      // disablePast={true}
                      onChange={(date) => {
                        setCompletionDate(
                          dayjs(dayjs(date).format("YYYY-MM-DD"))
                        );

                        // field.onChange(date);
                        // const formattedDate = new Date(date);
                        // setCompletionDate(formattedDate);
                      }}
                      style={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!errors.pastOrderedDate}
                          helperText={
                            errors.pastOrderedDate
                              ? errors.pastOrderedDate.message
                              : ""
                          }
                          required={false}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  className="width-100-pers"
                >
                  <Typography className="edit-invoice-form-title style-15-500-24-px">
                    Past Received Date
                  </Typography>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      // label="Select date"
                      value={completionDate || null}
                      // disabled={appoinmentId && appoinmentData?.shopClosingTime}
                      // disablePast={true}
                      onChange={(date) => {
                        setCompletionDate(
                          dayjs(dayjs(date).format("YYYY-MM-DD"))
                        );

                        // field.onChange(date);
                        // const formattedDate = new Date(date);
                        // setCompletionDate(formattedDate);
                      }}
                      style={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!errors.pastReceivedDate}
                          helperText={
                            errors.pastReceivedDate
                              ? errors.pastReceivedDate.message
                              : ""
                          }
                          required={false}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Divider
                style={{
                  width: "100%",
                  borderBottomStyle: "dotted",
                  borderBottomWidth: "4px",
                  marginTop: "24px",
                }}
              />

              {allService?.map((data, index) => {
                return (
                  <>
                    {(data?.pricingType == "fixed" ||
                      data?.pricingType == "no_price") && (
                      <>
                        <Grid
                          container
                          item
                          style={{
                            backgroundColor: "#002394",
                            color: "white",
                            marginTop: "20px",
                          }}
                        >
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography
                              variant="h6"
                              fontWeight={"500"}
                              padding={"16px"}
                            >
                              {data?.service?.name}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container item>
                          <Grid
                            item
                            xl={12}
                            md={12}
                            sm={12}
                            xs={12}
                            boxShadow={0}
                          >
                            <Table
                              aria-label="caption table"
                              padding="16px"
                              style={{ boxShadow: 0 }}
                            >
                              <TableHead
                                style={{
                                  backgroundColor: "#f4f6f8",
                                  fontWeight: 500,
                                }}
                              >
                                <TableRow>
                                  <TableCell width={"9.6%"}>
                                    <Typography
                                      variant="table/header"
                                      fontWeight={500}
                                      width={"120px"}
                                    >
                                      {" "}
                                      No.
                                    </Typography>
                                  </TableCell>
                                  <TableCell> Description</TableCell>
                                  <TableCell width={"9.6%"}>
                                    {" "}
                                    Quantity
                                  </TableCell>
                                  <TableCell width={"9.6%"}> Cost</TableCell>
                                  <TableCell width={"9.6%"}> Amount</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow key={1}>
                                  <TableCell component="th" scope="row">
                                    1
                                  </TableCell>
                                  <TableCell align="left">
                                    {data?.service?.name}
                                  </TableCell>
                                  <TableCell align="left">1</TableCell>{" "}
                                  <TableCell align="left">
                                    {data?.fixedPrice ? (
                                      <FormatNumber
                                        inputValue={data?.fixedPrice}
                                      />
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                  <TableCell align="left">
                                    {data?.fixedPrice ? (
                                      <FormatNumber
                                        inputValue={data?.fixedPrice}
                                      />
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </>
                );
              })}
              <Grid item container padding={"12px 0px"}>
                <Grid item>
                  <Button
                    color="primary"
                    variant="outlined"
                    startIcon={<Add />}
                    onClick={handleOpenPartModal}
                    className="btn-text-size-manage"
                    sx={{ mb: 2 }}
                  >
                    ADD Part
                  </Button>
                </Grid>
              </Grid>
              <Divider
                style={{
                  width: "100%",
                  borderBottomStyle: "dotted",
                  borderBottomWidth: "4px",
                }}
              />

              <Grid container item justifyContent={"end"} padding={"24px"}>
                <Grid item>
                  <Stack direction="row" spacing={4}>
                    <Stack direction="column">
                      <TextField
                        variant="outlined"
                        size="medium"
                        label="Discount(%)"
                        color={discountError ? "error" : "primary"}
                        value={totals?.discountPercentage}
                        disabled={totals?.subTotal == 0 ? true : false}
                        onChange={(e) => {
                          if (!isNaN(e.target.value)) {
                            let discountValue =
                              (totals?.subTotal * +e.target.value) / 100;
                            setTotals({
                              discountPercentage: +e.target.value,
                              discount: discountValue,
                              tax:
                                totals?.taxPercentage > 0
                                  ? ((totals?.subTotal - discountValue) *
                                      totals?.taxPercentage) /
                                    100
                                  : 0,

                              estimatedTotal:
                                totals?.subTotal -
                                e.target.value +
                                (totals?.taxPercentage > 0
                                  ? ((totals?.subTotal - discountValue) *
                                      totals?.taxPercentage) /
                                    100
                                  : 0),
                              taxPercentage:
                                totals?.taxPercentage > 0
                                  ? totals?.taxPercentage
                                  : 0,
                              subTotal: totals?.subTotal,
                            });
                            if (e.target.value > totals?.subTotal) {
                              setDiscountError(true);
                            } else {
                              setDiscountError(false);
                            }
                          } else {
                            toast.error("Please Enter Number value");
                          }
                        }}
                      />
                      {discountError && (
                        <p
                          style={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                            letterSpacing: "0.03333em",
                            lineHeight: "1.66",
                            marginTop: "3px",
                          }}
                        >
                          Discount can not be more than subtotal amount
                        </p>
                      )}
                    </Stack>
                    <Stack direction="column">
                      <TextField
                        variant="outlined"
                        size="medium"
                        label="Discount($)"
                        color={discountError ? "error" : "primary"}
                        value={totals?.discount}
                        disabled={totals?.subTotal == 0 ? true : false}
                        onChange={(e) => {
                          if (!isNaN(e.target.value)) {
                            let discountPercentage =
                              (+e.target.value / totals?.subTotal) * 100;
                            setTotals({
                              discount: +e.target.value,
                              tax:
                                totals?.taxPercentage > 0
                                  ? ((totals?.subTotal - +e.target.value) *
                                      totals?.taxPercentage) /
                                    100
                                  : 0,

                              estimatedTotal:
                                totals?.subTotal -
                                e.target.value +
                                (totals?.taxPercentage > 0
                                  ? ((totals?.subTotal - +e.target.value) *
                                      totals?.taxPercentage) /
                                    100
                                  : 0),
                              taxPercentage:
                                totals?.taxPercentage > 0
                                  ? totals?.taxPercentage
                                  : 0,
                              subTotal: totals?.subTotal,
                              discountPercentage: discountPercentage.toFixed(2),
                            });
                            if (e.target.value > totals?.subTotal) {
                              setDiscountError(true);
                            } else {
                              setDiscountError(false);
                            }
                          } else {
                            toast.error("Please Enter Number value");
                          }
                        }}
                      />
                      {discountError && (
                        <p
                          style={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                            letterSpacing: "0.03333em",
                            lineHeight: "1.66",
                            marginTop: "3px",
                          }}
                        >
                          Discount can not be more than subtotal amount
                        </p>
                      )}
                    </Stack>
                    <TextField
                      variant="outlined"
                      size="medium"
                      value={totals?.taxPercentage}
                      label="Taxes (%)"
                      onChange={(e) => {
                        if (!isNaN(e.target.value)) {
                          setTotals({
                            taxPercentage: +e.target.value,
                            tax:
                              ((totals?.subTotal - totals?.discount) *
                                e.target.value) /
                              100,
                            estimatedTotal:
                              totals?.subTotal -
                              totals?.discount +
                              ((totals?.subTotal - totals?.discount) *
                                +e.target.value) /
                                100,
                            discount: totals?.discount,
                            subTotal: totals?.subTotal,
                          });
                        } else {
                          toast.error("Please Enter Number value");
                        }
                      }}
                    />
                  </Stack>
                </Grid>
              </Grid>

              <Grid
                container
                item
                justifyContent={"end"}
                padding={"0px 23px 24px 24px"}
              >
                <Grid item>
                  <Stack direction="row" spacing={2}>
                    <Box textAlign={"end"}>
                      <Typography mb={1}>Sub Total</Typography>

                      <Typography mb={1}>Discount</Typography>

                      <Typography mb={1}>Tax</Typography>
                      <Typography mb={1}> Total</Typography>
                    </Box>
                    <Box textAlign={"end"}>
                      <Typography mb={1}>
                        <FormatTotalNumbers inputValue={totals?.subTotal} />
                      </Typography>

                      <Typography mb={1}>
                        <FormatTotalNumbers inputValue={totals?.discount} />
                      </Typography>

                      <Typography mb={1}>
                        <FormatTotalNumbers inputValue={totals?.tax} />
                      </Typography>
                      <Typography>
                        <FormatTotalNumbers
                          inputValue={totals?.estimatedTotal}
                        />
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            justifyContent={{ md: "space-between", xs: "" }}
            bgcolor={"white"}
            container
            padding={"24px"}
            margin={" 17px 0px 32px 0"}
            boxShadow={2}
            borderRadius={2}
            alignItems={"center"}
            rowGap={2}
          >
            <Grid
              item
              container
              xs={12}
              md={6}
              columnGap={2}
              alignItems={"end"}
              rowGap={2}
            >
              <Grid xs={12} sm={6} md={6}>
                <Typography fontWeight={500} mb={0.5}>
                  {" "}
                  Payment Status
                </Typography>
                <Select
                  fullWidth
                  placeholder="" // defaultValue={}
                  isDisabled={false}
                  isLoading={false}
                  isClearable={false}
                  isSearchable={false}
                  options={paymentStatus ? paymentStatus : []}
                  getOptionLabel={(option) => option.value} // Specifies which field to use as the label
                  getOptionValue={(option) => option}
                  styles={customStyles}
                  // components={{ Input }}
                />
              </Grid>
              <Grid xs={12} sm={4} md={4}>
                <TextField
                  {...register("amountPaid", {})}
                  // value={value}
                  // onChange={(e) => setValue(e.target.value)}
                  variant="outlined"
                  label="Amount Paid($)"
                  InputProps={{
                    startAdornment: "$",
                  }}
                  type="text" // Set type to text to remove up/down arrows
                  inputProps={{
                    inputMode: "decimal", // Ensures numeric keypad on mobile devices
                    maxLength: 10, // Adjust this as needed
                  }}
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    const numericValue = inputValue.replace(/[^\d.]/g, "");
                    setValue(
                      "amountPaid",
                      FormatNumberForKmFloat(numericValue)
                    );
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              display="flex"
              justifyContent={{ lg: "end" }}
              flexWrap={{ xs: "wrap", sm: "nowrap" }}
              gap={2}
            >
              {/* <Stack
                spacing={2}
                direction={{ md: "row", xs: "column" }}
                sx={{ display: "flex" }}
             display={"flex"}
              > */}
              <Button
                color="error"
                variant="contained"
                className="btn-text-size-manage"
                onClick={cancelInvoice}
                sx={{ whiteSpace: "nowrap", height: "42px" }} // Prevent label from wrapping
              >
                CANCEL
              </Button>
              <Button
                variant="contained"
                type="submit"
                className="btn-text-size-manage"
                onClick={() => setIsSaveDraft(true)}
                sx={{ whiteSpace: "nowrap", height: "42px" }} // Prevent label from wrapping
              >
                SAVE AS DRAFT
              </Button>
              <Button
                variant="contained"
                className="btn-text-size-manage"
                type="submit"
                onClick={() => setIsSaveDraft(false)}
                sx={{ whiteSpace: "nowrap", height: "42px" }} // Prevent label from wrapping
              >
                SAVE
              </Button>
              {/* </Stack> */}
            </Grid>
          </Grid>
        </form>
      </Box>

      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <SupplierPartModel
        openPartModel={openPartModel}
        handleClosePartModel={handleClosePartModel}
        onSubmitPartModel={onSubmitPartModel}
      />
    </>
  );
};

export default SupplierNewInvoice;
