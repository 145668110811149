import { combineReducers } from "redux";
import AccountReducer from "./Account/reducer";
import ApprovalsReducers from "./Approvals/reducer";
import AuthReducer from "./Auth/reducer";
import CalendarReducer from "./Calendar/reducer";
import CustomerReducer from "./Customer/reducer";
import DasboardReducer from "./Dashboard/reducer";
import EstimateReducer from "./Estimate/reducer";
import InvoiceReducer from "./Invoice/reducer";
import NotificationReducers from "./Notification/reducer";
import PauseAppointmentReducer from "./PauseAppointment/reducer";
import VehicleReducer from "./Vehicles/reducer";
import AppointmentReducer from "./appointment/reducer";

const rootReducers = combineReducers({
  auth: AuthReducer,
  account: AccountReducer,
  Notification: NotificationReducers,
  dashboard: DasboardReducer,
  estimate: EstimateReducer,
  customer: CustomerReducer,
  vehicle: VehicleReducer,
  customer: CustomerReducer,
  invoice: InvoiceReducer,
  approvals: ApprovalsReducers,
  appointment: AppointmentReducer,
  calendar: CalendarReducer,
  pauseAppointment: PauseAppointmentReducer,
});
export default rootReducers;
