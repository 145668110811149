import { Add, ExpandMore } from "@mui/icons-material";
import PropTypes from "prop-types";

import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  ClearIcon,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  APEX_AUTO_APP,
  SHOP_CREATED,
  SHOP_IMPORTED,
} from "../../config/dataService/constants";
import { customerList } from "../../redux/Customer/action";
import { formatPhoneNumber } from "../Common/FormatPhoneNumber";
import "./Customers.css";
import ShopImportedCustomers from "./ShopImportedList";
// import FilterModal from "./FilterModal";
import TableContainer from "@mui/material/TableContainer";
import axios from "axios";
import { setItem } from "../../utility/localStorage";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Customers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [searchValue, setSearchValue] = useState(null);
  const [orderBy, setOrderBy] = useState("fullName");
  const [order, setOrder] = useState("asc");
  const [filterDate, setFilterDate] = useState("");
  const [calender, setCalender] = React.useState(false);
  const [tabvalue, setTabValue] = React.useState(0);
  const [showFilterModal, setShowFilterModal] = React.useState(true);
  const [selectedSearchType, setSelectedSearchType] = useState("Full Name");
  const [searchBy, setSearchBy] = useState("fullName");
  const [customers, setCustomers] = useState([]);
  const [fullPageLoader, setFullPageLoader] = useState(false);
  const [types, setTypes] = useState([
    "Shop Created",
    "Shop Imported",
    "Apex Auto App",
  ]);
  const [typeValues, setTypeValues] = useState([
    "shop_created",
    "shop_imported",
    "apex_auto_app",
  ]);

  const [calenderDate, setCalenderDate] = useState("");

  const handleChangeTabValue = (event, newValue) => {
    setTabValue(newValue);
  };
  let params = {
    page: page + 1,
    limit: rowsPerPage,
    search: searchValue,
    searchBy: searchBy,
    sortBy: orderBy,
    sortType: order == "asc" ? "ASC" : "DESC",
    date: filterDate,
    createdFrom: typeValues,
  };
  const [showLoader, setShowLoader] = useState(true);
  const cancelTokenSourceRef = useRef(null);

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const fetchCustomerList = async (params) => {
    setShowLoader(true);
    // Cancel API call on recall
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        "Operation canceled due to new request."
      );
    }

    cancelTokenSourceRef.current = axios.CancelToken.source();
    const resp = await dispatch(
      customerList(params, cancelTokenSourceRef.current.token)
    );
    if (Array.isArray(resp)) {
      setCustomers(resp);
      setShowLoader(false);
      setFullPageLoader(false);
    }
    // console.log("resp", resp);
    if (resp.code == "ERR_CANCELED") {
      setCustomers([]);
    }
  };

  const customers1 = useSelector((state) => state.customer.customerList);
  console.log("🚀 ~ Customers ~ customers:", customers);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    params.page = newPage + 1;
    fetchCustomerList(params);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    params.limit = parseInt(event.target.value, 10);
    params.page = 1;
    fetchCustomerList(params);
  };
  useEffect(() => {
    localStorage.getItem("searchValue") &&
      setSearchValue(localStorage.getItem("searchValue"));

    localStorage.getItem("searchBy") &&
      setSearchBy(localStorage.getItem("searchBy"));

    const delayDebounceFn = setTimeout(() => {
      params.page = 1;
      fetchCustomerList(params);
    }, 800); // Set the debounce time (milliseconds)

    // Cleanup function to cancel the previous setTimeout
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);
  useEffect(() => {
    handleSelectSearchType(localStorage.getItem("searchBy") ?? "fullName");
  }, []);
  const handleSearchChange = (event) => {
    console.log("event.target.value", event.target.value);

    localStorage.setItem("searchValue", event.target.value);

    setSearchValue(event.target.value);
  };

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    params.sortBy = property;
    params.sortType = isAsc ? "DSC" : "ASC";
    fetchCustomerList(params);
  };

  const TableRowsLoader = ({ rowsNum }) => {
    return [...Array(rowsNum)].map((row, index) => (
      <TableRow key={index}>
        <TableCell component="th" scope="row">
          <Skeleton animation="wave" variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" />
        </TableCell>
      </TableRow>
    ));
  };
  const handleSelectSearchType = (type) => {
    setSearchBy(type);
    localStorage.setItem("searchBy", type);
    if (type == "fullName") {
      setSelectedSearchType("Full Name");
    } else if (type == "phoneNumber") {
      setSelectedSearchType("Phone Number");
    } else if (type == "email") {
      setSelectedSearchType("Email");
    } else if (type == "company") {
      setSelectedSearchType("Company");
    } else if (type == "vehicle") {
      setSelectedSearchType("Vehicle");
    } else {
      localStorage.setItem("searchBy", "");
      setSearchBy("");

      setSelectedSearchType("");
    }
    params.page = 1;
    params.searchBy = type;
    if (searchValue != "") {
      fetchCustomerList(params);
    }
    // setSearchValue("");
    // localStorage.setItem("searchValue", "");
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log("value", value);
    setFullPageLoader(true);

    let val = [...value];
    if (val.includes("Shop Created")) {
      let index = val.findIndex((va) => va == "Shop Created");
      if (index > -1) {
        val[index] = SHOP_CREATED;
      }
    }
    if (val.includes("Shop Imported")) {
      let index = val.findIndex((va) => va == "Shop Imported");
      if (index > -1) {
        val[index] = SHOP_IMPORTED;
      }
    }
    if (val.includes("Apex Auto App")) {
      let index = val.findIndex((va) => va == "Apex Auto App");
      if (index > -1) {
        val[index] = APEX_AUTO_APP;
      }
    }
    setTypeValues(val);

    setTypes(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );

    fetchCustomerList({
      page: 1,
      limit: rowsPerPage,
      sortBy: orderBy,
      sortType: order == "asc" ? "ASC" : "DESC",
      date: filterDate,
      createdFrom: val,
      searchBy: searchBy,
      search: searchValue,
    });
  };
  return (
    <>
      <Box padding={"32px 24px"} sx={{ bgcolor: "background.main" }}>
        <Grid container rowGap={2}>
          <Grid container item justifyContent={"space-between"}>
            <Grid item lg={9} padding={"16px 0px"}>
              <Typography variant="h4" sx={{ fontWeight: 400 }}>
                Customers{" "}
              </Typography>
            </Grid>
            <Grid item padding={"16px 0px"}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<Add />}
                onClick={() => {
                  navigate("/customers/new-customer");
                }}
                size="large"
              >
                NEW CUSTOMER
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Box overflow={"auto"}>
          <Tabs
            value={tabvalue}
            onChange={handleChangeTabValue}
            aria-label="basic tabs example"
            textColor="primary"
            variant="standard"
            sx={{ mb: "32px" }}
            className="overflow-tabs"
          >
            <Tab label="ALL" {...a11yProps(0)} />
            <Tab label="SHOP IMPORTED" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={tabvalue} index={0}>
          <Box>
            <Grid
              container
              columns={13}
              item
              // gap={1}
              alignItems={"center"}
              // justifyContent={"space-between"}
              marginBottom={"32px"}
              spacing={2}
            >
              <Grid
                item
                xl={2.5}
                lg={3.6}
                // sm={2.5}
                // xs={2.5}
                md={6.5}
                xs={13}
                alignItems={"center"}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  {/* <DatePicker
                  label="Last Appointment Date"
                  components={{
                    OpenPickerIcon: KeyboardArrowDownIcon,
                  }}
                  onChange={(value) => {
                    setFilterDate( value? dayjs(value).format("YYYY-MM-DD"):null);
                    params.date = value?dayjs(value).format("YYYY-MM-DD"):null;
                    fetchCustomerList(params);
                  }}
                  slotProps={{ field: { clearable: true } }}
                
                /> */}

                  <DatePicker
                    className="DropDatePicker"
                    label="Last Appointment Date"
                    // name="proposeDate"
                    value={calenderDate ? calenderDate : null}
                    onChange={(data) => {
                      const formattedDate = new Date(data);
                      setCalenderDate(data);
                      setFilterDate(
                        data
                          ? formattedDate
                            ? dayjs(formattedDate).format("YYYY-MM-DD")
                            : null
                          : null
                      );
                      params.date = data
                        ? formattedDate
                          ? dayjs(formattedDate).format("YYYY-MM-DD")
                          : null
                        : null;

                      fetchCustomerList(params);
                      setCalender(false);
                    }}
                    style={{ width: "100%" }}
                    open={calender}
                    slotProps={{
                      textField: {
                        InputProps: {
                          endAdornment: (
                            <InputAdornment position="end">
                              <ExpandMore
                                onClick={() => {
                                  setCalender(!calender);
                                }}
                                style={{ cursor: "pointer" }}
                              />
                            </InputAdornment>
                          ),
                        },
                      },

                      field: { clearable: true },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={13} sm={7} md={6.5} lg={3} xl={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Type
                  </InputLabel>
                  <Select
                    fullWidth
                    label="Select Type"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    multiple
                    value={types}
                    onChange={handleChange}
                    input={<OutlinedInput label="Select Type" />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value={"Shop Created"} key={"Shop Created"}>
                      <Checkbox checked={types.indexOf("Shop Created") > -1} />
                      <ListItemText primary={"Shop Created"} />
                    </MenuItem>
                    <MenuItem value={"Shop Imported"} key={"Shop Imported"}>
                      <Checkbox checked={types.indexOf("Shop Imported") > -1} />
                      <ListItemText primary={"Shop Imported"} />
                    </MenuItem>
                    <MenuItem value={"Apex Auto App"} key={"Apex Auto App"}>
                      <Checkbox checked={types.indexOf("Apex Auto App") > -1} />
                      <ListItemText primary={"Apex Auto App"} />
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={13} sm={6} md={6.5} lg={3} xl={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Search by
                  </InputLabel>
                  <Select
                    fullWidth
                    label="Search by"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={searchBy}
                    endAdornment={
                      searchBy && (
                        <InputAdornment
                          position="end"
                          sx={{ paddingRight: "20px" }}
                        >
                          <IconButton
                            edge="end"
                            onClick={() => {
                              setSelectedSearchType("");
                              setSearchValue("");
                              localStorage.setItem("searchValue", "");
                              setSearchBy(null);
                              localStorage.setItem("searchBy", "");
                              params.page = 1;
                              // params.searchBy = "";
                            }}
                          >
                            <ClearIcon sx={{ height: "18px", width: "18px" }} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                    onChange={(e) => {
                      handleSelectSearchType(e.target.value);
                      setItem("searchValue", "");
                    }}
                  >
                    <MenuItem value="fullName" key="fullName">
                      Name
                    </MenuItem>

                    <MenuItem value="phoneNumber" key="phoneNumber">
                      Phone{" "}
                    </MenuItem>
                    <MenuItem value="email" key="email">
                      Email
                    </MenuItem>
                    <MenuItem value="vehicle" key="vehicle">
                      Vehicle
                    </MenuItem>
                    <MenuItem value="company" key="company">
                      Company
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={13} sm={7} md={6.5} lg={3.4} xl={4.5}>
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor="outlined-adornment-search"
                    style={{ backgroundColor: "transparent", color: "black" }}
                  >
                    Search
                  </InputLabel>
                  <OutlinedInput
                    fullWidth
                    id="outlined-adornment-search"
                    sx={{ backgroundColor: "transparent", color: "black" }}
                    placeholder={
                      searchBy
                        ? selectedSearchType
                        : " Customer name, phone number, email, etc..."
                    }
                    onChange={handleSearchChange}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    endAdornment={
                      searchValue && (
                        <InputAdornment
                          position="end"
                          className="search-close-icon"
                          onClick={() => {
                            localStorage.setItem("searchValue", "");
                            setSearchValue("");
                          }}
                        >
                          <ClearIcon />
                        </InputAdornment>
                      )
                    }
                    label="Search"
                    value={searchValue}
                    disabled={searchBy ? false : true}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              item
              padding={"24px"}
              boxShadow={2}
              borderRadius={"10px"}
              sx={{ bgcolor: "white" }}
            >
              <Grid item xl={12} md={12} sm={12} xs={12}>
                <TableContainer sx={{ maxHeight: 600 }}>
                  <Table stickyHeader aria-label="caption table" padding="16px">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <TableSortLabel
                            active={orderBy === "fullName"}
                            direction={orderBy === "fullName" ? order : "asc"}
                            onClick={() => handleSortRequest("fullName")}
                          >
                            Customer Name
                          </TableSortLabel>{" "}
                        </TableCell>
                        <TableCell>
                          {" "}
                          {/* <TableSortLabel
                          active={orderBy === "phoneNumber"}
                          direction={orderBy === "phoneNumber" ? order : "asc"}
                          onClick={() => handleSortRequest("phoneNumber")}
                        >
                          {" "}
                          Phone Number
                        </TableSortLabel> */}
                          Phone Number
                        </TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Company</TableCell>
                        <TableCell>Type</TableCell>

                        <TableCell>Last Appointment Date</TableCell>
                        <TableCell>Last Vehicle Serviced</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {showLoader ? (
                        <TableRowsLoader rowsNum={rowsPerPage} />
                      ) : (
                        Array.isArray(customers) &&
                        customers.map((data) => (
                          <TableRow
                            key={data._id}
                            onClick={() => {
                              // navigate(`${data?._id}/${data?.createdFrom}`);
                              if (Boolean(data?.isImportData) == false) {
                                navigate(
                                  `${data?.refIdUsers}/${data?.createdFrom}`
                                );
                              }
                              if (Boolean(data?.isImportData) == true) {
                                navigate(
                                  `${data?.refIdImport}/${data?.createdFrom}`
                                );
                              }
                            }}
                            hover={true}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell component="th" scope="row">
                              {data?.fullName}
                            </TableCell>
                            <TableCell align="left">
                              {" "}
                              {formatPhoneNumber(data.phoneNumber)}{" "}
                            </TableCell>
                            <TableCell align="left">{data?.email}</TableCell>
                            <TableCell align="left">{data?.company}</TableCell>
                            <TableCell align="left">
                              {" "}
                              {data?.createdFrom && (
                                <Chip
                                  label={
                                    data?.createdFrom == SHOP_CREATED
                                      ? "Shop Created"
                                      : data?.createdFrom == SHOP_IMPORTED
                                      ? "Shop Imported"
                                      : data?.createdFrom == APEX_AUTO_APP
                                      ? "Apex Auto"
                                      : ""
                                  }
                                  style={{
                                    background:
                                      data?.createdFrom == SHOP_CREATED
                                        ? theme.components.MuiChip
                                            .styleOverrides.shopCreatedChip
                                            .backgroundColor
                                        : data?.createdFrom == SHOP_IMPORTED
                                        ? theme.components.MuiChip
                                            .styleOverrides.shopImportedChip
                                            .backgroundColor
                                        : data?.createdFrom == APEX_AUTO_APP
                                        ? theme.components.MuiChip
                                            .styleOverrides.apexAutoChip
                                            .backgroundColor
                                        : "",

                                    color:
                                      data?.createdFrom == SHOP_CREATED
                                        ? theme.components.MuiChip
                                            .styleOverrides.shopCreatedChip
                                            .color
                                        : data?.createdFrom == SHOP_IMPORTED
                                        ? theme.components.MuiChip
                                            .styleOverrides.shopImportedChip
                                            .color
                                        : data?.createdFrom == APEX_AUTO_APP
                                        ? theme.components.MuiChip
                                            .styleOverrides.apexAutoChip.color
                                        : "",
                                    fontWeight: "500",
                                  }}
                                />
                              )}
                            </TableCell>

                            <TableCell align="left">
                              {" "}
                              {data?.appointment?.dropOffDateTime
                                ? dayjs(
                                    data.appointment?.dropOffDateTime
                                  ).format("MMMM DD, YYYY")
                                : ""}
                            </TableCell>
                            <TableCell align="left">
                              {data?.appointment?.vehicle?.year &&
                                data?.appointment?.vehicle?.year}{" "}
                              {data?.appointment?.vehicle?.vehicleMake?.name &&
                                data?.appointment?.vehicle?.vehicleMake
                                  ?.name}{" "}
                              {data?.appointment?.vehicle?.vehicleModel?.name &&
                                data?.appointment?.vehicle?.vehicleModel
                                  ?.name}{" "}
                              {data?.appointment?.vehicle?.vehicleModelTrim
                                ?.name &&
                                data?.appointment?.vehicle?.vehicleModelTrim
                                  ?.name}
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Grid container item alignItems={"center"} justifyContent="end">
                  <Grid item>
                    {customers1?.pagination?.totalCount > 0 && (
                      <TablePagination
                        component="div"
                        count={customers1?.pagination?.totalCount}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={tabvalue} index={1}>
          <ShopImportedCustomers />
        </CustomTabPanel>
      </Box>

      <Dialog fullScreen={fullScreen} open={fullPageLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      {/* <FilterModal open={showFilterModal} setOpen={setShowFilterModal} /> */}
    </>
  );
};

export default Customers;
