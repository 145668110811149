import Delete from "@mui/icons-material/Delete";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Button, Grid, Link, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ImageShowModal from "../../container/modal/ImageShowModal";
import PDFViewModel from "../../container/modal/PDFViewModal";
import { toast } from "sonner";
import { getFileType } from "../../utility/getFileType";

const dragStyle = { backgroundColor: "#F5F5F7", height: "184px" };

function FileUpload({ sendBackUploadedFile }) {
  const fileInputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState([]);
  const [imageOpenModalShows, setImageOpenModalShows] = useState(false);
  const [imageView, setImageView] = useState("");
  const [pdfOpenModalShows, setPDFOpenModalShows] = useState(false);
  const [pdfView, setPDFView] = useState("");

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) return;

    const fileType = selectedFile.type;
    if (fileType.startsWith("image/") || fileType === "application/pdf") {
      if (fileType.startsWith("image/svg")) {
        toast.error("Please upload an image or PDF file only.");

        event.target.value = null; // Clear the input
        return;
      }
    } else {
      toast.error("Please upload an image or PDF file only.");

      event.target.value = null; // Clear the input
      return;
    }
    if (selectedFile && files.length < 4) {
      setFiles([...files, selectedFile]);
      setUploadProgress([...uploadProgress, 0]);

      // You can start the upload process here
      uploadFile(selectedFile, files.length);
    }
  };
  const uploadFile = (file, index) => {
    const formData = new FormData();

    const xhr = new XMLHttpRequest();
    xhr.open("POST", "/your-upload-endpoint", true);

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const progress = (event.loaded / event.total) * 100;
        const updatedProgress = [...uploadProgress];
        updatedProgress[index] = progress;
        setUploadProgress(updatedProgress);
      }
    };

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        // File upload is complete
        console.log("File upload complete");
      }
    };

    xhr.send(formData);
  };
  const fileSize = (bytes) => {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) {
      return "n/a";
    }
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  };

  const handleDelete = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
    sendBackUploadedFile(updatedFiles);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    // Add some styling to indicate that the area is droppable
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = e.dataTransfer.files;
    console.log("droppedFiles", droppedFiles);
    if (!droppedFiles[0]) return;

    const fileType = droppedFiles[0].type;
    console.log("fileType", fileType);
    if (fileType.startsWith("image/") || fileType === "application/pdf") {
      if (fileType.startsWith("image/svg")) {
        toast.error("Please upload an image or PDF file only.");

        e.target.value = null; // Clear the input
        return;
      }
    } else {
      toast.error("Please upload an image or PDF file only.");

      e.target.value = null; // Clear the input
      return;
    }
    if (droppedFiles.length > 0) {
      const selectedFile = droppedFiles[0];
      if (selectedFile && files.length < 4) {
        setFiles([...files, selectedFile]);
        setUploadProgress([...uploadProgress, 0]);

        // You can start the upload process here
        uploadFile(selectedFile, files.length);
      }
    }
  };

  useEffect(() => {
    sendBackUploadedFile(files);
  }, [files]);

  return (
    <div>
      <ImageShowModal
        setImageOpenModalShows={setImageOpenModalShows}
        imageOpenModalShows={imageOpenModalShows}
        setImageView={setImageView}
        imageView={imageView}
      />
      <PDFViewModel
        setPDFOpenModalShows={setPDFOpenModalShows}
        pdfOpenModalShows={pdfOpenModalShows}
        setPDFView={setPDFView}
        pdfView={pdfView}
      />
      <input
        type="file"
        accept="image/*, .pdf"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />

      <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        marginBottom={"16px"}
      >
        <Typography variant="h5" fontWeight={400}>
          Upload Photos
        </Typography>
      </Grid>
      <Grid container>
        <div onDragOver={handleDragOver} onDrop={handleDrop} style={dragStyle}>
          <Grid
            container
            item
            justifyContent={"center"}
            alignContent={"center"}
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <Grid
              item
              container
              justifyContent={"center"}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              marginBottom={"16px"}
              paddingBottom={"16px"}
              width={"75vw"}
              border={"4px dotted #D8D8D8"}
            >
              <Stack
                direction={"column"}
                rowGap={1}
                padding={"24px 24px 24px 24px"}
                sx={{ cursor: "pointer" }}
              >
                <Button
                  style={{ marginTop: "20px" }}
                  onClick={handleUploadClick}
                  startIcon={<UploadFileIcon />}
                ></Button>
                <Stack direction={"row"}>
                  <div
                    style={{
                      cursor: "pointer",
                      fontWeight: 400,
                      color: "#0075ff",
                      textDecoration: "underline",
                      textDecorationThickness: "2px",
                      textDecorationColor: "rgb(0 117 255 / 67%)",
                    }}
                    onClick={handleUploadClick}
                  >
                    Click to upload &nbsp;
                  </div>
                  <Typography fontWeight={400}>or drag and drop</Typography>
                </Stack>

                <Typography fontWeight={400} variant="body2">
                  <span> SVG, PNG, JPG, GIF or PDF (max. 3MB)</span>
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </div>
      </Grid>

      <Grid container spacing={2} padding={"16px"}>
        {files.map((file, index) => (
          <Grid
            item
            container
            justifyContent={"space-between"}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            key={index}
            alignItems={"center"}
          >
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Stack direction={"row"} spacing={2} className="flex-col">
                {console.log("file", file)}
                {getFileType(file?.name) != "image" ? (
                  <Link
                    onClick={() => {
                      setPDFView(URL.createObjectURL(file));
                      setPDFOpenModalShows(true);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {file.name}
                  </Link>
                ) : (
                  <img
                    src={URL.createObjectURL(file)}
                    alt=".."
                    className="current-app-img"
                    onClick={() => {
                      setImageView(URL.createObjectURL(file));
                      setImageOpenModalShows(true);
                    }}
                  />
                )}

                <Typography
                  fontWeight={400}
                  variant="body2"
                  fontSize={"14px"}
                  className="margin-left-zero"
                >
                  <span>{fileSize(file.size)} </span>
                </Typography>
              </Stack>

              {/* {file.name} - {uploadProgress[index] ? `${uploadProgress[index].toFixed(2)}%` : 'Uploading...'} */}
            </Grid>
            <Grid item>
              <Stack>
                <Delete
                  style={{ color: "gray", cursor: "pointer" }}
                  onClick={() => handleDelete(index)}
                />
              </Stack>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default FileUpload;
