import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Breadcrumbs,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Modal,
  Paper,
  Stack,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  addCalendarSetting,
  deleteCalendarSetting,
  editCalendarSetting,
} from "../../redux/Calendar/action";
import { mechanicDetailsList } from "../../redux/appointment/action";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  pl: 4,
  pr: 0,
  pb: 3,
};
const breadcrumbStyle = {
  marginBottom: "8px",
};
function CalendarSetting(props) {
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    reset,
  } = useForm();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const [isEdit, setIsEdit] = useState({ edit: false, id: "" });
  const [deleteId, setDeleteId] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);

  useEffect(() => {
    fetchCalendarSettings();
  }, []);
  const fetchCalendarSettings = async () => {
    setShowLoader(true);
    await dispatch(mechanicDetailsList());
    setShowLoader(false);
  };
  const settings = useSelector(
    (state) => state.appointment.mechanicDetailsData
  );
  // console.log("settings, settings",settings);
  const handleClose = () => {
    setOpen(false);
    setIsEdit({ edit: false, id: "" });
    reset();
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const onSubmit = async (values) => {
    setShowLoader(true);

    if (isEdit?.edit) {
      const resp = await dispatch(
        editCalendarSetting({ id: isEdit?.id, name: values })
      );
    } else {
      const resp = await dispatch(addCalendarSetting(values));
    }
    fetchCalendarSettings();
    handleClose();
    // console.log("values", values);
  };

  const handleDelete = async () => {
    setShowLoader(true);
    await dispatch(deleteCalendarSetting({ id: deleteId }));
    fetchCalendarSettings();
    handleCloseDelete();
    handleClose();
  };

  return (
    <div>
      <Box padding={"24px 24px 24px 20px"}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div
            role="presentation"
            // onClick={handleClick}
            style={breadcrumbStyle}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                to="/calendar"
                style={{
                  color: "black",
                  opacity: "0.6",
                  textDecoration: "none",
                }}
              >
                Calendar
              </Link>
              <Link style={{ color: "black", textDecoration: "none" }}>
                <Typography color="black">Settings</Typography>
              </Link>
            </Breadcrumbs>
          </div>
        </Grid>
        <Grid container rowGap={3}>
          <Grid container item justifyContent={"space-between"}>
            <Grid item lg={8}>
              <Typography variant="h4" sx={{ fontWeight: 400 }}>
                Calendar Settings
              </Typography>
            </Grid>
            <Grid item lg={4}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                style={{ float: "right" }}
                onClick={() => setOpen(true)}
              >
                + New Calendar
              </Button>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent={"space-between"}
            spacing={2}
            mt={"24px"}
          >
            {Array.isArray(settings) &&
              settings.map((d) => {
                return (
                  <Grid item md={12} sm={12} key={d._id}>
                    <Card>
                      <Paper>
                        <CardHeader
                          title={
                            <Stack spacing={3} direction="row">
                              <div
                                style={{
                                  width: "100%",
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                {d.name}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="large"
                                  onClick={() => {
                                    setIsEdit({ edit: true, id: d._id });
                                    setOpen(true);
                                    setValue("name", d.name);
                                  }}
                                >
                                  EDIT
                                </Button>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  color="error"
                                  size="large"
                                  onClick={() => {
                                    setOpenDelete(true);
                                    setDeleteId(d._id);
                                  }}
                                >
                                  DELETE
                                </Button>
                              </div>
                            </Stack>
                          }
                        ></CardHeader>
                      </Paper>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: "40vw" }}>
          <AppBar
            color="secondary"
            variant="elevation"
            sx={{
              height: "64px",
              paddingRight: "0px !important",
            }}
          >
            <Toolbar variant="regular">
              <Typography variant="h6" width={"98%"} color={"white"}>
                {isEdit?.edit ? "Edit " : "New "}
                Calendar
              </Typography>
              <Stack direction={"row"} spacing={0}>
                <div onClick={handleClose} style={{ cursor: "pointer", height: '32px' }}>
                  <CloseIcon style={{ fontSize: 32, color: "#0000008F" }} />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>
          <Container
            gap={2}
            disableGutters
            style={{ marginTop: "66px", paddingRight: "20px" }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} xl={12}>
                  <Typography variant="body1" style={{ fontWeight: 400 }}>
                    Calendar Name
                    {/* <span style={{ color: "#D32F2F" }}> *</span> */}
                  </Typography>
                  <Controller
                    name="name"
                    control={control}
                    rules={{
                      required: "Calendar name is required",
                    }}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        required
                        id="outlined-basic"
                        variant="outlined"
                        error={!!errors.name}
                        helperText={errors.name ? errors.name.message : ""}
                        {...field}
                      />
                    )}
                  />
                  {/* <TextField
                            id="outlined-basic"
                            variant="outlined"
                            fullWidth
                          /> */}
                </Grid>

                <Grid
                  item
                  container
                  justifyContent={"space-between"}
                  spacing={2}
                  style={{ marginTop: "20px" }}
                >
                  <Grid item>
                    <Stack spacing={2} direction="row">
                      {isEdit?.edit && (
                        <Button
                          variant="contained"
                          color="error"
                          //   onClick={handleDeleteVehicle}
                          size="large"
                        >
                          DELETE CALENDAR
                        </Button>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item>
                    <Stack spacing={2} direction="row">
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleClose}
                        size="large"
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        // onClick={handleClose}
                        size="large"
                        type="submit"
                      >
                        Save
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Box>
      </Modal>

      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ fontWeight: "400", fontSize: "24px" }}
        >
          {"Are you Sure?"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDelete}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The selected calendar will be deleted and all existing scheduled
            appointments will shift to default calendar.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete} variant="outlined">
            CANCEL
          </Button>
          <Button
            onClick={handleDelete}
            autoFocus
            color="error"
            variant="contained"
          >
            DELETE
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CalendarSetting;
