import { useTheme } from "@emotion/react";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Context, REQUIRED_RED } from "../../../App";
import {
  personalDetails,
  updatePersonalDetails,
} from "../../../redux/Account/peronalIfno/action";
import SuccessMessageModal from "../../Common/SuccessMessage";
import EmailCustomer from "./EmailCustomer";
import { inputFormatPhoneNumber } from "../../Common/FormatPhoneNumber";
const PersonalInfo = () => {
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);

  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const { isOpenSideBar } = useContext(Context);
  const [loadingCount, setLoadingCount] = useState(0);
  const {
    handleSubmit,

    setValue,
    control,
    formState: { errors },
  } = useForm();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const DetailsData = useSelector(
    (state) => state?.account?.personalDetailsData
  );

  useEffect(() => {
    getPersonalDetails();
  }, []);

  useEffect(() => {
    setValue("email", DetailsData?.email);
    setValue("firstName", DetailsData?.firstName);
    setValue("lastName", DetailsData?.lastName);
    setValue("phoneNumber", inputFormatPhoneNumber(DetailsData?.phoneNumber));
  }, [DetailsData]);

  useEffect(() => {
    setLoadingCount((prevCount) =>
      showLoader ? prevCount + 1 : prevCount - 1
    );
  }, [showLoader]);
  console.log("loadingcount", loadingCount);
  const getPersonalDetails = async () => {
    setShowLoader(true);

    const data = await dispatch(personalDetails());
    if (data) {
      setShowLoader(false);
    }
  };
  const onSubmit = async (values) => {
    setShowLoader(true);
    delete values.shopEmail;
    if (values.email == DetailsData?.email) delete values.email;
    values.phoneNumber = values.phoneNumber.toString()?.replace(/-/g, "");
    let noDashedNumber = DetailsData?.phoneNumber
      ?.toString()
      ?.replace(/-/g, "");
    if (values.phoneNumber == noDashedNumber) delete values.phoneNumber;

    let result = await dispatch(updatePersonalDetails(values));
    if (result) {
      setOpenSuccessModal(true);
      setSuccessMessage("Changes Saved");
      getPersonalDetails();
    }
    setShowLoader(false);
  };

  const cancelHandle = () => {
    setValue("email", DetailsData?.email);
    setValue("firstName", DetailsData?.firstName);
    setValue("lastName", DetailsData?.lastName);
    setValue("phoneNumber", inputFormatPhoneNumber(DetailsData?.phoneNumber));
  };

  const validateNoLeadingSpaces = (value) => {
    if (value && value.trim() !== value) {
      return "Leading spaces are not allowed";
    }
    return true;
  };

  return (
    <>
      <Box>
        {" "}
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "20px" }}>
          <Grid
            container
            sx={{ boxShadow: 3, bgcolor: "white" }}
            justifyContent={"start"}
            // alignItems={"center"}
            marginTop={"32px"}
            padding={"24px"}
            borderRadius={"10px"}
            // marginTop={"20px"}
          >
            <Grid item xs={12} sm={12} md={isOpenSideBar ? 6 : 5} lg={4} xl={4}>
              <Typography variant="h5">Personal Information</Typography>
            </Grid>

            <Grid
              container
              item
              xs={12}
              sm={12}
              md={isOpenSideBar ? 6 : 7}
              lg={8}
              xl={8}
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                xl={6}
                style={{ paddingTop: "20px" }}
              >
                <Controller
                  name="firstName"
                  control={control}
                  rules={{
                    required: "First Name is required",
                    pattern: {
                      value: /^[A-Za-z]+$/,
                      message:
                        "Only letters are allowed in First Name. or space will be not allowed.",
                    },
                    validate: validateNoLeadingSpaces, // Custom validation
                  }}
                  render={({ field }) => (
                    <>
                      <Typography variant="body1" className="label-500">
                        First Name{" "}
                        <span style={{ color: REQUIRED_RED }}>*</span>
                      </Typography>
                      <TextField
                        // label="First Name"
                        fullWidth
                        id="outlined-basic"
                        variant="outlined"
                        error={!!errors.firstName}
                        helperText={
                          errors.firstName ? errors.firstName.message : ""
                        }
                        {...field}
                        InputLabelProps={{ shrink: field.value?.length > 0 }}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                xl={6}
                style={{ paddingTop: "20px" }}
              >
                <Typography variant="body1" className="label-500">
                  Last Name <span style={{ color: REQUIRED_RED }}>*</span>
                </Typography>
                <Controller
                  name="lastName"
                  control={control}
                  rules={{
                    required: "Last Name is required",
                    pattern: {
                      value: /^[A-Za-z]+$/,
                      message: "Only letters are allowed in Last Name",
                    },
                    validate: validateNoLeadingSpaces, // Custom validation
                  }}
                  render={({ field }) => (
                    <TextField
                      // label="Last Name"
                      fullWidth
                      id="outlined-basic"
                      variant="outlined"
                      error={!!errors.lastName}
                      helperText={
                        errors.lastName ? errors.lastName.message : ""
                      }
                      {...field}
                      InputLabelProps={{ shrink: field.value?.length > 0 }}
                    />
                  )}
                />
              </Grid>
              {/* <Grid container item xs={12} sm={12} md={12} xl={12} spacing={2}> */}
              <Grid item xs={12} sm={12} md={6} xl={6}>
                <Typography variant="body1" className="label-500">
                  Phone Number <span style={{ color: REQUIRED_RED }}>*</span>
                </Typography>
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{
                    required: "Phone Number is required",
                    minLength: 12,
                    // validate: validateNoLeadingSpaces,
                  }}
                  render={({ field }) => (
                    <TextField
                      // label="Phone"
                      fullWidth
                      id="outlined-basic"
                      variant="outlined"
                      // type="number"
                      error={!!errors.phoneNumber}
                      helperText={
                        errors.phoneNumber ? errors.phoneNumber.message : ""
                      }
                      {...field}
                      InputLabelProps={{ shrink: field.value?.length > 0 }}
                      inputProps={{
                        maxLength: 12,
                        minLength: 12,
                      }}
                      onChange={(e) => {
                        setValue(
                          "phoneNumber",
                          inputFormatPhoneNumber(e.target.value)
                        );
                      }}
                    />
                  )}
                />
              </Grid>
              {/* </Grid> */}

              <Grid item xs={12} sm={12} md={6} xl={6}>
                <Typography variant="body1" className="label-500">
                  Email <span style={{ color: REQUIRED_RED }}>*</span>
                </Typography>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Invalid email address",
                    },
                    validate: validateNoLeadingSpaces,
                  }}
                  render={({ field }) => (
                    <TextField
                      // label="Email"
                      fullWidth
                      id="outlined-basic"
                      variant="outlined"
                      error={!!errors.email}
                      helperText={errors.email ? errors.email.message : ""}
                      {...field}
                      InputLabelProps={{ shrink: field.value?.length > 0 }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent={"end"}
              spacing={2}
              style={{ marginTop: "10px" }}
            >
              <Grid item spacing={2}>
                <Stack spacing={2} direction="row">
                  <Button
                    variant="outlined"
                    color="primary"
                    className="btn-text-size-manage"
                    onClick={cancelHandle}
                  >
                    Cancel Changes
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    className="btn-text-size-manage"
                  >
                    Save Changes
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>{" "}
        </form>
      </Box>
      <EmailCustomer setShowLoader={setShowLoader} />
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
    </>
  );
};

export default PersonalInfo;
