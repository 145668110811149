import CloseIcon from "@mui/icons-material/Close";
import { AppBar, Box, Button, Modal, Stack, Typography } from "@mui/material";
import { ContentState, Editor, EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import htmlToDraft from "html-to-draftjs";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 720,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 3,
  //   padding
};
function EditAuthorizationModel({ open, setOpen, message, setMessage }) {
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      message: message,
    },
  });
  const [error, setError] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const handleEditorChange = (newEditorState) => {
    const contentState = newEditorState.getCurrentContent();
    const text = contentState.getPlainText();

    if (text.trim() === "") {
      setError(true);
    } else {
      setError(false);
    }
    // const rawContent = convertToRaw(newEditorState.getCurrentContent());
    // const text = rawContent.blocks.map((block) => block.text).join("");
    // console.log("text", text);

    setEditorState(newEditorState);
  };
  const blockStyleFn = (block) => {
    if (block.getType() === "header-one") {
      return "header-one";
    } else if (block.getType() === "header-two") {
      return "header-two";
    } else {
      return null;
    }
  };

  useEffect(() => {
    const preprocessHTML = (html) => {
      return html
        .replace(/<p><br><\/p>/g, "<p></p>")
        .replace(/(<p><\/p>)+/g, "<p></p>"); // Remove consecutive empty paragraphs
    };

    const processedHTML = preprocessHTML(message);

    const { contentBlocks, entityMap } = htmlToDraft(processedHTML);
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    setEditorState(EditorState.createWithContent(contentState));
  }, [open, message]);

  const handleClose = () => {
    setOpen(false);
    reset();
  };
  const handelSave = (values) => {
    const contentState = editorState.getCurrentContent();
    const text = contentState.getPlainText();
    const cssStyles = `
    <style>
      p {
        margin: 0;
        padding: 0;
      }
    </style>
  `;
    if (text.trim() === "") {
      return setError(true);
    } else {
      setError(false);
    }
    const htmlContent = stateToHTML(editorState.getCurrentContent());
    setMessage(cssStyles + htmlContent);
    setOpen(false);
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={style} className="modalBodyBoxContainer">
        <AppBar
          color="secondary"
          variant="elevation"
          sx={{
            height: "64px",
            borderRadius: "8px 8px 0px 0px !important",
            paddingRight: "0px !important",
            justifyContent: "center",
          }}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            display={"flex"}
            // paddingTop={1.9}
            paddingInline={2}
          >
            <Typography variant="h6" color={"white"}>
              Edit Authorization
            </Typography>
            <div
              onClick={handleClose}
              style={{ cursor: "pointer", height: "32px" }}
            >
              <CloseIcon sx={{ fill: "white", fontSize: 35 }} />
            </div>
          </Stack>
        </AppBar>
        <form onSubmit={handleSubmit(handelSave)}>
          <Box mt={8} ml={0}>
            <Controller
              name="message"
              control={control}
              rules={{
                required: "Message is required",
              }}
              render={({ field }) => (
                <div
                  style={{
                    border: error
                      ? "1px solid rgb(211,47,47)"
                      : "1px solid #ACACAC",
                    minHeight: "170px",
                    maxHeight: "170px",

                    padding: "12px",
                    borderRadius: "4px",
                    overflow: "auto",
                  }}
                >
                  <Editor
                    editorState={editorState}
                    onChange={handleEditorChange}
                    blockStyleFn={blockStyleFn}
                  />
                </div>
              )}
            />
            {error && (
              <span
                style={{
                  fontWeight: 400,
                  fontSize: "12px",
                  color: "rgb(211,47,47)",
                }}
              >
                Message is required
              </span>
            )}
          </Box>
          <Stack
            direction={"row"}
            justifyContent={"end"}
            display={"flex"}
            paddingTop={3}
            //   paddingInline={2}
            spacing={2}
          >
            <Button variant="outlined" size="large" onClick={handleClose}>
              CANCEl
            </Button>
            <Button variant="contained" size="large" type="submit">
              SAVE
            </Button>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
}

export default EditAuthorizationModel;
