import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { toast } from "sonner";
import {
  addPaymentMethod,
  updateCard,
} from "../../../redux/Account/Billing/action";
import CloseIcon from "@mui/icons-material/Close";

import { useDispatch } from "react-redux";
import { getItem } from "../../../utility/localStorage";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
function AddpaymentModel({
  addCardModal,
  setAddCardModal,
  setModalLoader,
  fetchCurrentPlan,
  setAddedCardResponse = () => {},
  setSelectedCard = () => {},
  setOpenSubscriptionModal = () => {},
}) {
  const shopOWnerId = getItem("apex-saas_details").shopOWnerId;
  const { pathname } = useLocation();

  const [savePaymentLoader, setSavePaymentLoader] = useState(false);
  const dispatch = useDispatch();
  const [choosedCard, setChoosedCard] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const currentPlanDetails = useSelector((state) => state.account.currentPlan);

  const handleCardSubmit = async (obj) => {
    if (
      (currentPlanDetails?.isManualSubscription ||
        Object.keys(currentPlanDetails?.currentCard ?? {}).length == 0) &&
      currentPlanDetails?.cards?.length > 0
    ) {
      if (choosedCard === null) {
        return toast.error("Please choose card");
      }
      setSelectedCard(choosedCard);
      setAddCardModal(false);
      setOpenSubscriptionModal(true);
    } else {
      setSavePaymentLoader(true);

      const cardElement = elements.getElement("card");

      stripe.createToken(cardElement).then(async (payload) => {
        if (payload?.error?.message) {
          toast.error("Please enter complete card details");
          setSavePaymentLoader(false);

          return;
        }

        if (payload?.token?.id) {
          const cardpayload = {
            cardHolder: shopOWnerId,
            token: payload?.token?.id,
          };
          setModalLoader(true);

          let resp = await dispatch(addPaymentMethod(cardpayload));
          if (resp && currentPlanDetails?.isManualSubscription == false) {
            let payload = { paymentMethodId: resp?.data?._id };

            const resp1 = await dispatch(updateCard(payload));
            if (resp1) {
              toast.success(
                `Payment method ${
                  currentPlanDetails?.currentCard ? "updated" : "added"
                } successfully`
              );
              fetchCurrentPlan();
            }

            if (pathname != "/account-settings/billing") {
              setSelectedCard(resp);
              setOpenSubscriptionModal(true);
            }

            // fetchCards(userid);
            setAddCardModal(false);
          }
          if (resp && currentPlanDetails?.isManualSubscription == true) {
            fetchCurrentPlan();
            setAddCardModal(false);
            setAddedCardResponse(resp);
            setSelectedCard(resp?.data);
            setOpenSubscriptionModal(true);
          }
        }
        setSavePaymentLoader(false);
        setModalLoader(false);
      });
    }
  };
  return (
    <div>
      <Modal open={addCardModal} onClose={() => setAddCardModal(false)}>
        <Box
          sx={{
            ...style,
            width: "600px",
            border: "none",
            borderRadius: "8px 8px 0px 0px",
          }}
        >
          <AppBar
            color="secondary"
            variant="elevation"
            sx={{
              height: "64px",
              paddingRight: "0px !important",
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            <Toolbar variant="regular">
              <Typography variant="h6" width={"98%"} color={"white"}>
                Payment Method
              </Typography>
              <Stack direction={"row"} spacing={0}>
                <div style={{ cursor: "pointer", height: "32px" }}>
                  <CloseIcon
                    style={{ color: "#FFFFFF", fontSize: 32 }}
                    onClick={() => setAddCardModal(false)}
                  />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>
          <Container
            gap={2}
            disableGutters
            style={{
              scrollbarColor: "blue",
              scrollbarWidth: "2px",
              marginTop: "75px",
              // paddingRight: "14px",
            }}
          >
            <Grid container justifyContent={"start"} spacing={3}>
              {(currentPlanDetails?.isManualSubscription ||
                Object.keys(currentPlanDetails?.currentCard ?? {}).length ==
                  0) &&
              pathname != "/account-settings/billing" ? (
                <>
                  {currentPlanDetails?.cards?.length == 0 ? (
                    <Grid item md={12} xl={12} spacing={2}>
                      <CardElement />
                    </Grid>
                  ) : (
                    <Grid
                      item
                      md={12}
                      xl={12}
                      style={{
                        maxHeight: "40vh",
                        overflowY: "auto",
                      }}
                    >
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        onChange={(e) => {
                          setChoosedCard(JSON.parse(e.target.value));
                          console.log("eee", e.target.value);
                        }}
                      >
                        {currentPlanDetails?.cards?.map((card, index) => (
                          <Grid container>
                            <FormControlLabel
                              style={{ width: "100%" }}
                              value={JSON.stringify(card)}
                              control={<Radio />}
                              className="carddd"
                              label={
                                <Grid
                                  item
                                  container
                                  xs={12}
                                  style={{ width: "100%" }}
                                >
                                  <Stack
                                    sx={{
                                      // justifyContent: "start",

                                      marginTop: index == 0 ? "0" : "20px",
                                      marginRight: "20px",
                                      border: "1px solid black",
                                      borderRadius: "8px",
                                      padding: "16px",
                                      width: "100%",
                                    }}
                                  >
                                    <Typography variant="body">
                                      {card?.cardBrand}{" "}
                                      {card?.cardBrand &&
                                        card?.lastFourDigit &&
                                        "ending in"}{" "}
                                      {card?.lastFourDigit}
                                    </Typography>
                                    <Typography variant="body">
                                      {card?.expirationMonth &&
                                        card?.expirationYear &&
                                        "Expires"}{" "}
                                      {card?.expirationMonth}
                                      {card?.expirationMonth &&
                                        card?.expirationYear &&
                                        "/"}
                                      {card?.expirationYear}
                                    </Typography>
                                  </Stack>
                                </Grid>
                              }
                            />
                          </Grid>
                        ))}
                      </RadioGroup>
                    </Grid>
                  )}
                </>
              ) : (
                <Grid item md={12} xl={12} spacing={2}>
                  <CardElement />
                </Grid>
              )}

              <Grid
                item
                container
                justifyContent={"end"}
                spacing={2}
                // style={{ marginTop: "20px" }}
              >
                <Grid item spacing={2}>
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setAddCardModal(false)}
                    >
                      Cancel{" "}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCardSubmit}
                      disabled={savePaymentLoader}
                    >
                      {savePaymentLoader && (
                        <>
                          <CircularProgress
                            size={20}
                            sx={{ mr: 1, color: "grey" }}
                          />
                        </>
                      )}{" "}
                      SAVE
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            {/* <PaymentElement /> */}
          </Container>
        </Box>
      </Modal>
    </div>
  );
}

export default AddpaymentModel;
