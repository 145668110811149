import { Add } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ESTIMATE_INVOICE_DRAFT, ESTIMATE_INVOICE_SENT } from "../../App";
import FormatNumber from "../../components/FormatNumber/FormatNumber";
import { invoiceListing } from "../../redux/Invoice/action";
import SelectVehicleModal from "../modal/SelectVehicleModal";
import ExportReportModel from "./ExportReportModel";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 4 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Invoice = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [invoiceData, setInvoiceData] = React.useState([]);
  const [showLoader, setShowLoader] = React.useState(false);
  const dispatch = useDispatch();
  const [sortTypeDate, setsortTypeDate] = React.useState("");
  const [sortByDate, setsortByDate] = React.useState("createdAt");
  const [sortTypeName, setsortTypeName] = React.useState("");
  const [sortTypeTotal, setsortTypeTotal] = React.useState("");

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const index = [0, 1, 2];
  const nameArray = window.location.pathname.split("/");
  const name = nameArray[nameArray.length - 1];
  console.log("🚀 ~ file: Invoice.jsx:121 ~ Invoice ~ name:", name);
  const [yearData, setYearData] = React.useState("");
  const [makeData, setMakeData] = React.useState("");
  const [modelData, setModelData] = React.useState("");
  const [selectDate, setSelectDate] = React.useState(null);
  const [dateData, setDateData] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [openExportModel, setOpenExportModel] = useState(false);
  const invoiceListingDataParams = {
    page: page,
    rowsPerPage: rowsPerPage,
    currentPageStatus:
      name == "sents" ? "sent" : name == "drafts" ? "draft" : "all",
    sortTypeName: sortTypeName
      ? sortTypeName
      : sortTypeName
      ? sortTypeName
      : sortTypeTotal
      ? sortTypeTotal
      : "DSC",
    sortByDate: sortByDate,
    yearData: yearData,
    makeData: makeData,
    modelData: modelData,
    dateData: dateData,
    search: search,
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPage(0);
    const updatingParams = { ...invoiceListingDataParams };
    updatingParams.page = 1;

    if (newValue == 0) {
      updatingParams.currentPageStatus = "all";

      invoiceDetailsData(updatingParams);

      navigate("/invoice/all");
    }
    if (newValue == 1) {
      updatingParams.currentPageStatus = "sent";

      invoiceDetailsData(updatingParams);

      navigate("/invoice/sents");
    }
    if (newValue == 2) {
      updatingParams.currentPageStatus = "draft";

      invoiceDetailsData(updatingParams);

      navigate("/invoice/drafts");
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    if (yearData || makeData || modelData) {
      const updatingParams = { ...invoiceListingDataParams };

      updatingParams.page = 1;
      // updatingParams.currentPageStatus = "all";

      invoiceDetailsData(updatingParams);
    }
    setOpen(false);
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };
  const navigate = useNavigate();

  useEffect(() => {
    setInvoiceData({});
    const updatingParams = { ...invoiceListingDataParams };

    updatingParams.page = 1;

    if (name == "all") {
      setValue(0);
      updatingParams.currentPageStatus = "all";

      invoiceDetailsData(updatingParams);
    }
    if (name == "sents") {
      setValue(1);
      updatingParams.currentPageStatus = "sent";

      invoiceDetailsData(updatingParams);
    }
    if (name == "drafts") {
      setValue(2);
      updatingParams.currentPageStatus = "draft";

      invoiceDetailsData(updatingParams);
    }
  }, [window.location.pathname]);

  const invoiceDetailsData = async ({
    page,
    rowsPerPage,
    currentPageStatus,
    sortTypeName,
    sortByDate,
    yearData,
    makeData,
    modelData,
    dateData,
    search,
  }) => {
    setShowLoader(true);
    let data = await dispatch(
      invoiceListing(
        page,
        rowsPerPage,
        currentPageStatus,
        sortTypeName,
        sortByDate,
        yearData,
        makeData,
        modelData,
        dateData,
        search
      )
    );
    const id = data?._id;
    if (data?._id) {
      localStorage.setItem("id", id);
    }
    if (data) {
      setInvoiceData(data);
    }
    setShowLoader(false);
  };

  // const invoiceData = useSelector((state) => state.invoice.invoiceData);

  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, invoiceData.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const updatingParams = { ...invoiceListingDataParams };

    updatingParams.page = newPage + 1;
    // updatingParams.currentPageStatus = "all";

    invoiceDetailsData(updatingParams);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("event", event.target.value);
    // setRowsPerPage(parseInt(event.target.value, 5));
    setRowsPerPage(event.target.value);

    setPage(0);
    const updatingParams = {
      ...invoiceListingDataParams,
      page: 1,
      rowsPerPage: event.target.value,
    };

    updatingParams.page = 1;
    // updatingParams.currentPageStatus = "all";

    invoiceDetailsData(updatingParams);
  };

  const handleSort = (sortType, sortBy) => {
    setsortByDate(sortBy);

    if (sortBy == "createdAt") {
      setsortTypeDate(sortType == "DSC" ? "ASC" : "DSC");
      setsortTypeName("");
      setsortTypeTotal("");
    } else if (sortBy == "customer.fullName") {
      setsortTypeName(sortType == "DSC" ? "ASC" : "DSC");
      setsortTypeDate("");
      setsortTypeTotal("");
    } else if (sortBy == "Total") {
      setsortTypeName("");
      setsortTypeDate("");
      setsortTypeTotal(sortType == "DSC" ? "ASC" : "DSC");
    } else {
    }
    const updatingParams = { ...invoiceListingDataParams };

    updatingParams.page = page == 0 ? 1 : page;
    updatingParams.sortTypeName = sortType;
    updatingParams.sortByDate = sortBy;

    if (name == "all") {
      updatingParams.currentPageStatus = "all";

      invoiceDetailsData(updatingParams);
    }
    if (name == "sents") {
      updatingParams.currentPageStatus = "sent";

      invoiceDetailsData(updatingParams);
    }
    if (name == "drafts") {
      updatingParams.currentPageStatus = "draft";

      invoiceDetailsData(updatingParams);
    }
  };

  const handleCancel = () => {
    const updatingParams = { ...invoiceListingDataParams };

    updatingParams.page = 1;
    updatingParams.sortTypeName = sortTypeName
      ? sortTypeName
      : sortTypeName
      ? sortTypeName
      : sortTypeTotal
      ? sortTypeTotal
      : "DSC";
    updatingParams.sortByDate = sortByDate;
    updatingParams.modelData = "";
    // updatingParams.dateData = "";
    // updatingParams.search = "";
    updatingParams.yearData = "";
    updatingParams.makeData = "";
    // updatingParams.currentPageStatus = "all";

    invoiceDetailsData(updatingParams);

    setOpen(false);
  };

  const handleDateChange = (date) => {
    // alert(date);
    const updatingParams = { ...invoiceListingDataParams };

    updatingParams.page = 1;

    if (date != null) {
      const newDate = new Date(date);
      let utcDate = moment(newDate).format("YYYY-MM-DD");
      setDateData(utcDate);

      updatingParams.dateData = utcDate;

      invoiceDetailsData(updatingParams);
    } else {
      updatingParams.dateData = "";
      setDateData("");

      invoiceDetailsData(updatingParams);
    }
  };

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  }

  const onChange = async (e, makeData, modelData, yearData) => {
    setSearch(e);
    const updatingParams = { ...invoiceListingDataParams };
    updatingParams.page = 1;

    updatingParams.search = e;
    updatingParams.modelData = modelData;
    updatingParams.yearData = yearData;
    updatingParams.makeData = makeData;

    invoiceDetailsData(updatingParams);
  };

  const debounceOnChange = React.useCallback(debounce(onChange, 800), []);
  // if(showLoader){
  //   return <Loader showLoader={showLoader}/>
  // }

  return (
    <>
      <Box padding={"32px 24px"} sx={{ bgcolor: "background.main" }}>
        <Grid container rowGap={3}>
          <Grid container item justifyContent={"space-between"}>
            <Grid item sm={4} lg={4}>
              <Typography variant="h4" sx={{ fontWeight: 400 }}>
                Invoice{" "}
              </Typography>
            </Grid>
            <Grid container sm={8} lg={8} gap={3} justifyContent={"end"}>
              <Grid item spacing={3}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setOpenExportModel(true);
                  }}
                  className="btn-text-size-manage"
                >
                  EXPORT
                </Button>
              </Grid>
              <Grid>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<Add />}
                  onClick={() => {
                    navigate("/invoice/new");
                  }}
                  className="btn-text-size-manage"
                >
                  NEW Invoice
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            item
            // gap={2}
            margin={"12px 0"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item container alignItems={"center"}>
              <Box sx={{ width: "100%" }}>
                <Box>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      label="ALL"
                      {...a11yProps(0)}
                      style={{ fontWeight: 500 }}
                    />
                    <Tab
                      label="Sent"
                      {...a11yProps(1)}
                      style={{ fontWeight: 500 }}
                    />
                    <Tab
                      label="drafts"
                      {...a11yProps(2)}
                      style={{ fontWeight: 500 }}
                    />
                  </Tabs>
                </Box>
                {index?.map((i) => {
                  return (
                    <CustomTabPanel
                      value={value}
                      key={i}
                      index={i}
                      padding={"24px"}
                    >
                      <Grid container item>
                        <Grid
                          container
                          marginBottom={"32px"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          {/* Select Date */}
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            lg={2}
                            xl={2}
                            padding={"6px"}
                          >
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              display={"flex"}
                              justifyContent={"space-between"}
                              spacing={1}
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  label="Select date"
                                  name="proposeDate"
                                  value={selectDate}
                                  onChange={(data) => {
                                    if (data) {
                                      // const formattedDate = new Date(data);
                                      setSelectDate(data);
                                      handleDateChange(data);
                                    } else {
                                      setSelectDate(null);
                                      handleDateChange(data);
                                    }
                                  }}
                                  style={{ width: "100%" }}
                                  slotProps={{ field: { clearable: true } }}
                                />
                              </LocalizationProvider>
                            </Stack>
                          </Grid>

                          {/* Select Vehicle */}
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            lg={2}
                            xl={2}
                            padding={"6px"}
                          >
                            <Button
                              variant="text"
                              color="primary"
                              style={{ color: "black", fontWeight: 550 }}
                              endIcon={<ExpandMoreIcon />}
                              onClick={handleOpen}
                            >
                              SELECT VEHICLE
                            </Button>
                            <SelectVehicleModal
                              handleClose={handleClose}
                              open={open}
                              cancel={handleCancel}
                              setYearData={setYearData}
                              setMakeData={setMakeData}
                              setModelData={setModelData}
                              yearData={yearData}
                              makeData={makeData}
                              modelData={modelData}
                            />
                          </Grid>

                          {/* Select Search */}
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            lg={8}
                            xl={8}
                            padding={"6px"}
                          >
                            <FormControl fullWidth>
                              <InputLabel htmlFor="outlined-adornment-search">
                                Search
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-search"
                                placeholder="Customer name, service, etc..."
                                startAdornment={
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                }
                                label="Search"
                                onChange={(e) => {
                                  debounceOnChange(
                                    e.target.value,
                                    makeData,
                                    modelData,
                                    yearData
                                  );
                                }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          item
                          padding={"24px"}
                          boxShadow={2}
                          sx={{ bgcolor: "white" }}
                        >
                          {invoiceData?.data?.length == 0 &&
                          showLoader == false ? (
                            <div className="not-found-class">
                              Sorry, we could not find any matches.
                            </div>
                          ) : (
                            <Grid item xl={12} md={12} sm={12} xs={12}>
                              <TableContainer sx={{ maxHeight: 600 }}>
                                <Table
                                  stickyHeader
                                  aria-label="caption table"
                                  padding="16px"
                                  style={{ boxShadow: 0 }}
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>
                                        <TableSortLabel
                                          active={true}
                                          direction={
                                            sortTypeDate == "DSC"
                                              ? "asc"
                                              : "desc"
                                          }
                                          onClick={() => {
                                            setsortByDate("createdAt");
                                            handleSort(
                                              sortTypeDate == "DSC"
                                                ? "DSC"
                                                : "ASC",
                                              "createdAt"
                                            );
                                          }}
                                          sx={{ fontWeight: 500 }}
                                        >
                                          Invoice Number
                                        </TableSortLabel>{" "}
                                      </TableCell>
                                      <TableCell>
                                        {" "}
                                        <TableSortLabel
                                          // active={true}
                                          // direction="desc"
                                          active={true}
                                          direction={
                                            sortTypeName == "DSC"
                                              ? "asc"
                                              : "desc"
                                          }
                                          onClick={() => {
                                            handleSort(
                                              sortTypeName == "DSC"
                                                ? "DSC"
                                                : "ASC",
                                              "customer.fullName"
                                            );
                                          }}
                                          sx={{ fontWeight: 500 }}
                                        >
                                          {" "}
                                          Customer Name
                                        </TableSortLabel>
                                      </TableCell>
                                      <TableCell>
                                        <TableSortLabel
                                          active={true}
                                          direction={
                                            sortTypeDate == "DSC"
                                              ? "asc"
                                              : "desc"
                                          }
                                          onClick={() => {
                                            handleSort(
                                              sortTypeDate == "DSC"
                                                ? "DSC"
                                                : "ASC",
                                              "createdAt"
                                            );
                                          }}
                                          sx={{ fontWeight: 500 }}
                                        >
                                          {" "}
                                          Date
                                        </TableSortLabel>
                                      </TableCell>
                                      <TableCell>
                                        <TableSortLabel
                                          active={true}
                                          direction={
                                            sortTypeTotal == "DSC"
                                              ? "asc"
                                              : "desc"
                                          }
                                          onClick={() => {
                                            handleSort(
                                              sortTypeTotal == "DSC"
                                                ? "DSC"
                                                : "ASC",
                                              "Total"
                                            );
                                          }}
                                          sx={{ fontWeight: 500 }}
                                        >
                                          {" "}
                                          Total
                                        </TableSortLabel>
                                      </TableCell>
                                      <TableCell sx={{ fontWeight: 500 }}>
                                        <Typography fontWeight={500}>
                                          Status
                                        </Typography>
                                      </TableCell>
                                      <TableCell sx={{ fontWeight: 500 }}>
                                        <Typography fontWeight={500}>
                                          Actions
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {Array.isArray(invoiceData?.data) &&
                                      invoiceData?.data?.map((row) => (
                                        <TableRow
                                          key={row._id}
                                          sx={{ cursor: "pointer" }}
                                          hover={true}
                                          onClick={() =>
                                            navigate(
                                              `/invoice/${row?.status}/${row?._id}`
                                            )
                                          }
                                        >
                                          <TableCell component="th" scope="row">
                                            {row?.invoiceNumber}
                                          </TableCell>
                                          <TableCell align="left">{`${row?.customer?.fullName}`}</TableCell>
                                          <TableCell align="left">
                                            {" "}
                                            {moment(
                                              row && row?.createdAt
                                            ).format("MMM D, YYYY")}
                                          </TableCell>
                                          <TableCell align="left">
                                            <FormatNumber
                                              inputValue={(
                                                Math.round(
                                                  row && row?.Total * 100
                                                ) / 100
                                              ).toString()}
                                            />
                                          </TableCell>
                                          <TableCell align="left">
                                            <Chip
                                              label={
                                                row?.status &&
                                                row?.status
                                                  ?.charAt(0)
                                                  .toUpperCase() +
                                                  row?.status?.slice(1)
                                              }
                                              style={{
                                                backgroundColor:
                                                  row?.status == "sent"
                                                    ? ESTIMATE_INVOICE_SENT
                                                    : ESTIMATE_INVOICE_DRAFT,
                                                color: "black",
                                                fontWeight: "500",
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell align="left">
                                            {row?.status && (
                                              <Button
                                                variant="text"
                                                color="primary"
                                                onClick={() =>
                                                  navigate(
                                                    `/invoice/${row?.status}/${row?._id}`
                                                  )
                                                }
                                              >
                                                View{" "}
                                                {row?.status == "draft"
                                                  ? "draft"
                                                  : "invoice"}
                                              </Button>
                                            )}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              <Grid
                                container
                                item
                                alignItems={"center"}
                                justifyContent="end"
                              >
                                <Grid item>
                                  {showLoader ? (
                                    ""
                                  ) : (
                                    <>
                                      <TablePagination
                                        rowsPerPageOptions={[
                                          5, 10, 25,
                                          // { label: "All", value: 1000 },
                                        ]}
                                        colSpan={3}
                                        count={invoiceData?.totalCount}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        slotProps={{
                                          select: {
                                            "aria-label": "rows per page",
                                          },
                                          actions: {
                                            showFirstButton: true,
                                            showLastButton: true,
                                          },
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={
                                          handleChangeRowsPerPage
                                        }
                                      />
                                    </>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>{" "}
                      </Grid>
                    </CustomTabPanel>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <ExportReportModel open={openExportModel} close={setOpenExportModel} />
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Invoice;
