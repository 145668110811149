import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
// import Link from "@mui/material/Link";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import { REQUIRED_RED } from "../../App";
import PostalCode from "../../components/Address/PostalCode";
import {
  formatPostalCode,
  preventPhoneInputKeys,
} from "../../components/FormatNumber/FormatNumber";
import {
  customerCheckPhoneNumberExist,
  customerCreate,
  deleteCustomerVehicle,
  getCityList,
  getProvinceList,
  updatePhoneNumberExistCustomer,
} from "../../redux/Customer/action";
import {
  CONTACT_PREFRENCES,
  PAYMENT_TERMS,
  PHONE_TYPES,
} from "../../utility/Constant";
import {
  customStyles,
  errorCustomStyles,
  phoneTypeCustomStyles,
} from "../../utility/SelectDropdown";
import { inputFormatPhoneNumber } from "../Common/FormatPhoneNumber";
import SuccessMessageModal from "../Common/SuccessMessage";
import AddressSelect from "../Customers/Profile/AddressSelect";
import styles from "./NewSupplier.module.css";

const NewSupplier = () => {
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    getValues,
    setError,
    clearErrors,
    reset,
  } = useForm({
    defaultValues: {
      primaryPhoneNumberType: {
        key: "mobile",
        value: "Mobile",
      },
      secondaryPhoneNumberType: {
        key: "mobile",
        value: "Mobile",
      },
    },
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [vehicles, setVehicles] = useState([]);
  const [completeAddress, setCompleteAddress] = useState({});
  const [placeId, setPlaceId] = useState("");
  const [open, setOpen] = React.useState(false);
  const [editVehicle, setEditVehicle] = useState({
    edit: false,
    editIndex: "",
  });
  const [notes, setNotes] = useState([]);
  const [noteError, setNoteError] = useState(false);
  const [newNote, setNewNote] = useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [selectedActionOfNote, setSelectedActionOfNote] = useState("");
  const [editDeleteNoteId, setEditDeleteNoteId] = useState("");
  const [editNoteText, setEditNoteText] = useState("");
  const [getAddress, setAddress] = useState();
  const [recordFoundByPhone, setRecordFoundByPhone] = useState({
    id: "",
    found: false,
  });
  const [addressValues, setAddressValue] = useState({
    street_number: "",
    city: "",
    province: "",
    postal: "",
    country: "",
    provinceId: "",
    cityId: "",
    streetAddress: "",
  });
  const [cityList, setCityList] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [cityListLoader, setCityListLoader] = useState(false);
  const [provinceListLoader, setProvinceListLoader] = useState(false);
  const [phoneTypes, setPhoneTypes] = useState(PHONE_TYPES);
  const [paymentTermsTypes, setPaymentTermsType] = useState(PAYMENT_TERMS);

  const [selectedPhoneTypes, setSelectedPhoneTypes] = useState({
    primary: "mobile",
    secondary: "mobile",
  });
  console.log("🚀 ~ NewCustomers ~ addressValues:", addressValues);
  const [contactPrefrences, setContactPrefrences] =
    useState(CONTACT_PREFRENCES);

  const [showSecondaryNumber, setShowSecondaryNumber] = useState(false);
  const [showSecondaryEmail, setShowSecondaryEmail] = useState(false);
  const [primaryEmailIndex, setPrimaryEmailIndex] = useState(0);
  const [error, setErrors] = useState({});

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditVehicle({ edit: false, editIndex: "" });
  };
  const handleClickNoteActions = (event, data) => {
    setAnchorEl(event.currentTarget);
    setSelectedActionOfNote(data);
  };
  const handleCloseNoteActions = () => {
    setAnchorEl(null);
    setSelectedActionOfNote("");
  };

  const handleDeleteVehicleByAPI = async (id) => {
    setShowLoader(true);
    const resp = await dispatch(deleteCustomerVehicle(id));

    setShowLoader(false);
    return resp;
  };

  const handleAddNote = async () => {
    if (!newNote) {
      return setNoteError(true);
    }
    setNoteError(false);

    setNotes([...notes, { note: newNote, createdAt: new Date() }]);
    setNewNote("");
  };

  const deleteNote = (selectedActionOfNote) => {
    const index = notes.indexOf(selectedActionOfNote);
    notes.splice(index, 1);
  };

  const handleEditNote = () => {
    // console.log("notes", notes, editDeleteNoteId);
    const allNotes = [...notes];
    let findIndex = allNotes.indexOf(editDeleteNoteId);
    // console.log("findIndex", findIndex);
    let data = { ...allNotes[findIndex], note: editNoteText.trim() };
    allNotes[findIndex] = data;
    // allNotes[0].note="sdsds"
    setNotes(allNotes);
    setSelectedActionOfNote("");
    setEditDeleteNoteId("");
    setEditNoteText("");
  };

  useEffect(() => {
    fetchCityList();
    fetchProvinceList();
  }, []);

  const fetchCityList = async (province, country) => {
    setCityListLoader(true);
    let resp = await dispatch(
      getCityList({ country: "64e738882fec8f2c9b79c139", province: province })
    );
    if (resp) {
      // let finalCity = [...resp];
      // finalCity = finalCity
      // .filter((obj) => obj.hasOwnProperty("city"))
      // .map((obj) => obj.city);

      setCityList(resp);
    }
    setCityListLoader(false);
  };

  const fetchProvinceList = async (country) => {
    setProvinceListLoader(true);
    let resp = await dispatch(
      getProvinceList({ country: "64e738882fec8f2c9b79c139" })
    );
    if (resp) {
      // let finalProvince = [...resp];
      // finalProvince = finalProvince
      // .filter((obj) => obj.hasOwnProperty("province"))
      // .map((obj) => obj.province);

      setProvinceList(resp);
    }
    setProvinceListLoader(false);
  };

  // console.log("complete address", completeAddress);

  const onSubmit = async (values) => {
    console.log("🚀 ~ onSubmit ~ values:", values);
    // values.phoneNumber = values.phoneNumber.replace(/-/g, "");

    let payload = {
      // values.phoneNumber  = values.phoneNumber.replace(/-/g, ""),

      notes: [],
      vehicle: [],
      countryCode: "+1",
    };
    for (const key in values) {
      if (values[key]) payload[key] = values[key];
    }

    if (values.email) {
      payload.email = values.email;
    }
    if (values.lastName) {
      payload.lastName = values.lastName;
    }

    if (recordFoundByPhone?.found) {
      if (values.lastName) {
        payload.lastName = values.lastName;
      } else {
        payload.lastName = "";
      }
    }

    if (values?.streetAddress) {
      payload.streetAddress = values?.streetAddress;
    }
    if (addressValues?.city) {
      payload.city = addressValues?.cityId;
    }
    if (addressValues?.province) {
      payload.province = addressValues?.provinceId;
    }
    if (values?.postalCode) {
      payload.postalCode = values?.postalCode;
    }
    if (values?.contactPrefrence) {
      payload.contactPrefrence = values?.contactPrefrence?.key;
    } else {
      delete payload["contactPrefrence"];
    }
    if (values?.primaryPhoneNumberType) {
      payload.primaryPhoneNumberType = values?.primaryPhoneNumberType?.key;
    }

    payload.secondaryPhoneNumberType = values?.secondaryPhoneNumberType?.key;

    payload.secondaryPhoneNumber = values?.secondaryPhoneNumber;
    if (primaryEmailIndex == 0) {
      payload.email = values?.email;
      payload.secondaryEmail = values?.secondaryEmail;
    } else {
      payload.secondaryEmail = values?.email;
      payload.email = values?.secondaryEmail;
    }

    notes?.map((notee) => {
      payload.notes.push({ notes: notee?.note });
    });
    if (recordFoundByPhone?.found) {
      delete payload["notes"];
    } else {
      // email field delete for new customer create if email is empty
      if (payload.email == "") delete payload["email"];
    }

    setShowLoader(true);

    let resp;
    recordFoundByPhone?.found
      ? (resp = await dispatch(
          updatePhoneNumberExistCustomer(recordFoundByPhone?.id, payload)
        ))
      : (resp = await dispatch(customerCreate(payload)));
    if (resp) {
      navigate(`/customers/${resp?._id}/shop_created`);
      setOpenSuccessModal(true);
      setSuccessMessage(
        `Customer ${recordFoundByPhone?.found ? "Updated" : "Created"}`
      );
    }
    setShowLoader(false);
  };

  const checkPhoneNumberExist = async () => {
    setShowLoader(true);
    var phoneNumberWithoutDashes = getValues()?.phoneNumber.replace(/-/g, "");
    // console.log("phoneNumberWithoutDashes",phoneNumberWithoutDashes);

    const res = await dispatch(
      customerCheckPhoneNumberExist({ phoneNumber: phoneNumberWithoutDashes })
    );
    if (res.status) {
      setRecordFoundByPhone({ id: res?.data?._id, found: true });
      setValue("firstName", res?.data?.firstName);
      setValue("lastName", res?.data?.lastName);
      setValue("email", res?.data?.email);
      setValue("company", res?.data?.company);

      setCompleteAddress(res?.data?.addressDetailObject);
      setAddress(res?.data?.streetAddress);

      // setValue("company",res?.data?.company)
      let existvehicle = [];
      res?.data?.vehicleData?.map((veh) => {
        existvehicle.push({
          vehcileId: veh?._id,
          licenseNumber: veh?.licenseNumber,
          vehicleMakeId: {
            id: veh?.vehicleMake?._id,
            name: veh?.vehicleMake?.name,
          },
          vehicleModelId: {
            id: veh?.vehicleModel?._id,
            name: veh?.vehicleModel?.name,
          },
          vehicleModelTrimId: {
            id: veh?.vehicleModelTrim?._id,
            name: veh?.vehicleModelTrim?.name,
          },
          year: veh?.year + "",
        });
      });
      setVehicles(existvehicle);
    } else {
      setRecordFoundByPhone({ id: "", found: false });
      // setValue("firstName", "");
      // setValue("lastName", "");
      // setValue("email", "");
      // setValue("company", "");

      // setCompleteAddress({});
      // setAddress(" ");
      // setValue("city", "");
      // setValue("province", "");
      // setValue("postalCode", "");
      // setValue("country", "");
      // setValue("streetAddress", "");
      // setVehicles([]);
      let allVehciles = [...vehicles];
      allVehciles.map((v, key) => {
        if (v?.vehcileId) {
          delete v["vehcileId"];
        }
      });
      setVehicles(allVehciles);
    }
    setShowLoader(false);
  };
  const validatePhoneNumber = (number) => {
    const numericValue = number.replace(/\D/g, "");
    return numericValue.length === 10;
  };

  const validate = () => {
    const newErrors = {};
    const isAnyFieldFilled =
      addressValues.streetAddress ||
      addressValues.province ||
      addressValues.city ||
      addressValues.postal;

    if (isAnyFieldFilled) {
      if (!addressValues.streetAddress)
        newErrors.streetAddress = "Street address is required";
      if (!addressValues.province) newErrors.province = "Province is required";
      if (!addressValues.city) newErrors.city = "City is required";
      if (addressValues.postal) {
        const formattedPostal = formatPostalCode(addressValues.postal);
        if (formattedPostal.length !== 7) {
          newErrors.postalCode = { message: "Postal code is required" };
        }
      } else {
        newErrors.postalCode = { message: "Postal code is required" };
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const Input = ({ ...rest }) => (
    <components.Input {...rest} autoComplete={"nope"} />
  );
  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault(); // Prevent default tab behavior
    }
  };

  return (
    <>
      <Box padding={"32px 24px"}>
        <Grid container sx={{ padding: "16px 0px" }} gap={"8px"}>
          <Grid item lg={12} md={12} sm={12} xs={12} paddingBottom={"8px"}>
            <div role="presentation" onClick={handleClick}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  to="/suppliers"
                  style={{
                    color: "black",
                    opacity: "0.6",
                    textDecoration: "none",
                  }}
                >
                  Suppliers
                </Link>
                <Link
                  to=""
                  style={{
                    color: "black",
                    textDecoration: "none",
                    opacity: "0.6",
                  }}
                >
                  New Supplier
                </Link>
              </Breadcrumbs>
            </div>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} fontWeight={"bold"}>
            <Typography sx={{ fontSize: "34px", fontWeight: 500 }}>
              New Supplier
            </Typography>
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            // spacing={2}
            // sx={{ boxShadow: 2 }}
            justifyContent={"start"}
            // border={"1px solid gray"}
            // borderBottom={"2px solid gray"}
            boxShadow={3}
            borderTop={"none"}
            padding={"24px"}
            borderRadius={"10px"}
            marginTop={"32px"}
            bgcolor={"white"}
            rowGap={2}
          >
            <Grid item xs={12} sm={12} md={3.2} xl={3.2}>
              <Typography
                variant="h5"
                sx={{ fontWeight: 400, fontSize: "24px" }}
              >
                Basic Details
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={8.8}
              xl={8.8}
              columnSpacing={3}
              rowSpacing={2}
              // columnGap={1}
              //   columnGap={2}
              //   rowGap={2}
              // paddingLeft={"24px"}
              // paddingTop={"0px"}
              //   justifyContent={"space-between"}
            >
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Typography variant="body1" className="label-500">
                  {" "}
                  Supplier Name <span style={{ color: REQUIRED_RED }}>*</span>
                </Typography>
                <Controller
                  name="supplierName"
                  control={control}
                  rules={{
                    required: "Supplier name is required",
                  }}
                  render={({ field }) => (
                    <TextField
                      id="outlined-basic"
                      /*label="First Name"*/
                      variant="outlined"
                      fullWidth
                      required
                      error={!!errors.supplierName}
                      helperText={
                        errors.supplierName ? errors.supplierName.message : ""
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Typography variant="body1" className="label-500">
                  Account #
                </Typography>
                <Controller
                  name="account"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="outlined-basic"
                      /*label="Last Name"*/
                      variant="outlined"
                      fullWidth
                      // required
                      error={!!errors.account}
                      helperText={errors.account ? errors.account.message : ""}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Typography variant="body1" className="label-500">
                  {" "}
                  Contact First Name{" "}
                  <span style={{ color: REQUIRED_RED }}>*</span>
                </Typography>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{
                    required: "First name is required",
                  }}
                  render={({ field }) => (
                    <TextField
                      id="outlined-basic"
                      /*label="First Name"*/
                      variant="outlined"
                      fullWidth
                      required
                      error={!!errors.firstName}
                      helperText={
                        errors.firstName ? errors.firstName.message : ""
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Typography variant="body1" className="label-500">
                  {" "}
                  Contact Last Name{" "}
                  <span style={{ color: REQUIRED_RED }}>*</span>
                </Typography>
                <Controller
                  name="lastName"
                  control={control}
                  rules={{
                    required: "Last name is required",
                  }}
                  render={({ field }) => (
                    <TextField
                      id="outlined-basic"
                      /*label="Last Name"*/
                      variant="outlined"
                      fullWidth
                      // required
                      error={!!errors.lastName}
                      helperText={
                        errors.lastName ? errors.lastName.message : ""
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                xl={12}
                className={styles.cardDetails}
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="body1" className="label-500">
                    {" "}
                    Primary Phone Number{" "}
                    <span style={{ color: REQUIRED_RED }}>*</span>
                  </Typography>
                  <div style={{ display: "flex" }}>
                    <Grid container spacing={2} rowGap={2}>
                      <Grid item xs={12} sm={4} md={4} lg={2} xl={2}>
                        <FormControl
                          fullWidth
                          error={!!errors.primaryPhoneNumberType}
                        >
                          <Controller
                            name="primaryPhoneNumberType"
                            control={control}
                            rules={{
                              required: "Phone Number Type is required",
                            }}
                            render={({ field }) => (
                              <Select
                                placeholder="" // defaultValue={}
                                isDisabled={false}
                                isLoading={false}
                                isClearable={false}
                                isSearchable={false}
                                value={
                                  selectedPhoneTypes
                                    ? phoneTypes.find(
                                        (option) =>
                                          option.key ===
                                          selectedPhoneTypes?.primary
                                      )
                                    : ""
                                }
                                options={phoneTypes ? phoneTypes : []}
                                getOptionLabel={(option) => option.value} // Specifies which field to use as the label
                                getOptionValue={(option) => option}
                                onChange={(e) => {
                                  setSelectedPhoneTypes({
                                    ...selectedPhoneTypes,
                                    primary: e ? e.key : "",
                                  });
                                }}
                                styles={
                                  errors.primaryPhoneNumberType
                                    ? {
                                        ...customStyles,
                                        ...errorCustomStyles,
                                        ...phoneTypeCustomStyles,
                                      }
                                    : {
                                        ...customStyles,
                                        ...phoneTypeCustomStyles,
                                      }
                                }
                                error={!!errors.primaryPhoneNumberType}
                                helperText={
                                  errors.primaryPhoneNumberType
                                    ? errors.primaryPhoneNumberType.message
                                    : ""
                                }
                                {...field}
                                components={{ Input }}
                              />
                            )}
                          />
                          {errors.primaryPhoneNumberType && (
                            <FormHelperText>
                              {errors.primaryPhoneNumberType.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      {/* <Grid item xs={12} sm={12} md={12} lg={1} xl={1}>
                          <MuiSelect fullWidth>
                            {countries?.map((country, index) => (
                              <MenuItem value={index} key={index}>
                                {country.flag}
                                {country.dial_code}
                              </MenuItem>
                            ))}
                          </MuiSelect>
                        </Grid> */}

                      <Grid item xs={12} sm={8} md={8} lg={10} xl={10}>
                        <Controller
                          name="phoneNumber"
                          control={control}
                          rules={{
                            required: "Phone Number is required",
                          }}
                          render={({ field }) => (
                            <TextField
                              id="outlined-basic"
                              /*label="Phone Number"*/
                              //  onChange={(e)=>console.log("ee",e)}
                              variant="outlined"
                              fullWidth
                              required
                              error={!!errors.phoneNumber}
                              helperText={
                                errors.phoneNumber
                                  ? errors.phoneNumber.message
                                  : ""
                              }
                              {...field}
                              onChange={(e) => {
                                // setValue(
                                //   "phoneNumber",
                                //   formatPhoneNumber(e.target.value)
                                // );
                                if (e.target.value.length <= 12) {
                                  setValue(
                                    "phoneNumber",
                                    inputFormatPhoneNumber(e.target.value)
                                  );
                                  let nondashedPhoneNumber = e.target.value
                                    .toString()
                                    ?.replace(/-/g, "");
                                  // if (
                                  //   nondashedPhoneNumber.length == 10 &&
                                  //   validatePhoneNumber(
                                  //     inputFormatPhoneNumber(e.target.value)
                                  //   )
                                  // ) {
                                  //   checkPhoneNumberExist();
                                  // }
                                  if (
                                    !validatePhoneNumber(
                                      inputFormatPhoneNumber(e.target.value)
                                    )
                                  ) {
                                    setError("phoneNumber", {
                                      type: "manual",
                                      message:
                                        "Invalid phone number. Must be 10 digits.",
                                    });
                                  } else {
                                    clearErrors("phoneNumber");
                                  }
                                }
                              }}
                              onKeyDown={(e) => {
                                preventPhoneInputKeys(e);
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  {!showSecondaryNumber && (
                    <div
                      onClick={() => setShowSecondaryNumber(true)}
                      style={{
                        color: "#006CDD",
                        cursor: "pointer",
                        fontWeight: 500,
                        height: "36px",
                        padding: "4px 5px",
                        letterSpacing: "0.46px",
                        fontSize: "14px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      + ADD SECONDARY NUMBER
                    </div>
                  )}
                </Grid>
              </Grid>
              {showSecondaryNumber && (
                <Grid item container xs={12} sm={12} md={12} xl={12}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="body1" className="label-500">
                      {" "}
                      Secondary Phone Number{" "}
                      {/* <span style={{ color: REQUIRED_RED }}>*</span> */}
                    </Typography>

                    <Grid container item spacing={2} rowGap={2}>
                      <Grid item xs={12} sm={4} md={4} lg={2} xl={2}>
                        <FormControl
                          fullWidth
                          error={!!errors.secondaryPhoneNumberType}
                        >
                          <Controller
                            name="secondaryPhoneNumberType"
                            control={control}
                            // rules={{
                            //   required: "Phone Number Type is required",
                            // }}
                            render={({ field }) => (
                              <Select
                                placeholder="" // defaultValue={}
                                isDisabled={false}
                                isLoading={false}
                                isClearable={false}
                                isSearchable={false}
                                value={
                                  selectedPhoneTypes
                                    ? phoneTypes.find(
                                        (option) =>
                                          option.key ===
                                          selectedPhoneTypes?.primary
                                      )
                                    : ""
                                }
                                options={phoneTypes ? phoneTypes : []}
                                getOptionLabel={(option) => option.value} // Specifies which field to use as the label
                                getOptionValue={(option) => option}
                                onChange={(e) => {
                                  setSelectedPhoneTypes({
                                    ...selectedPhoneTypes,
                                    secondary: e ? e.key : "",
                                  });
                                }}
                                styles={
                                  errors.secondaryPhoneNumberType
                                    ? {
                                        ...customStyles,
                                        ...errorCustomStyles,
                                        ...phoneTypeCustomStyles,
                                      }
                                    : {
                                        ...customStyles,
                                        ...phoneTypeCustomStyles,
                                      }
                                }
                                error={!!errors.secondaryPhoneNumberType}
                                helperText={
                                  errors.secondaryPhoneNumberType
                                    ? errors.secondaryPhoneNumberType.message
                                    : ""
                                }
                                {...field}
                                components={{ Input }}
                              />
                            )}
                          />
                          {errors.secondaryPhoneNumberType && (
                            <FormHelperText>
                              {errors.secondaryPhoneNumberType.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={10} sm={7.2} md={7.5} lg={9.5} xl={9.5}>
                        <Controller
                          name="secondaryPhoneNumber"
                          control={control}
                          // rules={{
                          //   required: "Phone Number is required",
                          // }}
                          render={({ field }) => (
                            <TextField
                              id="outlined-basic"
                              /*label="Phone Number"*/
                              //  onChange={(e)=>console.log("ee",e)}
                              variant="outlined"
                              fullWidth
                              // required
                              error={!!errors.secondaryPhoneNumber}
                              helperText={
                                errors.secondaryPhoneNumber
                                  ? errors.secondaryPhoneNumber.message
                                  : ""
                              }
                              {...field}
                              onChange={(e) => {
                                // setValue(
                                //   "secondaryPhoneNumber",
                                //   formatsecondaryPhoneNumber(e.target.value)
                                // );
                                if (e.target.value.length <= 12) {
                                  setValue(
                                    "secondaryPhoneNumber",
                                    inputFormatPhoneNumber(e.target.value)
                                  );
                                  // let nondashedPhoneNumber = e.target.value
                                  //   .toString()
                                  //   ?.replace(/-/g, "");
                                  // if (nondashedPhoneNumber.length == 10) {
                                  //   checkPhoneNumberExist();
                                  // }
                                  if (
                                    !validatePhoneNumber(
                                      inputFormatPhoneNumber(e.target.value)
                                    )
                                  ) {
                                    setError("secondaryPhoneNumber", {
                                      type: "manual",
                                      message:
                                        "Invalid phone number. Must be 10 digits.",
                                    });
                                  } else {
                                    clearErrors("secondaryPhoneNumber");
                                  }
                                }
                              }}
                              onKeyDown={(e) => {
                                preventPhoneInputKeys(e);
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={2} sm={0.8} md={0.5} xl={0.5}>
                        <div
                          style={{
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <IconButton
                            color="error"
                            size="medium"
                            onClick={() => setShowSecondaryNumber(false)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              <Grid item container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="body1" className="label-500">
                    {" "}
                    Email
                  </Typography>
                  {/* <div style={{ display: "flex", flexDirection: "column" }}> */}
                  {/* <Grid container item spacing={3} rowGap={0}> */}
                  <div style={{ display: "flex", gap: "24px" }}>
                    <Grid xs={12}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Controller
                          name="email"
                          control={control}
                          // rules={{
                          //   required: "Email is required",
                          // }}
                          render={({ field }) => (
                            <TextField
                              id="outlined-basic"
                              /*label="Email"*/
                              variant="outlined"
                              fullWidth
                              // required
                              error={!!errors.email}
                              helperText={
                                errors.email ? errors.email.message : ""
                              }
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={2} sm={2} md={6} xl={6}>
                        <div
                          style={{
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={0 == primaryEmailIndex}
                                onChange={(e) => {
                                  console.log("www", e.target.checked);
                                  showSecondaryEmail &&
                                    setPrimaryEmailIndex(
                                      e.target.checked ? 0 : 1
                                    );
                                }}
                              />
                            }
                            label="Primary"
                          />
                        </div>
                        {/* <CheckBox  >Primary</CheckBox> */}
                        {/* <IconButton
                          color="error"
                          size="medium"
                          onClick={() => setShowSecondaryNumber(false)}
                        >
                          <DeleteIcon />
                        </IconButton> */}
                      </Grid>
                    </Grid>

                    {/* </Grid> */}
                    {/* </div> */}

                    {/* <Grid container item columnGap={2} rowGap={2} marginTop={2}> */}
                    <Grid xs={12}>
                      {showSecondaryEmail ? (
                        <>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Controller
                              name="secondaryEmail"
                              control={control}
                              // rules={{
                              //   required: "Email is required",
                              // }}
                              render={({ field }) => (
                                <TextField
                                  id="outlined-basic"
                                  /*label="Email"*/
                                  variant="outlined"
                                  fullWidth
                                  // required
                                  error={!!errors.secondaryEmail}
                                  helperText={
                                    errors.secondaryEmail
                                      ? errors.secondaryEmail.message
                                      : ""
                                  }
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={2} sm={2} md={4} lg={4} xl={4}>
                            <div
                              style={{
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={1 == primaryEmailIndex}
                                    onChange={(e) => {
                                      console.log("www", e.target.checked);
                                      setPrimaryEmailIndex(
                                        e.target.checked ? 1 : 0
                                      );
                                    }}
                                  />
                                }
                                label="Primary"
                              />
                              {/* <CheckBox  >Primary</CheckBox> */}
                              <IconButton
                                color="error"
                                size="medium"
                                onClick={() => {
                                  setShowSecondaryEmail(false);
                                  setPrimaryEmailIndex(0);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          </Grid>
                        </>
                      ) : (
                        <div
                          onClick={() => setShowSecondaryEmail(true)}
                          style={{
                            color: "#006CDD",
                            cursor: "pointer",
                            fontWeight: 500,
                            height: "36px",
                            padding: "4px 5px",
                            letterSpacing: "0.46px",
                            fontSize: "14px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          + ADD EMAIL
                        </div>
                      )}
                    </Grid>
                  </div>

                  {/* </Grid> */}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="body1" className="label-500">
                  Website
                </Typography>
                <Controller
                  name="website"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="outlined-basic"
                      /*label="Last Name"*/
                      variant="outlined"
                      fullWidth
                      // required
                      error={!!errors.website}
                      helperText={errors.website ? errors.website.message : ""}
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            // spacing={2}
            sx={{ boxShadow: 3 }}
            justifyContent={"start"}
            // border={"1px solid gray"}
            // borderBottom={"2px solid gray"}
            borderTop={"none"}
            padding={"24px"}
            borderRadius={"10px"}
            marginTop={"32px"}
            bgcolor={"white"}
            rowGap={2}
          >
            <Grid item xs={12} sm={12} md={3.2} xl={3.2}>
              <Typography
                variant="h5"
                sx={{ fontWeight: 400, fontSize: "24px" }}
              >
                Additional Details
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={8.8}
              xl={8.8}
              rowGap={2}
              // paddingLeft={"24px"}
              justifyContent={"space-between"}
            >
              {/* <Grid container className={styles.cardDetails}>
                <Grid item xs={12} sm={12} md={5.8} lg={5.8} xl={5.8}>
                  <Typography variant="body1" className="label-500">
                
                    Company
                  </Typography>
                  <Controller
                    name="company"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        id="outlined-basic"
                    
                        variant="outlined"
                        fullWidth
                        // required
                        error={!!errors.company}
                        helperText={
                          errors.company ? errors.company.message : ""
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={5.8} lg={5.8} xl={5.8}>
                <FormControl fullWidth error={!!errors.contactPrefrence}>
                  <Typography variant="body1" className="label-500">
                    Contact Preferences
                  </Typography>
                  <Controller
                    name="contactPrefrence"
                    control={control}
                    // rules={{
                    //   required: "Phone Number Type is required",
                    // }}
                    render={({ field }) => (
                      <Select
                        placeholder="" // defaultValue={}
                        isDisabled={false}
                        isLoading={false}
                        isClearable={true}
                        isSearchable={false}
                        // value={
                        //   selectedPhoneTypes
                        //     ? contactPrefrences.find(
                        //         (option) =>
                        //           option.key === selectedPhoneTypes?.primary
                        //       )
                        //     : ""
                        // }
                        options={contactPrefrences ? contactPrefrences : []}
                        getOptionLabel={(option) => option.value} // Specifies which field to use as the label
                        getOptionValue={(option) => option}
                        onChange={(e) => {
                          field.onChange(e);
                          // setSelectedPhoneTypes({
                          //   ...selectedPhoneTypes,
                          //   primary: e ? e.key : "",
                          // });
                        }}
                        styles={
                          errors.contactPrefrence
                            ? {
                                ...customStyles,
                                ...errorCustomStyles,
                              }
                            : {
                                ...customStyles,
                              }
                        }
                        error={!!errors.contactPrefrence}
                        helperText={
                          errors.contactPrefrence
                            ? errors.contactPrefrence.message
                            : ""
                        }
                        {...field}
                      />
                    )}
                  />
                  {errors.contactPrefrence && (
                    <FormHelperText>
                      {errors.contactPrefrence.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid> */}
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <Typography variant="body1" className="label-500">
                  {" "}
                  Street Address
                  {(addressValues.streetAddress ||
                    addressValues.province ||
                    addressValues.city ||
                    addressValues.postal) && (
                    <span style={{ color: "#D32F2F" }}> *</span>
                  )}
                </Typography>
                <Controller
                  name="streetAddress"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      fullWidth
                      error={!!error.streetAddress}
                      helperText={
                        error.streetAddress ? error.streetAddress : ""
                      }
                      {...field}
                      onChange={(e) => {
                        setValue("streetAddress", e.target.value);
                        setAddressValue({
                          ...addressValues,
                          streetAddress: e.target.value,
                        });
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid container item xs={12} sm={12} md={12} xl={12} spacing={2}>
                <Grid item xs={12} sm={4} md={4} xl={4}>
                  <Typography variant="body1" className="label-500">
                    {" "}
                    City
                    {(addressValues.streetAddress ||
                      addressValues.province ||
                      addressValues.city ||
                      addressValues.postal) && (
                      <span style={{ color: "#D32F2F" }}> *</span>
                    )}
                  </Typography>
                  <Controller
                    name="city"
                    control={control}
                    render={({ field }) => (
                      <AddressSelect
                        list={cityList}
                        addressValues={addressValues}
                        setAddressValue={setAddressValue}
                        type={"city"}
                        loader={cityListLoader}
                        maxMenuHeight={300}
                      />
                    )}
                  />
                  {error && error.city && (
                    <p
                      style={{
                        color: "#d32f2f",
                        fontSize: "0.75rem",
                        letterSpacing: "0.03333em",
                        lineHeight: "1.66",
                        marginTop: "3px",
                      }}
                    >
                      {error.city}
                    </p>
                  )}
                </Grid>
                <Grid item xs={12} sm={4} md={4} xl={4}>
                  <Typography variant="body1" className="label-500">
                    {" "}
                    Province
                    {(addressValues.streetAddress ||
                      addressValues.province ||
                      addressValues.city ||
                      addressValues.postal) && (
                      <span style={{ color: "#D32F2F" }}> *</span>
                    )}
                  </Typography>

                  <AddressSelect
                    list={provinceList}
                    addressValues={addressValues}
                    setAddressValue={setAddressValue}
                    type={"province"}
                    setCityList={setCityList}
                    fetchCityList={fetchCityList}
                    loader={provinceListLoader}
                    maxMenuHeight={300}
                  />
                  {error && error.province && (
                    <p
                      style={{
                        color: "#d32f2f",
                        fontSize: "0.75rem",
                        letterSpacing: "0.03333em",
                        lineHeight: "1.66",
                        marginTop: "3px",
                      }}
                    >
                      {error.province}
                    </p>
                  )}
                </Grid>
                <Grid item xs={12} sm={4} md={4} xl={4}>
                  <PostalCode
                    name="postalCode"
                    control={control}
                    errors={error}
                    setValueinForm={setValue}
                    setAddressValue={setAddressValue}
                    addressValues={addressValues}
                    // setError={setError}
                    // clearErrors={clearErrors}
                    spacing={0.5}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <Grid
          // spacing={2}
          container
          sx={{ boxShadow: 3 }}
          justifyContent={"start"}
          // border={"1px solid gray"}
          // borderBottom={"2px solid gray"}
          borderTop={"none"}
          padding={"24px"}
          borderRadius={"10px"}
          marginTop={"32px"}
          bgcolor={"white"}
          rowGap={2}
        >
          <Grid item xs={12} sm={12} md={3.2} xl={3.2}>
            <Typography variant="h5" sx={{ fontWeight: 400, fontSize: "24px" }}>
              Payment Terms
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={8.8}
            xl={8.8}
            // spacing={2}
            rowGap={2}
            // paddingLeft={"24px"}
          >
            <Grid container item xs={12} sm={12} md={5} xl={5}>
              <FormControl fullWidth error={!!errors.paymentTerm}>
                <Controller
                  name="paymentTerm"
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder="" // defaultValue={}
                      isDisabled={false}
                      isLoading={false}
                      isClearable={false}
                      isSearchable={false}
                      options={paymentTermsTypes ? paymentTermsTypes : []}
                      getOptionLabel={(option) => option.value} // Specifies which field to use as the label
                      getOptionValue={(option) => option}
                      styles={
                        errors.paymentTerm
                          ? {
                              ...customStyles,
                              ...errorCustomStyles,
                              ...phoneTypeCustomStyles,
                            }
                          : {
                              ...customStyles,
                              ...phoneTypeCustomStyles,
                            }
                      }
                      error={!!errors.paymentTerm}
                      helperText={
                        errors.paymentTerm ? errors.paymentTerm.message : ""
                      }
                      {...field}
                      components={{ Input }}
                    />
                  )}
                />
                {errors.paymentTerm && (
                  <FormHelperText>{errors.paymentTerm.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          // spacing={2}
          container
          sx={{ boxShadow: 3 }}
          justifyContent={"start"}
          // border={"1px solid gray"}
          // borderBottom={"2px solid gray"}
          borderTop={"none"}
          padding={"24px"}
          borderRadius={"10px"}
          marginTop={"32px"}
          bgcolor={"white"}
          rowGap={2}
        >
          <Grid item xs={12} sm={12} md={3.2} xl={3.2}>
            <Typography variant="h5" sx={{ fontWeight: 400, fontSize: "24px" }}>
              Notes
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={8.8}
            xl={8.8}
            // spacing={2}
            rowGap={2}
            // paddingLeft={"24px"}
          >
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 500, fontSize: "20px" }}
              >
                Recent Notes
              </Typography>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} xl={12}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                onChange={(e) => setNewNote(e.target.value)}
                value={newNote}
              />
            </Grid>
            {noteError && (
              <Grid container item xs={12} sm={12} md={12} xl={12}>
                <span style={{ color: "red" }}>Please enter note</span>
              </Grid>
            )}
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              xl={12}
              justifyContent={"end"}
              marginTop={"0px"}
            >
              <Grid item padding={"0 0 26px 0"}>
                <Button
                  variant="contained"
                  size="large"
                  style={{ background: "#002394" }}
                  onClick={handleAddNote}
                >
                  Add Note
                </Button>
              </Grid>
            </Grid>

            {notes?.map((data, index) => {
              return (
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  style={{ backgroundColor: "#e6f7f7", borderRadius: "8px" }}
                  padding={"16px"}
                  display={"block"}
                >
                  {/* <Card padding={"16px"} style={{backgroundColor:"#e6f7f7"}}> */}
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    mb={2}
                  >
                    <div>
                      <Typography variant="body1">
                        {dayjs(data?.createdAt).format("MMMM DD, YYYY") +
                          " at " +
                          dayjs(data?.createdAt).format("hh:mm A")}
                      </Typography>
                    </div>

                    <div style={{ marginRight: "16px" }}>
                      <Button
                        variant="contained"
                        onClick={(e) => handleClickNoteActions(e, data)}
                        id="demo-positioned-button"
                        aria-controls={
                          open1 ? "demo-positioned-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={open1 ? "true" : undefined}
                        // onClick={handleClickNoteActions}

                        endIcon={<ExpandMoreIcon />}
                      >
                        Actions
                      </Button>
                      <Menu
                        style={{ width: "300px" }}
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={open1}
                        onClose={handleCloseNoteActions}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            handleCloseNoteActions();
                            setEditDeleteNoteId(selectedActionOfNote);
                            setEditNoteText(selectedActionOfNote?.note);
                          }}
                        >
                          {" "}
                          <EditIcon style={{ marginRight: "10px" }} /> Edit Note
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleCloseNoteActions();
                            deleteNote(selectedActionOfNote);
                          }}
                        >
                          {" "}
                          <DeleteIcon style={{ marginRight: "10px" }} /> Delete
                          Note
                        </MenuItem>
                      </Menu>
                    </div>
                  </Stack>

                  {editDeleteNoteId &&
                  notes.indexOf(editDeleteNoteId) == index ? (
                    <>
                      <TextField
                        // value={data?.notes}
                        style={{
                          backgroundColor: "#fff",
                          // padding: "10px",
                          border: "1px solid #C4C4C4",
                          width: "100%",
                        }}
                        value={editNoteText}
                        onChange={(e) => setEditNoteText(e.target.value)}
                      />
                      <Stack
                        direction={"row"}
                        mb={2}
                        mt={2}
                        spacing={2}
                        sx={{ marginBottom: "0px" }}
                      >
                        <div>
                          <Button
                            variant="contained"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditNote();
                            }}
                            disabled={editNoteText?.trim() == ""}
                          >
                            SAVE
                          </Button>
                        </div>
                        <div>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              setEditDeleteNoteId("");
                              setSelectedActionOfNote("");
                            }}
                          >
                            CANCEL
                          </Button>
                        </div>
                      </Stack>
                    </>
                  ) : (
                    <Card
                      style={{
                        backgroundColor: "#fff",
                        padding: "10px",
                        border: "1px solid #C4C4C4",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 400, fontSize: "16px" }}
                      >
                        {data?.note}
                      </Typography>
                    </Card>
                  )}

                  {/* </Card> */}
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            justifyContent={"end"}
            marginTop={"32px"}
            // paddingY={"7.5px"}
            // paddingRight={"24px"}
            boxShadow={3}
            borderRadius={"10px"}
            bgcolor={"white"}
            padding={"24px"}

            // borderBottom={"2px solid gray"}
          >
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                type="submit"
                // onClick={handleSubmit(onSubmit)}
                onClick={(e) => {
                  if (validate()) {
                    control.handleSubmit(onSubmit);
                  } else {
                    e.preventDefault();
                  }
                }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
    </>
  );
};

export default NewSupplier;
