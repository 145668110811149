import React, { useState } from "react";
import Cards from "./Cards";
import { Grid } from "@mui/material";

function SupplierInvoice(props) {
  const [supplierInvoice, setSupplierInvoice] = useState([]);
  return (
    <Grid container spacing={3}>
      {/* {
        invoices?.map(invoice=>{

        })
    } */}
      <Grid item container md={6}>
        <Cards
          activeTab="supplierInvoice"
          date="January 23,2023"
          // customerName="John Michale"
          // vehicle="2019 Volkswagen Jetta 1.4T SEL"
          chipNumber="EST824-01"
          amount="$80.9"
          creditDays="30 Days"
        />
      </Grid>
      <Grid item container md={6}>
        <Cards
          activeTab="supplierInvoice"
          date="January 26,2023"
          // customerName="John Michale"
          // vehicle="2019 Volkswagen Jetta 1.4T SEL"
          chipNumber="EST824-01"
          amount="$81.9"
          creditDays="40 Days"
        />
      </Grid>
      <Grid item container md={6}>
        <Cards
          activeTab="supplierInvoice"
          date="January 24,2023"
          // customerName="John Michale"
          // vehicle="2019 Volkswagen Jetta 1.4T SEL"
          chipNumber="EST824-01"
          amount="$82.9"
          creditDays="180 Days"
        />
      </Grid>{" "}
      <Grid item container md={6}>
        <Cards
          activeTab="supplierInvoice"
          date="January 23,2023"
          // customerName="John Michale"
          // vehicle="2019 Volkswagen Jetta 1.4T SEL"
          chipNumber="EST824-01"
          amount="$40.9"
          creditDays="90 Days"
        />
      </Grid>
    </Grid>
  );
}

export default SupplierInvoice;
