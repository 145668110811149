import AddIcon from "@mui/icons-material/Add";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ChecklistIcon from "@mui/icons-material/Checklist";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import ReceiptIcon from "@mui/icons-material/Receipt";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import SettingsIcon from "@mui/icons-material/Settings";
import SmsIcon from "@mui/icons-material/Sms";
import MuiAppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";
import "./Sidebar.css";
// import ContentPasteIcon from '@mui/icons-material/ContentPaste';
// import PeopleIcon from '@mui/icons-material/People';
// import ReceiptIcon from '@mui/icons-material/Receipt';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Menu,
  MenuItem,
  Stack,
  Switch,
  Tooltip,
} from "@mui/material";
import ApexIcon from "../../assets/Logo_SaaS.svg";
import SideBarIcon from "../../assets/sidebar-button-icon.svg";
// import IconButton from "@mui/material/IconButton";
import CallIcon from "@mui/icons-material/Call";

import HelpIcon from "@mui/icons-material/Help";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Context } from "../../App";
import {
  editPauseAppointmentStatus,
  getPauseAppointmentStatus,
} from "../../redux/PauseAppointment/action";
import SuccessMessageModal from "../Common/SuccessMessage";
import NotificationPopUp from "./NotificationPopUp";
import ShopPopup from "./ShopPopup";

const drawerWidth = 288;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  overflowY: "overlay",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  overflowY: "overlay",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open
    ? {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }
    : {
        marginLeft: "50px",
        width: `calc(100% - 62.5px)`,

        [theme.breakpoints.down("md")]: {
          width: `calc(100% - 62px)`,
        },
        [theme.breakpoints.down("sm")]: {
          width: `calc(100% - 57px)`,
        },
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
const styles = {
  menuBottom: {
    marginTop: "auto",
  },
};
export default function Dashboard({ open, setOpen }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElApointment, setAnchorElAppointment] = React.useState(null);
  const [anchorElEstimate, setAnchorElEstimate] = React.useState(null);
  const [anchorElApproval, setAnchorElApproval] = React.useState(null);
  const [anchorElInvoice, setAnchorElInvoice] = React.useState(null);
  const [anchorElCustomer, setAnchorElCustomer] = React.useState(null);
  const [anchorElSupplier, setAnchorElSupplier] = React.useState(null);

  const { setIsOpenSidebar } = React.useContext(Context);
  const open1 = Boolean(anchorEl);
  const openAppointmentPopup = Boolean(anchorElApointment);
  const openEstimatePopup = Boolean(anchorElEstimate);
  const openApprovalPopup = Boolean(anchorElApproval);
  const openInvoicePopup = Boolean(anchorElInvoice);
  const openCustomerPopup = Boolean(anchorElCustomer);
  const openSupplierPopup = Boolean(anchorElSupplier);

  const dispatch = useDispatch();
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [statusValue, setStatusValue] = React.useState(false);
  const [disabledSwitch, setDisabledSwitch] = React.useState(false);
  const location = useLocation();
  const handleOpenNewButtonMenu = (event) => {
    if (event.currentTarget != anchorEl) setAnchorEl(event.currentTarget);
  };
  const handleCloseNewButtonMenu = () => {
    if (anchorEl != null) setAnchorEl(null);
  };

  const handleOpenAppointmentButtonMenu = (event) => {
    setAnchorElAppointment(event.currentTarget);
  };
  const handleCloseAppointmentButtonMenu = () => {
    setAnchorElAppointment(null);
  };

  const handleOpenEstimateButtonMenu = (event) => {
    setAnchorElEstimate(event.currentTarget);
  };
  const handleCloseEstimateButtonMenu = () => {
    setAnchorElEstimate(null);
  };
  const handleOpenApprovalButtonMenu = (event) => {
    setAnchorElApproval(event.currentTarget);
  };
  const handleCloseApprovalButtonMenu = () => {
    setAnchorElApproval(null);
  };

  const handleOpenInvoiceButtonMenu = (event) => {
    setAnchorElInvoice(event.currentTarget);
  };
  const handleCloseInvoiceButtonMenu = () => {
    setAnchorElInvoice(null);
  };
  const handleOpenSupplierButtonMenu = (event) => {
    setAnchorElSupplier(event.currentTarget);
  };
  const handleCloseSupplierButtonMenu = () => {
    setAnchorElSupplier(null);
  };
  const handleOpenCustomerButtonMenu = (event) => {
    setAnchorElCustomer(event.currentTarget);
  };
  const handleCloseCustomerButtonMenu = () => {
    setAnchorElCustomer(null);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
    setIsOpenSidebar(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setIsOpenSidebar(false);
  };
  const [authData, setAuthData] = React.useState(
    JSON.parse(localStorage.getItem("apex-saas_details"))
  );

  useEffect(() => {
    fetchAppointmentStatus();
  }, []);
  const fetchAppointmentStatus = async () => {
    const resp = await dispatch(getPauseAppointmentStatus());
    if (resp) {
      setStatusValue(resp?.overallPauseAppointment == "" ? false : true);
    }
  };

  const data = useSelector((state) => state.pauseAppointment.pauseAppoinment);

  const updateAppointmentStatus = async (value) => {
    let payload = {
      overallPauseAppointment: value,
    };
    setDisabledSwitch(true);
    const resp = await dispatch(editPauseAppointmentStatus(payload));
    if (resp) {
      setStatusValue(resp?.data?.overallPauseAppointment == "" ? false : true);
      setOpenSuccessModal(true);
      setSuccessMessage(resp?.message);
    }
    setDisabledSwitch(false);
  };

  const PauseAppointmentToogle = styled((props) => (
    <Switch
      disabled={disabledSwitch}
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
      defaultChecked={statusValue}
      onChange={(e) => updateAppointmentStatus(e.target.checked)}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#d32f2f",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor:
        theme.palette.mode === "light"
          ? "rgba(120, 120, 128, 0.16)"
          : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  useEffect(() => {
    const handleStorageChange = (event) => {
      setAuthData(JSON.parse(localStorage.getItem("apex-saas_details")));
    };

    window.addEventListener("localStorageChangePlan", handleStorageChange);

    return () => {
      window.removeEventListener("localStorageChangePlan", handleStorageChange);
    };
  }, []);
  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        style={{ background: "white", zIndex: 9 }}
      >
        <Toolbar>
          <div
            style={{
              width: open ? "0%" : "50%",
              justifyItems: "left",
              display: "flex",
            }}
          >
            <Typography variant="h6" noWrap component="div"></Typography>
          </div>
          <Stack
            spacing={2}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
            direction="row"
          >
            <div className="pauseAppointmentDiv">
              <PauseAppointmentToogle size="large" />
              <Tooltip
                title={
                  <span className="pauseAppointmentToolTip">
                    Emergency Pause:
                    <br />
                    Activating this toggle will temporarily halt all new and
                    incoming appointments requests. Customers will not be able
                    to request appointment for any days. <br /> This toggle will
                    automatically turn off after 24 hours.
                  </span>
                }
              >
                <HelpIcon className="tooltipIcon" />
              </Tooltip>
            </div>
            <div>
              <NotificationPopUp />
            </div>
            <div>
              <ShopPopup />
            </div>
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Stack
          spacing={2}
          direction={"row"}
          paddingInline={2}
          justifyContent="space-between"
          style={{ marginTop: "40px" }}
        >
          <div
            style={{
              display: open ? "" : "none",
              width: "168px",
              height: "46.92px",
            }}
          >
            <img
              src={ApexIcon}
              style={{ objectFit: "cover", width: "inherit" }}
            />
          </div>
          <div style={{ display: open ? "" : "none" }}>
            <IconButton
              color="default"
              size="small"
              onClick={handleDrawerClose}
            >
              <img src={SideBarIcon} />
            </IconButton>
          </div>
        </Stack>
        {!open && (
          <div className="small-sidebar-button">
            <IconButton color="default" size="small" onClick={handleDrawerOpen}>
              <img src={SideBarIcon} width={"43px"} height={"43px"} />
            </IconButton>
          </div>
        )}
        {authData?.allowedPlatforms &&
          authData?.allowedPlatforms.find((d) => d == "web") && (
            <div>
              <div style={{ justifyContent: "center", display: "flex" }}>
                {open ? (
                  <>
                    <Button
                      variant="contained"
                      id="demo-positioned-button"
                      color="secondary"
                      aria-controls={open ? "demo-positioned-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleOpenNewButtonMenu}
                      style={{
                        width: "100%",
                        borderRadius: "22px",
                        border: "1px solid white",
                        margin: "40px 16px",
                      }}
                    >
                      <AddIcon fontSize={"medium"} sx={{ mr: "4px" }} /> NEW
                    </Button>
                  </>
                ) : (
                  <AddIcon
                    style={{
                      borderRadius: "15px",
                      width: "40px",
                      // height: "40px",
                      marginTop: "20px",
                      marginBottom: "20px",
                      cursor: "pointer",
                      padding: "7px",
                      border: "1px solid white",
                    }}
                    fontSize={"large"}
                    aria-controls={open ? "demo-positioned-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleOpenNewButtonMenu}
                    sx={{ bgcolor: "secondary", color: "white" }}
                  />
                )}
                <Menu
                  sx={{ width: "80%" }}
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl}
                  open={open1}
                  onClose={handleCloseNewButtonMenu}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  className={
                    open
                      ? "openSidebar-button-menu"
                      : "closeSidebar-button-menu"
                  }
                >
                  <MenuItem
                    onClick={() => {
                      handleCloseNewButtonMenu();
                      navigate("/appointment/new-appointment");
                    }}
                  >
                    <ContentPasteIcon style={{ marginRight: "10px" }} />{" "}
                    Appointment
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleCloseNewButtonMenu();
                      navigate("/customers/new-customer");
                    }}
                  >
                    <PeopleIcon style={{ marginRight: "10px" }} /> Customer
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleCloseNewButtonMenu();
                      navigate("/estimates/new");
                    }}
                  >
                    {" "}
                    <RequestQuoteIcon style={{ marginRight: "10px" }} />{" "}
                    Estimate
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleCloseNewButtonMenu();
                      navigate("/invoice/new");
                    }}
                  >
                    {" "}
                    <ReceiptIcon style={{ marginRight: "10px" }} /> Invoice{" "}
                  </MenuItem>
                </Menu>
              </div>
              <Divider />

              <List
                sx={{
                  textAlign: "center",
                  marginLeft: open ? "16px" : "2px",
                  marginRight: open ? "21px" : "12px",
                }}
                onClick={() => localStorage.removeItem("searchValue")}
              >
                <Tooltip title={!open && "Dashboard"} placement="right">
                  <ListItem
                    key={"Dashboard"}
                    disablePadding
                    sx={{
                      display: "block",
                      marginLeft: !open && "4px",
                      marginBottom: !open && "6px",
                    }}
                    onClick={() => navigate("/")}
                    className={
                      (location.pathname == "/" && "sidebarActive") +
                      (!open ? " sidebarListItem" : " ")
                    }
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        marginLeft:
                          location.pathname.split("/")[1] == "dashboard" &&
                          "-3px",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                          color: "white",
                        }}
                      >
                        <HomeIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={"Dashboard"}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                </Tooltip>

                {open ? (
                  <Accordion
                    style={{
                      color: "white",
                      background: "#002394",
                      "&:before": {
                        display: "none",
                      },
                    }}
                    disableGutters
                    variant="elevation"
                    elevation={0}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      style={{ paddingLeft: "inherit", marginBottom: "0px" }}
                    >
                      <ListItem
                        key={"Appointment"}
                        disablePadding
                        sx={{ display: "block" }}
                      >
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: "white",
                            }}
                          >
                            <ContentPasteIcon />
                          </ListItemIcon>

                          <ListItemText
                            primary={"Appointments"}
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: "8px 0px 15px 12px" }}>
                      {/* <List> */}

                      <ListItem
                        key={"Today"}
                        disablePadding
                        style={{ marginTop: "-10px" }}
                        onClick={() => navigate("/appointment/today")}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 5 : "auto",
                            mt: 0,
                            color: "white",
                          }}
                        ></ListItemIcon>
                        <ListItemButton
                          className={
                            (location.pathname.split("/")[2] == "today" &&
                              "sidebarActiveInside") +
                            (!open ? " sidebarListItem" : " ")
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 5 : "auto",
                              mt: 0,
                              color: "white",
                            }}
                          >
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              <span style={{ fontSize: "14px" }}>Today</span>
                            </ListItemText>
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                      <ListItem
                        key={"Upcoming Appointments"}
                        onClick={() =>
                          navigate("appointment/upcoming-appointments")
                        }
                        disablePadding
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 5 : "auto",
                            mt: 0,
                            color: "white",
                          }}
                        ></ListItemIcon>
                        <ListItemButton
                          className={
                            (location.pathname.split("/")[2] ==
                              "upcoming-appointments" &&
                              "sidebarActiveInside") +
                            (!open ? " sidebarListItem" : " ")
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 5 : "auto",
                              mt: 0,
                              color: "white",
                            }}
                          >
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              {" "}
                              <span style={{ fontSize: "14px" }}>
                                Upcoming Appointments
                              </span>
                            </ListItemText>
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                      <ListItem
                        key={"Past Appointments"}
                        onClick={() =>
                          navigate("/appointment/past-appointments")
                        }
                        disablePadding
                        style={{ marginBottom: "-18px" }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 5 : "auto",
                            mt: 0,
                            color: "white",
                          }}
                        ></ListItemIcon>
                        <ListItemButton
                          className={
                            (location.pathname.split("/")[2] ==
                              "past-appointments" && "sidebarActiveInside") +
                            (!open ? " sidebarListItem" : " ")
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 5 : "auto",
                              mt: 0,
                              color: "white",
                            }}
                          >
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              <span style={{ fontSize: "14px" }}>
                                Past Appointments
                              </span>
                            </ListItemText>
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  <>
                    <Tooltip title={!open && "Appointments"} placement="right">
                      <ListItem
                        key={"Appointment"}
                        disablePadding
                        sx={{
                          display: "block",
                          marginLeft: !open && "4px",
                          marginBottom: !open && "6px",
                        }}
                        className={
                          (location.pathname.split("/")[1] == "appointment" &&
                            "sidebarActive") +
                          (!open ? " sidebarListItem" : " ")
                        }
                        onClick={() => navigate("/appointment/today")}
                      >
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                            marginLeft:
                              location.pathname.split("/")[1] ==
                                "appointment" && "-3px",
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: "white",
                            }}
                            onClick={handleOpenAppointmentButtonMenu}
                          >
                            <ContentPasteIcon />
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                    </Tooltip>
                    <Menu
                      style={{ width: "80%" }}
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      anchorEl={anchorElApointment}
                      open={openAppointmentPopup}
                      onClose={handleCloseAppointmentButtonMenu}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      className="openMenuItems"
                    >
                      <MenuItem
                        onClick={() => {
                          handleCloseAppointmentButtonMenu();
                          navigate("/appointment/today");
                        }}
                      >
                        Today
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleCloseAppointmentButtonMenu();
                          navigate("/appointment/upcoming-appointments");
                        }}
                      >
                        Upcoming
                      </MenuItem>

                      <MenuItem
                        onClick={() => {
                          handleCloseAppointmentButtonMenu();
                          navigate("/appointment/past-appointments");
                        }}
                      >
                        {" "}
                        Past{" "}
                      </MenuItem>
                    </Menu>
                  </>
                )}

                <Tooltip title={!open && "Calendar"} placement="right">
                  <ListItem
                    key={"Calendar"}
                    disablePadding
                    sx={{
                      display: "block",
                      marginLeft: !open && "4px",
                      marginBottom: !open && "6px",
                    }}
                    onClick={() => navigate("/calendar")}
                    className={
                      (location.pathname.split("/")[1] == "calendar" &&
                        "sidebarActive") + (!open ? " sidebarListItem" : " ")
                    }
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        marginLeft:
                          location.pathname.split("/")[1] == "calendar" &&
                          "-3px",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                          color: "white",
                        }}
                      >
                        <CalendarTodayIcon />
                      </ListItemIcon>

                      <ListItemText
                        primary={"Calendar"}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                </Tooltip>
                {open ? (
                  <Accordion
                    style={{
                      color: "white",
                      background: "#002394",
                      "&:before": {
                        display: "none",
                      },
                    }}
                    disableGutters
                    elevation={0}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      style={{ paddingLeft: "inherit", marginBottom: "0px" }}
                    >
                      <ListItem
                        key={"Approvals"}
                        disablePadding
                        sx={{ display: "block" }}
                        // onClick={() => navigate("/approvals")}
                      >
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: "white",
                            }}
                          >
                            <ChecklistIcon />
                            {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                          </ListItemIcon>

                          <ListItemText
                            primary={"Approvals"}
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* <List> */}

                      <ListItem
                        key={"Waiting"}
                        disablePadding
                        style={{ marginTop: "-10px" }}
                        onClick={() => navigate("/approvals/waiting")}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 5 : "auto",
                            mt: 0,
                            // justifyContent: "center",
                            color: "white",
                          }}
                        ></ListItemIcon>
                        <ListItemButton
                          className={
                            (location.pathname.split("/")[2] == "waiting" &&
                              "sidebarActiveInside") +
                            (!open ? " sidebarListItem" : " ")
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 5 : "auto",
                              mt: 0,
                              color: "white",
                            }}
                          >
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              <span style={{ fontSize: "14px" }}>Waiting</span>
                            </ListItemText>
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                      <ListItem
                        key={"Responded"}
                        disablePadding
                        onClick={() => navigate("/approvals/responded")}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 5 : "auto",
                            mt: 0,
                            color: "white",
                          }}
                        ></ListItemIcon>
                        <ListItemButton
                          className={
                            (location.pathname.split("/")[2] == "responded" &&
                              "sidebarActiveInside") +
                            (!open ? " sidebarListItem" : " ")
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 5 : "auto",
                              mt: 0,
                              color: "white",
                            }}
                          >
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              {" "}
                              <span style={{ fontSize: "14px" }}>
                                Responded
                              </span>
                            </ListItemText>
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                      <ListItem
                        key={"Deferred"}
                        onClick={() => navigate("/approvals/deferred")}
                        disablePadding
                        style={{ marginBottom: "-18px" }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 5 : "auto",
                            mt: 0,
                            color: "white",
                          }}
                        ></ListItemIcon>
                        <ListItemButton
                          className={
                            (location.pathname.split("/")[2] == "deferred" &&
                              "sidebarActiveInside") +
                            (!open ? " sidebarListItem" : " ")
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 5 : "auto",
                              mt: 0,
                              color: "white",
                            }}
                          >
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              <span style={{ fontSize: "14px" }}>Deferred</span>
                            </ListItemText>
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  <>
                    <Tooltip title={!open && "Approvals"} placement="right">
                      <ListItem
                        key={"Approvals"}
                        disablePadding
                        sx={{
                          display: "block",
                          marginLeft: !open && "4px",
                          marginBottom: !open && "6px",
                        }}
                        className={
                          (location.pathname.split("/")[1] == "approvals" &&
                            "sidebarActive") +
                          (!open ? " sidebarListItem" : " ")
                        }
                        onClick={() => navigate("/approvals/waiting")}
                      >
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                            marginLeft:
                              location.pathname.split("/")[1] ==
                                "appointment" && "-3px",
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: "white",
                            }}
                            onClick={handleOpenApprovalButtonMenu}
                          >
                            <ChecklistIcon />
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                    </Tooltip>
                    <Menu
                      style={{ width: "80%" }}
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      anchorEl={anchorElApproval}
                      open={openApprovalPopup}
                      onClose={handleCloseApprovalButtonMenu}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      className="openMenuItems"
                    >
                      <MenuItem
                        onClick={() => {
                          handleCloseApprovalButtonMenu();
                          navigate("/approvals/waiting");
                        }}
                      >
                        Waiting
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleCloseApprovalButtonMenu();
                          navigate("/approvals/responded");
                        }}
                      >
                        Responded
                      </MenuItem>

                      <MenuItem
                        onClick={() => {
                          handleCloseApprovalButtonMenu();
                          navigate("/approvals/deferred");
                        }}
                      >
                        {" "}
                        Deferred
                      </MenuItem>
                    </Menu>
                  </>
                )}
                {open ? (
                  <Accordion
                    style={{
                      color: "white",
                      background: "#002394",
                      "&:before": {
                        display: "none",
                      },
                    }}
                    disableGutters
                    elevation={0}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      style={{ paddingLeft: "inherit", marginBottom: "0px" }}
                    >
                      <ListItem
                        key={"Estimates"}
                        disablePadding
                        sx={{ display: "block" }}
                      >
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: "white",
                            }}
                          >
                            <RequestQuoteIcon />
                          </ListItemIcon>

                          <ListItemText
                            primary={"Estimates"}
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ListItem
                        key={"All"}
                        onClick={() => navigate("/estimates/all")}
                        disablePadding
                        style={{ marginTop: "-10px" }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 5 : "auto",
                            mt: 0,
                            color: "white",
                          }}
                        ></ListItemIcon>
                        <ListItemButton
                          className={
                            (location.pathname.split("/")[1] == "estimates" &&
                              location.pathname.split("/")[2] == "all" &&
                              "sidebarActiveInside") +
                            (!open ? " sidebarListItem" : " ")
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 5 : "auto",
                              mt: 0,
                              color: "white",
                            }}
                          >
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              <span style={{ fontSize: "14px" }}>All</span>
                            </ListItemText>
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                      <ListItem
                        key={"Sent"}
                        disablePadding
                        onClick={() => navigate("/estimates/sents")}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 5 : "auto",
                            mt: 0,
                            color: "white",
                          }}
                        ></ListItemIcon>
                        <ListItemButton
                          className={
                            (location.pathname.split("/")[1] == "estimates" &&
                              location.pathname.split("/")[2] == "sents" &&
                              "sidebarActiveInside") +
                            (!open ? " sidebarListItem" : " ")
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 5 : "auto",
                              mt: 0,
                              color: "white",
                            }}
                          >
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              {" "}
                              <span style={{ fontSize: "14px" }}>Sent</span>
                            </ListItemText>
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                      <ListItem
                        key={"Drafts"}
                        disablePadding
                        style={{ marginBottom: "-18px" }}
                        onClick={() => navigate("/estimates/drafts")}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 5 : "auto",
                            mt: 0,
                            color: "white",
                          }}
                        ></ListItemIcon>
                        <ListItemButton
                          className={
                            (location.pathname.split("/")[1] == "estimates" &&
                              location.pathname.split("/")[2] == "drafts" &&
                              "sidebarActiveInside") +
                            (!open ? " sidebarListItem" : " ")
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 5 : "auto",
                              mt: 0,
                              color: "white",
                            }}
                          >
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              <span style={{ fontSize: "14px" }}>Drafts</span>
                            </ListItemText>
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                      {/* </List> */}
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  <>
                    <Tooltip title={!open && "Estimates"} placement="right">
                      <ListItem
                        key={"Estimates"}
                        disablePadding
                        sx={{
                          display: "block",
                          marginLeft: !open && "4px",
                          marginBottom: !open && "6px",
                        }}
                        className={
                          (location.pathname.split("/")[1] == "estimates" &&
                            "sidebarActive") +
                          (!open ? " sidebarListItem" : " ")
                        }
                        onClick={() => navigate("/estimates/all")}
                      >
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: "white",
                            }}
                          >
                            <RequestQuoteIcon
                              onClick={handleOpenEstimateButtonMenu}
                            />
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                    </Tooltip>
                    <Menu
                      style={{ width: "80%" }}
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      anchorEl={anchorElEstimate}
                      open={openEstimatePopup}
                      onClose={handleCloseEstimateButtonMenu}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      className="openMenuItems"
                    >
                      <MenuItem
                        onClick={() => {
                          handleCloseEstimateButtonMenu();
                          navigate("/estimates/all");
                        }}
                      >
                        All
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleCloseEstimateButtonMenu();
                          navigate("/estimates/sents");
                        }}
                      >
                        Sents
                      </MenuItem>

                      <MenuItem
                        onClick={() => {
                          handleCloseEstimateButtonMenu();
                          navigate("/estimates/drafts");
                        }}
                      >
                        {" "}
                        Drafts
                      </MenuItem>
                    </Menu>
                  </>
                )}
                {open ? (
                  <Accordion
                    style={{
                      color: "white",
                      background: "#002394",
                      "&:before": {
                        display: "none",
                      },
                    }}
                    disableGutters
                    elevation={0}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      style={{ paddingLeft: "inherit", marginBottom: "0px" }}
                    >
                      <ListItem
                        key={"Invoices"}
                        disablePadding
                        sx={{ display: "block" }}
                      >
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: "white",
                            }}
                          >
                            <ReceiptIcon />
                          </ListItemIcon>

                          <ListItemText
                            primary={"Invoices"}
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ListItem
                        key={"All"}
                        disablePadding
                        style={{ marginTop: "-10px" }}
                        onClick={() => navigate("/invoice/all")}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 5 : "auto",
                            mt: 0,
                            color: "white",
                          }}
                        ></ListItemIcon>
                        <ListItemButton
                          className={
                            (location.pathname.split("/")[1] == "invoice" &&
                              location.pathname.split("/")[2] == "all" &&
                              "sidebarActiveInside") +
                            (!open ? " sidebarListItem" : " ")
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 5 : "auto",
                              mt: 0,
                              color: "white",
                            }}
                          >
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              <span style={{ fontSize: "14px" }}>All</span>
                            </ListItemText>
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                      <ListItem
                        key={"Sent"}
                        disablePadding
                        onClick={() => navigate("/invoice/sents")}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 5 : "auto",
                            mt: 0,
                            color: "white",
                          }}
                        ></ListItemIcon>
                        <ListItemButton
                          className={
                            (location.pathname.split("/")[1] == "invoice" &&
                              location.pathname.split("/")[2] == "sents" &&
                              "sidebarActiveInside") +
                            (!open ? " sidebarListItem" : " ")
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 5 : "auto",
                              mt: 0,
                              color: "white",
                            }}
                          >
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              {" "}
                              <span
                                style={{ fontSize: "14px" }}
                                onClick={() => navigate("/invoice/sents")}
                              >
                                Sent
                              </span>
                            </ListItemText>
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                      <ListItem
                        key={"Draft"}
                        disablePadding
                        style={{ marginBottom: "-18px" }}
                        onClick={() => navigate("/invoice/drafts")}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 5 : "auto",
                            mt: 0,
                            // justifyContent: "center",
                            color: "white",
                          }}
                        ></ListItemIcon>
                        <ListItemButton
                          className={
                            (location.pathname.split("/")[1] == "invoice" &&
                              location.pathname.split("/")[2] == "drafts" &&
                              "sidebarActiveInside") +
                            (!open ? " sidebarListItem" : " ")
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 5 : "auto",
                              mt: 0,
                              color: "white",
                            }}
                          >
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              <span style={{ fontSize: "14px" }}>Draft</span>
                            </ListItemText>
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  <>
                    <Tooltip title={!open && "Invoices"} placement="right">
                      <ListItem
                        key={"Invoices"}
                        disablePadding
                        sx={{
                          display: "block",
                          marginLeft: !open && "4px",
                          marginBottom: !open && "6px",
                        }}
                        className={
                          (location.pathname.split("/")[1] == "invoice" &&
                            "sidebarActive") +
                          (!open ? " sidebarListItem" : " ")
                        }
                        onClick={() => navigate("/invoice/all")}
                      >
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                            marginLeft:
                              location.pathname.split("/")[1] == "invoice" &&
                              "-3px",
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: "white",
                            }}
                          >
                            <ReceiptIcon
                              onClick={handleOpenInvoiceButtonMenu}
                            />
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                    </Tooltip>
                    <Menu
                      style={{ width: "80%" }}
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      anchorEl={anchorElInvoice}
                      open={openInvoicePopup}
                      onClose={handleCloseInvoiceButtonMenu}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      className="openMenuItems"
                    >
                      <MenuItem
                        onClick={() => {
                          handleCloseInvoiceButtonMenu();
                          navigate("/invoice/all");
                        }}
                      >
                        All
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleCloseInvoiceButtonMenu();
                          navigate("/invoice/sents");
                        }}
                      >
                        Sents
                      </MenuItem>

                      <MenuItem
                        onClick={() => {
                          handleCloseInvoiceButtonMenu();
                          navigate("/invoice/drafts");
                        }}
                      >
                        {" "}
                        Drafts
                      </MenuItem>
                    </Menu>
                  </>
                )}

                {/* {open ? (
                  <Accordion
                    style={{
                      color: "white",
                      background: "#002394",
                      "&:before": {
                        display: "none",
                      },
                    }}
                    disableGutters
                    variant="elevation"
                    elevation={0}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      style={{ paddingLeft: "inherit", marginBottom: "0px" }}
                      className={
                        (location.pathname.split("/")[1] == "suppliers" &&
                          location.pathname.split("/")?.length == 2 &&
                          "sidebarActive") + (!open ? " sidebarListItem" : " ")
                      }
                    >
                      <ListItem
                        key={"suppliers"}
                        disablePadding
                        sx={{ display: "block" }}
                        onClick={() => navigate("/suppliers")}
                      >
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: "white",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="25"
                              viewBox="0 0 24 25"
                              fill="none"
                            >
                              <path
                                d="M0.75 8.41895H10.5L11.25 9.91895H1.5L0.75 8.41895ZM1.75 11.4189H11.5L12.25 12.9189H2.5L1.75 11.4189ZM18 19.4189C18.83 19.4189 19.5 18.7489 19.5 17.9189C19.5 17.0889 18.83 16.4189 18 16.4189C17.17 16.4189 16.5 17.0889 16.5 17.9189C16.5 18.7489 17.17 19.4189 18 19.4189ZM19.5 10.4189H17V12.9189H21.46L19.5 10.4189ZM8 19.4189C8.83 19.4189 9.5 18.7489 9.5 17.9189C9.5 17.0889 8.83 16.4189 8 16.4189C7.17 16.4189 6.5 17.0889 6.5 17.9189C6.5 18.7489 7.17 19.4189 8 19.4189ZM20 8.91895L23 12.9189V17.9189H21C21 19.5789 19.66 20.9189 18 20.9189C16.34 20.9189 15 19.5789 15 17.9189H11C11 19.5789 9.65 20.9189 8 20.9189C6.34 20.9189 5 19.5789 5 17.9189H3V14.4189H5V15.9189H5.76C6.31 15.3089 7.11 14.9189 8 14.9189C8.89 14.9189 9.69 15.3089 10.24 15.9189H15V6.91895H3C3 5.80895 3.89 4.91895 5 4.91895H17V8.91895H20Z"
                                fill="white"
                              />
                            </svg>
                          </ListItemIcon>

                          <ListItemText
                            primary={"Suppliers"}
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ListItem
                        key={"supplierInvoice"}
                        disablePadding
                        style={{ marginTop: "-10px" }}
                        onClick={() => navigate("/suppliers-invoice")}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 5 : "auto",
                            mt: 0,
                            color: "white",
                          }}
                        ></ListItemIcon>
                        <ListItemButton
                          className={
                            (location.pathname.split("/")[2] ==
                              "supplier-invoice" && "sidebarActiveInside") +
                            (!open ? " sidebarListItem" : " ")
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 5 : "auto",
                              mt: 0,
                              color: "white",
                            }}
                          >
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              <span style={{ fontSize: "14px" }}>
                                Supplier Invoices
                              </span>
                            </ListItemText>
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  <>
                    <Tooltip title={!open && "Suppliers"} placement="right">
                      <ListItem
                        key={"suppliers-invoice"}
                        disablePadding
                        sx={{
                          display: "block",
                          marginLeft: !open && "4px",
                          marginBottom: !open && "6px",
                        }}
                        className={
                          (location.pathname.split("/")[1] == "suppliers" &&
                            "sidebarActive") +
                          (!open ? " sidebarListItem" : " ")
                        }
                        onClick={() => navigate("/suppliers")}
                      >
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                            marginLeft:
                              location.pathname.split("/")[1] ==
                                "appointment" && "-3px",
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: "white",
                            }}
                            onClick={handleOpenSupplierButtonMenu}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="25"
                              viewBox="0 0 24 25"
                              fill="none"
                            >
                              <path
                                d="M0.75 8.41895H10.5L11.25 9.91895H1.5L0.75 8.41895ZM1.75 11.4189H11.5L12.25 12.9189H2.5L1.75 11.4189ZM18 19.4189C18.83 19.4189 19.5 18.7489 19.5 17.9189C19.5 17.0889 18.83 16.4189 18 16.4189C17.17 16.4189 16.5 17.0889 16.5 17.9189C16.5 18.7489 17.17 19.4189 18 19.4189ZM19.5 10.4189H17V12.9189H21.46L19.5 10.4189ZM8 19.4189C8.83 19.4189 9.5 18.7489 9.5 17.9189C9.5 17.0889 8.83 16.4189 8 16.4189C7.17 16.4189 6.5 17.0889 6.5 17.9189C6.5 18.7489 7.17 19.4189 8 19.4189ZM20 8.91895L23 12.9189V17.9189H21C21 19.5789 19.66 20.9189 18 20.9189C16.34 20.9189 15 19.5789 15 17.9189H11C11 19.5789 9.65 20.9189 8 20.9189C6.34 20.9189 5 19.5789 5 17.9189H3V14.4189H5V15.9189H5.76C6.31 15.3089 7.11 14.9189 8 14.9189C8.89 14.9189 9.69 15.3089 10.24 15.9189H15V6.91895H3C3 5.80895 3.89 4.91895 5 4.91895H17V8.91895H20Z"
                                fill="white"
                              />
                            </svg>
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                    </Tooltip>
                    <Menu
                      style={{ width: "80%" }}
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      anchorEl={anchorElSupplier}
                      open={openSupplierPopup}
                      onClose={handleCloseSupplierButtonMenu}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      className="openMenuItems"
                    >
                      <MenuItem
                        onClick={() => {
                          handleCloseSupplierButtonMenu();
                          navigate("/suppliers-invoice");
                        }}
                      >
                        Supplier Invoices
                      </MenuItem>
                    </Menu>
                  </>
                )} */}

                {open ? (
                  <Accordion
                    style={{
                      color: "white",
                      background: "#002394",
                      "&:before": {
                        display: "none",
                      },
                    }}
                    disableGutters
                    variant="elevation"
                    elevation={0}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      style={{ paddingLeft: "inherit", marginBottom: "0px" }}
                      className={
                        (location.pathname.split("/")[1] == "customers" &&
                          location.pathname.split("/")?.length == 2 &&
                          "sidebarActive") + (!open ? " sidebarListItem" : " ")
                      }
                    >
                      <ListItem
                        key={"customers"}
                        disablePadding
                        sx={{ display: "block" }}
                        onClick={() => navigate("/customers")}
                      >
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: "white",
                            }}
                          >
                            <PeopleIcon />
                          </ListItemIcon>

                          <ListItemText
                            primary={"Customers"}
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* <List> */}

                      <ListItem
                        key={"merge"}
                        disablePadding
                        style={{ marginTop: "-10px" }}
                        onClick={() => navigate("/customers/merge")}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 5 : "auto",
                            mt: 0,
                            color: "white",
                          }}
                        ></ListItemIcon>
                        <ListItemButton
                          className={
                            (location.pathname.split("/")[2] == "merge" &&
                              "sidebarActiveInside") +
                            (!open ? " sidebarListItem" : " ")
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 5 : "auto",
                              mt: 0,
                              color: "white",
                            }}
                          >
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              <span style={{ fontSize: "14px" }}>Merge</span>
                            </ListItemText>
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  <>
                    <Tooltip title={!open && "Customers"} placement="right">
                      <ListItem
                        key={"Merge"}
                        disablePadding
                        sx={{
                          display: "block",
                          marginLeft: !open && "4px",
                          marginBottom: !open && "6px",
                        }}
                        className={
                          (location.pathname.split("/")[1] == "customers" &&
                            "sidebarActive") +
                          (!open ? " sidebarListItem" : " ")
                        }
                        onClick={() => navigate("/customers")}
                      >
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                            marginLeft:
                              location.pathname.split("/")[1] ==
                                "appointment" && "-3px",
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: "white",
                            }}
                            onClick={handleOpenCustomerButtonMenu}
                          >
                            <PeopleIcon />
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                    </Tooltip>
                    <Menu
                      style={{ width: "80%" }}
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      anchorEl={anchorElCustomer}
                      open={openCustomerPopup}
                      onClose={handleCloseCustomerButtonMenu}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      className="openMenuItems"
                    >
                      {/* <MenuItem
                        onClick={() => {
                          handleCloseCustomerButtonMenu();
                          navigate("/customers");
                        }}
                      >
                        Customers
                      </MenuItem> */}
                      <MenuItem
                        onClick={() => {
                          handleCloseCustomerButtonMenu();
                          navigate("/customers/merge");
                        }}
                      >
                        Merge
                      </MenuItem>
                    </Menu>
                  </>
                )}
                <Tooltip title={!open && "Messenger"} placement="right">
                  <ListItem
                    key={"Messenger"}
                    disablePadding
                    sx={{
                      display: "block",
                      // mb: !open && "16px",
                      marginLeft: !open && "4px",
                      marginBottom: !open && "6px",
                    }}
                    onClick={() => navigate("/messenger")}
                    className={
                      (location.pathname.split("/")[1] == "messenger" &&
                        "sidebarActive") + (!open ? " sidebarListItem" : " ")
                    }
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        marginLeft:
                          location.pathname.split("/")[1] == "messenger" &&
                          "-3px",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                          color: "white",
                        }}
                      >
                        <SmsIcon />
                        {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                      </ListItemIcon>

                      <ListItemText
                        primary={"Messenger"}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                </Tooltip>
              </List>
            </div>
          )}
        {authData?.allowedPlatforms &&
          authData?.allowedPlatforms.length == 1 &&
          authData?.allowedPlatforms.find((d) => d == "mobile") && (
            <div style={{ justifyContent: "center", display: "flex" }}>
              {open && (
                <>
                  <Button
                    variant="contained"
                    id="demo-positioned-button"
                    aria-controls={open ? "demo-positioned-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    style={{
                      width: "80%",
                      backgroundColor: "#75DBFF",
                      borderRadius: "20px",
                      cursor: "unset",
                      margin: "40px 16px",
                    }}
                    onClick={() => navigate("/account-settings/billing-plans")}
                  >
                    SWITCH TO PREMIUM
                  </Button>
                </>
              )}
            </div>
          )}

        {authData?.subscriptionStatus &&
          authData?.subscriptionStatus == "active" && (
            <div style={styles.menuBottom}>
              <List
                sx={{
                  textAlign: "center",
                  marginLeft: open ? "16px" : "2px",
                  marginRight: open ? "21px" : "12px",
                }}
              >
                <Tooltip title={!open && "Account"} placement="right">
                  <ListItem
                    key={"Account Settings"}
                    disablePadding
                    sx={{
                      display: "block",
                      marginLeft: !open && "4px",
                      marginBottom: !open && "6px",
                    }}
                    onClick={() =>
                      navigate("/account-settings/personal-information")
                    }
                    className={
                      (location.pathname.split("/")[1] == "account-settings" &&
                        "sidebarActive") + (!open ? " sidebarListItem" : " ")
                    }
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        marginLeft:
                          location.pathname.split("/")[1] ==
                            "account-settings" && "-3px",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                          color: "white",
                        }}
                      >
                        <SettingsIcon />
                      </ListItemIcon>

                      <ListItemText
                        primary={"Account"}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                </Tooltip>
                <Tooltip title={!open && "Contact Support"} placement="right">
                  <ListItem
                    key={"Contact Support"}
                    disablePadding
                    sx={{ display: "block", marginLeft: !open && "4px" }}
                    onClick={() => navigate("/contact-support")}
                    className={
                      (location.pathname.split("/")[1] == "contact-support" &&
                        "sidebarActive") + (!open ? " sidebarListItem" : " ")
                    }
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        marginLeft:
                          location.pathname.split("/")[1] ==
                            "contact-support" && "-3px",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                          color: "white",
                        }}
                      >
                        <CallIcon />
                      </ListItemIcon>

                      <ListItemText
                        primary={"Contact Support"}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                </Tooltip>
              </List>
            </div>
          )}
      </Drawer>
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
    </>
  );
}
