import { Person } from "@mui/icons-material";
import { Avatar, CircularProgress } from "@mui/material";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

function UserList({
  customers,
  showLoader,
  setPage,
  showBottomLoader,
  handleCloseModel,
}) {
  const containerRef = useRef();
  const navigate = useNavigate();
  const handleClickUser = (user) => {
    console.log("selected user", user._id);
    navigate(`/messenger/${user.refIdUsers}`);
    handleCloseModel();
  };
  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      if (
        container.scrollTop + container.clientHeight >=
        container.scrollHeight
      ) {
        setPage((page) => page + 1);
      }
    }
  };

  return (
    <>
      {showLoader ? (
        <CircularProgress />
      ) : (
        <div ref={containerRef} onScroll={handleScroll} className="userList">
          {customers.length > 0 &&
            customers?.map((user, i) => (
              <div
                className="user-info-card"
                key={i}
                onClick={() => handleClickUser(user)}
              >
                {user.profilePic !== "" &&
                user.profilePic !== null &&
                user.profilePic !== undefined ? (
                  <Avatar
                    alt="Remy Sharp"
                    src={user.profilePic}
                    sx={{
                      width: "48px",
                      height: "48px",
                    }}
                  />
                ) : (
                  <Avatar
                    sx={{
                      width: "48px",
                      height: "48px",
                    }}
                  >
                    <Person />
                  </Avatar>
                )}
                <p className="username">{user.fullName}</p>
              </div>
            ))}
          {showBottomLoader && <CircularProgress />}
        </div>
      )}
    </>
  );
}

export default UserList;
