import { API } from "../../config/api/index";

//ALL TYPE WISE DATA RETURN
const initState = {
  loading: false,
  requestEstimateData: {},
  requestAppointmentData: {},
};

//DEFINE ACCOUNT REDUCER FUNCTION
const DasboardReducer = (state = initState, action) => {
  switch (action.type) {
    case API.dashboard.dashboardData:
      return {
        ...state,
        dashboardData: action.dashboardData,
        loading: action.loading,
      };
    case API.dashboard.requestEstimateListing:
      return {
        ...state,
        requestEstimateData: action.requestEstimateData,
        loading: action.loading,
      };
    case API.dashboard.requestAppointmentListing:
      return {
        ...state,
        requestAppointmentData: action.requestAppointmentData,
        loading: action.loading,
      };
    default:
      return state;
  }
};

//EXPORT ALL FUNCTION
export default DasboardReducer;
