import React, { useState } from "react";
import "./RecoveryCode.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router";
import {
  CircularProgress,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { handleVerifyotp } from "../../../redux/Auth/action";
import logo from "../../../assets/Logo.svg";

const RecoveryCode = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [disableButton, setDisableButton] = useState(false);

  const userId = localStorage.getItem("id");
  const onSubmit = async (values) => {
    setDisableButton(true);

    values.userId = userId;
    setShowLoader(true);
    let result = await dispatch(handleVerifyotp(values));
    if (result) {
      navigate("/reset-password");
      // localStorage.removeItem("id")
    }
    setShowLoader(false);
    setDisableButton(false);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };
  return (
    <div id="auth_container">
      <div className="auth_logo_container">
        <img src={logo} alt="logo" />
      </div>

      <div className="auth_component_container">
        <div className="formTitle">Enter Recovery Code</div>
        {/* <Typography
              className="enter_recovery_content"
              variant="body1"
              style={{ maxWidth: "520px", paddingBottom: "20px" }}
            >
              Please enter the recovery code sent to your mail. It should arrive
              within 2-3 minutes of entering your email.
            </Typography> */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box component="form" noValidate autoComplete="off">
            <div className="email">
              <Controller
                name="otp"
                control={control}
                rules={{ required: "Recovery Code is required" }}
                render={({ field }) => (
                  <TextField
                    className="auth_input_field"
                    sx={{ height: "48px" }}
                    variant="outlined"
                    fullWidth
                    error={!!errors.otp}
                    helperText={errors.otp ? errors.otp.message : ""}
                    {...field}
                    onKeyPress={handleKeyPress}
                  />
                )}
              />
              {/* <TextField required id="outlined" label="Recovery Code" fullWidth /> */}
            </div>
          </Box>

          <Button
            className="log_in_btn"
            variant="contained"
            color="secondary"
            disableElevation
            fullWidth
            size="large"
            type="submit"
            disabled={disableButton}
          >
            {showLoader ? (
              <CircularProgress
                size={24}
                style={{ color: "white", marginRight: "10px" }}
              />
            ) : (
              ""
            )}
            Continue
          </Button>
        </form>
        <Box className="policy">
          <Typography
            variant="subtitle1"
            fontSize={"16px"}
            fontWeight={400}
            lineHeight={"175%"}
            fontStyle={"normal"}
            color={"#000"}
            letterSpacing={"0.15px"}
          >
            Back to{" "}
            <Link
              color="primary"
              variant="body1"
              fontWeight="medium"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
            >
              Log In
            </Link>
          </Typography>
        </Box>

        <Box className="sign_up">
          <Typography
            variant="subtitle1"
            fontSize={"16px"}
            fontWeight={400}
            lineHeight={"175%"}
            fontStyle={"normal"}
            color={"#000"}
            letterSpacing={"0.15px"}
            textAlign={"center"}
          >
            New to Apex Mechanic?{" "}
            <Link
              variant="body1"
              sx={{
                fontWeight: "medium",
                color: "primary",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/signup");
              }}
            >
              Sign Up
            </Link>
          </Typography>
        </Box>
      </div>
    </div>
  );
};

export default RecoveryCode;
