import { getItem, removeItem, setItem } from "../utility/localStorage";

// src/history.js
const historyStack = getItem("backfeature") ?? [];

export const addToHistory = (path) => {
  const lastPath = historyStack[historyStack.length - 1];
  if (lastPath !== path) {
    historyStack.push(path);
    setItem("backfeature", historyStack);
  }
};

export const getPreviousPath = () => {
  return getItem("backfeature")?.[getItem("backfeature")?.length - 1]; // Return previous path
};

export const clearAllPaths = () => {
  historyStack.length = 0;
  return removeItem("backfeature"); // Return previous path
};
export default historyStack;
