import axios from "axios";
import { toast } from "sonner";
import { getItem, removeItem, setItem } from "../../utility/localStorage";
import { API } from "../api";
// import { message } from "antd";

// const API_ENDPOINT = `http://172.105.58.76:4504/web/v1/api/`;
// const imageUrl = `http://172.105.58.76:4504/storage/`;

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const imageUrl = process.env.REACT_APP_IMAGE_URL;

export const COMET_CHAT_AUTH_KEY = process.env.REACT_APP_COMET_CHAT_AUTH_KEY;
export const COMET_CHAT_REGION = process.env.REACT_APP_COMET_CHAT_REGION;
export const COMET_CHAT_APP_ID = process.env.REACT_APP_COMET_CHAT_APP_ID;

const backToApex = "https://apexmechanic.com/";
const authHeader = () => ({
  Authorization: `Bearer ${getItem("apex-saas_token")}`,
});

const platformHeader = () => ({
  platform: `web`,
  web_platform: "saas",
});
const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${getItem("apex-saas_token")}`,
    "Content-Type": "application/json",
  },
});

class DataService {
  //GET API SETUP
  static get(path = "", optionalHeader = {}, cancelToken) {
    return client({
      method: "GET",
      url: path,
      headers: { ...authHeader(), ...optionalHeader, ...platformHeader() },
      cancelToken: cancelToken,
    });
  }

  //POST API SETUP
  static post(
    path = "",
    data = {},
    optionalHeader = {},
    responseType = "",
    cancelToken
  ) {
    return client({
      method: "POST",
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader, ...platformHeader() },
      responseType,
      cancelToken: cancelToken,
    });
  }

  //PATCH API SETUP
  static patch(path = "", data = {}, optionalHeader = {}) {
    return client({
      method: "PATCH",
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader, ...platformHeader() },
    });
  }

  //PUT API SETUP
  static put(path = "", data = {}, optionalHeader = {}) {
    return client({
      method: "PUT",
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader, ...platformHeader() },
    });
  }

  //DELETE API SETUP
  static delete(path = "", data = {}) {
    return client({
      method: "DELETE",
      url: path,
      data,
      headers: { ...authHeader(), ...platformHeader() },
    });
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */

client.interceptors.request.use((config) => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  const { headers } = config;
  requestConfig.headers = {
    ...headers,
    Authorization: `Bearer ${getItem("apex-saas_token")}`,
  };

  return requestConfig;
});

client.interceptors.response.use(
  (response) => {
    // if (!response.data.error) {
    //   if (!response.data.message.includes("List Found")) {
    //     message.success(response.data.message);
    //   }
    sessionStorage.setItem("maintenance", false);
    // sessionStorage.setItem("maintenance", true);
    // if (!window.location.pathname.split("/").includes("site-maintenance"))
    //   if (window.location.pathname.split("/").includes("apex-saas")) {
    //     window.location.href = "/apex-saas/site-maintenance";
    //   } else {
    //     window.location.href = "/site-maintenance";
    //   }
    // removeItem("maintenance");
    // if (true) {
    //   if (!window.location.pathname.split("/").includes("site-maintenance"))
    //     if (window.location.pathname.split("/").includes("apex-saas")) {
    //       window.location.replace("/apex-saas/site-maintenance");
    //     } else {
    //       window.location.replace("/site-maintenance");
    //     }
    // }

    return response;
    // }
  },
  (error) => {
    if (error?.code == "ERR_NETWORK") {
      sessionStorage.setItem("maintenance", true);
      if (!window.location.pathname.split("/").includes("site-maintenance"))
        if (window.location.pathname.split("/").includes("apex-saas")) {
          window.location.href = "/apex-saas/site-maintenance";
        } else {
          window.location.href = "/site-maintenance";
        }
    }
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;

    const originalRequest = error.response;

    if (response) {
      if (
        response.status === 500 &&
        response.config.url != "invoice/export-invoice"
      ) {
        // do something here

        // <Snackbar
        //   open={true}
        //   anchorOrigin={{ vertical: "top", horizontal: "center" }}
        //   autoHideDuration={100}
        //   key={"top center"}
        // >
        //   <Alert severity="error" sx={{ width: "100%" }}>
        //     Internal Server Error
        //   </Alert>
        // </Snackbar>;
        toast.info(response.data.message);
        // localStorage.setItem("error",JSON.stringify({message:response.data.message, open:true}))

        // message.error("Internal Server Error");
      } else if (response.status == 403) {
        window.localStorage.clear();
        window.location.reload();
      } else if (
        response.status == 400 &&
        response.config.url != API.auth.login &&
        response.config.url != "invoice/export-invoice"
      ) {
        console.log("response", response);
        toast.error(response.data.message);
        // localStorage.setItem("error",JSON.stringify({message:response.data.message, open:true}))
      } else {
        // message.error(response.data.message);
        if (
          response.config.url != API.auth.login &&
          response.config.url != "invoice/export-invoice"
        ) {
          toast.error(response.data.message);
          // localStorage.setItem("error",JSON.stringify({message:response.data.message, open:true}))
        }

        // <Snackbar
        //   open={true}
        //   anchorOrigin={{ vertical: "top", horizontal: "center" }}
        //   autoHideDuration={100}
        //   key={"top center"}
        // >
        //   <Alert severity="error" sx={{ width: "100%" }}>
        //     {response.data.message}
        //   </Alert>
        // </Snackbar>;
        return originalRequest;
      }
    }

    return Promise.reject(error);
  }
);
export { API_ENDPOINT, DataService, backToApex, imageUrl };
