import React, { useState } from "react";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { styled } from "@mui/system";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  backgroundColor: "rgba(241, 241, 241, 1)",
  borderRadius: 50,
  "& .MuiToggleButtonGroup-grouped": {
    margin: 5,
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: 50,
    },
    "&:first-of-type": {
      borderRadius: 50,
    },
    margin: "4px 8px",
  },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  color: "rgba(142, 142, 142, 1)",
  textTransform: "none",
  fontWeight: "500",
  padding: "8px 17px",
  fontSize: "16px",
}));

const BillingToggle = ({ activeButton, handleChangeButton }) => {
  // const [billing, setBilling] = useState("month");

  const handleChange = (event, newBilling) => {
    if (newBilling !== null) {
      // setBilling(newBilling);
      handleChangeButton(newBilling);
    }
  };

  return (
    <div>
      <StyledToggleButtonGroup
        value={activeButton}
        exclusive
        onChange={handleChange}
        aria-label="billing frequency"
      >
        <StyledToggleButton value="month" aria-label="monthly">
          Monthly
        </StyledToggleButton>
        <StyledToggleButton value="year" aria-label="annually">
          Annually
        </StyledToggleButton>
      </StyledToggleButtonGroup>
    </div>
  );
};

export default BillingToggle;
