import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Card,
  Grid,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";

function SupplierNotes(props) {
  const [newNote, setNewNote] = useState("");
  const [noteError, setNoteError] = useState(false);
  const [notes, setNotes] = useState([]);
  const [editDeleteNoteId, setEditDeleteNoteId] = useState("");
  const [editNoteText, setEditNoteText] = useState("");
  const [selectedActionOfNote, setSelectedActionOfNote] = useState("");

  const [anchorElAtions, setAnchorElActions] = React.useState(null);
  const openNoteActions = Boolean(anchorElAtions);

  const handleClickNoteActions = (event, data) => {
    setSelectedActionOfNote(data);
    setAnchorElActions(event.currentTarget);
  };
  const handleCloseNoteActions = () => {
    setAnchorElActions(null);
    setSelectedActionOfNote("");
  };
  const editNote = async () => {
    // let payload = { notes: editNoteText };
    // setShowLoader(true);
    // await dispatch(editCustomerNote(editDeleteNoteId?._id, payload));
    // fetchCustomerNotes(customerNoteParams);
    // // setShowLoader(false)
    // setEditDeleteNoteId("");
    // setEditNoteText("");
    // setShowLoader(false);
    // setOpenSuccessModal(true);
    // setSuccessMessage("Note Updated");
  };
  const deleteNote = async (id) => {
    // setShowLoader(true);
    // await dispatch(deleteCustomerNote(id));
    // fetchCustomerNotes(customerNoteParams);
    // // setShowLoader(false)
    // setEditDeleteNoteId("");
    // setEditNoteText("");
    // setShowLoader(false);
    // setOpenSuccessModal(true);
    // setSuccessMessage("Note Deleted");
  };
  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={12}
      xl={12}
      justifyContent={"space-between"}
      // spacing={2}
      borderRadius={"4px"}
      mt={"16px"}
    >
      <Grid container justifyContent={"start"} borderTop={"none"}>
        <Grid container item xs={12} sm={12} md={12} xl={12} rowGap={3}>
          <Stack spacing={2} sx={{ width: "100%", padding: "16px" }}>
            <Grid item xs={12} sm={12} md={12} xl={12} padding={""}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 500, marginBottom: "16px" }}
              >
                Recent Notes
              </Typography>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} xl={12}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                type="text"
                value={newNote}
                onChange={(e) => setNewNote(e.target.value)}
              />
            </Grid>
            {noteError && (
              <p
                style={{
                  color: "#d32f2f",
                  fontSize: "0.75rem",
                  letterSpacing: "0.03333em",
                  lineHeight: "1.66",
                  marginTop: "3px",
                }}
              >
                Please enter note
              </p>
            )}
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              xl={12}
              justifyContent={"end"}
            >
              <Grid>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  // onClick={handleAddNote}
                >
                  Add Note
                </Button>
              </Grid>
            </Grid>
          </Stack>

          <Grid item container>
            {Array.isArray(notes) &&
              notes?.map((data) => {
                return (
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{
                      backgroundColor: "#e6f7f7",
                      borderRadius: "8px",
                      padding: "16px",
                      marginBottom: "24px",
                    }}
                    // paddingBottom={"16px"}
                    display={"block"}
                  >
                    {/* <Card padding={"16px"} style={{backgroundColor:"#e6f7f7"}}> */}
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      mb={2}
                    >
                      <div>
                        <Typography variant="body1">
                          {dayjs(data?.createdAt).format("MMMM DD, YYYY") +
                            " at " +
                            dayjs(data?.createdAt).format("h:mm A")}
                        </Typography>
                      </div>

                      <div style={{ marginRight: "16px" }}>
                        <Button
                          variant="contained"
                          onClick={(e) => {
                            handleClickNoteActions(e, data);
                          }}
                          id={data?._id}
                          aria-controls={
                            openNoteActions ? "demo-positioned-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={openNoteActions ? "true" : undefined}
                          endIcon={<ExpandMoreIcon />}
                        >
                          Actions
                        </Button>
                        <Menu
                          style={{ width: "300px" }}
                          id="demo-positioned-menu"
                          aria-labelledby={data?._id}
                          anchorEl={anchorElAtions}
                          open={openNoteActions}
                          onClose={handleCloseNoteActions}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <MenuItem
                            onClick={() => {
                              handleCloseNoteActions();
                              setEditDeleteNoteId(selectedActionOfNote);
                              setEditNoteText(selectedActionOfNote?.notes);
                            }}
                          >
                            {" "}
                            <EditIcon style={{ marginRight: "10px" }} /> Edit
                            Note
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleCloseNoteActions();
                              // setEditDeleteNoteId(
                              //   selectedActionOfNote
                              // );
                              deleteNote(selectedActionOfNote?._id);
                            }}
                          >
                            {" "}
                            <DeleteIcon style={{ marginRight: "10px" }} />{" "}
                            Delete Note
                          </MenuItem>
                        </Menu>
                      </div>
                    </Stack>
                    {editDeleteNoteId?._id != data._id && (
                      <Card
                        style={{
                          backgroundColor: "#fff",
                          padding: "10px",
                          marginRight: "10px",
                          marginBottom: "8px",
                          border: "1px solid #C4C4C4",
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: 400,
                            fontSize: "16px",
                          }}
                        >
                          {data?.notes}
                        </Typography>
                      </Card>
                    )}
                    {editDeleteNoteId && editDeleteNoteId?._id == data?._id && (
                      <>
                        <TextField
                          // value={data?.notes}
                          style={{
                            backgroundColor: "#fff",
                            // padding: "10px",
                            marginRight: "10px",
                            marginBottom: "16px",
                            // border: "1px solid #C4C4C4",
                            width: "100%",
                          }}
                          value={editNoteText}
                          onChange={(e) => setEditNoteText(e.target.value)}
                        />
                        <Button
                          variant="contained"
                          style={{ marginRight: "16px" }}
                          onClick={() => editNote()}
                        >
                          SAVE
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setEditDeleteNoteId("");
                            setSelectedActionOfNote("");
                          }}
                        >
                          CANCEL
                        </Button>
                      </>
                    )}
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SupplierNotes;
