import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Modal,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 3,
  pb: 3,
  maxWidth: "80%",
};
const AddCustomerProgramModal = ({
  open,
  setOpenProgramModal,
  isRemoveDiscount,
  setIsRemoveDiscount,
  onSave,
  customerName,
  programName,
}) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          setOpenProgramModal(false);
          setIsRemoveDiscount(false);
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: "700px", height: "fit-content" }}>
          <AppBar
            variant="elevation"
            color="secondary"
            sx={{
              height: "64px",
              paddingRight: "0px !important",
            }}
          >
            <Toolbar
              variant="regular"
              style={{
                padding: "16px ",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom={false}
                width={"98%"}
                color={"white"}
                fontSize={"20px"}
                lineHeight={"32px"}
              >
                {isRemoveDiscount
                  ? `Remove ${customerName} from discount program?`
                  : `Add ${customerName} to ${programName} discount program?`}
              </Typography>
              <Stack direction={"row"} spacing={0}>
                <div
                  style={{ cursor: "pointer", height: "32px" }}
                  onClick={() => {
                    setOpenProgramModal(false);
                    setIsRemoveDiscount(false);
                  }}
                >
                  <CloseIcon style={{ color: "#0000008F", fontSize: 32 }} />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>
          <Container
            disableGutters
            style={{
              height: "fit-content",
              marginTop: "66px",
              gap: "24px",
            }}
          >
            <Typography color={"black"}>
              {isRemoveDiscount
                ? "By clicking 'Yes,' this customer will be removed from the discount program, and the discount will no longer apply to future invoices, estimates, and appointments."
                : `By clicking 'Yes,' this customer will be added to the ${programName} discount program, and the
              discount will automatically apply to future invoices,
              estimates, and appointments.`}
            </Typography>
          </Container>

          <Grid
            container
            justifyContent={"end"}
            spacing={1}
            style={{
              marginTop: "8px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Grid item spacing={2}>
              <Stack spacing={2} direction="row">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => onSave(false)}
                >
                  DON{"'"}T {isRemoveDiscount ? "REMOVE" : "ADD"}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onSave(true)}
                >
                  YES {isRemoveDiscount ? "REMOVE" : "ADD"}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default AddCustomerProgramModal;
