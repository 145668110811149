import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { mechanicDetailsList } from "../../redux/appointment/action";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const displayAppointmentTypes = [
  { key: "Upcoming Appointments", value: "upcoming_appointments" },
  { key: "Waiting for Drop Off", value: "waiting_for_drop_off" },
  { key: "Vehicles Dropped Off", value: "vehicle_dropped_off" },
  { key: "In Progress", value: "in_progress" },
  { key: "Ready for Pick Up", value: "ready_for_pick_up" },
  { key: "Customer Paid", value: "customer_paid" },
  { key: "No Show", value: "mark_as_no_show" },
];
function Sidebar({
  view,
  setView,
  setSideBarWidth,
  sideBarWidth,
  setResources,
  resources,
  setAssignee,
  calendarPreferences,
  setCalendarPreferences,
}) {
  const dispatch = useDispatch();
  const [state, setState] = useState(0);
  const [calendars, setCalendars] = useState();
  // const [selectedAppointmentTypes, setSelectedAppointmentTypes] = useState([
  //   "upcoming_appointments",
  //   "waiting_for_drop_off",
  //   "vehicle_dropped_off",
  //   "in_progress",
  //   "ready_for_pick_up",
  // ]);
  useEffect(() => {
    mechanicList();
  }, []);
  const mechanicList = async () => {
    let data = await dispatch(mechanicDetailsList());
    // console.log("dataa", data);
    let allData = [];
    Array.isArray(data) &&
      data?.map((d, index) => {
        allData.push({ title: d?.name, id: d._id, _id: d._id });
      });
    setAssignee(allData);
    setCalendars(allData);
    setResources(allData);
    // setMechanicDetail(data);
  };

  return (
    <Box
      sx={{
        minHeight: "100%",
        border:
          resources.length > 1 ? "1px solid #D8D8D8" : "0px solid #D8D8D8 ",
        // p: 2,
      }}
    >
      <Stack gap={2} pt={"40px"} pr={2} pl={2}>
        <div>
          {sideBarWidth == 3 ? (
            <IconButton
              style={{ height: "24px", width: "24px", float: "right" }}
              onClick={() => setSideBarWidth(1)}
            >
              <ArrowBackIcon className="arrowIcon" />
            </IconButton>
          ) : (
            <IconButton
              style={{ height: "24px", width: "24px" }}
              onClick={() => setSideBarWidth(3)}
            >
              <ArrowForwardIcon className="arrowIcon" />
            </IconButton>
          )}
        </div>
      </Stack>
      {/* {sideBarWidth == 3 && ( */}
      <div className={`transition-box ${sideBarWidth == 3 ? "visible" : ""} `}>
        <Box paddingTop={4} paddingLeft={2}>
          <Typography fontWeight={500}>Views</Typography>
          <br />
          <Stack style={{ paddingLeft: "6px" }}>
            <div>
              <FormControlLabel
                control={
                  <Radio
                    checked={view == "day"}
                    onChange={(e) => {
                      setView("day");
                      // setState(0);
                    }}
                  />
                }
                labelPlacement="end"
                label="Day"
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Radio
                    checked={view == "3day"}
                    onChange={(e) => {
                      setView(e.target.checked ? "3day" : "day");
                      // setState(1);
                    }}
                  />
                }
                labelPlacement="end"
                label="3 Days"
              />
            </div>{" "}
            <div>
              <FormControlLabel
                control={
                  <Radio
                    checked={view == "5day"}
                    onChange={(e) => {
                      setView(e.target.checked ? "5day" : "day");
                      // setState(2);
                    }}
                  />
                }
                labelPlacement="end"
                label="5 Days"
              />
            </div>{" "}
            <div>
              <FormControlLabel
                control={
                  <Radio
                    checked={view == "month"}
                    onChange={(e) => {
                      setView(e.target.checked ? "month" : "day");

                      // setState(3);
                    }}
                  />
                }
                labelPlacement="end"
                label="Month"
              />
            </div>
          </Stack>
        </Box>
        <br />
        <Divider sx={{ marginInline: "16px" }} />

        <Box>
          <Accordion defaultExpanded style={{ boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ fill: "black" }} />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ fontWeight: 500 }}
            >
              Calendars
            </AccordionSummary>
            <AccordionDetails sx={{ background: "white", pl: 3, pt: 0 }}>
              <Stack>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={resources.length == calendars?.length}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setResources([...calendars]);
                        } else {
                          setResources([calendars[0]]);
                        }
                      }}
                    />
                  }
                  label="Select All"
                />
                {calendars?.map((calendar) => {
                  return (
                    <div key={calendar._id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            // style={{
                            //   color: (resources.filter(
                            //     (data) => data != calendar
                            //   ))[0] == calendar ? calendar.color : "black"
                            // }}
                            checked={
                              resources?.filter((d) => d._id == calendar._id)
                                ?.length > 0
                                ? true
                                : false
                            }
                            disabled={
                              resources.length == 1 && calendar == resources[0]
                            }
                            onChange={(e) => {
                              const allResources = [...resources];
                              if (e.target.checked) {
                                setResources([...resources, calendar]);
                              } else {
                                const resource = allResources.filter(
                                  (data) => data != calendar
                                );
                                setResources(resource);
                              }
                            }}
                          />
                        }
                        labelPlacement="end"
                        label={calendar.title}
                      />
                    </div>
                  );
                })}
              </Stack>
            </AccordionDetails>{" "}
          </Accordion>
        </Box>

        {/* Display Appointment design */}
        <Divider sx={{ marginInline: "16px" }} />
        <Box>
          <Accordion defaultExpanded style={{ boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ fill: "black" }} />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ fontWeight: 500 }}
            >
              Display Appointments
            </AccordionSummary>
            <AccordionDetails sx={{ background: "white", pl: 3, pt: 0 }}>
              <Stack>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        calendarPreferences.length ==
                        displayAppointmentTypes?.length
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          const all = displayAppointmentTypes?.map(
                            (d) => d.value
                          );
                          setCalendarPreferences([...all]);
                        } else {
                          setCalendarPreferences([]);
                        }
                      }}
                    />
                  }
                  label="Select All"
                />
                {displayAppointmentTypes?.map((type) => {
                  return (
                    <div key={type._id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            // style={{
                            //   color: (resources.filter(
                            //     (data) => data != type
                            //   ))[0] == type ? type.color : "black"
                            // }}
                            checked={
                              calendarPreferences.find((d) => d == type.value)
                                ? true
                                : false
                            }
                            // disabled={
                            //   resources.length == 1 &&
                            //   type == resources[0]
                            // }
                            onChange={(e) => {
                              const allSelectedTypes = [...calendarPreferences];
                              if (e.target.checked) {
                                setCalendarPreferences([
                                  ...calendarPreferences,
                                  type.value,
                                ]);
                              } else {
                                const resource = allSelectedTypes.filter(
                                  (data) => data != type.value
                                );
                                setCalendarPreferences(resource);
                              }
                            }}
                          />
                        }
                        key={type.key}
                        labelPlacement="end"
                        label={type.key}
                      />
                    </div>
                  );
                })}
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Box>
      </div>
      {/* )} */}
    </Box>
  );
}

export default Sidebar;
