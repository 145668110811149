import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from "@mui/icons-material/Help";
import {
  AppBar,
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  Modal,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  serviceCategoryData,
  serviceSubcategoryData,
} from "../../redux/Estimate/action";
import AddNewServiceModal from "./AddNewServiceModal";

import { toast } from "sonner";
import { CustomPaper } from "../../utility/SelectDropdown";
import styles from "./AddAppointmentService.module.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 3,
};

const AddAppointmentServiceModal = ({
  open,
  handleClose,
  id,
  appointentServiceModalDataProps,
  filteredArray,
  servicesNameAPI,
}) => {
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();

  const [categoryData, setCategoryData] = React.useState([{}]);

  const [serviceNameData, setServiceNameData] = React.useState(null);

  const [serviceCategory, setServiceCategory] = React.useState("");

  const [openNewServiceModal, setOpenNewServiceModal] = React.useState(false);

  const [data, setData] = React.useState("");
  const [showMenuLoader, setShowMenuLoader] = React.useState(false);
  const [showServiceCategoryLoader, setShowServiceCategoryLoader] =
    React.useState(false);

  const [servicePageData, setServicePageData] = useState({
    totalpages: "",
    currentPage: "",
  });

  const [page, setPage] = useState(1);
  const limit = 1000;
  const [autofillDataInModal, setAutoFillDataInModal] = useState();
  const onSubmit = (value) => {
    const idExists = filteredArray.some(
      (obj) => obj.serviceSubCategory._id === serviceNameData
    );
    if (idExists) {
      return toast.error("This Service Already Added in this Appointment");
    }
    appointentServiceModalDataProps(serviceNameData, page);
    handleClose();
    setServiceNameData("");

    setServiceCategory("");
  };

  const fetchThisShopsCategory = async () => {
    setShowServiceCategoryLoader(true);
    const resp = await dispatch(serviceCategoryData(5000));

    setCategoryData(resp);
    setShowServiceCategoryLoader(false);
  };

  useEffect(() => {
    fetchThisShopsCategory();
  }, []);

  const handleCancel = () => {
    setServiceNameData("");
    setServiceCategory("");
    handleClose();
  };

  const Data = useSelector((state) => state?.estimate?.serviceSubData);

  const servicesName = async (page, limit, catogryId) => {
    let id;
    if (catogryId) {
      id = catogryId;
    } else {
      id = undefined;
    }

    setShowMenuLoader(true);

    let serviceSubCategoryData = await dispatch(
      serviceSubcategoryData(page, limit, catogryId)
    );

    if (serviceSubCategoryData) {
      if (page > 1) {
        setData([...data, ...serviceSubCategoryData.data]);
      } else {
        setData([...serviceSubCategoryData.data]);
      }
      setServicePageData({
        totalpages: serviceSubCategoryData?.totalPages,
        currentPage: serviceSubCategoryData?.currentPage,
      });
      setShowMenuLoader(false);
    }
  };

  const handleScroll = (e) => {
    const target = e.target;
    if (
      parseInt(target.scrollHeight - target.scrollTop) ==
        parseInt(target.clientHeight) &&
      servicePageData.totalpages > page
    ) {
      setPage(page + 1);
      servicesName(page + 1, limit);
    }
  };
  useEffect(() => {
    servicesName(1, 1000, autofillDataInModal?.serviceCategory);
    servicesNameAPI();
  }, [autofillDataInModal]);
  useEffect(() => {
    if (autofillDataInModal && data) {
      setServiceNameData(autofillDataInModal?.serviceSubCategory);
    }
  }, [data]);
  useEffect(() => {
    if (autofillDataInModal && categoryData) {
      setServiceCategory(autofillDataInModal?.serviceCategory);
    }
  }, [categoryData]);

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              ...style,
              border: "none",
              borderRadius: "8px 8px 0px 0px",
            }}
            className="modalBodyBoxContainer"
          >
            <AppBar
              color="secondary"
              variant="elevation"
              sx={{
                height: "64px",
                borderRadius: "8px 8px 0px 0px !important",
                paddingRight: "0px !important",
              }}
            >
              <Toolbar variant="regular">
                <Typography variant="h6" width={"98%"} color={"white"}>
                  {id ? "Edit Service" : "Add Service"}
                </Typography>
                <Stack direction={"row"}>
                  <div
                    onClick={() => {
                      handleCancel();
                    }}
                    style={{ cursor: "pointer", height: "32px" }}
                  >
                    <CloseIcon style={{ color: "#FFFFFF", fontSize: 32 }} />
                  </div>
                </Stack>
              </Toolbar>
            </AppBar>
            <Container
              disableGutters
              style={{
                scrollbarColor: "blue",
                scrollbarWidth: "thin",
                overflowY: "auto",
                marginTop: "64px",
                maxHeight: "80vh",
              }}
            >
              <Grid
                container
                spacing={3}
                style={{ scrollbarColor: "blue", scrollbarWidth: "thin" }}
              >
                <Grid item xs={12} md={12} xl={12}>
                  <Stack spacing={1}>
                    <Typography
                      variant="body1"
                      className={styles.serviceNameText}
                    >
                      Service Category <span style={{ color: "red" }}>*</span>
                    </Typography>

                    <Autocomplete
                      PaperComponent={CustomPaper}
                      disablePortal
                      id="combo-box-demo"
                      options={
                        categoryData
                          ? categoryData.sort((a, b) =>
                              a.name.localeCompare(b.name)
                            )
                          : []
                      }
                      value={
                        serviceCategory
                          ? categoryData.filter(
                              (d) => d._id == serviceCategory
                            )?.[0]
                          : null
                      }
                      getOptionLabel={(option) => option?.name}
                      onChange={(event, newValue) => {
                        setData([]);
                        setPage(1);

                        setServiceCategory(newValue?._id);
                        servicesName(1, 1000, newValue?._id);
                        setServiceNameData(null);
                      }}
                      ListboxProps={{
                        style: {
                          maxHeight: "300px",
                        },
                        onScroll: (event) => {
                          handleScroll(event);
                        },
                      }}
                      loading={showServiceCategoryLoader}
                      loadingText={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <CircularProgress />
                        </div>
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="" />
                      )}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                  <Stack spacing={1}>
                    <Typography
                      variant="body1"
                      className={styles.serviceNameText}
                    >
                      Service Name <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Autocomplete
                      PaperComponent={CustomPaper}
                      disablePortal
                      id="combo-box-demo"
                      options={data ?? []}
                      getOptionLabel={(option) =>
                        option?.serviceSubCategory?.name
                      }
                      value={
                        serviceNameData
                          ? data.filter(
                              (d) =>
                                d.serviceSubCategory?._id == serviceNameData
                            )?.[0]
                          : null
                      }
                      disabled={serviceCategory ? false : true}
                      onChange={(event, newValue) => {
                        setServiceNameData(newValue?.serviceSubCategory?._id);
                      }}
                      ListboxProps={{
                        style: {
                          maxHeight: "300px",
                        },
                        onScroll: (event) => {
                          handleScroll(event);
                        },
                      }}
                      loading={showMenuLoader}
                      loadingText={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <CircularProgress />
                        </div>
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="" />
                      )}
                      renderOption={(props, option) => (
                        <>
                          <Box component="li" {...props}>
                            <li>{option?.serviceSubCategory?.name}</li>
                          </Box>
                          {showMenuLoader &&
                            props["data-option-index"] + 1 == data?.length && (
                              <Box component="li" {...props}>
                                <li {...props}>
                                  <CircularProgress />
                                </li>
                              </Box>
                            )}
                        </>
                      )}
                    />
                    <div className={styles.flexCenter}>
                      <div className={styles.flex}>
                        <AddIcon className={styles.skyBlueColor} />
                      </div>
                      <div
                        className={styles.addServiceText}
                        onClick={() => {
                          setOpenNewServiceModal(true);
                        }}
                      >
                        ADD NEW SERVICE TO YOUR SHOP MENU
                      </div>
                      <Tooltip
                        title={
                          <span className="pauseAppointmentToolTip">
                            If you are unable to find a service, you can quickly
                            additional services to your shop by clicking on this
                            button
                          </span>
                        }
                      >
                        <HelpIcon className={styles.toolTipIcon} />
                      </Tooltip>
                    </div>
                  </Stack>
                </Grid>

                <Grid item container justifyContent={"end"}>
                  <Grid item>
                    <Stack spacing={2} direction="row">
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          handleCancel();
                        }}
                      >
                        CANCEL
                      </Button>
                      <Button type="submit" variant="contained" color="primary">
                        SAVE
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </form>
      </Modal>

      <AddNewServiceModal
        open={openNewServiceModal}
        handleClose={() => setOpenNewServiceModal(false)}
        openFor="add"
        reFetchParentDetails={fetchThisShopsCategory}
        setAutoFillDataInModal={setAutoFillDataInModal}
        // editData,
        // page,
        // limit,
        // searchValue,
        // deleteData,
        // selectedIds,
        // selectedPriceType,
      />
    </Box>
  );
};

export default AddAppointmentServiceModal;
