import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Modal,
  Select,
  styled,
  Switch,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Stack } from "@mui/system";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
// import FormControlLabel from '@mui/material/FormControlLabel';
import CloseIcon from "@mui/icons-material/Close";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import ReschedulePolicy from "./Reschedule";

import React, { useEffect, useState } from "react";
import {
  addCancellationPolicy,
  addTermsAndWarrenty,
  deleteCancellationPolicy,
  deleteReschedulingPolicy,
  deleteTermsAndWarrenty,
  getAminitiy,
  getShopPriceDetails,
  shopDetails,
  updateAminities,
  updateCancellationPolicy,
  updateEstimatesFee,
  updateShopCertificates,
  updateShopDetails,
  updateShopImages,
  updateShopPriceDetails,
  updateTermsAndWarrenty,
} from "../../../redux/Account/ShopDetails/action";

import { useTheme } from "@emotion/react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import "../style.css";

import { getCityList, getProvinceList } from "../../../redux/Customer/action";
import { getItem, setItem } from "../../../utility/localStorage";
import SuccessMessageModal from "../../Common/SuccessMessage";
import GoogleAutoComplete from "../../Customers/NewCustomers/GoogleAutoComplete";
import AddressSelect from "../../Customers/Profile/AddressSelect";
import ImageShowModal from "../../modal/ImageShowModal";
import { REQUIRED_RED } from "./../../../App";
import ImageUploader from "./ImageUploader";
import RequestedPhotos from "./RequestedPhotos";
const top100Films = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
  { label: "12 Angry Men", year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: "Pulp Fiction", year: 1994 },
];

const itemData = [
  {
    img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
    title: "Breakfast",
  },
  {
    img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    title: "Burger",
  },
  {
    img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
    title: "Camera",
  },
  {
    img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
    title: "Coffee",
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const ShopDetails = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [cityList, setCityList] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [cityListLoader, setCityListLoader] = useState(false);
  const [provinceListLoader, setProvinceListLoader] = useState(false);
  const [addressValues, setAddressValue] = useState({
    street_number: "",
    city: "",
    province: "",
    postal: "",
    country: "",
    provinceId: "",
    cityId: "",
    countryId: "",
  });
  const fetchCityList = async (province, country) => {
    setCityListLoader(true);
    let resp = await dispatch(
      getCityList({ country: "64e738882fec8f2c9b79c139", province: province })
    );
    if (resp) {
      // let finalCity = [...resp];
      // finalCity = finalCity
      // .filter((obj) => obj.hasOwnProperty("city"))
      // .map((obj) => obj.city);

      setCityList(resp);
    }
    setCityListLoader(false);
  };

  const fetchProvinceList = async (country) => {
    setProvinceListLoader(true);
    let resp = await dispatch(
      getProvinceList({ country: "64e738882fec8f2c9b79c139" })
    );
    if (resp) {
      // let finalProvince = [...resp];
      // finalProvince = finalProvince
      // .filter((obj) => obj.hasOwnProperty("province"))
      // .map((obj) => obj.province);

      setProvinceList(resp);
    }
    setProvinceListLoader(false);
  };

  useEffect(() => {
    fetchCityList();
    fetchProvinceList();
  }, []);

  const BasicDetails = useSelector((state) => state?.account?.shopDetailsData);
  const shopPriceDetails = useSelector((state) => state?.account.shopPrice);

  console.log("shopPriceDetails", shopPriceDetails);
  const BasicCertificatesDetails = useSelector(
    (state) => state?.account?.shopDetailsData?.certificates
  );
  // const getTermsSpecificApiData = useSelector(
  //   (state) => state?.account?.getTermsAndWarrenty
  // );
  const getTermsSpecificApiData = useSelector(
    (state) => state?.account?.shopDetailsData.termsAndWarranty
  );

  const getReseduleApiData = useSelector(
    (state) => state?.account?.shopDetailsData?.reschedulePolicy
  );

  const getPolicyApiData = useSelector(
    (state) => state?.account?.getCancellationPolicy
  );
  const getAminitiesApiData = useSelector(
    (state) => state?.account?.getAmenities?.list
  );

  const [openTerms, setOpenTerms] = useState(false);
  const [open, setOpen] = useState(false);
  const [termsData, setTermsData] = useState(null);
  const [openRescheduleModel, setOpenRescheduleModel] = useState(false);
  const [resecheduleData, setReseduleData] = useState(false);

  const handleRescheduleModelOpen = () => {
    setOpenRescheduleModel(true);
  };
  const handleCloseRescheduleModel = () => {
    setOpenRescheduleModel(false);
    setSelectedValue("");
    setTypeOfCancel("");
    setTypeOfAmount("");
    // setValue("cancelUntill", "");
    setNumOfDays("");
    // setValue("amount", "");
    setAmountNum("");
    handleClose();
    setEditRescheduleModal(false);
  };

  const [openReschedule, setOpenReschedule] = useState(false);
  // const [open, setOpen] = useState(false);
  const [openDeleteConformModal, setOpenDeleteConformModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editRescheduleModal, setEditRescheduleModal] = useState(false);
  const [deleteForTermsAndWarrenty, setDeleteForTermsAndWarrenty] =
    useState(false);
  const [paidEstimateChecked, setPaidEstimateChecked] = useState(false);

  const [selectedValue, setSelectedValue] = useState("");
  const [typeOfCancel, setTypeOfCancel] = useState("");
  const [typeOfAmount, setTypeOfAmount] = useState("");
  const [Ids, setIds] = useState(null);
  const [basciDetailsCancel, setBasciDetailsCancel] = useState(false);
  const [shopPriceCancel, setShopPriceCancel] = useState(false);

  const [shopImageCancel, setShopImageCancel] = useState(false);
  const [imageOpenModalShows, setImageOpenModalShows] = useState(false);
  const [imageView, setImageView] = useState("");
  const [amountNum, setAmountNum] = useState("");
  const [resultData, setResultData] = useState(null);
  const [resultDataWarrenty, setResultDataWarrenty] = useState(null);
  const [policies, setPolicies] = useState([]);
  const [certificationBtn, setCertificationBtns] = useState({
    cancel: 0,
    submit: 0,
  });
  const [shopImgBtn, setShopImgBtn] = useState({
    cancel: 0,
    submit: 0,
  });
  const [aminitiesBtn, setAminitiesBtn] = useState({
    cancel: 0,
    submit: 0,
  });
  const [estimateFeeChecked, setEstimateFeeChecked] = useState(false);

  const [estimateFeeCancel, setEstimateFeeCancel] = useState(false);
  const [afterEstimateFee, setAfterEstimateFee] = useState("");
  const [aminitiesCancel, setAminitiesCancel] = useState(false);
  const [editTermsModalOpen, setEditTermsModalOpen] = useState(false);
  const [editRescheduleModalOpen, setEditRescheduleModalOpen] = useState(false);
  const [completeAddress, setCompleteAddress] = useState({});
  const [placeId, setPlaceId] = useState("");

  const [imageLinks, setImageLinks] = useState({
    0: { imageLink: "", imageFile: "", event: "" },
    2: { imageLink: "", imageFile: "", event: "" },
    1: { imageLink: "", imageFile: "", event: "" },
    3: { imageLink: "", imageFile: "", event: "" },
  });
  const [aftersubmitImage, setAfterSubmitImage] = useState({
    0: { imageLink: "", imageFile: "", event: "" },
    2: { imageLink: "", imageFile: "", event: "" },
    1: { imageLink: "", imageFile: "", event: "" },
    3: { imageLink: "", imageFile: "", event: "" },
  });
  const [certificationData, setCertificationData] = useState({
    "310b": "",
    "310d": "",
    "310q": "",
    "310s": "",
  });
  const [aftersubmissionData, setAftersubmissionData] = useState({
    "310b": "",
    "310d": "",
    "310q": "",
    "310s": "",
  });
  const [numOfDays, setNumOfDays] = useState(0);
  const [aminities, setAminities] = useState([]);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [deleteRescheduledPolicy, setDeleteRescheduledPolicy] = useState(false);
  const [defaultPriceDetails, setDefaultPriceDetails] = useState("");
  const [aftersubmitAmenities, setAftersubmitAmenities] = useState([]);
  useEffect(() => {
    setPolicies(BasicDetails?.cancellationPolicy);
    setAddressValue({
      ...addressValues,
      city: BasicDetails?.city?.city,
      cityId: BasicDetails?.city?._id,
      province: BasicDetails?.province?.province,
      provinceId: BasicDetails?.province?._id,
      countryId: BasicDetails?.country?._id,
    });
  }, [BasicDetails]);

  useEffect(() => {
    setPolicies(getPolicyApiData);
  }, [getPolicyApiData]);

  useEffect(() => {
    console.log("completeAddress", completeAddress);
    if (Object.keys(completeAddress).length > 0) {
      const country = completeAddress.address_components.filter(
        (country) => country.types[0] == "country"
      );
      const postalCode = completeAddress.address_components.filter(
        (postal) => postal.types[0] == "postal_code"
      );
      const provinceName = completeAddress.address_components.filter(
        (data) => data.types[0] == "administrative_area_level_1"
      );
      const cityName = completeAddress.address_components.filter(
        (data) => data.types[0] == "locality"
      );
      const route = completeAddress.address_components.filter(
        (data) => data.types[0] == "route"
      );

      const streetNumber = completeAddress.address_components.filter(
        (data) => data.types[0] == "street_number"
      );

      let completeStreetAddress = "";
      if (streetNumber.length != 0) {
        completeStreetAddress += streetNumber[0]?.long_name;
      }
      if (route.length != 0) {
        completeStreetAddress += " " + route[0]?.long_name;
      }
      setValue("city", cityName?.[0]?.long_name);
      setValue("province", provinceName?.[0]?.long_name);
      setValue("postalCode", postalCode?.[0]?.long_name);
      setValue("country", country?.[0]?.long_name);
      setValue("address", completeStreetAddress);
      let foundCity = cityList.find((d) => d.city == cityName?.[0]?.long_name);
      setAddressValue({
        ...addressValues,
        city: foundCity ? foundCity.city : "",
        cityId: foundCity ? foundCity._id : "",
        province: foundCity ? foundCity.province?.province : "",
        provinceId: foundCity ? foundCity.province?._id : "",
        countryId: foundCity?.country?._id,
      });
      console.log("foundCity", cityList, foundCity);
    } else {
      setValue("city", "");
      setValue("province", "");
      setValue("postalCode", "");
      setValue("country", "");
      setValue("address", "");
      setAddressValue({
        street_number: "",
        city: "",
        province: "",
        postal: "",
        country: "",
        provinceId: "",
        cityId: "",
        countryId: "",
      });
    }
  }, [completeAddress]);

  useEffect(() => {
    const initialAmenitiesState = [];
    getAminitiesApiData?.forEach((amenity, index) => {
      initialAmenitiesState.push({
        [`amenities[${index}]`]: BasicDetails?.amenities?.find(
          (items) => items._id == amenity._id
        )
          ? true
          : false,
        [`id[${index}]`]: amenity._id,
      });
      // initialAmenitiesState[`id[${index}]`] = amenity._id;
    });
    setAminities(initialAmenitiesState);
    // setAftersubmitAmenities(initialAmenitiesState);
  }, [getAminitiesApiData]);

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const {
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getShopDetails();
    setVal();
  }, []);

  const setVal = () => {
    getAminitiesApiData?.forEach((item, index) => {
      // });

      //    getAminitiesApiData?.map((item, index) => {
      BasicDetails?.amenities?.find((items) => items._id == item?._id)
        ? (aminities[`amenities[${index}]`] = item._id)
        : (aminities[`amenities[${index}]`] = "");
    });
  };
  const [getAddress, setAddress] = useState();
  console.log();
  useEffect(() => {
    setValue("shopName", BasicDetails?.shopName);
    setValue("country", BasicDetails?.country?.country);
    setValue("address", BasicDetails?.address);
    setAddress(BasicDetails?.address);
    setValue("city", BasicDetails?.city?.city);
    setValue("postalCode", BasicDetails?.postalCode);
    setValue("province", BasicDetails?.province?.province);
    setValue(
      "businessRegistrationNumber",
      BasicDetails?.businessRegistrationNumber
    );

    setValue("numberOfEmployes", BasicDetails?.numberOfEmployes);
    setValue("defaultHourlyRate", shopPriceDetails);
    setDefaultPriceDetails(shopPriceDetails);
    return () => {
      setBasciDetailsCancel(false);
    };
  }, [BasicDetails, basciDetailsCancel, shopPriceDetails]);

  useEffect(() => {
    if (shopImgBtn.submit == 0)
      setImageLinks({
        0: { imageLink: "", imageFile: "", event: "" },
        2: { imageLink: "", imageFile: "", event: "" },
        1: { imageLink: "", imageFile: "", event: "" },
        3: { imageLink: "", imageFile: "", event: "" },
      });
    else {
      const newData = { ...aftersubmitImage };
      newData[0].imageLink = aftersubmitImage[0].imageLink;
      newData[1].imageLink = aftersubmitImage[1].imageLink;
      newData[2].imageLink = aftersubmitImage[2].imageLink;
      newData[3].imageLink = aftersubmitImage[3].imageLink;

      setImageLinks(newData);
      // setImageLinks({
      //   0: {
      //     imageLink: BasicDetails.images[0].url,
      //     imageFile: "",
      //     event: "",
      //   },
      //   2: {
      //     imageLink: BasicDetails.images[1].url,
      //     imageFile: "",
      //     event: "",
      //   },
      //   1: {
      //     imageLink: BasicDetails.images[2].url,
      //     imageFile: "",
      //     event: "",
      //   },
      //   3: {
      //     imageLink: BasicDetails.images[3].url,
      //     imageFile: "",
      //     event: "",
      //   },
      // });
    }
    return () => {
      setShopImageCancel(false);
    };
  }, [shopImageCancel]);

  useEffect(() => {
    if (certificationBtn.submit == 0)
      setCertificationData({
        "310b": "",
        "310d": "",
        "310q": "",
        "310s": "",
      });
    else {
      setCertificationData({
        "310b": aftersubmissionData["310b"],
        "310d": aftersubmissionData["310d"],
        "310q": aftersubmissionData["310q"],
        "310s": aftersubmissionData["310s"],
      });
    }
  }, [certificationBtn.cancel]);

  useEffect(() => {
    if (aminitiesBtn.submit == 0) {
      const initialAmenitiesState = [];
      getAminitiesApiData?.forEach((amenity, index) => {
        initialAmenitiesState.push({
          [`amenities[${index}]`]: BasicDetails?.amenities?.find(
            (items) => items._id == amenity._id
          )
            ? true
            : false,
          [`id[${index}]`]: amenity._id,
        });
        // initialAmenitiesState[`id[${index}]`] = amenity._id;
      });
      setAminities(initialAmenitiesState);
    } else {
      const newArr = [...aftersubmitAmenities];
      setAminities(newArr);
    }
    return () => setAminitiesCancel(false);
  }, [aminitiesCancel]);

  useEffect(() => {
    if (afterEstimateFee != "") {
      setValue("estimateFees", afterEstimateFee);
    } else {
      setValue("estimateFees", BasicDetails?.estimateFees);
    }
    return () => {
      setEstimateFeeCancel(false);
    };
  }, [BasicDetails, estimateFeeCancel]);

  const getShopDetails = async () => {
    setShowLoader(true);
    const data = await dispatch(shopDetails());
    if (data) {
      setEstimateFeeChecked(data?.allowEstimateRequest);
      setPaidEstimateChecked(data?.isEstimatePaid);
      getAminities();
      getShopPrice();
      setShowLoader(false);
    }
  };
  const getShopPrice = async () => {
    await dispatch(getShopPriceDetails());
  };
  const onSubmitShopDetails = async (values) => {
    setShowLoader(true);
    delete values["defaultHourlyRate"];
    let payload = {
      ...values,

      shopPlaceDetailObj: JSON.stringify({
        address_components: completeAddress?.address_components,
        formatted_address: completeAddress?.formatted_address,
        geometry: {
          location: {
            lat: completeAddress.geometry?.location?.lat(),
            lng: completeAddress.geometry?.location?.lng(),
          },
          viewport: {
            south: completeAddress.geometry?.viewport?.getSouthWest().lat(),
            west: completeAddress.geometry?.viewport?.getSouthWest().lng(),
            north: completeAddress.geometry?.viewport?.getNorthEast().lat(),
            east: completeAddress.geometry?.viewport?.getNorthEast().lng(),
          },
        },
      }),
      addressLatLong: JSON.stringify({
        latitude: completeAddress.geometry?.location?.lat(),
        longitude: completeAddress.geometry?.location?.lng(),
      }),
      placeId: placeId,
      city: addressValues?.cityId,
      province: addressValues?.provinceId,
      country: addressValues?.countryId,
    };

    delete payload.estimateFees;

    if (placeId) {
    } else {
      payload.placeId = BasicDetails?.placeId;
      payload.addressLatLong = JSON.stringify({
        latitude: BasicDetails?.addressLatLong?.coordinates[1],
        longitude: BasicDetails?.addressLatLong?.coordinates[0],
      });
      payload.shopPlaceDetailObj = JSON.stringify(
        BasicDetails?.shopPlaceDetailObj
      );
    }

    let result = await dispatch(updateShopDetails(payload));
    if (result) {
      // getShopDetails();
      setOpenSuccessModal(true);

      setSuccessMessage("Changes Saved");
      const localItems = { ...getItem("apex-saas_details") };
      localItems.shopName = result?.shopName;
      setItem("apex-saas_details", localItems);
      window.dispatchEvent(new Event("localStorageChangeShopName"));
    }
    setShowLoader(false);
  };
  const onSubmitShopServicePricing = async (values) => {
    let payload = {
      defaultHourlyRate: parseInt(
        ("" + values?.defaultHourlyRate)?.replace(/,/g, "")
      ),
    };
    setShowLoader(true);
    let result = await dispatch(updateShopPriceDetails(payload));
    if (result) {
      setOpenSuccessModal(true);
      setSuccessMessage("Changes Saved");
      setDefaultPriceDetails(result.defaultHourlyRate);
      // getShopDetails();
      // setOpenSuccessModal(true);
      // setSuccessMessage("Changes Saved");
      // const localItems = { ...getItem("apex-saas_details") };
      // localItems.shopName = result?.shopName;
      // setItem("apex-saas_details", localItems);
    }
    setShowLoader(false);
  };
  const onSubmitCarfaxIntegration = async (values) => {};
  const manageImageUrl = (event, index) => {
    // if (aftersubmitImage[index]?.imageLink && shopImgBtn.submit > 0) {
    //   const newImageLinks = { ...aftersubmitImage };
    //   newImageLinks[index].imageLink = URL.createObjectURL(
    //     event.target.files[0]
    //   );
    //   newImageLinks[index].imageFile = event.target.files[0];
    //   newImageLinks[index].event = event.target.value;
    //   newImageLinks[index].noOfTimes = 1;
    //   setAfterSubmitImage(newImageLinks);
    // } else {
    const newImageLinks = { ...imageLinks };
    newImageLinks[index].imageLink = URL.createObjectURL(event.target.files[0]);
    newImageLinks[index].imageFile = event.target.files[0];
    newImageLinks[index].event = event.target.value;

    setImageLinks(newImageLinks);
    // }
  };

  const shopImagesSubmit = async (value) => {
    setShowLoader(true);
    let formdata = new FormData();

    const givenImages = BasicDetails?.images;

    formdata.append(
      "images[0].image",
      // aftersubmitImage[0].imageFile != ""
      //   ? aftersubmitImage[0].imageFile
      //   :
      imageLinks[0].imageFile != ""
        ? imageLinks[0].imageFile
        : givenImages[0].url
    );
    formdata.append(
      "images[1].image",
      // aftersubmitImage[1].imageFile != ""
      //   ? aftersubmitImage[1].imageFile
      //   :
      imageLinks[1].imageFile != ""
        ? imageLinks[1].imageFile
        : givenImages[1].url
    );
    formdata.append(
      "images[2].image",
      // aftersubmitImage[2].imageFile != ""
      //   ? aftersubmitImage[2].imageFile
      //   :
      imageLinks[2].imageFile != ""
        ? imageLinks[2].imageFile
        : givenImages[2].url
    );
    formdata.append(
      "images[3].image",
      // aftersubmitImage[3].imageFile != ""
      //   ? aftersubmitImage[3].imageFile
      //   :
      imageLinks[3].imageFile != ""
        ? imageLinks[3].imageFile
        : givenImages[3].url
    );
    formdata.append("images[0].value", "primary");
    formdata.append("images[1].value", "sub");
    formdata.append("images[2].value", "sub");
    formdata.append("images[3].value", "sub");

    let result = await dispatch(updateShopImages(formdata));

    if (result) {
      result = result.images;
      // const newdata = { ...aftersubmitImage };
      // for (let i = 0; i < 4; i++) {
      // newdata[i].imageLink = result[i].url;
      // aftersubmitImage[i].noOfTimes = 0;
      // }

      // setAfterSubmitImage(newdata);

      let updatedVal = { ...shopImgBtn };
      updatedVal.submit = ++updatedVal.submit;
      setShopImgBtn(updatedVal);

      const newdata = { ...imageLinks };
      for (let i = 0; i < 4; i++) {
        newdata[i].imageLink = result[i].url;
      }

      setImageLinks(newdata);
      setAfterSubmitImage(newdata);
    }
    setShowLoader(false);
  };

  const handleChangeCertificate = (certification, value) => {
    setCertificationData({
      ...certificationData,
      [certification]: value,
    });
  };

  const certificateSubmit = async (values) => {
    setShowLoader(true);
    const payload = {
      "certificates[0][type]": "certificate_310B",
      "certificates[0][haveCertificates]": certificationData["310b"] == "Yes",
      "certificates[1][type]": "certificate_310D",
      "certificates[1][haveCertificates]": certificationData["310d"] == "Yes",
      "certificates[2][type]": "certificate_310Q",
      "certificates[2][haveCertificates]": certificationData["310q"] == "Yes",
      "certificates[3][type]": "certificate_310S",
      "certificates[3][haveCertificates]": certificationData["310s"] == "Yes",
    };

    let result = await dispatch(updateShopCertificates(payload));
    if (result) {
      setOpenSuccessModal(true);

      setSuccessMessage("Changes Saved");
      let updatedVal = { ...certificationBtn };
      updatedVal.submit = ++updatedVal.submit;
      setCertificationBtns(updatedVal);
      setAftersubmissionData({
        "310b":
          payload["certificates[0][haveCertificates]"] == true ? "Yes" : "No",
        "310d":
          payload["certificates[1][haveCertificates]"] == true ? "Yes" : "No",
        "310q":
          payload["certificates[2][haveCertificates]"] == true ? "Yes" : "No",
        "310s":
          payload["certificates[3][haveCertificates]"] == true ? "Yes" : "No",
      });
    }
    setShowLoader(false);
  };

  const onAddNewPolicy = async (values) => {
    const payload = {
      amount: amountNum,
      amountType: typeOfAmount,
      cancelUntill: numOfDays,
      cancelUntillType: selectedValue,
      cancellationType: typeOfCancel,
    };
    if (typeOfCancel == "no_show") {
      delete payload.cancelUntill;
      delete payload.cancelUntillType;
    }

    setShowLoader(true);
    let result;
    let resultArray;

    if (resultData == null) {
      resultArray = [...policies];
    } else {
      resultArray = [...resultData];
    }
    if (editModal) {
      result = await dispatch(updateCancellationPolicy(payload, Ids));
      if (result) {
        const index = resultArray.findIndex((policy) => policy._id === Ids);
        if (index !== -1) {
          const updatedPolicies = [...resultArray];
          updatedPolicies[index] = result;
          setResultData(updatedPolicies);
        } else {
          console.log("Policy not found for update. Ids:", Ids);
        }
      }
    } else {
      result = await dispatch(addCancellationPolicy(payload));
      if (result) {
        setResultData([...resultArray, result]);
      } else {
        handleCloseModal(false);
      }
    }

    setShowLoader(false);

    if (result) {
      setSelectedValue("");
      setTypeOfCancel("");
      setTypeOfAmount("");
      setNumOfDays(0);
      setAmountNum("");
      handleClose();
      setEditModal(false);
    }
  };

  const handleCloseModal = () => {
    setSelectedValue("");
    setTypeOfCancel("");
    setTypeOfAmount("");
    setValue("cancelUntill", "");
    setNumOfDays(0);
    setValue("amount", "");
    setAmountNum("");
    handleClose();
    setEditModal(false);
  };

  const EditManage = (item) => {
    setIds(item._id);
    // setValue("cancelUntill", item.cancelUntill);

    // setValue("amount", item.amount);
    setNumOfDays(item.cancelUntill);
    setAmountNum(item.amount);
    setSelectedValue(item.cancelUntillType);
    setSelectedValue(item.cancelUntillType);
    setTypeOfCancel(item.cancellationType);
    setTypeOfAmount(item.amountType);
  };
  const EditManageRescheduling = (item) => {
    setIds(item?._id);
    // setValue("cancelUntill", item.cancelUntill);

    // setValue("amount", item.amount);
    setAmountNum(item?.amount);
    setNumOfDays(item?.rescheduleUntill);

    setTypeOfCancel(item?.rescheduleUntillType);
    setSelectedValue(item?.rescheduleUntillType);
    // setTypeOfAmount(item.rescheduleUntillType);
  };

  const deleteCancellationPolicies = async () => {
    setShowLoader(true);
    let resultArray;
    if (resultData == null) {
      resultArray = [...policies];
    } else {
      resultArray = [...resultData];
    }

    try {
      let result = await dispatch(deleteCancellationPolicy(Ids));

      if (result) {
        const filteredPeople = resultArray.filter((item) => item._id !== Ids);
        setResultData(filteredPeople);
      } else {
        console.log("eror in delete policy");
      }
    } catch (error) {
      // getCancellationPolicies(); // You may uncomment this line if needed

      console.error("Error during deletion", error);
    } finally {
      setShowLoader(false); // Ensure that loader is hidden whether the operation succeeds or fails
      handleCloseModal();
    }
  };

  const onSubmitEstimateFee = async (values) => {
    setShowLoader(true);
    const payload = {
      estimateFees: values.estimateFees,
      allowEstimateRequest: estimateFeeChecked,
      isEstimatePaid: paidEstimateChecked,
    };
    if (!estimateFeeChecked) {
      delete payload.isEstimatePaid;
      delete payload.estimateFees;
    }

    let result = await dispatch(updateEstimatesFee(payload));
    if (result) {
      setOpenSuccessModal(true);

      setSuccessMessage("Changes Saved");
      setAfterEstimateFee(payload.estimateFees);
    }
    setShowLoader(false);
  };
  const [allowApiCallTerms, setallowApiCallTerms] = useState(true);
  const onSubmitTermsHandler = async (values) => {
    if (allowApiCallTerms) {
      setallowApiCallTerms(false);
      const payload = {
        title: values.title,
        description: values.description,
      };
      let data;
      let termsArray;

      if (termsData == null) {
        termsArray = [...getTermsSpecificApiData];
      } else {
        termsArray = [...termsData];
      }
      if (Ids) {
        data = await dispatch(updateTermsAndWarrenty(payload, Ids));
        setTermsData(data?.termsAndWarranty);
      } else {
        data = await dispatch(addTermsAndWarrenty(payload));

        if (data) {
          setTermsData(data?.termsAndWarranty);
        }
      }

      if (data) {
        setValue("title", "");
        setValue("description", "");
        setIds(null);
        handleTermsClose();
      }
    }
  };

  const EditTermsAndWarrenty = (item) => {
    setIds(item._id);
    setValue("title", item.title);
    setValue("description", item.description);
  };
  const deleteCancellationTermsAndWarrenty = async () => {
    let termsArray;
    if (termsData == null) {
      termsArray = [...getTermsSpecificApiData];
    } else {
      termsArray = [...termsData];
    }
    setShowLoader(true);
    let result = await dispatch(deleteTermsAndWarrenty(Ids));

    if (result) {
      const filteredPeople = termsArray.filter((item) => item._id !== Ids);
      setTermsData(filteredPeople);
    } else {
      console.log("error in delete policy");
    }
    setShowLoader(false);
  };
  const getCancelReschedulepolicy = async () => {
    setShowLoader(true);
    try {
      let result = await dispatch(deleteReschedulingPolicy(Ids));
      setShowLoader(false);
      handleCloseModal();
    } catch (error) {
      console.log("error", error);
    }

    setShowLoader(false);
  };

  const getAminities = async () => {
    let result = await dispatch(getAminitiy());
  };

  const handleChangeAminities = (event, index) => {
    const updatedData = [...aminities];

    updatedData[index][`amenities[${index}]`] =
      !updatedData[index][`amenities[${index}]`];
    setAminities(updatedData);
  };

  const handleSubmitAminities = async (values) => {
    const payload = {};
    setShowLoader(true);
    for (let i = 0; i < aminities.length; i++) {
      if (aminities[i][`amenities[${i}]`] == true) {
        payload[`amenities[${i}]`] = aminities[i][`id[${i}]`];
      }
    }

    let res = await dispatch(updateAminities(payload));

    if (res) {
      setOpenSuccessModal(true);

      setSuccessMessage("Changes Saved");
      let updatedVal = { ...aminitiesBtn };
      updatedVal.submit = ++updatedVal.submit;
      setAminitiesBtn(updatedVal);
      let newArr = [...aminities];
      // const newArr = JSON.parse(JSON.stringify(aminities));

      setAftersubmitAmenities(newArr);
    }
    setShowLoader(false);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleTermsClose = () => {
    setOpenTerms(false);
    setIds("");
    setallowApiCallTerms(true);
  };

  const handleOpenTerms = () => setOpenTerms(true);

  const validateNonNegative = (value) => {
    if (value < 0) {
      return "Number must not be negative";
    }
    return true;
  };
  const [openCancelSubscriptionModal, setOpenCancelSubscriptionModal] =
    useState(false);
  const requestedPhotos = () => {
    setOpenCancelSubscriptionModal(true);
  };
  const CarfaxToggleSwitch = styled((props) => (
    <Switch
      // disabled={disabledSwitch}
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
      // defaultChecked={statusValue}
      // onChange={(e) => updateAppointmentStatus(e.target.checked)}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#d32f2f",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor:
        theme.palette.mode === "light"
          ? "rgba(120, 120, 128, 0.16)"
          : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  return (
    <>
      <Box>
        {/* basic details section start */}
        <form
          onSubmit={handleSubmit(onSubmitShopDetails)}
          style={{ marginTop: "20px" }}
        >
          <Grid
            container
            sx={{ boxShadow: 3, bgcolor: "white" }}
            justifyContent={"start"}
            // alignItems={"center"}
            marginTop={"32px"}
            padding={"24px"}
            borderRadius={"10px"}
            // marginTop={"20px"}
          >
            <Grid item xs={12} sm={12} md={3} xl={3}>
              <Typography variant="h5">Basic Details</Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={9}
              xl={9}
              spacing={2}
              className="Basic-details-wrapper"
              padding={"10px"}
            >
              <Grid item xs={12} sm={6} md={6} xl={6} gap={3}>
                <Typography variant="body1" className="label-500">
                  Shop Name <span style={{ color: REQUIRED_RED }}>*</span>
                </Typography>
                <Controller
                  name="shopName"
                  control={control}
                  rules={{
                    required: "Shop Name is required",
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="outlined-basic"
                      // label="Shop Name"
                      variant="outlined"
                      fullWidth
                      error={!!errors.shopName}
                      helperText={
                        errors.shopName ? errors.shopName.message : ""
                      }
                      InputLabelProps={{ shrink: field.value?.length > 0 }}
                    />
                  )}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6} md={12} xl={12}>
                <Typography variant="body1" className="label-500">
                  Country <span style={{ color: REQUIRED_RED }}>*</span>
                </Typography>
                <Controller
                  name="country"
                  control={control}
                  rules={{
                    required: "Country is required",
                  }}
                  render={({ field }) => (
                    <TextField
                      id="outlined-basic"
                      // label="Country"
                      variant="outlined"
                      disabled
                      fullWidth
                      required
                      error={!!errors.country}
                      helperText={errors.country ? errors.country.message : ""}
                      {...field}
                      InputLabelProps={{ shrink: field.value?.length > 0 }}
                    />
                  )}
                />
              </Grid> */}
              <Grid item xs={12} sm={6} md={12} xl={12}>
                <Typography variant="body1" className="label-500">
                  Street Address <span style={{ color: REQUIRED_RED }}>*</span>
                </Typography>

                <Controller
                  name="address"
                  control={control}
                  rules={{
                    required: "Street Address is required",
                  }}
                  render={({ field }) => (
                    <GoogleAutoComplete
                      setCompleteAddress={setCompleteAddress}
                      setPlaceId={setPlaceId}
                      getAddress={getAddress}
                      required
                      error={!!errors.streetAddress}
                      helperText={
                        errors.streetAddress ? errors.streetAddress.message : ""
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid container item xs={12} sm={12} md={12} xl={12} spacing={2}>
                <Grid item xs={12} sm={4} md={4} xl={4}>
                  <Typography variant="body1" className="label-500">
                    City
                  </Typography>
                  <Controller
                    name="city"
                    control={control}
                    rules={{
                      required: "City is required",
                    }}
                    render={({ field }) => (
                      // <TextField
                      //   id="outlined-basic"
                      //   disabled
                      //   /*label=""*/
                      //   variant="outlined"
                      //   required
                      //   fullWidth
                      //   error={!!errors.city}
                      //   helperText={errors.city ? errors.city.message : ""}
                      //   {...field}
                      //   InputLabelProps={{ shrink: field.value?.length > 0 }}
                      //   sx={{
                      //     "& .MuiOutlinedInput-root": {
                      //       "&.Mui-disabled fieldset": {
                      //         backgroundColor: "background.disabled_fields",
                      //         border: 0,
                      //       },
                      //       "& .MuiInputBase-input.Mui-disabled": {
                      //         WebkitTextFillColor: "black",
                      //       },
                      //     },
                      //   }}
                      // />
                      <AddressSelect
                        list={cityList}
                        addressValues={addressValues}
                        setAddressValue={setAddressValue}
                        type={"city"}
                        disable={true}
                        loader={cityListLoader}
                      />
                      // {error && error.city && (
                      //   <p
                      //     style={{
                      //       color: "#d32f2f",
                      //       fontSize: "0.75rem",
                      //       letterSpacing: "0.03333em",
                      //       lineHeight: "1.66",
                      //       marginTop: "3px",
                      //     }}
                      //   >
                      //     {error.city}
                      //   </p>
                      // )}
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={4} md={4} xl={4}>
                  <Typography variant="body1" className="label-500">
                    Province
                  </Typography>
                  <Controller
                    name="province"
                    control={control}
                    rules={{
                      required: "Postal Code is required",
                    }}
                    render={({ field }) => (
                      // <TextField
                      //   id="outlined-basic"
                      //   variant="outlined"
                      //   disabled
                      //   fullWidth
                      //   required
                      //   error={!!errors.postalCode}
                      //   helperText={
                      //     errors.postalCode ? errors.postalCode.message : ""
                      //   }
                      //   {...field}
                      //   InputLabelProps={{ shrink: field.value?.length > 0 }}
                      //   sx={{
                      //     "& .MuiOutlinedInput-root": {
                      //       "&.Mui-disabled fieldset": {
                      //         backgroundColor: "background.disabled_fields",
                      //         border: 0,
                      //       },
                      //       "& .MuiInputBase-input.Mui-disabled": {
                      //         WebkitTextFillColor: "black",
                      //       },
                      //     },
                      //   }}
                      // />
                      <AddressSelect
                        list={provinceList}
                        addressValues={addressValues}
                        setAddressValue={setAddressValue}
                        type={"province"}
                        disable={true}
                        loader={provinceListLoader}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4} xl={4}>
                  <Typography variant="body1" className="label-500">
                    Postal Code
                  </Typography>
                  <Controller
                    name="postalCode"
                    control={control}
                    rules={{
                      required: "Postal Code is required",
                    }}
                    render={({ field }) => (
                      <TextField
                        id="outline"
                        //label="Postal Code"
                        variant="outlined"
                        disabled
                        fullWidth
                        required
                        error={!!errors.postalCode}
                        helperText={
                          errors.postalCode ? errors.postalCode.message : ""
                        }
                        {...field}
                        InputLabelProps={{ shrink: field.value?.length > 0 }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-disabled fieldset": {
                              backgroundColor: "background.disabled_fields",
                              border: 0,
                            },
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "black",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Typography variant="body1" className="label-500">
                  Number of Employees{" "}
                  <span style={{ color: REQUIRED_RED }}>*</span>
                </Typography>
                <Controller
                  name="numberOfEmployes"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      fullWidth
                      error={!!errors.numberOfEmployes}
                      helperText={
                        errors.numberOfEmployes
                          ? errors.numberOfEmployes.message
                          : ""
                      }
                      {...field}
                      InputLabelProps={{ shrink: field.value?.length > 0 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Typography variant="body1" className="label-500">
                  Business Number (GST/HST Number){" "}
                  <span style={{ color: REQUIRED_RED }}>*</span>
                </Typography>
                <Controller
                  name="businessRegistrationNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      fullWidth
                      error={!!errors.businessRegistrationNumber}
                      helperText={
                        errors.businessRegistrationNumber
                          ? errors.businessRegistrationNumber.message
                          : ""
                      }
                      {...field}
                      InputLabelProps={{ shrink: field.value?.length > 0 }}
                    />
                  )}
                />
              </Grid>
              <Grid
                container
                justifyContent={"end"}
                spacing={2}
                style={{ marginTop: "20px" }}
              >
                <Grid item spacing={2}>
                  <Stack spacing={2} direction="row">
                    <Button
                      className="btn-text-size-manage"
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setBasciDetailsCancel(true);
                      }}
                    >
                      Cancel Changes
                    </Button>
                    <Button
                      className="btn-text-size-manage"
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Save Changes
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
        {/* <form
          onSubmit={handleSubmit(onSubmitCarfaxIntegration)}
          style={{ marginTop: "20px" }}
        >
          <Grid
            container
            sx={{ boxShadow: 3, bgcolor: "white" }}
            justifyContent={"start"}
            // alignItems={"center"}
            marginTop={"32px"}
            padding={"24px"}
            borderRadius={"10px"}
            // marginTop={"20px"}
          >
            <Grid item xs={12} sm={12} md={3} xl={3}>
              <Typography variant="h5">Integrations</Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={9}
              xl={9}
              spacing={2}
              // className="Basic-details-wrapper"
              // padding={"10px"}
            >
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Grid item xs={12} sm={12} md={12} xl={12} display={"flex"}>
                  <CarfaxToggleSwitch size="large" />
                  <Typography pl={"7px"}>CARFAX Integration</Typography>
                  <Tooltip
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "15px",
                    }}
                    title={
                      <div
                        style={{
                          fontWeight: 500,
                          fontSize: "10px",
                          width: "140px",
                        }}
                      >
                        Turning this integration on will allow you to see a
                        vehicle’s previous service history. However, RO data
                        will also be shared with Carfax
                      </div>
                    }
                  >
                    {" "}
                    <InfoIcon style={{ color: "#0000008F" }} />{" "}
                  </Tooltip>{" "}
                </Grid>
              </Grid>

              <Grid
                container
                justifyContent={"end"}
                spacing={2}
                style={{ marginTop: "20px" }}
              >
                <Grid item spacing={2}>
                  <Stack spacing={2} direction="row">
                    <Button
                      className="btn-text-size-manage"
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setShopPriceCancel(true);
                        setValue("defaultHourlyRate", defaultPriceDetails);
                      }}
                    >
                      Cancel Changes
                    </Button>
                    <Button
                      className="btn-text-size-manage"
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Save Changes
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form> */}
        {/* <ShopPricing
          setShowLoader={setShowLoader}
          setOpenSuccessModal={setOpenSuccessModal}
          setSuccessMessage={setSuccessMessage}
        /> */}
        <RequestedPhotos
          openCancelSubscriptionModal={openCancelSubscriptionModal}
          setOpenCancelSubscriptionModal={setOpenCancelSubscriptionModal}
        />
        <ImageShowModal
          setImageOpenModalShows={setImageOpenModalShows}
          imageOpenModalShows={imageOpenModalShows}
          setImageView={setImageView}
          imageView={imageView}
        />
        {/* <ShopImages
          requestedPhotos={requestedPhotos}
          BasicDetails={BasicDetails}
          imageLinks={imageLinks}
          setImageView={setImageView}
          setImageOpenModalShows={setImageOpenModalShows}
          manageImageUrl={manageImageUrl}
        /> */}
        <ImageUploader />
        {/* shop images section end */}
        {/* certifications section start */}{" "}
        <form
          onSubmit={handleSubmit(certificateSubmit)}
          style={{ marginTop: "20px" }}
        >
          <Grid
            container
            sx={{ boxShadow: 2, bgcolor: "white" }}
            justifyContent={"space-between"}
            padding={"24px"}
            borderRadius={"10px"}
            marginTop={"32px"}
          >
            <Grid item xs={12} sm={12} md={4} xl={3}>
              <Typography variant="h5">Certifications</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={8} xl={9} direction={"column"}>
              <Stack direction="row" spacing={0}>
                <FormControl
                  sx={{ m: 1, minWidth: 120 }}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  variant="standard"
                  name="310b"
                >
                  <FormLabel style={{ paddingRight: "60px" }}>
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      fontSize={"20px"}
                      color={"var(--text-primary, rgba(0, 0, 0, 0.87))"}
                    >
                      {" "}
                      Certification 310B
                    </Typography>
                    {/* Certification 310B */}
                  </FormLabel>

                  <RadioGroup
                    // aria-labelledby="demo-radio-buttons-group-label"
                    value={
                      certificationData["310b"] != ""
                        ? certificationData["310b"]
                        : BasicCertificatesDetails?.find(
                            (item) => item.type == "certificate_310B"
                          )?.haveCertificates
                        ? "Yes"
                        : "No"
                    }
                    name="radio-buttons-group"
                    row
                    style={{ display: "flex", flexDirection: "row" }}
                    onChange={(event) =>
                      handleChangeCertificate("310b", event.target.value)
                    }
                  >
                    <FormControlLabel
                      value={"Yes"}
                      control={<Radio />}
                      label="Yes"
                      style={{ paddingRight: "50px" }}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value={"No"}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Stack>
              <Stack direction="row" spacing={0}>
                <FormControl
                  sx={{ m: 1, minWidth: 120 }}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  variant="standard"
                  name="310d"
                >
                  <FormLabel
                    style={{ paddingRight: "60px", fontWeight: "bold" }}
                  >
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      fontSize={"20px"}
                      color={"var(--text-primary, rgba(0, 0, 0, 0.87))"}
                    >
                      {" "}
                      Certification 310D
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    // aria-labelledby="demo-radio-buttons-group-label"

                    value={
                      certificationData["310d"] != ""
                        ? certificationData["310d"]
                        : BasicCertificatesDetails?.find(
                            (item) => item.type == "certificate_310D"
                          )?.haveCertificates
                        ? "Yes"
                        : "No"
                    }
                    name="radio-buttons-group"
                    row
                    style={{ display: "flex", flexDirection: "row" }}
                    onChange={(event) =>
                      handleChangeCertificate("310d", event.target.value)
                    }
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                      style={{ paddingRight: "50px" }}
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Stack>
              <Stack direction="row" spacing={0}>
                <FormControl
                  sx={{ m: 1, minWidth: 120 }}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  variant="standard"
                  name="310q"
                >
                  <FormLabel
                    style={{ paddingRight: "60px", fontWeight: "bold" }}
                  >
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      fontSize={"20px"}
                      color={"var(--text-primary, rgba(0, 0, 0, 0.87))"}
                    >
                      {" "}
                      Certification 310Q
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    value={
                      certificationData["310q"] != ""
                        ? certificationData["310q"]
                        : BasicCertificatesDetails?.find(
                            (item) => item.type == "certificate_310Q"
                          )?.haveCertificates
                        ? "Yes"
                        : "No"
                    }
                    name="radio-buttons-group"
                    row
                    style={{ display: "flex", flexDirection: "row" }}
                    onChange={(event) =>
                      handleChangeCertificate("310q", event.target.value)
                    }
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                      style={{ paddingRight: "50px" }}
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Stack>
              <Stack direction="row" spacing={0}>
                <FormControl
                  sx={{ m: 1, minWidth: 120 }}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  variant="standard"
                  name="310s"
                >
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{ paddingRight: "60px", fontWeight: "bold" }}
                  >
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      fontSize={"20px"}
                      color={"var(--text-primary, rgba(0, 0, 0, 0.87))"}
                    >
                      {" "}
                      Certification 310S
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    value={
                      certificationData["310s"] != ""
                        ? certificationData["310s"]
                        : BasicCertificatesDetails?.find(
                            (item) => item.type == "certificate_310S"
                          )?.haveCertificates
                        ? "Yes"
                        : "No"
                    }
                    name="radio-buttons-group"
                    row
                    style={{ display: "flex", flexDirection: "row" }}
                    onChange={(event) =>
                      handleChangeCertificate("310s", event.target.value)
                    }
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                      style={{ paddingRight: "50px" }}
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Stack>
            </Grid>
            <Grid
              container
              justifyContent={"end"}
              spacing={2}
              style={{ marginTop: "20px" }}
            >
              <Grid item spacing={2} padding={"10px"}>
                <Stack spacing={2} direction="row">
                  <Button
                    className="btn-text-size-manage"
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      // setCertificationBtns(true);

                      let updatedVal = { ...certificationBtn };
                      updatedVal.cancel = ++updatedVal.cancel;
                      setCertificationBtns(updatedVal);
                    }}
                  >
                    Cancel Changes
                  </Button>
                  <Button
                    className="btn-text-size-manage"
                    variant="contained"
                    color="primary"
                    // onClick={certificateSubmit}
                    type="submit"
                  >
                    Save Changes
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </form>
        {/* certifications section end */}
        {/* amenities section start */}
        <Grid
          container
          sx={{ boxShadow: 3, bgcolor: "white" }}
          justifyContent={"space-between"}
          padding={"24px"}
          borderRadius={"10px"}
          marginTop={"32px"}
        >
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Typography variant="h5">Amenities</Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={9}
            lg={9}
            xl={9}
            direction={"row"}
            padding={"16px"}
            // spacing={4}
          >
            <FormGroup row>
              {getAminitiesApiData?.map((item, index) => {
                return (
                  <Grid
                    lg={3}
                    style={{
                      padding: "20px",
                      paddingTop: 0,
                    }}
                  >
                    <FormControlLabel
                      key={index}
                      style={{ width: "max-content" }}
                      control={
                        <Checkbox
                          name={`amenities[${index}]`}
                          onChange={(e) => {
                            handleChangeAminities(e, index);
                            handleSubmitAminities();
                          }}
                          key={index}
                          checked={
                            aminities.length > 0 &&
                            aminities[index] &&
                            aminities[index][`amenities[${index}]`]
                          }
                        />
                      }
                      label={item?.name}
                    />{" "}
                  </Grid>
                );
              })}
            </FormGroup>
          </Grid>
        </Grid>
        {/* Hide cancellatoion policy for production only */}
        {/* <Grid
          container
          sx={{ boxShadow: 2, bgcolor: "white" }}
          justifyContent={"space-between"}
          padding={"24px"}
          borderRadius={"10px"}
          marginTop={"32px"}
          paddingRight={"20px"}
        >
          <Grid
            item
            container
            xs={12}
            sm={6}
            md={3}
            lg={3}
            xl={3}
            paddingRight={"10px"}
            style={{ height: "max-content" }}
          >
            <Typography variant="h5" style={{ fontWeight: 400 }}>
              Cancellation Policy
            </Typography>
            <Typography
              variant="body"
              component={"div"}
              style={{ marginTop: "20px" }}
            >
              To minimize no-shows, you have the option to implement a
              cancellation or a no-show policy for customers. You can set a
              specific minimum notice period when customers need to cancel to
              avoid incurring a fee.
            </Typography>
            <Typography variant="body" style={{ marginTop: "20px" }}>
              While we recommend that customers try to avoid last-minute
              cancellations and no-shows, implementing a cancellation policy is
              completely optional. You can continue to use Apex Mechanic without
              imposing any charges on customers for cancellations or no-shows.
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={8.5}
            lg={8.5}
            xl={8.5}
            direction={"row"}
            // spacing={4}
            rowSpacing={4}
            // columnGap={2}
            justifyContent={"end"}
            padding={"10px"}
            className="cancel-policy-listing-grid"
          >
            <Grid item className="fit-height-style">
              <Stack
                justifyContent={"end"}
                spacing={2}
                className="align-item-end"
              >
                <Button
                  className="btn-text-size-manage"
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setEditModal(false);
                    handleOpen();
                  }}
                >
                  ADD NEW POLICY
                </Button>

                <Box>
                  <Modal
                    open={open}
                    onClose={handleCloseModal}
                    aria-labelledby="parent-modal-title"
                    aria-describedby="parent-modal-description"
                  >
                    <Box sx={{ ...style, width: "620px" }}>
                      <AppBar
                        variant="elevation"
                        color="secondary"
                        sx={{
                          height: "64px",
                          paddingRight: "0px !important",
                        }}
                      >
                        <Toolbar variant="regular">
                          <Typography
                            variant="body1"
                            gutterBottom={false}
                            width={"98%"}
                            color={"white"}
                            fontSize={"20px"}
                            lineHeight={"32px"}
                          >
                            {editModal ? "Edit Policy" : "Add New Policy"}
                          </Typography>
                          <Stack direction={"row"} spacing={0}>
                            <div
                              onClick={() => {
                                handleClose();
                                setSelectedValue("");
                                setTypeOfCancel("");
                                setTypeOfAmount("");
                                // setValue("cancelUntill", "");
                                setNumOfDays(0);

                                // setValue("amount", "");
                                setAmountNum("");
                              }}
                              style={{ cursor: "pointer", height: "32px" }}
                            >
                              <CloseIcon style={{ fontSize: 32 }} />
                            </div>
                          </Stack>
                        </Toolbar>
                      </AppBar>
                      <Container
                        gap={2}
                        disableGutters
                        style={{
                          scrollbarColor: "blue",
                          scrollbarWidth: "2px",
                          overflowY: "auto",
                          marginTop: "60px",
                          paddingRight: "14px",
                        }}
                      >
                        <form onSubmit={handleSubmit(onAddNewPolicy)}>
                          <Grid container maxHeight={"75vh"}>
                            <Grid item xs={12} md={6} xl={12} sm={12} lg={12}>
                              <Stack
                                direction={"column"}
                                spacing={1}
                                rowGap={2}
                                marginTop={"20px"}
                                // maxHeight={"75vh"}
                              >
                                <Stack spacing={2}>
                                  <Typography
                                    variant="body1"
                                    style={{
                                      fontWeight: 400,
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                    }}
                                  >
                                    Type of Cancellation{" "}
                                    <span style={{ color: "#D32F2F" }}>*</span>
                                  </Typography>

                                  <FormControl
                                    sx={{ m: 1, minWidth: 120 }}
                                    style={{ width: "100%" }}
                                  >
                                    <Select
                                      value={typeOfCancel}
                                      onChange={(event) => {
                                        setTypeOfCancel(event.target.value);
                                      }}
                                      displayEmpty
                                      inputProps={{
                                        "aria-label": "Without label",
                                      }}
                                      placeholder="type"
                                    >
                                      <MenuItem value={"standard"}>
                                        Standard
                                      </MenuItem>
                                      <MenuItem value={"no_show"}>
                                        No show
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Stack>
                              </Stack>
                            </Grid>
                            {typeOfCancel != "no_show" && (
                              <Grid
                                item
                                xs={12}
                                md={6}
                                xl={12}
                                sm={12}
                                lg={12}
                                style={{ marginTop: "20px" }}
                              >
                                <Typography
                                  variant="body1"
                                  style={{
                                    fontWeight: 400,
                                    fontSize: "16px",
                                    lineHeight: "24px",
                                  }}
                                >
                                  Customer’s Can Cancel Until...
                                  <span style={{ color: "#D32F2F" }}>*</span>
                                </Typography>
                                <Stack
                                  direction={"row"}
                                  spacing={2}
                                  rowGap={2}
                                  marginTop={"20px"}
                                >
                                  <Grid item xs={6} md={6} xl={6} sm={6} lg={6}>
                                    <Controller
                                      name="cancelUntill"
                                      control={control}
                                      // rules={{
                                      //   required:
                                      //     "cancelUntill Type is required",
                                      //   validate: validateNonNegative,
                                      // }}
                                      render={({ field }) => (
                                        <TextField
                                          {...field}
                                          variant="outlined"
                                          size="medium"
                                          state="enabled"
                                          fullWidth
                                          // type="number"
                                          value={numOfDays}
                                          onChange={(event) => {
                                            const enteredValue =
                                              event.target.value;
                                            // Check if enteredValue is a non-negative number
                                            if (
                                              /^\d+$/.test(enteredValue) ||
                                              enteredValue === ""
                                            ) {
                                              setNumOfDays(enteredValue);
                                            }
                                          }}
                                        />
                                      )}
                                    />
                                  </Grid>
                                  <Grid item xs={6} md={6} xl={6} sm={6} lg={6}>
                                    <FormControl
                                      sx={{ m: 1, minWidth: 120 }}
                                      style={{ margin: 0, width: "100%" }}
                                    >
                                      <Select
                                        value={selectedValue}
                                        onChange={(event) => {
                                          setSelectedValue(event.target.value);
                                        }}
                                        displayEmpty
                                        inputProps={{
                                          "aria-label": "Without label",
                                        }}
                                        placeholder="type"
                                      >
                                        <MenuItem value={"days"}>Days</MenuItem>
                                        <MenuItem value={"hours"}>
                                          Hours
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                </Stack>
                              </Grid>
                            )}
                            <Grid item xs={12} md={6} xl={12} sm={12} lg={12}>
                              <Stack
                                direction={"column"}
                                spacing={1}
                                rowGap={2}
                                marginTop={"20px"}
                              >
                                <Stack gap={2}>
                                  <Typography
                                    variant="body1"
                                    style={{
                                      fontWeight: 400,
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                    }}
                                  >
                                    Type of Amount{" "}
                                    <span style={{ color: "#D32F2F" }}>*</span>
                                  </Typography>

                                  <FormControl
                                    sx={{ m: 1, minWidth: 120 }}
                                    style={{ margin: 0, width: "100%" }}
                                  >
                                    <Select
                                      value={typeOfAmount}
                                      onChange={(event) => {
                                        setTypeOfAmount(event.target.value);
                                      }}
                                      displayEmpty
                                      inputProps={{
                                        "aria-label": "Without label",
                                      }}
                                      placeholder="type"
                                    >
                                      <MenuItem value={"fixed"}>Fixed</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Stack>
                              </Stack>
                            </Grid>

                            <Grid item xs={12} md={6} xl={12} sm={12} lg={12}>
                              <Stack
                                direction={"column"}
                                spacing={2}
                                rowGap={2}
                                marginTop={"20px"}
                              >
                                <Stack gap={2}>
                                  <Typography
                                    variant="body1"
                                    style={{
                                      fontWeight: 400,
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                    }}
                                  >
                                    Amount
                                    <span style={{ color: "#D32F2F" }}>*</span>
                                  </Typography>

                                  <TextField
                                    variant="outlined"
                                    size="medium"
                                    state="Enabled"
                                    fullWidth
                                    type="number"
                                    error={!!errors.amount}
                                    helperText={
                                      errors.amount ? errors.amount.message : ""
                                    }
                                    value={amountNum}
                                    onChange={(event) => {
                                      const inputValue = event.target.value;
                                      // Check if the input is a non-negative number
                                      if (/^\d*\.?\d*$/.test(inputValue)) {
                                        setAmountNum(inputValue);
                                      }
                                    }}
                                    // {...field}
                                  />
                                </Stack>
                              </Stack>
                            </Grid>
                            <Grid item xs={12} md={6} xl={12} sm={12} lg={12}>
                              <Stack direction={"column"} spacing={1}>
                                <Stack>
                                  {typeOfCancel == "no_show" ? (
                                    <Typography
                                      variant="body1"
                                      style={{
                                        fontWeight: 400,
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                        marginTop: "20px",
                                        backgroundColor: "#F0F0F0",
                                        padding: "10px",
                                      }}
                                    >
                                      In the event that customer does not show
                                      up for the appoinment and you mark them as
                                      a no show, a charge of ${amountNum} will
                                      be applied to the card on file and
                                      remitted to you.
                                    </Typography>
                                  ) : (
                                    <Typography
                                      variant="body1"
                                      style={{
                                        fontWeight: 400,
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                        marginTop: "20px",
                                        backgroundColor: "#F0F0F0",
                                        padding: "10px",
                                      }}
                                    >
                                      Customers will have until{" "}
                                      {numOfDays + " " + selectedValue} to
                                      cancel an appointment. Should they cancel
                                      after this allocated time, a charge of $
                                      {amountNum} will be applied to the card on
                                      file and remitted to you.
                                    </Typography>
                                  )}
                                </Stack>
                              </Stack>
                            </Grid>

                            <Grid
                              container
                              justifyContent={"end"}
                              spacing={2}
                              style={{
                                marginTop: "20px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Stack
                                style={{
                                  marginTop: "10px",
                                  marginLeft: "18px",
                                }}
                              >
                                {editModal && (
                                  <Button
                                    className="btn-text-size-manage"
                                    variant="contained"
                                    color="error"
                                    onClick={() => {
                                      setOpenDeleteConformModal(true);
                                    }}
                                  >
                                    Delete
                                  </Button>
                                )}
                              </Stack>
                              <Grid item spacing={2}>
                                <Stack spacing={2} direction="row">
                                  <Button
                                    className="btn-text-size-manage"
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                      handleClose();
                                      setSelectedValue("");
                                      setTypeOfCancel("");
                                      setTypeOfAmount("");
                                      setValue("cancelUntill", "");
                                      setNumOfDays("");

                                      setValue("amount", "");
                                      setAmountNum("");
                                    }}
                                  >
                                    Cancel{" "}
                                  </Button>
                                  <Button
                                    className="btn-text-size-manage"
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={() => {}}
                                  >
                                    SAVE
                                  </Button>
                                </Stack>
                              </Grid>
                            </Grid>
                          </Grid>
                        </form>
                      </Container>
                    </Box>
                  </Modal>
                </Box>
              </Stack>
            </Grid>
            {Array.isArray(policies) &&
              resultData == null &&
              (policies?.length > 0
                ? policies.map((item, index) => (
                    <React.Fragment key={item?._id}>
                      <Grid
                        container
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        xl={12}
                        direction={"row"}
                        spacing={2}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Grid item xs={6} sm={6} md={6} xl={3}>
                          <Typography
                            variant="h6"
                            style={{ fontWeight: "500" }}
                          >
                            {item?.cancellationType == "standard"
                              ? "Standard"
                              : "No Show"}
                          </Typography>
                          <Typography
                            variant="body"
                            style={{ lineHeight: "35px" }}
                          >
                            {item?.cancelUntill} {item?.cancelUntillType} Until
                            Booking
                          </Typography>
                          <br />
                          <Typography variant="body">
                            Amount: ${item?.amount}.00
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          xl={3}
                          spacing={2}
                          direction={"row"}
                          alignItems={"start"}
                          style={{ display: "contents" }}
                        >
                          <Stack spacing={2} direction="row">
                            <Button
                              variant="contained"
                              size="medium"
                              style={{ marginRight: "10px" }}
                              onClick={() => {
                                setEditModal(true);
                                handleOpen();
                                EditManage(item);
                              }}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="contained"
                              size="medium"
                              color="error"
                              onClick={() => {
                                setOpenDeleteConformModal(true);
                                // setDeleteRescheduledPolicy(true)
                                setIds(item._id);
                              }}
                            >
                              Delete
                            </Button>
                          </Stack>
                        </Grid>
                      </Grid>
                      <Divider className="cancellation-divider" />
                    </React.Fragment>
                  ))
                : null)}

            {resultData &&
              (Array.isArray(resultData) && resultData?.length > 0
                ? resultData.map((item, index) => (
                    <React.Fragment key={item?._id}>
                      <Grid
                        container
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        xl={12}
                        direction={"row"}
                        spacing={2}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Grid item xs={6} sm={6} md={6} xl={3}>
                          <Typography
                            variant="h6"
                            style={{ fontWeight: "500" }}
                          >
                            {item?.cancellationType == "standard"
                              ? "Standard"
                              : "No Show"}
                          </Typography>
                          <Typography
                            variant="body"
                            style={{ lineHeight: "35px" }}
                          >
                            {item?.cancelUntill} {item?.cancelUntillType} Until
                            Booking
                          </Typography>
                          <br />
                          <Typography variant="body">
                            Amount: ${item?.amount}.00
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          xl={3}
                          spacing={2}
                          direction={"row"}
                          alignItems={"start"}
                          style={{ display: "contents" }}
                        >
                          <Stack spacing={2} direction="row">
                            <Button
                              variant="contained"
                              size="medium"
                              style={{ marginRight: "10px" }}
                              onClick={() => {
                                setEditModal(true);
                                handleOpen();
                                EditManage(item);
                              }}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="contained"
                              size="medium"
                              color="error"
                              onClick={() => {
                                setOpenDeleteConformModal(true);
                                // setDeleteRescheduledPolicy(true)
                                setIds(item._id);
                              }}
                            >
                              Delete
                            </Button>
                          </Stack>
                        </Grid>
                      </Grid>
                      <Divider className="cancellation-divider" />
                    </React.Fragment>
                  ))
                : null)}
          </Grid>
        </Grid> */}
        {/* cancellation section end */}
        {/* ReschedulePolicy Start */}
        {/* <ReschedulePolicy /> */}
        {/* ReschedulePolicy  end here  */}
        {/* estimates section start */}
        <form
          onSubmit={handleSubmit(onSubmitEstimateFee)}
          style={{ marginTop: "20px" }}
        >
          <Grid
            container
            sx={{ boxShadow: 3, bgcolor: "white" }}
            justifyContent={"space-between"}
            padding={"24px"}
            borderRadius={"10px"}
            marginTop={"32px"}
          >
            <Grid item xs={12} sm={3} md={3} xl={3}>
              <Typography variant="h5">Estimates</Typography>
              <div className="flex-col">
                <FormControlLabel
                  label="Offer Estimates"
                  labelPlacement="right"
                  control={
                    <Checkbox
                      checked={estimateFeeChecked}
                      onChange={(e) => {
                        setEstimateFeeChecked(!estimateFeeChecked);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                />
                <FormControlLabel
                  label="Paid Estimate"
                  labelPlacement="right"
                  disabled={!estimateFeeChecked}
                  control={
                    <Checkbox
                      checked={paidEstimateChecked}
                      onChange={(e) => {
                        setPaidEstimateChecked(!paidEstimateChecked);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              md={8}
              xl={9}
              direction={"row"}
              spacing={4}
              padding={"24px"}
            >
              <div className="estimate-title-checkbox">
                <Typography
                  variant="h6"
                  style={{ marginTop: "10px", fontWeight: "500" }}
                >
                  Estimate Fee
                </Typography>
              </div>
              <Controller
                name="estimateFees"
                control={control}
                rules={{
                  required: "Estimate Fee is required",
                }}
                render={({ field }) => (
                  <TextField
                    style={{ marginTop: "10px" }}
                    id="outlined-basic"
                    // label="Estimate Fee"
                    variant="outlined"
                    fullWidth
                    disabled={!estimateFeeChecked || !paidEstimateChecked}
                    error={!!errors.estimateFees}
                    helperText={
                      errors.estimateFees ? errors.estimateFees.message : ""
                    }
                    {...field}
                    InputLabelProps={{ shrink: field.value?.length > 0 }}
                  />
                )}
              />
              <Grid
                container
                justifyContent={"end"}
                spacing={2}
                style={{ marginTop: "5px" }}
                padding={"10px"}
              >
                <Grid item spacing={2}>
                  <Stack spacing={2} direction="row">
                    <Button
                      className="btn-text-size-manage"
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setEstimateFeeCancel(true);
                        setEstimateFeeChecked(
                          BasicDetails?.allowEstimateRequest
                        );
                        setPaidEstimateChecked(BasicDetails?.isEstimatePaid);
                      }}
                    >
                      Cancel Changes
                    </Button>
                    <Button
                      className="btn-text-size-manage"
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Save Changes
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
        {/* estimates section end */}
        {/* terms and warranty section start */}
        <Grid
          container
          sx={{ boxShadow: 3, bgcolor: "white" }}
          justifyContent={"space-between"}
          padding={"24px"}
          borderRadius={"10px"}
          marginTop={"32px"}
        >
          <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
            <Typography variant="h5" style={{ fontWeight: "400" }}>
              Terms and Warranty
            </Typography>
            <Typography
              variant="body"
              component={"div"}
              style={{ marginTop: "20px" }}
            >
              Add your own customized warranty and terms specifically designed
              for your customers on invoices and estimates.
            </Typography>
          </Grid>

          <Grid
            container
            item
            xs={12}
            sm={7}
            md={7}
            lg={8}
            xl={8}
            direction={"row"}
            spacing={4}
            justifyContent={"end"}
            padding={"24px"}
          >
            <Grid item justifyContent={"end"}>
              <Stack justifyContent={"end"}>
                {" "}
                <Button
                  className="btn-text-size-manage"
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setEditTermsModalOpen(false);
                    handleOpenTerms();
                    setValue("title", "");
                    setValue("description", "");
                  }}
                >
                  ADD NEW TERM OR WARRANTY
                </Button>
              </Stack>
              <Box className="add-terms-modal-box">
                {/*  */}
                <Modal
                  open={openTerms}
                  onClose={handleTermsClose}
                  aria-labelledby="parent-modal-title"
                  aria-describedby="parent-modal-description"
                >
                  <Box sx={{ ...style, width: "520px", height: "fit-content" }}>
                    <AppBar
                      variant="elevation"
                      color="secondary"
                      sx={{
                        height: "64px",
                        paddingRight: "0px !important",
                      }}
                    >
                      <Toolbar variant="regular">
                        <Typography
                          variant="h6"
                          gutterBottom={false}
                          width={"98%"}
                          color={"white"}
                          fontSize={"20px"}
                          lineHeight={"32px"}
                        >
                          {editTermsModalOpen
                            ? "Edit Terms or Warranty"
                            : "Add Terms or Warranty"}
                        </Typography>
                        <Stack direction={"row"} spacing={0}>
                          <div
                            onClick={handleTermsClose}
                            style={{ cursor: "pointer", height: "32px" }}
                          >
                            <CloseIcon
                              style={{ color: "#0000008F", fontSize: 32 }}
                            />
                          </div>
                        </Stack>
                      </Toolbar>
                    </AppBar>

                    <Container
                      gap={4}
                      disableGutters
                      style={{
                        height: "fit-content",
                        marginTop: "66px",
                        gap: "24px",
                      }}
                    >
                      <form onSubmit={handleSubmit(onSubmitTermsHandler)}>
                        <Grid container>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Stack direction={"column"} spacing={0}>
                              <Box>
                                <Typography
                                  variant="body1"
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    lineHeight: "24px",
                                    paddingBottom: "8px",
                                  }}
                                >
                                  Title{" "}
                                  <span style={{ color: REQUIRED_RED }}>*</span>
                                </Typography>
                              </Box>
                              <Box>
                                <Controller
                                  name="title"
                                  control={control}
                                  // rules={{
                                  //   required: "Title Fee is required",
                                  // }}
                                  render={({ field }) => (
                                    <TextField
                                      variant="outlined"
                                      size="medium"
                                      state="Enabled"
                                      fullWidth
                                      required
                                      onChange={() => {
                                        setallowApiCallTerms(true);
                                      }}
                                      error={!!errors.title}
                                      helperText={
                                        errors.title ? errors.title.message : ""
                                      }
                                      {...field}
                                      InputLabelProps={{
                                        shrink: field.value?.length > 0,
                                      }}
                                    />
                                  )}
                                />
                              </Box>
                            </Stack>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Stack direction={"column"} spacing={2}>
                              <Box>
                                <Typography
                                  variant="body1"
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    lineHeight: "24px",
                                    marginTop: "20px",
                                  }}
                                >
                                  Description{" "}
                                  <span style={{ color: REQUIRED_RED }}>*</span>
                                </Typography>
                              </Box>
                              <Box>
                                <Controller
                                  name="description"
                                  control={control}
                                  // rules={{
                                  //   required: "Description is required",
                                  // }}
                                  render={({ field }) => (
                                    <textarea
                                      style={{
                                        width: "100%",
                                        height: "100px",
                                        fontFamily: "Roboto",
                                      }}
                                      className="style-16-400-24-px"
                                      required
                                      fullWidth
                                      error={!!errors.description}
                                      helperText={
                                        errors.description
                                          ? errors.description.message
                                          : ""
                                      }
                                      onChange={() => {
                                        setallowApiCallTerms(true);
                                      }}
                                      {...field}
                                      InputLabelProps={{
                                        shrink: field.value?.length > 0,
                                      }}
                                    />
                                  )}
                                />
                              </Box>
                            </Stack>
                          </Grid>

                          <Grid
                            container
                            justifyContent={"end"}
                            spacing={2}
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Stack
                              style={{
                                marginTop: "10px",
                                marginLeft: "18px",
                              }}
                            >
                              {editTermsModalOpen && (
                                <Button
                                  variant="contained"
                                  color="error"
                                  onClick={() => {
                                    setDeleteForTermsAndWarrenty(true);
                                    setOpenDeleteConformModal(true);
                                    setOpenTerms(false);
                                    setallowApiCallTerms(true);
                                  }}
                                >
                                  Delete
                                </Button>
                              )}
                            </Stack>
                            <Grid item spacing={2}>
                              <Stack spacing={2} direction="row">
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={handleTermsClose}
                                >
                                  Cancel{" "}
                                </Button>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  type="submit"
                                  onClick={() => {}}
                                  disabled={!allowApiCallTerms}
                                >
                                  SAVE{" "}
                                </Button>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                      </form>
                    </Container>
                  </Box>
                </Modal>
              </Box>
            </Grid>
            {getTermsSpecificApiData &&
              getTermsSpecificApiData?.length > 0 &&
              termsData == null &&
              getTermsSpecificApiData?.map((item, index) => {
                return (
                  <>
                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      xl={12}
                      direction={"row"}
                      spacing={2}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      className="terms-listing"
                    >
                      <Grid item xs={8} sm={8} md={8} xl={8}>
                        <Typography
                          variant="h6"
                          fontSize={"20px"}
                          style={{ fontWeight: 500 }}
                        >
                          {item?.title}
                        </Typography>
                        <Typography
                          variant="h6"
                          fontSize={"16px"}
                          style={{ fontWeight: 400 }}
                          className="terms-desc"
                        >
                          {item?.description}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        xl={3}
                        spacing={2}
                        direction={"row"}
                        style={{ display: "contents" }}
                      >
                        <Stack spacing={2} direction="row">
                          <Button
                            // className="btn-text-size-manage"
                            variant="contained"
                            size="medium"
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              setEditTermsModalOpen(true);
                              EditTermsAndWarrenty(item);

                              handleOpenTerms();
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            // className="btn-text-size-manage"
                            variant="contained"
                            color="error"
                            size="medium"
                            onClick={() => {
                              setDeleteForTermsAndWarrenty(true);
                              setOpenDeleteConformModal(true);
                              setIds(item?._id);
                            }}
                          >
                            Delete
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                    <Divider className="divider-terms" />
                  </>
                );
              })}

            {termsData &&
              termsData?.length > 0 &&
              termsData?.map((item, index) => {
                return (
                  <>
                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      xl={12}
                      direction={"row"}
                      spacing={2}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      className="terms-listing"
                    >
                      <Grid item xs={8} sm={8} md={8} xl={8}>
                        <Typography
                          variant="h6"
                          fontSize={"20px"}
                          style={{ fontWeight: 500 }}
                        >
                          {item?.title}
                        </Typography>
                        <Typography
                          variant="h6"
                          fontSize={"16px"}
                          style={{ fontWeight: 400 }}
                          className="terms-desc"
                        >
                          {item?.description}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        xl={3}
                        spacing={2}
                        direction={"row"}
                        style={{ display: "contents" }}
                      >
                        <Stack spacing={2} direction="row">
                          <Button
                            // className="btn-text-size-manage"
                            variant="contained"
                            size="medium"
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              setEditTermsModalOpen(true);
                              EditTermsAndWarrenty(item);

                              handleOpenTerms();
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            // className="btn-text-size-manage"
                            variant="contained"
                            color="error"
                            size="medium"
                            onClick={() => {
                              setDeleteForTermsAndWarrenty(true);
                              setOpenDeleteConformModal(true);
                              setIds(item?._id);
                            }}
                          >
                            Delete
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                    <Divider className="divider-terms" />
                  </>
                );
              })}
          </Grid>
        </Grid>
        {/* terms and warranty section end */}
      </Box>
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>

      {/* delete conformation modal  */}

      <Dialog
        open={openDeleteConformModal}
        onClose={() => setOpenDeleteConformModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this
            {deleteForTermsAndWarrenty ? " term and warranty" : " policy"}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="btn-text-size-manage"
            onClick={() => {
              setOpenDeleteConformModal(false);
              handleTermsClose();
              setDeleteForTermsAndWarrenty(false);
              setDeleteRescheduledPolicy(false);
            }}
          >
            No
          </Button>
          <Button
            className="btn-text-size-manage"
            onClick={() => {
              setOpenDeleteConformModal(false);
              handleTermsClose();
              if (deleteForTermsAndWarrenty) {
                deleteCancellationTermsAndWarrenty();
                setDeleteForTermsAndWarrenty(false);
              } else if (deleteRescheduledPolicy) {
                getCancelReschedulepolicy();
              } else {
                deleteCancellationPolicies();
              }
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
    </>
  );
};

export default ShopDetails;
