import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box, Container, Stack } from "@mui/system";

import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form"; // Step 1

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { useDispatch } from "react-redux";

import { useTheme } from "@emotion/react";
import dayjs from "dayjs";
import moment from "moment";
import { updatePickupDateAndTime } from "../../redux/Dashboard/action";
import SuccessMessageModal from "../Common/SuccessMessage";
import "../Estimates/Estimates.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const textAreaStyle = {
  width: "100%",
  height: "100px",
  fontSize: "16px",
  border: "1px solid #ccc",
  borderRadius: "4px",
  padding: "10px",
  fontFamily: "Roboto",
  fontWeight: 400,
};

const EditEstimatePickUpModal = ({
  open,
  handleClose,
  setEstimatePickUpDate,
  id,
  dropOffDate,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const dropOffDateTime =
    dropOffDate && moment(dropOffDate).format("MMM D, YYYY hh:mm A");
  const [estimatePickUpDateAndTime, setEstimatePickUpDateAndTime] =
    useState(dropOffDate);

  // Add useEffect to update estimatePickUpDateAndTime when dropOffDate changes
  useEffect(() => {
    setEstimatePickUpDateAndTime(dropOffDate);
  }, [dropOffDate]);

  const [showLoader, setShowLoader] = React.useState(false);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [validationDateError, setValidationDateError] = React.useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const onSubmit = async () => {
    const originalPickupDate = dayjs(estimatePickUpDateAndTime);
    const isoDateEstimateDate = originalPickupDate.toISOString();

    const date1 = new Date(dropOffDate);
    const date2 = new Date(isoDateEstimateDate);

    if (date1.getTime() === date2.getTime()) {
      setValidationDateError("Estimated pick up time must be after drop off.");
      return;
    } else if (date1 > date2) {
      setValidationDateError("Estimated pick up time must be after drop off.");
      return;
    }

    const newDate = new Date(
      estimatePickUpDateAndTime.$y,
      estimatePickUpDateAndTime.$M,
      estimatePickUpDateAndTime.$D,
      estimatePickUpDateAndTime.$H,
      estimatePickUpDateAndTime.$m,
      estimatePickUpDateAndTime.$s,
      estimatePickUpDateAndTime.$ms
    );
    const isoPickUpDate = newDate.toISOString();

    setShowLoader(true);
    const payload = {
      pickupDateTime: isoPickUpDate,
    };
    const data = await dispatch(updatePickupDateAndTime(id, payload));
    if (data) {
      setEstimatePickUpDate(estimatePickUpDateAndTime);
      setOpenSuccessModal(true);
      setSuccessMessage("Estimated pick up date and time changed successfully");
      setShowLoader(false);
      handleClose();
    }
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={9} md={9} xl={9}>
          <Modal
            open={open}
            onClose={() => {
              handleClose();
              setValidationDateError("");
            }}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              {" "}
              <Box
                sx={{
                  ...style,
                  height: "fit-content",
                  width: "500px",
                  border: "none",
                }}
              >
                <Container gap={4} disableGutters>
                  <Grid container padding={"10px 0px"}>
                    <Grid container item xs={12} md={12} xl={12} spacing={3}>
                      <Grid item xs={12} md={12} xl={12}>
                        <Grid item xs={12} md={12} xl={12}>
                          <Typography
                            variant="body1"
                            padding={"8px 0px"}
                            fontWeight={500}
                            fontSize={16}
                          >
                            Estimated Pick Up Date & Time
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={2}>
                              <DateTimePicker
                                name="pickUpDateAndTime"
                                defaultValue={dayjs(estimatePickUpDateAndTime)}
                                // value={estimatePickUpDateAndTime}
                                onChange={(newValue) => {
                                  setEstimatePickUpDateAndTime(newValue);
                                  // checkDateValidation()
                                }}
                                timeSteps={{ minutes: 30 }}
                              />
                            </Stack>
                          </LocalizationProvider>
                          {validationDateError && (
                            <p
                              style={{
                                color: "red",
                                margin: "0",
                                fontSize: "14px",
                                lineHeight: "24px",
                              }}
                            >
                              {validationDateError}
                            </p>
                          )}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        justifyContent={"end"}
                        spacing={2}
                        style={{ marginTop: "0px" }}
                      >
                        <Grid item>
                          <Stack spacing={2} direction="row">
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                setValidationDateError("");
                                handleClose();
                              }}
                            >
                              CANCEL
                            </Button>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                            >
                              SAVE
                            </Button>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            </form>
          </Modal>
        </Grid>
      </Grid>
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default EditEstimatePickUpModal;
