import React, { useEffect, useState } from "react";
import "./ShopHours.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import { Modal } from "@mui/material";
import Switch from "@mui/material/Switch";
import {
  AppBar,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  useMediaQuery,
  FormControl,
  InputLabel,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import {
  getOntarioHolidays,
  updateOntarioHolidays,
} from "../../../redux/Account/shopHours/action";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import SampleModal from "./SampleModal";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTheme } from "@emotion/react";
import SuccessMessageModal from "../../Common/SuccessMessage";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const label = { inputProps: { "aria-label": "Switch demo" } };

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const OntarioHoliday = () => {
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const theme = useTheme();

  const dispatch = useDispatch();
  const getTimeZoneRedux = useSelector(
    (state) => state?.account?.getOntarioHolidays
  );

  const [values, setValues] = React.useState(dayjs("2022-04-17T09:30"));
  const [nextOpen, setNexOpen] = React.useState(false);
  const [showTimeInMOdal, setShowTimeInModal] = useState(false);
  const [dateAndHolidays, setDateAndHolidays] = useState({
    date: "",
    holidays: "",
  });

  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [ontarioDate, setOntarioDate] = useState({
    starting: dayjs("2022-04-17T23:59"),
    closing: dayjs("2022-04-17T23:59"),
  });
  const [Id, setId] = useState();
  const limit = 10;
  const [page, setPage] = useState(1);
  const [currentPages, setCurrentPages] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [cardData, setCardData] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [validationDateError, setValidationDateError] = useState("");
  const [selectedYear, setSelectedYear] = useState(dayjs());

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  // const totleData=useSelector((state)=>state.)

  const handleNextOpen = () => {
    setNexOpen(true);
  };
  const handleClose = () => {
    setNexOpen(false);
  };

  function createData(date, disc, invTotal, patmentMethod, Action) {
    return { date, disc, invTotal, patmentMethod, Action };
  }

  useEffect(() => {
    getOntarioHoliday(1, false, selectedYear);
    setPage(1);
  }, [selectedYear]);

  const getOntarioHoliday = async (currentPage, InfiniteScrollCall) => {
    try {
      setLoading(!InfiniteScrollCall); // Set loading only if not called by InfiniteScroll
      const res = await dispatch(
        getOntarioHolidays(
          limit,
          currentPage ? currentPage : page,
          dayjs(selectedYear).year()
        )
      );

      if (res) {
        setCurrentPages(res.currentPage);
        setTotalPages(res.totalPages);

        if (currentPage) {
          setCardData((prevData) =>
            currentPage === 1 ? res.data : [...prevData, ...res.data]
          );
        } else {
          setCardData(res.data);
        }
      }
    } catch (error) {
      // Handle errors
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    setSelectedYear(event.target.value);
  };
  const getOrdinalSuffix = (day) => {
    if (day >= 11 && day <= 13) {
      return `${day}th`;
    }
    switch (day % 10) {
      case 1:
        return `${day}st`;
      case 2:
        return `${day}nd`;
      case 3:
        return `${day}rd`;
      default:
        return `${day}th`;
    }
  };

  const convertTimestampToFormattedDate = (timestamp) => {
    const dateObject = new Date(timestamp);
    const day = dateObject.getDate();
    const month = dateObject.toLocaleString("default", { month: "long" });
    const year = dateObject.getFullYear();
    const ordinalSuffix = getOrdinalSuffix(day);
    return `${month} ${ordinalSuffix}, ${year}`;
  };
  const EditDataHandler = async (data) => {
    setShowLoader(true);
    // handleNextOpen();

    const openingTime = dayjs(ontarioDate?.starting);
    const closingTime = dayjs(ontarioDate?.closing);

    // Add validation to check if closing time is greater than opening time
    if (
      showTimeInMOdal &&
      (closingTime?.isBefore(openingTime) || closingTime?.isSame(openingTime))
    ) {
      setValidationDateError("Closing time must be greater than opening time");
      setShowLoader(false);
      return;
    }

    const formattedData = {
      startTime: ontarioDate?.starting?.$d
        ? dayjs(ontarioDate?.starting?.$d).format("HH:mm")
        : dayjs(ontarioDate.starting).format("HH:mm"),
      endTime: ontarioDate?.closing?.$d
        ? dayjs(ontarioDate?.closing?.$d).format("HH:mm")
        : dayjs(ontarioDate.closing).format("HH:mm"),
      type: !showTimeInMOdal ? "single_full_day" : "single_day_with_some_hours",
    };
    if (!showTimeInMOdal) {
      formattedData.startTime = "12:00";
      formattedData.endTime = "23:59";
    }

    const res = await dispatch(updateOntarioHolidays(formattedData, Id));
    if (res) {
      setOpenSuccessModal(true);
      setSuccessMessage("Changes Saved");
      getOntarioHoliday(page, false);
      setValidationDateError("");
    }
    setShowLoader(false);
    handleClose();
  };

  const EditDataWithMoment = (data) => {
    if (data.type == "single_full_day") {
      setShowTimeInModal(false);
    } else {
      setShowTimeInModal(true);
    }
    setDateAndHolidays({
      date: convertTimestampToFormattedDate(data.startDate),
      holidays: data.title,
    });

    setId(data._id);
    handleNextOpen();

    setOntarioDate({
      ...ontarioDate,
      starting: dayjs(`2022-04-17T${data.startTime}`),
      closing: dayjs(`2022-04-17T${data.endTime}`),
    });
  };
  // dayjs("2022-04-17T15:30")
  // const [selectedYear, setSelectedYear] = React.useState(null);

  const handleDateChange = (date) => {
    // Extract the year from the selected date
    const selectedYear = dayjs(date).year();

    setSelectedYear(date);
  };

  return (
    <>
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <Box
        className="box3"
        sx={{ width: "100%", height: "100%", bgcolor: "white" }}
        boxShadow={
          "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px"
        }
        padding={"10px"}
        borderRadius={"10px"}
      >
        <Grid
          container
          spacing={2}
          style={{ marginTop: "20px", paddingInline: "24px" }}
        >
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            xl={12}
            justifyContent={"space-between"}
            alignItems={"center"}
            rowGap={2}
          >
            <Grid item xl={4}>
              <Typography variant="h5" style={{ textAlign: "left" }}>
                Ontario Holidays
              </Typography>
            </Grid>
            <Grid item xl={2}>
              {
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    views={["year"]}
                    // openTo="year"
                    label="Year"
                    value={selectedYear}
                    onChange={handleDateChange}
                    // renderInput={(params) => <input {...params.inputProps} readOnly />} // Disable manual input
                  />
                </LocalizationProvider>
              }
            </Grid>
          </Grid>
          <Grid item xs={12} justifyContent={"flex-end"} display={"flex"}>
            <Grid item xs={12} sm={12} md={12} lg={10} xl={8}>
              <div
                id="scrollableDiv"
                style={{
                  maxHeight: "800px",
                  overflowY: "scroll",
                }}
              >
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow className="ontario-table-row">
                        <TableCell>Date</TableCell>
                        <TableCell align="center">Holidays</TableCell>
                        <TableCell align="center">Hours</TableCell>
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>

                    {!loading ? (
                      cardData && cardData?.length > 0 ? (
                        <>
                          <TableBody>
                            {cardData.map((row, index) => (
                              <TableRow
                                key={row?._id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {convertTimestampToFormattedDate(
                                    row?.startDate
                                  )}
                                </TableCell>
                                <TableCell align="center">
                                  {row?.title}
                                </TableCell>
                                <TableCell align="center">
                                  {/* {row.startTimeIn12Hr == "12:00 AM" &&
                    row.endTimeIn12Hr == "11:59 PM" */}

                                  {row.type == "single_full_day"
                                    ? "Closed"
                                    : row.startTimeIn12Hr +
                                      " to " +
                                      row.endTimeIn12Hr}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Stack spacing={2} direction="row">
                                    <Button
                                      className="btn-text-size-manage"
                                      variant="contained"
                                      size="medium"
                                      onClick={() => {
                                        EditDataWithMoment(row);
                                      }}
                                      style={{
                                        lineHeight: "24px",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Edit
                                    </Button>
                                  </Stack>
                                </TableCell>
                                {/* <TableCell align="center">{row.Action}yuiyu</TableCell> */}
                              </TableRow>
                            ))}
                          </TableBody>
                          <InfiniteScroll
                            data={cardData}
                            dataLength={cardData.length}
                            next={() => {
                              setPage(page + 1);
                              getOntarioHoliday(page + 1, true);
                            }}
                            hasMore={currentPages <= totalPages}
                            // loader={
                            //   currentPages < totalPages && (
                            //     <CircularProgress
                            //       style={{ display: "block", margin: "auto" }}
                            //     />
                            //   )
                            // }
                            scrollableTarget="scrollableDiv"
                          />
                        </>
                      ) : (
                        !loading && (
                          <>
                            <TableRow>
                              <TableCell colSpan={4} align="center">
                                <Typography
                                  variant="h6"
                                  style={{
                                    fontWeight: 400,
                                    textAlign: "center",
                                  }}
                                >
                                  Data is not available
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </>
                        )
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          <CircularProgress
                            style={{ display: "block", margin: "auto" }}
                          />
                        </TableCell>
                      </TableRow>
                    )}
                  </Table>
                </TableContainer>{" "}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Modal
          open={nextOpen}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box
            sx={{
              ...style,
              width: "720px",
              height: `${showTimeInMOdal ? "410px" : "300px"}`,
            }}
            className="Modal-z-index"
          >
            <AppBar
              variant="elevation"
              color="secondary"
              sx={{
                height: "64px",
                paddingRight: "0px !important",
              }}
            >
              <Toolbar variant="regular">
                <Typography
                  variant="body1"
                  gutterBottom={false}
                  width={"98%"}
                  fontSize={"20px"}
                  color={"white"}
                  fontWeight={500}
                >
                  Edit Holiday
                </Typography>
                <Stack direction={"row"} spacing={0}>
                  <div
                    onClick={handleClose}
                    style={{ cursor: "pointer", height: "32px" }}
                  >
                    <CloseIcon style={{ color: "#0000008F", fontSize: 32 }} />
                  </div>
                </Stack>
              </Toolbar>
            </AppBar>
            <Container
              gap={"24px"}
              disableGutters
              style={{ height: "400px", marginTop: "66px", zIndex: "10" }}
            >
              <form
                onSubmit={handleSubmit(EditDataHandler)}
                style={{ marginTop: "20px" }}
              >
                <Grid container justifyContent={"start"} spacing={4}>
                  <Grid item md={12} xl={12} spacing={2}>
                    <Typography variant="h6" style={{ fontWeight: "500" }}>
                      Holiday:
                    </Typography>
                    <Typography variant="h6" style={{ fontWeight: "500" }}>
                      {dateAndHolidays.date}
                    </Typography>
                    <Typography variant="h6" style={{ fontWeight: "500" }}>
                      {dateAndHolidays.holidays}
                    </Typography>
                    <div>
                      <Switch
                        // {...label}
                        onChange={(e) => {
                          setShowTimeInModal(e.target.checked);
                        }}
                        checked={showTimeInMOdal}
                      />{" "}
                      {!showTimeInMOdal ? "Closed" : "Open"}
                      {/* Closed */}
                    </div>
                    {showTimeInMOdal && (
                      <>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={["TimePicker", "TimePicker"]}
                          >
                            <div>
                              <Typography variant="body1" className="label-500">
                                Shop Closed From
                              </Typography>

                              <TimePicker
                                // label="Starting"
                                value={ontarioDate.starting}
                                onChange={(newValue) => {
                                  const inputDate = dayjs(newValue.$d);

                                  const formattedDate = dayjs(
                                    "2022-04-17T23:59:00"
                                  )
                                    .hour(inputDate.hour())
                                    .minute(inputDate.minute())
                                    .format("YYYY-MM-DDTHH:mm");

                                  setOntarioDate({
                                    ...ontarioDate,
                                    starting: formattedDate,
                                  });
                                }}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                paddingTop: "25px",
                              }}
                            >
                              <div>
                                <Typography
                                  variant="body1"
                                  className="label-500"
                                >
                                  to
                                </Typography>
                              </div>
                              {/* to */}
                            </div>
                            <div>
                              <Typography variant="body1" className="label-500">
                                Shop Closed To
                              </Typography>

                              <TimePicker
                                // label="Closing"
                                value={ontarioDate.closing}
                                onChange={(newValue) => {
                                  const inputDate = dayjs(newValue.$d);

                                  const formattedDate = dayjs(
                                    "2022-04-17T23:59:00"
                                  )
                                    .hour(inputDate.hour())
                                    .minute(inputDate.minute())
                                    .format("YYYY-MM-DDTHH:mm");

                                  setOntarioDate({
                                    ...ontarioDate,
                                    closing: formattedDate,
                                  });
                                }}
                              />
                            </div>
                          </DemoContainer>
                        </LocalizationProvider>
                      </>
                    )}
                    {validationDateError != "" && (
                      <Typography variant="body2" color="error">
                        {validationDateError}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item container justifyContent={"end"} spacing={2}>
                    <Grid item spacing={2}>
                      <Stack spacing={2} direction="row">
                        <Button
                          className="btn-text-size-manage"
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            handleClose();
                            setValidationDateError("");
                          }}
                        >
                          Cancel{" "}
                        </Button>
                        <Button
                          className="btn-text-size-manage"
                          variant="contained"
                          color="primary"
                          type="submit"
                          // onClick={handleNextOpen}
                          onClick={EditDataHandler}
                        >
                          Save
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Container>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default OntarioHoliday;
