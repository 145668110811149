import React from "react";
import { Route, Routes } from "react-router-dom";
import ForgotPassword from "../container/Authentication/ForgotPassword";
import LoginPage from "../container/Authentication/LoginPage";
import SignUp from "../container/Authentication/SignUp/SignUP";

import NotFound from "../container/NotFound/NotFound";

import RecoveryCode from "../container/Authentication/RecoveryCode/RecoveryCode";
import ResetPassword from "../container/Authentication/ResetPassword/ResetPswd";
import SiteMaintennance from "../container/SiteMaintenance/SiteMaintennance";

const PublicRoutes = () => {
  return (
    <>
      <Routes>
        <Route exact path={"/"} element={<LoginPage />} />
        <Route exact path={"/login"} element={<LoginPage />} />
        <Route exact path={"/forgot-password"} element={<ForgotPassword />} />

        <Route exact path={"/signup"} element={<SignUp />} />
        <Route exact path={"/recoveryCode"} element={<RecoveryCode />} />
        <Route
          exact
          path={"/reset-password"}
          element={<ResetPassword title={"Reset Password"} />}
        />
        <Route
          exact
          path={"/update-password/:token"}
          element={<ResetPassword title={"Update your Password"} />}
        />
        <Route exact path="/*" element={<NotFound />} />
        <Route exact path="/site-maintenance" element={<SiteMaintennance />} />
      </Routes>
    </>
  );
};

export default PublicRoutes;
