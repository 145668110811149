import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SupplierProfileEditLeftSection from "./SupplierProfileEditLeftSection";
import SupplierProfileLeftDataSection from "./SupplierProfileLeftDataSection";
function SupplierProfileLeftSection({ setQuickEdit, quickEdit }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open1 = Boolean(anchorEl);

  return (
    <Box>
      <Grid
        item
        container
        height={"fit-content"}
        xs={12}
        // spacing={2}
        // boxShadow={4}
        borderRadius={"4px"}
        padding="24px"
        sx={{
          boxShadow:
            "0px 2px 16px 1px rgba(0, 0, 0, 0.12), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 4px 5px -2px rgba(0, 0, 0, 0.20)",
        }}
      >
        {!quickEdit ? (
          <SupplierProfileLeftDataSection setQuickEdit={setQuickEdit} />
        ) : (
          <SupplierProfileEditLeftSection
            setQuickEdit={setQuickEdit}
            quickEdit={quickEdit}
          />
        )}
      </Grid>
    </Box>
  );
}

export default SupplierProfileLeftSection;
