import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    /* Call to Action blue */
    primary: {
      main: "rgba(0, 108, 221, 1)",
      light: "rgba(0, 108, 221, 0.6)",
    },
    /* Apex dark blue */
    secondary: { main: "rgba(0, 35, 148, 1)" },
    // tabColor: { main: "#2196f3" },

    // error: "red",
  },
  breakpoints: {
    values: {
      xxs: 180,
      xs: 360,
      sm: 656,
      md: 1024,
      lg: 1280,
      xl: 1341,
    },
  },
});
