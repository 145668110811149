import { CircularProgress, MenuItem } from "@mui/material";
import React from "react";

const DropdownLoader = () => {
  return (
    // <Dialog open={true}>
    <MenuItem className="dropdownLoader">
      <CircularProgress />{" "}
    </MenuItem>
    // </Dialog>
  );
};

export default DropdownLoader;
