import { API } from "../../config/api";
import { DataService } from "../../config/dataService/dataService";

export const createUser = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(API.cometChat.create, payload);

      if (response.data.status) {
        return response;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
