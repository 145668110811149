import React from "react";
import { Skeleton } from "@mui/material";
import "./Skeletons.css";
function Skeletons(props) {
  return (
    <Skeleton
      animation="wave"
      variant="text"
      className="modelFieldSkeleton"
    ></Skeleton>
  );
}

export default Skeletons;
