import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Input,
  InputAdornment,
  OutlinedInput,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import PeopleIcon from "@mui/icons-material/People";
import MailIcon from "@mui/icons-material/Mail";

function InviteCustomer(props) {
  const [emailList, setEmailList] = useState([
    {
      email: "momin@gmail.com",
      value: "Invited",
    },
    {
      email: "momin@gmail.com",
      value: "Invited",
    },
    {
      email: "momin@gmail.com",
      value: "Invited",
    },
    {
      email: "momin@gmail.com",
      value: "Invited",
    },
    {
      email: "momin@gmail.com",
      value: "Invited",
    },
  ]);

  return (
    <Box padding={"32px 24px"} sx={{ bgcolor: "background.main" }}>
      <Grid container rowGap={3}>
        <Grid container item justifyContent={"space-between"}>
          <Grid item lg={12}>
            <Typography variant="h4" sx={{ fontWeight: 400 }}>
              Invite Customers
            </Typography>
          </Grid>
          <Grid item lg={12} marginTop={"26px "}>
            <Typography
              variant="body"
              sx={{ fontWeight: 400, color: "#646466" }}
            >
              Invite your customers to download the Apex Auto app, allowing them
              to schedule appointments without calling, chat with you through
              real time messaging and manage their vehicle service history.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent={"space-between"}
          spacing={2}
          mt={"24px"}
        >
          <Grid item md={12} xl={12} sm={12}>
            <Card>
              <Paper>
                <CardHeader
                  title="Share Email"
                  style={{ marginTop: "11px" }}
                ></CardHeader>
                <CardContent style={{ marginBottom: "20px" }}>
                  <Stack spacing={3} direction="row">
                    <div style={{ width: "90%" }}>
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        placeholder="Enter email"
                        startAdornment={
                          <InputAdornment position="start">
                            <MailIcon />
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        fullWidth
                      />
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Button variant="contained" color="primary" size="large">
                        INVITE
                      </Button>
                    </div>
                  </Stack>
                </CardContent>
                {emailList?.map((list) => {
                  return (
                    <Stack
                      spacing={2}
                      direction="row"
                      justifyContent={"space-between"}
                      style={{
                        paddingLeft: "20px",
                        paddingRight: "45px",
                        height: "40px",
                      }}
                    >
                      <div>
                        {/* <TextField
                            // label="Labl"
                            variant="outlined"
                            size="medium"
                            fullWidth
                          /> */}

                        <Typography>{list?.email}</Typography>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="body1" color={"#9e9e9e"}>
                          {list?.value}
                        </Typography>
                      </div>
                    </Stack>
                  );
                })}
              </Paper>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default InviteCustomer;
