import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Link,
  Modal,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box, Container, Stack } from "@mui/system";

import { useTheme } from "@emotion/react";
import { InfoOutlined } from "@mui/icons-material";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import FormatNumber from "../../components/FormatNumber/FormatNumber";
import { acceptDeclineRescheduleRequest } from "../../redux/appointment/action";
import SuccessMessageModal from "../Common/SuccessMessage";
import "../Estimates/Estimates.css";
import EditEstimatePickUpModal from "../modal/EditEstimatePickUpModal";

// import "./modal.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 491,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  // pt: 2,
  // px: 4,
  // pb: 3,
};

const AppointmentRescheduleRequestModal = ({
  open,
  setOpen,
  appointmentData,
  id,
  apiCall,
}) => {
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = React.useState(false);
  const [showModel, setShowModel] = React.useState(false);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");

  const [showEstimatePickUp, setShowEstimatePickUp] = React.useState(false);
  const [estimatePickUpDate, setEstimatePickUpDate] = React.useState(null);
  // const [appointmentData, setAppointmentData] = useState({});
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [showProposeNewTimemOdal, setShowProposeNewTimeModal] = useState(false);

  const handleResponses = async (status) => {
    setShowLoader(true);
    const resp = await dispatch(acceptDeclineRescheduleRequest(id, status));
    setShowLoader(false);
    if (resp) {
      setSuccessMessage(
        status == "accept"
          ? `Appointment reschedule request accepted`
          : `Appointment reschedule request declined`
      );
      setOpenSuccessModal(true);
    }
    setOpen(false);
    apiCall(1, false);
  };
  const handleCloseEsatimatePickUpModal = () => {
    setShowEstimatePickUp(false);
  };
  // useEffect(() => {
  //   if (id) AppointmentDetails();
  // }, [id]);

  // const AppointmentDetails = async () => {
  //   setShowLoader(true);
  //   const data = await dispatch(requestAppointmentDetails(id));
  //   if (data) {
  //     setShowLoader(false);
  //     setAppointmentData(data);
  //   }
  //   setShowLoader(false);
  // };
  const handleCloseProposeNewTime = () => {
    setShowProposeNewTimeModal(false);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };
  return (
    <Box>
      <Grid container>
        <Grid item xs={9} md={9} xl={9}>
          <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box
              sx={{
                ...style,
                height: "fit-content",
                width: "900px",
                border: "none",
              }}
            >
              <AppBar
                className="modalHeader"
                variant="elevation"
                sx={{
                  height: "64px",
                  paddingRight: "0px !important",
                }}
              >
                <Toolbar variant="regular">
                  <Typography
                    variant="h6"
                    fontWeight={500}
                    width={"98%"}
                    color={"black"}
                  >
                    Appointment Reschedule Request
                  </Typography>
                  <Stack direction={"row"} spacing={0}>
                    <div
                      onClick={() => {
                        setOpen(false);
                      }}
                      className="appointmentModal"
                      style={{ cursor: "pointer", height: "32px" }}
                    >
                      <CloseIcon style={{ fontSize: 32 }} />
                    </div>
                  </Stack>
                </Toolbar>
              </AppBar>
              <Container
                gap={2}
                disableGutters
                style={{ marginTop: "45px" }}
                className="appointmentModal_rightContainer"
              >
                <Grid container style={{ backgroundColor: "#F5F5F7" }}>
                  <Grid
                    container
                    item
                    xs={5.3}
                    md={5.3}
                    xl={5.3}
                    style={{
                      borderRight: "1px solid #C4C4C4",
                      marginTop: "10px",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={12}
                      xl={12}
                      style={{ paddingLeft: "20px", backgroundColor: "white" }}
                    >
                      {/* <Typography
                        item
                        md={12}
                        border={"1px solid #C4C4C4"}
                        padding={"16px"}
                        fontWeight={400}
                        borderRadius={"4px"}
                        fontSize={"16px"}
                        marginTop={"20px"}
                        width={"358px"}
                        color={"white"}
                        backgroundColor={"#D32F2F"}
                      >
                       <InfoOutlined/> Rescheduling Fee:{appointmentData?.rescheduleFee}
                      </Typography> */}
                      <Stack
                        direction={"row"}
                        spacing={2}
                        style={{
                          backgroundColor: "#D32F2F",
                          fontSize: "16px",
                          marginTop: "20px",
                          width: "358px",
                        }}
                        alignItems={"center"}
                        padding={"8px 8px"}
                        borderRadius={"4px"}
                        color="white"
                      >
                        <Stack>
                          <InfoOutlined />
                        </Stack>
                        <Stack direction={"column"} spacing={0}>
                          <Typography variant="subtitle1" fontWeight={500}>
                            Rescheduling Fee:{" "}
                            {appointmentData?.rescheduleFee
                              ? FormatNumber({
                                  inputValue: appointmentData?.rescheduleFee,
                                })
                              : "No Fee"}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Typography
                        variant="body1"
                        fontWeight={500}
                        fontSize={"16px"}
                        marginTop={"20px"}
                      >
                        Appointment Information
                      </Typography>
                      <Grid
                        item
                        md={12}
                        lg={12}
                        xl={12}
                        border={"1px solid #C4C4C4"}
                        padding={"16px"}
                        fontWeight={400}
                        borderRadius={"4px"}
                        fontSize={"16px"}
                        marginTop={"7px"}
                        width={"358px"}
                      >
                        <Grid container item>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"16px"}
                            >
                              Customer
                            </Typography>
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"16px"}
                            >
                              <Link
                                style={{
                                  textDecoration: "none",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  navigate(
                                    `/customers/${appointmentData?.customer?._id}/${appointmentData?.customer?.createdFrom}`
                                  )
                                }
                              >
                                {appointmentData &&
                                  appointmentData?.customer &&
                                  appointmentData?.customer?.firstName +
                                    " " +
                                    appointmentData?.customer?.lastName}
                              </Link>
                            </Typography>
                          </Grid>
                          <Divider
                            style={{
                              padding: "5px ",
                              width: "100%",
                              borderBottomStyle: "solid",
                              borderBottomWidth: "2px",
                              marginBottom: "12px",
                            }}
                          />
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"16px"}
                            >
                              Drop Off
                            </Typography>
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"16px"}
                            >
                              {appointmentData &&
                                appointmentData?.dropOffDateTime &&
                                moment(appointmentData?.dropOffDateTime).format(
                                  "MMM D, YYYY hh:mm A"
                                )}
                            </Typography>
                          </Grid>
                          <Divider
                            style={{
                              padding: "5px ",
                              width: "100%",
                              borderBottomStyle: "solid",
                              borderBottomWidth: "2px",
                              marginBottom: "12px",
                            }}
                          />
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"16px"}
                            >
                              Estimated Pick Up
                            </Typography>
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"16px"}
                            >
                              <Link
                                style={{
                                  textDecoration: "none",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setShowEstimatePickUp(true);
                                }}
                              >
                                {estimatePickUpDate != null
                                  ? moment(new Date(estimatePickUpDate)).format(
                                      "MMM D, YYYY h:mm A"
                                    )
                                  : appointmentData?.pickupDateTime
                                  ? moment(
                                      appointmentData?.pickupDateTime
                                    ).format("MMM D, YYYY hh:mm A")
                                  : moment(
                                      appointmentData?.shopClosingTime
                                    ).format("MMM D, YYYY hh:mm A")}
                              </Link>
                            </Typography>
                          </Grid>
                          <Divider
                            style={{
                              padding: "5px ",
                              width: "100%",
                              borderBottomStyle: "solid",
                              borderBottomWidth: "2px",
                              marginBottom: "12px",
                            }}
                          />
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"16px"}
                            >
                              Customer Wants to
                            </Typography>
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"16px"}
                            >
                              {appointmentData?.waitingOnSite &&
                              appointmentData?.waitingOnSite ==
                                "dropping_off_car"
                                ? "Dropping Off Car"
                                : " Wait on Site "}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Typography
                        variant="body1"
                        fontWeight={500}
                        fontSize={"16px"}
                        marginTop={"20px"}
                      >
                        Contact Preferences
                      </Typography>

                      <Typography
                        item
                        md={12}
                        border={"1px solid #C4C4C4"}
                        padding={"16px"}
                        fontWeight={400}
                        borderRadius={"4px"}
                        fontSize={"16px"}
                        marginTop={"7px"}
                        width={"358px"}
                      >
                        {appointmentData?.contactPreferences &&
                        appointmentData?.contactPreferences == "phone"
                          ? "Phone Call"
                          : appointmentData?.contactPreferences == "email"
                          ? "Email"
                          : "In-App Message"}
                      </Typography>
                      <Typography
                        variant="body1"
                        fontWeight={500}
                        fontSize={"16px"}
                        marginTop={"20px"}
                      >
                        Note from {appointmentData?.customer?.firstName}
                      </Typography>
                      <Typography
                        item
                        md={12}
                        border={"1px solid #C4C4C4"}
                        padding={"16px"}
                        fontWeight={400}
                        borderRadius={"4px"}
                        fontSize={"16px"}
                        marginTop={"7px"}
                        width={"358px"}
                        // height={"100%"}
                        marginBottom={"20px"}
                      >
                        {appointmentData?.rescheduleNoteForMechanic
                          ? appointmentData?.rescheduleNoteForMechanic
                          : " "}
                      </Typography>
                    </Grid>
                  </Grid>

                  {/* <Divider orientation="vertical" flexItem  /> */}

                  <Grid container item xs={6} md={6} xl={6}>
                    <div>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        xl={12}
                        style={{
                          paddingLeft: "20px",
                          paddingTop: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={500}
                          fontSize={"16px"}
                        >
                          Vehicle Information
                        </Typography>
                        <Grid
                          item
                          md={12}
                          lg={12}
                          xl={12}
                          border={"1px solid #C4C4C4"}
                          padding={"16px"}
                          fontWeight={400}
                          borderRadius={"4px"}
                          fontSize={"16px"}
                          marginTop={"7px"}
                          width={"450px"}
                          bgcolor={"white"}
                        >
                          <Grid container item>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="body1"
                                fontWeight={400}
                                fontSize={"16px"}
                              >
                                Vehicle
                              </Typography>
                              <Typography
                                variant="body1"
                                fontWeight={400}
                                fontSize={"16px"}
                              >
                                {appointmentData?.vehicle?.year
                                  ? appointmentData?.vehicle?.year + " "
                                  : " "}
                                {appointmentData?.vehicle?.vehicleMake?.name
                                  ? appointmentData?.vehicle?.vehicleMake
                                      ?.name + " "
                                  : ""}
                                {appointmentData?.vehicle?.vehicleModel?.name
                                  ? appointmentData?.vehicle?.vehicleModel?.name
                                  : ""}
                              </Typography>
                            </Grid>
                            <Divider
                              style={{
                                padding: "5px ",
                                width: "100%",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "2px",
                                marginBottom: "12px",
                              }}
                            />
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="body1"
                                fontWeight={400}
                                fontSize={"16px"}
                              >
                                Trim
                              </Typography>
                              <Typography
                                variant="body1"
                                fontWeight={400}
                                fontSize={"16px"}
                              >
                                {
                                  appointmentData?.vehicle?.vehicleModelTrim
                                    ?.name
                                }
                              </Typography>
                            </Grid>
                            <Divider
                              style={{
                                padding: "5px ",
                                width: "100%",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "2px",
                                marginBottom: "12px",
                              }}
                            />
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="body1"
                                fontWeight={400}
                                fontSize={"16px"}
                              >
                                VIN
                              </Typography>
                              <Typography
                                variant="body1"
                                fontWeight={400}
                                fontSize={"16px"}
                              >
                                {appointmentData?.vehicle?.vin
                                  ? appointmentData?.vehicle?.vin
                                  : " - "}
                              </Typography>
                            </Grid>
                            <Divider
                              style={{
                                padding: "5px ",
                                width: "100%",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "2px",
                                marginBottom: "12px",
                              }}
                            />
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="body1"
                                fontWeight={400}
                                fontSize={"16px"}
                              >
                                Licence Plate
                              </Typography>
                              <Typography
                                variant="body1"
                                fontWeight={400}
                                fontSize={"16px"}
                              >
                                {appointmentData?.vehicle?.licenseNumber
                                  ? appointmentData?.vehicle?.licenseNumber
                                  : " - "}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={12}
                        xl={12}
                        style={{
                          paddingLeft: "20px",
                          paddingTop: "15px",
                          maxHeight: "485px",
                          overflow: "auto",
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={500}
                          fontSize={"16px"}
                        >
                          Services
                        </Typography>
                        <Grid
                          item
                          md={12}
                          lg={12}
                          xl={12}
                          border={"1px solid #C4C4C4"}
                          padding={"16px"}
                          fontWeight={400}
                          borderRadius={"4px"}
                          fontSize={"16px"}
                          marginTop={"7px"}
                          width={"450px"}
                          bgcolor={"white"}
                        >
                          <Grid container item>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="body1"
                                fontWeight={400}
                                fontSize={"16px"}
                              >
                                Total Services
                              </Typography>
                              <Typography
                                variant="body1"
                                fontWeight={400}
                                fontSize={"16px"}
                              >
                                {appointmentData?.services?.length}
                              </Typography>
                            </Grid>
                            {appointmentData &&
                              appointmentData?.services?.map((item) => {
                                return (
                                  <>
                                    <Divider
                                      style={{
                                        padding: "5px ",
                                        width: "100%",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "2px",
                                        marginBottom: "12px",
                                      }}
                                    />
                                    <Grid
                                      item
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      xs={12}
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography
                                        variant="body1"
                                        fontWeight={400}
                                        fontSize={"16px"}
                                      >
                                        {item?.name}
                                      </Typography>
                                      <Typography
                                        variant="body1"
                                        fontWeight={400}
                                        fontSize={"16px"}
                                      >
                                        {item?.pricingType === "no_price"
                                          ? "No Price"
                                          : item?.pricingType === "fixed"
                                          ? FormatNumber({
                                              inputValue: item?.fixedPrice,
                                            })
                                          : item?.pricingType === "range"
                                          ? FormatNumber({
                                              inputValue: item?.priceMin,
                                            }) +
                                            " - " +
                                            FormatNumber({
                                              inputValue: item?.priceMax,
                                            })
                                          : ""}
                                      </Typography>
                                    </Grid>
                                    {item?.questionnaire?.map((row) => {
                                      return (
                                        <>
                                          <Grid item md={12}>
                                            <Typography
                                              style={{
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                marginTop: "5px",
                                              }}
                                              variant="body1"
                                            >
                                              {" "}
                                              {row?.question}
                                            </Typography>
                                          </Grid>
                                          <Grid item md={12}>
                                            <Typography
                                              style={{
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                marginTop: "5px",
                                                marginBottom: "5px",
                                              }}
                                              variant="body1"
                                            >
                                              {" "}
                                              {row?.answer}
                                            </Typography>
                                          </Grid>
                                        </>
                                      );
                                    })}
                                  </>
                                );
                              })}
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        container
                        justifyContent={"end"}
                        spacing={2}
                        style={{ marginTop: "20px", marginBottom: "20px" }}
                      >
                        <Grid item>
                          <Stack spacing={2} direction="row">
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => handleResponses("decline")}
                            >
                              DECLINE
                            </Button>
                            {/* <Button
                              variant="contained"
                              color="primary"
                              //   onClick={() => {
                              //     handleProposeTime();
                              //   }}
                              onClick={() => setShowProposeNewTimeModal(true)}
                            >
                              PROPOSE NEW TIME
                            </Button> */}
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleResponses("accept")}
                            >
                              ACCEPT
                            </Button>
                          </Stack>
                        </Grid>
                      </Grid>
                    </div>{" "}
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Modal>
        </Grid>
      </Grid>
      <EditEstimatePickUpModal
        open={showEstimatePickUp}
        handleClose={handleCloseEsatimatePickUpModal}
        setEstimatePickUpDate={setEstimatePickUpDate}
        id={appointmentData?._id}
        dropOffDate={appointmentData?.dropOffDateTime}
      />
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AppointmentRescheduleRequestModal;
