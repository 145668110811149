import { API } from "../../../config/api";
import { DataService } from "../../../config/dataService/dataService";
import { paramsToQueryString } from "../../../utility/helpers/commonFunction";

// shop details fetch
export const discountProgramApi = (params) => async (dispatch) => {
  try {
    const queryString = paramsToQueryString(params);
    const response = await DataService.get(
      API.account.discountProgram.get + queryString
    );
    if (!response.data.error) {
      dispatch({
        type: API.account.discountProgram.get,
        showDiscountProgramData: response.data,
        loading: false,
      });
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

// Update personal details
export const saveDiscountProgramApi = (payload) => async () => {
  try {
    const response = await DataService.post(
      API.account.discountProgram.save,
      payload
    );
    if (!response.data.error) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

// delete cancellation policy
export const deleteDiscountProgramApi = (policyId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.delete(
        `${API.account.cancellationPolicy.deletePolicy}/${policyId}`
      );
      if (!response.data.error) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
