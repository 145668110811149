import {
  Alert,
  CircularProgress,
  Grid,
  Link,
  Stack,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/Logo.svg";
import { backToApex } from "../../../config/dataService/dataService";
import { handleRegister } from "../../../redux/Auth/action";
import "../LoginPage.css";

const SignUP = () => {
  const [success, setSuccess] = useState(false);
  const theme = createTheme({
    palette: {
      /* Call to Action blue */
      primary: {
        main: "rgba(0, 108, 221, 1)",
        light: "rgba(0, 108, 221, 0.6)",
      },
      /* Apex dark blue */
      secondary: { main: "rgba(0, 35, 148, 1)" },
      // tabColor: { main: "#2196f3" },

      // error: "red",
    },
    breakpoints: {
      values: {
        xxs: 180,
        xs: 360,
        sm: 656,
        md: 1024,
        lg: 1280,
        xl: 1440,
      },
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);

  const onSubmit = async (values) => {
    console.log(values);
    values.countryCode = "+1";
    const phoneNumber = values.phoneNumber;
    if (phoneNumber && phoneNumber.length < 10) {
      return;
    }
    const number = phoneNumber.replace(/[^0-9]/g, "");
    values.phoneNumber = number;
    console.log(values);
    setShowLoader(true);
    let result = await dispatch(handleRegister(values));

    if (result) {
      setSuccess(true);
      // navigate("/");
    }
    setShowLoader(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div id="auth_container">
        <div className="auth_logo_container">
          <img src={logo} alt="logo" />
        </div>

        <div className="auth_component_container">
          {success ? (
            <>
              <Alert
                variant="filled"
                severity="success"
                sx={{
                  fontSize: "16px",
                  backgroundColor: "#EDF7ED",
                  padding: "8px 16px",
                  color: "#2E7D32",
                  lineHeight: "24px",
                  letterSpacing: "0.15px",
                }}
              >
                We are thrilled to have you onboard! A member of our team will
                be in touch shortly. In the meantime, you can visit our website
                to learn about our mission to help mechanics grow their
                businesses.
              </Alert>

              <Button
                className="log_in_btn"
                variant="contained"
                color="secondary"
                type="submit"
                disableElevation
                fullWidth
                onClick={() => window.location.replace(backToApex)}
              >
                Take me to apexmechanic.com
              </Button>
            </>
          ) : (
            <>
              <div className="formTitle" style={{ color: "#27272A" }}>
                Sign Up
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack rowGap={4}>
                  <Stack rowGap={3}>
                    <Grid container gap={3}>
                      <Grid
                        container
                        item
                        xl={12}
                        md={12}
                        sm={12}
                        xs={12}
                        gap={3}
                      >
                        <Grid
                          item
                          xl={5.6}
                          lg={5.6}
                          md={5.6}
                          sm={5.6}
                          xs={12}
                          xxs={12}
                        >
                          <Stack>
                            <Typography
                              fontSize={"14px"}
                              fontWeight={500}
                              lineHeight={"150%"}
                              fontStyle={"normal"}
                              color={"#27272A"}
                              sx={{ marginBottom: "8px" }}
                            >
                              First Name<span className="asterik">*</span>
                            </Typography>
                            <Controller
                              name="firstName"
                              control={control}
                              rules={{ required: "First Name is required" }}
                              render={({ field }) => (
                                <TextField
                                  variant="outlined"
                                  fullWidth
                                  className="auth_input_field"
                                  sx={{ height: "48px" }}
                                  error={!!errors.firstName}
                                  helperText={
                                    errors.firstName
                                      ? errors.firstName.message
                                      : ""
                                  }
                                  {...field}
                                  onKeyPress={handleKeyPress}
                                />
                              )}
                            />
                          </Stack>
                        </Grid>
                        <Grid
                          item
                          xl={5.7}
                          lg={5.6}
                          md={5.7}
                          sm={5.6}
                          xs={12}
                          xxs={12}
                        >
                          <Stack>
                            <Typography
                              fontSize={"14px"}
                              fontWeight={500}
                              lineHeight={"150%"}
                              fontStyle={"normal"}
                              color={"#27272A"}
                              sx={{ marginBottom: "8px" }}
                            >
                              Last Name<span className="asterik">*</span>
                            </Typography>
                            <Controller
                              name="lastName"
                              control={control}
                              rules={{ required: "Last Name is required" }}
                              render={({ field }) => (
                                <TextField
                                  variant="outlined"
                                  fullWidth
                                  className="auth_input_field"
                                  sx={{ height: "48px" }}
                                  error={!!errors.lastName}
                                  helperText={
                                    errors.lastName
                                      ? errors.lastName.message
                                      : ""
                                  }
                                  {...field}
                                  onKeyPress={handleKeyPress}
                                />
                              )}
                            />
                          </Stack>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        xxs={12}
                      >
                        <Stack>
                          <Typography
                            fontSize={"14px"}
                            fontWeight={500}
                            lineHeight={"150%"}
                            fontStyle={"normal"}
                            color={"#27272A"}
                            sx={{ marginBottom: "8px" }}
                          >
                            Phone Number<span className="asterik">*</span>
                          </Typography>
                          <Controller
                            name="phoneNumber"
                            control={control}
                            rules={{
                              required: "Phone Number is required",

                              pattern: {
                                value:
                                  /^(\+?1[ -]?)?(\([2-9][0-9]{2}\)[ -]?|[2-9][0-9]{2}[ -]?)\d{3}[ -]?\d{4}$/,
                                message:
                                  "Please enter a valid Canadian phone number",
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                type="number"
                                fullWidth
                                className="auth_input_field"
                                sx={{ height: "48px" }}
                                rules={{
                                  required: "Phone Number is required",

                                  pattern: {
                                    value:
                                      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                                    message: "Phone Number is invalid",
                                  },
                                }}
                                error={!!errors.phoneNumber}
                                helperText={
                                  errors.phoneNumber
                                    ? errors.phoneNumber.message
                                    : ""
                                }
                                {...field}
                                onKeyPress={handleKeyPress}
                              />
                            )}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xl={12} md={12} sm={12} xs={12} xxs={12}>
                        <Stack>
                          <Typography
                            fontSize={"14px"}
                            fontWeight={500}
                            lineHeight={"150%"}
                            fontStyle={"normal"}
                            color={"#27272A"}
                            sx={{ marginBottom: "8px" }}
                          >
                            Business Name<span className="asterik">*</span>
                          </Typography>
                          <Controller
                            name="businessName"
                            control={control}
                            rules={{ required: "Business name is required" }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                fullWidth
                                className="auth_input_field"
                                sx={{ height: "48px" }}
                                error={!!errors.businessName}
                                helperText={
                                  errors.businessName
                                    ? errors.businessName.message
                                    : ""
                                }
                                {...field}
                                onKeyPress={handleKeyPress}
                              />
                            )}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Stack>

                  <Button
                    className="log_in_btn"
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disableElevation
                    fullWidth
                  >
                    {showLoader && (
                      <CircularProgress
                        size={25}
                        style={{ color: "white", marginLeft: "10px" }}
                      />
                    )}
                    Request Access
                  </Button>
                  <Link
                    color="primary"
                    variant="body1"
                    justifyContent={"center"}
                    display={"flex"}
                    fontWeight={500}
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    Back to Login
                  </Link>
                </Stack>
              </form>
            </>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default SignUP;
