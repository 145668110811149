import { API } from "../../config/api/index";

//ALL TYPE WISE DATA RETURN
const initState = {
  //   login: Cookies.get("logedIn") ? Cookies.get("logedIn") : false,
  getApprovalsListing: [],

  getApprovalDetails: [],

  currentApproval: {},
};

//DEFINE AUTH REDUCER FUNCTION
const ApprovalsReducers = (state = initState, action) => {
  switch (action.type) {
    case API.approvals.currentApproval:
      return {
        ...state,
        currentApproval: action.currentApproval,
      };
    case API.approvals.getApprovalsListing:
      return {
        ...state,
        getApprovalsListing: action.getApprovalsListing,
      };
    case API.approvals.getApprovalDetails:
      return {
        ...state,
        getApprovalDetails: action.getApprovalDetails,
      };


    default:
      return state;
  }
};

//EXPORT ALL FUNCTION
export default ApprovalsReducers;
