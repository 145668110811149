import { API } from "../../config/api";
import { DataService } from "../../config/dataService/dataService";

export const getPauseAppointmentStatus = () => async (dispatch) => {
  try {
    const response = await DataService.get(API.pauseAppointment.get);
    if (response.data.status) {
      dispatch({
        type: API.pauseAppointment.get,
        pauseAppointment: response.data.data,
      });
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const editPauseAppointmentStatus = (payload) => async (dispatch) => {
  try {
    const response = await DataService.post(
      API.pauseAppointment.update,
      payload
    );
    if (response.data.status) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
