import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Alert,
  AppBar,
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Modal,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { default as React, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { REQUIRED_RED } from "../../../App";
import { getCityList, getProvinceList } from "../../../redux/Customer/action";
import { CONTACT_PREFRENCES, PHONE_TYPES } from "../../../utility/Constant";
import {
  customStyles,
  errorCustomStyles,
  phoneTypeCustomStyles,
} from "../../../utility/SelectDropdown";
import { inputFormatPhoneNumber } from "../../Common/FormatPhoneNumber";
// import "../Customers.css";
import AddressSelect from "../../Customers/Profile/AddressSelect";

import PostalCode from "../../../components/Address/PostalCode";
import { formatPostalCode } from "../../../components/FormatNumber/FormatNumber";
import { handlePreventKeyDown } from "../../../utility/helpers/commonFunction";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "2px solid ",
  boxShadow: 24,
  // p: 3,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function SupplierEditModal({
  openEditSupplier,
  handleCloseSupplier,
  onSubmitSupplier,

  addressValues = {},
  setAddressValue = () => {},
  // primaryEmailIndex,
  // setPrimaryEmailIndex,
  setOpenDelete,
  type,
}) {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue: setValueinForm,
    setError,
    clearErrors,
  } = useForm();

  const [editModalTabvalue, setEditModalTabValue] = useState(0);
  const [phoneTypes, setPhoneTypes] = useState(PHONE_TYPES);
  const [contactPrefrences, setContactPrefrences] =
    useState(CONTACT_PREFRENCES);
  const [showSecondaryNumber, setShowSecondaryNumber] = useState(false);
  const [selectedPhoneTypes, setSelectedPhoneTypes] = useState({
    primary: "mobile",
    secondary: "mobile",
  });
  const [getAddress, setAddress] = useState();
  const [country, setCountry] = useState();
  const [primaryEmailIndex, setPrimaryEmailIndex] = useState(0);
  const [showSecondaryEmail, setShowSecondaryEmail] = useState(false);
  const [contactPrefrenceValue, setContactPrefrenceValue] = useState("");
  const handleChangeEditCustomerTabValue = (event, newValue) => {
    setEditModalTabValue(newValue);
  };
  const [cityList, setCityList] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [cityListLoader, setCityListLoader] = useState(false);
  const [provinceListLoader, setProvinceListLoader] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");

  // const [openDelete, setOpenDelete] = useState(false);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const handleDeleteSupplier = () => {};

  useEffect(() => {
    fetchCityList();
    fetchProvinceList();
    setValueinForm(
      "primaryPhoneNumberType",
      selectedPhoneTypes
        ? phoneTypes.find((option) => option.key == selectedPhoneTypes?.primary)
        : ""
    );
  }, []);

  const fetchCityList = async (province, country) => {
    setCityListLoader(true);
    let resp = await dispatch(
      getCityList({ country: "64e738882fec8f2c9b79c139", province: province })
    );
    if (resp) {
      // let finalCity = [...resp];
      // finalCity = finalCity
      // .filter((obj) => obj.hasOwnProperty("city"))
      // .map((obj) => obj.city);

      setCityList(resp);
    }
    setCityListLoader(false);
  };

  const fetchProvinceList = async (country) => {
    setProvinceListLoader(true);
    let resp = await dispatch(
      getProvinceList({ country: "64e738882fec8f2c9b79c139" })
    );
    if (resp) {
      // let finalProvince = [...resp];
      // finalProvince = finalProvince
      // .filter((obj) => obj.hasOwnProperty("province"))
      // .map((obj) => obj.province);

      setProvinceList(resp);
    }
    setProvinceListLoader(false);
  };

  const data = useSelector((state) => state.customer.customerDetail);
  useEffect(() => {
    reset();
    setShowSecondaryNumber(false);
    setShowSecondaryEmail(false);

    if (data.primaryPhoneNumberType) {
      setValueinForm(
        "primaryPhoneNumberType",
        data.primaryPhoneNumberType
          ? phoneTypes.find(
              (option) => option.key == data.primaryPhoneNumberType
            )
          : ""
      );
    } else {
      setValueinForm(
        "primaryPhoneNumberType",
        selectedPhoneTypes
          ? phoneTypes.find(
              (option) => option.key == selectedPhoneTypes?.primary
            )
          : ""
      );
    }
    setValueinForm("firstName", data?.firstName);
    setValueinForm("lastName", data?.lastName);
    setValueinForm("phoneNumber", inputFormatPhoneNumber(data?.phoneNumber));
    setValueinForm("email", data?.email);
    setValueinForm("company", data?.company);
    setValueinForm("contactPrefrence", data?.contactPrefrence);

    setValueinForm("province", data?.province?.province);
    setValueinForm("city", data?.city?.city);
    setValueinForm("streetAddress", data?.streetAddress);
    setValueinForm("country", data?.country?.country);
    setValueinForm("postalCode", formatPostalCode(data?.postalCode));
    setAddress(data?.streetAddress);
    setAddressValue({
      ...addressValues,
      city: data?.city?.city,
      province: data?.province?.province,
      cityId: data?.city?._id,
      provinceId: data?.province?._id,
      streetAddress: data?.streetAddress,
      postal: formatPostalCode(data?.postalCode),
    });
    setCountry(data?.country?.country);
    setContactPrefrenceValue(data?.contactPrefrence);
    if (data?.secondaryPhoneNumber) {
      setShowSecondaryNumber(true);
      setValueinForm(
        "secondaryPhoneNumberType",
        data.secondaryPhoneNumberType
          ? phoneTypes.find(
              (option) => option.key == data?.secondaryPhoneNumberType
            )
          : ""
      );
      setValueinForm(
        "secondaryPhoneNumber",
        inputFormatPhoneNumber(data?.secondaryPhoneNumber)
      );
    }
    if (data?.secondaryEmail) {
      setShowSecondaryEmail(true);
      setValueinForm("secondaryEmail", data?.secondaryEmail);
    }
  }, [data]);

  const handleUpdate = (data) => {
    const { streetAddress, city, province, postalCode } = data;

    const isAnyFieldFilled = [streetAddress, city, province, postalCode].some(
      (field) => field && field.trim() !== ""
    );
    if (isAnyFieldFilled) {
      if (!streetAddress)
        setError("streetAddress", {
          type: "required",
          message: "Street address is required",
        });
      if (!city)
        setError("city", { type: "required", message: "City is required" });
      if (!province)
        setError("province", {
          type: "required",
          message: "Province is required",
        });
      if (!postalCode)
        setError("postalCode", {
          type: "required",
          message: "Postal code is required",
        });

      if (!streetAddress || !city || !province || !postalCode) {
        setEditModalTabValue(1);
        return;
      }
    } else {
      clearErrors();
    }
    onSubmitSupplier(data);
  };

  return (
    <div>
      <Modal
        open={openEditSupplier}
        onClose={() => {
          handleCloseSupplier();
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            ...style,
            border: "none",
            borderRadius: "8px 8px 0px 0px",
          }}
          className="modalBodyBoxContainer"
        >
          <AppBar
            color="secondary"
            variant="elevation"
            sx={{
              backgroundColor: "#002394",
              color: "white",
              height: "64px",
              borderRadius: "8px 8px 0px 0px !important",
              paddingRight: "0px !important",
            }}
          >
            <Toolbar variant="regular">
              <Typography variant="h6" width={"98%"} color={"white"}>
                Edit Supplier Details
              </Typography>
              <Stack direction={"row"} spacing={0}>
                <div
                  onClick={() => {
                    handleCloseSupplier();
                  }}
                  style={{ cursor: "pointer", height: "32px" }}
                >
                  <CloseIcon style={{ color: "#0000008F", fontSize: 32 }} />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>
          <Container
            gap={2}
            disableGutters
            style={{
              marginTop: "66px",
              // scrollbarColor: "blue",
              // scrollbarWidth: "thin",
              // overflowY: "auto",
              // maxHeight: "80vh",
              // position: "relative",
            }}
          >
            <form onSubmit={handleSubmit(handleUpdate)}>
              <Grid
                container
                // spacing={3}
                style={{
                  scrollbarColor: "blue",
                  scrollbarWidth: "thin",
                  overflowY: "auto",
                  maxHeight: "70vh",
                }}
                padding={"24px 24px 0 24px"}
              >
                <Tabs
                  value={editModalTabvalue}
                  onChange={handleChangeEditCustomerTabValue}
                  aria-label="basic tabs example"
                  sx={{ marginBottom: "24px" }}
                >
                  <Tab
                    label="BASIC DETAILS"
                    // style={{ display: type == SHOP_IMPORTED && "none" }}
                    {...a11yProps(0)}
                  />

                  <Tab label="ADDITIONAL DETAILS" {...a11yProps(1)} />
                </Tabs>

                <div
                  style={{ display: editModalTabvalue == 0 ? "block" : "none" }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} xl={6}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Supplier Name{" "}
                          <span style={{ color: "#D32F2F" }}> *</span>
                        </Typography>
                        <Controller
                          name="supplierName"
                          control={control}
                          rules={{
                            required: "Supplier name is required",
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              // required
                              id="outlined-basic"
                              variant="outlined"
                              error={!!errors.supplierName}
                              helperText={
                                errors.supplierName
                                  ? errors.supplierName.message
                                  : ""
                              }
                            />
                          )}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} xl={6}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Account #
                          {/* <span style={{ color: "#D32F2F" }}> *</span> */}
                        </Typography>
                        <Controller
                          name="account"
                          control={control}
                          // rules={{
                          //   required: "Last Name is required",
                          // }}
                          render={({ field }) => (
                            <TextField
                              fullWidth
                              // required
                              id="outlined-basic"
                              variant="outlined"
                              error={!!errors.account}
                              helperText={
                                errors.account ? errors.account.message : ""
                              }
                              {...field}
                            />
                          )}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} xl={6}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Contact First Name{" "}
                          <span style={{ color: "#D32F2F" }}> *</span>
                        </Typography>
                        <Controller
                          name="contactFirstName"
                          control={control}
                          rules={{
                            required: "Contact First Name is required",
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              // required
                              id="outlined-basic"
                              variant="outlined"
                              error={!!errors.contactFirstName}
                              helperText={
                                errors.contactFirstName
                                  ? errors.contactFirstName.message
                                  : ""
                              }
                            />
                          )}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} xl={6}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Contact Last Name
                          {/* <span style={{ color: "#D32F2F" }}> *</span> */}
                        </Typography>
                        <Controller
                          name="contactLastName"
                          control={control}
                          // rules={{
                          //   required: "Last Name is required",
                          // }}
                          render={({ field }) => (
                            <TextField
                              fullWidth
                              // required
                              id="outlined-basic"
                              variant="outlined"
                              error={!!errors.contactLastName}
                              helperText={
                                errors.contactLastName
                                  ? errors.contactLastName.message
                                  : ""
                              }
                              {...field}
                            />
                          )}
                        />
                      </Stack>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={12} xl={12}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Stack spacing={1}>
                          <Typography
                            variant="body1"
                            style={{
                              fontWeight: 500,
                              fontSize: "16px",
                              lineHeight: "24px",
                              letterSpacing: "0.15px",
                            }}
                          >
                            {" "}
                            Primary Phone Number{" "}
                            <span style={{ color: REQUIRED_RED }}>*</span>
                          </Typography>
                          <div style={{ display: "flex" }}>
                            <Grid container spacing={2} rowGap={2}>
                              <Grid item xs={12} sm={12} md={8} lg={3} xl={3}>
                                <FormControl
                                  fullWidth
                                  error={!!errors.primaryPhoneNumberType}
                                >
                                  <Controller
                                    name="primaryPhoneNumberType"
                                    control={control}
                                    rules={{
                                      required: "Phone Number Type is required",
                                    }}
                                    render={({ field }) => (
                                      <Select
                                        placeholder="" // defaultValue={}
                                        isLoading={false}
                                        isClearable={false}
                                        isSearchable={false}
                                        value={
                                          selectedPhoneTypes
                                            ? phoneTypes.find(
                                                (option) =>
                                                  option.key ==
                                                  selectedPhoneTypes?.primary
                                              )
                                            : ""
                                        }
                                        options={phoneTypes ? phoneTypes : []}
                                        getOptionLabel={(option) =>
                                          option.value
                                        } // Specifies which field to use as the label
                                        getOptionValue={(option) => option.key}
                                        onChange={(e) => {
                                          setSelectedPhoneTypes({
                                            ...selectedPhoneTypes,
                                            primary: e ? e.key : "",
                                          });
                                        }}
                                        styles={
                                          errors.primaryPhoneNumberType
                                            ? {
                                                ...customStyles,
                                                ...errorCustomStyles,
                                                ...phoneTypeCustomStyles,
                                              }
                                            : {
                                                ...customStyles,
                                                ...phoneTypeCustomStyles,
                                              }
                                        }
                                        error={!!errors.primaryPhoneNumberType}
                                        helperText={
                                          errors.primaryPhoneNumberType
                                            ? errors.primaryPhoneNumberType
                                                .message
                                            : ""
                                        }
                                        {...field}
                                      />
                                    )}
                                  />
                                  {errors.primaryPhoneNumberType && (
                                    <FormHelperText>
                                      {errors.primaryPhoneNumberType.message}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
                                <Controller
                                  name="phoneNumber"
                                  control={control}
                                  rules={{
                                    required: "Phone Number is required",
                                    pattern: {
                                      value: /^\d{3}-\d{3}-\d{4}$/,
                                      message:
                                        "Phone number should be 10 digits long",
                                    },
                                  }}
                                  render={({ field }) => (
                                    <TextField
                                      id="outlined-basic"
                                      /*label="Phone Number"*/
                                      //  onChange={(e)=>console.log("ee",e)}
                                      variant="outlined"
                                      fullWidth
                                      // required

                                      error={!!errors.phoneNumber}
                                      helperText={
                                        errors.phoneNumber
                                          ? errors.phoneNumber.message
                                          : ""
                                      }
                                      {...field}
                                      onChange={(e) => {
                                        setValueinForm(
                                          "phoneNumber",
                                          inputFormatPhoneNumber(e.target.value)
                                        );
                                        if (e.target.value.length == 12) {
                                          clearErrors("phoneNumber");
                                        } else {
                                          setError("phoneNumber", {
                                            message:
                                              "Phone number should be 10 digits long",
                                          });
                                        }
                                      }}
                                      onKeyDown={handlePreventKeyDown}
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </div>
                        </Stack>
                        {!showSecondaryNumber && (
                          <div
                            onClick={() => setShowSecondaryNumber(true)}
                            style={{
                              color: "#006CDD",
                              cursor: "pointer",
                              fontWeight: 500,
                              height: "36px",
                              padding: "4px 5px",
                              letterSpacing: "0.46px",
                              fontSize: "14px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            + ADD PHONE NUMBER
                          </div>
                        )}
                      </Grid>
                    </Grid>
                    {showSecondaryNumber && (
                      <Grid item container xs={12} sm={12} md={12} xl={12}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Stack spacing={1}>
                            <Typography
                              variant="body1"
                              style={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                                letterSpacing: "0.15px",
                              }}
                            >
                              {" "}
                              Secondary Phone Number{" "}
                              {/* <span style={{ color: REQUIRED_RED }}>*</span> */}
                            </Typography>
                            <div style={{ display: "flex" }}>
                              <Grid container spacing={2} rowGap={2}>
                                <Grid item xs={12} sm={12} md={8} lg={3} xl={3}>
                                  <FormControl
                                    fullWidth
                                    error={!!errors.secondaryPhoneNumberType}
                                  >
                                    <Controller
                                      name="secondaryPhoneNumberType"
                                      control={control}
                                      render={({ field }) => (
                                        <Select
                                          placeholder="" // defaultValue={}
                                          isLoading={false}
                                          isClearable={false}
                                          isSearchable={false}
                                          value={
                                            selectedPhoneTypes
                                              ? phoneTypes.find(
                                                  (option) =>
                                                    option.key ===
                                                    selectedPhoneTypes?.secondary
                                                )
                                              : ""
                                          }
                                          options={phoneTypes ? phoneTypes : []}
                                          getOptionLabel={(option) =>
                                            option.value
                                          } // Specifies which field to use as the label
                                          getOptionValue={(option) => option}
                                          onChange={(e) => {
                                            setSelectedPhoneTypes({
                                              ...selectedPhoneTypes,
                                              secondary: e ? e.key : "",
                                            });
                                          }}
                                          styles={
                                            errors.secondaryPhoneNumberType
                                              ? {
                                                  ...customStyles,
                                                  ...errorCustomStyles,
                                                  ...phoneTypeCustomStyles,
                                                }
                                              : {
                                                  ...customStyles,
                                                  ...phoneTypeCustomStyles,
                                                }
                                          }
                                          error={
                                            !!errors.secondaryPhoneNumberType
                                          }
                                          helperText={
                                            errors.secondaryPhoneNumberType
                                              ? errors.secondaryPhoneNumberType
                                                  .message
                                              : ""
                                          }
                                          {...field}
                                        />
                                      )}
                                    />
                                    {errors.secondaryPhoneNumberType && (
                                      <FormHelperText>
                                        {
                                          errors.secondaryPhoneNumberType
                                            .message
                                        }
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={8}
                                  xl={8}
                                >
                                  <Controller
                                    name="secondaryPhoneNumber"
                                    control={control}
                                    rules={{
                                      pattern: {
                                        value: /^\d{3}-\d{3}-\d{4}$/,
                                        message:
                                          "Phone number should be 10 digits long",
                                      },
                                    }}
                                    render={({ field }) => (
                                      <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        fullWidth
                                        // required
                                        error={!!errors.secondaryPhoneNumber}
                                        helperText={
                                          errors.secondaryPhoneNumber
                                            ? errors.secondaryPhoneNumber
                                                .message
                                            : ""
                                        }
                                        {...field}
                                        onChange={(e) => {
                                          setValueinForm(
                                            "secondaryPhoneNumber",
                                            inputFormatPhoneNumber(
                                              e.target.value
                                            )
                                          );
                                          if (
                                            e.target.value.length == 12 ||
                                            e.target.value.length == 0
                                          ) {
                                            clearErrors("secondaryPhoneNumber");
                                          } else {
                                            setError("secondaryPhoneNumber", {
                                              message:
                                                "Phone number should be 10 digits long",
                                            });
                                          }
                                        }}
                                        onKeyDown={handlePreventKeyDown}
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={2} sm={2} md={1} xl={1}>
                                  <div
                                    style={{
                                      height: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <IconButton
                                      color="error"
                                      size="medium"
                                      onClick={() => {
                                        setShowSecondaryNumber(false);
                                        setValueinForm(
                                          "secondaryPhoneNumber",
                                          ""
                                        );
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </Stack>
                        </Grid>
                      </Grid>
                    )}

                    <Grid item container>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="body1" className="label-500">
                          {" "}
                          Email
                        </Typography>
                        {/* <div style={{ display: "flex", flexDirection: "column" }}> */}
                        {/* <Grid container item spacing={3} rowGap={0}> */}
                        <div style={{ display: "flex", gap: "24px" }}>
                          <Grid xs={12}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Controller
                                name="email"
                                control={control}
                                // rules={{
                                //   required: "Email is required",
                                // }}
                                render={({ field }) => (
                                  <TextField
                                    id="outlined-basic"
                                    /*label="Email"*/
                                    variant="outlined"
                                    fullWidth
                                    // required
                                    error={!!errors.email}
                                    helperText={
                                      errors.email ? errors.email.message : ""
                                    }
                                    {...field}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={2} sm={2} md={6} xl={6}>
                              <div
                                style={{
                                  height: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={0 == primaryEmailIndex}
                                      onChange={(e) => {
                                        console.log("www", e.target.checked);
                                        showSecondaryEmail &&
                                          setPrimaryEmailIndex(
                                            e.target.checked ? 0 : 1
                                          );
                                      }}
                                    />
                                  }
                                  label="Primary"
                                />
                              </div>
                              {/* <CheckBox  >Primary</CheckBox> */}
                              {/* <IconButton
                          color="error"
                          size="medium"
                          onClick={() => setShowSecondaryNumber(false)}
                        >
                          <DeleteIcon />
                        </IconButton> */}
                            </Grid>
                          </Grid>

                          {/* </Grid> */}
                          {/* </div> */}

                          {/* <Grid container item columnGap={2} rowGap={2} marginTop={2}> */}
                          <Grid xs={12}>
                            {showSecondaryEmail ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                >
                                  <Controller
                                    name="secondaryEmail"
                                    control={control}
                                    // rules={{
                                    //   required: "Email is required",
                                    // }}
                                    render={({ field }) => (
                                      <TextField
                                        id="outlined-basic"
                                        /*label="Email"*/
                                        variant="outlined"
                                        fullWidth
                                        // required
                                        error={!!errors.secondaryEmail}
                                        helperText={
                                          errors.secondaryEmail
                                            ? errors.secondaryEmail.message
                                            : ""
                                        }
                                        {...field}
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={2} sm={2} md={4} lg={4} xl={4}>
                                  <div
                                    style={{
                                      height: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={1 == primaryEmailIndex}
                                          onChange={(e) => {
                                            console.log(
                                              "www",
                                              e.target.checked
                                            );
                                            setPrimaryEmailIndex(
                                              e.target.checked ? 1 : 0
                                            );
                                          }}
                                        />
                                      }
                                      label="Primary"
                                    />
                                    {/* <CheckBox  >Primary</CheckBox> */}
                                    <IconButton
                                      color="error"
                                      size="medium"
                                      onClick={() => {
                                        setShowSecondaryEmail(false);
                                        setPrimaryEmailIndex(0);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </div>
                                </Grid>
                              </>
                            ) : (
                              <div
                                onClick={() => setShowSecondaryEmail(true)}
                                style={{
                                  color: "#006CDD",
                                  cursor: "pointer",
                                  fontWeight: 500,
                                  height: "36px",
                                  padding: "4px 5px",
                                  letterSpacing: "0.46px",
                                  fontSize: "14px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                + ADD EMAIL
                              </div>
                            )}
                          </Grid>
                        </div>

                        {/* </Grid> */}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography variant="body1" className="label-500">
                        Website
                      </Typography>
                      <Controller
                        name="website"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            id="outlined-basic"
                            /*label="Last Name"*/
                            variant="outlined"
                            fullWidth
                            // required
                            error={!!errors.website}
                            helperText={
                              errors.website ? errors.website.message : ""
                            }
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div
                  style={{ display: editModalTabvalue == 1 ? "block" : "none" }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <FormControl fullWidth error={!!errors.contactPrefrence}>
                        <Stack spacing={1}>
                          <Typography
                            variant="body1"
                            style={{
                              fontWeight: 500,
                              fontSize: "16px",
                              lineHeight: "24px",
                              letterSpacing: "0.15px",
                            }}
                          >
                            Contact Preferences
                          </Typography>
                          <Controller
                            name="contactPrefrence"
                            control={control}
                            // rules={{
                            //   required: "Phone Number Type is required",
                            // }}
                            render={({ field }) => (
                              <Select
                                placeholder="" // defaultValue={}
                                isDisabled={false}
                                isLoading={false}
                                isClearable={true}
                                isSearchable={false}
                                // value={
                                //   selectedPhoneTypes
                                //     ? contactPrefrences.find(
                                //         (option) =>
                                //           option.key === selectedPhoneTypes?.primary
                                //       )
                                //     : ""
                                // }
                                options={
                                  contactPrefrences ? contactPrefrences : []
                                }
                                getOptionLabel={(option) => option.value} // Specifies which field to use as the label
                                getOptionValue={(option) => option}
                                onChange={(e) => {
                                  field.onChange(e);
                                  setContactPrefrenceValue(e.key);
                                }}
                                value={
                                  contactPrefrences?.filter(
                                    (d) => d.key == contactPrefrenceValue
                                  )[0]
                                }
                                styles={
                                  errors.contactPrefrence
                                    ? {
                                        ...customStyles,
                                        ...errorCustomStyles,
                                      }
                                    : {
                                        ...customStyles,
                                      }
                                }
                                error={!!errors.contactPrefrence}
                                helperText={
                                  errors.contactPrefrence
                                    ? errors.contactPrefrence.message
                                    : ""
                                }
                                // {...field}
                              />
                            )}
                          />
                          {errors.contactPrefrence && (
                            <FormHelperText>
                              {errors.contactPrefrence.message}
                            </FormHelperText>
                          )}
                        </Stack>
                      </FormControl>
                    </Grid>
                    {/* <Grid item xs={12} md={12} xl={12}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Company
                        
                        </Typography>
                        <Controller
                          name="company"
                          control={control}
                          // rules={{
                          //   required: "Street Address is required",
                          // }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="outlined-basic"
                              variant="outlined"
                              fullWidth
                              // defaultValue={addressValues?.streetAddress}
                              // required
                              InputLabelProps={{
                                shrink: field.value?.length > 0,
                              }}
                              error={!!errors.company}
                              helperText={
                                errors.company ? errors.company.message : ""
                              }
                            />
                          )}
                        />
                      </Stack>
                    </Grid> */}
                    <Grid item xs={12} md={12} xl={12}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Street Address
                          {/* {(addressValues.streetAddress ||
                            addressValues.province ||
                            addressValues.city ||
                            addressValues.postal) && (
                            <span style={{ color: "#D32F2F" }}> *</span>
                          )} */}
                        </Typography>
                        <Controller
                          name="streetAddress"
                          control={control}
                          // rules={{
                          //   required: (addressValues.city !== undefined || addressValues.city !== '' ||
                          //     addressValues.province == undefined || addressValues.province == "" ||
                          //     addressValues.postal !== '') &&
                          //     (addressValues.streetAddress == '') ? "Street Address is required" : false,
                          // }}
                          render={({ field }) => (
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              fullWidth
                              error={!!errors.streetAddress}
                              helperText={
                                errors.streetAddress
                                  ? errors.streetAddress.message
                                  : ""
                              }
                              {...field}
                              onChange={(e) => {
                                setValueinForm("streetAddress", e.target.value);
                                setAddressValue({
                                  ...addressValues,
                                  streetAddress: e.target.value,
                                });
                              }}
                            />
                          )}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4} xl={4}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          City
                          {/* {(addressValues.streetAddress ||
                            addressValues.province ||
                            addressValues.city ||
                            addressValues.postal) && (
                            <span style={{ color: "#D32F2F" }}> *</span>
                          )} */}
                        </Typography>
                        <Controller
                          name="city"
                          control={control}
                          render={({ field }) => (
                            <AddressSelect
                              list={cityList}
                              addressValues={addressValues}
                              setAddressValue={setAddressValue}
                              type={"city"}
                              loader={cityListLoader}
                              setValue={setValueinForm}
                              error={!!errors.city}
                              menuPlacement={"top"}
                            />
                          )}
                        />
                        {errors && errors.city && (
                          <p
                            style={{
                              color: "#d32f2f",
                              fontSize: "0.75rem",
                              letterSpacing: "0.03333em",
                              lineHeight: "1.66",
                              marginTop: "3px",
                            }}
                          >
                            {errors.city?.message}
                          </p>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4} xl={4}>
                      <Stack spacing={1}>
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          Province
                          {/* {(addressValues.streetAddress ||
                            addressValues.province ||
                            addressValues.city ||
                            addressValues.postal) && (
                            <span style={{ color: "#D32F2F" }}> *</span>
                          )} */}
                        </Typography>
                        <Controller
                          name="province"
                          control={control}
                          render={({ field }) => (
                            <AddressSelect
                              list={provinceList}
                              addressValues={addressValues}
                              setAddressValue={setAddressValue}
                              type={"province"}
                              setCityList={setCityList}
                              fetchCityList={fetchCityList}
                              loader={provinceListLoader}
                              setValue={setValueinForm}
                              error={!!errors.province}
                              menuPlacement={"top"}
                            />
                          )}
                        />
                        {errors && errors.province && (
                          <p
                            style={{
                              color: "#d32f2f",
                              fontSize: "0.75rem",
                              letterSpacing: "0.03333em",
                              lineHeight: "1.66",
                              marginTop: "3px",
                            }}
                          >
                            {errors.province?.message}
                          </p>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4} xl={4}>
                      <PostalCode
                        name="postalCode"
                        control={control}
                        errors={errors}
                        setValueinForm={setValueinForm}
                        setAddressValue={setAddressValue}
                        addressValues={addressValues}
                        setError={setError}
                        clearErrors={clearErrors}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>

              <Grid
                item
                container
                justifyContent={"space-between"}
                // spacing={2}
                style={{ marginTop: "24px" }}
                padding={"0px 24px 24px 24px"}
              >
                <Grid item>
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => setOpenDelete(true)}
                      size="large"
                    >
                      DELETE
                    </Button>
                  </Stack>
                </Grid>
                <Grid item>
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        handleCloseSupplier();
                      }}
                      size="large"
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                      onClick={() => {
                        if (Object.keys(errors)?.length != 0) {
                          setEditModalTabValue(0);
                        }
                      }}
                    >
                      Save
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Box>
      </Modal>
      <Snackbar
        open={openSuccessModal}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message="I love snacks"
        key={"top" + "center"}
        onClose={() => setOpenSuccessModal(false)}
        autoHideDuration={2000}
      >
        <Alert severity={"error"}>{successMessage}</Alert>
      </Snackbar>
    </div>
  );
}

export default SupplierEditModal;
