import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  AppBar,
  Badge,
  Box,
  Container,
  Grid,
  Modal,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CarfaxHistoryTable from "./CarfaxHistoryTable";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "2px solid ",
  boxShadow: 24,
  outline: "none",
};
function CarfaxHistoryModel({ open, handleClose, vehicleData }) {
  const [recallData, setRecallData] = useState({});
  useEffect(() => {
    if (open) {
      setRecallData({});
    }
  }, [open]);
  console.log("🚀 ~ CarfaxHistoryModel ~ recallData:", recallData);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            ...style,
            // width: "41vw",
            // width: "100%",
            // maxWidth: "50vw",
            // maxHeight: "90vh",
            // overflow: "hidden",
            // overflowY: "scroll",
          }}
          className="modelSize"
        >
          <AppBar
            color=""
            variant="elevation"
            sx={{
              height: "64px",
              paddingRight: "0px !important",
            }}
          >
            <Toolbar variant="regular">
              <Typography variant="h6" width={"98%"} color={"#000000DE"}>
                View CARFAX Report
              </Typography>
              <Stack direction={"row"} spacing={0}>
                <div
                  onClick={handleClose}
                  style={{ cursor: "pointer", height: "32px" }}
                >
                  <CloseIcon
                    style={{
                      color: "#0000008F",
                      fontSize: 32,
                      fill: "#0000008F",
                    }}
                  />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>
          <Container
            gap={2}
            disableGutters
            // className="viewVehicleModal"
            style={{
              marginTop: "64px",
              maxHeight: "72vh",
              overflow: "auto",
            }}
          >
            {Object.keys(recallData)?.length > 0 &&
              recallData?.numberOfRecallRecords > 0 && (
                <div
                  style={{
                    border: "1px solid #D32F2F",
                    background: "rgba(211, 47, 47, 0.06)",
                    margin: "24px 24px 0px 24px",
                    padding: "18px 16px 16px 16px",
                    rowGap: "8px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "18px",
                      color: "#D32F2F",
                    }}
                  >
                    <Badge
                      badgeContent={recallData?.numberOfRecallRecords}
                      color="error"
                      sx={{ marginLeft: "10px" }}
                    />

                    <Typography fontWeight={500} color="#D32F2F">
                      Recalls
                    </Typography>
                  </div>
                  <Typography
                    fontSize={"14px"}
                    fontWeight={400}
                    color={"#9E1616"}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: recallData?.recallDisclaimer,
                      }}
                    ></div>
                  </Typography>
                </div>
              )}

            <Grid mt={3} ml={3}>
              <Typography
                fontWeight={500}
                fontSize={"24px"}
                color={"#000000DE"}
                display={"flex"}
                alignItems={"center"}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M16 28C12.9333 28 10.2613 26.9836 7.984 24.9507C5.70667 22.9178 4.40089 20.3787 4.06667 17.3333H6.8C7.11111 19.6444 8.13911 21.5556 9.884 23.0667C11.6289 24.5778 13.6676 25.3333 16 25.3333C18.6 25.3333 20.8058 24.428 22.6173 22.6173C24.4289 20.8067 25.3342 18.6009 25.3333 16C25.3324 13.3991 24.4271 11.1938 22.6173 9.384C20.8076 7.57422 18.6018 6.66844 16 6.66667C14.4667 6.66667 13.0333 7.02222 11.7 7.73333C10.3667 8.44444 9.24444 9.42222 8.33333 10.6667H12V13.3333H4V5.33333H6.66667V8.46667C7.8 7.04444 9.18356 5.94444 10.8173 5.16667C12.4511 4.38889 14.1787 4 16 4C17.6667 4 19.228 4.31689 20.684 4.95067C22.14 5.58444 23.4067 6.43956 24.484 7.516C25.5613 8.59244 26.4169 9.85911 27.0507 11.316C27.6844 12.7729 28.0009 14.3342 28 16C27.9991 17.6658 27.6827 19.2271 27.0507 20.684C26.4187 22.1409 25.5631 23.4076 24.484 24.484C23.4049 25.5604 22.1382 26.416 20.684 27.0507C19.2298 27.6853 17.6684 28.0018 16 28ZM19.7333 21.6L14.6667 16.5333V9.33333H17.3333V15.4667L21.6 19.7333L19.7333 21.6Z"
                    fill="#2196F3"
                  />
                </svg>
                Service History
              </Typography>
            </Grid>

            <CarfaxHistoryTable
              vehicleData={vehicleData}
              open={open}
              setRecallData={setRecallData}
            />
          </Container>
        </Box>
      </Modal>
    </div>
  );
}

export default CarfaxHistoryModel;
