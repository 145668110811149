import { API } from "../../config/api/index";

//ALL TYPE WISE DATA RETURN
const initState = {
  loading: false,
  pauseAppoinment: {},
};

//DEFINE ACCOUNT REDUCER FUNCTION
const PauseAppointmentReducer = (state = initState, action) => {
  switch (action.type) {
    case API.pauseAppointment.get:
      return {
        ...state,
        pauseAppoinment: action.pauseAppointment,
        loading: action.loading,
      };
    default:
      return state;
  }
};

//EXPORT ALL FUNCTION
export default PauseAppointmentReducer;
