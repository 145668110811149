import React, { useState } from "react";
import Cards from "./Cards";
import { Grid } from "@mui/material";

function Appointments(props) {
  const [appointments, setAppointment] = useState([]);
  return (
    <Grid container spacing={3}>
      {/* {
        invoices?.map(invoice=>{

        })
    } */}
      <Grid item container md={6}>
        <Cards
          activeTab="appointment"
          date="Febuary 23,2023"
          // customerName="John Michale"
          vehicle="2019 Volkswagen Jetta 1.4T SEL"
          chipNumber="123-02"
          amount="$80.9"
          service="Electrical System Diagnosis"
        />
      </Grid>
      <Grid item container md={6}>
        <Cards
          activeTab="appointment"
          date="March 26,2023"
          // customerName="John Michale"
          vehicle="2019 Volkswagen Jetta 1.4T SEL"
          chipNumber="123-02"
          amount="$81.9"
          service="Electrical System Diagnosis"
        />
      </Grid>
      <Grid item container md={6}>
        <Cards
          activeTab="appointment"
          date="January 24,2023"
          // customerName="John Michale"
          vehicle="2019 Volkswagen Jetta 1.4T SEL"
          chipNumber="193-11"
          amount="$82.9"
          service="Electrical System Diagnosis"
        />
      </Grid>{" "}
      <Grid item container md={6}>
        <Cards
          activeTab="appointment"
          date="January 23,2023"
          // customerName="John Michale"
          vehicle="2019 Volkswagen Jetta 1.4T SEL"
          chipNumber="113-01"
          amount="$40.9"
          service="Electrical System Diagnosis"
        />
      </Grid>
      <Grid item container md={6}>
        <Cards
          activeTab="appointment"
          date="January 23,2023"
          // customerName="John Michale"
          vehicle="2019 Volkswagen Jetta 1.4T SEL"
          chipNumber="113-01"
          amount="$40.9"
          service="Electrical System Diagnosis"
        />
      </Grid>
    </Grid>
  );
}

export default Appointments;
