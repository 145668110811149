import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";

const deleteStyle = {
  position: "absolute",
  top: "0%",
  left: "0%",
  width: 400,
  transform: "translate(-0%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

function SupplierDeleteModel({
  openDelete,
  handleCloseDelete,
  handleDeleteSupplier,
}) {
  return (
    <Dialog
      open={openDelete}
      onClose={handleCloseDelete}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="deleteCustomer"
    >
      <Box
        sx={{
          ...deleteStyle,
          width: "100%",
          border: "none",
          borderRadius: "8px 8px 0px 0px",
        }}
      >
        <AppBar
          color=""
          variant="outlined"
          style={{
            width: "inherit",
            borderRadius: "8px 8px 0px 0px !important",
            padding: "0px",
          }}
        >
          <Toolbar variant="regular" sx={{ color: "black" }}>
            <Typography variant="h6" width={"98%"}>
              Delete Supplier
            </Typography>
            <Stack direction={"row"} spacing={0}>
              <div
                onClick={handleCloseDelete}
                style={{ cursor: "pointer", height: "32px" }}
              >
                <CloseIcon sx={{ fill: "black", fontSize: 32 }} />
              </div>
            </Stack>
          </Toolbar>
        </AppBar>
      </Box>
      <Container
        style={{
          marginTop: "64px",
          padding: "0",
        }}
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: "black" }}
          >
            Are you sure you want to delete this supplier?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ mb: "8px" }}>
          <Button onClick={handleCloseDelete} variant="outlined">
            CANCEL
          </Button>
          <Button
            onClick={handleDeleteSupplier}
            autoFocus
            color="error"
            variant="contained"
          >
            DELETE
          </Button>
        </DialogActions>
      </Container>
    </Dialog>
  );
}

export default SupplierDeleteModel;
