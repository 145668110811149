import { Visibility, VisibilityOff } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import logo from "../../../assets/Logo.svg";
import {
  handleResetPassword,
  setFirstTimePassword,
} from "../../../redux/Auth/action";
import "./Resetpswd.css";

const ResetPassword = ({ title }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
    getValues,
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  //FIRST TIME LOGIN
  const param = useParams();
  const location = useLocation();
  const theme = useTheme();
  const userId = localStorage.getItem("id");
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [passwordLengthValid, setPasswordLengthValid] = useState(false);
  const [currentPasswordLengthValid, setCurrentPasswordLengthValid] =
    useState(false);
  const [validNumber, setValidNumber] = useState(false);
  const [validUppercase, setValidUppercase] = useState(false);
  const [validSpacialCharacter, setValidSpacialCharacter] = useState(false);
  const [confirmPasswordMatch, setConfirmPasswordMatch] = useState("");

  const [password, setPassword] = useState("");
  const [disableButton, setDisableButton] = useState(false);

  //FIRST TIME LOGIN
  const pathname = location.pathname.split("/");
  useEffect(() => {
    if (
      pathname[1] == "update-password" &&
      param.token != localStorage.getItem("apex-saas_token")
    ) {
      navigate("/");
    }
  }, []);
  const onSubmit = async (values) => {
    checkValidationError(values?.password);
    if (values?.password != values?.confirmPassword) {
      return setError("confirmPassword", {
        message: "Password and Confirm Passwword should match",
      });
      // displayError("Password and Confirm Passwword does not match");
    }
    setDisableButton(true);
    if (pathname[1] == "update-password") {
      setShowLoader(true);
      let result = await dispatch(setFirstTimePassword(values));
      if (result) {
        const authData = JSON.parse(localStorage.getItem("apex-saas_details"));
        // authData?.allowedPlatforms &&
        // authData?.allowedPlatforms.find((d) => d == "web")
        //   ? navigate("/dashboard")
        //   : navigate("/");
        navigate("/");
      }
      setShowLoader(false);
    } else {
      values.userId = userId;

      setShowLoader(true);
      let result = await dispatch(handleResetPassword(values));
      if (result) {
        localStorage.setItem("alert", true);
        navigate("/");
      }
      setShowLoader(false);
    }
    setDisableButton(false);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConformPassword, setShowConformPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConformPassword = () =>
    setShowConformPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownConformPassword = (event) => {
    event.preventDefault();
  };
  const checkCurrentPasswordLength = (password) => {
    if (password.length >= 8) {
      setCurrentPasswordLengthValid(false);
    } else {
      setCurrentPasswordLengthValid(true);
    }
  };
  const checkPasswordLength = (password) => {
    if (password.length >= 8) {
      setPasswordLengthValid(true);
    } else {
      setPasswordLengthValid(false);
    }
  };
  const checkSpecialCharacters = (password) => {
    const pattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    if (pattern.test(password)) {
      setValidSpacialCharacter(true);
    } else {
      setValidSpacialCharacter(false);
    }
  };
  const checkUppercase = (password) => {
    const pattern = /[A-Z]/;
    if (pattern.test(password)) {
      setValidUppercase(true);
    } else {
      setValidUppercase(false);
    }
  };
  const checkNumber = (password) => {
    const pattern = /[0-9]/;
    if (pattern.test(password)) {
      setValidNumber(true);
    } else {
      setValidNumber(false);
    }
  };
  const checkConfirmPasswordMatch = (password, confirmPassword) => {
    if (password === "" && confirmPassword === "") {
      setConfirmPasswordMatch(true); // Set to true if both are empty
    } else if (password === confirmPassword) {
      setConfirmPasswordMatch(true);
    } else {
      setConfirmPasswordMatch(false);
    }
  };
  const checkValidationError = (password) => {
    const isLengthValid = password.length >= 8;
    const hasNumber = /\d/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasSpecialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(
      password
    );
    if (!isLengthValid || !hasNumber || !hasUppercase || !hasSpecialCharacter)
      setError("password", {
        message: "Password should match the requirement",
      });
    else {
      clearErrors("password");
    }
  };
  console.log("errors", errors);
  return (
    <div id="auth_container">
      <div className="auth_logo_container">
        <img src={logo} alt="logo" />
      </div>
      <div className="auth_component_container">
        <div className="formTitle">{title}</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box component="form" noValidate autoComplete="off">
            <Typography
              fontSize={"14px"}
              fontWeight={500}
              lineHeight={"150%"}
              fontStyle={"normal"}
              color={"#000"}
              sx={{ marginBottom: "8px" }}
            >
              New Password <span style={{ color: "#D32F2F" }}>*</span>
            </Typography>
            <Controller
              name="password"
              control={control}
              rules={{ required: "New Password is required" }}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  className="auth_input_field"
                  sx={{ height: "48px", mb: errors?.password ? "10px" : "0px" }}
                  type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                  error={!!errors.password}
                  helperText={errors.password ? errors.password.message : ""}
                  {...field}
                  onKeyPress={handleKeyPress}
                  onChange={(e) => {
                    field.onChange(e);
                    checkPasswordLength(e.target.value);
                    checkSpecialCharacters(e.target.value);
                    checkUppercase(e.target.value);
                    checkNumber(e.target.value);
                    // checkValidationError(e.target.value);
                  }}
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Box>

          <div className="passowrd_condition">
            <Stack rowGap={0.5} paddingTop={"8px"}>
              <Typography
                variant="body1"
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"21px"}
                color={passwordLengthValid ? "#2E7D32" : "rgba(0, 0, 0, 0.56)"}
                alignItems={"center"}
                display={"flex"}
              >
                {" "}
                <CheckCircleIcon
                  style={{
                    height: "15px",
                    width: "15px",

                    color: passwordLengthValid ? "#2E7D32" : "gray",
                    marginRight: "2px",
                  }}
                />
                Must be at least 8 characters
              </Typography>
              <Typography
                variant="body1"
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"21px"}
                color={validNumber ? "#2E7D32" : "rgba(0, 0, 0, 0.56)"}
                alignItems={"center"}
                display={"flex"}
              >
                <CheckCircleIcon
                  style={{
                    height: "15px",
                    width: "15px",

                    color: validNumber ? "#2E7D32" : "gray",
                    marginRight: "2px",
                  }}
                />
                1 Number
              </Typography>
              <Typography
                variant="body1"
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"21px"}
                color={
                  validSpacialCharacter ? "#2E7D32" : "rgba(0, 0, 0, 0.56)"
                }
                alignItems={"center"}
                display={"flex"}
              >
                <CheckCircleIcon
                  style={{
                    height: "15px",
                    width: "15px",

                    color: validSpacialCharacter ? "#2E7D32" : "gray",

                    marginRight: "2px",
                  }}
                />
                1 Special Character
              </Typography>
              <Typography
                variant="body1"
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"21px"}
                color={validUppercase ? "#2E7D32" : "rgba(0, 0, 0, 0.56)"}
                alignItems={"center"}
                display={"flex"}
              >
                <CheckCircleIcon
                  style={{
                    height: "15px",
                    width: "15px",

                    color: validUppercase ? "#2E7D32" : "gray",

                    marginRight: "2px",
                  }}
                />
                1 Uppercase Letter
              </Typography>
            </Stack>
          </div>

          <Box
            component="form"
            sx={{
              margin: "24px 0",
            }}
            noValidate
            autoComplete="off"
          >
            <Typography
              fontSize={"14px"}
              fontWeight={500}
              lineHeight={"150%"}
              fontStyle={"normal"}
              color={"#000"}
              sx={{ marginBottom: "8px" }}
            >
              Confirm Password <span style={{ color: "#D32F2F" }}>*</span>
            </Typography>
            <Controller
              name="confirmPassword"
              control={control}
              rules={{ required: "Confirm Password is required" }}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  className="auth_input_field"
                  sx={{ height: "48px" }}
                  type={showConformPassword ? "text" : "password"} // <-- This is where the magic happens
                  error={!!errors.password}
                  helperText={
                    errors.confirmPassword ? errors.confirmPassword.message : ""
                  }
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    checkConfirmPasswordMatch(password, e.target.value);
                  }}
                  onKeyPress={handleKeyPress}
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConformPassword}
                          onMouseDown={handleMouseDownConformPassword}
                        >
                          {showConformPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Box>

          <Button
            className="log_in_btn"
            variant="contained"
            color="secondary"
            disableElevation
            size="large"
            type="submit"
            fullWidth
            disabled={disableButton}
          >
            {showLoader ? (
              <CircularProgress
                size={24}
                style={{ color: "white", marginRight: "10px" }}
              />
            ) : (
              ""
            )}
            Log In
          </Button>
        </form>
        <Box className="policy">
          <Typography
            variant="subtitle1"
            fontSize={"16px"}
            fontWeight={400}
            lineHeight={"175%"}
            fontStyle={"normal"}
            color={"#000"}
            letterSpacing={"0.15px"}
          >
            By signing in, you agree to our&nbsp;
            <Link
              variant="body1"
              style={{
                textDecoration: "none",
                cursor: "pointer",
                color: "#006CDD",
                fontWeight: 500,
              }}
              onClick={() =>
                window.location.replace(
                  "https://apexmechanic.com/terms-and-conditions/"
                )
              }
            >
              Terms and Conditions
            </Link>
            , and&nbsp;
            <Link
              variant="body1"
              style={{
                textDecoration: "none",
                cursor: "pointer",
                color: "#006CDD",
                fontWeight: 500,
              }}
              onClick={() =>
                window.location.replace(
                  "https://apexmechanic.com/privacy-policy/"
                )
              }
            >
              Privacy Policy.
            </Link>
          </Typography>
        </Box>
        <Box className="sign_up">
          <Typography
            variant="subtitle1"
            fontSize={"16px"}
            fontWeight={400}
            lineHeight={"175%"}
            fontStyle={"normal"}
            color={"#000"}
            letterSpacing={"0.15px"}
            textAlign={"center"}
          >
            New to Apex Mechanic?{" "}
            <Link
              variant="body1"
              style={{
                fontWeight: "medium",
                color: "#006CDD",
                cursor: "pointer",
                fontWeight: 500,
              }}
              to={"/signup"}
            >
              Sign Up
            </Link>
          </Typography>
        </Box>
      </div>
    </div>
  );
};

export default ResetPassword;
