import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Modal,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { REQUIRED_RED } from "../../../App";
import { handlePreventKeyDownDecimal } from "../../../utility/helpers/commonFunction";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 3,
  px: 3,
  pb: 3,
};

const ProgramModal = ({
  open,
  onClose,
  onSave,
  updateIndex,
  setUpdateIndex,
  discountProgram,
  handleSubmitProgram,
}) => {
  const [nameError, setNameError] = useState(""); // State to handle program name error

  const {
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (updateIndex !== -1) {
      setValue("programName", discountProgram[updateIndex]?.programName);
      setValue("discount", discountProgram[updateIndex]?.discount);
    } else {
      reset();
    }
  }, [updateIndex, setValue, discountProgram]);

  const handleFormSubmit = (values) => {
    // Reset the name error state
    setNameError("");

    // Check if the program name already exists (excluding the current index if updating)
    const existingProgram = discountProgram.find(
      (program, index) =>
        program.programName.trim().toLowerCase() ===
          values.programName.trim().toLowerCase() && index !== updateIndex // ignore the program being updated
    );

    if (existingProgram) {
      // Set error if the name already exists
      setNameError(
        "Program name already exists. Please choose a different name."
      );
      return;
    }

    if (updateIndex !== -1) {
      // Edit mode: Update the selected index
      const updatedPrograms = [...discountProgram];
      updatedPrograms[updateIndex] = {
        ...updatedPrograms[updateIndex],
        programName: values.programName,
        discount: (+values.discount)?.toFixed(2),
      };
      onSave(updatedPrograms);
      handleSubmitProgram(undefined, updatedPrograms);
    } else {
      // Add mode: Append new program
      onSave([
        ...discountProgram,
        {
          programName: values.programName,
          discount: (+values.discount)?.toFixed(2),
        },
      ]);
      handleSubmitProgram(undefined, [
        ...discountProgram,
        {
          programName: values.programName,
          discount: (+values.discount)?.toFixed(2),
        },
      ]);
    }

    reset();
    onClose(false);
    setUpdateIndex(-1); // Reset the update index after save
  };
  const handleClose = () => {
    setUpdateIndex(-1);
    reset();
    onClose(false);
    setNameError("");
  };
  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: "720px", height: "fit-content" }}>
        <AppBar
          variant="elevation"
          color="secondary"
          sx={{
            height: "64px",
            paddingRight: "0px !important",
          }}
        >
          <Toolbar variant="regular">
            <Typography
              variant="h6"
              gutterBottom={false}
              width={"98%"}
              color={"white"}
              fontSize={"20px"}
              lineHeight={"32px"}
            >
              {updateIndex !== -1 ? "Edit Program" : "Add New Program"}
            </Typography>
            <Stack direction={"row"} spacing={0}>
              <div
                style={{ cursor: "pointer", height: "32px" }}
                onClick={() => handleClose()}
              >
                <CloseIcon style={{ color: "#0000008F", fontSize: 32 }} />
              </div>
            </Stack>
          </Toolbar>
        </AppBar>

        <Container
          gap={4}
          disableGutters
          style={{
            height: "fit-content",
            marginTop: "66px",
            gap: "24px",
          }}
        >
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Grid container spacing={3}>
              <Grid item lg={8} md={8} sm={8} xs={12}>
                <Typography variant="body1" className="label-500">
                  Program Name
                  <span style={{ color: REQUIRED_RED, marginLeft: "4px" }}>
                    *
                  </span>
                </Typography>
                <Controller
                  name="programName"
                  control={control}
                  rules={{
                    required: "Please enter program name",
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      style={{ marginTop: "3px" }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      fullWidth
                      error={!!errors.programName || !!nameError}
                      helperText={
                        errors.programName
                          ? errors.programName.message
                          : nameError // Show the custom error for duplicate name
                      }
                      InputLabelProps={{
                        shrink: field.programName?.length > 0,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Typography variant="body1" className="label-500">
                  Discount %
                  <span style={{ color: REQUIRED_RED, marginLeft: "4px" }}>
                    *
                  </span>
                </Typography>
                <Controller
                  name="discount"
                  control={control}
                  rules={{
                    required: "Please enter discount percentage",
                    min: {
                      value: 0,
                      message: "Discount cannot be less than 0%",
                    },
                    max: {
                      value: 100,
                      message: "Discount cannot be more than 100%",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      style={{ marginTop: "3px" }}
                      id="outlined-basic"
                      variant="outlined"
                      fullWidth
                      error={!!errors.discount}
                      helperText={
                        errors.discount ? errors.discount.message : ""
                      }
                      InputLabelProps={{ shrink: field.discount?.length > 0 }}
                      onKeyDown={handlePreventKeyDownDecimal}
                    />
                  )}
                />
              </Grid>

              <Grid
                container
                justifyContent={"end"}
                spacing={2}
                style={{
                  marginTop: "8px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Grid item spacing={2}>
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleClose()}
                    >
                      Cancel{" "}
                    </Button>
                    <Button variant="contained" color="primary" type="submit">
                      SAVE{" "}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Box>
    </Modal>
  );
};

export default ProgramModal;
