import { Add, Delete } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  debounce,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./DateTimePickerStyles.css";

import { useTheme } from "@emotion/react";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { REQUIRED_RED } from "../../../App";
import { FormatNumberForKm } from "../../../components/FormatNumber/FormatNumber";
import { SHOP_IMPORTED } from "../../../config/dataService/constants";
import {
  customerDetail,
  importedCustomerDetail,
} from "../../../redux/Customer/action";
import { serviceSubcategoryData } from "../../../redux/Estimate/action";
import { CustomersListBasedOnType } from "../../../redux/Invoice/action";
import { vehicleDetails } from "../../../redux/Vehicles/action";
import {
  createAppointment,
  fetchShopClosingTime,
  mechanicDetailsList,
} from "../../../redux/appointment/action";
import SuccessMessageModal from "../../Common/SuccessMessage";
import AddAppointmentServiceModal from "../../modal/AddAppointmentService";
import ConfirmCustomersInfoModel from "../../modal/ConfirmCustomersInfo";
import MissingVehicleInfoModal from "../../modal/MissingVehicleInfoModal";

import axios from "axios";
import advancedFormat from "dayjs/plugin/advancedFormat"; // Import advanced format plugin
import timezone from "dayjs/plugin/timezone"; // Import timezone plugin
import utc from "dayjs/plugin/utc"; // Import UTC plugin
import { clearAllPaths, getPreviousPath } from "../../../components/history";
import { APPOINTMENT_CONTACT_PREFRENCES } from "../../../utility/Constant";
import DropdownLoader from "../../Common/DropdownLoader";
// import { DateTime } from "luxon";
// Extend dayjs with plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
const localTimezone = dayjs.tz.guess();
console.log("Local Timezone:", localTimezone);
const styleChip = {
  color: "#002394",
  // marginLeft: "200px",
  width: "125px",
  height: "21px",
  paddingTop: "10px",
  paddingBottom: "10px",
  alignItems: "right",
  fontSize: "13px",
  fontStyle: "normal",
  fontWeight: "500",
};

const NewAppointment = () => {
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    reset,
    setError,
    setFocus,
    clearErrors,
  } = useForm();
  const { id, type } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [customerDetails, setCustomerDetails] = React.useState([]);
  const [mechanicDetail, setMechanicDetail] = React.useState("");
  const [customerData, setCustomerData] = React.useState("");
  const [customerTypeData, setCustomerTypeData] = React.useState([]);
  const [mechanicData, setMechanicData] = React.useState("");
  const [customerOnSite, setCustomerOnSite] = React.useState("");
  const [vehicleData, setVehicleData] = React.useState("");
  const [data, setData] = React.useState("");
  const [isFirstSelection, setIsFirstSelection] = useState(true);
  const currentTimeFormate = dayjs().format("YYYY-MM-DDTHH:mm").split("T")[1];
  const [assigneeLoader, setAssigneeLoader] = useState(false);

  const [dropOffDate, setDropOffDate] = React.useState(null);
  const [pickUpDate, setPickUpDate] = React.useState(null);

  const [serviceId, setServiceId] = React.useState([]);
  const [filterData, setFilterData] = React.useState([]);
  const [addCustomerInfoButton, setAddCustomerInfoButton] =
    React.useState(false);
  const [addVehicleInfoButton, setAddVehicleInfoButton] = React.useState(false);

  const [showLoader, setShowLoader] = React.useState(false);
  const [checkChangeLoader, setCheckChangeLoader] = useState(false);
  const [showIdLoader, setShowIdLoader] = React.useState(true);
  const [vehicleLoader, setVehicleLoader] = React.useState(false);
  const [customerListLoader, setCustomerListLoader] = React.useState(false);
  const [customerSearch, setCustomerSearch] = useState("");

  const [newVehicleCreatedId, setVehicleCreatedId] = useState("");

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  function handleClick(event) {
    event.preventDefault();
    // console.info("You clicked a breadcrumb.");
  }
  let limit = 5000;
  const [page, setPage] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [openCustomerInfo, setOpenCustomerInfo] = React.useState(false);
  const [customerCretedFrom, setCustomerCretedFrom] = React.useState("");
  const [vehicleSourceData, setVehicleSourceData] = React.useState("");
  const [servicePageData, setServicePageData] = useState({
    totalpages: "",
    currentPage: "",
  });
  const cancelTokenSourceRef = useRef(null);

  const [state, setState] = React.useState({
    shop_created: true,
    shop_imported: true,
    apex_auto_app: false,
  });

  const limits = 50;

  const handleOpen = () => setOpen(true);
  const handleOpenCustomerInfo = () => setOpenCustomerInfo(true);

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseCustomerInfo = () => {
    setOpenCustomerInfo(false);
  };
  const [vehicleInfoOpen, setVehicleInfoOpen] = useState(false);
  const handleVehicleInfoOpen = () => {
    setVehicleInfoOpen(true);
    handleClose();
  };
  const handleVehicleInfoClose = () => {
    setVehicleInfoOpen(false);
    handleClose();
    if (vehicleSourceData) {
      setVehicleSourceData("");
      setVehicleData("");
    }
  };

  useEffect(() => {
    reset({
      noteForCustomer: "",
    });
    setData([]);
    setVehicleData("");
    setDropOffDate(null);
    setPickUpDate(null);
    setCustomerOnSite(null);
    setMechanicData("");
    setServiceId([]);
  }, [window.location.pathname]);

  const customerDataDetail = async () => {
    setShowLoader(true);

    let resp;
    if (type != SHOP_IMPORTED) {
      resp = await dispatch(customerDetail(id));
    }
    if (type == SHOP_IMPORTED) {
      resp = await dispatch(importedCustomerDetail(id));
    }
    resp.fullName = resp?.firstName + " " + resp?.lastName;
    resp.refIdUsers = id;
    resp.refIdImport = id;

    setCustomerData(resp);
    setCustomerDetails([resp]);
    setValue("customer", resp);
    setShowLoader(false);
  };
  useEffect(() => {
    // setCustomerData(id);
    if (id && type) {
      setCustomerTypeData(["shop_created", "apex_auto_app", "shop_imported"]);
      setState({
        shop_created: true,
        apex_auto_app: true,
        shop_imported: true,
      });
      customerDataDetail();
    }
    if (!id) {
      setCustomerTypeData(["shop_created", "apex_auto_app", "shop_imported"]);
      setState({
        shop_created: true,
        apex_auto_app: true,
        shop_imported: true,
      });
    }
    servicesName();
    mechanicList();
    // if(customerTypeData.length == 0){
    //   setCustomerDetails([])
    // }
  }, [window.location.pathname]);

  useEffect(() => {
    if (customerTypeData) {
      if (!id && !type) {
        customerList(page, limits);
      }
    }
  }, [customerTypeData]);

  useEffect(() => {
    if (customerData && customerData?.createdFrom != "shop_imported") {
      vehicalDetail();
    }
    if (customerData?.createdFrom == "shop_imported") {
      setCustomerCretedFrom("shop_imported");
      setAddCustomerInfoButton(false);
      handleOpenCustomerInfo();
    }
  }, [customerData]);
  useEffect(() => {
    let hh = currentTimeFormate.split(":")[0];
    let mm = currentTimeFormate.split(":")[1];
    if (mm < 14) {
      mm = 15;
    } else if (mm < 29) {
      mm = 30;
    } else if (mm < 44) {
      mm = 45;
    } else {
      mm = "00";
      hh = +hh + 1;
    }
  }, []);
  const customerList = async (page, limits) => {
    // setCustomerDetails([]);
    try {
      if (showIdLoader && id) {
        setShowLoader(true);
        setShowIdLoader(false);
      }
      setCustomerListLoader(true);
      let data;

      if (customerTypeData.length > 0) {
        data = await dispatch(
          CustomersListBasedOnType(
            customerSearch,
            customerTypeData,
            page,
            limits
          )
          // CustomersListBasedOnType(customerSearch, customerTypeData, page)
        );
        if (data.data) {
          setCustomerDetails([...customerDetails, ...data.data]);
          setCustomerData(data.data.find((d) => d._id == id));
          setServicePageData({
            totalpages: data?.totalPages,
            currentPage: data?.currentPage,
          });
          setCustomerListLoader(false);
          setShowLoader(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
    setCheckChangeLoader(false);
  };
  const mechanicList = async () => {
    setAssigneeLoader(true);
    let data = await dispatch(mechanicDetailsList());
    setMechanicDetail(data);
    setAssigneeLoader(false);
    if (data?.length == 1) {
      setMechanicData(data[0]?._id);
    } else {
      setMechanicData(data?.find((d) => d.isDefault == true)?._id);
    }
  };

  const servicesName = async () => {
    try {
      let serviceSubCategoryData = await dispatch(
        serviceSubcategoryData(page, limit)
      );
      setFilterData(serviceSubCategoryData.data);
    } catch (error) {
      console.log(error);
    }
  };

  let filteredArray;
  filteredArray = filterData?.filter((item) =>
    serviceId.includes(item.serviceSubCategory._id)
  );
  const vehicalDetail = async (newCustomerId) => {
    // setShowLoader(true);
    setData([]);
    setVehicleLoader(true);
    let data = await dispatch(
      vehicleDetails(
        10,
        1,
        newCustomerId ? newCustomerId : customerData?.refIdUsers
      )
    );
    setVehicleLoader(false);
    if (data) {
      setData(data);
      if (data.length == 0) {
        handleVehicleInfoOpen();
      } else if (data.length == 1) {
        setVehicleData(data[0]?._id);
        setValue("vehicle", data[0]?._id);
        clearErrors("vehicle");
        if (data[0].source && data[0].source == "shop_imported") {
          setVehicleSourceData(data[0]);
          handleVehicleInfoOpen();
        }
      } else {
        handleCloseCustomerInfo();
      }
    }
    // setShowLoader(false);
  };

  useEffect(() => {
    if (Array.isArray(data) && data.length > 0 && newVehicleCreatedId) {
      setVehicleData(newVehicleCreatedId);
      setVehicleCreatedId("");
    }
  }, [data]);

  const getClosestAllowedMinute = (minute) => {
    let allowedMinutes = [0, 15, 30, 45];
    // Find the closest allowed minute greater than or equal to the current minute
    for (let i = 0; i < allowedMinutes.length; i++) {
      if (minute <= allowedMinutes[i]) return allowedMinutes[i];
    }
    // If no match, return the first allowed minute of the next hour
    return allowedMinutes[0];
  };

  const onInvalid = (errors) => {
    const firstErrorField = Object.keys(errors)[0];
    if (firstErrorField) {
      const errorElement = document.querySelector(`[name=${firstErrorField}]`);
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth" });
        setTimeout(() => {
          const offset = 100; // Adjust the offset value as needed
          const elementPosition = errorElement.getBoundingClientRect().top;
          const offsetPosition = elementPosition - offset;
          window.scrollBy({ top: offsetPosition, behavior: "smooth" });
          setFocus(firstErrorField);
        }, 0);
      }
    }
  };
  const onSubmit = async (value) => {
    const now = dayjs();
    let cloneData = dropOffDate;
    if (!dayjs(cloneData).isValid()) {
      console.log("invalide date error");
      setError("dropOffDate", {
        type: "custom",
        message: "Invalid date",
      });

      onInvalid(errors);
      return;
    }
    if (![0, 15, 30, 45].includes(dayjs(cloneData).minute())) {
      console.log("minuite error");
      setError("dropOffDate", {
        type: "custom",
        message: "Please select a time ending in 00, 15, 30, or 45 minutes.",
      });
      onInvalid(errors);
      return;
    }
    if (dayjs(cloneData).isBefore(now)) {
      setError("dropOffDate", {
        type: "custom",
        message: "Please choose a future drop off time.",
      });
      onInvalid(errors);
      return;
    }
    setShowLoader(true);
    let submitData = {
      customer: customerData?.refIdUsers,
      vehicle: vehicleData,
      services: serviceId,
      waitingOnSite: customerOnSite,
      assignee: [mechanicData],
    };

    if (dropOffDate) {
      submitData.dropOffDateTime = dropOffDate.toISOString();
    }
    if (pickUpDate) {
      submitData.pickupDateTime = pickUpDate.toISOString();
    }
    if (value?.contactPrefrence) {
      submitData.contactPreferences = value?.contactPrefrence;
    }
    if (value.noteForCustomer) {
      submitData.noteForCustomer = value.noteForCustomer.trim();
    }
    try {
      const data = await dispatch(createAppointment(submitData));

      if (data) {
        // If the data is truthy (meaning the appointment was created successfully)
        setShowLoader(false);
        setOpenSuccessModal(true);
        setSuccessMessage("Appointment created successfully.");

        setTimeout(() => {
          if (getPreviousPath()?.split("/")?.includes("calendar")) {
            clearAllPaths();
            navigate(-1);
          } else {
            navigate("/appointment/today");
          }
        }, 1000);
      } else {
        // If the data is falsy (handling the case where the appointment creation failed)
        setShowLoader(false);
        // Optionally, you may want to handle the error state or provide user feedback.
      }
    } catch (error) {
      // Handle any unexpected errors during the appointment creation
      console.error("Error creating appointment:", error);
      // Optionally, you may want to set an error state or provide user feedback.
    }
  };

  const handleRemoveService = (id) => {
    let newArray = serviceId.filter((itemId) => itemId !== id);
    setServiceId(newArray);
  };

  const disablePrevDates = (startDate) => {
    const startSeconds = Date.parse(startDate);
    return (date) => {
      return Date.parse(date) < startSeconds;
    };
  };
  async function handleInputChange(newInputValue) {
    setCustomerSearch(newInputValue);

    setCustomerDetails([]);
    try {
      setCustomerListLoader(true);
      let data;

      data = await dispatch(
        CustomersListBasedOnType(newInputValue, customerTypeData, 1, limits)
      );
      if (data) {
        setCustomerDetails(data.data);
        setCustomerListLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const debouncedHandleInputChange = debounce(handleInputChange, 300);
  const handleInputDebounced = (event, newInputValue) => {
    debouncedHandleInputChange(newInputValue);
  };

  const handleCheckChange = (event) => {
    setCheckChangeLoader(true);
    // setShowLoader(true)
    setCustomerDetails([]);
    setCustomerSearch("");
    setPage(1);
    if (event.target.name && event.target.checked == true) {
      setCustomerTypeData((prevArray) => [...prevArray, event.target.name]);
    } else {
      const filterData = customerTypeData.filter(
        (value) => value !== event.target.name
      );
      setCustomerTypeData(filterData);
    }
    setState({ ...state, [event.target.name]: event.target.checked });
    // setShowLoader(false)
  };
  const handleBlur = () => {
    if (customerSearch != "") {
      setCustomerSearch("");

      handleInputChange(""); // Clear the search text when the Autocomplete loses focus
    }
  };

  const handleScroll = (e) => {
    const listboxNode = e.currentTarget;
    if (
      listboxNode.scrollTop + listboxNode.clientHeight ===
        listboxNode.scrollHeight &&
      servicePageData.totalpages > page
    ) {
      setPage(page + 1);
      customerList(page + 1, limits);
    }
  };

  useEffect(() => {
    getShopClosingTime(dayjs().format("YYYY-MM-DDTHH:mm"));
  }, []);

  const getShopClosingTime = async (time) => {
    // Cancel API call on recall
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokenSourceRef.current = axios.CancelToken.source();

    // console.log("🚀 ~ getShopClosingTime ~utc time:", dayjs().toISOString());
    let resp = await dispatch(
      fetchShopClosingTime({
        dropOffDateTime: dayjs(time).utc(),
        cancelToken: cancelTokenSourceRef.current.token,
      })
    );

    const localTime = dayjs(resp.shopClosingTime)
      .tz(localTimezone)
      .format("YYYY-MM-DDTHH:mm");
    setPickUpDate(dayjs(localTime));
    setValue("pickUpDate", dayjs(localTime));
  };

  return (
    <>
      <Box padding={"24px"}>
        {" "}
        <Grid container sx={{ padding: "16px 0px" }} gap={"8px"}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div role="presentation" onClick={handleClick}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  to="/appointment/today"
                  style={{
                    color: "black",
                    opacity: "0.6",
                    textDecoration: "none",
                  }}
                >
                  Appointments
                </Link>
                <Link
                  to="/appointment/new-appointment"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  New Appointment
                </Link>
              </Breadcrumbs>
            </div>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h4" fontWeight={500}>
              New Appointment
            </Typography>
          </Grid>
        </Grid>
        <form
          onSubmit={handleSubmit(onSubmit, onInvalid)}
          style={{ width: "100%" }}
        >
          <Grid
            container
            padding={"24px"}
            margin={"32px 0"}
            boxShadow={2}
            borderRadius={"8px"}
            bgcolor={"white"}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth required error={!!errors.customer}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "5.5px",
                    }}
                  >
                    <Typography variant="body1" className="label-500">
                      Customer <span style={{ color: "red" }}>*</span>
                    </Typography>
                    {!addCustomerInfoButton &&
                    customerCretedFrom == "shop_imported" ? (
                      <Grid
                      // item
                      // xl={2.7}
                      // lg={2.5}
                      // md={2.7}
                      // sm={2.7}
                      // xs={2.7}
                      // paddingTop={"0px"}
                      // marginTop={"0px"}
                      // className="width-100-per-drop-off"
                      >
                        <Button
                          // style={{ marginTop: "35px" }}
                          onClick={() => {
                            handleOpenCustomerInfo();
                          }}
                        >
                          {" "}
                          + Add Customer Info
                        </Button>
                      </Grid>
                    ) : (
                      <></>
                      // New customer button
                      // <Typography
                      //   color={"primary"}
                      //   fontWeight={500}
                      //   fontSize={"15px"}
                      //   sx={{ cursor: "pointer" }}
                      // >
                      //   + NEW CUSTOMER
                      // </Typography>
                    )}
                    {/* Additional content like the "Add Customer Info" button */}
                  </div>

                  <Controller
                    name="customer" // Form field name
                    control={control}
                    rules={{ required: "Customer is required" }} // Validation rule
                    render={({ field: { onChange, value, name, ref } }) => (
                      <Autocomplete
                        name={name}
                        ref={ref}
                        disablePortal
                        id="combo-box-demo"
                        options={customerDetails ? customerDetails : []}
                        getOptionLabel={(option) => option.fullName}
                        loading={customerListLoader}
                        disabled={id || customerTypeData.length == 0}
                        value={customerData ? customerData : null}
                        onChange={(event, newValue) => {
                          onChange(newValue);
                          setCustomerData(newValue);
                          setVehicleData(null);
                          setValue("vehicle", null);
                          setCustomerCretedFrom(newValue?.createdFrom);
                          if (customerData != null) {
                            setData("");
                          }
                        }}
                        loadingText={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {" "}
                            <CircularProgress />
                          </div>
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label=""
                            variant="outlined"
                            error={!!errors.customer} // Error state for validation
                            helperText={
                              errors.customer ? errors.customer.message : ""
                            }
                            onChange={(e) =>
                              handleInputDebounced(e, e.target.value)
                            }
                            onBlur={handleBlur}
                          />
                        )}
                        ListboxProps={{
                          style: { maxHeight: "300px" },
                          onScroll: (event) => {
                            handleScroll(event);
                          },
                        }}
                        renderOption={(props, option) => (
                          <>
                            <Box component="li" {...props}>
                              <li
                                {...props}
                                style={{
                                  width: "100%",
                                  justifyContent: "space-between",
                                }}
                                key={option?._id}
                                onClick={() => {
                                  if (option.createdFrom == "shop_imported") {
                                    handleOpenCustomerInfo();
                                  }
                                }}
                              >
                                <div>{option.fullName}</div>
                                <div>
                                  <Chip
                                    style={{
                                      ...styleChip,
                                      display: "flex",
                                      background:
                                        option.createdFrom == "shop_created"
                                          ? "#75DBFF80"
                                          : option.createdFrom ==
                                            "shop_imported"
                                          ? "#E1D0FD"
                                          : "#EFEFEF",
                                      color:
                                        option.createdFrom == "shop_created"
                                          ? "#000000"
                                          : option.createdFrom ==
                                            "shop_imported"
                                          ? "#3E1C76"
                                          : "#002394",
                                    }}
                                    className="chip-text-style"
                                    label={
                                      option.createdFrom == "shop_created"
                                        ? "Shop Created"
                                        : option.createdFrom == "shop_imported"
                                        ? "Shop Imported"
                                        : "Apex Auto"
                                    }
                                  />
                                </div>
                              </li>
                            </Box>

                            <Box component="li" {...props}>
                              {props["data-option-index"] + 1 ==
                                customerDetails?.length &&
                                customerListLoader && (
                                  <li
                                    style={{
                                      width: "100%",
                                      justifyContent: "center",

                                      display: "flex",
                                    }}
                                  >
                                    <CircularProgress />
                                  </li>
                                )}
                            </Box>
                          </>
                        )}
                      />
                    )}
                  />
                  {/* {errors.customer && (
                    <FormHelperText error>
                      {errors.customer.message}
                    </FormHelperText>
                  )} */}
                </FormControl>
                <Grid item container columnGap={2} rowGap={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.shop_created}
                        onChange={handleCheckChange}
                        name="shop_created"
                        disabled={id}
                      />
                    }
                    labelPlacement="end"
                    label="Shop Created"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.shop_imported}
                        onChange={handleCheckChange}
                        name="shop_imported"
                        disabled={id}
                      />
                    }
                    labelPlacement="end"
                    label="Shop Imported"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.apex_auto_app}
                        onChange={handleCheckChange}
                        name="apex_auto_app"
                        disabled={id}
                      />
                    }
                    labelPlacement="end"
                    label="Apex Auto"
                  />
                </Grid>
                {/* </Stack> */}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={!!errors.vehicle} // Show error if validation fails
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "5.5px",
                    }}
                  >
                    <Typography variant="body1" className="label-500">
                      Vehicle <span style={{ color: REQUIRED_RED }}>*</span>
                    </Typography>
                    {customerData &&
                      customerData?.createdFrom != "shop_imported" && (
                        <Grid
                          item
                          // xl={2.7}
                          // lg={2.5}
                          // md={2.7}
                          // sm={2.7}
                          // xs={2.7}
                          // paddingTop={"0px"}
                          // marginTop={"0px"}
                          // className="width-100-per-drop-off"
                        >
                          <Typography
                            // style={{ marginTop: "35px" }}
                            color={"primary"}
                            fontWeight={500}
                            fontSize={"15px"}
                            onClick={() => {
                              handleVehicleInfoOpen();
                              setVehicleSourceData("");
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {" "}
                            + ADD VEHICLE
                          </Typography>
                        </Grid>
                      )}
                  </div>
                  <Controller
                    name="vehicle" // Form field name
                    control={control}
                    rules={{ required: "Vehicle is required" }} // Validation rule
                    render={({ field: { onChange, value, name, ref } }) => (
                      <Select
                        name={name}
                        ref={ref}
                        value={
                          Array.isArray(data) && customerData !== null
                            ? vehicleData
                            : ""
                        }
                        onChange={(event) => {
                          onChange(event.target.value);
                          setVehicleData(event.target.value);
                        }}
                        displayEmpty
                        error={!!errors.vehicle} // Error prop for validation state
                      >
                        {data &&
                          data?.map((item) => {
                            if (item)
                              return (
                                <MenuItem
                                  value={item._id}
                                  onClick={() => {
                                    if (
                                      item.source &&
                                      item.source == "shop_imported"
                                    ) {
                                      setVehicleSourceData(item);
                                      handleVehicleInfoOpen();
                                    }
                                  }}
                                >
                                  {item?.vehicleYear ? item?.vehicleYear : " "}
                                  {item?.year && item?.year}{" "}
                                  {item?.vehicleMake?.name &&
                                    item?.vehicleMake?.name}{" "}
                                  {item?.vehicleModel?.name &&
                                    item?.vehicleModel?.name}{" "}
                                  {item?.vehicleModelTrim?.name &&
                                    item?.vehicleModelTrim?.name}
                                </MenuItem>
                              );
                          })}
                        {vehicleLoader && <DropdownLoader />}
                      </Select>
                    )}
                  />
                  {errors.vehicle && (
                    <FormHelperText error>
                      {errors.vehicle.message}
                    </FormHelperText>
                  )}
                </FormControl>

                {/* </Stack> */}
              </Grid>
              <Grid
                item
                xl={4}
                md={4}
                lg={4}
                sm={6}
                xs={12}
                paddingTop={"0px"}
                marginTop={"0px"}
                className="width-100-per-drop-off dateTimePicker"
              >
                <FormControl fullWidth required error={!!errors?.dropOffDate}>
                  <Typography variant="body1" className="label-500">
                    Drop Off
                    <span style={{ color: REQUIRED_RED }}> *</span>
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      name="dropOffDate" // Field name
                      control={control}
                      rules={{
                        required: "Drop-off date and time are required.",
                      }} // Validation rule
                      render={({
                        field: { onChange, value, name, ref },
                        fieldState: { error },
                      }) => (
                        <DemoContainer components={["DateTimePicker"]}>
                          <DateTimePicker
                            name={name}
                            ref={ref}
                            format="DD/MM/YYYY - hh:mm A"
                            value={dropOffDate}
                            onChange={(data) => {
                              let cloneData = data;
                              if (!dayjs(cloneData).isValid()) {
                                console.log("invalide date error");
                                setDropOffDate(data);
                                setError("dropOffDate", {
                                  type: "custom",
                                  message: "Invalid date",
                                });
                                return;
                              }
                              if (
                                ![0, 15, 30, 45].includes(
                                  dayjs(cloneData).minute()
                                )
                              ) {
                                console.log("minuite error");
                                setDropOffDate(data);
                                setError("dropOffDate", {
                                  type: "custom",
                                  message:
                                    "Please select a time ending in 00, 15, 30, or 45 minutes.",
                                });
                                return;
                              }
                              const now = dayjs();
                              if (isFirstSelection) {
                                // Get current hour and closest allowed minute
                                const currentHour = dayjs().hour();
                                const currentMinute = dayjs().minute();
                                const closestMinute =
                                  getClosestAllowedMinute(currentMinute);

                                // Set the selected date's time to either current time or closest allowed minute
                                const updatedDate = data
                                  .set(
                                    "hour",
                                    closestMinute === 0 && currentMinute > 45
                                      ? currentHour + 1
                                      : currentHour
                                  )
                                  .set("minute", closestMinute);

                                // Validate against current time
                                if (dayjs(updatedDate).isBefore(now)) {
                                  console.log(
                                    "Validate against current time ifff"
                                  );
                                  setDropOffDate(updatedDate);
                                  setError("dropOffDate", {
                                    type: "custom",
                                    message:
                                      "Please choose a future drop off time.",
                                  });
                                  return;
                                } else {
                                  clearErrors("dropOffDate");
                                  setDropOffDate(updatedDate);
                                  setValue("pickUpDate", updatedDate);
                                  getShopClosingTime(updatedDate);
                                  setIsFirstSelection(false); // Mark as not the first selection
                                }
                                onChange(updatedDate);
                              } else {
                                // Validate only, without resetting time
                                if (dayjs(data).isBefore(now)) {
                                  console.log(
                                    "Validate against current time ifff"
                                  );
                                  setDropOffDate(data);
                                  setError("dropOffDate", {
                                    type: "custom",
                                    message:
                                      "Please choose a future drop off time.",
                                  });
                                  return;
                                } else {
                                  clearErrors("dropOffDate");
                                  setDropOffDate(data);
                                  setValue("pickUpDate", data);
                                  getShopClosingTime(data);
                                }
                                onChange(data);
                              }

                              clearErrors("pickUpDate"); // Clear pickUpDate error if any
                            }}
                            style={{ width: "100%" }}
                            minutesStep={15}
                            minDate={dayjs().startOf("day")}
                            slotProps={{
                              textField: {
                                error: !!error, // Set error state for TextField
                                helperText: error ? error.message : undefined, // Set helper text
                              },
                            }}
                          />
                        </DemoContainer>
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid
                item
                xl={4}
                md={4}
                lg={4}
                sm={6}
                xs={12}
                paddingTop={"0px"}
                marginTop={"0px"}
                className="width-100-per-drop-off"
              >
                <FormControl fullWidth required error={!!errors?.pickUpDate}>
                  <Typography variant="body1" className="label-500">
                    Pick Up Time
                    <span style={{ color: REQUIRED_RED }}> *</span>
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      name="pickUpDate" // Field name
                      control={control}
                      rules={{ required: "Pickup date and time are required." }} // Validation rule
                      render={({
                        field: { onChange, value, name, ref },
                        fieldState: { error },
                      }) => (
                        <DemoContainer components={["DateTimePicker"]}>
                          <DateTimePicker
                            name={name}
                            ref={ref}
                            format="DD/MM/YYYY - hh:mm A"
                            value={pickUpDate}
                            onChange={(data) => {
                              onChange(data);
                              setPickUpDate(data);
                              getShopClosingTime(dayjs(data));
                            }}
                            style={{ width: "100%" }}
                            minDate={dayjs().startOf("day")}
                            slotProps={{
                              textField: {
                                error: !!error, // Set error state for TextField
                                helperText: error ? error.message : undefined, // Set helper text
                              },
                            }}
                            shouldDisableDate={disablePrevDates(dropOffDate)}
                          />
                        </DemoContainer>
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xl={4} md={4} lg={4} sm={6} xs={12}>
                <Stack spacing={1}>
                  <Typography variant="body1" className="label-500">
                    Assign To (Calendar){" "}
                    <span style={{ color: REQUIRED_RED }}>*</span>
                  </Typography>
                  <Select
                    value={mechanicData}
                    onChange={(event) => {
                      setMechanicData(event.target.value);
                    }}
                    displayEmpty
                    required
                  >
                    {mechanicDetail &&
                      mechanicDetail?.map((item) => {
                        return (
                          <MenuItem value={item?._id}>{item.name}</MenuItem>
                        );
                      })}
                    {assigneeLoader && (
                      <MenuItem
                        value={null}
                        disabled
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress />
                      </MenuItem>
                    )}
                    {!assigneeLoader && mechanicDetail.length == 0 && (
                      <MenuItem value={null} disabled>
                        No assignee found
                      </MenuItem>
                    )}
                  </Select>
                </Stack>
              </Grid>
              <Grid item xl={6} md={6} lg={6} sm={12} xs={12}>
                <FormControl fullWidth required error={!!errors?.isWaitonSite}>
                  <Typography variant="body1" className="label-500">
                    Is the Customer Waiting on Site?{" "}
                    <span style={{ color: REQUIRED_RED }}>*</span>
                  </Typography>
                  <Controller
                    name="isWaitonSite" // Field name
                    control={control}
                    rules={{ required: "Please choose one option" }} // Validation rule
                    render={({ field: { onChange, value } }) => (
                      <RadioGroup
                        row
                        aria-labelledby="customer-waiting-on-site"
                        name="row-radio-buttons-group"
                        value={value}
                        onChange={(event) => {
                          onChange(event.target.value);
                          setCustomerOnSite(event.target.value);
                        }}
                      >
                        <FormControlLabel
                          value="wait_on_site"
                          control={<Radio />}
                          label="Wait on Site"
                        />
                        <FormControlLabel
                          value="dropping_off_car"
                          control={<Radio />}
                          label="Dropping off Car"
                        />
                      </RadioGroup>
                    )}
                  />
                  {errors.isWaitonSite && (
                    <FormHelperText>
                      {errors?.isWaitingOnSite?.message ||
                        "This field is required."}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xl={6} md={6} lg={6} sm={12} xs={12}>
                <FormControl fullWidth error={!!errors.contactPrefrence}>
                  <Stack spacing={1}>
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "0.15px",
                      }}
                    >
                      Contact Preference
                    </Typography>
                    <Controller
                      name="contactPrefrence"
                      control={control}
                      // rules={{
                      //   required: "Phone Number Type is required",
                      // }}
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        >
                          {APPOINTMENT_CONTACT_PREFRENCES?.map((contact) => (
                            <FormControlLabel
                              value={contact.key}
                              control={<Radio />}
                              label={contact?.value}
                            />
                          ))}
                        </RadioGroup>
                      )}
                    />
                  </Stack>
                </FormControl>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Stack spacing={1}>
                  <Typography variant="body1" className="label-500">
                    Add Note
                  </Typography>
                  <Controller
                    name="noteForCustomer"
                    control={control}
                    render={({ field }) => (
                      <>
                        <TextField
                          fullWidth
                          variant="outlined"
                          error={!!errors.noteForCustomer}
                          helperText={
                            errors.noteForCustomer
                              ? errors.noteForCustomer.message
                              : ""
                          }
                          {...field}
                        />
                      </>
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>

            {/* <Grid container item justifyItems={"space-between"}> */}

            {/* <Divider
              style={{
                width: "100%",
                borderBottomStyle: "solid",
                borderBottomWidth: "2px",
              }}
            /> */}
            {/* </Grid> */}
            <Grid
              item
              container
              justifyContent={"end"}
              padding={"20px 0px 10px 0px"}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<Add />}
                  size="large"
                  onClick={handleOpen}
                >
                  ADD SERVICE
                </Button>
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xl={12} md={12} sm={12} xs={12} boxShadow={0}>
                {Array.isArray(filteredArray) &&
                  filteredArray.map((item, index) => {
                    return (
                      <>
                        <Grid
                          container
                          item
                          style={{
                            backgroundColor: "#c5d0da",
                            color: "black",
                            marginTop: "20px",
                          }}
                        >
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography
                              style={{
                                padding: "16px",
                                backgroundColor: "#002394",
                                color: "white",
                                borderTopLeftRadius: "4px",
                                borderTopRightRadius: "4px",
                              }}
                              variant="h6"
                              fontWeight={"500"}
                              padding={"16px"}
                              fontSize={"20px"}
                              letterSpacing={"0.15px"}
                              lineHeight={"32px"}
                            >
                              {item?.serviceSubCategory?.name}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Table
                          aria-label="caption table"
                          padding="16px"
                          style={{ boxShadow: 0 }}
                        >
                          <TableHead
                            style={{
                              backgroundColor: "#f4f6f8",
                              fontWeight: 500,
                            }}
                          >
                            <TableRow>
                              <TableCell>No.</TableCell>
                              <TableCell width={"600px"}>
                                {" "}
                                Service Name
                              </TableCell>
                              <TableCell width={"600px"}> Cost</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell
                                component="th"
                                scope="row"
                                width={"200px"}
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell align="left">
                                {item?.serviceSubCategory?.name}
                              </TableCell>
                              <TableCell align="left">
                                {item?.priceType == "no_price"
                                  ? "-"
                                  : item?.priceType == "fixed"
                                  ? "$" + FormatNumberForKm(item?.fixedPrice)
                                  : item?.priceType == "range"
                                  ? "$" +
                                    FormatNumberForKm(item?.priceMin) +
                                    " - " +
                                    "$" +
                                    FormatNumberForKm(item?.priceMax)
                                  : "-"}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                        <Grid
                          container
                          item
                          justifyContent={"end"}
                          padding={"24px"}
                        >
                          <Grid item>
                            <Stack direction="row" spacing={2}>
                              <Button
                                variant="contained"
                                color="error"
                                startIcon={<Delete />}
                                size="large"
                                onClick={() => {
                                  handleRemoveService(
                                    item?.serviceSubCategory?._id
                                  );
                                }}
                              >
                                REMOVE SERVICE
                              </Button>
                            </Stack>
                          </Grid>{" "}
                        </Grid>
                      </>
                    );
                  })}
              </Grid>
            </Grid>
            <Divider
              style={{
                width: "100%",
                borderBottomStyle: "solid",
                borderBottomWidth: "2px",
              }}
            />
            <Grid container justifyContent={"end"} padding={"24px 0 0 0"}>
              {/* <Grid item> */}
              {/* <Stack spacing={2} direction={"row"}> */}
              <Button
                variant="contained"
                color="secondary"
                size="large"
                type="submit"
              >
                DONE
              </Button>
              {/* </Stack> */}
              {/* </Grid> */}
            </Grid>
          </Grid>
        </form>
        <AddAppointmentServiceModal
          open={open}
          handleClose={handleClose}
          filteredArray={filteredArray}
          appointentServiceModalDataProps={(dataId, page) => {
            let serviceIdArray = serviceId;
            serviceIdArray.push(dataId);
            setServiceId(serviceIdArray);
            setPage(page);
          }}
          servicesNameAPI={servicesName}
        />
        <ConfirmCustomersInfoModel
          open={openCustomerInfo}
          handleClose={handleCloseCustomerInfo}
          customerData={customerData}
          id={customerData?.refIdImport}
          vehicalDetailFunction={vehicalDetail}
          setAddCustomerInfoButton={setAddCustomerInfoButton}
          setCustomerData={setCustomerData}
          setCustomerTypeData={setCustomerTypeData}
          state={state}
          setState={setState}
          setAddVehicleInfoButton={setAddVehicleInfoButton}
          madeFromCustomerProfile={false}
        />
        <MissingVehicleInfoModal
          open={vehicleInfoOpen}
          handleClose={handleVehicleInfoClose}
          id={customerData?.refIdUsers}
          vehicalDetailFunction={vehicalDetail}
          setAddVehicleInfoButton={setAddVehicleInfoButton}
          setVehicleCreatedId={setVehicleCreatedId}
          vehicleSource={vehicleSourceData}
          setVehicleSourceData={setVehicleSourceData}
        />
        <SuccessMessageModal
          message={successMessage}
          open={openSuccessModal}
          setOpenSuccessModal={setOpenSuccessModal}
        />
      </Box>
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <Dialog fullScreen={fullScreen} open={checkChangeLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NewAppointment;
