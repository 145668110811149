import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Modal,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  updateCard,
  updateManualPlan,
  updatePlan,
} from "../../../redux/Account/Billing/action";
import SuccessMessageModal from "../../Common/SuccessMessage";
import { getItem, setItem } from "../../../utility/localStorage";
import { FormatNumberForKmFloat } from "../../../components/FormatNumber/FormatNumber";
import PaypalImage from "../../../assets/CardImages/paypal.png";
import VisaImage from "../../../assets/CardImages/visa.png";
import AmexImage from "../../../assets/CardImages/amex.png";
import MasterCardImage from "../../../assets/CardImages/mastercard.png";
import DiscoverImage from "../../../assets/CardImages/discover.png";
import DinerImage from "../../../assets/CardImages/diners.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  pl: 3,
  pr: 3,
  pb: 3,
};

function CurrentCardImageByName(type) {
  if (type == "visa") {
    return <img src={VisaImage} width={"47"} />;
  }
  if (type == "mastercard") {
    return <img src={MasterCardImage} width={"47"} />;
  }
  if (type == "amex") {
    return <img src={AmexImage} width={"47"} />;
  }
  if (type == "paypal") {
    return <img src={PaypalImage} width={"47"} />;
  }
  if (type == "discover") {
    return <img src={DiscoverImage} width={"47"} />;
  }
  if (type == "diners") {
    return <img src={DinerImage} width={"48"} />;
  }
}

const ChangeSubscriptionModal = ({
  openSubscriptionModal,
  setOpenSubscriptionModal,
  currentPlan,
  newPlan,
  fetchCurrentPlan = () => {},
  setAddCardModal = () => {},
  selectedCard = null,
}) => {
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    reset,
  } = useForm();
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [showLoader, setShowLoader] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const handleCloseSubscriptionModal = () => {
    setOpenSubscriptionModal(false);
  };

  console.log("selectedCard", selectedCard);
  const localStorageData = getItem("apex-saas_details");
  const onSubmitPlan = async () => {
    setShowLoader(true);
    let resp = "";
    if (currentPlan?.isManualSubscription) {
      let payload = {
        paymentMethodId: selectedCard?._id,
        priceId: newPlan?._id,
      };
      resp = await dispatch(updateManualPlan(payload));
    } else {
      if (Object.keys(currentPlan?.currentCard ?? {})?.length == 0) {
        let cardPayload = { paymentMethodId: selectedCard?._id };
        resp = await dispatch(updateCard(cardPayload));
      }

      resp = await dispatch(
        updatePlan({ oldId: currentPlan?._id, newId: newPlan?._id })
      );
    }
    if (resp) {
      let allLocalData = localStorageData;
      let data = [];
      if (newPlan?.nameOfProduct == "Mobile") data.push("mobile");
      if (newPlan?.nameOfProduct == "Web") data.push("web");
      if (newPlan?.nameOfProduct == "Combined") data = ["web", "mobile"];
      allLocalData.allowedPlatforms = data;

      setItem("apex-saas_details", allLocalData);
      window.dispatchEvent(new Event("localStorageChangePlan"));
    }
    setShowLoader(false);
    handleCloseSubscriptionModal();
    fetchCurrentPlan();
    setOpenSuccessModal(true);
    setSuccessMessage("Subscription updated");
    navigate("/account-settings/billing");
  };

  return (
    <Box>
      <Modal
        open={openSubscriptionModal}
        onClose={handleCloseSubscriptionModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <form onSubmit={handleSubmit(onSubmitPlan)}>
          <Box
            sx={{
              ...style,
              width: "70%",
              maxWidth: "1000px",
              border: "none",
              borderRadius: "8px 8px 0px 0px",
              outline: "none",
            }}
          >
            <AppBar
              color="secondary"
              variant="elevation"
              sx={{
                height: "64px",
                paddingRight: "0px !important",
                borderRadius: "8px 8px 0px 0px",
                padding: 0,
                margin: 0,
                paddingLeft: "-16px !important",
              }}
            >
              <Toolbar variant="regular">
                <Typography variant="h6" width={"98%"} color={"white"}>
                  Change Subscription Plan
                </Typography>
                <Stack direction={"row"} spacing={0}>
                  <div
                    onClick={() => {
                      handleCloseSubscriptionModal();
                    }}
                    style={{ cursor: "pointer", height: "32px" }}
                  >
                    <CloseIcon sx={{ fill: "white", fontSize: 32 }} />
                  </div>
                </Stack>
              </Toolbar>
            </AppBar>
            <Container
              gap={2}
              disableGutters
              style={{
                scrollbarColor: "blue",
                scrollbarWidth: "2px",

                marginTop: "72px",
              }}
            >
              <Grid
                container
                spacing={2}
                maxHeight={"85vh"}
                style={{ scrollbarColor: "blue", scrollbarWidth: "thin" }}
              >
                <Grid item container xs={12} lg={6} spacing={3}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    lg={12}
                    display={"grid"}
                    gap={"8px"}
                  >
                    <Typography
                      // variant="subtitle1"
                      // fontWeight={500}
                      fontSize={24}
                    >
                      Current Plan
                    </Typography>
                    {currentPlan?.isManualSubscription ? (
                      <Typography>
                        {currentPlan.allowedPlatforms.length == 2
                          ? "Premium"
                          : currentPlan?.allowedPlatforms?.includes("web")
                          ? "Web"
                          : currentPlan?.allowedPlatforms?.includes("mobile")
                          ? "Mobile"
                          : ""}
                      </Typography>
                    ) : (
                      <Typography>
                        {currentPlan?.subscriptionPrice?.nameOfProduct ==
                          "web" && "Web Access"}{" "}
                        {currentPlan?.subscriptionPrice?.nameOfProduct ==
                          "mobile" && "Mobile Essentials"}{" "}
                        {currentPlan?.subscriptionPrice?.nameOfProduct ==
                          "premium" && "Premium"}
                      </Typography>
                    )}
                    {currentPlan?.isManualSubscription ? (
                      <Typography>
                        {`$${FormatNumberForKmFloat(
                          currentPlan?.manualSubscriptionAmount + ""
                        )}`}
                      </Typography>
                    ) : (
                      <Typography>
                        $
                        {FormatNumberForKmFloat(
                          currentPlan?.subscriptionPrice?.unitAmountInDollars?.toFixed(
                            2
                          ) + ""
                        )}
                        {currentPlan?.subscriptionPrice?.interval
                          ? "/" + currentPlan?.subscriptionPrice?.interval
                          : ""}
                      </Typography>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    lg={12}
                    display={"grid"}
                    gap={"8px"}
                    // gap={0}
                  >
                    <Typography
                      // variant="subtitle1"
                      // fontWeight={500}
                      fontSize={24}
                    >
                      New Plan
                    </Typography>
                    <Typography>
                      {newPlan?.nameOfProduct == "mobile"
                        ? "Mobile Essentials Apex Mechanic Subscription"
                        : newPlan?.nameOfProduct == "web"
                        ? "Web Access Apex Mechanic Subscription"
                        : "Premium Apex Mechanic Subscription"}
                    </Typography>
                    <Typography>
                      ${newPlan?.unitAmountInDollars?.toLocaleString()}
                      {"/"}
                      {newPlan?.interval}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Typography
                    // variant="subtitle1"
                    // fontWeight={500}
                    fontSize={24}
                    pb={1}
                    // height={"fit-content"}
                  >
                    Payment Summary
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      height: "fit-content",
                      paddingBottom: "8px",
                    }}
                  >
                    <Typography>Total Amount</Typography>
                    <Typography fontSize={"16px"} fontWeight={600}>
                      ${newPlan?.changeAmount}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      height: "fit-content",
                      paddingBottom: "8px",
                    }}
                  >
                    <Typography> Sales Tax (13%)</Typography>
                    <Typography fontSize={"16px"} fontWeight={600}>
                      ${newPlan?.changeAmountTax}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      height: "fit-content",
                      paddingBottom: "8px",
                    }}
                  >
                    <Typography>
                      Amount Charged Today
                      {/* {currentPlan?.ifFreeTrial
                        ? dayjs(currentPlan?.endDate).format("MMMM D, YYYY") ==
                          dayjs().add(1, "day").format("MMMM D, YYYY")
                          ? "tomorrow"
                          : "on " +
                            dayjs(currentPlan?.endDate).format("MMMM D, YYYY")
                        : "today"} */}
                    </Typography>
                    <Typography fontSize={"16px"} fontWeight={600}>
                      ${newPlan?.changeAmountTotal}
                    </Typography>
                  </div>

                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    gap={"13px"}
                    height={"fit-content"}
                  >
                    {CurrentCardImageByName(
                      currentPlan?.currentCard?.brand?.toLowerCase() ??
                        selectedCard?.cardBrand?.toLowerCase()
                    )}
                    **** **** ****{" "}
                    {currentPlan?.currentCard?.last4 ??
                      selectedCard?.lastFourDigit}{" "}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    bgcolor={"rgba(241, 241, 241, 1)"}
                    paddingInline={1.6}
                    pt={1}
                    pb={1}
                    borderRadius={2}
                  >
                    New plan starts{" "}
                    <span style={{ fontWeight: 500 }}>
                      {currentPlan?.ifFreeTrial
                        ? dayjs(currentPlan?.endDate).format("MMMM D, YYYY") ==
                          dayjs().add(1, "day").format("MMMM D, YYYY")
                          ? "tomorrow"
                          : "on " +
                            dayjs(currentPlan?.endDate).format("MMMM D, YYYY")
                        : "today"}
                    </span>
                    .
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  display={"flex"}
                  justifyContent={"end"}
                  mt={"8px"}

                  // style={{ marginTop: "20px" }}
                >
                  {/* <Grid item></Grid> */}

                  <Grid item>
                    <Stack spacing={2} direction="row">
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          handleCloseSubscriptionModal();
                        }}
                      >
                        CANCEL
                      </Button>
                      <Button type="submit" variant="contained" color="primary">
                        CONFIRM CHANGES
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </form>
      </Modal>
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
    </Box>
  );
};

export default ChangeSubscriptionModal;
