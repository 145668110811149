import {
  CircularProgress,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { handleForgot } from "../../redux/Auth/action";
import "./ForgotPassword.css";
import logo from "../../assets/Logo.svg";

const ForgotPassword = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [id, setId] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const onSubmit = async (values) => {
    setDisableButton(true);
    const payload = {
      emailOrPhoneNumber: values.emailORPhoneNumber,
      countryCode: "+1",
      role: "shop_owner",
    };
    setShowLoader(true);
    let result = await dispatch(handleForgot(payload));

    let userid = result?._id;
    if (result?._id) {
      localStorage.setItem("id", userid);
      navigate("/recoveryCode");
    }
    setShowLoader(false);
    setDisableButton(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  return (
    <div id="auth_container">
      <div className="auth_logo_container">
        <img src={logo} alt="logo" />
      </div>

      <div className="auth_component_container">
        <div className="formTitle">Password Reset</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Typography
              fontSize={"14px"}
              fontWeight={500}
              lineHeight={"150%"}
              fontStyle={"normal"}
              color={"#000"}
              sx={{ marginBottom: "8px" }}
            >
              Email <span style={{ color: "#D32F2F" }}>*</span>
              {/* Email or Phone Number */}
            </Typography>
            <Controller
              name="emailORPhoneNumber"
              control={control}
              rules={{
                required: "Email is required",
                // pattern: {
                //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                //   message: "Invalid email address",
                // },
              }}
              render={({ field }) => (
                <TextField
                  // label="Email or Phone Number"
                  variant="outlined"
                  fullWidth
                  required
                  className="auth_input_field"
                  sx={{ height: "48px" }}
                  error={!!errors.emailORPhoneNumber}
                  helperText={
                    errors.emailORPhoneNumber
                      ? errors.emailORPhoneNumber.message
                      : ""
                  }
                  {...field}
                  onKeyPress={handleKeyPress}
                />
              )}
            />
          </Box>
          <Button
            style={{ marginTop: "24px" }}
            className="log_in_btn"
            variant="contained"
            color="secondary"
            type="submit"
            disableElevation
            fullWidth
            disabled={disableButton}
          >
            {showLoader ? (
              <CircularProgress
                size={24}
                style={{ color: "white", marginRight: "10px" }}
              />
            ) : (
              ""
            )}
            Continue
          </Button>
        </form>

        <Box className="policy">
          <Typography
            variant="subtitle1"
            fontSize={"16px"}
            fontWeight={400}
            lineHeight={"175%"}
            fontStyle={"normal"}
            color={"#000"}
            letterSpacing={"0.15px"}
          >
            Back to{" "}
            <Link
              color="primary"
              variant="body1"
              fontWeight="medium"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
            >
              Log In
            </Link>
          </Typography>
        </Box>

        <Box className="sign_up">
          <Typography
            variant="subtitle1"
            fontSize={"16px"}
            fontWeight={400}
            lineHeight={"175%"}
            fontStyle={"normal"}
            color={"#000"}
            letterSpacing={"0.15px"}
            textAlign={"center"}
          >
            New to Apex Mechanic?{" "}
            <Link
              variant="body1"
              sx={{
                fontWeight: "medium",
                color: "primary",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/signup");
              }}
            >
              Sign Up
            </Link>
          </Typography>
        </Box>
      </div>
    </div>
  );
};

export default ForgotPassword;
