import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Modal,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";
import React from "react";
import { Controller, useForm } from "react-hook-form"; // Step 1
import { useDispatch } from "react-redux";
import {
  fetchShopClosingTime,
  reschuleAppointment,
} from "../../redux/appointment/action";
import SuccessMessageModal from "../Common/SuccessMessage";
import "../Estimates/Estimates.css";

// const dayjs = require("dayjs");
// import dayjsPluginUTC from "dayjs-plugin-utc";

import advancedFormat from "dayjs/plugin/advancedFormat"; // Import advanced format plugin
import timezone from "dayjs/plugin/timezone"; // Import timezone plugin
import utc from "dayjs/plugin/utc"; // Import UTC plugin
// import { DateTime } from "luxon";
// Extend dayjs with plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const convertToLocalTime = (dateTimeString, fromTimeZone, toTimeZone) => {
  // Parse the date-time string in the original time zone
  const localTime = dayjs.tz(dateTimeString, fromTimeZone).tz(toTimeZone);
  return localTime;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const textAreaStyle = {
  width: "100%",
  height: "100px",
  fontSize: "16px",
  border: "1px solid #ccc",
  borderRadius: "4px",
  padding: "10px",
  fontFamily: "Roboto",
  fontWeight: 400,
};

const ReseduleAppointmentModal = ({
  open,
  handleClose,
  id,
  handleClose2,
  date,
  recallDetailsAPI,
}) => {
  const {
    control,
    handleSubmit,
    resetField,
    reset,
    formState: { errors },
  } = useForm(); // Step 2

  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = React.useState(false);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [dropOffDateTime, setDropOffDateTime] = React.useState(null);
  const [pickUpDateAndTime, setPickUpDateAndTime] = React.useState(null);
  const [validationDateError, setValidationDateError] = React.useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const toTimeZone = dayjs.tz.guess();

  const onSubmit = async (data) => {
    if (dropOffDateTime && pickUpDateAndTime) {
      setValidationDateError("");
      const dropOffNewDate = new Date(
        dropOffDateTime.$y,
        dropOffDateTime.$M,
        dropOffDateTime.$D,
        dropOffDateTime.$H,
        dropOffDateTime.$m,
        dropOffDateTime.$s,
        dropOffDateTime.$ms
      );
      const isoDropOffDate = dropOffNewDate.toISOString();

      const newDate = new Date(
        pickUpDateAndTime.$y,
        pickUpDateAndTime.$M,
        pickUpDateAndTime.$D,
        pickUpDateAndTime.$H,
        pickUpDateAndTime.$m,
        pickUpDateAndTime.$s,
        pickUpDateAndTime.$ms
      );
      const isoPickUpDate = newDate.toISOString();

      const date1String = isoDropOffDate;
      const date2String = isoPickUpDate;

      const date1 = new Date(date1String);
      const date2 = new Date(date2String);

      if (date1.getTime() === date2.getTime()) {
        setValidationDateError(
          "Estimated pick up time must be after drop off."
        );
        return;
      } else if (date1 > date2) {
        setValidationDateError(
          "Estimated pick up time must be after drop off."
        );
        return;
      }
      setShowLoader(true);
      let payload = {
        dropOffDateTime: isoDropOffDate,
        pickupDateTime: isoPickUpDate,
        appointmentId: id,
      };

      if (data.noteForCustomer) {
        payload.noteForCustomer = data.noteForCustomer;
      } else {
        delete payload.noteForCustomer;
      }

      const reschedule = await dispatch(reschuleAppointment(payload));
      if (reschedule) {
        setShowLoader(false);
        reset();
        setOpenSuccessModal(true);
        setSuccessMessage("Appointment rescheduled successfully");
        setTimeout(() => {
          handleClose();
          handleClose2();
        }, 1000);
        if (
          window.location.pathname.split("/").includes("workOrderAuthorization")
        ) {
          recallDetailsAPI();
        }
      }
      setShowLoader(false);
    } else {
      setValidationDateError(
        "Propose and Pick up Date and Time both are required"
      );
    }
    setShowLoader(false);
  };

  const fetchClosingTime = async (time) => {
    // console.log("time", time);
    // console.log("time", time.utc().toDate());
    // console.log(
    //   "timee",
    //   dayjs(time.utc().toDate()).format("YYYY-MM-DD"),
    //   new Date(time.utc().toDate()).toLocaleTimeString(),
    //   dayjs(time.utc().toDate()).offsetName()
    // );
    // const modifiedTime = time.format("YYYY-MM-DD") + "T04:01:00.000Z";
    // console.log("modifiedTime", modifiedTime);
    let resp = await dispatch(
      fetchShopClosingTime({ dropOffDateTime: time.utc().toDate() })
    );

    // const localTime = dayjs.utc(resp?.shopClosingTime).tz(resp?.shopTimeZone);
    const localTime = convertToLocalTime(
      resp?.shopClosingTime,
      "Africa/Abidjan",
      resp?.shopTimeZone ?? "America/Toronto"
    );
    setPickUpDateAndTime(localTime);
  };

  return (
    <>
      <Box>
        <Grid container>
          <Grid item xs={9} md={9} xl={9}>
            <Modal
              open={open}
              onClose={() => {
                handleClose();
                setValidationDateError("");
              }}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                {" "}
                <Box
                  sx={{
                    ...style,
                    height: "fit-content",
                    width: "848px",
                    border: "none",
                  }}
                >
                  <AppBar
                    variant="elevation"
                    sx={{
                      height: "64px",
                      paddingRight: "0px !important",
                      boxShadow: "none",
                    }}
                  >
                    <Toolbar variant="regular">
                      <Typography variant="h6" fontWeight={500} width={"98%"}>
                        Reschedule Appointment
                      </Typography>
                      <Stack direction={"row"} spacing={0}>
                        <div
                          onClick={() => {
                            handleClose();
                            resetField();
                          }}
                          style={{ cursor: "pointer", height: "32px" }}
                        >
                          <CloseIcon sx={{ fontSize: 32 }} />
                        </div>
                      </Stack>
                    </Toolbar>
                  </AppBar>
                  <Container
                    gap={4}
                    disableGutters
                    style={{ marginTop: "66px" }}
                  >
                    <Grid container padding={"10px 0px"}>
                      <Grid container item xs={12} md={12} xl={12} spacing={3}>
                        <Grid item>
                          <Stack spacing={2}>
                            <Stack>
                              <Typography
                                variant="body1"
                                fontWeight={500}
                                fontSize={"16px"}
                              >
                                Current Appointment Drop Off
                              </Typography>
                            </Stack>
                            <Stack>
                              <Typography
                                variant="body1"
                                fontWeight={500}
                                fontSize={"16px"}
                              >
                                {/* {date} */}

                                {date &&
                                  moment(date).format(
                                    "ddd. MMM D, YYYY h:mm A"
                                  )}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={12} xl={12}>
                          <Typography
                            variant="body1"
                            padding={"8px 0px"}
                            fontWeight={500}
                            fontSize={16}
                          >
                            New Drop Off Date and Time{" "}
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={2}>
                              <DateTimePicker
                                name="proposeDateAndTime"
                                // value={proposeTime ? dayjs(`2022-04-17T${proposeTime}`) : null}
                                onChange={(newValue) => {
                                  setDropOffDateTime(newValue);

                                  let value = JSON.parse(
                                    localStorage.getItem("apex-saas_details")
                                  )?.shopClosingTime;
                                  let time = dayjs(value).format("hh:mm A");
                                  fetchClosingTime(newValue);
                                  // getShopClosingTime(time);
                                  // setPickUpDateAndTime(
                                  //   dayjs(
                                  //     `${dayjs(newValue).format(
                                  //       "YYYY-MM-DD"
                                  //     )} ${time}`
                                  //   )
                                  // );
                                }}
                                timeSteps={{ minutes: 30 }}
                              />
                            </Stack>
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={12} xl={12}>
                          <Typography
                            variant="body1"
                            padding={"8px 0px"}
                            fontWeight={500}
                            fontSize={16}
                          >
                            New Pick Up Date and Time
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={2}>
                              <DateTimePicker
                                name="pickUpDateAndTime"
                                value={pickUpDateAndTime}
                                onChange={(newValue) => {
                                  setPickUpDateAndTime(newValue);
                                }}
                                timeSteps={{ minutes: 30 }}
                              />
                            </Stack>
                          </LocalizationProvider>
                          {validationDateError && (
                            <p
                              style={{
                                color: "red",
                                margin: "0",
                                fontSize: "14px",
                                lineHeight: "24px",
                              }}
                            >
                              {validationDateError}
                            </p>
                          )}
                        </Grid>
                        <Grid item xs={12} md={12} xl={12}>
                          <Stack spacing={2}>
                            <Controller
                              name="noteForCustomer"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <textarea
                                    fullWidth
                                    variant="outlined"
                                    {...field}
                                    style={textAreaStyle}
                                    placeholder="Type Optional Message"
                                  />
                                </>
                              )}
                            />
                          </Stack>
                        </Grid>

                        <Grid
                          item
                          container
                          justifyContent={"end"}
                          spacing={2}
                          style={{ marginTop: "0px" }}
                        >
                          <Grid item>
                            <Stack spacing={2} direction="row">
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  handleClose();
                                  reset();
                                }}
                              >
                                CANCEL
                              </Button>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                              >
                                RESCHEDULE
                              </Button>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Container>
                </Box>
              </form>
            </Modal>
          </Grid>
        </Grid>
        <SuccessMessageModal
          message={successMessage}
          open={openSuccessModal}
          setOpenSuccessModal={setOpenSuccessModal}
        />
        <Dialog fullScreen={fullScreen} open={showLoader}>
          <DialogContent>
            <CircularProgress />
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
};

export default ReseduleAppointmentModal;
