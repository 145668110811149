import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Input,
  MenuItem,
  Radio,
  Select,
  Stack,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
// import Link from "@mui/material/Link";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  getMergeCustomerByIds,
  mergeCustomer,
  removeMergeCustomer,
  vehicleMakeMaster,
  vehicleModelMaster,
  vehicleTrimMaster,
  vehicleYearMaster,
} from "../../redux/Customer/action";
import "./Merge.css";
import SuccessMessageModal from "../Common/SuccessMessage";
import Loader from "../Common/Loader";
import {
  formatPhoneNumber,
  inputFormatPhoneNumber,
} from "../Common/FormatPhoneNumber";
import { countries } from "../../config/dataService/countries";
const steps = ["Select Value", "Review and Merge"];
const NewMergeCustomers = () => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    getValues,
    reset,
  } = useForm();
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [firstContinueClicked, SetFirstContinueClicked] = useState(false);
  const [secondContinueClicked, SetSecondContinueClicked] = useState(false);
  const [yearLoader, setYearLoader] = useState(false);

  const [makeLoader, setMakeLoader] = useState(false);

  const [modelLoader, setModelLoader] = useState({});
  const [trimLoader, setTrimLoader] = useState({});
  const [clickedFirstNameCustomField, setClickedFirstNameCustomField] =
    useState(false);
  const [selectedFirstName, setSelectedFirstName] = useState({
    id: "",
    value: "",
  });
  const [customeFirstName, setCustomFirstName] = useState("");

  const [clickedLastNameCustomField, setClickedLastNameCustomField] =
    useState(false);
  const [selectedLastName, setSelectedLastName] = useState({
    id: "",
    value: "",
  });
  const [customeLastName, setCustomLastName] = useState("");

  const [clickedPhoneCustomField, setClickedPhoneCustomField] = useState(false);
  const [selectedPhone, setSelectedPhone] = useState({ id: "", value: "" });
  const [customePhone, setCustomPhone] = useState("");

  const [clickedEmailCustomField, setClickedEmailCustomField] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState({ id: "", value: "" });
  const [customeEmail, setCustomEmail] = useState("");

  const [clickedCompanyCustomField, setClickedCompanyCustomField] =
    useState(false);
  const [selectedCompany, setSelectedCompany] = useState({ id: "", value: "" });
  const [customeCompany, setCustomCompany] = useState("");
  const [openRemoveMerge, setOpenRemoveMerge] = useState(false);
  const [yearPageLimit, setYearPageLimit] = useState({ page: 1, limit: 1000 });
  const [makeValuePage, setMakeValuePage] = useState(1);
  const [modelValuePage, setModelValuePage] = useState(1);
  const [trimValuePage, setTrimValuePage] = useState(1);
  const [selectedVehicleValues, setSelectedvehicleValues] = useState([]);

  const [selectedYearValues, setSelectedYearValues] = useState([]);

  const [selectedMakeValues, setSelectedMakeValues] = useState([]);
  const [selectedModelValues, setSelectedModelValues] = useState([]);
  const [selectedTrimValues, setSelectedTrimValues] = useState([]);

  const [checkedVehicles, setCheckedVehicles] = useState([]);

  const [modelsList, setModelsList] = useState([]);
  const [trimList, setTrimList] = useState([]);
  const [customVIN, setCustomVIN] = useState([]);
  const [customLicense, setCustomLicense] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const [selectedCountryCode, setSelectedCountryCode] = useState("+1");
  const [selectedRemoveId, setSelectedRemoveId] = useState();
  const [haveAtleastOneVehicle, setHaveAtleastOnevehicle] = useState(false);
  const [addedMoreVehicles, setAddedMoreVehicles] = useState([]);
  let vehicleMakeParams = {
    page: makeValuePage,
    limit: 100,
  };

  const [data, setData] = useState([
    // {
    //   id: "1",
    //   firstName: "Harjot",
    //   lastName: "Singh",
    //   emailId: "harjotsingh@gmail.com",
    //   phoneNumber: "sdsdsd",
    //   company: "c1",
    // },
    // {
    //   id: "2",
    //   firstName: "Harjot2",
    //   lastName: "sdsds",
    //   emailId: "harjotsingh2@gmail.com",
    //   phoneNumber: "4543354",
    //   company: "c2",
    // },
    // {
    //   firstName: "Harjot3",
    //   lastName: "Singh3",
    //   emailId: "sdsdsds",
    //   phoneNumber: "4543354",
    //   company: "sdsd",
    // },
  ]);

  useEffect(() => {
    const customerIds = JSON.parse(localStorage.getItem("mergeCustomeDetails"));
    console.log("customerIds", customerIds);
    let payload = customerIds;
    fetchMergeCustomerDetails(payload);
  }, [localStorage.getItem("mergeCustomeDetails")]);

  const fetchMergeCustomerDetails = async (payload) => {
    setShowLoader(true);
    await dispatch(getMergeCustomerByIds(payload));
    setShowLoader(false);
  };
  const mergeDatas = useSelector((state) => state.customer.mergeCustomerData);
  console.log("mergeDatas", mergeDatas);
  useEffect(() => {
    setData(mergeDatas?.data);
    let yearValues = [];
    let makeValues = [];
    let modelValues = [];
    let trimValues = [];

    let vehicleValues = [];
    mergeDatas?.data?.map(async (d) => {
      // d?.map?.vehicle((veh) => {
      yearValues.push({ id: d?._id, value: d?.vehicle?.vehicleYear + "" });
      makeValues.push({
        id: d?._id,
        value: JSON.stringify({
          id: d?.vehicle?.vehicleMake?._id,
          name: d?.vehicle?.vehicleMake?.name,
        }),
      });
      modelValues.push({
        id: d?._id,
        value: JSON.stringify({
          id: d?.vehicle?.vehicleModel?._id,
          name: d?.vehicle?.vehicleModel?.name,
        }),
      });
      trimValues.push({
        id: d?._id,
        value: JSON.stringify({
          id: d?.vehicle?.vehicleTrim?._id,
          name: d?.vehicle?.vehicleTrim?.name,
        }),
      });

      vehicleValues.push({
        id: d?._id,
        year: d?.vehicle?.vehicleYear + "",
        make: JSON.stringify({
          id: d?.vehicle?.vehicleMake?._id,
          name: d?.vehicle?.vehicleMake?.name,
        }),
        model: JSON.stringify({
          id: d?.vehicle?.vehicleModel?._id,
          name: d?.vehicle?.vehicleModel?.name,
        }),
      });
      // });
      const data = await Promise.all(
        mergeDatas?.data.map((d) => {
          if (d?.vehicle?.vehicleMake?._id) {
            return fetchVehicleModelByLoop({
              page: modelValuePage,
              limit: 1000,
              vehicleMakeId: d?.vehicle?.vehicleMake?._id,
              selectedId: d?._id,
            });
          }
        })
      );
      setModelsList(data);

      const trimsData = await Promise.all(
        mergeDatas?.data.map((d) => {
          if (d?.vehicle?.vehicleMake?._id && d?.vehicle?.vehicleModel?._id) {
            return fetchVehicleTrimByLoop({
              page: trimValuePage,
              limit: 1000,
              vehicleMakeId: d?.vehicle?.vehicleMake?._id,
              vehicleModelId: d?.vehicle?.vehicleModel?._id,
              selectedId: d?._id,
            });
          }
        })
      );
      setTrimList(trimsData);

      console.log("dataapi", data);
    });

    setSelectedMakeValues(makeValues);
    setSelectedvehicleValues(vehicleValues);
    setSelectedYearValues(yearValues);
    setSelectedModelValues(modelValues);
    setSelectedTrimValues(trimValues);
  }, [mergeDatas]);

  useEffect(() => {
    fetchVehicleYear(yearPageLimit.page, yearPageLimit.limit);
    fetchVehicleMake(vehicleMakeParams);
  }, []);

  const fetchVehicleYear = async (yearPage, yearLimit) => {
    setYearLoader(true);
    await dispatch(vehicleYearMaster({ page: yearPage, limit: yearLimit }));
    setYearLoader(false);
  };
  const year = useSelector((state) => state.customer?.vehicleYear);

  const fetchVehicleMake = async (vehicleMakeParams) => {
    setMakeLoader(true);
    await dispatch(vehicleMakeMaster(vehicleMakeParams));
    setMakeLoader(false);
  };
  const make = useSelector((state) => state.customer?.vehicleMake);

  const fetchVehicleModel = async (vehicleModelParams) => {
    let params = { ...vehicleModelParams };
    delete params["selectedId"];

    setModelLoader({ id: vehicleModelParams.selectedId, loader: true });
    let allModelsList = [...modelsList];
    let allTrimList = [...trimList];

    let findIndexModelList = allModelsList.findIndex(
      (d) => d?.id == vehicleModelParams.selectedId
    );
    if (findIndexModelList !== -1) allModelsList[findIndexModelList].data = [];

    let findIndexTrimList = allTrimList.findIndex(
      (d) => d?.id == vehicleModelParams.selectedId
    );
    if (findIndexTrimList !== -1) allTrimList[findIndexTrimList].data = [];

    const resp = await dispatch(vehicleModelMaster(params));
    console.log("resp", resp);
    if (resp) {
      if (findIndexModelList !== -1) {
        allModelsList[findIndexModelList].data = resp;
      } else {
        allModelsList.push({
          data: resp,
          id: vehicleModelParams.selectedId,
        });
      }
      setModelsList(allModelsList);
    }
    setModelLoader({ id: vehicleModelParams.selectedId, loader: false });
    // return { id: vehicleModelParams?.selectedId, data: resp };
  };

  const fetchVehicleModelByLoop = async (vehicleModelParams) => {
    let params = { ...vehicleModelParams };
    delete params["selectedId"];

    const resp = await dispatch(vehicleModelMaster(params));

    return { id: vehicleModelParams?.selectedId, data: resp };
  };

  const fetchVehicleTrim = async (vehicleTrimParams) => {
    let params = { ...vehicleTrimParams };
    delete params["selectedId"];

    setTrimLoader({ id: vehicleTrimParams.selectedId, loader: true });
    let allTrimList = [...trimList];

    let findIndexTrimList = allTrimList.findIndex(
      (d) => d?.id == vehicleTrimParams.selectedId
    );
    if (findIndexTrimList !== -1) allTrimList[findIndexTrimList].data = [];
    const resp = await dispatch(vehicleTrimMaster(params));
    console.log("resp", resp);
    if (resp) {
      if (findIndexTrimList !== -1) {
        allTrimList[findIndexTrimList].data = resp;
      } else {
        allTrimList.push({
          data: resp,
          id: vehicleTrimParams.selectedId,
        });
      }
      setTrimList(allTrimList);
    }
    setTrimLoader({ id: vehicleTrimParams.selectedId, loader: false });
  };
  const fetchVehicleTrimByLoop = async (vehicleTrimParams) => {
    let params = { ...vehicleTrimParams };
    delete params["selectedId"];

    const resp = await dispatch(vehicleTrimMaster(params));

    return { id: vehicleTrimParams?.selectedId, data: resp };
  };
  const clickedFirstContinueButton = () => {
    console.log(
      "values",
      selectedFirstName,
      selectedLastName,
      selectedEmail,
      selectedCompany,
      selectedPhone
    );

    if (selectedFirstName?.value == "") {
      setAlertMessage("First name is required");
      setMessageType("error");
      setShowMessage(true);
      return;
    }
    // if (selectedLastName?.value == "") {
    //   setAlertMessage("Last name is required");
    //   setMessageType("error");
    //   setShowMessage(true);
    //   return;
    // }
    if (selectedPhone?.value == "") {
      setAlertMessage("Phone Number is required");
      setMessageType("error");
      setShowMessage(true);
      return;
    }
    if (selectedPhone?.value != "") {
      let number = selectedPhone?.value;
      let withoutDashNumber = number.replace(/-/g, "");

      if (withoutDashNumber.length != 10) {
        setAlertMessage("Phone number should be 10 digit");
        setMessageType("error");
        setShowMessage(true);
        return;
      }
    }

    // if (selectedEmail?.value == "") {
    //   setAlertMessage("Email is required");
    //   setMessageType("error");
    //   setShowMessage(true);
    //   return;
    // }
    // if (selectedCompany?.value == "") {
    //   setAlertMessage("Company is required");
    //   setMessageType("error");
    //   setShowMessage(true);
    //   return;
    // }
    SetFirstContinueClicked(!firstContinueClicked);
  };
  const clickedBackButton = () => {
    SetFirstContinueClicked(!firstContinueClicked);
  };
  const secondContinueButtonClicked = async () => {
    setShowLoader(true);

    let payload = {
      customerIds: JSON.parse(localStorage.getItem("mergeCustomeDetails"))?.id,
      firstName: selectedFirstName?.value,
      // lastName: selectedLastName?.value,
      // email: selectedEmail?.value,
      countryCode: selectedCountryCode,
      phoneNumber: selectedPhone?.value,
    };

    if (selectedLastName?.value) {
      payload.lastName = selectedLastName?.value;
    }
    if (selectedEmail?.value) {
      payload.email = selectedEmail?.value;
    }
    if (selectedCompany?.value) {
      payload.company = selectedCompany?.value;
    }

    let vehicles = [];
    selectedVehicleValues?.map((vehicle) => {
      let data = {};
      if (checkedVehicles.includes(vehicle.id)) {
        if (vehicle?.year) {
          data.vehicleYear = vehicle?.year;
        }
        if (vehicle?.make) {
          data.vehicleMake = JSON.parse(vehicle?.make)?.id;
        }
        if (vehicle?.model) {
          data.vehicleModel = JSON.parse(vehicle?.model)?.id;
        }
        if (vehicle?.trim) {
          data.vehicleTrim = JSON.parse(vehicle?.trim)?.id;
        }
        if (vehicle?.vin) {
          data.vin = vehicle?.vin;
        }
        if (vehicle?.license) {
          data.license = vehicle?.license;
        }
        vehicles.push(data);
      }
    });
    payload.vehicle = vehicles;

    const resp = await dispatch(mergeCustomer(payload));
    if (resp.status) {
      setAlertMessage("Customer Merged Successfully");
      setMessageType("success");
      setShowMessage(true);
      navigate(`/customers/${resp?.data?._id}/shop_imported`);
    }
    setShowLoader(false);
  };
  const cancelButtonClicked = () => {
    navigate("/customers/merge");
  };
  const handleCloseRemoveMerge = () => {
    setOpenRemoveMerge(false);
    setSelectedRemoveId("");
  };

  const handleOpenRemoveMerge = () => {
    setOpenRemoveMerge(true);
  };
  const handleRemoveMerge = async () => {
    setShowLoader(true);

    const resp = await dispatch(removeMergeCustomer(selectedRemoveId));

    if (resp) {
      setAlertMessage("Customer Removed Successfully");
      setMessageType("success");
      setShowMessage(true);
      let ids = JSON.parse(localStorage.getItem("mergeCustomeDetails"))?.id;
      ids = ids.filter((id) => id !== selectedRemoveId);
      localStorage.setItem("mergeCustomeDetails", JSON.stringify({ id: ids }));

      setSelectedRemoveId("");
    }
    setShowLoader(false);
    setOpenRemoveMerge(false);
  };

  useEffect(() => {
    let vehicleHave = false;
    {
      data?.map((d, index) => {
        if (
          d?.vehicle?.vehicleYear ||
          d?.vehicle?.vehicleMake ||
          d?.vehicle?.vehicleModel
        ) {
          vehicleHave = true;
          return;
        }
      });
    }

    setHaveAtleastOnevehicle(vehicleHave);
  }, [data]);
  const handleAddMoreVehicle = () => {
    let length = addedMoreVehicles.length;
    let keyname = `custom${length + 1}`;
    setAddedMoreVehicles([
      ...addedMoreVehicles,
      { key: keyname, id: length + 1 },
    ]);
  };
  console.log(
    "selecetedvalues",
    selectedYearValues
    // selectedMakeValues,
    // selectedModelValues,
    // selectedTrimValues
  );
  // console.log("modelsList", modelsList);
  console.log("selectedVehicleValues", selectedVehicleValues);
  console.log("selectedMakeValues", selectedMakeValues);

  return (
    <>
      <Box
        padding={"32px 24px"}
        sx={{ width: "-webkit-fill-available", position: "absolute" }}
      >
        <Grid container sx={{ padding:'16px 0px' }} gap={'8px'}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div role="presentation">
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  to="/customers/merge"
                  style={{
                    color: "black",
                    opacity: "0.6",
                    textDecoration: "none",
                  }}
                >
                  Merge
                </Link>
                <Link className="linkContent">
                  <Typography color="black">New Merge</Typography>
                </Link>
              </Breadcrumbs>
            </div>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className="newMergeheading">New Merge</Typography>
          </Grid>
        </Grid>

        <Grid container mt={"16px"} justifyContent={"center"}>
          <Stepper activeStep={activeStep} sx={{ width: "50%" }}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps} className="stepIcon">
                    {" "}
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Grid>
        {!firstContinueClicked && (
          <>
            <Box boxShadow={3} borderRadius={4}>
              <Box className="basicDetailBox">
                <Grid
                  container
                  // columns={26}
                  md={4}
                  height={"fit-Content"}
                >
                  <Grid container md={12} sx={{ height: "75px" }}>
                    <Typography className="customTypographySection"></Typography>
                  </Grid>
                  <Grid
                    container
                    md={12}
                    bgcolor={"#EEEEEE"}
                    alignItems={"center"}
                    className="borderBottomColor"
                    sx={{ mt: "24px" }}
                  >
                    <Typography className="customTypographySectionHeader">
                      Basic Details
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    md={12}
                    alignItems={"center"}
                    className="borderBottomColor"
                  >
                    <Typography className="customTypographySectionHeader">
                      First Name
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    md={12}
                    alignItems={"center"}
                    className="borderBottomColor"
                  >
                    <Typography className="customTypographySectionHeader">
                      Last Name
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    md={12}
                    alignItems={"center"}
                    className="borderBottomColor"
                    height={"55px"}
                  >
                    <Typography className="customTypographySectionHeader">
                      Phone Number{" "}
                      <Select
                        value={selectedCountryCode}
                        onChange={(e) => setSelectedCountryCode(e.target.value)}
                        sx={{
                          height: "30px",
                          minWidth: "70px",
                          maxWidth: "fitContent",
                        }}
                        MenuProps={{
                          style: { maxHeight: "400px" },
                        }}
                      >
                        {countries?.map((country) => (
                          <MenuItem
                            value={"+" + country.phone}
                            key={"+" + country.phone}
                          >
                            {"+" + country.phone}
                          </MenuItem>
                        ))}
                      </Select>
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    md={12}
                    alignItems={"center"}
                    className="borderBottomColor"
                  >
                    <Typography className="customTypographySectionHeader">
                      Email
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    md={12}
                    alignItems={"center"}
                    className="borderBottomColor"
                  >
                    <Typography className="customTypographySectionHeader">
                      Company
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item className="mergeInline">
                  {data?.map((data, index) => {
                    return (
                      <Grid
                        container
                        md={4}
                        key={data._id}

                        //className="borderBottomColor"
                        // borderBottom={"1px solid black"}
                      >
                        <Grid
                          item
                          md={12}
                          display={"flex"}
                          justifyContent={"center"}
                          bgcolor={
                            index % 2 == 0
                              ? "rgba(255, 224, 178, 0.6)"
                              : "rgba(255, 248, 225, 0.60)"
                          }
                          // className="borderBottomColor"
                        >
                          {" "}
                          <Button
                            sx={{
                              mt: "16px",
                              mb:
                                mergeDatas?.data?.length > 2 ? "18px" : "16px",
                            }}
                            color="error"
                            size="large"
                            variant="contained"
                            onClick={() => {
                              handleOpenRemoveMerge();
                              setSelectedRemoveId(data?._id);
                            }}
                          >
                            REMOVE FROM MERGE
                          </Button>{" "}
                        </Grid>
                        <Grid
                          container
                          md={12}
                          bgcolor={"#EEEEEE"}
                          alignItems={"center"}
                          sx={{ height: "57px", mt: "24px" }}
                          className="borderBottomColorWithBoxHeight"
                        >
                          <Typography className="customTypographySection"></Typography>
                        </Grid>

                        <Grid
                          container
                          md={12}
                          alignItems={"center"}
                          bgcolor={
                            index % 2 == 0
                              ? "rgba(255, 224, 178, 0.6)"
                              : "rgba(255, 248, 225, 0.60)"
                          }
                          className="borderBottomColorWithBoxHeight"
                          onClick={(e) =>
                            setSelectedFirstName({
                              id: data?._id,
                              value: data?.firstName,
                            })
                          }
                        >
                          {data?.firstName && (
                            <Radio
                              className="customTypographySectionRadio"
                              checked={
                                selectedFirstName.id == data._id &&
                                selectedFirstName.value == data?.firstName
                              }
                            />
                          )}
                          <Typography className="customTypographySection">
                            {data?.firstName}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          md={12}
                          bgcolor={
                            index % 2 == 0
                              ? "rgba(255, 224, 178, 0.6)"
                              : "rgba(255, 248, 225, 0.60)"
                          }
                          alignItems={"center"}
                          className="borderBottomColorWithBoxHeight"
                          onClick={(e) =>
                            setSelectedLastName({
                              id: data?._id,
                              value: data?.lastName,
                            })
                          }
                        >
                          {data?.lastName && (
                            <Radio
                              className="customTypographySectionRadio"
                              checked={
                                selectedLastName.id == data._id &&
                                selectedLastName.value == data?.lastName
                              }
                            />
                          )}
                          <Typography className="customTypographySection">
                            {data?.lastName}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          md={12}
                          bgcolor={
                            index % 2 == 0
                              ? "rgba(255, 224, 178, 0.6)"
                              : "rgba(255, 248, 225, 0.60)"
                          }
                          alignItems={"center"}
                          className="borderBottomColorWithBoxHeight"
                          onClick={(e) =>
                            setSelectedPhone({
                              id: data?._id,
                              value: formatPhoneNumber(data?.phoneNumber),
                            })
                          }
                        >
                          {data?.phoneNumber && (
                            <Radio
                              className="customTypographySectionRadio"
                              checked={
                                selectedPhone.id == data._id &&
                                selectedPhone.value ==
                                  formatPhoneNumber(data?.phoneNumber)
                              }
                            />
                          )}
                          <Typography className="customTypographySection">
                            {formatPhoneNumber(data?.phoneNumber)}
                            {/* {data?.phoneNumber?<formatPhoneNumber phoneNumber={data?.phoneNumber}/>:""}  */}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          md={12}
                          bgcolor={
                            index % 2 == 0
                              ? "rgba(255, 224, 178, 0.6)"
                              : "rgba(255, 248, 225, 0.60)"
                          }
                          alignItems={"center"}
                          className="borderBottomColorWithBoxHeight"
                          onClick={(e) =>
                            setSelectedEmail({
                              id: data?._id,
                              value: data?.email,
                            })
                          }
                        >
                          {data?.email && (
                            <Radio
                              className="customTypographySectionRadio"
                              checked={
                                selectedEmail.id == data._id &&
                                selectedEmail.value == data?.email
                              }
                            />
                          )}

                          <Typography className="customTypographySection">
                            {data?.email}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          md={12}
                          bgcolor={
                            index % 2 == 0
                              ? "rgba(255, 224, 178, 0.6)"
                              : "rgba(255, 248, 225, 0.60)"
                          }
                          alignItems={"center"}
                          className="borderBottomColorWithBoxHeight"
                          onClick={(e) =>
                            setSelectedCompany({
                              id: data?._id,
                              value: data?.company,
                            })
                          }
                        >
                          {data?.company && (
                            <Radio
                              className="customTypographySectionRadio"
                              checked={
                                selectedCompany.id == data._id &&
                                selectedCompany.value == data?.company
                              }
                            />
                          )}
                          <Typography className="customTypographySection">
                            {data?.company}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Grid container md={4}>
                    <Grid item md={12} className="customBox">
                      {" "}
                      <Typography
                        className="customTypographySectionHeader"
                        // sx={{ mt: "11px", mb: "11px" }}
                        sx={{ alignItems: "center", display: "flex" }}
                      >
                        Custom
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      md={12}
                      bgcolor={"#EEEEEE"}
                      alignItems={"center"}
                      sx={{
                        height: "57px",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                        mt: "24px",
                      }}
                    >
                      <Typography className="customTypographySection"></Typography>
                    </Grid>

                    <Grid
                      container
                      md={12}
                      alignItems={"center"}
                      className="borderBottomColorWithBoxHeight"
                    >
                      {clickedFirstNameCustomField ? (
                        <div
                          onClick={() =>
                            setSelectedFirstName({
                              id: "custom",
                              value: customeFirstName,
                            })
                          }
                        >
                          <Radio
                            className="customTypographySectionRadio"
                            checked={
                              selectedFirstName.id == "custom" &&
                              selectedFirstName.value == customeFirstName
                            }
                          />
                          <Input
                            onChange={(e) => {
                              setCustomFirstName(e.target.value);
                              setSelectedFirstName({
                                id: "custom",
                                value: e.target.value,
                              });
                            }}
                            value={customeFirstName}
                          />
                        </div>
                      ) : (
                        <Typography
                          className="customTypographySectionLink"
                          onClick={() => {
                            setClickedFirstNameCustomField(true);
                          }}
                        >
                          ADD OWN FIELD
                        </Typography>
                      )}
                    </Grid>

                    <Grid
                      container
                      md={12}
                      alignItems={"center"}
                      className="borderBottomColorWithBoxHeight"
                    >
                      {clickedLastNameCustomField ? (
                        <div
                          onClick={() =>
                            setSelectedLastName({
                              id: "custom",
                              value: customeLastName,
                            })
                          }
                        >
                          <Radio
                            className="customTypographySectionRadio"
                            checked={
                              selectedLastName.id == "custom" &&
                              selectedLastName.value == customeLastName
                            }
                          />
                          <Input
                            onChange={(e) => {
                              setCustomLastName(e.target.value);
                              setSelectedLastName({
                                id: "custom",
                                value: e.target.value,
                              });
                            }}
                            value={customeLastName}
                          />
                        </div>
                      ) : (
                        <Typography
                          className="customTypographySectionLink"
                          onClick={() => {
                            setClickedLastNameCustomField(true);
                          }}
                        >
                          ADD OWN FIELD
                        </Typography>
                      )}
                    </Grid>

                    <Grid
                      container
                      md={12}
                      alignItems={"center"}
                      className="borderBottomColorWithBoxHeight"
                    >
                      {clickedPhoneCustomField ? (
                        <div
                          onClick={() =>
                            setSelectedPhone({
                              id: "custom",
                              value: customePhone,
                            })
                          }
                        >
                          <Radio
                            className="customTypographySectionRadio"
                            checked={
                              selectedPhone.id == "custom" &&
                              selectedPhone.value == customePhone
                            }
                          />
                          <Input
                            onChange={(e) => {
                              // if (e.target.value.length <= 10) {
                              // setCustomPhone(formatPhoneNumber(e.target.value));
                              setCustomPhone(
                                inputFormatPhoneNumber(e.target.value)
                              );

                              setSelectedPhone({
                                id: "custom",
                                value: inputFormatPhoneNumber(e.target.value),
                              });
                              // }
                            }}
                            value={customePhone}
                          />
                        </div>
                      ) : (
                        <Typography
                          className="customTypographySectionLink"
                          onClick={() => {
                            setClickedPhoneCustomField(true);
                          }}
                        >
                          ADD OWN FIELD
                        </Typography>
                      )}
                    </Grid>

                    <Grid
                      container
                      md={12}
                      alignItems={"center"}
                      className="borderBottomColorWithBoxHeight"
                    >
                      {clickedEmailCustomField ? (
                        <div
                          onClick={() =>
                            setSelectedEmail({
                              id: "custom",
                              value: customeEmail,
                            })
                          }
                        >
                          <Radio
                            className="customTypographySectionRadio"
                            checked={
                              selectedEmail.id == "custom" &&
                              selectedEmail.value == customeEmail
                            }
                          />
                          <Input
                            onChange={(e) => {
                              setCustomEmail(e.target.value);
                              setSelectedEmail({
                                id: "custom",
                                value: e.target.value,
                              });
                            }}
                            value={customeEmail}
                          />
                        </div>
                      ) : (
                        <Typography
                          className="customTypographySectionLink"
                          onClick={() => {
                            setClickedEmailCustomField(true);
                          }}
                        >
                          ADD OWN FIELD
                        </Typography>
                      )}
                    </Grid>

                    <Grid
                      container
                      md={12}
                      alignItems={"center"}
                      className="borderBottomColorWithBoxHeight"
                    >
                      {clickedCompanyCustomField ? (
                        <div
                          onClick={() =>
                            setSelectedCompany({
                              id: "custom",
                              value: customeCompany,
                            })
                          }
                        >
                          <Radio
                            className="customTypographySectionRadio"
                            checked={
                              selectedCompany.id == "custom" &&
                              selectedCompany.value == customeCompany
                            }
                          />
                          <Input
                            onChange={(e) => {
                              setCustomCompany(e.target.value);
                              setSelectedCompany({
                                id: "custom",
                                value: e.target.value,
                              });
                            }}
                            value={customeCompany}
                          />
                        </div>
                      ) : (
                        <Typography
                          className="customTypographySectionLink"
                          onClick={() => {
                            setClickedCompanyCustomField(true);
                          }}
                        >
                          ADD OWN FIELD
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box className="addVehicle">
                <Button onClick={handleAddMoreVehicle}>
                  Add Additional Vehicles
                </Button>
              </Box>
              <Box className="vehicleDetailBox">
                <Grid
                  container
                  // mt={"32px"}
                  // display="flex"
                  // flexDirection={"column"}
                  md={6}
                  height={"fit-Content"}
                >
                  <Grid
                    container
                    md={12}
                    bgcolor={"#EEEEEE"}
                    alignItems={"center"}
                    className="borderBottomColor"
                  >
                    <Typography className="customTypographySectionHeader">
                      Vehicle Details
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    md={12}
                    alignItems={"center"}
                    className="borderBottomColor"
                  >
                    <Typography className="customTypographySectionHeader">
                      Year <span className="requiredColor">*</span>
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    md={12}
                    alignItems={"center"}
                    className="borderBottomColor"
                  >
                    <Typography className="customTypographySectionHeader">
                      Make <span className="requiredColor">*</span>
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    md={12}
                    alignItems={"center"}
                    className="borderBottomColor"
                  >
                    <Typography className="customTypographySectionHeader">
                      Model <span className="requiredColor">*</span>
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    md={12}
                    alignItems={"center"}
                    className="borderBottomColor"
                  >
                    <Typography className="customTypographySectionHeader">
                      Trim
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    md={12}
                    alignItems={"center"}
                    className="borderBottomColor"
                  >
                    <Typography className="customTypographySectionHeader">
                      VIN
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    md={12}
                    alignItems={"center"}
                    className="borderBottomColor"
                  >
                    <Typography className="customTypographySectionHeader">
                      Licence Number
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item className="mergeInline">
                  {data?.map((data, index) => {
                    return (
                      (data?.vehicle?.vehicleYear ||
                        data?.vehicle?.vehicleMake ||
                        data?.vehicle?.vehicleModel) && (
                        <Grid
                          container
                          md={6}
                          bgcolor={
                            index % 2 == 0
                              ? "rgba(255, 224, 178, 0.6)"
                              : "rgba(255, 248, 225, 0.60)"
                          }
                          key={data?._id}

                          //className="borderBottomColor"
                          // borderBottom={"1px solid black"}
                        >
                          <Grid
                            item
                            md={12}
                            display={"flex"}
                            className="borderBottomColor"
                          >
                            {" "}
                            <Checkbox
                              className="customTypographySectionRadio"
                              onChange={(e) => {
                                let all = [...checkedVehicles];

                                if (!e.target.checked) {
                                  all = all.filter((item) => item !== data._id);
                                } else {
                                  all.push(data._id);
                                }
                                setCheckedVehicles(all);
                              }}
                              checked={checkedVehicles.includes(data._id)}
                            />
                            <Typography className="customTypographySection">
                              <span>
                                {data?.vehicle?.vehicleYear
                                  ? data?.vehicle?.vehicleYear
                                  : " "}
                              </span>
                              <span>
                                {data?.vehicle?.vehicleMake
                                  ? " " + data?.vehicle?.vehicleMake?.name
                                  : " "}
                              </span>

                              <span>
                                {data?.vehicle?.vehicleModel
                                  ? " " + data?.vehicle?.vehicleModel?.name
                                  : " "}
                              </span>
                              <span>
                                {data?.vehicle?.vehicleTrim
                                  ? " " + data?.vehicle?.vehicleTrim?.name
                                  : " "}
                              </span>
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            md={12}
                            alignItems={"center"}
                            sx={{ height: "57px" }}
                            className="borderBottomColor"
                          >
                            <Select
                              style={{
                                width: "100%",
                                height: "30px",
                                marginInline: "16px",
                              }}
                              onChange={(event) => {
                                let all = [...selectedYearValues];

                                let findIndex = all.findIndex(
                                  (d) => d?.id == data._id
                                );
                                if (findIndex !== -1) {
                                  all[findIndex].value = event.target.value;
                                } else {
                                  all.push({
                                    value: event.target.value,
                                    id: data._id,
                                  });
                                }
                                setSelectedYearValues(all);

                                let allVehicles = [...selectedVehicleValues];

                                let findVehicleIndex = allVehicles.findIndex(
                                  (d) => d?.id == data._id
                                );
                                if (findVehicleIndex !== -1) {
                                  allVehicles[findVehicleIndex].year =
                                    event.target.value;
                                } else {
                                  allVehicles.push({
                                    year: event.target.value,
                                    id: data._id,
                                  });
                                }
                                setSelectedvehicleValues(allVehicles);
                              }}
                              MenuProps={{
                                style: { maxHeight: "400px" },
                              }}
                              value={
                                selectedYearValues[
                                  selectedYearValues?.findIndex(
                                    (d) => d?.id == data?._id
                                  )
                                ]?.value
                              }
                              // defaultValue={""}
                            >
                              {Array.isArray(year) &&
                                year?.map((y) => {
                                  return (
                                    <MenuItem value={y} key={y}>
                                      {y}
                                    </MenuItem>
                                  );
                                })}
                              {yearLoader && (
                                <MenuItem>
                                  <CircularProgress />
                                </MenuItem>
                              )}
                            </Select>
                          </Grid>

                          <Grid
                            container
                            md={12}
                            alignItems={"center"}
                            sx={{ height: "57px" }}
                            className="borderBottomColor"
                          >
                            <Select
                              style={{
                                width: "100%",
                                height: "30px",
                                marginInline: "16px",
                              }}
                              value={
                                selectedMakeValues[
                                  selectedMakeValues?.findIndex(
                                    (d) => d?.id == data?._id
                                  )
                                ]?.value
                              }
                              defaultValue={""}
                              onChange={(event) => {
                                let allMakes = [...selectedMakeValues];

                                let findIndex = allMakes.findIndex(
                                  (d) => d?.id == data._id
                                );
                                if (findIndex !== -1) {
                                  allMakes[findIndex].value =
                                    event.target.value;
                                } else {
                                  allMakes.push({
                                    value: event.target.value,
                                    id: data?._id,
                                  });
                                }

                                setSelectedMakeValues(allMakes);

                                let allModels = [...selectedModelValues];

                                let findIndexModel = allModels.findIndex(
                                  (d) => d?.id == data?._id
                                );
                                if (findIndexModel !== -1) {
                                  allModels[findIndexModel].value = "";
                                } else {
                                  allModels.push({
                                    value: "",
                                    id: data?._id,
                                  });
                                }
                                setSelectedModelValues(allModels);

                                let allTrims = [...selectedTrimValues];

                                let findIndexTrim = allTrims.findIndex(
                                  (d) => d?.id == data?._id
                                );
                                if (findIndexTrim !== -1) {
                                  allTrims[findIndexTrim].value = "";
                                } else {
                                  allTrims.push({
                                    value: "",
                                    id: data?._id,
                                  });
                                }
                                setSelectedTrimValues(allTrims);

                                fetchVehicleModel({
                                  page: modelValuePage,
                                  limit: 1000,
                                  vehicleMakeId: JSON.parse(event.target.value)
                                    .id,
                                  selectedId: data?._id,
                                });

                                let allVehicles = [...selectedVehicleValues];

                                let findVehicleIndex = allVehicles.findIndex(
                                  (d) => d?.id == data?._id
                                );
                                if (findVehicleIndex !== -1) {
                                  allVehicles[findVehicleIndex].make =
                                    event.target.value;
                                } else {
                                  allVehicles.push({
                                    make: event.target.value,
                                    id: data?._id,
                                  });
                                }
                                setSelectedvehicleValues(allVehicles);

                                // setModel([]);
                                // setTrim([]);
                                // setTrimValue(null);
                              }}
                              MenuProps={{
                                style: { maxHeight: "400px" },
                              }}
                            >
                              {Array.isArray(make) &&
                                make?.map((m) => {
                                  return (
                                    <MenuItem
                                      value={JSON.stringify({
                                        id: m?._id,
                                        name: m?.name,
                                      })}
                                      key={m?._id}
                                    >
                                      {m?.name}
                                    </MenuItem>
                                  );
                                })}
                              {makeLoader && (
                                <MenuItem>
                                  <CircularProgress />
                                </MenuItem>
                              )}
                            </Select>
                          </Grid>
                          <Grid
                            container
                            md={12}
                            alignItems={"center"}
                            sx={{ height: "57px" }}
                            className="borderBottomColor"
                          >
                            <Select
                              style={{
                                width: "100%",
                                height: "30px",
                                marginInline: "16px",
                              }}
                              value={
                                selectedModelValues[
                                  selectedModelValues?.findIndex(
                                    (d) => d?.id == data?._id
                                  )
                                ]?.value
                              }
                              defaultValue={""}
                              onChange={(event) => {
                                let allModels = [...selectedModelValues];

                                let findIndexModel = allModels.findIndex(
                                  (d) => d?.id == data?._id
                                );
                                if (findIndexModel !== -1) {
                                  allModels[findIndexModel].value =
                                    event.target.value;
                                } else {
                                  allModels.push({
                                    value: event.target.value,
                                    id: data?._id,
                                  });
                                }
                                setSelectedModelValues(allModels);

                                let allTrims = [...selectedTrimValues];

                                let findIndexTrim = allTrims.findIndex(
                                  (d) => d?.id == data?._id
                                );
                                if (findIndexTrim !== -1) {
                                  allTrims[findIndexTrim].value = "";
                                } else {
                                  allTrims.push({
                                    value: "",
                                    id: data?._id,
                                  });
                                }
                                setSelectedTrimValues(allTrims);

                                let allMakes = [...selectedMakeValues];

                                let findIndex = allMakes.findIndex(
                                  (d) => d?.id == data?._id
                                );
                                fetchVehicleTrim({
                                  page: trimValuePage,
                                  limit: 1000,
                                  vehicleMakeId: JSON.parse(
                                    allMakes[findIndex].value
                                  ).id,
                                  vehicleModelId: JSON.parse(event.target.value)
                                    .id,
                                  selectedId: data?._id,
                                });
                                let allVehicles = [...selectedVehicleValues];

                                let findVehicleIndex = allVehicles.findIndex(
                                  (d) => d?.id == data?._id
                                );
                                if (findVehicleIndex !== -1) {
                                  allVehicles[findVehicleIndex].model =
                                    event.target.value;
                                } else {
                                  allVehicles.push({
                                    model: event.target.value,
                                    id: data?._id,
                                  });
                                }
                                setSelectedvehicleValues(allVehicles);
                              }}
                              MenuProps={{
                                style: { maxHeight: "400px" },
                              }}
                            >
                              {modelsList?.map(
                                (d) =>
                                  d?.id == data?._id &&
                                  d?.data?.map((model) => (
                                    <MenuItem
                                      value={JSON.stringify({
                                        id: model?._id,
                                        name: model?.name,
                                      })}
                                      key={model?._id}
                                    >
                                      {model?.name}
                                    </MenuItem>
                                  ))
                              )}
                              {modelLoader?.id == data?._id &&
                                modelLoader?.loader && (
                                  <MenuItem>
                                    <CircularProgress />
                                  </MenuItem>
                                )}
                            </Select>
                          </Grid>

                          <Grid
                            container
                            md={12}
                            alignItems={"center"}
                            sx={{ height: "57px" }}
                            className="borderBottomColor"
                          >
                            <Select
                              style={{
                                width: "100%",
                                height: "30px",
                                marginInline: "16px",
                              }}
                              value={
                                selectedTrimValues[
                                  selectedTrimValues?.findIndex(
                                    (d) => d?.id == data?._id
                                  )
                                ]?.value
                              }
                              defaultValue={""}
                              onChange={(event) => {
                                let allTrims = [...selectedTrimValues];

                                let findIndexTrim = allTrims.findIndex(
                                  (d) => d?.id == data?._id
                                );
                                if (findIndexTrim !== -1) {
                                  allTrims[findIndexTrim].value =
                                    event.target.value;
                                } else {
                                  allTrims.push({
                                    value: event.target.value,
                                    id: data?._id,
                                  });
                                }
                                setSelectedTrimValues(allTrims);
                                let allVehicles = [...selectedVehicleValues];

                                let findVehicleIndex = allVehicles.findIndex(
                                  (d) => d?.id == data?._id
                                );
                                if (findVehicleIndex !== -1) {
                                  allVehicles[findVehicleIndex].trim =
                                    event.target.value;
                                } else {
                                  allVehicles.push({
                                    trim: event.target.value,
                                    id: data?._id,
                                  });
                                }
                                setSelectedvehicleValues(allVehicles);
                              }}
                              MenuProps={{
                                style: { maxHeight: "400px" },
                              }}
                            >
                              {trimList?.map(
                                (d) =>
                                  d?.id == data?._id &&
                                  d?.data?.map((trim) => (
                                    <MenuItem
                                      value={JSON.stringify({
                                        id: trim?._id,
                                        name: trim?.name,
                                      })}
                                      key={trim?._id}
                                    >
                                      {trim?.name}
                                    </MenuItem>
                                  ))
                              )}
                              {trimLoader?.id == data?._id &&
                                trimLoader?.loader && (
                                  <MenuItem>
                                    <CircularProgress />
                                  </MenuItem>
                                )}
                            </Select>
                          </Grid>
                          <Grid
                            container
                            // md={12}
                            alignItems={"center"}
                            sx={{ height: "57px" }}
                            className="borderBottomColor"
                          >
                            {customVIN?.findIndex((d) => d.id == data?._id) !==
                            -1 ? (
                              <Input
                                sx={{ ml: "16px", width: "100%", mr: "16px" }}
                                onChange={(e) => {
                                  let allCustomVINs = [...customVIN];
                                  let findIndex = allCustomVINs?.findIndex(
                                    (d) => d.id == data?._id
                                  );
                                  allCustomVINs[findIndex].value =
                                    e.target.value;
                                  setCustomVIN(allCustomVINs);

                                  let allVehicles = [...selectedVehicleValues];

                                  let findVehicleIndex = allVehicles.findIndex(
                                    (d) => d?.id == data?._id
                                  );
                                  if (findVehicleIndex !== -1) {
                                    allVehicles[findVehicleIndex].vin =
                                      e.target.value;
                                  } else {
                                    allVehicles.push({
                                      vin: e.target.value,
                                      id: data?._id,
                                    });
                                  }
                                  setSelectedvehicleValues(allVehicles);
                                }}
                                value={
                                  customVIN[
                                    customVIN?.findIndex(
                                      (d) => d?.id == data?._id
                                    )
                                  ]?.value
                                }
                              />
                            ) : (
                              <Typography
                                className="customTypographySectionLink"
                                onClick={() => {
                                  let allCustomVINs = [...customVIN];
                                  allCustomVINs.push({
                                    id: data?._id,
                                    value: "",
                                  });
                                  setCustomVIN(allCustomVINs);
                                }}
                              >
                                ADD OWN FIELD
                              </Typography>
                            )}
                          </Grid>

                          <Grid
                            container
                            md={12}
                            alignItems={"center"}
                            sx={{ height: "57px" }}
                            className="borderBottomColor"
                          >
                            {customLicense?.findIndex(
                              (d) => d.id == data?._id
                            ) !== -1 ? (
                              <Input
                                sx={{ ml: "16px", width: "100%", mr: "16px" }}
                                value={
                                  customLicense[
                                    customLicense?.findIndex(
                                      (d) => d?.id == data?._id
                                    )
                                  ]?.value
                                }
                                onChange={(e) => {
                                  let allCustomLicense = [...customLicense];
                                  let findIndex = allCustomLicense?.findIndex(
                                    (d) => d.id == data?._id
                                  );
                                  allCustomLicense[findIndex].value =
                                    e.target.value;
                                  setCustomLicense(allCustomLicense);

                                  let allVehicles = [...selectedVehicleValues];

                                  let findVehicleIndex = allVehicles.findIndex(
                                    (d) => d?.id == data?._id
                                  );
                                  if (findVehicleIndex !== -1) {
                                    allVehicles[findVehicleIndex].license =
                                      e.target.value;
                                  } else {
                                    allVehicles.push({
                                      license: e.target.value,
                                      id: data?._id,
                                    });
                                  }
                                  setSelectedvehicleValues(allVehicles);
                                }}
                              />
                            ) : (
                              <Typography
                                className="customTypographySectionLink"
                                onClick={() => {
                                  let allCustomLicense = [...customLicense];
                                  allCustomLicense.push({
                                    id: data?._id,
                                    value: "",
                                  });
                                  setCustomLicense(allCustomLicense);
                                }}
                              >
                                ADD OWN FIELD
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      )
                    );
                  })}
                  {!haveAtleastOneVehicle && (
                    <Grid
                      container
                      md={6}
                      bgcolor={"rgba(255, 224, 178, 0.6)"}
                      key={"custom"}

                      //className="borderBottomColor"
                      // borderBottom={"1px solid black"}
                    >
                      <Grid
                        item
                        md={12}
                        display={"flex"}
                        className="borderBottomColor"
                      >
                        {" "}
                        <Checkbox
                          className="customTypographySectionRadio"
                          onChange={(e) => {
                            let all = [...checkedVehicles];

                            if (!e.target.checked) {
                              all = all.filter((item) => item !== "custom");
                            } else {
                              all.push("custom");
                            }
                            setCheckedVehicles(all);
                          }}
                          checked={checkedVehicles.includes("custom")}
                        />
                        <Typography
                          className="customTypographySection"
                          style={{ height: "56px" }}
                        >
                          <span>
                            {data?.vehicle?.vehicleYear
                              ? data?.vehicle?.vehicleYear
                              : " "}
                          </span>
                          <span>
                            {data?.vehicle?.vehicleMake
                              ? " " + data?.vehicle?.vehicleMake?.name
                              : " "}
                          </span>

                          <span>
                            {data?.vehicle?.vehicleModel
                              ? " " + data?.vehicle?.vehicleModel?.name
                              : " "}
                          </span>
                          {/* <span>
  {data?.vehicle?.trim
    ? " " + data?.vehicle?.trim?.name
    : " "}
</span> */}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        md={12}
                        alignItems={"center"}
                        sx={{ height: "57px" }}
                        className="borderBottomColor"
                      >
                        <Select
                          style={{
                            width: "100%",
                            height: "30px",
                            marginInline: "16px",
                          }}
                          onChange={(event) => {
                            let all = [...selectedYearValues];

                            let findIndex = all.findIndex(
                              (d) => d?.id == "custom"
                            );
                            if (findIndex !== -1) {
                              all[findIndex].value = event.target.value;
                            } else {
                              all.push({
                                value: event.target.value,
                                id: "custom",
                              });
                            }
                            setSelectedYearValues(all);

                            let allVehicles = [...selectedVehicleValues];

                            let findVehicleIndex = allVehicles.findIndex(
                              (d) => d?.id == "custom"
                            );
                            if (findVehicleIndex !== -1) {
                              allVehicles[findVehicleIndex].year =
                                event.target.value;
                            } else {
                              allVehicles.push({
                                year: event.target.value,
                                id: "custom",
                              });
                            }
                            setSelectedvehicleValues(allVehicles);
                          }}
                          MenuProps={{
                            style: { maxHeight: "400px" },
                          }}
                          value={
                            selectedYearValues[
                              selectedYearValues?.findIndex(
                                (d) => d?.id == "custom"
                              )
                            ]?.value
                          }
                          // defaultValue={""}
                        >
                          {Array.isArray(year) &&
                            year?.map((y) => {
                              return (
                                <MenuItem value={y} key={y}>
                                  {y}
                                </MenuItem>
                              );
                            })}
                          {yearLoader && (
                            <MenuItem>
                              <CircularProgress />
                            </MenuItem>
                          )}
                        </Select>
                      </Grid>

                      <Grid
                        container
                        md={12}
                        alignItems={"center"}
                        sx={{ height: "57px" }}
                        className="borderBottomColor"
                      >
                        <Select
                          style={{
                            width: "100%",
                            height: "30px",
                            marginInline: "16px",
                          }}
                          value={
                            selectedMakeValues[
                              selectedMakeValues?.findIndex(
                                (d) => d?.id == "custom"
                              )
                            ]?.value
                          }
                          defaultValue={""}
                          onChange={(event) => {
                            let allMakes = [...selectedMakeValues];

                            let findIndex = allMakes.findIndex(
                              (d) => d?.id == "custom"
                            );
                            if (findIndex !== -1) {
                              allMakes[findIndex].value = event.target.value;
                            } else {
                              allMakes.push({
                                value: event.target.value,
                                id: "custom",
                              });
                            }

                            setSelectedMakeValues(allMakes);

                            let allModels = [...selectedModelValues];

                            let findIndexModel = allModels.findIndex(
                              (d) => d?.id == "custom"
                            );
                            if (findIndexModel !== -1) {
                              allModels[findIndexModel].value = "";
                            } else {
                              allModels.push({
                                value: "",
                                id: "custom",
                              });
                            }
                            setSelectedModelValues(allModels);

                            let allTrims = [...selectedTrimValues];

                            let findIndexTrim = allTrims.findIndex(
                              (d) => d?.id == "custom"
                            );
                            if (findIndexTrim !== -1) {
                              allTrims[findIndexTrim].value = "";
                            } else {
                              allTrims.push({
                                value: "",
                                id: "custom",
                              });
                            }
                            setSelectedTrimValues(allTrims);

                            fetchVehicleModel({
                              page: modelValuePage,
                              limit: 1000,
                              vehicleMakeId: JSON.parse(event.target.value).id,
                              selectedId: "custom",
                            });

                            let allVehicles = [...selectedVehicleValues];

                            let findVehicleIndex = allVehicles.findIndex(
                              (d) => d?.id == "custom"
                            );
                            if (findVehicleIndex !== -1) {
                              allVehicles[findVehicleIndex].make =
                                event.target.value;
                            } else {
                              allVehicles.push({
                                make: event.target.value,
                                id: "custom",
                              });
                            }
                            setSelectedvehicleValues(allVehicles);

                            // setModel([]);
                            // setTrim([]);
                            // setTrimValue(null);
                          }}
                          MenuProps={{
                            style: { maxHeight: "400px" },
                          }}
                        >
                          {Array.isArray(make) &&
                            make?.map((m) => {
                              return (
                                <MenuItem
                                  value={JSON.stringify({
                                    id: m?._id,
                                    name: m?.name,
                                  })}
                                  key={m?._id}
                                >
                                  {m?.name}
                                </MenuItem>
                              );
                            })}
                          {makeLoader && (
                            <MenuItem>
                              <CircularProgress />
                            </MenuItem>
                          )}
                        </Select>
                      </Grid>
                      <Grid
                        container
                        md={12}
                        alignItems={"center"}
                        sx={{ height: "57px" }}
                        className="borderBottomColor"
                      >
                        <Select
                          style={{
                            width: "100%",
                            height: "30px",
                            marginInline: "16px",
                          }}
                          value={
                            selectedModelValues[
                              selectedModelValues?.findIndex(
                                (d) => d?.id == "custom"
                              )
                            ]?.value
                          }
                          defaultValue={""}
                          onChange={(event) => {
                            let allModels = [...selectedModelValues];

                            let findIndexModel = allModels.findIndex(
                              (d) => d?.id == "custom"
                            );
                            if (findIndexModel !== -1) {
                              allModels[findIndexModel].value =
                                event.target.value;
                            } else {
                              allModels.push({
                                value: event.target.value,
                                id: "custom",
                              });
                            }
                            setSelectedModelValues(allModels);

                            let allTrims = [...selectedTrimValues];

                            let findIndexTrim = allTrims.findIndex(
                              (d) => d?.id == "custom"
                            );
                            if (findIndexTrim !== -1) {
                              allTrims[findIndexTrim].value = "";
                            } else {
                              allTrims.push({
                                value: "",
                                id: "custom",
                              });
                            }
                            setSelectedTrimValues(allTrims);

                            let allMakes = [...selectedMakeValues];

                            let findIndex = allMakes.findIndex(
                              (d) => d?.id == "custom"
                            );
                            fetchVehicleTrim({
                              page: trimValuePage,
                              limit: 1000,
                              vehicleMakeId: JSON.parse(
                                allMakes[findIndex].value
                              ).id,
                              vehicleModelId: JSON.parse(event.target.value).id,
                              selectedId: "custom",
                            });
                            let allVehicles = [...selectedVehicleValues];

                            let findVehicleIndex = allVehicles.findIndex(
                              (d) => d?.id == "custom"
                            );
                            if (findVehicleIndex !== -1) {
                              allVehicles[findVehicleIndex].model =
                                event.target.value;
                            } else {
                              allVehicles.push({
                                model: event.target.value,
                                id: "custom",
                              });
                            }
                            setSelectedvehicleValues(allVehicles);
                          }}
                          MenuProps={{
                            style: { maxHeight: "400px" },
                          }}
                        >
                          {modelsList?.map(
                            (d) =>
                              d?.id == "custom" &&
                              d?.data?.map((model) => (
                                <MenuItem
                                  value={JSON.stringify({
                                    id: model?._id,
                                    name: model?.name,
                                  })}
                                  key={model?._id}
                                >
                                  {model?.name}
                                </MenuItem>
                              ))
                          )}
                          {modelLoader?.id == "custom" && modelLoader?.loader && (
                            <MenuItem>
                              <CircularProgress />
                            </MenuItem>
                          )}
                        </Select>
                      </Grid>

                      <Grid
                        container
                        md={12}
                        alignItems={"center"}
                        sx={{ height: "57px" }}
                        className="borderBottomColor"
                      >
                        <Select
                          style={{
                            width: "100%",
                            height: "30px",
                            marginInline: "16px",
                          }}
                          value={
                            selectedTrimValues[
                              selectedTrimValues?.findIndex(
                                (d) => d?.id == "custom"
                              )
                            ]?.value
                          }
                          defaultValue={""}
                          onChange={(event) => {
                            let allTrims = [...selectedTrimValues];

                            let findIndexTrim = allTrims.findIndex(
                              (d) => d?.id == "custom"
                            );
                            if (findIndexTrim !== -1) {
                              allTrims[findIndexTrim].value =
                                event.target.value;
                            } else {
                              allTrims.push({
                                value: event.target.value,
                                id: "custom",
                              });
                            }
                            setSelectedTrimValues(allTrims);
                            let allVehicles = [...selectedVehicleValues];

                            let findVehicleIndex = allVehicles.findIndex(
                              (d) => d?.id == "custom"
                            );
                            if (findVehicleIndex !== -1) {
                              allVehicles[findVehicleIndex].trim =
                                event.target.value;
                            } else {
                              allVehicles.push({
                                trim: event.target.value,
                                id: "custom",
                              });
                            }
                            setSelectedvehicleValues(allVehicles);
                          }}
                          MenuProps={{
                            style: { maxHeight: "400px" },
                          }}
                        >
                          {trimList?.map(
                            (d) =>
                              d?.id == "custom" &&
                              d?.data?.map((trim) => (
                                <MenuItem
                                  value={JSON.stringify({
                                    id: trim?._id,
                                    name: trim?.name,
                                  })}
                                  key={trim?._id}
                                >
                                  {trim?.name}
                                </MenuItem>
                              ))
                          )}
                          {trimLoader?.id == "custom" && trimLoader?.loader && (
                            <MenuItem>
                              <CircularProgress />
                            </MenuItem>
                          )}
                        </Select>
                      </Grid>
                      <Grid
                        container
                        // md={12}
                        alignItems={"center"}
                        sx={{ height: "57px" }}
                        className="borderBottomColor"
                      >
                        {customVIN?.findIndex((d) => d.id == "custom") !==
                        -1 ? (
                          <Input
                            sx={{ ml: "16px", width: "100%", mr: "16px" }}
                            onChange={(e) => {
                              let allCustomVINs = [...customVIN];
                              let findIndex = allCustomVINs?.findIndex(
                                (d) => d.id == "custom"
                              );
                              allCustomVINs[findIndex].value = e.target.value;
                              setCustomVIN(allCustomVINs);

                              let allVehicles = [...selectedVehicleValues];

                              let findVehicleIndex = allVehicles.findIndex(
                                (d) => d?.id == "custom"
                              );
                              if (findVehicleIndex !== -1) {
                                allVehicles[findVehicleIndex].vin =
                                  e.target.value;
                              } else {
                                allVehicles.push({
                                  vin: e.target.value,
                                  id: "custom",
                                });
                              }
                              setSelectedvehicleValues(allVehicles);
                            }}
                            value={
                              customVIN[
                                customVIN?.findIndex((d) => d?.id == "custom")
                              ]?.value
                            }
                          />
                        ) : (
                          <Typography
                            className="customTypographySectionLink"
                            onClick={() => {
                              let allCustomVINs = [...customVIN];
                              allCustomVINs.push({
                                id: "custom",
                                value: "",
                              });
                              setCustomVIN(allCustomVINs);
                            }}
                          >
                            ADD OWN FIELD
                          </Typography>
                        )}
                      </Grid>

                      <Grid
                        container
                        md={12}
                        alignItems={"center"}
                        sx={{ height: "57px" }}
                        className="borderBottomColor"
                      >
                        {customLicense?.findIndex((d) => d.id == "custom") !==
                        -1 ? (
                          <Input
                            sx={{ ml: "16px", width: "100%", mr: "16px" }}
                            value={
                              customLicense[
                                customLicense?.findIndex(
                                  (d) => d?.id == "custom"
                                )
                              ]?.value
                            }
                            onChange={(e) => {
                              let allCustomLicense = [...customLicense];
                              let findIndex = allCustomLicense?.findIndex(
                                (d) => d.id == "custom"
                              );
                              allCustomLicense[findIndex].value =
                                e.target.value;
                              setCustomLicense(allCustomLicense);

                              let allVehicles = [...selectedVehicleValues];

                              let findVehicleIndex = allVehicles.findIndex(
                                (d) => d?.id == "custom"
                              );
                              if (findVehicleIndex !== -1) {
                                allVehicles[findVehicleIndex].license =
                                  e.target.value;
                              } else {
                                allVehicles.push({
                                  license: e.target.value,
                                  id: "custom",
                                });
                              }
                              setSelectedvehicleValues(allVehicles);
                            }}
                          />
                        ) : (
                          <Typography
                            className="customTypographySectionLink"
                            onClick={() => {
                              let allCustomLicense = [...customLicense];
                              allCustomLicense.push({
                                id: "custom",
                                value: "",
                              });
                              setCustomLicense(allCustomLicense);
                            }}
                          >
                            ADD OWN FIELD
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  )}
                  {addedMoreVehicles?.map((data) => {
                    return (
                      <Grid
                        container
                        md={6}
                        bgcolor={"rgba(255, 224, 178, 0.6)"}
                        key={data?.key}

                        //className="borderBottomColor"
                        // borderBottom={"1px solid black"}
                      >
                        <Grid
                          item
                          md={12}
                          display={"flex"}
                          className="borderBottomColor"
                        >
                          {" "}
                          <Checkbox
                            className="customTypographySectionRadio"
                            onChange={(e) => {
                              let all = [...checkedVehicles];

                              if (!e.target.checked) {
                                all = all.filter((item) => item !== data?.key);
                              } else {
                                all.push(data?.key);
                              }
                              setCheckedVehicles(all);
                            }}
                            checked={checkedVehicles.includes(data?.key)}
                          />
                          <Typography
                            className="customTypographySection"
                            style={{ height: "56px" }}
                          >
                            <span>
                              {data?.vehicle?.vehicleYear
                                ? data?.vehicle?.vehicleYear
                                : " "}
                            </span>
                            <span>
                              {data?.vehicle?.vehicleMake
                                ? " " + data?.vehicle?.vehicleMake?.name
                                : " "}
                            </span>

                            <span>
                              {data?.vehicle?.vehicleModel
                                ? " " + data?.vehicle?.vehicleModel?.name
                                : " "}
                            </span>
                            {/* <span>
      {data?.vehicle?.trim
        ? " " + data?.vehicle?.trim?.name
        : " "}
    </span> */}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          md={12}
                          alignItems={"center"}
                          sx={{ height: "57px" }}
                          className="borderBottomColor"
                        >
                          <Select
                            style={{
                              width: "100%",
                              height: "30px",
                              marginInline: "16px",
                            }}
                            onChange={(event) => {
                              let all = [...selectedYearValues];

                              let findIndex = all.findIndex(
                                (d) => d?.id == data?.key
                              );
                              if (findIndex !== -1) {
                                all[findIndex].value = event.target.value;
                              } else {
                                all.push({
                                  value: event.target.value,
                                  id: data?.key,
                                });
                              }
                              setSelectedYearValues(all);

                              let allVehicles = [...selectedVehicleValues];

                              let findVehicleIndex = allVehicles.findIndex(
                                (d) => d?.id == data?.key
                              );
                              if (findVehicleIndex !== -1) {
                                allVehicles[findVehicleIndex].year =
                                  event.target.value;
                              } else {
                                allVehicles.push({
                                  year: event.target.value,
                                  id: data?.key,
                                });
                              }
                              setSelectedvehicleValues(allVehicles);
                            }}
                            MenuProps={{
                              style: { maxHeight: "400px" },
                            }}
                            value={
                              selectedYearValues[
                                selectedYearValues?.findIndex(
                                  (d) => d?.id == data?.key
                                )
                              ]?.value
                            }
                            // defaultValue={""}
                          >
                            {Array.isArray(year) &&
                              year?.map((y) => {
                                return (
                                  <MenuItem value={y} key={y}>
                                    {y}
                                  </MenuItem>
                                );
                              })}
                            {yearLoader && (
                              <MenuItem>
                                <CircularProgress />
                              </MenuItem>
                            )}
                          </Select>
                        </Grid>

                        <Grid
                          container
                          md={12}
                          alignItems={"center"}
                          sx={{ height: "57px" }}
                          className="borderBottomColor"
                        >
                          <Select
                            style={{
                              width: "100%",
                              height: "30px",
                              marginInline: "16px",
                            }}
                            value={
                              selectedMakeValues[
                                selectedMakeValues?.findIndex(
                                  (d) => d?.id == data?.key
                                )
                              ]?.value
                            }
                            defaultValue={""}
                            onChange={(event) => {
                              let allMakes = [...selectedMakeValues];

                              let findIndex = allMakes.findIndex(
                                (d) => d?.id == data?.key
                              );
                              if (findIndex !== -1) {
                                allMakes[findIndex].value = event.target.value;
                              } else {
                                allMakes.push({
                                  value: event.target.value,
                                  id: data?.key,
                                });
                              }

                              setSelectedMakeValues(allMakes);

                              let allModels = [...selectedModelValues];

                              let findIndexModel = allModels.findIndex(
                                (d) => d?.id == data?.key
                              );
                              if (findIndexModel !== -1) {
                                allModels[findIndexModel].value = "";
                              } else {
                                allModels.push({
                                  value: "",
                                  id: data?.key,
                                });
                              }
                              setSelectedModelValues(allModels);

                              let allTrims = [...selectedTrimValues];

                              let findIndexTrim = allTrims.findIndex(
                                (d) => d?.id == data?.key
                              );
                              if (findIndexTrim !== -1) {
                                allTrims[findIndexTrim].value = "";
                              } else {
                                allTrims.push({
                                  value: "",
                                  id: data?.key,
                                });
                              }
                              setSelectedTrimValues(allTrims);

                              fetchVehicleModel({
                                page: modelValuePage,
                                limit: 1000,
                                vehicleMakeId: JSON.parse(event.target.value)
                                  .id,
                                selectedId: data?.key,
                              });

                              let allVehicles = [...selectedVehicleValues];

                              let findVehicleIndex = allVehicles.findIndex(
                                (d) => d?.id == data?.key
                              );
                              if (findVehicleIndex !== -1) {
                                allVehicles[findVehicleIndex].make =
                                  event.target.value;
                              } else {
                                allVehicles.push({
                                  make: event.target.value,
                                  id: data?.key,
                                });
                              }
                              setSelectedvehicleValues(allVehicles);

                              // setModel([]);
                              // setTrim([]);
                              // setTrimValue(null);
                            }}
                            MenuProps={{
                              style: { maxHeight: "400px" },
                            }}
                          >
                            {Array.isArray(make) &&
                              make?.map((m) => {
                                return (
                                  <MenuItem
                                    value={JSON.stringify({
                                      id: m?._id,
                                      name: m?.name,
                                    })}
                                    key={m?._id}
                                  >
                                    {m?.name}
                                  </MenuItem>
                                );
                              })}
                            {makeLoader && (
                              <MenuItem>
                                <CircularProgress />
                              </MenuItem>
                            )}
                          </Select>
                        </Grid>
                        <Grid
                          container
                          md={12}
                          alignItems={"center"}
                          sx={{ height: "57px" }}
                          className="borderBottomColor"
                        >
                          <Select
                            style={{
                              width: "100%",
                              height: "30px",
                              marginInline: "16px",
                            }}
                            value={
                              selectedModelValues[
                                selectedModelValues?.findIndex(
                                  (d) => d?.id == data?.key
                                )
                              ]?.value
                            }
                            defaultValue={""}
                            onChange={(event) => {
                              let allModels = [...selectedModelValues];

                              let findIndexModel = allModels.findIndex(
                                (d) => d?.id == data?.key
                              );
                              if (findIndexModel !== -1) {
                                allModels[findIndexModel].value =
                                  event.target.value;
                              } else {
                                allModels.push({
                                  value: event.target.value,
                                  id: data?.key,
                                });
                              }
                              setSelectedModelValues(allModels);

                              let allTrims = [...selectedTrimValues];

                              let findIndexTrim = allTrims.findIndex(
                                (d) => d?.id == data?.key
                              );
                              if (findIndexTrim !== -1) {
                                allTrims[findIndexTrim].value = "";
                              } else {
                                allTrims.push({
                                  value: "",
                                  id: data?.key,
                                });
                              }
                              setSelectedTrimValues(allTrims);

                              let allMakes = [...selectedMakeValues];

                              let findIndex = allMakes.findIndex(
                                (d) => d?.id == data?.key
                              );
                              fetchVehicleTrim({
                                page: trimValuePage,
                                limit: 1000,
                                vehicleMakeId: JSON.parse(
                                  allMakes[findIndex].value
                                ).id,
                                vehicleModelId: JSON.parse(event.target.value)
                                  .id,
                                selectedId: data?.key,
                              });
                              let allVehicles = [...selectedVehicleValues];

                              let findVehicleIndex = allVehicles.findIndex(
                                (d) => d?.id == data?.key
                              );
                              if (findVehicleIndex !== -1) {
                                allVehicles[findVehicleIndex].model =
                                  event.target.value;
                              } else {
                                allVehicles.push({
                                  model: event.target.value,
                                  id: data?.key,
                                });
                              }
                              setSelectedvehicleValues(allVehicles);
                            }}
                            MenuProps={{
                              style: { maxHeight: "400px" },
                            }}
                          >
                            {modelsList?.map(
                              (d) =>
                                d?.id == data?.key &&
                                d?.data?.map((model) => (
                                  <MenuItem
                                    value={JSON.stringify({
                                      id: model?._id,
                                      name: model?.name,
                                    })}
                                    key={model?._id}
                                  >
                                    {model?.name}
                                  </MenuItem>
                                ))
                            )}
                            {modelLoader?.id == data?.key &&
                              modelLoader?.loader && (
                                <MenuItem>
                                  <CircularProgress />
                                </MenuItem>
                              )}
                          </Select>
                        </Grid>

                        <Grid
                          container
                          md={12}
                          alignItems={"center"}
                          sx={{ height: "57px" }}
                          className="borderBottomColor"
                        >
                          <Select
                            style={{
                              width: "100%",
                              height: "30px",
                              marginInline: "16px",
                            }}
                            value={
                              selectedTrimValues[
                                selectedTrimValues?.findIndex(
                                  (d) => d?.id == data?.key
                                )
                              ]?.value
                            }
                            defaultValue={""}
                            onChange={(event) => {
                              let allTrims = [...selectedTrimValues];

                              let findIndexTrim = allTrims.findIndex(
                                (d) => d?.id == data?.key
                              );
                              if (findIndexTrim !== -1) {
                                allTrims[findIndexTrim].value =
                                  event.target.value;
                              } else {
                                allTrims.push({
                                  value: event.target.value,
                                  id: data?.key,
                                });
                              }
                              setSelectedTrimValues(allTrims);
                              let allVehicles = [...selectedVehicleValues];

                              let findVehicleIndex = allVehicles.findIndex(
                                (d) => d?.id == data?.key
                              );
                              if (findVehicleIndex !== -1) {
                                allVehicles[findVehicleIndex].trim =
                                  event.target.value;
                              } else {
                                allVehicles.push({
                                  trim: event.target.value,
                                  id: data?.key,
                                });
                              }
                              setSelectedvehicleValues(allVehicles);
                            }}
                            MenuProps={{
                              style: { maxHeight: "400px" },
                            }}
                          >
                            {trimList?.map(
                              (d) =>
                                d?.id == data?.key &&
                                d?.data?.map((trim) => (
                                  <MenuItem
                                    value={JSON.stringify({
                                      id: trim?._id,
                                      name: trim?.name,
                                    })}
                                    key={trim?._id}
                                  >
                                    {trim?.name}
                                  </MenuItem>
                                ))
                            )}
                            {trimLoader?.id == data?.key && trimLoader?.loader && (
                              <MenuItem>
                                <CircularProgress />
                              </MenuItem>
                            )}
                          </Select>
                        </Grid>
                        <Grid
                          container
                          // md={12}
                          alignItems={"center"}
                          sx={{ height: "57px" }}
                          className="borderBottomColor"
                        >
                          {customVIN?.findIndex((d) => d.id == data?.key) !==
                          -1 ? (
                            <Input
                              sx={{ ml: "16px", width: "100%", mr: "16px" }}
                              onChange={(e) => {
                                let allCustomVINs = [...customVIN];
                                let findIndex = allCustomVINs?.findIndex(
                                  (d) => d.id == data?.key
                                );
                                allCustomVINs[findIndex].value = e.target.value;
                                setCustomVIN(allCustomVINs);

                                let allVehicles = [...selectedVehicleValues];

                                let findVehicleIndex = allVehicles.findIndex(
                                  (d) => d?.id == data?.key
                                );
                                if (findVehicleIndex !== -1) {
                                  allVehicles[findVehicleIndex].vin =
                                    e.target.value;
                                } else {
                                  allVehicles.push({
                                    vin: e.target.value,
                                    id: data?.key,
                                  });
                                }
                                setSelectedvehicleValues(allVehicles);
                              }}
                              value={
                                customVIN[
                                  customVIN?.findIndex(
                                    (d) => d?.id == data?.key
                                  )
                                ]?.value
                              }
                            />
                          ) : (
                            <Typography
                              className="customTypographySectionLink"
                              onClick={() => {
                                let allCustomVINs = [...customVIN];
                                allCustomVINs.push({
                                  id: data?.key,
                                  value: "",
                                });
                                setCustomVIN(allCustomVINs);
                              }}
                            >
                              ADD OWN FIELD
                            </Typography>
                          )}
                        </Grid>

                        <Grid
                          container
                          md={12}
                          alignItems={"center"}
                          sx={{ height: "57px" }}
                          className="borderBottomColor"
                        >
                          {customLicense?.findIndex(
                            (d) => d.id == data?.key
                          ) !== -1 ? (
                            <Input
                              sx={{ ml: "16px", width: "100%", mr: "16px" }}
                              value={
                                customLicense[
                                  customLicense?.findIndex(
                                    (d) => d?.id == data?.key
                                  )
                                ]?.value
                              }
                              onChange={(e) => {
                                let allCustomLicense = [...customLicense];
                                let findIndex = allCustomLicense?.findIndex(
                                  (d) => d.id == data?.key
                                );
                                allCustomLicense[findIndex].value =
                                  e.target.value;
                                setCustomLicense(allCustomLicense);

                                let allVehicles = [...selectedVehicleValues];

                                let findVehicleIndex = allVehicles.findIndex(
                                  (d) => d?.id == data?.key
                                );
                                if (findVehicleIndex !== -1) {
                                  allVehicles[findVehicleIndex].license =
                                    e.target.value;
                                } else {
                                  allVehicles.push({
                                    license: e.target.value,
                                    id: data?.key,
                                  });
                                }
                                setSelectedvehicleValues(allVehicles);
                              }}
                            />
                          ) : (
                            <Typography
                              className="customTypographySectionLink"
                              onClick={() => {
                                let allCustomLicense = [...customLicense];
                                allCustomLicense.push({
                                  id: data?.key,
                                  value: "",
                                });
                                setCustomLicense(allCustomLicense);
                              }}
                            >
                              ADD OWN FIELD
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Box>
            <Box
              boxShadow={2}
              padding={"16px"}
              mt={4}
              borderRadius={3}
              bgcolor="#EEE"
            >
              <Typography fontWeight={500} fontSize={20}>
                {selectedFirstName?.value} {selectedLastName?.value}
              </Typography>
              <Typography fontWeight={400} fontSize={16} pt={"8px"}>
                {selectedPhone?.value}
              </Typography>
              <Typography fontWeight={400} fontSize={16} pt={"8px"}>
                {selectedEmail?.value}
              </Typography>
              <Typography fontWeight={500} fontSize={20} pt={"24px"}>
                {selectedVehicleValues?.map((vehicle) => (
                  <p>
                    {checkedVehicles.includes(vehicle.id) && (
                      <>
                        <span>{vehicle?.year ? vehicle?.year : " "}</span>
                        <span>
                          {vehicle?.make
                            ? " " + JSON.parse(vehicle?.make)?.name
                            : " "}
                        </span>

                        <span>
                          {vehicle?.model
                            ? " " + JSON.parse(vehicle?.model)?.name
                            : " "}
                        </span>
                        <span>
                          {vehicle?.trim
                            ? " " + JSON.parse(vehicle?.trim)?.name
                            : " "}
                        </span>
                      </>
                    )}
                  </p>
                ))}
              </Typography>
            </Box>

            <Stack className="stackButtons">
              <Button
                variant="contained"
                onClick={() => clickedFirstContinueButton()}
                size={"large"}
              >
                Continue
              </Button>
              <Button
                className="cancelButton"
                size={"large"}
                onClick={() => cancelButtonClicked()}
              >
                Cancel
              </Button>
            </Stack>
          </>
        )}
        {firstContinueClicked && (
          <>
            <Box
              boxShadow={2}
              padding={"16px"}
              mt={4}
              borderRadius={3}
              bgcolor="white"
            >
              <Typography fontWeight={500} fontSize={20}>
                {selectedFirstName?.value} {selectedLastName?.value}
              </Typography>
              <Typography fontWeight={400} fontSize={16} pt={"8px"}>
                {selectedPhone?.value}
              </Typography>
              <Typography fontWeight={400} fontSize={16} pt={"8px"}>
                {selectedEmail?.value}
              </Typography>
              <Typography fontWeight={500} fontSize={20} pt={"24px"}>
                {selectedVehicleValues?.map((vehicle) => (
                  <p>
                    {checkedVehicles.includes(vehicle.id) && (
                      <>
                        <span>{vehicle?.year ? vehicle?.year : " "}</span>
                        <span>
                          {vehicle?.make
                            ? " " + JSON.parse(vehicle?.make)?.name
                            : " "}
                        </span>

                        <span>
                          {vehicle?.model
                            ? " " + JSON.parse(vehicle?.model)?.name
                            : " "}
                        </span>
                        <span>
                          {vehicle?.trim
                            ? " " + JSON.parse(vehicle?.trim)?.name
                            : " "}
                        </span>
                      </>
                    )}
                  </p>
                ))}
              </Typography>
            </Box>

            <Stack className="stackButtons">
              <Button
                variant="contained"
                onClick={() => secondContinueButtonClicked()}
                size={"large"}
              >
                Continue
              </Button>
              <Button
                className="cancelButton"
                onClick={() => clickedBackButton()}
                size={"large"}
              >
                Back
              </Button>
            </Stack>
          </>
        )}
      </Box>
      <Dialog
        open={openRemoveMerge}
        onClose={handleCloseRemoveMerge}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ fontWeight: "400", fontSize: "24px" }}
        >
          Remove from Merge?
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseRemoveMerge}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon className="closeButton" />
        </IconButton>
        <DialogContent style={{ paddingTop: "0px" }}>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove customer from the merge. The
            customer will remain in the customer list, but will no longer be
            taken into consideration with other duplicate options.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ mb: "10px" }}>
          <Button onClick={handleCloseRemoveMerge} variant="outlined">
            CANCEL
          </Button>
          <Button
            onClick={handleRemoveMerge}
            autoFocus
            color="error"
            variant="contained"
          >
            DELETE
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessMessageModal
        message={alertMessage}
        alertType={messageType}
        open={showMessage}
        setOpenSuccessModal={setShowMessage}
      />
      <Loader showLoader={showLoader} />
    </>
  );
};

export default NewMergeCustomers;
