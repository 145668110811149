import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Divider, Grid, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
function SupplierProfileEditLeftSection({ setQuickEdit }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open1 = Boolean(anchorEl);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data); // Process form data
  };
  return (
    <>
      <Grid item width={"100%"}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid item container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12}>
              <TextField
                id="standard-basic"
                label="Supplier Name"
                variant="standard"
                {...register("supplierName", {
                  required: "Supplier name is required",
                  minLength: {
                    value: 3,
                    message: "Supplier name must be at least 3 characters long",
                  },
                })}
                error={!!errors.supplierName}
                helperText={
                  errors.supplierName ? errors.supplierName.message : ""
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="standard-basic"
                label="First Name"
                variant="standard"
                {...register("firstName", {
                  required: "First name is required",
                  minLength: {
                    value: 3,
                    message: "First name must be at least 3 characters long",
                  },
                })}
                error={!!errors.firstName}
                helperText={errors.firstName ? errors.firstName.message : ""}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="standard-basic"
                label="Last Name"
                variant="standard"
                {...register("lastName", {
                  required: "Last name is required",
                  minLength: {
                    value: 3,
                    message: "Last name must be at least 3 characters long",
                  },
                })}
                error={!!errors.lastName}
                helperText={errors.lastName ? errors.lastName.message : ""}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-basic"
                label="Account #"
                variant="standard"
                {...register("accountNumber", {
                  required: "Account number is required",
                })}
                error={!!errors.accountNumber}
                helperText={
                  errors.accountNumber ? errors.accountNumber.message : ""
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-basic"
                label="Home"
                variant="standard"
                {...register("home", {
                  required: "Home is required",
                })}
                error={!!errors.home}
                helperText={errors.home ? errors.home.message : ""}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-basic"
                label="Landline"
                variant="standard"
                {...register("landline", {
                  required: "Landline is required",
                })}
                error={!!errors.landline}
                helperText={errors.landline ? errors.home.message : ""}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-basic"
                label="Work"
                variant="standard"
                {...register("work", {
                  required: "Work is required",
                })}
                error={!!errors.work}
                helperText={errors.work ? errors.work.message : ""}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-basic"
                label="Mobile"
                variant="standard"
                {...register("mobile", {
                  required: "Mobile is required",
                })}
                error={!!errors.mobile}
                helperText={errors.mobile ? errors.mobile.message : ""}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-basic"
                label="Email Address"
                variant="standard"
                {...register("emailAddress", {
                  required: "Email address is required",
                })}
                error={!!errors.emailAddress}
                helperText={
                  errors.emailAddress ? errors.emailAddress.message : ""
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-basic"
                label="Website"
                variant="standard"
                {...register("website", {
                  required: "Website is required",
                })}
                error={!!errors.website}
                helperText={errors.website ? errors.website.message : ""}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-basic"
                label="Street Address"
                variant="standard"
                {...register("streetAddress", {
                  required: "Street Address is required",
                })}
                error={!!errors.streetAddress}
                helperText={errors.streetAddress ? errors.home.message : ""}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                select
                id="standard-basic"
                label="City"
                variant="standard"
                {...register("city", {
                  required: "City is required",
                })}
                error={!!errors.city}
                helperText={errors.city ? errors.home.message : ""}
                fullWidth
              >
                {/* {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))} */}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                select
                id="standard-basic"
                label="Province"
                variant="standard"
                {...register("province", {
                  required: "Province is required",
                })}
                error={!!errors.province}
                helperText={errors.province ? errors.home.message : ""}
                fullWidth
              >
                {/* {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))} */}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="standard-basic"
                label="Postal Code"
                variant="standard"
                {...register("postalCode", {
                  required: "Postal code is required",
                })}
                error={!!errors.postalCode}
                helperText={errors.postalCode ? errors.home.message : ""}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                id="standard-basic"
                label="Contact Preferences:"
                variant="standard"
                {...register("contactPreferences", {
                  required: "Contact Preferences is required",
                })}
                error={!!errors.contactPreferences}
                helperText={
                  errors.contactPreferences ? errors.home.message : ""
                }
                fullWidth
              >
                {/* {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))} */}
              </TextField>
            </Grid>
          </Grid>

          <Grid
            item
            container
            justifyContent={"end"}
            // paddingLeft={"8px"}
            paddingTop={"24px"}
          >
            <Stack spacing={1} direction={"row"}>
              <Button
                variant="outlined"
                //   onClick={() => {
                //     navigate(`/messenger/${id}`);
                //     // navigate(`/messenger`);
                //   }}
                onClick={() => {
                  setQuickEdit(false);
                }}
              >
                DISCARD
              </Button>

              <Button
                variant="contained"
                id="demo-positioned-button"
                type="submit"
                // aria-controls={open ? "demo-positioned-menu" : undefined}
              >
                SAVE{" "}
              </Button>
            </Stack>
          </Grid>
        </form>
      </Grid>
      <Divider style={{ width: "100%", marginTop: "23px" }} />
      <Grid
        item
        container
        justifyContent={"start"}
        paddingLeft={"8px"}
        paddingTop={"24px"}
        style={{ opacity: 0.5, pointerEvents: "none" }}
      >
        <Stack spacing={1} direction={"row"}>
          <Button variant="contained">SEND EMAIL</Button>
          <Button variant="outlined" endIcon={<ExpandMoreIcon />}>
            ACTIONS
          </Button>
        </Stack>
      </Grid>
    </>
  );
}

export default SupplierProfileEditLeftSection;
