import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { customerImportList } from "../../redux/Customer/action";
import "./Customers.css";

const ShopImportedCustomers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [orderBy, setOrderBy] = useState("firstName");
  const [order, setOrder] = useState("asc");
  const [checkedMergeCustomer, setCheckedMergeCustomers] = useState([]);
  const [searchBy, setSearchBy] = useState("");
  let params = {
    page: page + 1,
    limit: rowsPerPage,
    search: searchValue,
    sortBy: orderBy,
    sortType: order == "asc" ? "asc" : "dsc",
    searchBy,
  };
  const [showLoader, setShowLoader] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedSearchType, setSelectedSearchType] = useState("");
  const open1 = Boolean(anchorEl);
  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    console.log("ssd");
    setAnchorEl(null);
  };
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    // fetchCustomerImportList(params);
  }, []);

  const fetchCustomerImportList = async (params) => {
    setShowLoader(true);
    await dispatch(customerImportList(params));
    setShowLoader(false);
  };

  const customers = useSelector((state) => state.customer.customerImportList);
  console.log("imported customers", customers);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    params.page = newPage + 1;
    fetchCustomerImportList(params);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    params.limit = parseInt(event.target.value, 10);
    params.page = 1;
    fetchCustomerImportList(params);
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      params.page = 1;
      fetchCustomerImportList(params);
    }, 900); // Set the debounce time (milliseconds)

    // Cleanup function to cancel the previous setTimeout

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "dsc" : "asc");
    setOrderBy(property);
    params.sortBy = property;
    params.sortType = isAsc ? "dsc" : "asc";
    fetchCustomerImportList(params);
  };
  const handleSelectSearchType = (type) => {
    setSearchBy(type);
    if (type == "firstName") {
      setSelectedSearchType("First Name");
    } else if (type == "lastName") {
      setSelectedSearchType("Last Name");
    } else if (type == "phoneNumber") {
      setSelectedSearchType("Phone Number");
    } else if (type == "email") {
      setSelectedSearchType("Email");
    } else if (type == "vehicleYear") {
      setSelectedSearchType("Vehicle Year");
    } else if (type == "vehicleMake") {
      setSelectedSearchType("Vehicle Make");
    } else if (type == "vehicleModel") {
      setSelectedSearchType("Vehicle Model");
    } else if (type == "vehicleModelTrim") {
      setSelectedSearchType("Vehicle Model Trim");
    } else {
      setSearchBy("");

      setSelectedSearchType("");
    }

    setSearchValue("");
    handleClose1();
  };

  const DisplayVehicles = ({ vehicles }) => {
    const vehiclesList = vehicles?.map((veh) => {
      let content = "";

      content += veh?.vehicleYear || "";
      content += " " + (veh?.vehicleMake?.name || "");
      content += " " + (veh?.vehicleModel?.name || "");
      content += " " + (veh?.vehicleModelTrim?.name || "");

      content = content.trim();

      if (!content) {
        content = veh?.vehicleDescription ? veh?.vehicleDescription : "";
      }

      return (
        <span key={veh?.id}>
          {content}
          <br />
        </span>
      );
    });

    return (
      <div style={{ height: "40px", overflow: "auto" }}>{vehiclesList}</div>
    );
  };
  const TableRowsLoader = ({ rowsNum }) => {
    return [...Array(rowsNum)].map((row, index) => (
      <TableRow key={index}>
        <TableCell component="th" scope="row">
          <Skeleton animation="wave" variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" />
        </TableCell>
      </TableRow>
    ));
  };
  return (
    <>
      <Box>
        <Grid
          container
          columns={13}
          item
          gap={1}
          alignItems={"center"}
          // justifyContent={"space-between"}
          marginBottom={"32px"}
        >
          <Grid item xs={6} sm={3} md={2} lg={2} xl={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Search by</InputLabel>
              <Select
                fullWidth
                label="Search by"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={searchBy}
                endAdornment={
                  selectedSearchType && (
                    <InputAdornment
                      position="end"
                      sx={{ paddingRight: "20px" }}
                    >
                      <IconButton
                        edge="end"
                        onClick={() => {
                          setSelectedSearchType("");
                          setSearchValue("");
                          setSearchBy(null);
                          params.page = 1;
                          params.searchBy = "";
                        }}
                      >
                        <ClearIcon sx={{ height: "18px", width: "18px" }} />
                      </IconButton>
                    </InputAdornment>
                  )
                }
                onChange={(e) => handleSelectSearchType(e.target.value)}
              >
                <MenuItem value="firstName" key="firstName">
                  First Name
                </MenuItem>
                <MenuItem value="lastName" key="lastName">
                  Last Name
                </MenuItem>
                <MenuItem value="phoneNumber" key="phoneNumber">
                  Phone{" "}
                </MenuItem>
                <MenuItem value="email" key="email">
                  Email
                </MenuItem>
                <MenuItem value="vehicleYear" key="vehicleYear">
                  Vehicle Year
                </MenuItem>
                <MenuItem value="vehicleMake" key="vehicleMake">
                  Vehicle Make
                </MenuItem>
                <MenuItem value="vehicleModel" key="vehicleModel">
                  Vehicle Model
                </MenuItem>
                <MenuItem value="vehicleModelTrim" key="vehicleModelTrim">
                  Vehicle Trim
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xl={9.8} lg={9.8} md={9.8} sm={11}>
            <FormControl fullWidth>
              <InputLabel
                htmlFor="outlined-adornment-search"
                style={{ backgroundColor: "transparent", color: "black" }}
              >
                Search
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-search"
                sx={{ backgroundColor: "transparent", color: "black" }}
                placeholder={
                  searchBy
                    ? selectedSearchType
                    : " Customer name, phone number, email, etc..."
                }
                onChange={handleSearchChange}
                value={searchValue}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                label="Search"
                disabled={searchBy ? false : true}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xl={1}
            lg={1}
            // sm={2.5}
            // xs={2.5}
            md={1}
            justifyContent={"end"}
            display={"flex"}
          >
            <Button
              variant="contained"
              color="secondary"
              disabled={checkedMergeCustomer?.length > 0 ? false : true}
              onClick={() => {
                navigate(`/customers/merge/new/`);
                localStorage.setItem(
                  "mergeCustomeDetails",
                  JSON.stringify({ id: checkedMergeCustomer })
                );
              }}
            >
              MERGE
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          item
          padding={"24px"}
          boxShadow={2}
          borderRadius={"10px"}
          sx={{ bgcolor: "white" }}
        >
          <Grid item xl={12} md={12} sm={12} xs={12}>
            <Table aria-label="caption table" padding="16px">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === "firstName"}
                      direction={orderBy === "firstName" ? order : "asc"}
                      onClick={() => handleSortRequest("firstName")}
                    >
                      Customer Name
                    </TableSortLabel>{" "}
                  </TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Email</TableCell>

                  <TableCell>Vehicle Imported</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {showLoader ? (
                  <TableRowsLoader rowsNum={rowsPerPage} />
                ) : (
                  Array.isArray(customers?.data) &&
                  customers?.data.map((data) => (
                    <TableRow
                      key={data._id}
                      //   onClick={() => navigate(`${data?._id}`)}
                      hover={true}
                      style={{ cursor: "pointer" }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Checkbox
                          onChange={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            let allValues = [...checkedMergeCustomer];
                            if (e.target.checked) {
                              allValues.push(data._id);
                            } else {
                              allValues = allValues.filter(
                                (d) => d !== data?._id
                              );
                            }

                            setCheckedMergeCustomers(allValues);
                          }}
                          checked={checkedMergeCustomer.includes(data?._id)}
                        />{" "}
                        <div
                          onClick={() => navigate(`${data?._id}/shop_imported`)}
                          style={{ width: "100%" }}
                        >
                          {data.firstName} {data?.lastName}
                        </div>
                      </TableCell>
                      <TableCell
                        align="left"
                        onClick={() => navigate(`${data?._id}/shop_imported`)}
                      >
                        {" "}
                        {data.phoneNumber}{" "}
                      </TableCell>
                      <TableCell
                        align="left"
                        onClick={() => navigate(`${data?._id}/shop_imported`)}
                      >
                        {data?.email}
                      </TableCell>

                      <TableCell
                        align="left"
                        onClick={() => navigate(`${data?._id}/shop_imported`)}
                      >
                        <DisplayVehicles
                          vehicles={data?.vehicle}
                          key={data?._id}
                        />
                        {/* <div style={{ height: "40px", overflow: "auto" }}>
                          {data?.vehicle?.map((veh) => (
                            <span>
                              {veh?.vehicleYear ? veh?.vehicleYear : ""}
                              {veh?.vehicleMake?.name
                                ? " " + veh?.vehicleMake?.name
                                : ""}
                              {veh?.vehicleModel?.name
                                ? " " + veh?.vehicleModel?.name
                                : ""}
                              {veh?.vehicleModelTrim?.name
                                ? " " + veh?.vehicleModelTrim?.name
                                : ""}
                              <br />
                            </span>
                          ))}
                        </div> */}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
            <Grid container item alignItems={"center"} justifyContent="end">
              <Grid item>
                {customers?.pagination?.totalCount > 0 && (
                  <TablePagination
                    component="div"
                    count={customers?.pagination?.totalCount}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {/* <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog> */}
    </>
  );
};

export default ShopImportedCustomers;
