import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Modal,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import React from "react";

import { useTheme } from "@emotion/react";
import moment from "moment";
import { Controller, useForm } from "react-hook-form"; // Step 1
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { cancleAppointment } from "../../redux/appointment/action";
import SuccessMessageModal from "../Common/SuccessMessage";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const textAreaStyle = {
  width: "670px",
  height: "100px",
  fontSize: "16px",
  border: "1px solid #ccc",
  borderRadius: "4px",
  padding: "10px",
  fontFamily: "Roboto",
  fontWeight: 400,
};

const CancelAppointment = ({ open, handleClose, id, data, handleClose2 }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = React.useState(false);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const onSubmit = async (values) => {
    setShowLoader(true);
    let payload = {};
    if (values?.messages) {
      payload.message = values?.messages;
    }
    const data = await dispatch(cancleAppointment(payload, id));
    if (data) {
      setShowLoader(false);
      setOpenSuccessModal(true);
      setSuccessMessage("Appointment cancelled successfully.");
      setTimeout(() => {
        handleClose();
        handleClose2();
      }, 1000);
      if (
        window.location.pathname.split("/").includes("workOrderAuthorization")
      ) {
        navigate("/appointment/upcoming-appointments");
      }
    }
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={9} md={9} xl={9}>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              {" "}
              {/* Step 3 */}
              <Box
                sx={{
                  ...style,
                  height: "fit-content",
                  width: "770px",
                  border: "none",
                }}
              >
                <AppBar
                  variant="elevation"
                  sx={{
                    height: "64px",
                    paddingRight: "0px !important",
                    boxShadow: "none",
                  }}
                >
                  <Toolbar variant="regular">
                    <Typography variant="h6" fontWeight={500} width={"98%"}>
                      Cancel Appointment
                    </Typography>
                    <Stack direction={"row"} spacing={0}>
                      <div
                        onClick={handleClose}
                        style={{ cursor: "pointer", height: "32px" }}
                      >
                        <CloseIcon style={{ fontSize: 32 }} />
                      </div>
                    </Stack>
                  </Toolbar>
                </AppBar>
                <Container gap={2} disableGutters style={{ marginTop: "66px" }}>
                  <Grid container padding={"10px 0px"}>
                    {/* <Grid item> */}
                    {/* <Stack spacing={2}> */}
                    <Grid container item>
                      <Grid
                        item
                        md={2}
                        fontSize={"16px"}
                        color={"#00000099"}
                        marginBottom={"10px"}
                      >
                        Customer
                      </Grid>
                      {/* {data?.customer?.firstName +
                        " " +
                        data?.customer?.lastName} */}
                      <Link
                        to={`/customers/${data?.customer?._id}`}
                        style={{ color: "#2196F3", textDecoration: "none" }}
                      >
                        {data?.customer?.firstName +
                          " " +
                          data?.customer?.lastName}
                      </Link>
                    </Grid>

                    <Grid container item>
                      <Grid
                        item
                        md={2}
                        fontSize={"16px"}
                        color={"#00000099"}
                        marginBottom={"10px"}
                      >
                        Vehicle{" "}
                      </Grid>
                      {data?.vehicle?.year ? data?.vehicle.year : " "}{" "}
                      {data?.vehicle?.vehicleMake?.name &&
                        data?.vehicle?.vehicleMake?.name}{" "}
                      {data?.vehicle?.vehicleModel?.name &&
                        data?.vehicle?.vehicleModel?.name}{" "}
                      {data?.vehicle?.vehicleModelTrim?.name &&
                        data?.vehicle?.vehicleModelTrim?.name}
                    </Grid>

                    <Grid container item>
                      <Grid
                        item
                        md={2}
                        fontSize={"16px"}
                        color={"#00000099"}
                        marginBottom={"10px"}
                      >
                        Drop Off{" "}
                      </Grid>
                      {moment(data?.dropOffDateTime).format(
                        "MMM D, YYYY hh:mm A"
                      )}
                    </Grid>
                    <Stack style={{ marginTop: "15px" }}>
                      <Typography
                        variant="body1"
                        fontWeight={500}
                        fontSize={"16px"}
                      >
                        Services
                        <Typography>
                          {data?.services?.map((item, index) => {
                            return (
                              <Stack key={index}>
                                <Typography
                                  variant="body1"
                                  fontWeight={400}
                                  fontSize={"16px"}
                                  marginLeft={"10px"}
                                  marginTop={"5px"}
                                >
                                  {index + 1}. {item?.serviceName}
                                </Typography>
                              </Stack>
                            );
                          })}
                        </Typography>
                      </Typography>
                    </Stack>

                    {/* </Stack> */}
                    {/* </Grid> */}
                    <Grid item paddingTop={"10px"}>
                      <Controller
                        name="messages"
                        control={control}
                        render={({ field }) => (
                          <>
                            <textarea
                              fullWidth
                              minRows={10}
                              style={textAreaStyle}
                              variant="outlined"
                              {...field}
                              placeholder="Type Optional Message"
                            />
                          </>
                        )}
                      />
                    </Grid>
                    {/* <Grid container item>
                      <Grid item>
                        <Button variant="contained">Cancel</Button>
                      </Grid>
                      <Grid item>
                        <Button variant="contained" type="submit" color="error">
                          CANCEL APPOINTMENT
                        </Button>
                      </Grid>
                    </Grid> */}
                    <Grid
                      item
                      container
                      justifyContent={"end"}
                      spacing={2}
                      style={{ marginTop: "0px" }}
                    >
                      <Grid item>
                        <Stack spacing={2} direction="row">
                          <Button
                            variant="contained"
                            onClick={() => {
                              handleClose();
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            type="submit"
                            color="error"
                          >
                            CANCEL APPOINTMENT
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            </form>
          </Modal>
        </Grid>
      </Grid>
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default CancelAppointment;
