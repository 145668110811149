import CloseIcon from "@mui/icons-material/Close";
import Search from "@mui/icons-material/Search";
import {
  Alert,
  AppBar,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Modal,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  Tab,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { default as React, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Scrollbar } from "react-scrollbars-custom";
import Select, { components } from "react-select";
import { FormatNumberForKmFloat } from "../../components/FormatNumber/FormatNumber";
import { customStyles, errorCustomStyles } from "../../utility/SelectDropdown";
// import "../Customers.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "2px solid ",
  boxShadow: 24,
  // p: 3,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// Custom Option component
const CustomOption = (props) => {
  const { data } = props;

  return (
    <components.Option {...props}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {/* Display option value */}
        <span>{data.value}</span>
        {/* Conditionally display "Save" for specific options */}
        {data.showSave && (
          <div
            style={{
              marginLeft: "8px",
              color: "#006CDD",
              fontWeight: 500,
              fontSize: "13px",
              textDecoration: "underline",
              background: "#2196F326",
              padding: "4px",
            }}
            // onClick={() => alert("Are you want to add")}
          >
            SAVE
          </div>
        )}
      </div>
    </components.Option>
  );
};
function SupplierPartModel({
  openPartModel,
  handleClosePartModel,
  onSubmitPartModel,
}) {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    setError,
    clearErrors,
  } = useForm();

  const [editModalTabvalue, setEditModalTabValue] = useState(0);
  const [newBrand, setNewBrand] = useState({});

  const [brands, setBrands] = useState([]);
  const handleChangeEditCustomerTabValue = (event, newValue) => {
    setEditModalTabValue(newValue);
  };

  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const handleUpdate = () => {};
  return (
    <div>
      <Modal
        open={openPartModel}
        onClose={() => {
          handleClosePartModel();
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            ...style,
            border: "none",
            borderRadius: "8px 8px 0px 0px",
          }}
          className="supplierPartModelContainer"
        >
          <AppBar
            color=""
            variant="outlined"
            sx={{
              // backgroundColor: "#002394",
              color: "white",
              height: "64px",
              borderRadius: "8px 8px 0px 0px !important",
              paddingRight: "0px !important",
            }}
          >
            <Toolbar variant="regular">
              <Typography variant="h6" width={"98%"} color={"#000000DE"}>
                Add Part
              </Typography>
              <Stack direction={"row"} spacing={0}>
                <div
                  onClick={() => {
                    handleClosePartModel();
                  }}
                  style={{ cursor: "pointer", height: "32px" }}
                >
                  <CloseIcon style={{ fill: "#000000DE", fontSize: 32 }} />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>
          <Container
            gap={2}
            disableGutters
            style={{
              marginTop: "66px",
              // scrollbarColor: "blue",
              // scrollbarWidth: "thin",
              // overflowY: "auto",
              // maxHeight: "80vh",
              // position: "relative",
            }}
          >
            <form onSubmit={handleSubmit(handleUpdate)}>
              <Scrollbar
                trackXVisible={true}
                trackYVisible={true}
                style={{
                  width: "100%",

                  height: "70vh",
                  // minHeight: loading ? "20vh" : "100%",
                }}
                className="boxStyle"
              >
                <Grid
                  container
                  // spacing={3}
                  // style={{
                  //   scrollbarColor: "blue",
                  //   scrollbarWidth: "thin",
                  //   overflowY: "auto",
                  //   maxHeight: "70vh",
                  // }}
                  padding={"24px 24px 0 24px"}
                >
                  <Tabs
                    value={editModalTabvalue}
                    onChange={handleChangeEditCustomerTabValue}
                    aria-label="basic tabs example"
                    sx={{ marginBottom: "24px" }}
                  >
                    <Tab
                      label="ADD MANUALLY"
                      // style={{ display: type == SHOP_IMPORTED && "none" }}
                      {...a11yProps(0)}
                    />

                    <Tab label="INVENTORY LISTING" {...a11yProps(1)} />
                  </Tabs>

                  <div
                    style={{
                      display: editModalTabvalue == 0 ? "block" : "none",
                    }}
                  >
                    <Grid item container>
                      <Typography
                        fontWeight={500}
                        fontSize={"24px"}
                        color={"#000000DE"}
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                        >
                          <path
                            d="M27.9467 21.933C27.96 21.733 28 21.533 28 21.333V21.9997L27.9467 21.933ZM14.6667 21.333C14.6667 22.2797 14.8667 23.1863 15.2267 23.9997H8V25.333C8 26.0663 7.4 26.6663 6.66667 26.6663H5.33333C4.6 26.6663 4 26.0663 4 25.333V14.6663L6.77333 6.66634C7.04 5.89301 7.78667 5.33301 8.66667 5.33301H23.3333C24.2133 5.33301 24.96 5.89301 25.2267 6.66634L28 14.6663V21.333C28 17.653 25.0133 14.6663 21.3333 14.6663C17.6533 14.6663 14.6667 17.653 14.6667 21.333ZM10.6667 17.9997C10.6667 16.893 9.77333 15.9997 8.66667 15.9997C7.56 15.9997 6.66667 16.893 6.66667 17.9997C6.66667 19.1063 7.56 19.9997 8.66667 19.9997C9.77333 19.9997 10.6667 19.1063 10.6667 17.9997ZM25.3333 13.333L23.3333 7.33301H8.66667L6.66667 13.333H25.3333ZM30.4933 28.253L25.0133 22.773C25.56 21.3863 25.2533 19.7597 24.1067 18.6263C22.9067 17.413 21.1067 17.173 19.6533 17.8397L22.24 20.4263L20.44 22.2397L17.7867 19.6397C17.0667 21.093 17.4 22.893 18.5733 24.1063C19.1029 24.6465 19.7788 25.0202 20.5179 25.1812C21.257 25.3423 22.027 25.2839 22.7333 25.013L28.2133 30.4797C28.4533 30.733 28.8133 30.733 29.0533 30.4797L30.44 29.1063C30.7333 28.8663 30.7333 28.4397 30.4933 28.253Z"
                            fill="#2196F3"
                          />
                        </svg>{" "}
                        Part Details
                      </Typography>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6} xl={6}>
                        <FormControl fullWidth>
                          <Stack spacing={1}>
                            <Typography
                              variant="body1"
                              style={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                                letterSpacing: "0.15px",
                              }}
                            >
                              Part Name{" "}
                              <span style={{ color: "#D32F2F" }}> *</span>
                            </Typography>
                            <Controller
                              name="partName"
                              control={control}
                              rules={{
                                required: "Part name is required",
                              }}
                              render={({ field }) => (
                                <Select
                                  // {...field}
                                  placeholder=""
                                  fullWidth
                                  // required
                                  // id="outlined-basic"
                                  variant="outlined"
                                  error={!!errors.partName}
                                  helperText={
                                    errors.partName
                                      ? errors.partName.message
                                      : ""
                                  }
                                  options={[]}
                                  styles={
                                    errors.partName
                                      ? {
                                          ...customStyles,
                                          ...errorCustomStyles,
                                        }
                                      : {
                                          ...customStyles,
                                        }
                                  }
                                />
                              )}
                            />
                          </Stack>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6} xl={6}>
                        <Stack spacing={1}>
                          <Typography
                            variant="body1"
                            style={{
                              fontWeight: 500,
                              fontSize: "16px",
                              lineHeight: "24px",
                              letterSpacing: "0.15px",
                            }}
                          >
                            Part Number
                            {/* <span style={{ color: "#D32F2F" }}> *</span> */}
                          </Typography>
                          <Controller
                            name="partNumber"
                            control={control}
                            // rules={{
                            //   required: "Last Name is required",
                            // }}
                            render={({ field }) => (
                              <TextField
                                fullWidth
                                // required
                                id="outlined-basic"
                                variant="outlined"
                                error={!!errors.partNumber}
                                helperText={
                                  errors.partNumber
                                    ? errors.partNumber.message
                                    : ""
                                }
                                {...field}
                              />
                            )}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6} xl={6}>
                        <Stack spacing={1}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              variant="body1"
                              style={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                                letterSpacing: "0.15px",
                              }}
                            >
                              Supplier{" "}
                              <span style={{ color: "#D32F2F" }}> *</span>
                            </Typography>
                            <Typography
                              variant="body1"
                              color={"primary"}
                              style={{
                                fontWeight: 500,
                                fontSize: "13px",

                                textTransform: "uppercase",
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                            >
                              View Supplier{" "}
                            </Typography>
                          </div>
                          <Controller
                            name="supllier"
                            control={control}
                            //   rules={{
                            //     required: "Contact First Name is required",
                            //   }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                placeholder=""
                                fullWidth
                                // required
                                id="outlined-basic"
                                variant="outlined"
                                error={!!errors.supllier}
                                helperText={
                                  errors.supllier ? errors.supllier.message : ""
                                }
                                styles={
                                  errors.supllier
                                    ? {
                                        ...customStyles,
                                        ...errorCustomStyles,
                                      }
                                    : {
                                        ...customStyles,
                                      }
                                }
                              ></Select>
                            )}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6} xl={6}>
                        <Stack spacing={1}>
                          <Typography
                            variant="body1"
                            style={{
                              fontWeight: 500,
                              fontSize: "16px",
                              lineHeight: "24px",
                              letterSpacing: "0.15px",
                            }}
                          >
                            Category
                            {/* <span style={{ color: "#D32F2F" }}> *</span> */}
                          </Typography>
                          <Controller
                            name="category"
                            control={control}
                            // rules={{
                            //   required: "Last Name is required",
                            // }}
                            render={({ field }) => (
                              <Select
                                fullWidth
                                placeholder=""
                                // required
                                id="outlined-basic"
                                variant="outlined"
                                error={!!errors.category}
                                helperText={
                                  errors.category ? errors.category.message : ""
                                }
                                {...field}
                                styles={
                                  errors.category
                                    ? {
                                        ...customStyles,
                                        ...errorCustomStyles,
                                      }
                                    : {
                                        ...customStyles,
                                      }
                                }
                              ></Select>
                            )}
                          />
                        </Stack>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Typography variant="body1" className="label-500">
                          Brand
                        </Typography>
                        <Controller
                          name="brand"
                          control={control}
                          render={({ field }) => (
                            // <TextField
                            //   id="outlined-basic"
                            //   /*label="Last Name"*/
                            //   variant="outlined"
                            //   fullWidth
                            //   // required
                            //   error={!!errors.brand}
                            //   helperText={
                            //     errors.brand ? errors.brand.message : ""
                            //   }
                            //   {...field}
                            // />

                            <Select
                              {...field}
                              placeholder=""
                              fullWidth
                              // required
                              // id="outlined-basic"
                              variant="outlined"
                              error={!!errors.brand}
                              helperText={
                                errors.brand ? errors.brand.message : ""
                              }
                              getOptionLabel={(option) => option.value} // Specifies which field to use as the label
                              //   getOptionLabel={(option) =>  <div
                              //     // style={{
                              //     //   display: "flex",
                              //     //   justifyContent: "space-between",
                              //     // }}
                              //     >
                              //       {option.value} {"46546"}
                              //     </div>
                              // }
                              getOptionValue={(option) => option}
                              options={[newBrand, ...brands].filter(
                                (d) => d.key !== ""
                              )}
                              onInputChange={(e) =>
                                setNewBrand({
                                  key: e,
                                  value: e,
                                  showSave: true,
                                })
                              }
                              onChange={(e, newValue) => {
                                if (
                                  Object.keys(e).length != 0 &&
                                  e?.key != ""
                                ) {
                                  if (
                                    brands.filter(
                                      (d) =>
                                        JSON.stringify(d) == JSON.stringify(e)
                                    )?.length == 0
                                  ) {
                                    const userConfirmation = window.confirm(
                                      "Would you like to save this option?"
                                    );
                                    if (userConfirmation) {
                                      // If user confirms "Yes", add the option to the brands array
                                      if (
                                        brands.filter((d) => d.key === e.key)
                                          ?.length === 0
                                      ) {
                                        setBrands([
                                          ...brands,
                                          { ...e, showSave: false },
                                        ]);
                                        setValue("brand", e);
                                      }
                                    } else {
                                      // If user selects "No", reset the select value
                                      setValue("brand", null);
                                    }
                                  }
                                }
                              }}
                              components={{
                                Option: CustomOption, // Use custom Option component
                              }}
                              styles={
                                errors.brand
                                  ? {
                                      ...customStyles,
                                      ...errorCustomStyles,
                                    }
                                  : {
                                      ...customStyles,
                                    }
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Typography variant="body1" className="label-500">
                          Parts Provider
                        </Typography>
                        <Controller
                          name="partProvider"
                          control={control}
                          render={({ field }) => (
                            <RadioGroup
                              {...field}
                              row
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              // value={value}
                              // onChange={handleChange}
                            >
                              <FormControlLabel
                                value="oem"
                                control={<Radio />}
                                label="OEM"
                              />
                              <FormControlLabel
                                value="oes"
                                control={<Radio />}
                                label="OES"
                              />
                              <FormControlLabel
                                value="afterMarket"
                                control={<Radio />}
                                label="After Market"
                              />
                              <FormControlLabel
                                value="used"
                                control={<Radio />}
                                label="Used"
                              />
                            </RadioGroup>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="body1" className="label-500">
                          Description
                        </Typography>
                        <Controller
                          name="description"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              id="outlined-basic"
                              /*label="Last Name"*/
                              variant="outlined"
                              fullWidth
                              // required
                              error={!!errors.description}
                              helperText={
                                errors.description
                                  ? errors.description.message
                                  : ""
                              }
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item container xs={12}>
                        <Divider
                          style={{
                            width: "100%",
                            paddingLeft: "24px",
                            // marginTop: "24px",
                            // marginBottom: "24px",
                          }}
                        />
                      </Grid>
                      <Grid item container>
                        <Typography
                          fontWeight={500}
                          fontSize={"24px"}
                          color={"#000000DE"}
                          display={"flex"}
                          alignItems={"center"}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                          >
                            <path
                              d="M4 8H28V24H4V8ZM16 12C17.0609 12 18.0783 12.4214 18.8284 13.1716C19.5786 13.9217 20 14.9391 20 16C20 17.0609 19.5786 18.0783 18.8284 18.8284C18.0783 19.5786 17.0609 20 16 20C14.9391 20 13.9217 19.5786 13.1716 18.8284C12.4214 18.0783 12 17.0609 12 16C12 14.9391 12.4214 13.9217 13.1716 13.1716C13.9217 12.4214 14.9391 12 16 12ZM9.33333 10.6667C9.33333 11.3739 9.05238 12.0522 8.55229 12.5523C8.05219 13.0524 7.37391 13.3333 6.66667 13.3333V18.6667C7.37391 18.6667 8.05219 18.9476 8.55229 19.4477C9.05238 19.9478 9.33333 20.6261 9.33333 21.3333H22.6667C22.6667 20.6261 22.9476 19.9478 23.4477 19.4477C23.9478 18.9476 24.6261 18.6667 25.3333 18.6667V13.3333C24.6261 13.3333 23.9478 13.0524 23.4477 12.5523C22.9476 12.0522 22.6667 11.3739 22.6667 10.6667H9.33333Z"
                              fill="#2196F3"
                            />
                          </svg>
                          Pricing
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={3} xl={3}>
                        <Stack spacing={1}>
                          <Typography
                            variant="body1"
                            style={{
                              fontWeight: 500,
                              fontSize: "16px",
                              lineHeight: "24px",
                              letterSpacing: "0.15px",
                            }}
                          >
                            Quantity
                            <span style={{ color: "#D32F2F" }}> *</span>
                          </Typography>
                          <Controller
                            name="quantity"
                            control={control}
                            rules={{
                              required: "Quantity is required",
                            }}
                            render={({ field }) => (
                              <TextField
                                fullWidth
                                // required
                                id="outlined-basic"
                                variant="outlined"
                                error={!!errors.quantity}
                                helperText={
                                  errors.quantity ? errors.quantity.message : ""
                                }
                                value={field.value}
                                onChange={(event) => {
                                  const inputValue = event.target.value;
                                  const numericValue = inputValue.replace(
                                    /[^\d.]/g,
                                    ""
                                  );

                                  setValue(
                                    "quantity",
                                    FormatNumberForKmFloat(numericValue)
                                  );
                                }}
                                // {...field}
                              />
                            )}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={3} xl={3}>
                        <Stack spacing={1}>
                          <Typography
                            variant="body1"
                            style={{
                              fontWeight: 500,
                              fontSize: "16px",
                              lineHeight: "24px",
                              letterSpacing: "0.15px",
                            }}
                          >
                            Cost Per Unit
                            <span style={{ color: "#D32F2F" }}> *</span>
                          </Typography>
                          <Controller
                            name="costPerUnit"
                            control={control}
                            rules={{
                              required: "Cost Per Unit is required",
                            }}
                            render={({ field }) => (
                              <TextField
                                fullWidth
                                // required
                                id="outlined-basic"
                                variant="outlined"
                                error={!!errors.costPerUnit}
                                helperText={
                                  errors.costPerUnit
                                    ? errors.costPerUnit.message
                                    : ""
                                }
                                value={field.value}
                                onChange={(event) => {
                                  const inputValue = event.target.value;
                                  const numericValue = inputValue.replace(
                                    /[^\d.]/g,
                                    ""
                                  );

                                  setValue(
                                    "costPerUnit",
                                    FormatNumberForKmFloat(numericValue)
                                  );
                                }}
                                // {...field}
                              />
                            )}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl fullWidth>
                          <Stack spacing={1}>
                            <Typography
                              variant="body1"
                              style={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                                letterSpacing: "0.15px",
                              }}
                            >
                              Markup Type{" "}
                            </Typography>
                            <Controller
                              name="markupType"
                              control={control}
                              // rules={{
                              //   required: "Markup type is required",
                              // }}
                              render={({ field }) => (
                                <Select
                                  // {...field}
                                  placeholder=""
                                  fullWidth
                                  // required
                                  // id="outlined-basic"
                                  variant="outlined"
                                  error={!!errors.markupType}
                                  helperText={
                                    errors.markupType
                                      ? errors.markupType.message
                                      : ""
                                  }
                                  getOptionLabel={(option) => option.value} // Specifies which field to use as the label
                                  getOptionValue={(option) => option}
                                  options={[
                                    {
                                      key: "margin",
                                      value: "Margin",
                                    },
                                    {
                                      key: "markup",
                                      value: "Markup",
                                    },
                                  ]}
                                  styles={
                                    errors.markupType
                                      ? {
                                          ...customStyles,
                                          ...errorCustomStyles,
                                        }
                                      : {
                                          ...customStyles,
                                        }
                                  }
                                />
                              )}
                            />
                          </Stack>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl fullWidth>
                          <Stack spacing={1}>
                            <Typography
                              variant="body1"
                              style={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                                letterSpacing: "0.15px",
                              }}
                            >
                              Markup / Margin{" "}
                            </Typography>
                            <Controller
                              name="markupMargin"
                              control={control}
                              // rules={{
                              //   required: "Markup type is required",
                              // }}
                              render={({ field }) => (
                                <Select
                                  // {...field}
                                  placeholder=""
                                  fullWidth
                                  // required
                                  // id="outlined-basic"
                                  variant="outlined"
                                  error={!!errors.markupMargin}
                                  helperText={
                                    errors.markupMargin
                                      ? errors.markupMargin.message
                                      : ""
                                  }
                                  options={[]}
                                  styles={
                                    errors.markupMargin
                                      ? {
                                          ...customStyles,
                                          ...errorCustomStyles,
                                        }
                                      : {
                                          ...customStyles,
                                        }
                                  }
                                />
                              )}
                            />
                          </Stack>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3} xl={3}>
                        <Stack spacing={1}>
                          <Typography
                            variant="body1"
                            style={{
                              fontWeight: 500,
                              fontSize: "16px",
                              lineHeight: "24px",
                              letterSpacing: "0.15px",
                            }}
                          >
                            Retail Per Unit
                          </Typography>
                          <Controller
                            name="retailPerUnit"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                fullWidth
                                disabled
                                sx={{
                                  background: "#F1F1F1",
                                  border: "#F1F1F1",
                                }}
                                // required

                                variant="outlined"
                              />
                            )}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={3} xl={3}>
                        <Stack spacing={1}>
                          <Typography
                            variant="body1"
                            style={{
                              fontWeight: 500,
                              fontSize: "16px",
                              lineHeight: "24px",
                              letterSpacing: "0.15px",
                            }}
                          >
                            Margin Per Unit
                          </Typography>
                          <Controller
                            name="marginPerUnit"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                fullWidth
                                disabled
                                sx={{
                                  background: "#F1F1F1",
                                  border: "#F1F1F1",
                                }}
                                // required

                                variant="outlined"
                              />
                            )}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={3} xl={3}>
                        <Stack spacing={1}>
                          <Typography
                            variant="body1"
                            style={{
                              fontWeight: 500,
                              fontSize: "16px",
                              lineHeight: "24px",
                              letterSpacing: "0.15px",
                            }}
                          >
                            Total Retail
                          </Typography>
                          <Controller
                            name="totalRetail"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                fullWidth
                                disabled
                                sx={{
                                  background: "#F1F1F1",
                                  border: "#F1F1F1",
                                }}
                                // required

                                variant="outlined"
                              />
                            )}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={3} xl={3}>
                        <Stack spacing={1}>
                          <Typography
                            variant="body1"
                            style={{
                              fontWeight: 500,
                              fontSize: "16px",
                              lineHeight: "24px",
                              letterSpacing: "0.15px",
                            }}
                          >
                            Total Margin
                          </Typography>
                          <Controller
                            name="totalMargin"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                fullWidth
                                disabled
                                sx={{
                                  background: "#F1F1F1",
                                  border: "#F1F1F1",
                                }}
                                // required

                                variant="outlined"
                              />
                            )}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </div>

                  {/* <div
                    style={{
                      display: editModalTabvalue == 1 ? "block" : "none",
                    }}
                  >
                    <Grid item container spacing={3}>
                      <Grid item md={6}>
                        <TextField
                          id="standard-search"
                          label="Search field"
                          type="search"
                          variant="standard"
                        />
                      </Grid>
                    </Grid>
                  </div> */}
                  <div
                    style={{
                      display: editModalTabvalue == 1 ? "block" : "none",
                      width: "100%",
                    }}
                  >
                    <Grid item container spacing={3}>
                      <Grid item md={6.5}>
                        <FormControl variant="standard" fullWidth>
                          <InputLabel htmlFor="input-with-icon-adornment">
                            Search field
                          </InputLabel>
                          <Input
                            placeholder="Search from inventory"
                            id="input-with-icon-adornment"
                            startAdornment={
                              <InputAdornment position="start">
                                <Search />
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={3.3}>
                        <TextField
                          fullWidth
                          select
                          id="standard-search"
                          label="Filter"
                          type="Search Category"
                          placeholder="Search Category"
                          variant="standard"
                        />
                      </Grid>
                      <Grid item md={2.2}>
                        <FormControlLabel
                          sx={{
                            width: "100%",
                            margin: 0,
                            justifyContent: "end",
                          }}
                          control={<Checkbox defaultChecked />}
                          label="Only In Stock"
                        />
                      </Grid>
                    </Grid>
                    <Grid item container mt={3}>
                      <Typography
                        fontWeight={500}
                        fontSize={"20px"}
                        color={"#000000DE"}
                      >
                        Recently Added Parts
                      </Typography>
                    </Grid>

                    <Grid item container mt={1}>
                      <TableContainer>
                        <Table
                          stickyHeader
                          aria-label="caption table"
                          padding="16px"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ bgcolor: "#F8F8F8" }}>
                                No.
                              </TableCell>
                              <TableCell sx={{ bgcolor: "#F8F8F8" }}>
                                Description
                              </TableCell>
                              <TableCell sx={{ bgcolor: "#F8F8F8" }}>
                                In Stock
                              </TableCell>
                              <TableCell sx={{ bgcolor: "#F8F8F8" }}>
                                Quantity
                              </TableCell>
                              <TableCell sx={{ bgcolor: "#F8F8F8" }}>
                                Cost
                              </TableCell>

                              <TableCell sx={{ bgcolor: "#F8F8F8" }}>
                                Amount
                              </TableCell>
                            </TableRow>
                          </TableHead>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </div>
                </Grid>
              </Scrollbar>

              <Grid
                item
                container
                justifyContent={"end"}
                // spacing={2}
                style={{ marginTop: "24px" }}
                padding={"0px 24px 24px 24px"}
              >
                {/* <Grid item>
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => setOpenDelete(true)}
                      size="large"
                    >
                      DELETE
                    </Button>
                  </Stack>
                </Grid> */}
                <Grid item>
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        handleClosePartModel();
                      }}
                      size="large"
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                      onClick={() => {
                        if (Object.keys(errors)?.length != 0) {
                          setEditModalTabValue(0);
                        }
                      }}
                    >
                      ADD TO APPOINTMENT
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Box>
      </Modal>
      <Snackbar
        open={openSuccessModal}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message="I love snacks"
        key={"top" + "center"}
        onClose={() => setOpenSuccessModal(false)}
        autoHideDuration={2000}
      >
        <Alert severity={"error"}>{successMessage}</Alert>
      </Snackbar>
    </div>
  );
}

export default SupplierPartModel;
