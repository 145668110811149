import Add from "@mui/icons-material/Add";
import { Box, Breadcrumbs, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./SupplierProfile.css";
import SupplierProfileLeftSection from "./SupplierProfileLeftSection";
import SupplierProfileRightSection from "./SupplierProfileRightSection";
function SupplierProfile(props) {
  const navigate = useNavigate();
  const [quickEdit, setQuickEdit] = useState(false);

  return (
    <Box padding={"32px 24px"} sx={{ bgcolor: "white" }}>
      <Grid
        container
        spacing={0}
        sx={{ padding: "16px 0px 16px 0px" }}
        gap={"8px"}
      >
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                to="/suppliers"
                style={{
                  color: "#000000DE",
                  // opacity: "0.8",
                  textDecoration: "none",
                }}
              >
                Supplier
              </Link>
              <Link
                // to={`/customers/${id}`}
                style={{
                  color: "#000000DE",

                  textDecoration: "none",
                }}
              >
                Ford compnay
              </Link>
            </Breadcrumbs>
          </div>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          // justifyContent={"space-between"}
          spacing={2}
          rowGap={{ lg: 2 }}
        >
          <Grid item lg={3.8} md={12} sm={12} xs={12} xl={5}>
            <Typography variant="h4" sx={{ fontWeight: 500 }}>
              Supplier Profile
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={8.2}
            xl={7}
            sx={{
              display: "flex",
              justifyContent: { lg: "end" },
              flexWrap: { xs: "wrap", sm: "nowrap" }, // Allow wrapping on smaller screens
              gap: 2,
              pointerEvents: quickEdit ? "none" : "auto",
              opacity: quickEdit ? "0.5" : "",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Add />}
              size={"large"}
              sx={{ whiteSpace: "nowrap" }} // Prevent label from wrapping
            >
              Part
            </Button>

            <Button
              variant="contained"
              color="secondary"
              startIcon={<Add />}
              size="large"
              sx={{ whiteSpace: "nowrap" }} // Prevent label from wrapping
            >
              Tire
            </Button>

            <Button
              variant="contained"
              color="secondary"
              startIcon={<Add />}
              size="large"
              sx={{ whiteSpace: "nowrap" }} // Prevent label from wrapping
            >
              Vehicle
            </Button>

            <Button
              variant="contained"
              color="secondary"
              startIcon={<Add />}
              size="large"
              sx={{ whiteSpace: "nowrap" }}
              onClick={() => navigate("/suppliers-invoice")}
              // Prevent label from wrapping
            >
              Supplier Invoice
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container mt={"41px"} columnSpacing={3} rowGap={3}>
        <Grid item container xs={12} sm={6} md={4.3}>
          <SupplierProfileLeftSection
            setQuickEdit={setQuickEdit}
            quickEdit={quickEdit}
          />
        </Grid>

        <Grid
          item
          container
          xs={12}
          sm={6}
          md={7.7}
          sx={{
            opacity: quickEdit ? "0.5" : "",
            pointerEvents: quickEdit ? "none" : "auto",
          }}
        >
          <SupplierProfileRightSection />
        </Grid>
      </Grid>
    </Box>
  );
}

export default SupplierProfile;
