import { AppBar, Box, Container, Modal, Paper, Stack, TextField, Toolbar, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};
const ServiceModal = () => {
    return (
        <Modal
            open={false}
            // title="Add new Service"
            // onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={{ ...style, width: "720px", height: "658px" }}>
                <AppBar color="primary" variant="elevation" sx={{
                    height: "64px",
                    paddingRight: "0px !important",
                }}>
                    <Paper variant="elevation" elevation={4} square={true} style={{ backgroundColor: "#2196F3" }}>
                        <Toolbar variant="regular">
                            <Typography variant="body1" gutterBottom={false} width={"98%"} color={"white"}>
                                Add New Service
                            </Typography>
                            <Stack direction={"row"} spacing={0}>
                                <div>
                                    <CloseIcon style={{ color: '#0000008F' }} />
                                </div>
                            </Stack>
                        </Toolbar>
                    </Paper>
                </AppBar>
                <Container gap={"24px"} disableGutters style={{ height: "602px", marginTop: "66px" }}>
                    <Stack direction={"column"} spacing={1}>
                        <div>
                            <Typography variant="body1" gutterBottom={false} style={{ fontWeight: "500", fontSize: "16px", lineHeight: "24px" }}>
                                Service Category *
                            </Typography>
                        </div>
                        <div>
                            <TextField
                                Variant="Outlined"
                                Size="Medium"
                                State="Enabled"
                                Placeholder="Select a Service Category"
                                fullWidth
                                placeholder="Select a Service Category"
                            ></TextField>
                        </div>
                    </Stack>
                </Container>
            </Box>
        </Modal>
    );
};
export default ServiceModal;
