// import React from 'react'

// const AddAssigneModal = () => {
//   return (
//     <div>

//     </div>
//   )
// }

// export default AddAssigneModal

import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  MenuItem,
  Modal,
  Select,
  TextareaAutosize,
  useMediaQuery,
} from "@mui/material";
import {
  AppBar,
  FormControlLabel,
  Grid,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form"; // Step 1
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  mechanicDetailsList,
  reschuleAppointment,
} from "../../redux/appointment/action";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useTheme } from "@emotion/react";
import "../Estimates/Estimates.css";
import SuccessMessageModal from "../Common/SuccessMessage";
import { acceptDeclineAppointment } from "../../redux/Dashboard/action";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const textAreaStyle = {
  width: "100%",
  height: "100px",
  fontSize: "16px",
  border: "1px solid #ccc",
  borderRadius: "4px",
  padding: "10px",
  fontFamily: "Roboto",
  fontWeight: 400,
};

const AddAssigneeModal = ({
  open,
  handleClose,
  id,
  handleCloseRequestModal,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = React.useState(false);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [assigneeData, setAssigneeData] = React.useState("");
  const [assigneeList, setAssigneeList] = React.useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const assigneecList = async () => {
    let data = await dispatch(mechanicDetailsList());
    if (data) {
      if (data?.length == 1) {
        setAssigneeData(data[0]?._id);
      } else {
        setAssigneeData(data?.find((d) => d.isDefault == true)?._id);
      }
    }
    setAssigneeList(data);
  };

  useEffect(() => {
    if (open) assigneecList();
  }, [open]);

  const onSubmit = async () => {
    setShowLoader(true);
    const payload = {
      status: "accept",
      assignee: assigneeData,
    };
    const data = await dispatch(acceptDeclineAppointment(id, payload));
    if (data) {
      setOpenSuccessModal(true);
      setSuccessMessage("Appointment request accepted");
      setShowLoader(false);
      handleClose();
      handleCloseRequestModal();
    }
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={9} md={9} xl={9}>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              {" "}
              <Box
                sx={{
                  ...style,
                  height: "fit-content",
                  width: "848px",
                  border: "none",
                }}
              >
                <AppBar
                  variant="elevation"
                  sx={{
                    height: "64px",
                    borderRadius: "8px 8px 0px 0px !important",
                    paddingRight: "0px !important",
                    boxShadow: "none",
                    backgroundColor: "#002394",
                  }}
                >
                  <Toolbar variant="regular">
                    <Typography variant="h6" fontWeight={500} width={"98%"}>
                      Add Assignee
                    </Typography>
                    <Stack direction={"row"} spacing={0}>
                      <div
                        onClick={handleClose}
                        style={{ cursor: "pointer", height: "32px" }}
                      >
                        <CloseIcon style={{ fontSize: 32 }} />
                      </div>
                    </Stack>
                  </Toolbar>
                </AppBar>

                <Container gap={4} disableGutters style={{ marginTop: "66px" }}>
                  <Grid container padding={"10px 0px"}>
                    <Grid container item xs={12} md={12} xl={12} spacing={3}>
                      <Grid item xs={12} md={12} xl={12}>
                        <Stack spacing={0}>
                          <Typography
                            variant="body1"
                            className="label-500"
                            fontWeight={500}
                            fontSize={16}
                          >
                            Assign To
                          </Typography>
                          <Select
                            value={assigneeData}
                            onChange={(event) => {
                              setAssigneeData(event.target.value);
                            }}
                            displayEmpty
                            required
                          >
                            {assigneeList &&
                              assigneeList?.map((item) => {
                                return (
                                  <MenuItem value={item?._id}>
                                    {item.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        container
                        justifyContent={"end"}
                        spacing={2}
                        style={{ marginTop: "0px" }}
                      >
                        <Grid item>
                          <Stack spacing={2} direction="row">
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={handleClose}
                            >
                              CANCEL
                            </Button>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                            >
                              SAVE
                            </Button>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            </form>
          </Modal>
        </Grid>
      </Grid>
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AddAssigneeModal;
