import { useTheme } from "@emotion/react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import SuccessMessageModal from "../../Common/SuccessMessage";
import ShopPricing from "../ShopDetails/ShopPricing";
import DiscountProgram from "./DiscountProgram";

const Pricing = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Box>
        <ShopPricing
          setShowLoader={setShowLoader}
          setOpenSuccessModal={setOpenSuccessModal}
          setSuccessMessage={setSuccessMessage}
        />
        <DiscountProgram />
        <Dialog fullScreen={fullScreen} open={showLoader}>
          <DialogContent>
            <CircularProgress />
          </DialogContent>
        </Dialog>
        <SuccessMessageModal
          message={successMessage}
          open={openSuccessModal}
          setOpenSuccessModal={setOpenSuccessModal}
        />
      </Box>
    </>
  );
};

export default Pricing;
