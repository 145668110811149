import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import {
  NotificationStatus,
  updateNotificationStatus,
} from "../../../redux/Account/Notification/action";
import { useDispatch, useSelector } from "react-redux";

const Notification = () => {
  const dispatch = useDispatch();
  const getNotificationRedux = useSelector(
    (state) => state?.account?.getNotificationStatus
  );

  const [isChecked, setChecked] = useState({
    emailNotificationsNewAppointments:
      getNotificationRedux?.emailNotificationsNewAppointments,

    emailNotificationsReschedules:
      getNotificationRedux?.emailNotificationsReschedules,
    emailNotificationsCancellations:
      getNotificationRedux?.emailNotificationsCancellations,
    emailNotificationsPromotionsandAnnouncements:
      getNotificationRedux?.emailNotificationsPromotionsandAnnouncements,
    emailNotificationsMessagefromCustomer:
      getNotificationRedux?.emailNotificationsMessagefromCustomer,
    mobileNotificationsCancellations:
      getNotificationRedux?.mobileNotificationsCancellations,
    mobileNotificationsMessageFromCustomer:
      getNotificationRedux?.mobileNotificationsMessageFromCustomer,
    mobileNotificationsNewAppointments:
      getNotificationRedux?.mobileNotificationsNewAppointments,
    mobileNotificationsPromotionsandAnnouncements:
      getNotificationRedux?.mobileNotificationsPromotionsandAnnouncements,
    mobileNotificationsReschedules:
      getNotificationRedux?.mobileNotificationsReschedules,
  });

  useEffect(() => {
    getNotificationData();
    // setChecked({ ...getNotificationRedux });
  }, []);

  const getNotificationData = async () => {
    let res = await dispatch(NotificationStatus());
    if (res) {
      setChecked(res);
    }
  };

  const updateData = async (newData) => {
    let res = await dispatch(updateNotificationStatus(newData));
  };

  return (
    <Box padding={"0 24px 24px 20px"} marginTop={"32px"}>
      <Grid
        container
        spacing={2}
        sx={{ boxShadow: 2, bgcolor: "white" }}
        padding={"10px"}
        borderRadius={"8px"}
      >
        <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
          <Typography
            variant="h5"
            style={{ fontWeight: 400 }}
            className="style-24-400-32-px"
          >
            Email
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={12}
          lg={8}
          xl={8}
          spacing={2}
          justifyContent={"space-between"}
          rowGap={3}
          paddingRight={"16px"}
          marginY={"16px"}
        >
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            xl={12}
            justifyContent={"space-between"}
          >
            <Grid item xs={10} sm={10} md={10} xl={10}>
              <Typography
                variant="h6"
                fontSize={"20px"}
                className="btn-text-style"
              >
                New Appointments
              </Typography>
              <Typography variant="body1">
                Get notified when a customer submits an appointment request or
                when a customeraccepts a new time request.
              </Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={isChecked.emailNotificationsNewAppointments}
                color="primary"
                size="medium"
                onChange={() => {
                  const newData = { ...isChecked };
                  newData.emailNotificationsNewAppointments =
                    !newData.emailNotificationsNewAppointments;
                  setChecked(newData);
                  updateData(newData);
                }}
              />
            </Grid>
          </Grid>
          <Divider orientation="horizontal" style={{ width: "100%" }} />
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            xl={12}
            justifyContent={"space-between"}
          >
            <Grid item xs={10} sm={10} md={10} xl={10}>
              <Typography
                variant="h6"
                fontSize={"20px"}
                className="btn-text-style"
              >
                Reschedules
              </Typography>
              <Typography variant="body1">
                Receive timely notifications when a customer requests to
                reschedule an appointment.
              </Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={isChecked.emailNotificationsReschedules}
                color="primary"
                size="medium"
                onChange={() => {
                  const newData = { ...isChecked };
                  newData.emailNotificationsReschedules =
                    !newData.emailNotificationsReschedules;

                  setChecked(newData);
                  updateData(newData);
                }}
              />
            </Grid>
          </Grid>
          <Divider orientation="horizontal" style={{ width: "100%" }} />
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            xl={12}
            justifyContent={"space-between"}
          >
            <Grid item xs={10} sm={10} md={10} xl={10}>
              <Typography
                variant="h6"
                fontSize={"20px"}
                className="btn-text-style"
              >
                Cancellations
              </Typography>
              <Typography variant="body1">
                Receive timely notifications when a customer cancels an
                appointment.
              </Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={isChecked.emailNotificationsCancellations}
                color="primary"
                size="medium"
                onChange={() => {
                  const newData = { ...isChecked };
                  newData.emailNotificationsCancellations =
                    !newData.emailNotificationsCancellations;
                  setChecked(newData);
                  updateData(newData);
                }}
              />
            </Grid>
          </Grid>
          <Divider orientation="horizontal" style={{ width: "100%" }} />
          {/* <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            xl={12}
            justifyContent={"space-between"}
          >
            <Grid item xs={10} sm={10} md={10} xl={10}>
              <Typography
                variant="h6"
                fontSize={"20px"}
                className="btn-text-style"
              >
                Message from a Customer
              </Typography>
              <Typography variant="body1">
                Receive instant notifications whenever a customer sends you a
                message.
              </Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={isChecked.emailNotificationsMessagefromCustomer}
                color="primary"
                size="medium"
                onChange={() => {
                  const newData = { ...isChecked };
                  newData.emailNotificationsMessagefromCustomer =
                    !newData.emailNotificationsMessagefromCustomer;
                  setChecked(newData);
                  updateData(newData);
                }}
              />
            </Grid>
          </Grid>
          <Divider orientation="horizontal" style={{ width: "100%" }} /> */}

          {/* <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            xl={12}
            justifyContent={"space-between"}
            marginBottom={"16px"}
          >
            <Grid item xs={10} sm={10} md={10} xl={10}>
              <Typography variant="h6" fontSize={"20px"}>
                Estimates
              </Typography>
              <Typography variant="body1">
                Receive timely notifications when a customer requests an
                estimate or when your shop complete and sends an estimate to a
                customer.
              </Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={isChecked.emailNotificationsPromotionsandAnnouncements}
                color="primary"
                size="medium"
                onChange={() => {
                  const newData = { ...isChecked };
                  newData.emailNotificationsPromotionsandAnnouncements =
                    !newData.emailNotificationsPromotionsandAnnouncements;
                  setChecked(newData);
                  updateData(newData);
                }}
              />
            </Grid>
          </Grid>
          <Divider orientation="horizontal" style={{ width: "100%" }} />
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            xl={12}
            justifyContent={"space-between"}
            marginBottom={"16px"}
          >
            <Grid item xs={10} sm={10} md={10} xl={10}>
              <Typography variant="h6" fontSize={"20px"}>
                Approvals
              </Typography>
              <Typography variant="body1">
                Receive timely notifications when a customer responds to a
                request for an approval.
              </Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={isChecked.emailNotificationsPromotionsandAnnouncements}
                color="primary"
                size="medium"
                onChange={() => {
                  const newData = { ...isChecked };
                  newData.emailNotificationsPromotionsandAnnouncements =
                    !newData.emailNotificationsPromotionsandAnnouncements;
                  setChecked(newData);
                  updateData(newData);
                }}
              />
            </Grid>
          </Grid>
          <Divider orientation="horizontal" style={{ width: "100%" }} /> */}
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            xl={12}
            justifyContent={"space-between"}
            marginBottom={"16px"}
          >
            <Grid item xs={10} sm={10} md={10} xl={10}>
              <Typography variant="h6" fontSize={"20px"}>
                Promotions and Announcements
              </Typography>
              <Typography variant="body1">
                Receive notifications for updates, promotions, and other
                information.
              </Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={isChecked.emailNotificationsPromotionsandAnnouncements}
                color="primary"
                size="medium"
                onChange={() => {
                  const newData = { ...isChecked };
                  newData.emailNotificationsPromotionsandAnnouncements =
                    !newData.emailNotificationsPromotionsandAnnouncements;
                  setChecked(newData);
                  updateData(newData);
                }}
              />
            </Grid>
          </Grid>
          <Divider orientation="horizontal" style={{ width: "100%" }} />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{ boxShadow: 2, bgcolor: "white" }}
        padding={"10px"}
        borderRadius={"8px"}
        marginTop={'32px'}
      >
        <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
          <Typography
            variant="h5"
            style={{ fontWeight: 400 }}
            className="style-24-400-32-px"
          >
            Push Notifications (Mobile)
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={12}
          lg={8}
          xl={8}
          spacing={2}
          justifyContent={"space-between"}
          rowGap={3}
          paddingRight={"16px"}
          marginY={"16px"}
        >
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            xl={12}
            justifyContent={"space-between"}
          >
            <Grid item xs={8} sm={8} md={8} xl={8}>
              <Typography
                variant="h6"
                fontSize={"20px"}
                className="btn-text-style"
              >
                New Appointments
              </Typography>
              <Typography variant="body1">
                Get notified when a customer submits an appointment request.
              </Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={isChecked.mobileNotificationsNewAppointments}
                color="primary"
                size="medium"
                onChange={() => {
                  const newData = { ...isChecked };
                  newData.mobileNotificationsNewAppointments =
                    !newData.mobileNotificationsNewAppointments;
                  setChecked(newData);
                  updateData(newData);
                }}
              />
            </Grid>
          </Grid>
          <Divider orientation="horizontal" style={{ width: "100%" }} />
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            xl={12}
            justifyContent={"space-between"}
          >
            <Grid item xs={10} sm={10} md={10} xl={10}>
              <Typography
                variant="h6"
                fontSize={"20px"}
                className="btn-text-style"
              >
                Reschedules
              </Typography>
              <Typography variant="body1">
                Receive timely notifications when a customer reschedules an
                appointment.
              </Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={isChecked.mobileNotificationsReschedules}
                color="primary"
                size="medium"
                onChange={() => {
                  const newData = { ...isChecked };
                  newData.mobileNotificationsReschedules =
                    !newData.mobileNotificationsReschedules;

                  setChecked(newData);
                  updateData(newData);
                }}
              />
            </Grid>
          </Grid>
          <Divider orientation="horizontal" style={{ width: "100%" }} />
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            xl={12}
            justifyContent={"space-between"}
          >
            <Grid item xs={10} sm={10} md={10} xl={10}>
              <Typography
                variant="h6"
                fontSize={"20px"}
                className="btn-text-style"
              >
                Cancellations
              </Typography>
              <Typography variant="body1">
                Receive timely notifications when a customer cancels an
                appointment.
              </Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={isChecked.mobileNotificationsCancellations}
                color="primary"
                size="medium"
                onChange={() => {
                  const newData = { ...isChecked };
                  newData.mobileNotificationsCancellations =
                    !newData.mobileNotificationsCancellations;
                  setChecked(newData);
                  updateData(newData);
                }}
              />
            </Grid>
          </Grid>
          <Divider orientation="horizontal" style={{ width: "100%" }} />
          {/* <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            xl={12}
            justifyContent={"space-between"}
            marginBottom={"16px"}
          >
            <Grid item xs={10} sm={10} md={10} xl={10}>
              <Typography variant="h6" fontSize={"20px"}>
                Estimates
              </Typography>
              <Typography variant="body1">
                Receive timely notifications when a customer requests an
                estimate or when your shop complete and sends an estimate to a
                customer.
              </Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={isChecked.emailNotificationsPromotionsandAnnouncements}
                color="primary"
                size="medium"
                onChange={() => {
                  const newData = { ...isChecked };
                  newData.emailNotificationsPromotionsandAnnouncements =
                    !newData.emailNotificationsPromotionsandAnnouncements;
                  setChecked(newData);
                  updateData(newData);
                }}
              />
            </Grid>
          </Grid>
          <Divider orientation="horizontal" style={{ width: "100%" }} />
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            xl={12}
            justifyContent={"space-between"}
            marginBottom={"16px"}
          >
            <Grid item xs={10} sm={10} md={10} xl={10}>
              <Typography variant="h6" fontSize={"20px"}>
                Approvals
              </Typography>
              <Typography variant="body1">
                Receive timely notifications when a customer responds to a
                request for an approval.
              </Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={isChecked.emailNotificationsPromotionsandAnnouncements}
                color="primary"
                size="medium"
                onChange={() => {
                  const newData = { ...isChecked };
                  newData.emailNotificationsPromotionsandAnnouncements =
                    !newData.emailNotificationsPromotionsandAnnouncements;
                  setChecked(newData);
                  updateData(newData);
                }}
              />
            </Grid>
          </Grid>
          <Divider orientation="horizontal" style={{ width: "100%" }} /> */}
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            xl={12}
            justifyContent={"space-between"}
          >
            <Grid item xs={10} sm={10} md={10} xl={10}>
              <Typography
                variant="h6"
                fontSize={"20px"}
                className="btn-text-style"
              >
                Message from a Customer
              </Typography>
              <Typography variant="body1">
                Receive instant notifications whenever a customer sends you a
                message.
              </Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={isChecked.mobileNotificationsMessageFromCustomer}
                color="primary"
                size="medium"
                onChange={() => {
                  const newData = { ...isChecked };
                  newData.mobileNotificationsMessageFromCustomer =
                    !newData.mobileNotificationsMessageFromCustomer;
                  setChecked(newData);
                  updateData(newData);
                }}
              />
            </Grid>
          </Grid>
          <Divider orientation="horizontal" style={{ width: "100%" }} />
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            xl={12}
            justifyContent={"space-between"}
            marginBottom={"16px"}
          >
            <Grid item xs={10} sm={10} md={10} xl={10}>
              <Typography variant="h6" fontSize={"20px"}>
                Promotions and Announcements
              </Typography>
              <Typography variant="body1">
                Receive notifications for updates, promotions, and other
                information.
              </Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={
                  isChecked.mobileNotificationsPromotionsandAnnouncements
                }
                color="primary"
                size="medium"
                onChange={() => {
                  const newData = { ...isChecked };
                  newData.mobileNotificationsPromotionsandAnnouncements =
                    !newData.mobileNotificationsPromotionsandAnnouncements;
                  setChecked(newData);
                  updateData(newData);
                }}
              />
            </Grid>
          </Grid>
          <Divider orientation="horizontal" style={{ width: "100%" }} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Notification;
