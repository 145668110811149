import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Box,
  Modal,
  Stack,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customerList } from "../../redux/Customer/action";
import UserList from "./UserList";

import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import {
  APEX_AUTO_APP,
  SHOP_CREATED,
} from "../../config/dataService/constants";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  width: "100%",
  marginTop: "10px",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#777777de",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: "16px 16px 16px 0px",
    borderRadius: "100px",
    border: "1px solid #0000003a",
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    width: "100%",
    color: "rgba(0, 0, 0, 0.87)",
  },
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const NewChatList = ({ openNewChatModel, handleClose }) => {
  const dispatch = useDispatch();
  const [tabvalue, setTabValue] = React.useState(0);
  const [customers, setCustomers] = useState([]);
  const [page, setPage] = React.useState(1);
  const [typeValues, setTypeValues] = useState([SHOP_CREATED, APEX_AUTO_APP]);
  const [showLoader, setShowLoader] = useState(false);
  const [showBottomLoader, setShowBottomLoader] = useState(false);
  const [search, setSearch] = useState("");
  const params = {
    page,
    limit: 50,
    sortBy: "fullName",
    sortType: "ASC",
    createdFrom: typeValues,
    search,
    searchBy: "fullName",
  };
  const handleChangeTabValue = (event, newValue) => {
    console.log("newValue", newValue);
    setTabValue(newValue);
    setTypeValues(
      newValue == 0
        ? [APEX_AUTO_APP, SHOP_CREATED]
        : newValue == 1
        ? [SHOP_CREATED]
        : [APEX_AUTO_APP]
    );
    setCustomers([]);
    setPage(1);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const Allcustomers = useSelector((state) => state.customer.customerList);
  useEffect(() => {
    if (page == 1 || Allcustomers.pagination.totalPages >= page)
      fetchCustomerList(params);
  }, [typeValues, page]);

  const fetchCustomerList = async (params) => {
    if (page == 1) setShowLoader(true);
    else setShowBottomLoader(true);
    const resp = await dispatch(customerList(params));
    if (page > 1) {
      setCustomers([...customers, ...resp]);
    } else {
      setCustomers(resp);
    }
    setShowLoader(false);
    setShowBottomLoader(false);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      params.page = 1;
      fetchCustomerList(params);
    }, 400); // Set the debounce time (milliseconds)

    // Cleanup function to cancel the previous setTimeout
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const handleSearch = (event) => {
    setSearch(event.target.value);
    params.search = event.target.value;

    // fetchCustomerList(params);
  };
  const handleCloseModel = () => {
    handleClose();
    setSearch("");
  };
  return (
    <Modal
      open={openNewChatModel}
      onClose={handleCloseModel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          //   width: 400,
          bgcolor: "background.paper",
          // border: "2px solid #000",
          //   boxShadow: 24,
          pt: 2,
          px: 4,
          pb: 3,
          height: "fit-content",
          border: "none",
          padding: "24px",
          flexDirection: "row",
          width: "500px",
        }}
      >
        <AppBar
          variant="outlined"
          sx={{
            height: "64px",
            backgroundColor: "#002394",
            paddingRight: "0 !important",
          }}
        >
          <Toolbar variant="regular">
            <Typography
              variant="h6"
              gutterBottom={false}
              width={"98%"}
              fontSize={"20px"}
            >
              New Chat
            </Typography>
            <Stack direction={"row"} spacing={0}>
              <div
                onClick={handleCloseModel}
                style={{
                  cursor: "pointer",
                  color: "white",
                }}
              >
                <CloseIcon />
              </div>
            </Stack>
          </Toolbar>
        </AppBar>

        {/* <AppBar
          variant="elevation"
          style={{
            height: "64px",
            boxShadow: "none",
            backgroundColor: "#002394",
            padding: "18px",
            flexDirection: "row",
          }}
        >
          <Typography variant="h6" fontWeight={500} width={"100%"}>
            New Chat
          </Typography>
          <Stack direction={"row"} spacing={0}>
            <div onClick={handleClose} style={{ cursor: "pointer" }}>
              <CloseIcon />
            </div>
          </Stack>
        </AppBar> */}

        <Box
          overflow={"auto"}
          marginTop={"55px"}
          sx={{ maxHeight: "70vh", scrollbarWidth: "thin", overflow: "hidden" }}
        >
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
              sx={{ width: "100%" }}
              onChange={handleSearch}
            />
          </Search>
          <Tabs
            value={tabvalue}
            onChange={handleChangeTabValue}
            aria-label="basic tabs example"
            textColor="primary"
            variant="standard"
            sx={{ m: "16px 0" }}
            className="overflow-tabs"
          >
            <Tab label="All" {...a11yProps(0)} />

            <Tab label="SHOP Created" {...a11yProps(1)} />
            <Tab label="Apex Auto" {...a11yProps(2)} />
          </Tabs>
          <CustomTabPanel value={tabvalue} index={0}>
            <Box>
              <UserList
                customers={customers}
                showLoader={showLoader}
                setPage={setPage}
                showBottomLoader={showBottomLoader}
                handleCloseModel={handleCloseModel}
              />
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={tabvalue} index={1}>
            <Box>
              <UserList
                customers={customers}
                showLoader={showLoader}
                setPage={setPage}
                showBottomLoader={showBottomLoader}
                handleCloseModel={handleCloseModel}
              />
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={tabvalue} index={2}>
            <Box>
              <UserList
                customers={customers}
                showLoader={showLoader}
                setPage={setPage}
                showBottomLoader={showBottomLoader}
                handleCloseModel={handleCloseModel}
              />
            </Box>
          </CustomTabPanel>
        </Box>
      </Box>
    </Modal>
  );
};

export default NewChatList;
