import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
// import Link from "@mui/material/Link";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  dismissMergeCustomers,
  getMergeCustomerList,
} from "../../redux/Customer/action";
import Loader from "../Common/Loader";
import SuccessMessageModal from "../Common/SuccessMessage";
import "./Merge.css";
const MergeCustomers = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("firstName");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [listData, setListData] = useState([]);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [dismissCustomerData, setDismissCustomerData] = React.useState("");
  const [openRemoveMerge, setOpenRemoveMerge] = useState(false);

  const [anchorElFilter, setAnchorElFilter] = React.useState(null);
  const filters = Boolean(anchorElFilter);

  let params = {
    page,
    limit,
    option: selectedFilter,
  };
  const handleClickss = (event) => {
    setAnchorElFilter(event.currentTarget);
  };
  const handleCloseFilter = () => {
    setAnchorElFilter(null);
  };
  useEffect(() => {
    setPage(1);
    params.page = 1;
    params.option = selectedFilter;
    setListData([]);
    fetchMergeList(params);
  }, [selectedFilter]);
  const fetchMergeList = async (params) => {
    setShowLoader(true);
    await dispatch(getMergeCustomerList(params));
    setShowLoader(false);
  };

  const list = useSelector((state) => state.customer.mergeCustomerList);

  useEffect(() => {
    console.log();
    if (list?.data) {
      if (list?.totalCount == 0) {
        setListData([]);
      } else {
        setListData([...listData, ...list?.data]);
      }
    }
  }, [list]);

  const handleScroll = () => {
    const isAtBottom =
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight;

    if (isAtBottom && !showLoader && page < list?.totalPages) {
      params.page = page + 1;
      setPage(page + 1);
      fetchMergeList(params);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [showLoader, page]);
  localStorage.removeItem("mergeCustomeDetails");

  const handleOpenRemoveMerge = () => {
    setOpenRemoveMerge(true);
  };
  const handleCloseRemoveMerge = () => {
    setOpenRemoveMerge(false);
    setDismissCustomerData("");
  };

  const handleDismiss = async (item) => {
    setShowLoader(true);
    let customerIds;
    customerIds = item?.customers?.map((data) => data?._id);
    let payload = {
      customerId: customerIds,
    };
    const resp = await dispatch(dismissMergeCustomers(payload));
    if (resp) {
      setListData([]);
      setOpenSuccessModal(true);
      setShowLoader(false);
      handleCloseRemoveMerge();
      setSuccessMessage("Dismiss Successfully.");
      fetchMergeList(params);
    }
  };
  return (
    <>
      <Box padding={"24px 24px 24px 20px"}>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography sx={{ fontSize: "34px", fontWeight: 500 }}>
              Merge Customers
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="body1" color={"rgba(0,0,0,0.6)"} mt={"14px"}>
              Merge customers is activated when our system detects duplicate
              entries in your customer list.
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <div style={{ marginLeft: "-10px" }}>
              <Button
                id="basic-button"
                aria-controls={filters ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={filters ? "true" : undefined}
                endIcon={<ExpandMoreIcon />}
                onClick={handleClickss}
                style={{ color: "black", marginTop: "10px" }}
              >
                <Typography fontSize={"14px"} fontWeight={500}></Typography>
                Select TYPE
              </Button>

              <Menu
                id="basic-menu"
                anchorEl={anchorElFilter}
                open={filters}
                onClose={handleCloseFilter}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                onClick={handleCloseFilter}
              >
                <MenuItem
                  // onClick={() => handleMenuItemClickPrice("no_price")}
                  className="menuitems-wrapper"
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedFilter("firstName");
                  }}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={selectedFilter == "firstName"}

                        // checked={selectedPriceType.includes("no_price")}
                        // onClick={() => handleMenuItemClickPrice("no_price")}
                      />
                    }
                    label="Name"
                  />
                </MenuItem>
                <MenuItem
                  // onClick={() => handleMenuItemClickPrice("no_price")}
                  className="menuitems-wrapper"
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedFilter("email");
                  }}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={selectedFilter == "email"}

                        // onClick={() => handleMenuItemClickPrice("no_price")}
                      />
                    }
                    label={"Email"}
                  />
                </MenuItem>{" "}
                <MenuItem
                  // onClick={() => handleMenuItemClickPrice("no_price")}
                  className="menuitems-wrapper"
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedFilter("phoneNumber");
                  }}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={selectedFilter == "phoneNumber"}

                        // onClick={() => handleMenuItemClickPrice("no_price")}
                      />
                    }
                    label={"Phone"}
                  />
                </MenuItem>
              </Menu>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ alignItems: "center", marginTop: "14px" }}
          spacing={1}
        >
          <Grid
            item
            // xl={1.4}
            // lg={2.1}
            // md={2.6}
            // sm={12}
            // xs={12}
          >
            <Typography variant="body1" fontWeight={500} fontSize={20}>
              Filter Selected:
            </Typography>
          </Grid>
          <Grid item>
            <Button size="medium" variant="contained" color="warning">
              {selectedFilter == "email" && "Email"}
              {selectedFilter == "phoneNumber" && "Phone Number"}
              {selectedFilter == "firstName" && "Name"}
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography mt={6} fontWeight={500} fontSize={20}>
              Number of duplicates: {list?.totalGroups ? list?.totalGroups : 0}
            </Typography>
          </Grid>

          {listData?.map((d) => (
            <Grid item lg={12} md={12} sm={12} xs={12} mt={"16px"} key={d?._id}>
              <Box bgcolor={"white"} p={"16px"} boxShadow={2}>
                {d?.customers?.map((cust) => (
                  <Typography
                    variant={"body1"}
                    className={"boxContent"}
                    key={cust?._id}
                  >
                    {cust?.firstName} {cust?.lastName}
                  </Typography>
                ))}

                <Grid item>
                  <Stack className="buttons" gap={2} style={{ zIndex: 1 }}>
                    <div>
                      <Button
                        color="error"
                        size="large"
                        variant="contained"
                        onClick={() => {
                          // handleDismiss(d);
                          setDismissCustomerData(d);
                          handleOpenRemoveMerge();
                        }}
                      >
                        {" "}
                        DISMISS
                      </Button>
                    </div>
                    <div>
                      <Button
                        color="primary"
                        size="large"
                        variant="contained"
                        onClick={() => {
                          navigate(`/customers/merge/new/`);
                          localStorage.setItem(
                            "mergeCustomeDetails",
                            JSON.stringify({
                              id: d?.customers?.map((data) => data?._id),
                            })
                          );
                        }}
                      >
                        {" "}
                        REVIEW
                      </Button>
                    </div>
                  </Stack>
                </Grid>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Dialog
        open={openRemoveMerge}
        onClose={handleCloseRemoveMerge}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ fontWeight: "400", fontSize: "24px" }}
        >
          Dismiss Customer
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseRemoveMerge}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon className="closeButton" />
        </IconButton>
        <DialogContent style={{ paddingTop: "0px" }}>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to dismiss this customer for merge?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ mb: "10px" }}>
          <Button onClick={handleCloseRemoveMerge} variant="outlined">
            CANCEL
          </Button>
          <Button
            onClick={() => {
              handleDismiss(dismissCustomerData);
            }}
            autoFocus
            color="error"
            variant="contained"
          >
            DELETE
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
      <Loader showLoader={showLoader} />
    </>
  );
};

export default MergeCustomers;
