import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Modal,
  Pagination,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TablePagination,
  TableSortLabel,
  TextField,
  Toolbar,
  Typography,
  alpha,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import AddNewServiceModal from "../../modal/AddNewServiceModal";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteServices,
  getShopServicesListing,
  updateShopServiceStatus,
} from "../../../redux/Account/ServiceOffered/action";
import FormatNumber from "../../../components/FormatNumber/FormatNumber";
import SuccessMessageModal from "../../Common/SuccessMessage";
import { green } from "@mui/material/colors";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const select_category = [
  { label: "Inglourious Basterds", year: 2009 },
  { label: "Snatch", year: 2000 },
  { label: "3 Idiots", year: 2009 },
  { label: "Monty Python and the Holy Grail", year: 1975 },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function ServiceTable({
  selectedCategory,
  selectedPriceType,
  selectedIds,
  searchValue,
}) {
  const Servicecategories = useSelector(
    (state) => state?.account?.shopServicesListing
  );
  const pageRef = useRef(0);

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [openDeleteConformModal, setOpenDeleteConformModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [id, setId] = useState({});
  const [loader, setLoader] = useState(false);
  const [sortName, setSortName] = React.useState("ASC");
  const [sortCategory, setSortCategory] = React.useState("ASC");

  const [sortByName, setSortByName] = React.useState("name");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [switchValues, setSwitchValues] = useState(Servicecategories);
  const limit = 10;

  useEffect(() => {
    getShopServicesListings(1);
  }, [searchValue, selectedIds, selectedPriceType]);

  const getShopServicesListings = async (
    paginationPage,
    sortedType,
    sortBy,
    rowsPerPages
  ) => {
    if (
      // searchValue == "" &&
      selectedIds.length == 0 &&
      selectedPriceType.length == 0
    )
      setLoader(true);

    let res = await dispatch(
      getShopServicesListing(
        rowsPerPages ? rowsPerPages : rowsPerPage,
        paginationPage ? paginationPage : page ? page : 1,
        searchValue,
        selectedIds,
        selectedPriceType,
        sortedType,
        sortBy
      )
    );

    if (res) {
      setTotalPages(res.totalCount);
      setSwitchValues(res.data);
    }
    if (
      // searchValue == "" &&
      selectedIds.length == 0 &&
      selectedPriceType.length == 0
    )
      setLoader(false);
  };

  const DeleteClicked = async () => {
    setLoader(true);
    const res = await dispatch(deleteServices(id));
    if (res) {
      getShopServicesListings(
        page,
        sortName != "" ? sortName : sortCategory,
        sortByName
      );
      handleClose();

      setOpenSuccessModal(true);
      setSuccessMessage("Service deleted successfully");
      setLoader(false);
    }
  };

  const beforeDeleteClicked = (row) => {
    setOpenDeleteConformModal(true);
    setId(row._id);
  };

  const handleSortByName = (status, sortBy) => {
    getShopServicesListings(page, status, sortBy);
    if (sortBy == "name") {
      if (status == "DSC") {
        setSortName("ASC");
      } else {
        setSortName("DSC");
      }
      setSortCategory("");
    } else {
      if (status == "DSC") {
        setSortCategory("ASC");
      } else {
        setSortCategory("DSC");
      }
      setSortName("");
    }
  };
  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 5));
    setRowsPerPage(event.target.value);

    setPage(0);
    pageRef.current = 0;

    getShopServicesListings(
      1,
      sortName != "" ? sortName : sortCategory,
      sortByName,
      event.target.value
    );
  };
  const SwitchToggle = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: green[500],
      "&:hover": {
        backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: green[600],
    },
  }));
  const handleChangeToggle = async (value, id, index) => {
    setLoader(true);
    const resp = await dispatch(
      updateShopServiceStatus({ showShopServices: value }, id)
    );
    if (resp) {
      let allSwitchValues = [...switchValues];
      allSwitchValues[index].showShopServices = value;
      setSwitchValues(allSwitchValues);
    }
    setLoader(false);
  };

  useEffect(() => {
    setSwitchValues(Servicecategories?.data);
  }, [Servicecategories]);

  return (
    <>
      <Dialog fullScreen={fullScreen} open={loader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <Table sx={{ minWidth: 650 }} aria-label="caption table" padding="24px">
        <TableHead>
          <TableRow style={{ fontWeight: 500 }}>
            <TableCell>
              <TableSortLabel
                active={true}
                direction={sortName == "DSC" ? "asc" : "desc"}
                onClick={() => {
                  setSortByName("name");
                  handleSortByName(sortName == "DSC" ? "DSC" : "ASC", "name");
                }}
              >
                Name
              </TableSortLabel>{" "}
            </TableCell>
            <TableCell>
              {" "}
              <TableSortLabel
                active={true}
                direction={sortCategory == "DSC" ? "asc" : "desc"}
                onClick={() => {
                  setSortByName("category");
                  handleSortByName(
                    sortCategory == "DSC" ? "DSC" : "ASC",
                    "category"
                  );
                }}
              >
                {" "}
                Category
              </TableSortLabel>
            </TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Service Time (Hrs)</TableCell>
            {/* VISIBLE SHOP PROFILE SWITCH BUTTON CR POINT 652 */}

            <TableCell>Visible on Apex Auto App</TableCell>

            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Servicecategories?.data?.map((row, index) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.serviceSubCategory.name}
              </TableCell>
              <TableCell align="left">{row.serviceCategory.name}</TableCell>
              <TableCell align="left">
                {/* {row?.priceMin} - {row?.priceMax} */}
                {row.priceType == "range" ? (
                  <>
                    <FormatNumber inputValue={row?.priceMin.toString()} /> -{" "}
                    <FormatNumber inputValue={(row?.priceMax).toString()} />
                  </>
                ) : row.priceType == "fixed" ? (
                  <FormatNumber inputValue={(row?.fixedPrice).toString()} />
                ) : (
                  "No Price"
                )}
              </TableCell>
              <TableCell align="left">{row.serviceTimeInHrs}</TableCell>
              {/* VISIBLE SHOP PROFILE SWITCH BUTTON CR POINT 652 */}

              <TableCell align="left">
                <SwitchToggle
                  checked={switchValues?.[index]?.showShopServices}
                  onChange={(e) =>
                    handleChangeToggle(e.target.checked, row?._id, index)
                  }
                />
              </TableCell>

              <TableCell align="left">
                <Box>
                  <Stack direction="row" spacing={1}>
                    <div>
                      <Button
                        variant="contained"
                        style={{ lineHeight: "24px" }}
                        onClick={() => {
                          handleOpen();
                          setEditData(row);
                          setId(row._id);
                        }}
                      >
                        {" "}
                        Edit
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="contained"
                        size="medium"
                        color="error"
                        onClick={() => {
                          beforeDeleteClicked(row);
                        }}
                      >
                        {" "}
                        Delete
                      </Button>
                      <SuccessMessageModal
                        message={successMessage}
                        open={openSuccessModal}
                        setOpenSuccessModal={setOpenSuccessModal}
                      />
                    </div>
                  </Stack>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>{" "}
      {/* <Pagination
        count={totalPages}
        page={page}
        onChange={(event, page) => {
          setPage(page);
          getShopServicesListings(
            page,
            sortName != "" ? sortName : sortCategory,
            sortByName
          );
        }}
        color="primary"
        showFirstButton
        showLastButton
        style={{ float: "right", marginTop: "25px" }}
      /> */}
      {loader ? (
        ""
      ) : (
        <TablePagination
          rowsPerPageOptions={[
            5, 10, 25,
            // { label: "All", value: 1000 },
          ]}
          colSpan={3}
          count={totalPages}
          rowsPerPage={rowsPerPage}
          // page={page}
          page={pageRef.current}
          slotProps={{
            select: {
              "aria-label": "rows per page",
            },
            actions: {
              showFirstButton: true,
              showLastButton: true,
            },
          }}
          onPageChange={(event, page) => {
            setPage(page);
            pageRef.current = page;

            getShopServicesListings(
              page + 1,
              sortName != "" ? sortName : sortCategory,
              sortByName
            );
          }}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className="pagination-right"
        />
      )}
      <Dialog
        open={openDeleteConformModal}
        onClose={() => setOpenDeleteConformModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this service?{" "}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="btn-text-size-manage"
            onClick={() => {
              setOpenDeleteConformModal(false);
            }}
          >
            No
          </Button>
          <Button
            className="btn-text-size-manage"
            onClick={() => {
              DeleteClicked();
              setOpenDeleteConformModal(false);
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <AddNewServiceModal
        open={open}
        handleClose={handleClose}
        openFor="edit"
        editData={editData}
        page={page}
        limit={limit}
        searchValue={searchValue}
        getShopServicesListing={getShopServicesListings}
        deleteData={DeleteClicked}
        selectedIds={selectedIds}
        selectedPriceType={selectedPriceType}
      />
    </>
  );
}
