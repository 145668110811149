import { useTheme } from "@emotion/react";
import { InfoOutlined } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import FormatNumber, {
  FormatNumberForKm,
} from "../../../components/FormatNumber/FormatNumber";
import { acceptDeclineRequest } from "../../../redux/Dashboard/action";
import { estimateRequestData } from "../../../redux/Estimate/action";
import { formatPhoneNumber } from "../../Common/FormatPhoneNumber";

const styleServiceName = {
  padding: "16px",
  backgroundColor: "#002394",
  color: "white",
  borderTopLeftRadius: "4px",
  borderTopRightRadius: "4px",
  fontSize: "20px",
  fontWeight: 500,
};

const EstimatesRequest = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  const { id } = useParams();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [getEstimateData, setGetEstimateData] = React.useState("");
  console.log("🚀 ~ EstimatesRequest ~ getEstimateData:", getEstimateData);
  const open1 = Boolean(anchorEl);
  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    estimateRequest();
  }, []);

  const estimateRequest = async () => {
    setShowLoader(true);
    const data = await dispatch(estimateRequestData(id));
    if (data) {
      setGetEstimateData(data);
      setShowLoader(false);
    }
  };

  const handleStatus = async (status) => {
    await requestacceptDecline(id, status);
  };

  const requestacceptDecline = async (id, status) => {
    setShowLoader(true);
    const payload = {
      status: status,
    };
    const data = await dispatch(acceptDeclineRequest(id, payload));
    if (data) {
      setShowLoader(false);
      // handleClose();
      setAnchorEl(null);
    }
  };

  return (
    <>
      <Box padding={"32px 24px"} sx={{ bgcolor: "background.main" }}>
        {" "}
        <Grid container sx={{ padding: "16px 0px" }} gap={"8px"}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div role="presentation" onClick={handleClick}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  to="/estimates/all"
                  style={{
                    color: "black",
                    opacity: "0.6",
                    textDecoration: "none",
                  }}
                >
                  Estimates
                </Link>
                <Link
                  to="/estimates/requests"
                  style={{
                    color: "black",
                    opacity: "0.6",
                    textDecoration: "none",
                  }}
                >
                  Request
                </Link>
                <Link
                  to="/estimates/all"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  {getEstimateData && getEstimateData?.estimateRequestNumber}
                </Link>
              </Breadcrumbs>
            </div>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h4" fontWeight={500}>
              {getEstimateData && getEstimateData?.estimateRequestNumber}
            </Typography>
          </Grid>
        </Grid>
        {getEstimateData?.status == "request" && (
          <Grid container spacing={2} justifyContent="end">
            <Grid item>
              <Stack direction={"row"} spacing={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  id="demo-positioned-button"
                  aria-controls={open1 ? "demo-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open1 ? "true" : undefined}
                  onClick={handleClick1}
                  size="large"
                >
                  more actions
                </Button>
                <Menu
                  style={{ width: "200px" }}
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl}
                  open={open1}
                  onClose={handleClose1}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleStatus("accept");
                    }}
                  >
                    <ThumbUpIcon style={{ marginRight: "10px" }} /> Accept
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleStatus("decline");
                    }}
                  >
                    <ThumbDownIcon style={{ marginRight: "10px" }} />
                    Decline
                  </MenuItem>
                </Menu>
              </Stack>
            </Grid>
          </Grid>
        )}
        {getEstimateData?.status == "accept" && (
          <>
            <Grid container spacing={2} justifyContent="end">
              <Grid item>
                <Stack direction={"row"} spacing={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<EditIcon />}
                    size="large"
                    onClick={() => {
                      navigate(`/estimates/estimate-request-edit/${id}`);
                    }}
                  >
                    EDIT ESTIMATE
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </>
        )}
        <Grid
          container
          padding={"24px"}
          margin={"32px 0"}
          boxShadow={2}
          gap={2}
        >
          <Grid container item spacing={2} justifyContent="space-between">
            <Grid
              item
              style={{ color: "white" }}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              xl={12}
            >
              <Stack
                direction={"row"}
                spacing={2}
                style={{ backgroundColor: "#FEBBC3", fontSize: "16px" }}
                alignItems={"center"}
                padding={"8px 8px"}
                borderRadius={"4px"}
                color="#000000DE"
              >
                <Stack>
                  <InfoOutlined />
                </Stack>
                <Stack direction={"column"} spacing={0}>
                  <Typography variant="subtitle1" fontWeight={500}>
                    Estimate Request{" "}
                    {getEstimateData?.status == "decline"
                      ? "Declined"
                      : getEstimateData?.status == "accept"
                      ? "Accepted (Customer Waiting for Complete Estimate)"
                      : ""}{" "}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <Grid
            container
            item
            justifyContent={"start"}
            marginTop={"32px"}
            columnGap={4}
          >
            <Grid item lg={5} xl={5} md={5} sm={2.7} xs={2.7}>
              <Typography variant="body1" fontWeight={500} fontSize={"20px"}>
                Note from {getEstimateData?.customer?.firstName}
              </Typography>
              <Grid
                item
                lg={12}
                xl={12}
                md={12}
                sm={8.9}
                xs={8.9}
                style={{
                  backgroundColor: "#f0f0f0",
                  padding: "10px",
                  width: "200%",
                  height: "100px",
                  marginTop: "10px",
                }}
              >
                <Stack spacing={1}>
                  <Typography
                    variant="body1"
                    fontWeight={400}
                    maxWidth={"100%"}
                    fontSize={"16px"}
                  >
                    {getEstimateData && getEstimateData?.noteForMechanic
                      ? getEstimateData?.noteForMechanic
                      : " "}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Divider style={{ width: "100%" }} />
          <Grid
            container
            item
            spacing={2}
            justifyContent="space-between"
            padding={"24px 0"}
          >
            <Grid item lg={7} md={12} sm={12} xs={12}>
              <Typography variant="h3" fontWeight={400}>
                Estimate{" "}
              </Typography>
            </Grid>
            <Grid item>
              {/* <Stack spacing={0}> */}
              <Typography
                variant="body1"
                fontWeight={500}
                fontSize={"24px"}
                style={{ marginTop: "13px" }}
              >
                {getEstimateData && getEstimateData?.estimateRequestNumber}
              </Typography>
              {/* </Stack> */}
            </Grid>
          </Grid>
          <Divider style={{ width: "100%" }} />
          <Grid container item padding={"24px 0"}>
            <Grid item>
              <Stack>
                <Typography variant="h6">
                  {getEstimateData && getEstimateData?.shop?.shopName}
                </Typography>
                <Typography variant="body1" fontWeight={400}>
                  {getEstimateData &&
                    getEstimateData?.shop?.address +
                      ", " +
                      getEstimateData?.shop?.city?.city +
                      ", " +
                      getEstimateData?.shop?.province?.province +
                      ", " +
                      getEstimateData?.shop?.postalCode}
                </Typography>
                <Typography variant="body1" fontWeight={400}>
                  {formatPhoneNumber(getEstimateData?.shop?.phoneNumber)}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid
            container
            item
            justifyItems={"space-between"}
            padding={"24px 0"}
          >
            <Grid
              container
              item
              justifyItems={"space-between"}
              spacing={{ lg: 2, md: 2, sm: 2, xs: 2 }}
            >
              <Grid item lg={4} md={4.5} sm={4.5} xs={4.5}>
                <Stack>
                  <Typography variant="h6">Dates</Typography>
                  <Stack direction={"row"} spacing={1}>
                    <Typography variant="body1" fontWeight={500}>
                      Requested Date:
                    </Typography>
                    <Typography variant="body1" fontWeight={400}>
                      {getEstimateData &&
                        moment(getEstimateData?.estimateRequestDate).format(
                          "MMM Do, YYYY"
                        )}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item lg={4} md={3.5} sm={3.5} xs={3.5}>
                <Stack>
                  <Typography variant="h6" fontWeight={500}>
                    Customer Information
                  </Typography>
                  <Typography variant="body1" fontWeight={400}>
                    {getEstimateData && getEstimateData?.customer?.fullName}
                  </Typography>
                  <Typography variant="body1" fontWeight={400}>
                    {formatPhoneNumber(getEstimateData?.customer?.phoneNumber)}
                  </Typography>
                  <Typography variant="body1" fontWeight={400}>
                    {getEstimateData && getEstimateData?.customer?.email}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Typography variant="h6">Vehicle Information</Typography>
                <Typography variant="body1" fontWeight={400}>
                  {/* 2023 Audi Q5 */}
                  {getEstimateData &&
                    getEstimateData?.vehicle?.year +
                      " " +
                      getEstimateData?.vehicle?.vehicleMake?.name +
                      " " +
                      getEstimateData?.vehicle?.vehicleModel?.name +
                      " " +
                      getEstimateData?.vehicle?.vehicleModelTrim?.name}
                </Typography>

                <Stack direction={"row"} spacing={1}>
                  <Typography variant="body1" fontWeight={500}>
                    VIN:
                  </Typography>
                  <Typography variant="body1" fontWeight={400}>
                    {getEstimateData && getEstimateData?.vehicle?.vin
                      ? getEstimateData?.vehicle?.vin
                      : " - "}
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <Typography variant="body1" fontWeight={500}>
                    Licence Plate Number:
                  </Typography>
                  <Typography variant="body1" fontWeight={400}>
                    {getEstimateData && getEstimateData?.vehicle?.licenseNumber
                      ? getEstimateData?.vehicle?.licenseNumber
                      : " - "}
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <Typography variant="body1" fontWeight={500}>
                    Odometer:
                  </Typography>
                  <Typography variant="body1" fontWeight={400}>
                    {/* {getEstimateData && getEstimateData?.vehicle?.odo ? getEstimateData?.vehicle?.odo + " km " : " - "} */}
                    {FormatNumberForKm(getEstimateData?.vehicle?.odo)}km
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h6">
                Total Services:{" "}
                {getEstimateData && getEstimateData?.services.length}
              </Typography>
            </Grid>
            {getEstimateData &&
              getEstimateData?.services.map((item, index) => {
                return (
                  <>
                    <Grid
                      container
                      item
                      style={{
                        backgroundColor: "#c5d0da",
                        color: "black",
                        marginTop: "20px",
                      }}
                    >
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography style={styleServiceName}>
                          {item?.name}
                        </Typography>
                      </Grid>
                    </Grid>

                    {item?.pricingType == "fixed" ? (
                      <Grid container item>
                        <Grid
                          item
                          xl={12}
                          md={12}
                          sm={12}
                          xs={12}
                          boxShadow={0}
                        >
                          <TableContainer style={{ minWidth: "850px" }}>
                            <Table aria-label="caption table" padding="16px">
                              <TableHead style={{ backgroundColor: "#f4f6f8" }}>
                                <TableRow
                                  style={{ fontWeight: 500, fontSize: "14px" }}
                                >
                                  <TableCell>No.</TableCell>
                                  <TableCell width={"600px"}>
                                    {" "}
                                    Service Name
                                  </TableCell>
                                  <TableCell>Quantity</TableCell>
                                  <TableCell> Cost</TableCell>
                                  <TableCell>Amount</TableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                <TableRow key={item.no}>
                                  <TableCell component="th" scope="row">
                                    1
                                  </TableCell>
                                  <TableCell align="left">
                                    {item?.name}
                                  </TableCell>
                                  <TableCell align="left">1</TableCell>
                                  <TableCell align="left">
                                    {" "}
                                    <FormatNumber
                                      inputValue={item?.fixedPrice}
                                    />
                                  </TableCell>
                                  <TableCell align="left">
                                    {" "}
                                    <FormatNumber
                                      inputValue={item?.fixedPrice}
                                    />
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Grid>
                    ) : item?.pricingType == "range" ? (
                      <Grid container item>
                        <Grid
                          item
                          xl={12}
                          md={12}
                          sm={12}
                          xs={12}
                          boxShadow={0}
                        >
                          <TableContainer style={{ minWidth: "850px" }}>
                            <Table aria-label="caption table" padding="16px">
                              <TableHead
                                style={{
                                  backgroundColor: "#f4f6f8",
                                  fontWeight: "bold",
                                }}
                              >
                                <TableRow style={{ fontWeight: "bold" }}>
                                  <TableCell width={"306px"}>No.</TableCell>
                                  <TableCell width={"600px"}>
                                    {" "}
                                    Service Name
                                  </TableCell>
                                  <TableCell>Cost</TableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                <TableRow key={item.no}>
                                  <TableCell component="th" scope="row">
                                    1
                                  </TableCell>
                                  <TableCell align="left">
                                    {item?.name}
                                  </TableCell>
                                  <TableCell align="left">
                                    {"$" +
                                      item?.priceMin +
                                      " - " +
                                      "$" +
                                      item?.priceMax}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Grid>
                    ) : item?.pricingType == "labor_&_parts" ? (
                      <Grid container item>
                        <Grid
                          item
                          xl={12}
                          md={12}
                          sm={12}
                          xs={12}
                          boxShadow={0}
                        >
                          <TableContainer style={{ minWidth: "850px" }}>
                            <Table aria-label="caption table" padding="16px">
                              <TableHead
                                style={{
                                  backgroundColor: "#f4f6f8",
                                  fontWeight: "bold",
                                }}
                              >
                                <TableRow style={{ fontWeight: "bold" }}>
                                  <TableCell>No.</TableCell>
                                  <TableCell width={"600px"}>
                                    {" "}
                                    Description
                                  </TableCell>
                                  <TableCell> Quantity</TableCell>
                                  <TableCell> Cost</TableCell>
                                  <TableCell> Amount</TableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                {item?.labor && (
                                  <TableRow key={item.no}>
                                    <TableCell component="th" scope="row">
                                      1
                                    </TableCell>
                                    <TableCell align="left">Labor</TableCell>
                                    <TableCell align="left">
                                      {item?.labor?.qty}
                                    </TableCell>
                                    <TableCell align="left">
                                      <FormatNumber
                                        inputValue={item?.labor?.ratePerHour}
                                      />
                                    </TableCell>
                                    <TableCell align="left">
                                      <FormatNumber
                                        inputValue={item?.labor?.totalAmount}
                                      />
                                    </TableCell>
                                  </TableRow>
                                )}

                                {item?.parts.map((row, index) => (
                                  <TableRow key={row.no}>
                                    <TableCell component="th" scope="row">
                                      {index + 2}
                                    </TableCell>
                                    <TableCell align="left">
                                      <div>{row.partName}</div>
                                      <div>
                                        Part Provider : {row.partsProvider}
                                      </div>
                                      <div>Part Number : {row.partNumber}</div>
                                      <div>{row.description}</div>
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.qty}
                                    </TableCell>
                                    <TableCell align="left">
                                      <FormatNumber inputValue={row?.cost} />
                                    </TableCell>
                                    <TableCell align="left">
                                      <FormatNumber
                                        inputValue={row?.totalAmount}
                                      />
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container item>
                        <Grid
                          item
                          xl={12}
                          md={12}
                          sm={12}
                          xs={12}
                          boxShadow={0}
                        >
                          <TableContainer style={{ minWidth: "850px" }}>
                            <Table aria-label="caption table" padding="16px">
                              <TableHead
                                style={{
                                  backgroundColor: "#f4f6f8",
                                  fontWeight: "bold",
                                }}
                              >
                                <TableRow style={{ fontWeight: "bold" }}>
                                  <TableCell>No.</TableCell>
                                  <TableCell width={"600px"}>
                                    {" "}
                                    Service Name
                                  </TableCell>
                                  <TableCell> Quantity</TableCell>
                                  <TableCell> Cost</TableCell>
                                  <TableCell> Amount</TableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                <TableRow key={item.no}>
                                  <TableCell component="th" scope="row">
                                    1
                                  </TableCell>
                                  <TableCell align="left">
                                    {item?.name}
                                  </TableCell>
                                  <TableCell align="left">1</TableCell>
                                  <TableCell align="left">-</TableCell>
                                  <TableCell align="left">-</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Grid>
                    )}
                  </>
                );
              })}
          </Grid>
        </Grid>
      </Box>
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EstimatesRequest;
