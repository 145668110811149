import { Suspense, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";

import { CometChatLocalize, CometChatUIKit } from "@cometchat/chat-uikit-react";
import { UIKitSettingsBuilder } from "@cometchat/uikit-shared";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { createContext } from "react";
import { useSelector } from "react-redux";
import { Toaster } from "sonner";
import PrivateRoutes from "./routes/PrivateRoutes";
import PublicRoutes from "./routes/PublicRoutes";

import { useDispatch } from "react-redux";
import {
  COMET_CHAT_APP_ID,
  COMET_CHAT_AUTH_KEY,
  COMET_CHAT_REGION,
} from "./config/dataService/dataService";
import { createUser } from "./redux/CometChat/action";
export const Context = createContext("Default Value");

/* Constants */
export const ACTION_BLUE = "#006CDD";
export const REQUIRED_RED = "#D32F2F";
export const ESTIMATE_REQUEST_COLOR = "#FEBBC3";
export const ESTIMATE_INVOICE_SENT = "#B0BDFC";
export const ESTIMATE_INVOICE_DRAFT = "#FDD79C";
export const ESTIMATE_INVOICE_TABLE_ROW = "c5d0da";

export const WAITING_FOR_DROP_OFF = "#75DBFF";
export const VEHICLES_DROPPED_OFF = "#FFC30F";
export const IN_PROGRESS = "#FF9E31";
export const READY_FOR_PICK_UP = "#5CE469";

export const APPROVALS_WAITING = "#FFD60A";
export const APPROVALS_ACCEPTED = "#30D158";
export const APPROVALS_DECLINED = "#FF2727";
// const stripePromise = loadStripe(
//   "pk_test_51Nhd0yJxzO4fXIFTmBd94ZK6Z7nYJ41yucmXrULGoURy4SJsphOPa53r83RUIS8hvi8M4RxSfukCZNtjsRC4BOyx00NSroIpzx"
// );
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_KEY
  // "pk_test_51Nhd0yJxzO4fXIFTmBd94ZK6Z7nYJ41yucmXrULGoURy4SJsphOPa53r83RUIS8hvi8M4RxSfukCZNtjsRC4BOyx00NSroIpzx"
); /* Theme */
const theme = createTheme({
  palette: {
    /* Call to Action blue */
    primary: {
      main: "rgba(0, 108, 221, 1)",
      light: "rgba(0, 108, 221, 0.6)",
    },
    /* Apex dark blue */
    secondary: { main: "rgba(0, 35, 148, 1)" },
    tabColor: { main: "#2196f3" },

    background: {
      main: "rgba(250, 251, 255, 1)" /* almost white rgba(250, 251, 255, 1) */,
      dark_grey: "rgba(241, 241, 241, 1)" /* dark grey */,
      disabled_fields: "rgba(160, 170, 180, 0.1)" /* light grey */,
    },
    accent: {
      main: "#75DBFF" /* sky blue - used for borders */,
      red: "#D32F2F" /* red - used for required fields */,
      yellow: "#FFD60A" /* red - used for approvals waiting fields */,
    },
    // error: "red",
  },
  /* Custom Theme for MUI Chip used for Estimates, Invoices, Approvals and Customer Profile */
  components: {
    MuiChip: {
      styleOverrides: {
        colorDefault: {
          backgroundColor:
            "rgba(0, 0, 0, 1)" /* black - used for deferred status in approvals */,
          color: "rgba(255, 255, 255, 1)",
          fontWeight: "500",
        },
        colorPrimary: {
          backgroundColor:
            "rgba(176, 189, 252, 1)" /* purple - used in sent status in estimates and invoices */,
          color: "rgba(0, 0, 0, 1)",
          fontWeight: "500",
        },
        colorSecondary: {
          backgroundColor:
            "rgba(253, 215, 156, 1)" /*  mustard yellow - used for draft status in estimates and invoices */,
          color: "rgba(0, 0, 0, 1)",
          fontWeight: "500",
        },
        colorSuccess: {
          backgroundColor:
            "rgba(48, 209, 88, 1)" /*  green - used for approvals approved status */,
          color: "rgba(255, 255, 255, 1)",
          fontWeight: "500",
        },
        colorWarning: {
          backgroundColor:
            "rgba(255, 214, 10, 1)" /* yellow - used for approvals waiting status */,
          color: "rgba(0, 0, 0, 1)",
          fontWeight: "500",
        },
        colorInfo: {
          backgroundColor:
            "rgba(117, 219, 255, 1)" /* sky blue used to identify if customer is shop created or apex auto  */,
          color: "rgba(0, 35, 148, 1)" /* apex dark blue */,
          fontWeight: "500",
        },
        colorError: {
          backgroundColor:
            "rgba(255, 39, 39, 1)" /* red  - used for approvals declined status */,
          color: "rgba(255, 255, 255, 1)",
          fontWeight: "500",
        },
        shopCreatedChip: {
          backgroundColor: " rgba(117, 219, 255, 0.5)",
          color: "rgba(0, 0, 0, 1)",
        },
        shopImportedChip: {
          backgroundColor: "rgba(225, 208, 253, 1)",
          color: "rgba(62, 28, 118, 1)",
        },
        apexAutoChip: {
          backgroundColor: "rgba(239, 239, 239, 1)",
          color: "rgba(0, 35, 148, 1)",
        },
      },
    },
  },
});

function App() {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const loggedin = useSelector((state) => state.auth.login);
  const errorMessage = useSelector((state) => state.auth.errorMessage);
  const error = useSelector((state) => state.auth.error);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isOpenSideBar, setIsOpenSidebar] = useState(true);
  const UIKitSettings = new UIKitSettingsBuilder()
    .setAppId(COMET_CHAT_APP_ID)
    .setRegion(COMET_CHAT_REGION)
    .setAuthKey(COMET_CHAT_AUTH_KEY)
    .subscribePresenceForFriends()
    .build();

  CometChatUIKit.init(UIKitSettings).then(
    () => {
      console.log("CometChat Initialized Successfully");
    },
    (error) => {
      console.log("CometChat Initialization Failed with error:", error);
    }
  );

  const options = {
    // passing the client secret obtained from the server
    clientSecret: "{{CLIENT_SECRET}}",
  };

  // useEffect(()=>{
  //   console.log("caleed");
  //   if(JSON.parse(localStorage.getItem("error"))?.open){
  //     setOpenSnackbar(true)
  //   }
  //   setTimeout(()=>{
  // localStorage.removeItem("error")
  //   },2000)
  // },[JSON.parse(localStorage.getItem("error"))])
  const loginUser = async (id) => {
    // CometChatUIKit.logout();
    // CometChatUIKit.getLoggedinUser().then((user) => {
    //   console.log("useruser",user);
    //   if (user) {
    //     setUser(user);
    //   }
    //   if (!user) {
    //Login user
    CometChatUIKit.login(id, COMET_CHAT_AUTH_KEY)
      .then((user1) => {
        // console.log("Login Successful:", { user1 });
        setUser(user1);
        //mount your app
      })
      .catch(console.log);
    //   } else {
    //   }
    // });
  };
  useEffect(() => {
    async function initialize() {
      await CometChatUIKit.init(UIKitSettings);
      CometChatLocalize.init("en", {
        en: {
          CHATS: "Messenger",
        },
      });
    }
    initialize();
  }, []);
  const createLoginUser = async () => {
    if (JSON.parse(localStorage.getItem("apex-saas_details"))) {
      const resp = await dispatch(
        createUser({
          shopId: JSON.parse(localStorage.getItem("apex-saas_details"))?._id,
        })
      );

      // console.log(
      //   "resp?.data?.data?.chatUserId",
      //   resp?.data?.data?.shopOwnerChatUser?.chatUserId
      // );
      // console.log("resp", resp);

      // console.log("resp COMET_CHAT_AUTH_KEY", COMET_CHAT_AUTH_KEY);
      await loginUser(resp?.data?.data?.shopOwnerChatUser?.chatUserId);
    }
    // setUser(resp?.data);
  };
  if (!user) {
    createLoginUser();
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        {/* <ErrorSnackbar open={errorMessage !== ""} message={errorMessage} severity={error} /> */}
        <Context.Provider
          value={{
            GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAP_KEY,
            cometChatUser: user,
            isOpenSideBar,
            setIsOpenSidebar,
          }}
          user={user}
        >
          <BrowserRouter basename="/">
            <Elements
              stripe={stripePromise}
              //   options={options}
            >
              <Toaster
                position="top-center"
                richColors
                theme="light"
                toastOptions={{
                  style: { borderRadius: "2px" },
                  className: "my-toast",
                }}
              />
              <Suspense>
                {loggedin ? <PrivateRoutes /> : <PublicRoutes />}
                {/* <AllRoutes /> */}
              </Suspense>
            </Elements>
          </BrowserRouter>
        </Context.Provider>

        {/* <Snackbar
          open={openSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={2000}
          onClose={()=>setOpenSnackbar(false)}
          key={"top center"}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
          {JSON.parse(localStorage.getItem("error"))?.message}
          </Alert>
        </Snackbar>; */}
      </ThemeProvider>
    </>
  );
}

export default App;
