import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import HomeIcon from "@mui/icons-material/Home";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonIcon from "@mui/icons-material/Person";
import WorkIcon from "@mui/icons-material/Work";
import {
  Button,
  Chip,
  Divider,
  Grid,
  Link,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SupplierDeleteModel from "./SupplierDeleteModel";
import SupplierEditModal from "./SupplierEditModel";
function SupplierProfileLeftDataSection({ setQuickEdit }) {
  const navigate = useNavigate();
  const [openEditSupplier, setOpenEditSupplier] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const handleCloseSupplier = () => {
    setOpenEditSupplier(false);
  };
  const onSubmitSupplier = () => {};

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleDeleteSupplier = () => {};

  const [anchorEl, setAnchorEl] = useState(null);
  const open1 = Boolean(anchorEl);
  return (
    <>
      <Grid item width={"100%"}>
        <Typography
          variant="h5"
          fontWeight={600}
          justifyContent={"space-between"}
          display={"flex"}
          paddingBottom={"8px"}
        >
          <span>Ford Motor Company</span>

          <span onClick={() => setQuickEdit(true)}>
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              cursor={"pointer"}
            >
              <path
                d="M0 18V13.75L13.2 0.575C13.4 0.391667 13.621 0.25 13.863 0.15C14.105 0.0500001 14.359 0 14.625 0C14.891 0 15.1493 0.0500001 15.4 0.15C15.6507 0.25 15.8673 0.4 16.05 0.6L17.425 2C17.625 2.18333 17.771 2.4 17.863 2.65C17.955 2.9 18.0007 3.15 18 3.4C18 3.66667 17.9543 3.921 17.863 4.163C17.7717 4.405 17.6257 4.62567 17.425 4.825L4.25 18H0ZM14.6 4.8L16 3.4L14.6 2L13.2 3.4L14.6 4.8Z"
                fill="black"
                fill-opacity="0.6"
              />
            </svg>
          </span>
        </Typography>

        <Chip
          style={{
            // ...styleChip,
            // display: "flex",
            background: "#00000014",
            color: "#000000DE",
            fontWeight: 500,
            fontSize: "16px",
          }}
          className="chip-text-style"
          label={`Balance Owing: $682.00`}
        />
        <Typography
          variant="body1"
          fontWeight={400}
          paddingTop={"16px"}
          display={"flex"}
          gap={"8px"}
        >
          <PersonIcon style={{ color: "#006CDD", fontSize: "20px" }} />

          <span
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",

              lineHeight: "normal",
            }}
          >
            User name
          </span>
        </Typography>
        <Typography
          variant="body1"
          fontWeight={400}
          paddingTop={"16px"}
          display={"flex"}
          gap={"8px"}
        >
          <WorkIcon style={{ color: "#006CDD", fontSize: "20px" }} />
          <span
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",

              lineHeight: "normal",
            }}
          >
            Work icon
          </span>
        </Typography>
        <Typography
          variant="body1"
          fontWeight={400}
          paddingTop={"16px"}
          display={"flex"}
          gap={"8px"}
        >
          <HomeIcon style={{ color: "#006CDD", fontSize: "20px" }} />
          <span
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",

              lineHeight: "normal",
            }}
          >
            416-912-8026
          </span>
        </Typography>
        <Typography
          variant="body1"
          fontWeight={400}
          paddingTop={"16px"}
          display={"flex"}
          gap={"8px"}
        >
          <img src="https://icongr.am/entypo/old-phone.svg?size=20&color=006cdd" />
          <span
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",

              lineHeight: "normal",
            }}
          >
            416-912-8026
          </span>
        </Typography>
        <Typography
          variant="body1"
          fontWeight={400}
          paddingTop={"16px"}
          display={"flex"}
          gap={"8px"}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M17.5 18.75V5H13.75V1.25H2.5V18.75H0V20H8.75V16.25H11.25V20H20V18.75H17.5ZM7.5 13.75H5V11.25H7.5V13.75ZM7.5 10H5V7.5H7.5V10ZM7.5 6.25H5V3.75H7.5V6.25ZM11.25 13.75H8.75V11.25H11.25V13.75ZM11.25 10H8.75V7.5H11.25V10ZM11.25 6.25H8.75V3.75H11.25V6.25ZM16.25 13.75H13.75V11.25H16.25V13.75ZM16.25 10H13.75V7.5H16.25V10Z"
              fill="#006CDD"
            />
          </svg>

          <span
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",

              lineHeight: "normal",
            }}
          >
            416-912-8026
          </span>
        </Typography>
        <Typography
          variant="body1"
          fontWeight={400}
          paddingTop={"16px"}
          display={"flex"}
          gap={"8px"}
        >
          <img src="https://icongr.am/entypo/mobile.svg?size=20&color=006cdd" />
          <span
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",

              lineHeight: "normal",
            }}
          >
            416-912-8026
          </span>
        </Typography>
        <Typography
          variant="body1"
          fontWeight={400}
          paddingTop={"16px"}
          display={"flex"}
          gap={"8px"}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M16.667 3.33337H3.33366C2.41699 3.33337 1.67533 4.08337 1.67533 5.00004L1.66699 15C1.66699 15.9167 2.41699 16.6667 3.33366 16.6667H16.667C17.5837 16.6667 18.3337 15.9167 18.3337 15V5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337ZM16.667 6.66671L10.0003 10.8334L3.33366 6.66671V5.00004L10.0003 9.16671L16.667 5.00004V6.66671Z"
              fill="#006CDD"
            />
          </svg>
          <span
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",

              lineHeight: "normal",
            }}
          >
            416-912-8026
          </span>
        </Typography>
        <Typography
          variant="body1"
          fontWeight={400}
          paddingTop={"16px"}
          display={"flex"}
          gap={"8px"}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M15.8333 15.8333H4.16667V4.16667H10V2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.24167 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V10H15.8333V15.8333ZM11.6667 2.5V4.16667H14.6583L6.46667 12.3583L7.64167 13.5333L15.8333 5.34167V8.33333H17.5V2.5H11.6667Z"
              fill="#006CDD"
            />
          </svg>
          <Link
            href="https://apexautoapp.com/"
            target="_blank"
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              color: "#000000DE",
              lineHeight: "normal",
            }}
            rel="noopener noreferrer" // Recommended for security
          >
            www.foodmotorcomapnu.com
          </Link>
        </Typography>
        <Typography
          variant="body1"
          fontWeight={400}
          paddingTop={"16px"}
          display={"flex"}
          gap={"8px"}
          alignItems={"center"}
        >
          <LocationOnIcon style={{ color: "#006CDD", fontSize: "20px" }} />
          <span
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",

              lineHeight: "normal",
            }}
          >
            899 Ledbury Crescent Mississauga, ON L5V 2P8
          </span>
        </Typography>

        <Typography
          variant="body1"
          fontWeight={400}
          paddingTop={"16px"}
          display={"flex"}
          gap={"8px"}
        >
          <FilterAltIcon style={{ color: "#006CDD", fontSize: "20px" }} />
          <span
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              fontWeight: 500,

              lineHeight: "normal",
            }}
          >
            Contact Preferences:
          </span>
          <span
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              // fontWeight: 500,

              lineHeight: "normal",
            }}
          >
            {/* {
                  CONTACT_PREFRENCES?.filter(
                    (d) => d.key == data?.contactPrefrence
                  )[0]?.values
                } */}
            Call on phone
          </span>
        </Typography>

        <Divider style={{ width: "100%", marginTop: "23px" }} />
      </Grid>
      <Grid
        item
        container
        justifyContent={"start"}
        paddingLeft={"8px"}
        paddingTop={"24px"}
      >
        <Stack spacing={1} direction={"row"}>
          <Button
            variant="contained"
            //   onClick={() => {
            //     navigate(`/messenger/${id}`);
            //     // navigate(`/messenger`);
            //   }}
          >
            SEND EMAIL
          </Button>

          <Button
            variant="outlined"
            endIcon={<ExpandMoreIcon />}
            id="demo-positioned-button"
            // aria-controls={open ? "demo-positioned-menu" : undefined}
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
          >
            ACTIONS{" "}
          </Button>

          <Menu
            style={{ width: "fitContent", top: 48 }}
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open1}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClick={() => setAnchorEl(null)}
          >
            <MenuItem
              //   onClick={() => {
              //     setOpenEditCustomer(true);
              //     setValueinForm("firstName", data?.firstName);
              //     setValueinForm("lastName", data?.lastName);
              //     setValueinForm(
              //       "phoneNumber",
              //       inputFormatPhoneNumber(data?.phoneNumber)
              //     );
              //     setValueinForm("email", data?.email);
              //     setValueinForm("company", data?.company);
              //     setValueinForm("province", data?.province?.province);
              //     setValueinForm("city", data?.city?.city);
              //     setValueinForm("streetAddress", data?.streetAddress);
              //     setValueinForm("country", data?.country?.country);
              //     setValueinForm("postalCode", data?.postalCode);
              //     setAddress(data?.streetAddress);
              //     setAddressValue({
              //       ...addressValues,
              //       city: data?.city?.city,
              //       province: data?.province?.province,
              //       cityId: data?.city?._id,
              //       provinceId: data?.province?._id,
              //     });
              //     setCountry(data?.country?.country);
              //   }}
              onClick={() => {
                setOpenEditSupplier(true);
              }}
            >
              <EditIcon
                style={{
                  marginRight: "10px",
                  color: "rgba(0, 0, 0, 0.56)",
                }}
              />{" "}
              Edit Supplier
            </MenuItem>

            <MenuItem onClick={() => setOpenDelete(true)}>
              <DeleteIcon
                style={{
                  marginRight: "10px",
                  color: "rgba(0, 0, 0, 0.56)",
                }}
              />{" "}
              Delete Supplier
            </MenuItem>
          </Menu>
        </Stack>
      </Grid>
      <SupplierEditModal
        openEditSupplier={openEditSupplier}
        handleCloseSupplier={handleCloseSupplier}
        onSubmitSupplier={onSubmitSupplier}
        setOpenDelete={setOpenDelete}
      />
      <SupplierDeleteModel
        openDelete={openDelete}
        handleCloseDelete={handleCloseDelete}
        handleDeleteSupplier={handleDeleteSupplier}
      />
    </>
  );
}

export default SupplierProfileLeftDataSection;
