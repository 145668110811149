import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Container,
  Modal,
  Stack,
  Toolbar,
  Typography,
  Link,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  pl: 4,
  pr: 0,
  pb: 3,
};
const ContactSupport = () => {
  const [open, setOpen] = useState(true);
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        sx={{
          ...style,
          width: "600px",
          height: "224px",
          border: "none",
          borderRadius: "8px",
        }}
      >
        <AppBar
          color="secondary"
          variant="elevation"
          style={{
            height: "56px",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            height: "64px",
            paddingRight: "0px !important",
          }}
        >
          <Toolbar variant="regular">
            <Typography variant="h6" width={"98%"} color={"white"}>
              Contact Support
            </Typography>
            <Stack direction={"row"} spacing={0}>
              <div onClick={() => setOpen(false)} style={{ cursor: "pointer", height: '32px' }}>
                <CloseIcon style={{ fontSize: 32, color: 'white' }} />
              </div>
            </Stack>
          </Toolbar>
        </AppBar>
        <Container
          gap={"24px"}
          disableGutters
          style={{ height: "168px", paddingTop: "62px" }}
        >
          <Stack>
            <div>
              <Typography variant="body1" fontSize={"16px"}>
                <b>Need Help? </b>
                We are here to support 9:00 AM - 9:00 PM. Monday to Sunday.
              </Typography>
            </div>
            <div style={{ paddingTop: "24px" }}>
              <Typography variant="body1" fontSize={"16px"} fontWeight={500}>
                Call Us: (437)-961-8836
              </Typography>
            </div>{" "}
            <div style={{ paddingTop: "24px" }} fontWeight={500}>
              <Typography variant="body1" fontWeight={"medium"}>
                Email:
                <Link style={{ textDecoration: "none" }}> support@apexmechanic.com</Link>
              </Typography>
            </div>
          </Stack>
        </Container>
      </Box>
    </Modal>
  );
};

export default ContactSupport;
