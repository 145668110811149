import { Add } from "@mui/icons-material";

import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import "./Customers.css";

// import FilterModal from "./FilterModal";
import TableContainer from "@mui/material/TableContainer";
import axios from "axios";

const Suppliers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [searchValue, setSearchValue] = useState(null);
  const [orderBy, setOrderBy] = useState("supplierName");
  const [order, setOrder] = useState("asc");
  const [filterDate, setFilterDate] = useState("");

  const [showFilterModal, setShowFilterModal] = React.useState(true);
  const [selectedSearchType, setSelectedSearchType] = useState("Supplier Name");
  const [searchBy, setSearchBy] = useState("supplierName");
  const [type, setType] = useState("all");

  const [suppliers, setSuppliers] = useState([
    {
      _id: "123",
      supplierName: "NAPA",
      accountNumber: "1565015004",
      contactName: "Leslie Alexander",
      phoneNumber: "+447700960137",
      email: "kenzi.lawson@example.com",
      website: "klotz.de",
    },
    {
      _id: "234",
      supplierName: "Auto Parts Supplier",
      accountNumber: "2112032819",
      contactName: "Jane Cooper",
      phoneNumber: "+447700960612",
      email: "felicia.reid@example.com",
      website: "transnova-ruf.de",
    },
  ]);
  const [fullPageLoader, setFullPageLoader] = useState(false);
  const [types, setTypes] = useState([
    "Shop Created",
    "Shop Imported",
    "Apex Auto App",
  ]);
  const [typeValues, setTypeValues] = useState([
    "shop_created",
    "shop_imported",
    "apex_auto_app",
  ]);

  let params = {
    page: page + 1,
    limit: rowsPerPage,
    search: searchValue,
    searchBy: searchBy,
    sortBy: orderBy,
    sortType: order == "asc" ? "ASC" : "DESC",
    date: filterDate,
    createdFrom: typeValues,
  };
  const [showLoader, setShowLoader] = useState(true);
  const cancelTokenSourceRef = useRef(null);

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const fetchSupplierList = async (params) => {
    setShowLoader(true);
    // Cancel API call on recall
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        "Operation canceled due to new request."
      );
    }

    cancelTokenSourceRef.current = axios.CancelToken.source();
    // const resp = await dispatch(
    //   customerList(params, cancelTokenSourceRef.current.token)
    // );
    // if (Array.isArray(resp)) {
    //   setSuppliers(resp);
    //   setShowLoader(false);
    //   setFullPageLoader(false);
    // }
    // // console.log("resp", resp);
    // if (resp.code == "ERR_CANCELED") {
    //   setSuppliers([]);
    // }
    setShowLoader(false);
  };

  const customers1 = useSelector((state) => state.customer.customerList);
  console.log("🚀 ~ Customers ~ suppliers:", suppliers);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    params.page = newPage + 1;
    fetchSupplierList(params);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    params.limit = parseInt(event.target.value, 10);
    params.page = 1;
    fetchSupplierList(params);
  };
  useEffect(() => {
    // localStorage.getItem("searchValue") &&
    //   setSearchValue(localStorage.getItem("searchValue"));

    // localStorage.getItem("searchBy") &&
    //   setSearchBy(localStorage.getItem("searchBy"));

    const delayDebounceFn = setTimeout(() => {
      params.page = 1;
      fetchSupplierList(params);
    }, 800); // Set the debounce time (milliseconds)

    // Cleanup function to cancel the previous setTimeout
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);
  useEffect(() => {
    handleSelectSearchType(localStorage.getItem("searchBy") ?? "fullName");
  }, []);
  const handleSearchChange = (event) => {
    console.log("event.target.value", event.target.value);

    localStorage.setItem("searchValue", event.target.value);

    setSearchValue(event.target.value);
  };

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    params.sortBy = property;
    params.sortType = isAsc ? "DSC" : "ASC";
    fetchSupplierList(params);
  };

  const TableRowsLoader = ({ rowsNum }) => {
    return [...Array(rowsNum)].map((row, index) => (
      <TableRow key={index}>
        <TableCell component="th" scope="row">
          <Skeleton animation="wave" variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" />
        </TableCell>
      </TableRow>
    ));
  };
  const handleSelectSearchType = (type) => {
    // setSearchBy(type);
    // localStorage.setItem("searchBy", type);
    // if (type == "supplierName") {
    //   setSelectedSearchType("Supplier Name");
    // } else if (type == "phoneNumber") {
    //   setSelectedSearchType("Phone Number");
    // } else if (type == "email") {
    //   setSelectedSearchType("Email");
    // } else if (type == "contactName") {
    //   setSelectedSearchType("Contact Name");
    // } else if (type == "vehicle") {
    //   setSelectedSearchType("Vehicle");
    // } else {
    //   localStorage.setItem("searchBy", "");
    //   // setSearchBy("");
    //   setSelectedSearchType("");
    // }
    // params.page = 1;
    // params.searchBy = type;
    // if (searchValue != "") {
    //   fetchSupplierList(params);
    // }
    // setSearchValue("");
    // localStorage.setItem("searchValue", "");
  };

  return (
    <>
      <Box padding={"32px 24px"} sx={{ bgcolor: "background.main" }}>
        <Grid container rowGap={2}>
          <Grid container item justifyContent={"space-between"}>
            <Grid item lg={9} padding={"16px 0px"}>
              <Typography variant="h4" sx={{ fontWeight: 400 }}>
                Suppliers{" "}
              </Typography>
            </Grid>
            <Grid item padding={"16px 0px"}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<Add />}
                onClick={() => {
                  navigate("/suppliers/new");
                }}
                size="large"
              >
                NEW SUPPLIER
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Box
          sx={{
            bgcolor: "white",
            mt: "59px",
            p: "24px",
            // boxShadow:
            //   "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
          }}
          boxShadow={2}
        >
          <Grid
            container
            columns={13}
            item
            // gap={1}
            alignItems={"center"}
            // justifyContent={"space-between"}
            // marginBottom={"32px"}
            spacing={2}
          >
            {/* <Grid item xs={13} sm={6.5} md={3} lg={2} xl={2}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  fullWidth
                  label="Type"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type}
                >
                  <MenuItem value="all" key="all">
                    All
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
            <Grid item xs={13} sm={6.5} md={3} lg={2} xl={2}>
              <FormControl
                fullWidth
                //  sx={{ height: "34px" }}
              >
                <InputLabel
                  id="demo-simple-select-label"
                  // sx={{
                  //   fontSize: "14px", // Adjust the label font size
                  //   transform: "translate(14px, 7px) scale(1)", // Default position for label
                  //   "&.MuiInputLabel-shrink": {
                  //     transform: "translate(14px, -6px) scale(0.75)", // Adjust position when label shrinks
                  //   },
                  // }}
                >
                  Search by
                </InputLabel>
                <Select
                  fullWidth
                  label="Search by"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={searchBy}
                  // sx={{
                  //   height: "34px", // Set the custom height
                  //   fontSize: "14px", // Adjust font size for selected value
                  //   padding: "0px 12px", // Reduce padding to fit within 34px
                  //   display: "flex",
                  //   alignItems: "center", // Vertically center the text
                  //   boxSizing: "border-box", // Ensure padding is included within height
                  // }}
                  //   size="small"
                  //   endAdornment={
                  //     searchBy && (
                  //       <InputAdornment
                  //         position="end"
                  //         sx={{ paddingRight: "20px" }}
                  //       >
                  //         <IconButton
                  //           edge="end"
                  //           onClick={() => {
                  //             setSelectedSearchType("");
                  //             setSearchValue("");
                  //             localStorage.setItem("searchValue", "");
                  //             setSearchBy(null);
                  //             localStorage.setItem("searchBy", "");
                  //             params.page = 1;
                  //             // params.searchBy = "";
                  //           }}
                  //         >
                  //           <ClearIcon sx={{ height: "18px", width: "18px" }} />
                  //         </IconButton>
                  //       </InputAdornment>
                  //     )
                  //   }
                  //   onChange={(e) => {
                  //     handleSelectSearchType(e.target.value);
                  //     setItem("searchValue", "");
                  //   }}
                >
                  <MenuItem value="supplierName" key="supplierName">
                    Supplier Name
                  </MenuItem>
                  <MenuItem value="contactName" key="contactName">
                    Contact Name
                  </MenuItem>
                  <MenuItem value="phoneNumber" key="phoneNumber">
                    Phone Number
                  </MenuItem>
                  <MenuItem value="email" key="email">
                    Email
                  </MenuItem>
                  {/* <MenuItem value="vehicle" key="vehicle">
                    Vehicle
                  </MenuItem>
                  <MenuItem value="company" key="company">
                    Company
                  </MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={13} sm={13} md={7} lg={9} xl={9}>
              <FormControl fullWidth>
                <InputLabel
                  htmlFor="outlined-adornment-search"
                  style={{ backgroundColor: "transparent", color: "black" }}
                >
                  Search
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  id="outlined-adornment-search"
                  sx={{ backgroundColor: "transparent", color: "black" }}
                  placeholder={
                    searchBy
                      ? selectedSearchType
                      : " Supplier name, phone number, email, etc..."
                  }
                  onChange={handleSearchChange}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  // style={{ height: "34px" }}
                  endAdornment={
                    searchValue && (
                      <InputAdornment
                        position="end"
                        className="search-close-icon"
                        onClick={() => {
                          localStorage.setItem("searchValue", "");
                          setSearchValue("");
                        }}
                      >
                        <ClearIcon />
                      </InputAdornment>
                    )
                  }
                  label="Search"
                  value={searchValue}
                  disabled={searchBy ? false : true}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Divider
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              width: "100%",
              height: "10px",
            }}
          />
          <Grid
            container
            item
            // padding={"24px"}
            // boxShadow={2}
            borderRadius={"10px"}
          >
            <Grid item xl={12} md={12} sm={12} xs={12}>
              <TableContainer sx={{ maxHeight: 600, maxWidth: "100%" }}>
                <Table
                  stickyHeader
                  padding="16px"
                  sx={{ tableLayout: "fixed" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: "200px" }}>
                        <TableSortLabel
                          active={orderBy === "supplierName"}
                          direction={orderBy === "supplierName" ? order : "asc"}
                          onClick={() => handleSortRequest("supplierName")}
                        >
                          Supplier Name
                        </TableSortLabel>{" "}
                      </TableCell>
                      <TableCell style={{ width: "200px" }}>
                        {" "}
                        {/* <TableSortLabel
                          active={orderBy === "phoneNumber"}
                          direction={orderBy === "phoneNumber" ? order : "asc"}
                          onClick={() => handleSortRequest("phoneNumber")}
                        >
                          {" "}
                          Phone Number
                        </TableSortLabel> */}
                        Account #
                      </TableCell>
                      <TableCell style={{ width: "200px" }}>
                        <TableSortLabel
                          active={orderBy === "fullName"}
                          direction={orderBy === "fullName" ? order : "asc"}
                          onClick={() => handleSortRequest("fullName")}
                        >
                          Contact Name
                        </TableSortLabel>{" "}
                      </TableCell>
                      <TableCell style={{ width: "200px" }}>
                        Phone Number
                      </TableCell>
                      <TableCell style={{ width: "200px" }}>Email</TableCell>

                      <TableCell style={{ width: "200px" }}>Website</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {showLoader ? (
                      <TableRowsLoader rowsNum={rowsPerPage} />
                    ) : (
                      Array.isArray(suppliers) &&
                      suppliers.map((data) => (
                        <TableRow
                          key={data._id}
                          onClick={() => {
                            // navigate(`${data?._id}/${data?.createdFrom}`);

                            navigate(`${data?._id}`);
                          }}
                          hover={true}
                          style={{ cursor: "pointer" }}
                        >
                          <TableCell component="th" scope="row">
                            {data?.supplierName}
                          </TableCell>
                          <TableCell align="left">
                            {data?.accountNumber}
                          </TableCell>
                          <TableCell align="left">
                            {data?.contactName}
                          </TableCell>
                          <TableCell align="left">
                            {data?.phoneNumber}
                          </TableCell>
                          <TableCell align="left">{data?.email}</TableCell>

                          <TableCell align="left">{data?.website}</TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container item alignItems={"center"} justifyContent="end">
                <Grid item>
                  {customers1?.pagination?.totalCount > 0 && (
                    <TablePagination
                      component="div"
                      count={customers1?.pagination?.totalCount}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPageOptions={[10, 20, 50, 100]}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Dialog fullScreen={fullScreen} open={fullPageLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      {/* <FilterModal open={showFilterModal} setOpen={setShowFilterModal} /> */}
    </>
  );
};

export default Suppliers;
