import React, { useState } from "react";
import Select, { components } from "react-select";
import {
  customStyles,
  errorCustomStyles,
} from "../../../utility/SelectDropdown";

function AddressSelect({
  list,
  addressValues,
  setAddressValue,
  type,
  setCityList,
  fetchCityList,
  disable,
  loader,
  maxMenuHeight,
  setValue = () => {},
  error,
  menuPlacement,
}) {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const handleMenuOpen = () => {
    setMenuIsOpen(true);
  };
  const handleMenuClose = () => {
    setMenuIsOpen(false);
  };
  const handleKeyDown = (event) => {
    if (menuIsOpen && event.key === "Tab") {
      event.preventDefault(); // Prevent default tab behavior
    }
  };
  const Input = ({ ...rest }) => (
    <components.Input {...rest} autoComplete={"nope"} />
  );
  return (
    <Select
      placeholder="" // defaultValue={}
      isDisabled={disable ?? false}
      isLoading={loader ?? false}
      isClearable={true}
      isSearchable={true}
      options={list}
      value={
        type == "city"
          ? addressValues?.cityId != ""
            ? list.find((option) => option._id === addressValues?.cityId)
            : null
          : addressValues?.provinceId !== ""
          ? list.find((option) => option._id === addressValues?.provinceId)
          : null
      }
      getOptionLabel={(option) =>
        type == "city" ? option.city : option.province
      } // Specifies which field to use as the label
      getOptionValue={(option) => option}
      onChange={(e) =>
        type == "city"
          ? (setAddressValue({
              ...addressValues,
              city: e ? e.city : "",
              cityId: e ? e._id : "",
              province: e ? e.province?.province : "",
              provinceId: e ? e.province?._id : "",
              countryId: e?.country?._id,
            }),
            setValue("city", e ? e.city : ""),
            setValue("province", e ? e.province?.province : ""))
          : (setAddressValue({
              ...addressValues,
              province: e?.province,
              provinceId: e?._id,
              city: "",
              cityId: "",
              countryId: e?.country?._id,
            }),
            setCityList([]),
            fetchCityList(e?._id),
            setValue("province", e ? e.province : ""),
            setValue("city", ""))
      }
      styles={error ? { ...customStyles, ...errorCustomStyles } : customStyles}
      maxMenuHeight={maxMenuHeight ?? 150}
      onKeyDown={handleKeyDown}
      components={{ Input }}
      blurInputOnSelect
      menuPlacement={menuPlacement ? menuPlacement : "auto"}
      onMenuOpen={handleMenuOpen}
      onMenuClose={handleMenuClose}
    />
  );
}

export default AddressSelect;
